import { useEffect, useState } from "react";
import { Col, Form, InputNumber, Row, Select } from "antd";
import BigboxContainer from "../components/containers/bigbox-container";
import { t } from "../translate";
import { useAtom } from "jotai";
import { loadingAtom } from "../store/global-atoms";
import { BasicModel } from "../service/models";
import { FormInstance } from "antd/es/form/Form";
import { useSecureService } from "../store/service-atoms";
import { formatPriceInput } from "../helpers/formatters"

type IMachineSalePricePartProps = {
    depositFee?: string | undefined;
    machineGoCommission?: string | undefined;
    totalPriceForMachineOwner?: string | undefined;
    initialCurId?: number; 
    availableCurrencies?: BasicModel[];
    onPriceChange: (val: any) => void;
    onCurrencyChange: (val: string | null | undefined) => void;
    onCurrencyFetchFinished?: () => void;
    initialInit: boolean;
    form?: FormInstance; 
};

const MachineSalePricePart = (props: IMachineSalePricePartProps) => {
    const [availableCurrencies, setAvailableCurrencies] = useState<BasicModel[] | undefined>(undefined);
    const [loading] = useAtom(loadingAtom);
    const { doGetCurrencies } = useSecureService();

    useEffect(() => {
        const getCurrencies = async () => {
            const currencies = await doGetCurrencies();
            setAvailableCurrencies(currencies);
        };
        getCurrencies();
        //eslint-disable-next-line
    }, []);
 
    useEffect(() => {
        if (availableCurrencies && availableCurrencies.length > 0 && props.initialCurId) {
            props.form?.setFieldsValue({
                machineSalePriceCurrencyName: props.availableCurrencies?.find(e => e.id === props.initialCurId)?.name,
            }); 
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.initialCurId, availableCurrencies]);

    return (
        <BigboxContainer title={t("machines.add.pricePartTitle")}>
            <Row gutter={[16, 32]}>
                <Col md={8} xs={24}>
                    <Form.Item
                        label={t("common.currency")}
                        name="machineSalePriceCurrencyName"
                        rules={[
                            {
                                required: true,
                                message: t("common.generic-required-message"),
                            },
                        ]}
                    >
                        <Select
                            onChange={(e) => props.onCurrencyChange(e)}
                            loading={loading}
                            placeholder={t("machines.add.price.currencyPlaceholder")}
                            getPopupContainer={(trigger: any) => trigger.parentElement}
                        >
                            {props.initialInit ? props.availableCurrencies?.map((curr) => (
                                <Select.Option value={curr.name}>{curr.name}</Select.Option>
                            )) : availableCurrencies?.map((curr) => (
                                <Select.Option value={curr.name}>{curr.name}</Select.Option>
                            ))}
                        </Select>
                    </Form.Item>
                </Col>
                <Col md={16} xs={24}>
                    <Form.Item
                        label={t("common.price-2")}
                        name="machineSalePrice"
                        rules={[
                            {
                                pattern: /^([1-9][0-9]*)$/,
                                message:t('common.generic-required-numeric-input-message')
                            },
                            {
                                required: true,
                                message: t("common.generic-required-message"),
                            },
                        ]}
                    >
                        <InputNumber
                            placeholder={t("machines.add.price.inputPlaceholder")}
                            onKeyDown={(event) => {
                                if ((event.key !== "Delete" && event.key !== "Backspace") && !/[0-9]/.test(event.key)) {
                                    event.preventDefault();
                                }
                            }}
                            formatter={value =>{
                                return formatPriceInput(value?.toString() ?? "");
                            }}
                            parser={value => {
                                return formatPriceInput(value?.toString() ?? "").replaceAll(".", '');
                            }} 
                            onWheel={(e: any) => e.target.blur()}
                            controls={false}
                            onChange={(e) => {
                                props.onPriceChange(e)
                            }}
                            type="text"
                            style={{ width: "100%" }}
                        />
                    </Form.Item>
                </Col>
            </Row>
        </BigboxContainer>
    );
};

export default MachineSalePricePart;
