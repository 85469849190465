import { Col, ColProps, Row } from "antd";
import { moment } from "../../helpers/moment";
import { ModifiedRequestDetailModel } from "../../store/global-atoms";
import { t } from "../../translate";
import BoxContainer from "../containers/box-container";
import BasicModelViewer from "./basic-model-viewer";
import PrimaryButton from "./primary-button";
import RequestAndOfferStatus from "./request-and-offer-status";

type RequestListCardProps = {
    requestForm: ModifiedRequestDetailModel | undefined;
    offerStatus: string | null | undefined;
    role?: string;
    onViewClick?: (param: number | undefined) => void;
} & ColProps;

const RequestListCard = (props: RequestListCardProps) => {
    const viewRequestClick = () => {
        props.onViewClick && props.onViewClick(props.requestForm?.id);
    };
    const catId = props.requestForm?.subCategory?.category?.id;
    const shortenedDesc = props.requestForm?.description?.truncate(100, true);

    let count = 0;
    if (props.requestForm?.offerForms) {
        props.requestForm?.offerForms?.map((val) => {
            if (val.status === "Approved") {
                count = count + 1;
            }
            return null;
        });
    }
    return (
        <Col {...props}>
            <BoxContainer style={{ display: "flex" }}>
                <div
                    style={{
                        display: "flex",
                        height: "100%",
                        flexDirection: "column",
                        justifyContent: "space-between",
                    }}
                >
                    <div>
                        <RequestAndOfferStatus
                            role={props.role}
                            requestStatus={props.requestForm?.status}
                            offerStatus={props.offerStatus}
                            showOfferStatus={false}
                            showRequestStatus={true}
                            count={count}
                        />
                        <Row gutter={[8, 8]}>
                            <BasicModelViewer xs={12} md={7} objKey={t("request.id")} value={props.requestForm?.id} />
                            <BasicModelViewer
                                xs={12}
                                md={10}
                                objKey={t("request.machineCategory")}
                                value={props.requestForm?.subCategory?.category?.name}
                            />
                            <BasicModelViewer
                                xs={12}
                                md={7}
                                objKey={t("request.machineSubcategory")}
                                value={props.requestForm?.subCategoryOther ?? props.requestForm?.subCategory?.name}
                            />
                        </Row>
                        <Row gutter={[8, 8]}>
                            <BasicModelViewer xs={12} md={7} objKey={t("request.machineCount")} value={props.requestForm?.machineCount} />
                            <BasicModelViewer
                                xs={12}
                                md={10}
                                objKey={t("request.requesteddates")}
                                value={moment(props.requestForm?.requestDateFrom) + " - " + moment(props.requestForm?.requestDateTo)}
                            />
                            <BasicModelViewer
                                xs={12}
                                md={7}
                                objKey={t("request.creationDate")}
                                value={moment(props.requestForm?.creationDate)}
                            />
                            <BasicModelViewer xs={12} md={7} objKey={t("request.isFirm")} value={props.requestForm?.companyName} />
                            <BasicModelViewer
                                xs={12}
                                md={7}
                                objKey={t("request.location")}
                                value={props.requestForm?.district?.city?.name}
                            />
                        </Row>
                    </div>
                    <div>
                        <BasicModelViewer showEvenValueNull xs={16} md={14} objKey={t("request.details")} value={shortenedDesc}>
                            <div style={{ marginTop: ".5rem" }}>
                                <PrimaryButton onClick={viewRequestClick} style={{ marginBottom: "0" }}>
                                    {t("common.viewDetails")}
                                </PrimaryButton>
                            </div>
                        </BasicModelViewer>
                    </div>
                    <div
                        style={{
                            width: "11rem",
                            height: "11rem",
                            position: "absolute",
                            right: "0",
                            bottom: "0",
                            overflow: "hidden",
                        }}
                    >
                        {catId && (
                            <img
                                style={{
                                    width: "100%",
                                    position: "relative",
                                    right: "-2rem",
                                }}
                                src={"/img" + catId + ".png"}
                                alt={"/img" + catId + ".png"}
                            />
                        )}
                    </div>
                </div>
            </BoxContainer>
        </Col>
    );
};

export default RequestListCard;
