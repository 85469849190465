/* tslint:disable */
/* eslint-disable */
/**
 * MachineGo P1 API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    MachineDocumentCreateModel,
    MachineDocumentCreateModelFromJSON,
    MachineDocumentCreateModelFromJSONTyped,
    MachineDocumentCreateModelToJSON,
} from './MachineDocumentCreateModel';

/**
 * 
 * @export
 * @interface MachineCreateSaleModel
 */
export interface MachineCreateSaleModel {
    /**
     * 
     * @type {number}
     * @memberof MachineCreateSaleModel
     */
    lessorCompanyId?: number;
    /**
     * 
     * @type {number}
     * @memberof MachineCreateSaleModel
     */
    subCategoryId?: number;
    /**
     * 
     * @type {number}
     * @memberof MachineCreateSaleModel
     */
    brandId?: number;
    /**
     * 
     * @type {string}
     * @memberof MachineCreateSaleModel
     */
    brandOther?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MachineCreateSaleModel
     */
    modelName?: string | null;
    /**
     * 
     * @type {number}
     * @memberof MachineCreateSaleModel
     */
    productionYear?: number;
    /**
     * 
     * @type {number}
     * @memberof MachineCreateSaleModel
     */
    operationMileage?: number | null;
    /**
     * 
     * @type {number}
     * @memberof MachineCreateSaleModel
     */
    operationHour?: number | null;
    /**
     * 
     * @type {string}
     * @memberof MachineCreateSaleModel
     */
    drivingEquipment?: string | null;
    /**
     * 
     * @type {number}
     * @memberof MachineCreateSaleModel
     */
    enginePower?: number | null;
    /**
     * 
     * @type {number}
     * @memberof MachineCreateSaleModel
     */
    operatingWeight?: number | null;
    /**
     * 
     * @type {number}
     * @memberof MachineCreateSaleModel
     */
    tankCapacity?: number | null;
    /**
     * 
     * @type {number}
     * @memberof MachineCreateSaleModel
     */
    fuelTypeId?: number | null;
    /**
     * 
     * @type {number}
     * @memberof MachineCreateSaleModel
     */
    elevatorTypeId?: number | null;
    /**
     * 
     * @type {number}
     * @memberof MachineCreateSaleModel
     */
    operatingHeight?: number | null;
    /**
     * 
     * @type {number}
     * @memberof MachineCreateSaleModel
     */
    liftingHeight?: number | null;
    /**
     * 
     * @type {number}
     * @memberof MachineCreateSaleModel
     */
    operatingArea?: number | null;
    /**
     * 
     * @type {number}
     * @memberof MachineCreateSaleModel
     */
    operatingLength?: number | null;
    /**
     * 
     * @type {number}
     * @memberof MachineCreateSaleModel
     */
    operationWidth?: number | null;
    /**
     * 
     * @type {number}
     * @memberof MachineCreateSaleModel
     */
    operatingCapacity?: number | null;
    /**
     * 
     * @type {string}
     * @memberof MachineCreateSaleModel
     */
    usageFields?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof MachineCreateSaleModel
     */
    hasCabin?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof MachineCreateSaleModel
     */
    engineBrand?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof MachineCreateSaleModel
     */
    hasPlatform?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof MachineCreateSaleModel
     */
    hasTowerElevationSystem?: boolean | null;
    /**
     * 
     * @type {number}
     * @memberof MachineCreateSaleModel
     */
    lightningCapacity?: number | null;
    /**
     * 
     * @type {number}
     * @memberof MachineCreateSaleModel
     */
    carryingCapacity?: number | null;
    /**
     * 
     * @type {number}
     * @memberof MachineCreateSaleModel
     */
    width?: number | null;
    /**
     * 
     * @type {number}
     * @memberof MachineCreateSaleModel
     */
    volume?: number | null;
    /**
     * 
     * @type {number}
     * @memberof MachineCreateSaleModel
     */
    height?: number | null;
    /**
     * 
     * @type {number}
     * @memberof MachineCreateSaleModel
     */
    floorCount?: number | null;
    /**
     * 
     * @type {string}
     * @memberof MachineCreateSaleModel
     */
    saleNotes?: string | null;
    /**
     * 
     * @type {number}
     * @memberof MachineCreateSaleModel
     */
    bladeWidth?: number | null;
    /**
     * 
     * @type {number}
     * @memberof MachineCreateSaleModel
     */
    frequency?: number | null;
    /**
     * 
     * @type {number}
     * @memberof MachineCreateSaleModel
     */
    minPower?: number | null;
    /**
     * 
     * @type {number}
     * @memberof MachineCreateSaleModel
     */
    maxPower?: number | null;
    /**
     * 
     * @type {number}
     * @memberof MachineCreateSaleModel
     */
    maxLoadCapacity?: number | null;
    /**
     * 
     * @type {number}
     * @memberof MachineCreateSaleModel
     */
    nominalCapacity?: number | null;
    /**
     * 
     * @type {number}
     * @memberof MachineCreateSaleModel
     */
    waterTankCapacity?: number | null;
    /**
     * 
     * @type {number}
     * @memberof MachineCreateSaleModel
     */
    maxFeedingSize?: number | null;
    /**
     * 
     * @type {number}
     * @memberof MachineCreateSaleModel
     */
    workingSpeed?: number | null;
    /**
     * 
     * @type {number}
     * @memberof MachineCreateSaleModel
     */
    cleaningCapacity?: number | null;
    /**
     * 
     * @type {number}
     * @memberof MachineCreateSaleModel
     */
    garbageCollectionBin?: number | null;
    /**
     * 
     * @type {number}
     * @memberof MachineCreateSaleModel
     */
    breakingInfo?: number | null;
    /**
     * 
     * @type {number}
     * @memberof MachineCreateSaleModel
     */
    bomLength?: number | null;
    /**
     * 
     * @type {number}
     * @memberof MachineCreateSaleModel
     */
    maxHeight?: number | null;
    /**
     * 
     * @type {number}
     * @memberof MachineCreateSaleModel
     */
    maxDepth?: number | null;
    /**
     * 
     * @type {number}
     * @memberof MachineCreateSaleModel
     */
    maxWidth?: number | null;
    /**
     * 
     * @type {number}
     * @memberof MachineCreateSaleModel
     */
    compressionWidth?: number | null;
    /**
     * 
     * @type {number}
     * @memberof MachineCreateSaleModel
     */
    platformLength?: number | null;
    /**
     * 
     * @type {number}
     * @memberof MachineCreateSaleModel
     */
    platformWidth?: number | null;
    /**
     * 
     * @type {number}
     * @memberof MachineCreateSaleModel
     */
    concretePumpingCapacity?: number | null;
    /**
     * 
     * @type {number}
     * @memberof MachineCreateSaleModel
     */
    maxPressure?: number | null;
    /**
     * 
     * @type {number}
     * @memberof MachineCreateSaleModel
     */
    freeAirOutput?: number | null;
    /**
     * 
     * @type {number}
     * @memberof MachineCreateSaleModel
     */
    spraySpeed?: number | null;
    /**
     * 
     * @type {number}
     * @memberof MachineCreateSaleModel
     */
    tankVolume?: number | null;
    /**
     * 
     * @type {number}
     * @memberof MachineCreateSaleModel
     */
    loadCapacity?: number | null;
    /**
     * 
     * @type {number}
     * @memberof MachineCreateSaleModel
     */
    cameraResolutionId?: number | null;
    /**
     * 
     * @type {number}
     * @memberof MachineCreateSaleModel
     */
    flightTime?: number | null;
    /**
     * 
     * @type {number}
     * @memberof MachineCreateSaleModel
     */
    flightDistance?: number | null;
    /**
     * 
     * @type {number}
     * @memberof MachineCreateSaleModel
     */
    workingRadius?: number | null;
    /**
     * 
     * @type {number}
     * @memberof MachineCreateSaleModel
     */
    machineId?: number;
    /**
     * 
     * @type {number}
     * @memberof MachineCreateSaleModel
     */
    machineSalePrice?: number | null;
    /**
     * 
     * @type {number}
     * @memberof MachineCreateSaleModel
     */
    machineSalePriceCurrencyId?: number | null;
    /**
     * 
     * @type {number}
     * @memberof MachineCreateSaleModel
     */
    machineLocationCityId?: number | null;
    /**
     * 
     * @type {string}
     * @memberof MachineCreateSaleModel
     */
    saleAdTitle?: string | null;
    /**
     * 
     * @type {Array<MachineDocumentCreateModel>}
     * @memberof MachineCreateSaleModel
     */
    documents?: Array<MachineDocumentCreateModel> | null;
}

export function MachineCreateSaleModelFromJSON(json: any): MachineCreateSaleModel {
    return MachineCreateSaleModelFromJSONTyped(json, false);
}

export function MachineCreateSaleModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): MachineCreateSaleModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'lessorCompanyId': !exists(json, 'lessorCompanyId') ? undefined : json['lessorCompanyId'],
        'subCategoryId': !exists(json, 'subCategoryId') ? undefined : json['subCategoryId'],
        'brandId': !exists(json, 'brandId') ? undefined : json['brandId'],
        'brandOther': !exists(json, 'brandOther') ? undefined : json['brandOther'],
        'modelName': !exists(json, 'modelName') ? undefined : json['modelName'],
        'productionYear': !exists(json, 'productionYear') ? undefined : json['productionYear'],
        'operationMileage': !exists(json, 'operationMileage') ? undefined : json['operationMileage'],
        'operationHour': !exists(json, 'operationHour') ? undefined : json['operationHour'],
        'drivingEquipment': !exists(json, 'drivingEquipment') ? undefined : json['drivingEquipment'],
        'enginePower': !exists(json, 'enginePower') ? undefined : json['enginePower'],
        'operatingWeight': !exists(json, 'operatingWeight') ? undefined : json['operatingWeight'],
        'tankCapacity': !exists(json, 'tankCapacity') ? undefined : json['tankCapacity'],
        'fuelTypeId': !exists(json, 'fuelTypeId') ? undefined : json['fuelTypeId'],
        'elevatorTypeId': !exists(json, 'elevatorTypeId') ? undefined : json['elevatorTypeId'],
        'operatingHeight': !exists(json, 'operatingHeight') ? undefined : json['operatingHeight'],
        'liftingHeight': !exists(json, 'liftingHeight') ? undefined : json['liftingHeight'],
        'operatingArea': !exists(json, 'operatingArea') ? undefined : json['operatingArea'],
        'operatingLength': !exists(json, 'operatingLength') ? undefined : json['operatingLength'],
        'operationWidth': !exists(json, 'operationWidth') ? undefined : json['operationWidth'],
        'operatingCapacity': !exists(json, 'operatingCapacity') ? undefined : json['operatingCapacity'],
        'usageFields': !exists(json, 'usageFields') ? undefined : json['usageFields'],
        'hasCabin': !exists(json, 'hasCabin') ? undefined : json['hasCabin'],
        'engineBrand': !exists(json, 'engineBrand') ? undefined : json['engineBrand'],
        'hasPlatform': !exists(json, 'hasPlatform') ? undefined : json['hasPlatform'],
        'hasTowerElevationSystem': !exists(json, 'hasTowerElevationSystem') ? undefined : json['hasTowerElevationSystem'],
        'lightningCapacity': !exists(json, 'lightningCapacity') ? undefined : json['lightningCapacity'],
        'carryingCapacity': !exists(json, 'carryingCapacity') ? undefined : json['carryingCapacity'],
        'width': !exists(json, 'width') ? undefined : json['width'],
        'volume': !exists(json, 'volume') ? undefined : json['volume'],
        'height': !exists(json, 'height') ? undefined : json['height'],
        'floorCount': !exists(json, 'floorCount') ? undefined : json['floorCount'],
        'saleNotes': !exists(json, 'saleNotes') ? undefined : json['saleNotes'],
        'bladeWidth': !exists(json, 'bladeWidth') ? undefined : json['bladeWidth'],
        'frequency': !exists(json, 'frequency') ? undefined : json['frequency'],
        'minPower': !exists(json, 'minPower') ? undefined : json['minPower'],
        'maxPower': !exists(json, 'maxPower') ? undefined : json['maxPower'],
        'maxLoadCapacity': !exists(json, 'maxLoadCapacity') ? undefined : json['maxLoadCapacity'],
        'nominalCapacity': !exists(json, 'nominalCapacity') ? undefined : json['nominalCapacity'],
        'waterTankCapacity': !exists(json, 'waterTankCapacity') ? undefined : json['waterTankCapacity'],
        'maxFeedingSize': !exists(json, 'maxFeedingSize') ? undefined : json['maxFeedingSize'],
        'workingSpeed': !exists(json, 'workingSpeed') ? undefined : json['workingSpeed'],
        'cleaningCapacity': !exists(json, 'cleaningCapacity') ? undefined : json['cleaningCapacity'],
        'garbageCollectionBin': !exists(json, 'garbageCollectionBin') ? undefined : json['garbageCollectionBin'],
        'breakingInfo': !exists(json, 'breakingInfo') ? undefined : json['breakingInfo'],
        'bomLength': !exists(json, 'bomLength') ? undefined : json['bomLength'],
        'maxHeight': !exists(json, 'maxHeight') ? undefined : json['maxHeight'],
        'maxDepth': !exists(json, 'maxDepth') ? undefined : json['maxDepth'],
        'maxWidth': !exists(json, 'maxWidth') ? undefined : json['maxWidth'],
        'compressionWidth': !exists(json, 'compressionWidth') ? undefined : json['compressionWidth'],
        'platformLength': !exists(json, 'platformLength') ? undefined : json['platformLength'],
        'platformWidth': !exists(json, 'platformWidth') ? undefined : json['platformWidth'],
        'concretePumpingCapacity': !exists(json, 'concretePumpingCapacity') ? undefined : json['concretePumpingCapacity'],
        'maxPressure': !exists(json, 'maxPressure') ? undefined : json['maxPressure'],
        'freeAirOutput': !exists(json, 'freeAirOutput') ? undefined : json['freeAirOutput'],
        'spraySpeed': !exists(json, 'spraySpeed') ? undefined : json['spraySpeed'],
        'tankVolume': !exists(json, 'tankVolume') ? undefined : json['tankVolume'],
        'loadCapacity': !exists(json, 'loadCapacity') ? undefined : json['loadCapacity'],
        'cameraResolutionId': !exists(json, 'cameraResolutionId') ? undefined : json['cameraResolutionId'],
        'flightTime': !exists(json, 'flightTime') ? undefined : json['flightTime'],
        'flightDistance': !exists(json, 'flightDistance') ? undefined : json['flightDistance'],
        'workingRadius': !exists(json, 'workingRadius') ? undefined : json['workingRadius'],
        'machineId': !exists(json, 'machineId') ? undefined : json['machineId'],
        'machineSalePrice': !exists(json, 'machineSalePrice') ? undefined : json['machineSalePrice'],
        'machineSalePriceCurrencyId': !exists(json, 'machineSalePriceCurrencyId') ? undefined : json['machineSalePriceCurrencyId'],
        'machineLocationCityId': !exists(json, 'machineLocationCityId') ? undefined : json['machineLocationCityId'],
        'saleAdTitle': !exists(json, 'saleAdTitle') ? undefined : json['saleAdTitle'],
        'documents': !exists(json, 'documents') ? undefined : (json['documents'] === null ? null : (json['documents'] as Array<any>).map(MachineDocumentCreateModelFromJSON)),
    };
}

export function MachineCreateSaleModelToJSON(value?: MachineCreateSaleModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'lessorCompanyId': value.lessorCompanyId,
        'subCategoryId': value.subCategoryId,
        'brandId': value.brandId,
        'brandOther': value.brandOther,
        'modelName': value.modelName,
        'productionYear': value.productionYear,
        'operationMileage': value.operationMileage,
        'operationHour': value.operationHour,
        'drivingEquipment': value.drivingEquipment,
        'enginePower': value.enginePower,
        'operatingWeight': value.operatingWeight,
        'tankCapacity': value.tankCapacity,
        'fuelTypeId': value.fuelTypeId,
        'elevatorTypeId': value.elevatorTypeId,
        'operatingHeight': value.operatingHeight,
        'liftingHeight': value.liftingHeight,
        'operatingArea': value.operatingArea,
        'operatingLength': value.operatingLength,
        'operationWidth': value.operationWidth,
        'operatingCapacity': value.operatingCapacity,
        'usageFields': value.usageFields,
        'hasCabin': value.hasCabin,
        'engineBrand': value.engineBrand,
        'hasPlatform': value.hasPlatform,
        'hasTowerElevationSystem': value.hasTowerElevationSystem,
        'lightningCapacity': value.lightningCapacity,
        'carryingCapacity': value.carryingCapacity,
        'width': value.width,
        'volume': value.volume,
        'height': value.height,
        'floorCount': value.floorCount,
        'saleNotes': value.saleNotes,
        'bladeWidth': value.bladeWidth,
        'frequency': value.frequency,
        'minPower': value.minPower,
        'maxPower': value.maxPower,
        'maxLoadCapacity': value.maxLoadCapacity,
        'nominalCapacity': value.nominalCapacity,
        'waterTankCapacity': value.waterTankCapacity,
        'maxFeedingSize': value.maxFeedingSize,
        'workingSpeed': value.workingSpeed,
        'cleaningCapacity': value.cleaningCapacity,
        'garbageCollectionBin': value.garbageCollectionBin,
        'breakingInfo': value.breakingInfo,
        'bomLength': value.bomLength,
        'maxHeight': value.maxHeight,
        'maxDepth': value.maxDepth,
        'maxWidth': value.maxWidth,
        'compressionWidth': value.compressionWidth,
        'platformLength': value.platformLength,
        'platformWidth': value.platformWidth,
        'concretePumpingCapacity': value.concretePumpingCapacity,
        'maxPressure': value.maxPressure,
        'freeAirOutput': value.freeAirOutput,
        'spraySpeed': value.spraySpeed,
        'tankVolume': value.tankVolume,
        'loadCapacity': value.loadCapacity,
        'cameraResolutionId': value.cameraResolutionId,
        'flightTime': value.flightTime,
        'flightDistance': value.flightDistance,
        'workingRadius': value.workingRadius,
        'machineId': value.machineId,
        'machineSalePrice': value.machineSalePrice,
        'machineSalePriceCurrencyId': value.machineSalePriceCurrencyId,
        'machineLocationCityId': value.machineLocationCityId,
        'saleAdTitle': value.saleAdTitle,
        'documents': value.documents === undefined ? undefined : (value.documents === null ? null : (value.documents as Array<any>).map(MachineDocumentCreateModelToJSON)),
    };
}

