import { t } from "../translate";
import PrimaryButton from "../components/atomics/primary-button";
import ModalLayout, { ModalLayoutProps } from "../components/layouts/modal-layout";
import styles from "./request-summary.module.scss";
import { LessorUserCreateViewModel } from "../service";
import { Col, Form, Input, Row } from "antd";
import { agreementsVersions } from "../store/agreements-versions";

type AddLessorUserModalProps = {
    onClick: (profileData: LessorUserCreateViewModel | undefined) => void;
} & ModalLayoutProps;

const AddLessorUserModal = (props: AddLessorUserModalProps) => {
    const [form] = Form.useForm();

    const onCreateUserClick = (values: LessorUserCreateViewModel | undefined) => {
        if (values) {
            values.agreementVersion = agreementsVersions.agreementVersion;
            values.kvkkVersion = agreementsVersions.kvkkVersion;
            values.kvkkInfoVersion = agreementsVersions.kvkkInfoVersion;
        }
        props.onClick(values);
    };

    return (
        <ModalLayout show={props.show} onCloseClick={props.onCloseClick}>
            <h3 style={{ textAlign: "center", marginBottom: "1rem" }}>{t("lessor-profile.addUser.title")}</h3>
            <div className={styles.lightboxContent}>
                <Form name="update-profile" form={form} onFinish={onCreateUserClick} layout="vertical">
                    <Row>
                        <Col xs={24} md={{offset:6,span:12}}>
                            <Form.Item label={t("lessor-profile.update.nameLabel")} name="name"
                               rules={[
                                {pattern: /^[a-zA-ZğüşıöçĞÜŞİÖÇ\s]*$/,message: t('add-company-user-modal.name-field.message')}
                            ]}
                            >
                                <Input />
                            </Form.Item>
                        </Col>
                    
                        <Col xs={24} md={{offset:6,span:12}}>
                            <Form.Item label={t("lessor-profile.update.lastnameLabel")} name="lastname"
                               rules={[
                                {pattern: /^[a-zA-ZğüşıöçĞÜŞİÖÇ\s]*$/,message: t('add-company-user-modal.surname-field.message')}
                            ]}
                            >
                                <Input/>
                            </Form.Item>
                        </Col>
                        <Col xs={24} md={{offset:6,span:12}}>
                            <Form.Item label={t("lessor-profile.update.phoneLabel")} name="phone">
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col xs={24} md={{offset:6,span:12}}>
                            <Form.Item label={t("lessor-profile.update.emailLabel")} name="email">
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col xs={24} md={{offset:6,span:12}}>
                            <Form.Item label={t("lessor-profile.update.titleLabel")} name="title">
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col xs={24} md={{offset:6,span:12}}>
                            <PrimaryButton type="primary" htmlType="submit">
                                {t("common.save")}
                            </PrimaryButton>
                        </Col>
                    </Row>
                </Form>
            </div>
        </ModalLayout>
    );
};

export default AddLessorUserModal;
