/* tslint:disable */
/* eslint-disable */
/**
 * MachineGo P1 API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    BasicModel,
    BasicModelFromJSON,
    BasicModelFromJSONTyped,
    BasicModelToJSON,
} from './BasicModel';
import {
    DistrictModel,
    DistrictModelFromJSON,
    DistrictModelFromJSONTyped,
    DistrictModelToJSON,
} from './DistrictModel';
import {
    DocumentModel,
    DocumentModelFromJSON,
    DocumentModelFromJSONTyped,
    DocumentModelToJSON,
} from './DocumentModel';
import {
    RequestFormAdditionalServices,
    RequestFormAdditionalServicesFromJSON,
    RequestFormAdditionalServicesFromJSONTyped,
    RequestFormAdditionalServicesToJSON,
} from './RequestFormAdditionalServices';
import {
    RequestFormMachineFeatures,
    RequestFormMachineFeaturesFromJSON,
    RequestFormMachineFeaturesFromJSONTyped,
    RequestFormMachineFeaturesToJSON,
} from './RequestFormMachineFeatures';
import {
    SubCategoryModel,
    SubCategoryModelFromJSON,
    SubCategoryModelFromJSONTyped,
    SubCategoryModelToJSON,
} from './SubCategoryModel';

/**
 * 
 * @export
 * @interface RequestFormOfferViewModel
 */
export interface RequestFormOfferViewModel {
    /**
     * 
     * @type {number}
     * @memberof RequestFormOfferViewModel
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof RequestFormOfferViewModel
     */
    companyName?: string | null;
    /**
     * 
     * @type {number}
     * @memberof RequestFormOfferViewModel
     */
    renterUserId?: number;
    /**
     * 
     * @type {SubCategoryModel}
     * @memberof RequestFormOfferViewModel
     */
    subCategory?: SubCategoryModel;
    /**
     * 
     * @type {number}
     * @memberof RequestFormOfferViewModel
     */
    machineId?: number | null;
    /**
     * 
     * @type {Array<BasicModel>}
     * @memberof RequestFormOfferViewModel
     */
    attachments?: Array<BasicModel> | null;
    /**
     * 
     * @type {string}
     * @memberof RequestFormOfferViewModel
     */
    attachmentsOther?: string | null;
    /**
     * 
     * @type {DistrictModel}
     * @memberof RequestFormOfferViewModel
     */
    district?: DistrictModel;
    /**
     * 
     * @type {Date}
     * @memberof RequestFormOfferViewModel
     */
    requestDateFrom?: Date;
    /**
     * 
     * @type {Date}
     * @memberof RequestFormOfferViewModel
     */
    requestDateTo?: Date;
    /**
     * 
     * @type {number}
     * @memberof RequestFormOfferViewModel
     */
    machineCount?: number;
    /**
     * 
     * @type {number}
     * @memberof RequestFormOfferViewModel
     */
    availableMachineCount?: number;
    /**
     * 
     * @type {number}
     * @memberof RequestFormOfferViewModel
     */
    dailyWorkingTime?: number;
    /**
     * 
     * @type {RequestFormMachineFeatures}
     * @memberof RequestFormOfferViewModel
     */
    machineFeatures?: RequestFormMachineFeatures;
    /**
     * 
     * @type {RequestFormAdditionalServices}
     * @memberof RequestFormOfferViewModel
     */
    additionalServices?: RequestFormAdditionalServices;
    /**
     * 
     * @type {string}
     * @memberof RequestFormOfferViewModel
     */
    status?: string | null;
    /**
     * 
     * @type {string}
     * @memberof RequestFormOfferViewModel
     */
    description?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof RequestFormOfferViewModel
     */
    creationDate?: Date;
    /**
     * 
     * @type {Array<DocumentModel>}
     * @memberof RequestFormOfferViewModel
     */
    documents?: Array<DocumentModel> | null;
}

export function RequestFormOfferViewModelFromJSON(json: any): RequestFormOfferViewModel {
    return RequestFormOfferViewModelFromJSONTyped(json, false);
}

export function RequestFormOfferViewModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): RequestFormOfferViewModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'companyName': !exists(json, 'companyName') ? undefined : json['companyName'],
        'renterUserId': !exists(json, 'renterUserId') ? undefined : json['renterUserId'],
        'subCategory': !exists(json, 'subCategory') ? undefined : SubCategoryModelFromJSON(json['subCategory']),
        'machineId': !exists(json, 'machineId') ? undefined : json['machineId'],
        'attachments': !exists(json, 'attachments') ? undefined : (json['attachments'] === null ? null : (json['attachments'] as Array<any>).map(BasicModelFromJSON)),
        'attachmentsOther': !exists(json, 'attachmentsOther') ? undefined : json['attachmentsOther'],
        'district': !exists(json, 'district') ? undefined : DistrictModelFromJSON(json['district']),
        'requestDateFrom': !exists(json, 'requestDateFrom') ? undefined : (new Date(json['requestDateFrom'])),
        'requestDateTo': !exists(json, 'requestDateTo') ? undefined : (new Date(json['requestDateTo'])),
        'machineCount': !exists(json, 'machineCount') ? undefined : json['machineCount'],
        'availableMachineCount': !exists(json, 'availableMachineCount') ? undefined : json['availableMachineCount'],
        'dailyWorkingTime': !exists(json, 'dailyWorkingTime') ? undefined : json['dailyWorkingTime'],
        'machineFeatures': !exists(json, 'machineFeatures') ? undefined : RequestFormMachineFeaturesFromJSON(json['machineFeatures']),
        'additionalServices': !exists(json, 'additionalServices') ? undefined : RequestFormAdditionalServicesFromJSON(json['additionalServices']),
        'status': !exists(json, 'status') ? undefined : json['status'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'creationDate': !exists(json, 'creationDate') ? undefined : (new Date(json['creationDate'])),
        'documents': !exists(json, 'documents') ? undefined : (json['documents'] === null ? null : (json['documents'] as Array<any>).map(DocumentModelFromJSON)),
    };
}

export function RequestFormOfferViewModelToJSON(value?: RequestFormOfferViewModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'companyName': value.companyName,
        'renterUserId': value.renterUserId,
        'subCategory': SubCategoryModelToJSON(value.subCategory),
        'machineId': value.machineId,
        'attachments': value.attachments === undefined ? undefined : (value.attachments === null ? null : (value.attachments as Array<any>).map(BasicModelToJSON)),
        'attachmentsOther': value.attachmentsOther,
        'district': DistrictModelToJSON(value.district),
        'requestDateFrom': value.requestDateFrom === undefined ? undefined : (value.requestDateFrom.toISOString()),
        'requestDateTo': value.requestDateTo === undefined ? undefined : (value.requestDateTo.toISOString()),
        'machineCount': value.machineCount,
        'availableMachineCount': value.availableMachineCount,
        'dailyWorkingTime': value.dailyWorkingTime,
        'machineFeatures': RequestFormMachineFeaturesToJSON(value.machineFeatures),
        'additionalServices': RequestFormAdditionalServicesToJSON(value.additionalServices),
        'status': value.status,
        'description': value.description,
        'creationDate': value.creationDate === undefined ? undefined : (value.creationDate.toISOString()),
        'documents': value.documents === undefined ? undefined : (value.documents === null ? null : (value.documents as Array<any>).map(DocumentModelToJSON)),
    };
}

