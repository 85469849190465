/* tslint:disable */
/* eslint-disable */
/**
 * MachineGo P1 API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface RequestFormMachineFeatures
 */
export interface RequestFormMachineFeatures {
    /**
     * 
     * @type {number}
     * @memberof RequestFormMachineFeatures
     */
    compressionWidth?: number | null;
    /**
     * 
     * @type {number}
     * @memberof RequestFormMachineFeatures
     */
    operatingWeight?: number | null;
    /**
     * 
     * @type {number}
     * @memberof RequestFormMachineFeatures
     */
    spraySpeed?: number | null;
    /**
     * 
     * @type {number}
     * @memberof RequestFormMachineFeatures
     */
    tankVolume?: number | null;
    /**
     * 
     * @type {number}
     * @memberof RequestFormMachineFeatures
     */
    maxFeedingSize?: number | null;
    /**
     * 
     * @type {number}
     * @memberof RequestFormMachineFeatures
     */
    waterTankCapacity?: number | null;
    /**
     * 
     * @type {number}
     * @memberof RequestFormMachineFeatures
     */
    height?: number | null;
    /**
     * 
     * @type {number}
     * @memberof RequestFormMachineFeatures
     */
    maxDepth?: number | null;
    /**
     * 
     * @type {number}
     * @memberof RequestFormMachineFeatures
     */
    maxWidth?: number | null;
    /**
     * 
     * @type {number}
     * @memberof RequestFormMachineFeatures
     */
    concretePumpingCapacity?: number | null;
    /**
     * 
     * @type {number}
     * @memberof RequestFormMachineFeatures
     */
    maxPressure?: number | null;
    /**
     * 
     * @type {number}
     * @memberof RequestFormMachineFeatures
     */
    loadCapacity?: number | null;
    /**
     * 
     * @type {number}
     * @memberof RequestFormMachineFeatures
     */
    bladeWidth?: number | null;
    /**
     * 
     * @type {number}
     * @memberof RequestFormMachineFeatures
     */
    frequency?: number | null;
    /**
     * 
     * @type {number}
     * @memberof RequestFormMachineFeatures
     */
    maxPower?: number | null;
    /**
     * 
     * @type {number}
     * @memberof RequestFormMachineFeatures
     */
    freeAirOutput?: number | null;
    /**
     * 
     * @type {number}
     * @memberof RequestFormMachineFeatures
     */
    width?: number | null;
    /**
     * 
     * @type {number}
     * @memberof RequestFormMachineFeatures
     */
    volume?: number | null;
    /**
     * 
     * @type {number}
     * @memberof RequestFormMachineFeatures
     */
    workingRadius?: number | null;
    /**
     * 
     * @type {number}
     * @memberof RequestFormMachineFeatures
     */
    maxLoadCapacity?: number | null;
    /**
     * 
     * @type {number}
     * @memberof RequestFormMachineFeatures
     */
    enginePower?: number | null;
    /**
     * 
     * @type {number}
     * @memberof RequestFormMachineFeatures
     */
    workingSpeed?: number | null;
    /**
     * 
     * @type {number}
     * @memberof RequestFormMachineFeatures
     */
    operationWidth?: number | null;
    /**
     * 
     * @type {number}
     * @memberof RequestFormMachineFeatures
     */
    garbageCollectionBin?: number | null;
    /**
     * 
     * @type {number}
     * @memberof RequestFormMachineFeatures
     */
    cleaningCapacity?: number | null;
    /**
     * 
     * @type {number}
     * @memberof RequestFormMachineFeatures
     */
    flightDistance?: number | null;
    /**
     * 
     * @type {number}
     * @memberof RequestFormMachineFeatures
     */
    cameraResolutionId?: number | null;
    /**
     * 
     * @type {number}
     * @memberof RequestFormMachineFeatures
     */
    maxHeight?: number | null;
    /**
     * 
     * @type {number}
     * @memberof RequestFormMachineFeatures
     */
    operatingHeight?: number | null;
    /**
     * 
     * @type {number}
     * @memberof RequestFormMachineFeatures
     */
    nominalCapacity?: number | null;
    /**
     * 
     * @type {number}
     * @memberof RequestFormMachineFeatures
     */
    fuelTypeId?: number | null;
    /**
     * 
     * @type {number}
     * @memberof RequestFormMachineFeatures
     */
    flightTime?: number | null;
    /**
     * 
     * @type {number}
     * @memberof RequestFormMachineFeatures
     */
    tankCapacity?: number | null;
    /**
     * 
     * @type {number}
     * @memberof RequestFormMachineFeatures
     */
    minPower?: number | null;
    /**
     * 
     * @type {number}
     * @memberof RequestFormMachineFeatures
     */
    carryingCapacity?: number | null;
}

export function RequestFormMachineFeaturesFromJSON(json: any): RequestFormMachineFeatures {
    return RequestFormMachineFeaturesFromJSONTyped(json, false);
}

export function RequestFormMachineFeaturesFromJSONTyped(json: any, ignoreDiscriminator: boolean): RequestFormMachineFeatures {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'compressionWidth': !exists(json, 'compressionWidth') ? undefined : json['compressionWidth'],
        'operatingWeight': !exists(json, 'operatingWeight') ? undefined : json['operatingWeight'],
        'spraySpeed': !exists(json, 'spraySpeed') ? undefined : json['spraySpeed'],
        'tankVolume': !exists(json, 'tankVolume') ? undefined : json['tankVolume'],
        'maxFeedingSize': !exists(json, 'maxFeedingSize') ? undefined : json['maxFeedingSize'],
        'waterTankCapacity': !exists(json, 'waterTankCapacity') ? undefined : json['waterTankCapacity'],
        'height': !exists(json, 'height') ? undefined : json['height'],
        'maxDepth': !exists(json, 'maxDepth') ? undefined : json['maxDepth'],
        'maxWidth': !exists(json, 'maxWidth') ? undefined : json['maxWidth'],
        'concretePumpingCapacity': !exists(json, 'concretePumpingCapacity') ? undefined : json['concretePumpingCapacity'],
        'maxPressure': !exists(json, 'maxPressure') ? undefined : json['maxPressure'],
        'loadCapacity': !exists(json, 'loadCapacity') ? undefined : json['loadCapacity'],
        'bladeWidth': !exists(json, 'bladeWidth') ? undefined : json['bladeWidth'],
        'frequency': !exists(json, 'frequency') ? undefined : json['frequency'],
        'maxPower': !exists(json, 'maxPower') ? undefined : json['maxPower'],
        'freeAirOutput': !exists(json, 'freeAirOutput') ? undefined : json['freeAirOutput'],
        'width': !exists(json, 'width') ? undefined : json['width'],
        'volume': !exists(json, 'volume') ? undefined : json['volume'],
        'workingRadius': !exists(json, 'workingRadius') ? undefined : json['workingRadius'],
        'maxLoadCapacity': !exists(json, 'maxLoadCapacity') ? undefined : json['maxLoadCapacity'],
        'enginePower': !exists(json, 'enginePower') ? undefined : json['enginePower'],
        'workingSpeed': !exists(json, 'workingSpeed') ? undefined : json['workingSpeed'],
        'operationWidth': !exists(json, 'operationWidth') ? undefined : json['operationWidth'],
        'garbageCollectionBin': !exists(json, 'garbageCollectionBin') ? undefined : json['garbageCollectionBin'],
        'cleaningCapacity': !exists(json, 'cleaningCapacity') ? undefined : json['cleaningCapacity'],
        'flightDistance': !exists(json, 'flightDistance') ? undefined : json['flightDistance'],
        'cameraResolutionId': !exists(json, 'cameraResolutionId') ? undefined : json['cameraResolutionId'],
        'maxHeight': !exists(json, 'maxHeight') ? undefined : json['maxHeight'],
        'operatingHeight': !exists(json, 'operatingHeight') ? undefined : json['operatingHeight'],
        'nominalCapacity': !exists(json, 'nominalCapacity') ? undefined : json['nominalCapacity'],
        'fuelTypeId': !exists(json, 'fuelTypeId') ? undefined : json['fuelTypeId'],
        'flightTime': !exists(json, 'flightTime') ? undefined : json['flightTime'],
        'tankCapacity': !exists(json, 'tankCapacity') ? undefined : json['tankCapacity'],
        'minPower': !exists(json, 'minPower') ? undefined : json['minPower'],
        'carryingCapacity': !exists(json, 'carryingCapacity') ? undefined : json['carryingCapacity'],
    };
}

export function RequestFormMachineFeaturesToJSON(value?: RequestFormMachineFeatures | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'compressionWidth': value.compressionWidth,
        'operatingWeight': value.operatingWeight,
        'spraySpeed': value.spraySpeed,
        'tankVolume': value.tankVolume,
        'maxFeedingSize': value.maxFeedingSize,
        'waterTankCapacity': value.waterTankCapacity,
        'height': value.height,
        'maxDepth': value.maxDepth,
        'maxWidth': value.maxWidth,
        'concretePumpingCapacity': value.concretePumpingCapacity,
        'maxPressure': value.maxPressure,
        'loadCapacity': value.loadCapacity,
        'bladeWidth': value.bladeWidth,
        'frequency': value.frequency,
        'maxPower': value.maxPower,
        'freeAirOutput': value.freeAirOutput,
        'width': value.width,
        'volume': value.volume,
        'workingRadius': value.workingRadius,
        'maxLoadCapacity': value.maxLoadCapacity,
        'enginePower': value.enginePower,
        'workingSpeed': value.workingSpeed,
        'operationWidth': value.operationWidth,
        'garbageCollectionBin': value.garbageCollectionBin,
        'cleaningCapacity': value.cleaningCapacity,
        'flightDistance': value.flightDistance,
        'cameraResolutionId': value.cameraResolutionId,
        'maxHeight': value.maxHeight,
        'operatingHeight': value.operatingHeight,
        'nominalCapacity': value.nominalCapacity,
        'fuelTypeId': value.fuelTypeId,
        'flightTime': value.flightTime,
        'tankCapacity': value.tankCapacity,
        'minPower': value.minPower,
        'carryingCapacity': value.carryingCapacity,
    };
}

