/* tslint:disable */
/* eslint-disable */
/**
 * MachineGo P1 API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    BasicModel,
    BasicModelFromJSON,
    BasicModelToJSON,
    ErrorModel,
    ErrorModelFromJSON,
    ErrorModelToJSON,
    MachineApprovalModel,
    MachineApprovalModelFromJSON,
    MachineApprovalModelToJSON,
    MachineCreateModel,
    MachineCreateModelFromJSON,
    MachineCreateModelToJSON,
    MachineCreateSaleModel,
    MachineCreateSaleModelFromJSON,
    MachineCreateSaleModelToJSON,
    MachineDetailModel,
    MachineDetailModelFromJSON,
    MachineDetailModelToJSON,
    MachineDetailModelPagedDataModel,
    MachineDetailModelPagedDataModelFromJSON,
    MachineDetailModelPagedDataModelToJSON,
    MachineEditModel,
    MachineEditModelFromJSON,
    MachineEditModelToJSON,
    MachineListModelPagedDataModel,
    MachineListModelPagedDataModelFromJSON,
    MachineListModelPagedDataModelToJSON,
    MachineListSearchModel,
    MachineListSearchModelFromJSON,
    MachineListSearchModelToJSON,
    MachineSaleListModelPagedDataModel,
    MachineSaleListModelPagedDataModelFromJSON,
    MachineSaleListModelPagedDataModelToJSON,
    MachineSaleListSearchModel,
    MachineSaleListSearchModelFromJSON,
    MachineSaleListSearchModelToJSON,
    MachineSearchModel,
    MachineSearchModelFromJSON,
    MachineSearchModelToJSON,
    OfferFormMachineDetailViewModel,
    OfferFormMachineDetailViewModelFromJSON,
    OfferFormMachineDetailViewModelToJSON,
} from '../models';

export interface IdsDeleteRequest {
    requestBody?: Array<number>;
}

export interface MachineApprovePostRequest {
    machineApprovalModel?: MachineApprovalModel;
}

export interface MachineApproveWithUpdatePostRequest {
    machineApprovalModel?: MachineApprovalModel;
}

export interface MachineCreatePostRequest {
    machineCreateModel?: MachineCreateModel;
}

export interface MachineCreateRentalFromSalePostRequest {
    machineEditModel?: MachineEditModel;
}

export interface MachineCreateSaleMachineFromRentalPostRequest {
    machineCreateSaleModel?: MachineCreateSaleModel;
}

export interface MachineCreateSaleMachineMachineIdPostRequest {
    machineId: number;
}

export interface MachineGetByApprovalApprovedPostRequest {
    approved: boolean;
    machineSearchModel?: MachineSearchModel;
}

export interface MachineGetBySaleApprovalApprovedPostRequest {
    approved: boolean;
    machineSearchModel?: MachineSearchModel;
}

export interface MachineGetSuitableMachinesForOfferOfferIdGetRequest {
    offerId: number;
}

export interface MachineIdDeleteRequest {
    id: number;
}

export interface MachineIdOfferFormsGetRequest {
    id: number;
}

export interface MachineIdRentalGetRequest {
    id: number;
    rental: number;
}

export interface MachineMachineIdOfferFormOfferFormIdGetRequest {
    machineId: number;
    offerFormId: number;
}

export interface MachineMachineSearchPostRequest {
    machineListSearchModel?: MachineListSearchModel;
}

export interface MachineMachineSearchShowcasePostRequest {
    machineListSearchModel?: MachineListSearchModel;
}

export interface MachineRemoveSaleMachineMachineIdPostRequest {
    machineId: number;
}

export interface MachineRepublishSaleMachineMachineIdGetRequest {
    machineId: number;
}

export interface MachineSaleApprovePostRequest {
    machineApprovalModel?: MachineApprovalModel;
}

export interface MachineSaleApproveWithUpdatePostRequest {
    machineApprovalModel?: MachineApprovalModel;
}

export interface MachineSaleIdDeleteRequest {
    id: number;
}

export interface MachineSaleIdGetRequest {
    id: number;
}

export interface MachineSaleMachineSearchPostRequest {
    machineSaleListSearchModel?: MachineSaleListSearchModel;
}

export interface MachineSaleUnapprovePostRequest {
    machineApprovalModel?: MachineApprovalModel;
}

export interface MachineSearchPostRequest {
    machineSearchModel?: MachineSearchModel;
}

export interface MachineUnapprovePostRequest {
    machineApprovalModel?: MachineApprovalModel;
}

export interface MachineUpdatePostRequest {
    machineEditModel?: MachineEditModel;
}

export interface MachineUpdateSaleMachinePostRequest {
    machineCreateSaleModel?: MachineCreateSaleModel;
}

/**
 * 
 */
export class MachineApi extends runtime.BaseAPI {

    /**
     * Admin
     */
    async idsDeleteRaw(requestParameters: IdsDeleteRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<MachineDetailModel>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/ids`,
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.requestBody,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => MachineDetailModelFromJSON(jsonValue));
    }

    /**
     * Admin
     */
    async idsDelete(requestParameters: IdsDeleteRequest = {}, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<MachineDetailModel> {
        const response = await this.idsDeleteRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Admin
     */
    async machineApprovePostRaw(requestParameters: MachineApprovePostRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<MachineDetailModelPagedDataModel>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/machine/approve`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: MachineApprovalModelToJSON(requestParameters.machineApprovalModel),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => MachineDetailModelPagedDataModelFromJSON(jsonValue));
    }

    /**
     * Admin
     */
    async machineApprovePost(requestParameters: MachineApprovePostRequest = {}, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<MachineDetailModelPagedDataModel> {
        const response = await this.machineApprovePostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Admin
     */
    async machineApproveWithUpdatePostRaw(requestParameters: MachineApproveWithUpdatePostRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<MachineDetailModelPagedDataModel>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/machine/approve-with-update`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: MachineApprovalModelToJSON(requestParameters.machineApprovalModel),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => MachineDetailModelPagedDataModelFromJSON(jsonValue));
    }

    /**
     * Admin
     */
    async machineApproveWithUpdatePost(requestParameters: MachineApproveWithUpdatePostRequest = {}, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<MachineDetailModelPagedDataModel> {
        const response = await this.machineApproveWithUpdatePostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Admin, Lessor
     */
    async machineCreatePostRaw(requestParameters: MachineCreatePostRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<MachineDetailModel>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/machine/create`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: MachineCreateModelToJSON(requestParameters.machineCreateModel),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => MachineDetailModelFromJSON(jsonValue));
    }

    /**
     * Admin, Lessor
     */
    async machineCreatePost(requestParameters: MachineCreatePostRequest = {}, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<MachineDetailModel> {
        const response = await this.machineCreatePostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Admin, Lessor
     */
    async machineCreateRentalFromSalePostRaw(requestParameters: MachineCreateRentalFromSalePostRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<MachineDetailModel>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/machine/create-rental-from-sale`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: MachineEditModelToJSON(requestParameters.machineEditModel),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => MachineDetailModelFromJSON(jsonValue));
    }

    /**
     * Admin, Lessor
     */
    async machineCreateRentalFromSalePost(requestParameters: MachineCreateRentalFromSalePostRequest = {}, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<MachineDetailModel> {
        const response = await this.machineCreateRentalFromSalePostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Admin, Lessor
     */
    async machineCreateSaleMachineFromRentalPostRaw(requestParameters: MachineCreateSaleMachineFromRentalPostRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<MachineDetailModel>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/machine/create-sale-machine-from-rental`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: MachineCreateSaleModelToJSON(requestParameters.machineCreateSaleModel),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => MachineDetailModelFromJSON(jsonValue));
    }

    /**
     * Admin, Lessor
     */
    async machineCreateSaleMachineFromRentalPost(requestParameters: MachineCreateSaleMachineFromRentalPostRequest = {}, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<MachineDetailModel> {
        const response = await this.machineCreateSaleMachineFromRentalPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Admin, Lessor
     */
    async machineCreateSaleMachineMachineIdPostRaw(requestParameters: MachineCreateSaleMachineMachineIdPostRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<MachineDetailModelPagedDataModel>> {
        if (requestParameters.machineId === null || requestParameters.machineId === undefined) {
            throw new runtime.RequiredError('machineId','Required parameter requestParameters.machineId was null or undefined when calling machineCreateSaleMachineMachineIdPost.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/machine/create-sale-machine/{machineId}`.replace(`{${"machineId"}}`, encodeURIComponent(String(requestParameters.machineId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => MachineDetailModelPagedDataModelFromJSON(jsonValue));
    }

    /**
     * Admin, Lessor
     */
    async machineCreateSaleMachineMachineIdPost(requestParameters: MachineCreateSaleMachineMachineIdPostRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<MachineDetailModelPagedDataModel> {
        const response = await this.machineCreateSaleMachineMachineIdPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Admin
     */
    async machineGetByApprovalApprovedPostRaw(requestParameters: MachineGetByApprovalApprovedPostRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<MachineDetailModelPagedDataModel>> {
        if (requestParameters.approved === null || requestParameters.approved === undefined) {
            throw new runtime.RequiredError('approved','Required parameter requestParameters.approved was null or undefined when calling machineGetByApprovalApprovedPost.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/machine/get-by-approval/{approved}`.replace(`{${"approved"}}`, encodeURIComponent(String(requestParameters.approved))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: MachineSearchModelToJSON(requestParameters.machineSearchModel),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => MachineDetailModelPagedDataModelFromJSON(jsonValue));
    }

    /**
     * Admin
     */
    async machineGetByApprovalApprovedPost(requestParameters: MachineGetByApprovalApprovedPostRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<MachineDetailModelPagedDataModel> {
        const response = await this.machineGetByApprovalApprovedPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Admin
     */
    async machineGetBySaleApprovalApprovedPostRaw(requestParameters: MachineGetBySaleApprovalApprovedPostRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<MachineDetailModelPagedDataModel>> {
        if (requestParameters.approved === null || requestParameters.approved === undefined) {
            throw new runtime.RequiredError('approved','Required parameter requestParameters.approved was null or undefined when calling machineGetBySaleApprovalApprovedPost.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/machine/get-by-sale-approval/{approved}`.replace(`{${"approved"}}`, encodeURIComponent(String(requestParameters.approved))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: MachineSearchModelToJSON(requestParameters.machineSearchModel),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => MachineDetailModelPagedDataModelFromJSON(jsonValue));
    }

    /**
     * Admin
     */
    async machineGetBySaleApprovalApprovedPost(requestParameters: MachineGetBySaleApprovalApprovedPostRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<MachineDetailModelPagedDataModel> {
        const response = await this.machineGetBySaleApprovalApprovedPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Lessor
     */
    async machineGetSuitableMachinesForOfferOfferIdGetRaw(requestParameters: MachineGetSuitableMachinesForOfferOfferIdGetRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<Array<BasicModel>>> {
        if (requestParameters.offerId === null || requestParameters.offerId === undefined) {
            throw new runtime.RequiredError('offerId','Required parameter requestParameters.offerId was null or undefined when calling machineGetSuitableMachinesForOfferOfferIdGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/machine/get-suitable-machines-for-offer/{offerId}`.replace(`{${"offerId"}}`, encodeURIComponent(String(requestParameters.offerId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(BasicModelFromJSON));
    }

    /**
     * Lessor
     */
    async machineGetSuitableMachinesForOfferOfferIdGet(requestParameters: MachineGetSuitableMachinesForOfferOfferIdGetRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<Array<BasicModel>> {
        const response = await this.machineGetSuitableMachinesForOfferOfferIdGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Admin, Lessor
     */
    async machineIdDeleteRaw(requestParameters: MachineIdDeleteRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<MachineDetailModel>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling machineIdDelete.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/machine/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => MachineDetailModelFromJSON(jsonValue));
    }

    /**
     * Admin, Lessor
     */
    async machineIdDelete(requestParameters: MachineIdDeleteRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<MachineDetailModel> {
        const response = await this.machineIdDeleteRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Admin, Lessor
     */
    async machineIdOfferFormsGetRaw(requestParameters: MachineIdOfferFormsGetRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<Array<OfferFormMachineDetailViewModel>>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling machineIdOfferFormsGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/machine/{id}/offer-forms`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(OfferFormMachineDetailViewModelFromJSON));
    }

    /**
     * Admin, Lessor
     */
    async machineIdOfferFormsGet(requestParameters: MachineIdOfferFormsGetRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<Array<OfferFormMachineDetailViewModel>> {
        const response = await this.machineIdOfferFormsGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async machineIdRentalGetRaw(requestParameters: MachineIdRentalGetRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<MachineDetailModel>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling machineIdRentalGet.');
        }

        if (requestParameters.rental === null || requestParameters.rental === undefined) {
            throw new runtime.RequiredError('rental','Required parameter requestParameters.rental was null or undefined when calling machineIdRentalGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/machine/{id}/{rental}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))).replace(`{${"rental"}}`, encodeURIComponent(String(requestParameters.rental))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => MachineDetailModelFromJSON(jsonValue));
    }

    /**
     */
    async machineIdRentalGet(requestParameters: MachineIdRentalGetRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<MachineDetailModel> {
        const response = await this.machineIdRentalGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Customer, Admin
     */
    async machineMachineIdOfferFormOfferFormIdGetRaw(requestParameters: MachineMachineIdOfferFormOfferFormIdGetRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<MachineDetailModel>> {
        if (requestParameters.machineId === null || requestParameters.machineId === undefined) {
            throw new runtime.RequiredError('machineId','Required parameter requestParameters.machineId was null or undefined when calling machineMachineIdOfferFormOfferFormIdGet.');
        }

        if (requestParameters.offerFormId === null || requestParameters.offerFormId === undefined) {
            throw new runtime.RequiredError('offerFormId','Required parameter requestParameters.offerFormId was null or undefined when calling machineMachineIdOfferFormOfferFormIdGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/machine/{machineId}/offer-form/{offerFormId}`.replace(`{${"machineId"}}`, encodeURIComponent(String(requestParameters.machineId))).replace(`{${"offerFormId"}}`, encodeURIComponent(String(requestParameters.offerFormId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => MachineDetailModelFromJSON(jsonValue));
    }

    /**
     * Customer, Admin
     */
    async machineMachineIdOfferFormOfferFormIdGet(requestParameters: MachineMachineIdOfferFormOfferFormIdGetRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<MachineDetailModel> {
        const response = await this.machineMachineIdOfferFormOfferFormIdGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async machineMachineSearchPostRaw(requestParameters: MachineMachineSearchPostRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<MachineListModelPagedDataModel>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/machine/machine-search`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: MachineListSearchModelToJSON(requestParameters.machineListSearchModel),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => MachineListModelPagedDataModelFromJSON(jsonValue));
    }

    /**
     */
    async machineMachineSearchPost(requestParameters: MachineMachineSearchPostRequest = {}, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<MachineListModelPagedDataModel> {
        const response = await this.machineMachineSearchPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async machineMachineSearchShowcasePostRaw(requestParameters: MachineMachineSearchShowcasePostRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<MachineListModelPagedDataModel>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/machine/machine-search-showcase`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: MachineListSearchModelToJSON(requestParameters.machineListSearchModel),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => MachineListModelPagedDataModelFromJSON(jsonValue));
    }

    /**
     */
    async machineMachineSearchShowcasePost(requestParameters: MachineMachineSearchShowcasePostRequest = {}, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<MachineListModelPagedDataModel> {
        const response = await this.machineMachineSearchShowcasePostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Admin, Lessor
     */
    async machineRemoveSaleMachineMachineIdPostRaw(requestParameters: MachineRemoveSaleMachineMachineIdPostRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<boolean>> {
        if (requestParameters.machineId === null || requestParameters.machineId === undefined) {
            throw new runtime.RequiredError('machineId','Required parameter requestParameters.machineId was null or undefined when calling machineRemoveSaleMachineMachineIdPost.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/machine/remove-sale-machine/{machineId}`.replace(`{${"machineId"}}`, encodeURIComponent(String(requestParameters.machineId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     * Admin, Lessor
     */
    async machineRemoveSaleMachineMachineIdPost(requestParameters: MachineRemoveSaleMachineMachineIdPostRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<boolean> {
        const response = await this.machineRemoveSaleMachineMachineIdPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Admin
     */
    async machineRepublishSaleMachineMachineIdGetRaw(requestParameters: MachineRepublishSaleMachineMachineIdGetRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.machineId === null || requestParameters.machineId === undefined) {
            throw new runtime.RequiredError('machineId','Required parameter requestParameters.machineId was null or undefined when calling machineRepublishSaleMachineMachineIdGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/machine/republish-sale-machine/{machineId}`.replace(`{${"machineId"}}`, encodeURIComponent(String(requestParameters.machineId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Admin
     */
    async machineRepublishSaleMachineMachineIdGet(requestParameters: MachineRepublishSaleMachineMachineIdGetRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<void> {
        await this.machineRepublishSaleMachineMachineIdGetRaw(requestParameters, initOverrides);
    }

    /**
     * Admin
     */
    async machineSaleApprovePostRaw(requestParameters: MachineSaleApprovePostRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<MachineDetailModelPagedDataModel>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/machine/sale-approve`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: MachineApprovalModelToJSON(requestParameters.machineApprovalModel),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => MachineDetailModelPagedDataModelFromJSON(jsonValue));
    }

    /**
     * Admin
     */
    async machineSaleApprovePost(requestParameters: MachineSaleApprovePostRequest = {}, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<MachineDetailModelPagedDataModel> {
        const response = await this.machineSaleApprovePostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Admin
     */
    async machineSaleApproveWithUpdatePostRaw(requestParameters: MachineSaleApproveWithUpdatePostRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<MachineDetailModelPagedDataModel>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/machine/sale-approve-with-update`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: MachineApprovalModelToJSON(requestParameters.machineApprovalModel),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => MachineDetailModelPagedDataModelFromJSON(jsonValue));
    }

    /**
     * Admin
     */
    async machineSaleApproveWithUpdatePost(requestParameters: MachineSaleApproveWithUpdatePostRequest = {}, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<MachineDetailModelPagedDataModel> {
        const response = await this.machineSaleApproveWithUpdatePostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Admin
     */
    async machineSaleIdDeleteRaw(requestParameters: MachineSaleIdDeleteRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<MachineDetailModel>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling machineSaleIdDelete.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/machine/sale/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => MachineDetailModelFromJSON(jsonValue));
    }

    /**
     * Admin
     */
    async machineSaleIdDelete(requestParameters: MachineSaleIdDeleteRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<MachineDetailModel> {
        const response = await this.machineSaleIdDeleteRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async machineSaleIdGetRaw(requestParameters: MachineSaleIdGetRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<MachineDetailModel>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling machineSaleIdGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/machine/sale/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => MachineDetailModelFromJSON(jsonValue));
    }

    /**
     */
    async machineSaleIdGet(requestParameters: MachineSaleIdGetRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<MachineDetailModel> {
        const response = await this.machineSaleIdGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async machineSaleMachineSearchPostRaw(requestParameters: MachineSaleMachineSearchPostRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<MachineSaleListModelPagedDataModel>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/machine/sale-machine-search`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: MachineSaleListSearchModelToJSON(requestParameters.machineSaleListSearchModel),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => MachineSaleListModelPagedDataModelFromJSON(jsonValue));
    }

    /**
     */
    async machineSaleMachineSearchPost(requestParameters: MachineSaleMachineSearchPostRequest = {}, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<MachineSaleListModelPagedDataModel> {
        const response = await this.machineSaleMachineSearchPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Admin, Lessor
     */
    async machineSaleUnapprovePostRaw(requestParameters: MachineSaleUnapprovePostRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<MachineDetailModelPagedDataModel>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/machine/sale-unapprove`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: MachineApprovalModelToJSON(requestParameters.machineApprovalModel),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => MachineDetailModelPagedDataModelFromJSON(jsonValue));
    }

    /**
     * Admin, Lessor
     */
    async machineSaleUnapprovePost(requestParameters: MachineSaleUnapprovePostRequest = {}, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<MachineDetailModelPagedDataModel> {
        const response = await this.machineSaleUnapprovePostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Admin, Lessor
     */
    async machineSearchPostRaw(requestParameters: MachineSearchPostRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<MachineDetailModelPagedDataModel>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/machine/search`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: MachineSearchModelToJSON(requestParameters.machineSearchModel),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => MachineDetailModelPagedDataModelFromJSON(jsonValue));
    }

    /**
     * Admin, Lessor
     */
    async machineSearchPost(requestParameters: MachineSearchPostRequest = {}, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<MachineDetailModelPagedDataModel> {
        const response = await this.machineSearchPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async machineSetMachineRanksManuallyGetRaw(initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/machine/set-machine-ranks-manually`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async machineSetMachineRanksManuallyGet(initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<void> {
        await this.machineSetMachineRanksManuallyGetRaw(initOverrides);
    }

    /**
     * Admin, Lessor
     */
    async machineUnapprovePostRaw(requestParameters: MachineUnapprovePostRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<MachineDetailModelPagedDataModel>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/machine/unapprove`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: MachineApprovalModelToJSON(requestParameters.machineApprovalModel),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => MachineDetailModelPagedDataModelFromJSON(jsonValue));
    }

    /**
     * Admin, Lessor
     */
    async machineUnapprovePost(requestParameters: MachineUnapprovePostRequest = {}, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<MachineDetailModelPagedDataModel> {
        const response = await this.machineUnapprovePostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Admin, Lessor
     */
    async machineUpdatePostRaw(requestParameters: MachineUpdatePostRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<MachineDetailModel>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/machine/update`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: MachineEditModelToJSON(requestParameters.machineEditModel),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => MachineDetailModelFromJSON(jsonValue));
    }

    /**
     * Admin, Lessor
     */
    async machineUpdatePost(requestParameters: MachineUpdatePostRequest = {}, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<MachineDetailModel> {
        const response = await this.machineUpdatePostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Admin, Lessor
     */
    async machineUpdateSaleMachinePostRaw(requestParameters: MachineUpdateSaleMachinePostRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<MachineDetailModel>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/machine/update-sale-machine`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: MachineCreateSaleModelToJSON(requestParameters.machineCreateSaleModel),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => MachineDetailModelFromJSON(jsonValue));
    }

    /**
     * Admin, Lessor
     */
    async machineUpdateSaleMachinePost(requestParameters: MachineUpdateSaleMachinePostRequest = {}, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<MachineDetailModel> {
        const response = await this.machineUpdateSaleMachinePostRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
