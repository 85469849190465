import { Col, Row } from "antd";
import { useAtom } from "jotai";
import { useEffect, useMemo, useState } from "react";
import DashboardRenterRequestBox from "../../components/containers/dashboard-renter-request-box";
import DashboardItemContainer from "../../components/containers/dashboard-item-container";
import SecureLayout from "../../components/layouts/secure-layout";
import { useQuery } from "../../helpers/use-query";
import { ReactComponent as EmptyWaitingWorks } from "../../components/assets/waiting-works-empty.svg";
import { ReactComponent as EmptyRequests } from "../../components/assets/requests-empty.svg";
import { ReactComponent as EmptyOffers } from "../../components/assets/offers-empty.svg";
import { ReactComponent as Plus } from "../../components/assets/plus-black.svg";
import { ReactComponent as EmptyUpcomingWorks } from "../../components/assets/upcoming-works-empty.svg";
import { ReactComponent as EmptyMachines } from "../../components/assets/machines-empty.svg";
import {
    extendedMachineDetailAtom,
    lessorPurposeAtom,
    loadingAtom,
    postloginMessageAtom,
    requestFormAtom,
    roleAtom,
    userAtom,
} from "../../store/global-atoms";
import { useSecureService } from "../../store/service-atoms";
import { t } from "../../translate";
import DashboardWaitingWorksBox from "../../components/containers/dashboard-waiting-works-box";
import DashboardIncomingRequestsBox from "../../components/containers/dashboard-incoming-requests-box";
import {
    CategoryModel,
    LessorCompanyAgreementsViewModel,
    LessorPurpose,
    OfferFormViewModelPagedDataModel,
    RenterCompanyAgreementsViewModel,
    RequestFormViewModelPagedDataModel,
} from "../../service";
import DashboardLessorOffersBox from "../../components/containers/dashboard-lessor-offers-box";
import DashboardLessorUpcomingWorksBox from "../../components/containers/dashboard-lessor-upcoming-works-box";
import DashboardMachinesBox from "../../components/containers/dashboard-machines-box";
import { SearchMachineModel } from "../../service/models/ExtendedMachineDetailModel";
import PrimaryButton from "../../components/atomics/primary-button";
import { useLocation, useNavigate } from "react-router-dom";
import { moment } from "../../helpers/moment";
import { activeRootSiteUrl } from "../../store/app-constants";
import styles from "./dashboard.module.scss";
import { agreementsVersions } from "../../store/agreements-versions";
import AgreementsUpdateModal from "../../modals/agreements-update-modal";
import DashboardLessorSaleMachineListCard from "../../components/containers/dashboard-lessor-sale-machines-box";

const Dashboard = () => {
    const query = useQuery();
    const [formRequestData, setFormRequestData] = useAtom(requestFormAtom);
    const fromRequest = query.get("request") ? true : false;

    const [role] = useAtom(roleAtom);
    const [user] = useAtom(userAtom);
    const [lessorPurpose] = useAtom(lessorPurposeAtom);
    const [loading] = useAtom(loadingAtom);
    const navigate = useNavigate();
    const [, editMachine] = useAtom(extendedMachineDetailAtom);
    const maxItemCount = 9;
    const [blogHtml, setBlogHtml] = useState("");
    const [, setAllCategories] = useState<undefined | CategoryModel[]>(undefined);

    const [requests, setRequests] = useState<RequestFormViewModelPagedDataModel>();

    const [suggestedOffers, setSuggestedOffers] = useState<OfferFormViewModelPagedDataModel>();
    const [allOffers, setAllOffers] = useState<OfferFormViewModelPagedDataModel>();
    const [upcomingOffers, setUpcomingOffers] = useState<OfferFormViewModelPagedDataModel>();
    const [upcomingRequests, setUpcomingRequests] = useState<RequestFormViewModelPagedDataModel>();
    const [machines, setMachines] = useState<SearchMachineModel>();
    const [saleMachines, setSaleMachines] = useState<SearchMachineModel>();
    const [offerBoxes, setOfferBoxes] = useState<any>([]);
    const [dataFetching, setDataFetching] = useState(true);

    const {
        doUpdateRequest,
        doSearchOffers,
        doSearchMachine,
        doSearchRequests,
        doGetUserDetails,
        doGetMachineSubcategory,
    } = useSecureService();
    const [, setMessage] = useAtom(postloginMessageAtom);
    const currentDate = new Date();
    const [showAgreementsUpdateModal, setShowAgreementsUpdateModal] = useState<boolean>(false);
    const [agreementsForUpdate, setAgreementsForUpdate] = useState<any>();

    useEffect(() => {
        const getCategoriesAndSubcategories = async () => {
            const result = await doGetMachineSubcategory();
            setAllCategories(result);
        };
        getCategoriesAndSubcategories();
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (user === undefined || user === null || user.id === null || user.id === undefined) {
            const getUserDetails = async () => {
                await doGetUserDetails();
            };

            getUserDetails();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user]);

    const isForSaleDashboard = useMemo(() => {
        return lessorPurpose === LessorPurpose.OnlyForSale;
    }, [lessorPurpose]);

    useEffect(() => {
        if (role === null) {
            return navigate("/login");
        }

        const updateRequest = async () => {
            const result = await doUpdateRequest({
                ...formRequestData,
            });
            setFormRequestData(undefined);
            if (result)
                setMessage({
                    message: t("request.update.success"),
                    type: "success",
                });
        };

        if (fromRequest && formRequestData) {
            updateRequest();
        }
        fetch(activeRootSiteUrl + "/frame/")
            .then((res) => {
                res.text().then((val) => {
                    // prettier-ignore
                    let friendlyHtml = val
                        .replaceAll("/static", activeRootSiteUrl + "/static")
                        .replaceAll("/blog", "/blog")
                        .replaceAll("var(--bs-body-font-family)", "\"Manrope\", sans-serif;")
                        .replaceAll("line-height:1.2", "")
                        .replaceAll("--bs-body-line-height:1.5", "--bs-body-line-height:1.5715");
                    setBlogHtml(friendlyHtml);
                });
            })
            .catch((err) => {
                console.log("\n\nerror: ", err);
            });

        //eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (role === null) return navigate("/login");

        const getRequests = async () => {
            const result = await doSearchRequests({
                pageNo: 1,
                pageSize: maxItemCount,
            });
            if (result) {
                setRequests(result);
            }
            setDataFetching(false);
        };

        const getIncomingRequests = async () => {
            // except -
            const result = await doSearchOffers({
                pageNo: 1,
                pageSize: maxItemCount,
                status: ["Suggested"],
            });
            if (result) {
                setSuggestedOffers(result);
            }
        };

        const getAllOffers = async () => {
            const result = await doSearchOffers({
                pageNo: 1,
                pageSize: maxItemCount,
                status: ["Offered", "Rejected", "Approved", "Accepted", "Expired", "Declined", "Deal", "PaymentDeclined"],
            });
            if (result) {
                setAllOffers(result);
            }
        };

        const getUpcomingOffers = async () => {
            const result = await doSearchOffers({
                pageNo: 1,
                pageSize: maxItemCount,
                requestDateFrom: currentDate,
                status: ["Deal"],
            });
            if (result) {
                setUpcomingOffers(result);
            }
        };

        const getUpcomingRequests = async () => {
            const result = await doSearchRequests({
                pageNo: 1,
                pageSize: maxItemCount,
                status: ["Offered", "Deal"],
            });
            if (result) {
                setUpcomingRequests(result);
            }
        };

        const getMachines = async () => {
            const result = await doSearchMachine({
                pageNo: 1,
                pageSize: maxItemCount,
            });
            if (result) {
                setMachines(result);
            }
            setDataFetching(false);
        };
        const getSaleMachines = async () => {
            const result = await doSearchMachine({
                pageNo: 1,
                pageSize: 16,
                filter: { onlySaleMachines: true },
                sortBy: "CreationDate",
            });
            if (result) {
                setSaleMachines(result);
            }
            setDataFetching(false);
        };
        const getUserDetails = async () => {
            await doGetUserDetails();
        };

        if (role === "customer") {
            getRequests();
            getUpcomingRequests();
        } else {
            getSaleMachines();
            getIncomingRequests();
            getAllOffers();
            getUpcomingOffers();
            getMachines();
        }

        getUserDetails();
        checkAgreements();
        //eslint-disable-next-line
    }, [role]);

    useEffect(() => {
        let offerBoxes: any[] = [];
        upcomingRequests?.data?.forEach((req) => {
            req.offerForms?.forEach((off) => {
                if (off.status === "Accepted" || off.status === "Deal") {
                    offerBoxes.push(
                        <DashboardWaitingWorksBox
                            offerForm={off}
                            catId={req.subCategory?.category?.id}
                            district={req.district?.name + " - " + req.district?.city?.name}
                            fromToDate={moment(req.requestDateFrom) + " - " + moment(req.requestDateTo)}
                            reqId={req.id}
                        />
                    );
                }
            });
        });
        setOfferBoxes(offerBoxes);
    }, [upcomingRequests]);

    useEffect(() => {
        setShowAgreementsUpdateModal(true);
    }, [agreementsForUpdate]);

    const checkAgreements = () => {
        if (role === "lessor" && user?.role === "Lessor") {
            let agreements = {} as LessorCompanyAgreementsViewModel;
            if (user?.agreementVersion !== agreementsVersions.agreementVersion) {
                agreements.agreementVersion = user?.agreementVersion;
            }
            if (user?.kvkkVersion !== agreementsVersions.kvkkVersion) {
                agreements.kvkkVersion = user?.kvkkVersion;
            }
            if (user?.kvkkInfoVersion !== agreementsVersions.kvkkInfoVersion) {
                agreements.kvkkInfoVersion = user?.kvkkInfoVersion;
            }
            console.log("Agreements|lessor: ", agreements);
            if (Object.values(agreements).length > 0 && !Object.values(agreements).every((o) => o === null)) {
                setAgreementsForUpdate(agreements);
            }
        } else if (role === "customer" && user?.role === "Customer") {
            let agreements = {} as RenterCompanyAgreementsViewModel;
            if (user?.agreementVersion !== agreementsVersions.agreementVersion) {
                agreements.agreementVersion = user?.agreementVersion;
            }
            if (user?.kvkkVersion !== agreementsVersions.kvkkVersion) {
                agreements.kvkkVersion = user?.kvkkVersion;
            }
            console.log("Agreements|renter: ", agreements);
            if (Object.values(agreements).length > 0 && !Object.values(agreements).every((o) => o === null)) {
                setAgreementsForUpdate(agreements);
            }
        }
    };

    type PageType = "dashboard" | "machines" | "requests" | "offers" | "requests-and-offers" | "reservations" | "lessor-sale-machines";
    const { pathname } = useLocation();
    const navigateToPage = (target: PageType) => {
        const pathParts = pathname.split("/");
        const slashCount = pathParts.length - 2;
        //let backCount = 0;
        if (slashCount > 0) {
            navigate("/" + target);
        } else navigate("/" + target, { replace: true });
        //     backCount = slashCount*-1;
        //     navigate(backCount);
        // }
    };
    let helperButtons = [];

    if (role === "customer") {
        helperButtons.push(
            <PrimaryButton loading={loading} onClick={() => navigate("/requests-and-offers/add")}>
                {t("requests-and-offers.list.createRequestForm")}
            </PrimaryButton>
        );
    }

    return (
        <SecureLayout loading={dataFetching} activePage={"dashboard"} title={t("dashboard.title")} description={t("dashboard.text")} helperButtons={helperButtons}>
            <Row gutter={[16, 16]}>            
                {
                    /* Taleplerim kutusu */ role === "customer" && (
                        <DashboardItemContainer
                            md={12}
                            title={t("dashboard.requestsBoxRenter.title")}
                            badgeNumber={requests?.itemCount}
                            showAllClick={() => {
                                navigateToPage("requests-and-offers");
                            }}
                        >
                            {requests?.data?.length !== 0 && requests?.data?.length !== undefined ? (
                                requests?.data?.map((req) => (
                                    <div className={styles.itemContainerStyle}>
                                        <DashboardRenterRequestBox requestForm={req} />
                                    </div>
                                ))
                            ) : (
                                <>
                                    <div className={styles.emptyContainerStyle}>
                                        <EmptyRequests />
                                        {t("dashboard.myRequests.emptyRequests")}
                                    </div>
                                </>
                            )}
                        </DashboardItemContainer>
                    )
                }
                {
                    /* Bekleyen İşlerim kutusu */ role === "customer" &&  (
                        <DashboardItemContainer
                            md={12}
                            title={t("dashboard.waitingWorks.title")}
                            badgeNumber={offerBoxes?.length}
                            showAllClick={() => {
                                navigateToPage("requests-and-offers");
                            }}
                        >
                            {
                                <div className={styles.itemContainerStyle}>
                                    {offerBoxes.length > 0 ? (
                                        offerBoxes
                                    ) : (
                                        <>
                                            <div className={styles.emptyContainerStyle}>
                                                <EmptyWaitingWorks />
                                                {t("dashboard.waitingWorks.emptyWaitingWorks")}
                                            </div>
                                        </>
                                    )}
                                </div>
                            }
                        </DashboardItemContainer>
                    )
                }
                {
                    /* Gelen Talepler kutusu */ role === "lessor" && !isForSaleDashboard &&  (
                        <DashboardItemContainer
                            md={6}
                            title={t("dashboard.incomingRequests.title")}
                            badgeNumber={suggestedOffers?.itemCount}
                            showAllClick={() => {
                                navigateToPage("requests");
                            }}
                        >
                            {suggestedOffers && suggestedOffers.data && suggestedOffers.data.length > 0 ? (
                                suggestedOffers.data.map((off) => (
                                    <div className={styles.itemContainerStyle}>
                                        <DashboardIncomingRequestsBox offerForm={off} />
                                    </div>
                                ))
                            ) : (
                                <>
                                    <div className={styles.emptyContainerStyle}>
                                        <EmptyRequests />
                                        {t("dashboard.incomingRequests.emptyRequests")}
                                    </div>
                                </>
                            )}
                        </DashboardItemContainer>
                    )
                }
                {
                    /* ;Makine sahibi satılık işlemlerim satılık makineler */
                    /*  */ role === "lessor" && isForSaleDashboard &&  (
                        <Row gutter={[8, 8]} style={{ justifyContent: "right", display: "flex", marginTop: "2rem", width: '100vw' }}>
                            <Row gutter={[8, 8]} style={{width: '100%'}}>
                                <h1 style={{ fontWeight: "800", fontSize: "1.5rem", marginRight: "auto" }}>
                                    {t("dashboard.machines.title")}
                                    {"(" + saleMachines?.itemCount + ")"}
                                </h1>
                                <Col xs={0} md={6}>
                                    <PrimaryButton
                                        loading={loading}
                                        onClick={() => {
                                            editMachine(undefined);
                                            navigate("/lessor-sale-machines");
                                        }}
                                        style={{ minWidth: "100%", background: "white" }}
                                    >
                                        {t("common.showAll")}
                                    </PrimaryButton>
                                </Col>
                                <Col xs={24} md={6}>
                                    <PrimaryButton
                                        loading={loading}
                                        onClick={() => {
                                            editMachine(undefined);
                                            navigate("/sale-machines/add?compid=" + user?.id);
                                        }}
                                        style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', minWidth: "240px" }}
                                    >
                                        <div>
                                            <Plus style={{ marginRight: "0.5rem" }} />
                                            {t("common.addNewSaleMachine")}
                                        </div>
                                    </PrimaryButton>
                                </Col>
                            </Row>
                            <Row style={{width: '100%'}}>
                                {saleMachines && saleMachines.machines && saleMachines.machines.length > 0 ? (
                                    <Row style={{width: '100%'}} gutter={[8, 8]}>
                                        {saleMachines?.machines?.map((salemac, index) => (
                                            <Col key={index} xs={24} md={8} xl={6}>
                                                <div className={styles.itemContainerStyle}>
                                                    <DashboardLessorSaleMachineListCard machine={salemac} />
                                                </div>
                                            </Col>
                                        ))}
                                    </Row>
                                ) : (
                                    <>
                                        <div className={styles.emptyContainerStyle}>{/* ... other code ... */}</div>
                                    </>
                                )}
                            </Row>
                            <Col xs={24} md={0}>
                                <PrimaryButton
                                    loading={loading}
                                    onClick={() => {
                                        editMachine(undefined);
                                        navigate("/lessor-sale-machines");
                                    }}
                                    style={{ minWidth: "100%", background: "white" }}
                                >
                                    {t("common.showAll")}
                                </PrimaryButton>
                            </Col>
                        </Row>
                    )
                }
                {
                    /* Tekliflerim kutusu */ role === "lessor" && !isForSaleDashboard &&  (
                        <DashboardItemContainer
                            md={6}
                            title={t("dashboard.lessorOffers.title")}
                            badgeNumber={allOffers?.itemCount}
                            showAllClick={() => {
                                navigateToPage("offers");
                            }}
                        >
                            {allOffers && allOffers.data && allOffers.data.length > 0 ? (
                                allOffers.data.map((off) => (
                                    <div className={styles.itemContainerStyle}>
                                        <DashboardLessorOffersBox offerForm={off} />
                                    </div>
                                ))
                            ) : (
                                <>
                                    <div className={styles.emptyContainerStyle}>
                                        <EmptyOffers />
                                        {t("dashboard.waitingWorks.emptyWaitingWorks")}
                                    </div>
                                </>
                            )}
                        </DashboardItemContainer>
                    )
                }
                {
                    /* Yaklaşan İşler kutusu */ role === "lessor" && !isForSaleDashboard && (
                        <DashboardItemContainer
                            md={6}
                            title={t("dashboard.lessorUpcomingWorks.title")}
                            badgeNumber={upcomingOffers?.itemCount}
                            showAllClick={() => {
                                navigateToPage("offers");
                            }}
                        >
                            {upcomingOffers && upcomingOffers.data && upcomingOffers.data.length > 0 ? (
                                upcomingOffers?.data?.map((off) => (
                                    <div className={styles.itemContainerStyle}>
                                        <DashboardLessorUpcomingWorksBox offerForm={off} />
                                    </div>
                                ))
                            ) : (
                                <>
                                    <div className={styles.emptyContainerStyle}>
                                        <EmptyUpcomingWorks />
                                        {t("dashboard.lessorUpcomingWorks.empty")}
                                    </div>
                                </>
                            )}
                        </DashboardItemContainer>
                    )
                }
                {
                    /* Makineler kutusu */ role === "lessor" && !isForSaleDashboard && (
                        <DashboardItemContainer
                            md={6}
                            title={t("dashboard.machines.title")}
                            badgeNumber={machines?.itemCount}
                            showAllClick={() => {
                                navigateToPage("machines");
                            }}
                        >
                            {machines && machines.machines && machines.machines.length > 0 ? (
                                machines?.machines?.map((mac) => (
                                    <div className={styles.itemContainerStyle}>
                                        <DashboardMachinesBox machine={mac} />
                                    </div>
                                ))
                            ) : (
                                <>
                                    <div className={styles.emptyContainerStyle}>
                                        <EmptyMachines />
                                        {t("dashboard.machines.empty")}
                                    </div>
                                </>
                            )}
                            <div>
                                <PrimaryButton
                                    loading={loading}
                                    onClick={() => {
                                        editMachine(undefined);
                                        navigate("/machines/add?compid=" + user?.id);
                                    }}
                                    style={{ minWidth: "240px" }}
                                >
                                    Makine Ekle
                                </PrimaryButton>
                            </div>
                        </DashboardItemContainer>
                    )
                }

                <Col xs={24} style={{ marginBottom: "2rem" }}>
                    <div dangerouslySetInnerHTML={{ __html: blogHtml }}></div>
                </Col>
            </Row>
            {showAgreementsUpdateModal && agreementsForUpdate && (
                <AgreementsUpdateModal
                    onAccept={() => {
                        setShowAgreementsUpdateModal(false);
                    }}
                    agreements={agreementsForUpdate}
                    role={role!}
                    show={showAgreementsUpdateModal}
                />
            )}
        </SecureLayout>
    );
};

export default Dashboard;
