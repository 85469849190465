import BoxContainer from "./box-container";
import { RequestFormViewModel } from "../../service/models";
import PrimaryButton from "../atomics/primary-button";
import RequestAndOfferStatus from "../atomics/request-and-offer-status";
import { t } from "../../translate";
import BasicModelViewer from "../atomics/basic-model-viewer";
import { Col, Row } from "antd";
import { moment } from "../../helpers/moment";
import SecondaryButton from "../atomics/secondary-button";
import { useNavigate } from "react-router-dom";
type DashboardRenterRequestBoxProps = {
    requestForm: RequestFormViewModel;
};

const DashboardRenterRequestBox = (props: DashboardRenterRequestBoxProps) => {
    const navigate = useNavigate();
    const buttons: JSX.Element[] = [];
    switch (props.requestForm.status) {
        case "InReview":
            buttons[0] = (
                <SecondaryButton onClick={() => navigate("/requests/view?reqid=" + props.requestForm.id)}>
                    {t("common.details")}
                </SecondaryButton>
            );
            buttons[1] = (
                <PrimaryButton
                    onClick={() =>
                        navigate(
                            "/requests/edit?reqid=" +
                                props.requestForm.id +
                                (props.requestForm.machineId ? "&macid=" + props.requestForm.machineId : "")
                        )
                    }
                >
                    {t("common.edit")}
                </PrimaryButton>
            );
            break;
        case "Rejected":
            buttons[0] = (
                <SecondaryButton onClick={() => navigate("/requests/view?reqid=" + props.requestForm.id)}>
                    {t("common.details")}
                </SecondaryButton>
            );
            buttons[1] = (
                <PrimaryButton
                    onClick={() =>
                        navigate(
                            "/requests/edit?reqid=" +
                                props.requestForm.id +
                                (props.requestForm.machineId ? "&macid=" + props.requestForm.machineId : "")
                        )
                    }
                >
                    {t("common.edit")}
                </PrimaryButton>
            );
            break;
        default:
            buttons[0] = (
                <SecondaryButton onClick={() => navigate("/requests/view?reqid=" + props.requestForm.id)}>
                    {t("common.details")}
                </SecondaryButton>
            );
    }
    const catId = props.requestForm?.subCategory?.category?.id;
    let count = 0;
    if (props.requestForm?.offerForms) {
        props.requestForm?.offerForms?.map((val) => {
            if (val.status === "Approved") {
                count = count + 1;
            }
            return null;
        });
    }
    return (
        <BoxContainer>
            <div
                style={{
                    display: "flex",
                    height: "100%",
                    flexDirection: "column",
                    justifyContent: "space-between",
                }}
            >
                <div
                    style={{
                        position: "absolute",
                        right: ".5rem",
                        top: ".5rem",
                    }}
                >
                    <RequestAndOfferStatus
                        showOfferStatus={false}
                        showRequestStatus={true}
                        requestStatus={props.requestForm?.status}
                        count={count}
                    />
                </div>
                <div>
                    <Row gutter={[8, 12]}>
                        {catId && (
                            <Col xs={4} md={3}>
                                <img style={{ width: "48px" }} src={"/img" + catId + ".png"} alt={"/img" + catId + ".png"} />
                            </Col>
                        )}
                        <BasicModelViewer
                            xs={20}
                            md={21}
                            objKey={t("request.id") + " : " + props.requestForm?.id}
                            value={props.requestForm?.subCategory?.name}
                        />
                        <BasicModelViewer
                            xs={12}
                            md={12}
                            objKey={t("request.requesteddates")}
                            value={moment(props.requestForm?.requestDateFrom) + " - " + moment(props.requestForm?.requestDateTo)}
                        />
                        <BasicModelViewer
                            xs={12}
                            md={12}
                            objKey={t("request.location")}
                            value={props.requestForm?.district?.name + " - " + props.requestForm?.district?.city?.name}
                        />
                        <div
                            style={{
                                display: "flex",
                                width: "100%",
                                gap: "1rem",
                            }}
                        >
                            {buttons}
                        </div>
                    </Row>
                </div>
            </div>
        </BoxContainer>
    );
};

export default DashboardRenterRequestBox;
