/* tslint:disable */
/* eslint-disable */
/**
 * MachineGo P1 API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CompanyBasicModel
 */
export interface CompanyBasicModel {
    /**
     * 
     * @type {string}
     * @memberof CompanyBasicModel
     */
    companyName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CompanyBasicModel
     */
    name?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CompanyBasicModel
     */
    lastname?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CompanyBasicModel
     */
    phone?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CompanyBasicModel
     */
    email?: string | null;
}

export function CompanyBasicModelFromJSON(json: any): CompanyBasicModel {
    return CompanyBasicModelFromJSONTyped(json, false);
}

export function CompanyBasicModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): CompanyBasicModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'companyName': !exists(json, 'companyName') ? undefined : json['companyName'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'lastname': !exists(json, 'lastname') ? undefined : json['lastname'],
        'phone': !exists(json, 'phone') ? undefined : json['phone'],
        'email': !exists(json, 'email') ? undefined : json['email'],
    };
}

export function CompanyBasicModelToJSON(value?: CompanyBasicModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'companyName': value.companyName,
        'name': value.name,
        'lastname': value.lastname,
        'phone': value.phone,
        'email': value.email,
    };
}

