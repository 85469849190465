import { Col, Row } from "antd";
import LightTitle from "../components/atomics/light-title";
import SelectedItemText from "../components/atomics/selected-item-text";
import BigboxContainer from "../components/containers/bigbox-container";
import { t } from "../translate";
import { ViewPartProps } from "../viewmodels/view-page-part-props";

const ViewServiceRegionsPart = (props: ViewPartProps) => {
    const serviceRegionsList = props.machine?.serviceRegionsList;
    return (
        <BigboxContainer title={t("machines.add.serviceRegionsTitle")}>
            <Row gutter={[16, 16]}>
                <Col xs={24}>
                    <LightTitle>{t("machines.add-info.selectRegions")}</LightTitle>
                    <Row gutter={[8, 8]}>
                        {props.machine &&
                            serviceRegionsList?.map((reg) => {
                                return (
                                    <Col xs={12} sm={8}>
                                        <SelectedItemText>{reg}</SelectedItemText>
                                    </Col>
                                );
                            })}
                        {props.request && (
                            <Col xs={12} sm={8}>
                                <SelectedItemText>
                                    {props.request.district?.city?.name} - {props.request.district?.name}
                                </SelectedItemText>
                            </Col>
                        )}
                    </Row>
                </Col>
            </Row>
        </BigboxContainer>
    );
};

export default ViewServiceRegionsPart;
