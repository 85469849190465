import { Col, Row } from "antd";
import ModalLayout from "../components/layouts/modal-layout";
import style from "./styles/machine-showcase-removal.module.scss";
import SecondaryButton from "../components/atomics/secondary-button";
import { t } from "../translate";
import { useNavigate } from "react-router-dom";

export interface ShowcaseRemovalProps {
    message: string,
    show: boolean,
    onClickOk: () => void;
    onCloseClick: () => void;
    modalType: 'pre-apply' | 'accepted' | 'error',
}
const MachineShowcaseRemovalModal = (props: ShowcaseRemovalProps) => {
    const {modalType, message, onClickOk, onCloseClick} = props;
    const navigate = useNavigate();
    return (
        <ModalLayout show={props.show} showCloseClick onCloseClick={modalType === 'pre-apply' ? onCloseClick : () => navigate("/lessor-sale-machines")}>
            <Row>
                <Row className={style['message-container']} style={{width: '100%'}}>
                    <Col className={style['message-container']} xs={24}>
                        <span className={style.message}>{message}</span>
                    </Col>
                </Row>
                {modalType === 'pre-apply' && (
                    <Row className={style['button-container']} gutter={[0,6]} >
                        <Col xs={24} sm={10}>
                            <SecondaryButton onClick={onClickOk} style={{background: '#FEB111'}} className={style['button-primary']}> 
                                <span>{t('machine-detail.showcase-modal.yes')}</span>
                            </SecondaryButton>
                        </Col>
                        <Col xs={24} sm={10}>
                            <SecondaryButton onClick={onCloseClick} style={{background: '#94939433'}}> 
                                <span>{t('machine-detail.showcase-modal.no')}</span>
                            </SecondaryButton>
                        </Col>
                    </Row>
                )}
                {!(modalType === 'pre-apply') && (
                    <Row className={style['button-container']} gutter={[0,6]} >
                        <Col xs={24} sm={12}>
                            <SecondaryButton onClick={() => {
                                navigate("/lessor-sale-machines");
                            }} style={{background: '#FEB111'}} className={style['button-primary']}> 
                                <span>{t('machine-detail.showcase-modal.ok')}</span>
                            </SecondaryButton>
                        </Col>
                    </Row>
                )}
            </Row>

        </ModalLayout>
    );
}

export default MachineShowcaseRemovalModal;