import { Col, Form, Input, Row, Select } from "antd";
import { UploadChangeParam, UploadFile } from "antd/lib/upload/interface";
import { useAtom } from "jotai";
import { useEffect, useState } from "react";
import { DropdownWithInput } from "../components/atomics/dropdown-with-input";
import {
    BladeWidth,
    BomLength,
    BreakingInfo,
    CameraResolution,
    CarryingCapacity,
    CleaningCapacity,
    CompressionWidth,
    ConcretePumpingCapacity,
    DrivingEquipment,
    ElevatorTypeId,
    EngineBrand,
    EnginePower,
    FlightDistance,
    FlightTime,
    FloorCount,
    FreeAirOutput,
    Frequency,
    FuelTypeId,
    GarbageCollectionBin,
    HasCabin,
    HasPlatform,
    HasTowerElevationSystem,
    Height,
    LiftingHeight,
    LightningCapacity,
    LoadCapacity,
    MaxDepth,
    MaxFeedingSize,
    MaxHeight,
    MaxLoadCapacity,
    MaxPower,
    MaxPressure,
    MaxWidth,
    MinPower,
    NominalCapacity,
    OperatingCapacity,
    OperatingHeight,
    OperatingLength,
    OperatingWeight,
    OperationHour,
    OperationMileage,
    OperationWidth,
    PlatformLength,
    PlatformWidth,
    SpraySpeed,
    TankCapacity,
    TankVolume,
    UsageFields,
    Volume,
    WaterTankCapacity,
    Width,
    WorkingRadius,
    WorkingSpeed,
} from "../components/atomics/form-items";
import { Uploader } from "../components/atomics/uploader";
import BigboxContainer from "../components/containers/bigbox-container";
import { BasicModel, CategoryModel, CityModel, DocumentCategory, DocumentModel } from "../service";
import { activeApiBaseUrl } from "../store/app-constants";
import { loadingAtom, postloginMessageAtom } from "../store/global-atoms";
import { useSecureService } from "../store/service-atoms";
import { t } from "../translate";
import { EditMachinePartProps } from "../viewmodels/edit-page-part-props";
import MgCharacterSelect from "../components/atomics/mg-character-select";

const MachineMachineSaleInfoPart = (props: EditMachinePartProps) => {
    const [, setErrorMessage] = useAtom(postloginMessageAtom);
    const [allCategories, setAllCategories] = useState<undefined | CategoryModel[]>(undefined);
    const [availableSubcategories, setAvailableSubcategories] = useState<undefined | null | BasicModel[]>(undefined);
    const [availableBrands, setAvailableBrands] = useState<BasicModel[] | undefined>(undefined);
    const [selectedCategory, setSelectedCategory] = useState<CategoryModel | undefined>(undefined);
    const [selectedSubcategory, setSelectedSubcategory] = useState<BasicModel | undefined>(undefined);
    const [otherBrand, setOtherBrand] = useState<string | undefined>(undefined);
    const [loading] = useAtom(loadingAtom);
    const { doGetCities } = useSecureService();
    const [cities, setCities] = useState<CityModel[] | undefined>(undefined);
    const { doGetMachineSubcategory, doGetBrandsByCategory } = useSecureService();

    useEffect(() => {
        const getCategoriesAndSubcategories = async () => {
            const result = await doGetMachineSubcategory();
            setAllCategories(result);
        };

        const getCities = async () => {
            const result = await doGetCities();
            setCities(result);
        };  

        getCategoriesAndSubcategories();
        getCities();
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        if(props.initialSubcategory !== undefined) {
            setSelectedSubcategory(props.initialSubcategory);
        }
    }, [props.initialSubcategory]);

    useEffect(() => {
        console.log('selected sub:' + JSON.stringify(selectedSubcategory));
    }, [selectedSubcategory]);


    useEffect(() => {
        console.log('selected cate:' + JSON.stringify(selectedCategory));
    }, [selectedCategory]);
    useEffect(() => {
        if (cities !== undefined && props.initialLocation !== undefined) {

            props.form.setFieldsValue({
                machineLocationCityName: props.initialLocation
            })
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [cities, props.initialLocation]);

    useEffect(() => {
        if (allCategories && cities && props.allDataFetched) {
            props.allDataFetched();
        }
    }, [allCategories, cities, props, props.allDataFetched]);

    useEffect(() => {
        const currentCategory = props.form.getFieldValue("categoryName") as string | undefined;
        onMachineCategorySelected(currentCategory, true);
        const currentSub = props.form.getFieldValue("subcategoryName") as string | undefined;
        setOtherBrand(props.form.getFieldValue("otherBrandName") as string | undefined);
        setTimeout(() => {
            onSubcategorySelected(currentSub, true);
        }, 1);
        if (allCategories !== undefined && props.initialCategory !== undefined) {
            props.form.setFieldsValue({
                categoryName: props.initialCategory
            })
            const selectedCat = allCategories?.find((cat) => cat.name === props.initialCategory);
            setSelectedCategory(selectedCat);
        }
        //eslint-disable-next-line
    }, [allCategories, props.initialCategory]);

    const handleUploadChange = (data: UploadChangeParam<UploadFile<unknown>>) => {
        if (data?.file.status === "uploading") return;
        if (data.fileList !== undefined) {
            const files: UploadFile[] | undefined = data.fileList;
            let machineDetailImgeModel: DocumentModel[] = [];
            files?.forEach((file) => {
                if (file.status !== "done" && file.status !== "success") return;
                machineDetailImgeModel.push({
                    id: file.response.id,
                    fileName: file.response.fileName,
                });
            });
            props.uploadChange && props.uploadChange(machineDetailImgeModel, DocumentCategory.Photo);
        }
    };

    const onMachineCategorySelected = async (selectedCategoryName: string | undefined, initial?: boolean) => {
        const selectedCat = allCategories?.find((cat) => cat.name === selectedCategoryName);
        setSelectedCategory(selectedCat);
        if (selectedCat && selectedCat.id) {
            const brands = await doGetBrandsByCategory(selectedCat.id);
            setAvailableBrands(brands);
        }
        setAvailableSubcategories(selectedCat?.subCategories);
        if (!initial) {
            props.form.setFieldsValue({
                subcategoryName: undefined,
                brandName: undefined,
                attachmentsList: [],
            });
        }
    };

    const onSubcategorySelected = async (selectedSubcategoryName: string | undefined, initial?: boolean) => {
        const currentCategory = props.form.getFieldValue("categoryName") as string | undefined;
        const selectedCat = allCategories?.find((cat) => cat.name === currentCategory);
        const selectedSubcat = selectedCat?.subCategories?.find((sub) => sub.name === selectedSubcategoryName);
        setSelectedSubcategory(selectedSubcat);
        if (!initial) {
            props.form.setFieldsValue({
                attachmentsList: [],
                otherAttachmentsName: undefined,
            });
        }
        props.subcategoryChanged?.(selectedCat?.id||0,selectedSubcat?.id||0);
    };

    const editedMachine: any = undefined;

    return (
        <BigboxContainer title={t("machines.add.machineInfoTitle")}>
            <Uploader
                onChange={handleUploadChange}
                defaultImageFiles={props.docs}
                maxSize={10}
                requestUrl={activeApiBaseUrl + "/Document"}
                buttonText={t('common.uploadPhoto')}
                unsupportedFileError={() =>
                    setErrorMessage({
                        message: t("common.unsupportedFile"),
                        type: "error",
                    })
                }
            />
            <Row gutter={16}>
                <Col xs={24} md={12}>
                    <Form.Item label={t("machines.add.machineIdLabel")} name="id">
                        <Input disabled placeholder={t("machines.add.machineIdPlaceholder")} />
                    </Form.Item>
                </Col>
                <Col xs={24} md={12}>
                    <Form.Item
                        name="categoryName"
                        label={t("machines.add.category")}
                        rules={[
                            {
                                required: true,
                                message: t("common.generic-required-message"),
                            },
                        ]}
                    >
                        <MgCharacterSelect 
                            dropdownStyle = {{ position: "relative" }} 
                            loading={loading} 
                            onSelect={(item: string) => onMachineCategorySelected(item)}
                            options={
                                allCategories?.map((ac)=>({label:ac.name, value: ac.name}))
                            }
                            />
                    </Form.Item>
                </Col>
                <Col xs={24} md={12}>
                    <Form.Item
                        name="subcategoryName"
                        label={t("machines.add.subcategory")}
                        rules={[
                            {
                                required: true,
                                message: t("common.generic-required-message"),
                            },
                        ]}
                    >
                        <MgCharacterSelect 
                            dropdownStyle = {{ position: "relative" }} 
                            loading={loading} 
                            onSelect={(item: string) => onSubcategorySelected(item)}
                            options={
                                availableSubcategories?.map((asc)=>({label: asc.name, value:asc.name}))
                            }
                        />
                    </Form.Item>
                </Col>
                <Col xs={24} md={12}>
                    <DropdownWithInput
                        data={availableBrands}
                        required={true}
                        dropdownValidationMessage={t("common.generic-required-message")}
                        formItemLabel={t("machines.add.brand")}
                        formItemName="brandName"
                        loading={loading}
                        inputName="otherBrandName"
                        inputLabel={t("machines.add.otherBrandInputlabel")}
                        inputValidationMessage={t("common.generic-required-message")}
                        otherChoiceValue="Diğer"
                        inputInitialVal={otherBrand}
                    />
                </Col>
                <Col xs={24} md={12}>
                    <Form.Item
                        name="modelName"
                        label={t("machines.add.modelName")}
                        rules={[
                            {
                                required: true,
                                message: t("common.generic-required-message"),
                            },
                        ]}
                    >
                        <Input />
                    </Form.Item>
                </Col>
                <Col xs={24} md={12}>
                    <Form.Item
                        name="productionYear"
                        label={t("machines.add.productionYear")}
                        rules={[
                            {
                                pattern: /^([1-9][0-9]*)$/,
                                message:t('common.generic-required-numeric-input-message')
                            },
                            {
                                required: true,
                                message: t("common.generic-required-message"),
                            },
                            {
                                validator: (_, value) => {
                                    const rejectPromise = Promise.reject(
                                        new Error(t("machines.additional-fields.productionYearValidationMessage"))
                                    );
                                    if (isNaN(value)) return rejectPromise;
                                    if (value < 0) return rejectPromise;
                                    if (value > 2147483647) return rejectPromise;
                                    return Promise.resolve();
                                },
                            },
                        ]}
                    >
                        <Input  
                            onKeyDown={(event) => {
                                const re = /[+-]/;
                                if (re.test(event.key)) {
                                    event.preventDefault();
                                }
                            }} 
                            type={"number"} 
                            onWheel={(e: any) => e.target.blur()} />
                    </Form.Item>
                </Col>

                {selectedSubcategory && (
                    <>
                        {OperationMileage(selectedCategory?.id, selectedSubcategory.id, editedMachine?.operationMileage)}
                        {OperationHour(selectedCategory?.id, selectedSubcategory.id, editedMachine?.operationHour)}
                        {DrivingEquipment(selectedCategory?.id, selectedSubcategory.id, editedMachine?.drivingEquipment)}
                        {EnginePower(selectedCategory?.id, selectedSubcategory.id, editedMachine?.enginePower)}
                        {OperatingWeight(selectedCategory?.id, selectedSubcategory.id, editedMachine?.operatingWeight)}
                        {TankCapacity(selectedCategory?.id, selectedSubcategory.id, editedMachine?.tankCapacity)}
                        {OperatingHeight(selectedCategory?.id, selectedSubcategory.id, editedMachine?.operatingHeight)}
                        {OperatingLength(selectedCategory?.id, selectedSubcategory.id, editedMachine?.operatingLength)}
                        {OperationWidth(selectedCategory?.id, selectedSubcategory.id, editedMachine?.operationWidth)}
                        {OperatingCapacity(selectedCategory?.id, selectedSubcategory.id, editedMachine?.operatingCapacity)}
                        {UsageFields(selectedCategory?.id, selectedSubcategory.id, editedMachine?.usageFields)}
                        {HasCabin(selectedCategory?.id, selectedSubcategory.id, editedMachine?.hasCabin)}
                        {EngineBrand(selectedCategory?.id, selectedSubcategory.id, editedMachine?.engineBrand)}
                        {HasPlatform(selectedCategory?.id, selectedSubcategory.id, editedMachine?.hasPlatform)}
                        {HasTowerElevationSystem(selectedCategory?.id, selectedSubcategory.id, editedMachine?.hasTowerElevationSystem)}
                        {LightningCapacity(selectedCategory?.id, selectedSubcategory.id, editedMachine?.lightningCapacity)}
                        {CarryingCapacity(selectedCategory?.id, selectedSubcategory.id, editedMachine?.carryingCapacity)}
                        {Width(selectedCategory?.id, selectedSubcategory.id, editedMachine?.width)}
                        {Volume(selectedCategory?.id, selectedSubcategory.id, editedMachine?.volume)}
                        {Height(selectedCategory?.id, selectedSubcategory.id, editedMachine?.height)}
                        {FloorCount(selectedCategory?.id, selectedSubcategory.id, editedMachine?.floorCount)}
                        {BladeWidth(selectedCategory?.id, selectedSubcategory.id, editedMachine?.bladeWidth)}
                        {Frequency(selectedCategory?.id, selectedSubcategory.id, editedMachine?.frequency)}
                        {MinPower(selectedCategory?.id, selectedSubcategory.id, editedMachine?.minPower)}
                        {MaxPower(selectedCategory?.id, selectedSubcategory.id, editedMachine?.maxPower)}
                        {MaxLoadCapacity(selectedCategory?.id, selectedSubcategory.id, editedMachine?.maxLoadCapacity)}
                        {NominalCapacity(selectedCategory?.id, selectedSubcategory.id, editedMachine?.nominalCapacity)}
                        {WaterTankCapacity(selectedCategory?.id, selectedSubcategory.id, editedMachine?.waterTankCapacity)}
                        {MaxFeedingSize(selectedCategory?.id, selectedSubcategory.id, editedMachine?.maxFeedingSize)}
                        {WorkingSpeed(selectedCategory?.id, selectedSubcategory.id, editedMachine?.workingSpeed)}
                        {CleaningCapacity(selectedCategory?.id, selectedSubcategory.id, editedMachine?.cleaningCapacity)}
                        {GarbageCollectionBin(selectedCategory?.id, selectedSubcategory.id, editedMachine?.garbageCollectionBin)}
                        {BreakingInfo(selectedCategory?.id, selectedSubcategory.id, editedMachine?.breakingInfo)}
                        {BomLength(selectedCategory?.id, selectedSubcategory.id, editedMachine?.bomLength)}
                        {MaxHeight(selectedCategory?.id, selectedSubcategory.id, editedMachine?.maxHeight)}
                        {MaxDepth(selectedCategory?.id, selectedSubcategory.id, editedMachine?.maxDepth)}
                        {MaxWidth(selectedCategory?.id, selectedSubcategory.id, editedMachine?.maxWidth)}
                        {CompressionWidth(selectedCategory?.id, selectedSubcategory.id, editedMachine?.compressionWidth)}
                        {PlatformLength(selectedCategory?.id, selectedSubcategory.id, editedMachine?.platformLength)}
                        {PlatformWidth(selectedCategory?.id, selectedSubcategory.id, editedMachine?.platformWidth)}
                        {ConcretePumpingCapacity(selectedCategory?.id, selectedSubcategory.id, editedMachine?.concretePumpingCapacity)}
                        {MaxPressure(selectedCategory?.id, selectedSubcategory.id, editedMachine?.maxPressure)}
                        {FreeAirOutput(selectedCategory?.id, selectedSubcategory.id, editedMachine?.freeAirOutput)}
                        {SpraySpeed(selectedCategory?.id, selectedSubcategory.id, editedMachine?.spraySpeed)}
                        {TankVolume(selectedCategory?.id, selectedSubcategory.id, editedMachine?.tankVolume)}
                        {LoadCapacity(selectedCategory?.id, selectedSubcategory.id, editedMachine?.loadCapacity)}
                        {FlightTime(selectedCategory?.id, selectedSubcategory.id, editedMachine?.flightTime)}
                        {FlightDistance(selectedCategory?.id, selectedSubcategory.id, editedMachine?.flightDistance)}
                        {WorkingRadius(selectedCategory?.id, selectedSubcategory.id, editedMachine?.workingRadius)}
                        {LiftingHeight(selectedCategory?.id, selectedSubcategory.id, undefined)}
                        <FuelTypeId cid={selectedCategory?.id} sid={selectedSubcategory?.id} defaultValue={editedMachine?.fuelType?.name} />
                        <CameraResolution
                            cid={selectedCategory?.id}
                            sid={selectedSubcategory?.id}
                            defaultValue={editedMachine?.cameraResolution?.name}
                        />
                        <ElevatorTypeId
                            cid={selectedCategory?.id}
                            sid={selectedSubcategory?.id}
                            defaultValue={editedMachine?.elevatorType?.name}
                        />
                    </>
                )}
                <Col xs={24} md={12}>
                    <Form.Item
                        name="machineLocationCityName"
                        label={t("machines.add-info.selectedRegion")}
                        rules={[
                            {
                                required: true,
                                message: t("common.generic-required-message"),
                            },
                        ]}
                    >
                        <Select getPopupContainer={(trigger: any) => trigger.parentElement} dropdownStyle = {{ position: "relative" }} loading={loading}>
                            {cities?.map((city) => (
                                <Select.Option value={city.name}>{city.name}</Select.Option>
                            ))}
                        </Select>
                    </Form.Item>
                </Col>
            </Row>
        </BigboxContainer>
    );
};

export default MachineMachineSaleInfoPart;
