/* tslint:disable */
/* eslint-disable */
/**
 * MachineGo P1 API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    MachineListSearchFilterModel,
    MachineListSearchFilterModelFromJSON,
    MachineListSearchFilterModelFromJSONTyped,
    MachineListSearchFilterModelToJSON,
} from './MachineListSearchFilterModel';
import {
    MachineListSearchSortField,
    MachineListSearchSortFieldFromJSON,
    MachineListSearchSortFieldFromJSONTyped,
    MachineListSearchSortFieldToJSON,
} from './MachineListSearchSortField';

/**
 * 
 * @export
 * @interface MachineListSearchModel
 */
export interface MachineListSearchModel {
    /**
     * 
     * @type {number}
     * @memberof MachineListSearchModel
     */
    pageNo?: number;
    /**
     * 
     * @type {number}
     * @memberof MachineListSearchModel
     */
    pageSize?: number;
    /**
     * 
     * @type {number}
     * @memberof MachineListSearchModel
     */
    readonly rowIndex?: number;
    /**
     * 
     * @type {MachineListSearchFilterModel}
     * @memberof MachineListSearchModel
     */
    filter?: MachineListSearchFilterModel;
    /**
     * 
     * @type {MachineListSearchSortField}
     * @memberof MachineListSearchModel
     */
    sortBy?: MachineListSearchSortField;
}

export function MachineListSearchModelFromJSON(json: any): MachineListSearchModel {
    return MachineListSearchModelFromJSONTyped(json, false);
}

export function MachineListSearchModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): MachineListSearchModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'pageNo': !exists(json, 'pageNo') ? undefined : json['pageNo'],
        'pageSize': !exists(json, 'pageSize') ? undefined : json['pageSize'],
        'rowIndex': !exists(json, 'rowIndex') ? undefined : json['rowIndex'],
        'filter': !exists(json, 'filter') ? undefined : MachineListSearchFilterModelFromJSON(json['filter']),
        'sortBy': !exists(json, 'sortBy') ? undefined : MachineListSearchSortFieldFromJSON(json['sortBy']),
    };
}

export function MachineListSearchModelToJSON(value?: MachineListSearchModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'pageNo': value.pageNo,
        'pageSize': value.pageSize,
        'filter': MachineListSearchFilterModelToJSON(value.filter),
        'sortBy': MachineListSearchSortFieldToJSON(value.sortBy),
    };
}

