import { useEffect, useState } from "react";
import { Upload } from "antd";
import { UploadProps } from "antd/lib/upload";
import { RcFile, UploadFile } from "antd/lib/upload/interface";
import { DocumentCategory, DocumentModel } from "../../service/models";
import { activeApiBaseUrl, activeRecaptcha } from "../../store/app-constants";
import { t } from "../../translate";
import SecondaryButton from "./secondary-button";
import GrayboxContainer from "../containers/graybox-container";
import styles from "../styles/uploader.module.scss";
import { useGoogleReCaptcha } from "react-google-recaptcha-hook";
export type MachinegoDocumentUploaderProp = {
    onFilesChange?: (list: DocumentModel[], type: DocumentCategory) => void;
    defaultDocumentFiles?: DocumentModel[] | null;
    unsupportedFileError?: () => void;
    multiple: boolean | undefined;
    title?: string | undefined;
    docType: DocumentCategory;
    description?: string;
    prelogin?: boolean;
} & UploadProps;

export const MachinegoDocumentUploader = (props: MachinegoDocumentUploaderProp) => {
    const [fileList, setFileList] = useState<UploadFile[]>([]);
    const { executeGoogleReCaptcha } = useGoogleReCaptcha(activeRecaptcha);
    const [token, setToken] = useState<string>("");

    const onChange = (params: any) => {
        setFileList(params.fileList);
        var docs: DocumentModel[] = params.fileList.filter((file: any) => file.status === "done").map((item: any) => item.response);
        if (props.onFilesChange && (params.file.status === "done" || params.file.status === "removed"))
            props.onFilesChange(docs, props.docType);
    };

    const onDocRemove = (param: any) => {
        console.log(param);
    };

    const onPreview = async (fileParam: any) => {
        let src = fileParam.name;
        if(props.defaultDocumentFiles) window.open(activeApiBaseUrl + "/Document/" + fileParam.uid);
        if (src !== undefined) {
            var url = window.URL.createObjectURL(fileParam.originFileObj);
            var anchor = document.createElement('a');
            anchor.href = url;
            if (true) {
                anchor.target = '_blank';
            }
            anchor.click();
            //var w = window.open(src);
            //w?.document.write(`<!DOCTYPE html><head><title>Document preview</title></head><body><img src="${blob}" ></body></html>`);
            
        }
    };

    useEffect(() => {
        let docs: UploadFile[] = [];
        if (props.defaultDocumentFiles !== undefined && props.defaultDocumentFiles !== null && props.defaultDocumentFiles.length > 0) {
            props.defaultDocumentFiles?.forEach((pic) => {
                if (pic !== undefined && pic.id !== undefined && pic.fileName !== undefined)
                    docs.push({
                        uid: pic?.id?.toString(),
                        name: pic?.fileName || "",
                        status: "done",
                        url: activeApiBaseUrl + "/Document/" + pic?.id,
                        response: {
                            id: pic?.id,
                            fileName: pic?.fileName,
                        },
                    });
            });
        }
        setFileList(docs);
    }, [props.defaultDocumentFiles]);

    const supportedFileTypes = {
        documents: [
            "image/jpeg",
            "application/pdf",
            "application/msword",
            "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
            "image/png",
        ],
    };

    const beforeUpload = async (file: RcFile) => {
        let result = supportedFileTypes.documents.includes(file.type);
        if (!result) props.unsupportedFileError ? props.unsupportedFileError() : console.log(file.type);
        const token = await executeGoogleReCaptcha("guest");
        setToken(token);
        return result || Upload.LIST_IGNORE;
    };

    const deleteUploadedFiles = () => {
        setFileList([]);
        if (props.onFilesChange) props.onFilesChange([], props.docType);
    };

    const uploader = (
        <GrayboxContainer title={props.title}>
            <div
                style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                }}
            >
                <div style={{ display: "flex", gap: ".5rem" }}>
                    <Upload
                        maxCount={props.maxCount}
                        action={activeApiBaseUrl + "/Document"}
                        headers={
                            props.prelogin
                                ? {
                                      mode: "cors",
                                      "x-recaptcha-token": token,
                                  }
                                : undefined
                        }
                        beforeUpload={beforeUpload}
                        name="document"
                        withCredentials={props.prelogin ? undefined : true}
                        multiple={props.multiple}
                        fileList={fileList}
                        listType={"text"}
                        onChange={onChange}
                        showUploadList
                        onRemove={onDocRemove}
                        onPreview={onPreview}
                        className={styles.uploadContainer}
                    >
                        <div style={{ display: "flex", gap: ".5rem" }}>
                            {fileList.length > 0 && (
                                <SecondaryButton onClick={deleteUploadedFiles} negative>
                                    {t("common.delete")}
                                </SecondaryButton>
                            )}
                            <SecondaryButton>
                                {fileList.length !== 0 && !props.multiple ? <>+ {t("common.update")}</> : <>{t("common.upload")}</>}
                            </SecondaryButton>
                        </div>
                    </Upload>
                </div>
                <p style={{ paddingTop: ".5rem", textAlign: "center" }}>{props.description}</p>
            </div>
        </GrayboxContainer>
    );

    return uploader;
};
