import { useAtom } from "jotai";
import PrimaryButton from "../components/atomics/primary-button";
import ModalLayout, { ModalLayoutProps } from "../components/layouts/modal-layout";
import { loadingAtom, postloginMessageAtom } from "../store/global-atoms";
import { t } from "../translate";
import { Col,Input } from "antd";
import ModalCardContainer from "../components/containers/modalcard-container";
import { useState } from "react";
import { useSecureService } from "../store/service-atoms";
import { useNavigate } from "react-router-dom";

type OfferDeclineModalProps = {
    offerIdN: string;
} & ModalLayoutProps;
const OfferDeclineModal = (props: OfferDeclineModalProps) => {
    const [loading] = useAtom(loadingAtom);
    const { doDeclineOfferForm } = useSecureService();
    const navigate = useNavigate();
    const [note, setNote] = useState<string>("");
    const { TextArea } = Input;
    const [, setErrorMessage] = useAtom(postloginMessageAtom);

    const onTextAreaChanged = (e: any) => {
        setNote(e.currentTarget.value);
    };

    const declineOffer = async () => {
        const result = await doDeclineOfferForm(parseInt(props.offerIdN), note);
        if (result !== false) {
            setErrorMessage({
                type: "info",
                message: t('offers.view.declineOffer-message'),
            });
            navigate(-1);
        }
    }

    return (
        <ModalLayout {...props}>
            <ModalCardContainer title={t("common.decline-reason")}>
                <Col>
                    <TextArea onChange={onTextAreaChanged} rows={4} placeholder={t("common.decline-reason")} />
                </Col>
                <Col span={18} offset={3}>
                    <PrimaryButton loading={loading} onClick={declineOffer}>
                        {t("common.continue")}
                    </PrimaryButton>
                </Col>
            </ModalCardContainer>
        </ModalLayout>
    );
};
export default OfferDeclineModal;
