import { Form, Input } from "antd";
import { useEffect, useState } from "react";

import "./SMSInput.css";

export type UsernameInputProps = {
    validationMessage?: string;
    placeholder?: string;
    label?: string;
    onChange?: any; // Parent'e iletmek için callback fonksiyonu
};
// interface SMSInputProps {
//     label: string;
//     validationMessage: string;
//     placeholder?: string;
//   }

const SmsInput = (props: any) => {
    const [smsCode, setSmsCode] = useState<string[]>(["", "", "", ""]);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>, index: number) => {
        const value = e.target.value;
        if (/^\d?$/.test(value)) {
            // Sadece tek rakam kabul ediliyor
            const newSmsCode = [...smsCode];
            newSmsCode[index] = value;
            setSmsCode(newSmsCode);

            // Eğer bir rakam girildiyse bir sonraki inputa geç
            if (value && index < 3) {
                const nextInput = document.getElementById(`sms-input-${index + 1}`);
                if (nextInput) {
                    (nextInput as HTMLInputElement).focus();
                }
            }
        }
    };

    const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>, index: number) => {
        if (e.key === "Backspace" && !smsCode[index] && index > 0) {
            // Eğer input boşsa ve backspace'e basılmışsa bir önceki inputa geç
            const prevInput = document.getElementById(`sms-input-${index - 1}`);
            if (prevInput) {
                (prevInput as HTMLInputElement).focus();
            }
        }
    };

    useEffect(() => {
        if (props?.onChange)
        props?.onChange(smsCode.join(""));
    }, [props, smsCode]);

    return (
        <Form.Item
            name="sms" // Form.Item'da name prop'u vermek gerekli
            rules={[{ required: true, message: props.validationMessage }]}
        >
            <div className="sms-input-container">
                {smsCode.map((value, index) => (
                    <Input
                        key={index}
                        id={`sms-input-${index}`}
                        className={`sms-input ${value ? "filled" : "empty"}`}
                        maxLength={1}
                        value={value}
                        onChange={(e) => handleChange(e, index)}
                        onKeyDown={(e) => handleKeyDown(e, index)} // Backspace işlemi
                    />
                ))}
           
            </div>
        </Form.Item>
    );
};

export default SmsInput;
