import { Button, Col, FormInstance, Row, Tooltip } from "antd";
import { EnterInput } from "../components/atomics/checkbox-with-input";
import BigboxContainer from "../components/containers/bigbox-container";
import { t } from "../translate";
import { useEffect, useState } from "react";
import { BasicModel } from "../service";
import { useSecureService } from "../store/service-atoms";
import { ReactComponent as Plus } from "../components/assets/plus.svg";
import { ReactComponent as Info } from "../components/assets/info-icon.svg";
import { offerTabAtom } from "../store/global-atoms";
import { useAtom } from "jotai";
import AttachmentPriceInput from "./attachment-price-input";
import { OfferAttachmentObject } from "../pages/secure/offers/add";

export type OfferAttachmentsProps = {
    maxAttachmentNumber: number;
    editPageOfferAttachments?: OfferAttachmentObject[]
}


const OfferAddAttachments = (props: { form: FormInstance<any> } & OfferAttachmentsProps) => {
    const [availableCurrencies, setAvailableCurrencies] = useState<BasicModel[] | undefined>(undefined);
    const [offerTabInfo, ] = useAtom(offerTabAtom);
    const [reRender,setRerender] = useState(false);
    const [attachmentCards, setAttachmentCards] = 
        useState<
        {element: JSX.Element, index: number}[]
        >([]);
    const { doGetCurrencies } = useSecureService();

    useEffect(
        () => {
            const getServiceData = async() => {
                const currencies = await doGetCurrencies();
                setAvailableCurrencies(currencies);
            }
            getServiceData();
        }


        //eslint-disable-next-line
    ,[]);

    useEffect(() => {
        if(props.editPageOfferAttachments){
            
            props.editPageOfferAttachments.forEach((attach, index) => {
                if(attachmentCards.length < props.maxAttachmentNumber) {
                    setAttachmentCards(
                        (oldVal) => {
                            return [
                                ...oldVal,
                                {element: <Col sm={24} md={12}>
                                    <AttachmentPriceInput 
                                        attachments={offerTabInfo.availableAttachments ?? []} 
                                        attachmentDropdownLabel={t("attachment.add.dropdownLabel")} 
                                        attachmentDropdownName={"attachment-dropdown-" + (index)} 
                                        attachmentPriceInputName={"attachment-price-input-" + (index)} 
                                        initialValue={0}
                                        form={props.form}
                                        attachmentPriceInputLabel={t("attachment.add.inputLabel")}
                                        enterType={EnterInput.Price} 
                                        isSuffixDropdown={true}
                                        removeButton={true}
                                        index={index}
                                        onRemoveClick={onRemoveClick}
                                        isSuffixDisabled={true}
                                        initialAttachment={attach.id}
                                        disabled={false}
                                        suffixProps={{
                                            selectName: "select-attachmentCurrency-" + (index),
                                            items: availableCurrencies,
                                            selectedItem: undefined,
                                        }}         
                                        alwaysDisabled={false}></AttachmentPriceInput>     
                                </Col>, index: (index)}
                                
                            ]
                        }
                    );
                }

            });
           setRerender(!reRender);
        }
        //eslint-disable-next-line
    }, [props.editPageOfferAttachments]);

/*     useEffect(() => {
        if(props.editPageOfferAttachments){

            props.editPageOfferAttachments.forEach((attach, index) => {
                if(attachmentCards.length < props.maxAttachmentNumber) {
                    setAttachmentCards(
                        (oldVal) => {
                            return [
                                ...oldVal,
                                {element: <Col sm={24} md={12}>
                                    <AttachmentPriceInput 
                                        attachments={offerTabInfo.availableAttachments ?? []} 
                                        attachmentDropdownLabel={t("attachment.add.dropdownLabel")} 
                                        attachmentDropdownName={"attachment-dropdown-" + (index)} 
                                        attachmentPriceInputName={"attachment-price-input-" + (index)} 
                                        initialValue={0}
                                        form={props.form}
                                        attachmentPriceInputLabel={t("attachment.add.inputLabel")}
                                        enterType={EnterInput.Price} 
                                        isSuffixDropdown={true}
                                        removeButton={true}
                                        index={index}
                                        onRemoveClick={onRemoveClick}
                                        isSuffixDisabled={true}
                                        suffixProps={{
                                            selectName: "select-attachmentCurrency-" + (index),
                                            items: availableCurrencies,
                                            selectedItem: undefined,
                                        }}         
                                        alwaysDisabled={false}></AttachmentPriceInput>     
                                </Col>, index: (index)}
                                
                            ]
                        }
                    );
                }

            });
           
        }
    }, [props.editPageOfferAttachments]); */

    const handleAddAttachment = async () => {
        if(attachmentCards.length < props.maxAttachmentNumber) {
            setAttachmentCards(
                (oldVal) => {
                    return [
                        ...oldVal,
                        {element: <Col sm={24} md={12}>
                            <AttachmentPriceInput 
                                attachments={offerTabInfo.availableAttachments ?? []} 
                                attachmentDropdownLabel={t("attachment.add.dropdownLabel")} 
                                attachmentDropdownName={"attachment-dropdown-" + (attachmentCards.length === 0 ? 0 : attachmentCards[attachmentCards.length - 1].index + 1)} 
                                attachmentPriceInputName={"attachment-price-input-" + (attachmentCards.length === 0 ? 0 : attachmentCards[attachmentCards.length - 1].index + 1)} 
                                initialValue={0}
                                form={props.form}
                                attachmentPriceInputLabel={t("attachment.add.inputLabel")}
                                enterType={EnterInput.Price} 
                                isSuffixDropdown={true}
                                removeButton={true}
                                index={(attachmentCards.length === 0 ? 0 : attachmentCards[attachmentCards.length - 1].index + 1)}
                                onRemoveClick={onRemoveClick}
                                isSuffixDisabled={true}
                                suffixProps={{
                                    selectName: "select-attachmentCurrency-" + (attachmentCards.length === 0 ? 0 : attachmentCards[attachmentCards.length - 1].index + 1),
                                    items: availableCurrencies,
                                    selectedItem: undefined,
                                }}         
                                alwaysDisabled={false}></AttachmentPriceInput>     
                        </Col>, index: (attachmentCards.length === 0 ? 0 : attachmentCards[attachmentCards.length - 1].index + 1)}
                        
                    ]
                }
            );
        }
    }

    const onRemoveClick = (index: number | undefined) => {
        if(index === undefined) return;

        setAttachmentCards(
            (oldVal) => {
                return (oldVal.filter((e) => {return e.index !== index}));
            }
        );
    }
    
    return (
        //TODO change title
        <BigboxContainer title={t("offer.add.attachments.title")}>
            <div style={{position: 'absolute', width: '100%', marginTop: '0.8rem'}}>    
                <Tooltip title='info'>
                    <div style={{width: '3rem', float: 'right', marginRight: '2rem'}}>
                        <Info style={{transform: 'scale(0.8)'}}></Info>
                    </div>
                    
                </Tooltip>
                
            </div>
            <Row gutter={12}>
                {attachmentCards.length !== 0 && 
                    <>
                        {
                            attachmentCards.map((e) => { 
                                return e.element;
                            })
                        }
                    </>
                }
            </Row>

            <Row>
                <Col xs={24} md={24}>
                        <Button 
                            style={{width: '100%'}}
                            onClick={(e) => {
                                handleAddAttachment();
                            }}
                        >
                            <div style={{display: 'flex', justifyContent: 'center', alignContent: 'center'}}>
                                <div>
                                    <Plus style={{transform: 'scale(0.70)translate(-5px,-7px)'}}/>
                                </div>
                                <span style={{fontWeight: '600'}}>{t("offer.add.attachments.add-button")}</span>
                            </div>
                        </Button>
                </Col>
            </Row>
        </BigboxContainer>
    );
}

export default OfferAddAttachments;