import { Col, ColProps } from "antd";

type BorderedBoxContainerProps = {
    children: any;
} & ColProps;

const BorderedBoxContainer = (props: BorderedBoxContainerProps) => {
    return (
        <Col {...props}>
            <div
                style={{
                    borderRadius: "0.75rem",
                    border: "1px rgba(40, 40, 40, 0.2) solid",
                    padding: "1rem",
                    fontSize: "1rem",
                    background: "white",
                    height: "100%",
                }}
            >
                {props.children}
            </div>
        </Col>
    );
};
export default BorderedBoxContainer;
