import { t } from "../translate";
import PrimaryButton from "../components/atomics/primary-button";
import ModalLayout, { ModalLayoutProps } from "../components/layouts/modal-layout";
import styles from "./request-summary.module.scss";
import { useAtom } from "jotai";
import { requestFormAtom } from "../store/global-atoms";
import { ReactComponent as SummaryIcon } from "../components/assets/apply-summary.svg";
import SecondaryButton from "../components/atomics/secondary-button";
import moment from "moment";

type SecureRequestSummaryModalProps = {
    onApprove: () => void;
    onEdit: () => void;
} & ModalLayoutProps;

const SecureRequestSummary = (props: SecureRequestSummaryModalProps) => {
    const [editedRequest] = useAtom(requestFormAtom);

    let attachmentsValue = editedRequest?.attachments?.map((atc) => {
        return atc.name;
    });
    if (editedRequest?.attachmentsOther !== undefined) attachmentsValue?.push(editedRequest.attachmentsOther);

    const editClick = () => {
        props.onEdit();
    };

    const approveClick = () => {
        props.onApprove();
    };

    return (
        <ModalLayout show={props.show} onCloseClick={props.onCloseClick}>
            <div className={styles.lightboxContent}>
                <div className={styles.stepsContainer}>
                    <SummaryIcon />
                </div>
                <p>{t("secure.request.machine-detail.summaryDescription")}</p>
                <div className={styles.successGrayField}>
                    <div className={styles.row}>
                        <span className={styles.successLabel}>{t("request.machine-detail.summaryMachineLabel")}</span>
                        <span className={styles.successValue}>{editedRequest?.subCategory?.name}</span>
                    </div>
                    <div className={styles.row}>
                        <span className={styles.successLabel}>{t("request.machine-detail.summaryAttachmentsLabel")}</span>
                        <span className={styles.successValue}>{attachmentsValue?.join(", ")}</span>
                    </div>
                    <div className={styles.row}>
                        <span className={styles.successLabel}>{t("request.machine-detail.summaryLocationsLabel")}</span>
                        <span className={styles.successValue}>{editedRequest?.district?.name}</span>
                    </div>
                    <div className={styles.row}>
                        <span className={styles.successLabel}>{t("request.machine-detail.summaryDatesLabel")}</span>
                        <span className={styles.successValue}>
                            {moment(editedRequest?.fromDateRaw).format("DD.MM.YYYY") + " - " + moment(editedRequest?.toDateRaw).format("DD.MM.YYYY")}
                        </span>
                    </div>
                    <div className={styles.row}>
                        <span className={styles.successLabel}>{t("request.machine-detail.summaryDetailsLabel")}</span>
                        <span className={styles.successValue}>{editedRequest?.description}</span>
                    </div>
                    <div></div>
                </div>
                <div
                    style={{
                        display: "flex",
                        gap: "1rem",
                        justifyContent: "space-between",
                        width: "100%",
                    }}
                >
                    <SecondaryButton onClick={editClick}>{t("common.edit")}</SecondaryButton>
                    <PrimaryButton type="primary" onClick={approveClick}>
                        {t("common.iapproved")}
                    </PrimaryButton>
                </div>
            </div>
        </ModalLayout>
    );
};

export default SecureRequestSummary;
