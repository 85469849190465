import { MachineDetailModel } from "../../service/models";
import { useNavigate } from "react-router-dom";
import BoxContainer from "./box-container";
import { t } from "../../translate";
import { Col, Row } from "antd";
import BasicModelViewer from "../atomics/basic-model-viewer";
import SecondaryButton from "../atomics/secondary-button";
type DashboardMachinesBoxProps = {
    machine: MachineDetailModel;
};

const DashboardMachinesBox = (props: DashboardMachinesBoxProps) => {
    const navigate = useNavigate();
    const buttons: JSX.Element[] = [];
    switch (props.machine) {
        default:
            buttons[0] = (
                <SecondaryButton onClick={() => navigate("/machines/view?macid=" + props.machine.id)}>
                    {t("common.details")}
                </SecondaryButton>
            );
    }
    const catId = props.machine.subCategory?.category?.id;
    return (
        <BoxContainer>
            <div
                style={{
                    display: "flex",
                    height: "100%",
                    flexDirection: "column",
                    justifyContent: "space-between",
                }}
            >
                <Row gutter={[8, 12]}>
                    {catId && (
                        <Col xs={4} md={6}>
                            <img style={{ width: "48px" }} src={"/img" + catId + ".png"} alt={"/img" + catId + ".png"} />
                        </Col>
                    )}
                    <BasicModelViewer
                        xs={20}
                        md={18}
                        objKey={t("machines.id") + " : " + props.machine?.id}
                        value={props.machine?.subCategory?.name}
                    />
                    <BasicModelViewer xs={12} md={12} objKey={t("machines.brand")} value={props.machine?.brand?.name} />
                    <BasicModelViewer xs={12} md={12} objKey={t("machines.model")} value={props.machine?.modelName} />
                    <div style={{ display: "flex", width: "100%", gap: "1rem" }}>{buttons}</div>
                </Row>
            </div>
        </BoxContainer>
    );
};

export default DashboardMachinesBox;
