/* tslint:disable */
/* eslint-disable */
/**
 * MachineGo P1 API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const SocialLoginMessageType = {
    Success: 'Success',
    NotFound: 'NotFound',
    Blocked: 'Blocked',
    SwitchMessage: 'SwitchMessage',
    Otp: 'OTP'
} as const;
export type SocialLoginMessageType = typeof SocialLoginMessageType[keyof typeof SocialLoginMessageType];


export function SocialLoginMessageTypeFromJSON(json: any): SocialLoginMessageType {
    return SocialLoginMessageTypeFromJSONTyped(json, false);
}

export function SocialLoginMessageTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): SocialLoginMessageType {
    return json as SocialLoginMessageType;
}

export function SocialLoginMessageTypeToJSON(value?: SocialLoginMessageType | null): any {
    return value as any;
}

