
import React from 'react';
import { ArrowProps } from 'react-multi-carousel';
import style from './custom-arrows.module.scss';
interface CustomArrowProps extends ArrowProps {
  slides?: any;
  color?: 'pink' | 'blue';
  direction:'left' | 'right';
}

const CustomArrows = (props: CustomArrowProps) => {
  return (
    <button className={
      props.direction==='left'
        ? (style.customArrowLeft)
        :(style.customArrowRight)
    } onClick={props.onClick}>
      {props.direction === "left" ? "<" : ">"}
    </button>
  );
};

export default CustomArrows;
