/* tslint:disable */
/* eslint-disable */
/**
 * MachineGo P1 API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    MachineSaleListSearchFilterModel,
    MachineSaleListSearchFilterModelFromJSON,
    MachineSaleListSearchFilterModelFromJSONTyped,
    MachineSaleListSearchFilterModelToJSON,
} from './MachineSaleListSearchFilterModel';
import {
    MachineSaleListSearchSortField,
    MachineSaleListSearchSortFieldFromJSON,
    MachineSaleListSearchSortFieldFromJSONTyped,
    MachineSaleListSearchSortFieldToJSON,
} from './MachineSaleListSearchSortField';

/**
 * 
 * @export
 * @interface MachineSaleListSearchModel
 */
export interface MachineSaleListSearchModel {
    /**
     * 
     * @type {number}
     * @memberof MachineSaleListSearchModel
     */
    pageNo?: number;
    /**
     * 
     * @type {number}
     * @memberof MachineSaleListSearchModel
     */
    pageSize?: number;
    /**
     * 
     * @type {number}
     * @memberof MachineSaleListSearchModel
     */
    readonly rowIndex?: number;
    /**
     * 
     * @type {MachineSaleListSearchFilterModel}
     * @memberof MachineSaleListSearchModel
     */
    filter?: MachineSaleListSearchFilterModel;
    /**
     * 
     * @type {MachineSaleListSearchSortField}
     * @memberof MachineSaleListSearchModel
     */
    sortBy?: MachineSaleListSearchSortField;
}

export function MachineSaleListSearchModelFromJSON(json: any): MachineSaleListSearchModel {
    return MachineSaleListSearchModelFromJSONTyped(json, false);
}

export function MachineSaleListSearchModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): MachineSaleListSearchModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'pageNo': !exists(json, 'pageNo') ? undefined : json['pageNo'],
        'pageSize': !exists(json, 'pageSize') ? undefined : json['pageSize'],
        'rowIndex': !exists(json, 'rowIndex') ? undefined : json['rowIndex'],
        'filter': !exists(json, 'filter') ? undefined : MachineSaleListSearchFilterModelFromJSON(json['filter']),
        'sortBy': !exists(json, 'sortBy') ? undefined : MachineSaleListSearchSortFieldFromJSON(json['sortBy']),
    };
}

export function MachineSaleListSearchModelToJSON(value?: MachineSaleListSearchModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'pageNo': value.pageNo,
        'pageSize': value.pageSize,
        'filter': MachineSaleListSearchFilterModelToJSON(value.filter),
        'sortBy': MachineSaleListSearchSortFieldToJSON(value.sortBy),
    };
}

