/* tslint:disable */
/* eslint-disable */
/**
 * MachineGo P1 API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface LessorCompanyAuthoriseLessorViewModel
 */
export interface LessorCompanyAuthoriseLessorViewModel {
    /**
     * 
     * @type {number}
     * @memberof LessorCompanyAuthoriseLessorViewModel
     */
    companyId?: number;
    /**
     * 
     * @type {Array<number>}
     * @memberof LessorCompanyAuthoriseLessorViewModel
     */
    authorisedLessors: Array<number>;
    /**
     * 
     * @type {Array<number>}
     * @memberof LessorCompanyAuthoriseLessorViewModel
     */
    unauthorisedLessors: Array<number>;
}

export function LessorCompanyAuthoriseLessorViewModelFromJSON(json: any): LessorCompanyAuthoriseLessorViewModel {
    return LessorCompanyAuthoriseLessorViewModelFromJSONTyped(json, false);
}

export function LessorCompanyAuthoriseLessorViewModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): LessorCompanyAuthoriseLessorViewModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'companyId': !exists(json, 'companyId') ? undefined : json['companyId'],
        'authorisedLessors': json['authorisedLessors'],
        'unauthorisedLessors': json['unauthorisedLessors'],
    };
}

export function LessorCompanyAuthoriseLessorViewModelToJSON(value?: LessorCompanyAuthoriseLessorViewModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'companyId': value.companyId,
        'authorisedLessors': value.authorisedLessors,
        'unauthorisedLessors': value.unauthorisedLessors,
    };
}

