import { Radio } from "antd";
import { EventHandler } from "react";
import styles from "../styles/radio-button.module.scss";

type RadioButtonProps = {
    content: string;
    value: string;
    radioClicked?: EventHandler<any>;
};

const RadioButton = (props: RadioButtonProps) => {
    return (
        <div
            className={styles.container}
            onClick={() => {
                if (props.radioClicked) props.radioClicked(props.value);
            }}
        >
            <span>{props.content}</span>
            <Radio value={props.value}></Radio>
        </div>
    );
};

export default RadioButton;
