import { Form } from "antd";
import PasswordInput from "../../components/atomics/password-input";
import PrimaryButton from "../../components/atomics/primary-button";
import AuthLayout from "../../components/layouts/auth-layout";
import { t } from "../../translate";
import { useNavigate } from "react-router-dom";
import { ReactComponent as PasswordIcon } from "../../components/assets/ic_password.svg";
import styles from "../../components/styles/auth-layout.module.scss";
import { useQuery } from "../../helpers/use-query";
import { useSecureService } from "../../store/service-atoms";
import { RoleType, loadingAtom } from "../../store/global-atoms";
import { useAtom } from "jotai";

const ResetPassword = () => {
    const query = useQuery();
    const phone = query.get("phone") || "";
    const role = (query.get("role") as RoleType) || "customer";
    const otp = query.get("otp") || "";
    const [loading] = useAtom(loadingAtom);
    const navigate = useNavigate();
    const { doResetPassword } = useSecureService();

    const formFinishHandler = async (params: { password: string; retype: string }) => {
        const result = await doResetPassword(role, {
            otpCode: otp,
            phone: phone,
            password: params.password,
        });
        if (result) navigate(["/login?role=", role].join(""));
    };

    return (
        <AuthLayout>
            <div className={styles.verticalCentralizedFlexContainer}>
                <PasswordIcon height={"3.75rem"} width={"auto"} />
            </div>
            <Form name="normal_login" className="login-form" layout="vertical" onFinish={formFinishHandler}>
                <div>
                    <PasswordInput
                        placeholder={t("reset-password.first.placeholder")}
                        validationMessage={t("reset-password.first.validationMessage")}
                        label={t("reset-password.first.label")}
                    />
                </div>
                <div>
                    <PasswordInput
                        again
                        placeholder={t("reset-password.second.placeholder")}
                        validationMessage={t("reset-password.second.validationMessage")}
                        label={t("reset-password.second.label")}
                    />
                </div>
                <div>
                    <PrimaryButton loading={loading} htmlType="submit">
                        {t("common.save")}
                    </PrimaryButton>
                </div>
            </Form>
        </AuthLayout>
    );
};

export default ResetPassword;
