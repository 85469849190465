import { Table, TableProps } from "antd";
import styles from "../styles/components.module.scss";

export type DataTableProps = {} & TableProps<any>;

const DataTable = (props: DataTableProps) => {
    return <Table {...props} className={styles.dataTable} />;
};

export default DataTable;
