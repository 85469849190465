import { useEffect, useState } from "react";
import styles from "../styles/increments-input.module.scss";

type IncrementsInputProp = {
    onChange?: (val: number) => void;
    min: number;
    max: number;
    value?: number;
};
const IncrementsInput = (props: IncrementsInputProp) => {
    const [plusVisible, setPlusVisible] = useState(false);
    const [minusVisible, setMinusVisible] = useState(false);
    const [currentVal, setCurrentVal] = useState(props.value || props.min);
    useEffect(() => {
        if (currentVal <= props.min) setMinusVisible(false);
        else setMinusVisible(true);

        if (currentVal >= props.max) setPlusVisible(false);
        else setPlusVisible(true);
        if (props.onChange) props.onChange(currentVal);
        // eslint-disable-next-line
    }, [currentVal]);

    useEffect(() => {
        if (props.value) setCurrentVal(props.value);
    }, [props.value]);

    const minusClicked = () => {
        if (minusVisible) setCurrentVal(currentVal - 1);
    };

    const plusClicked = () => {
        if (plusVisible) setCurrentVal(currentVal + 1);
    };

    return (
        <div
            className={styles.mainContainer}
            style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "3.75rem",
            }}
        >
            <div onClick={minusClicked} className={minusVisible ? styles.buttonContainer : `${styles.buttonContainer} ${styles.disable}`}>
                -
            </div>
            <span>{currentVal}</span>
            <div onClick={plusClicked} className={plusVisible ? styles.buttonContainer : `${styles.buttonContainer} ${styles.disable}`}>
                +
            </div>
        </div>
    );
};

export default IncrementsInput;
