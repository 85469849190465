import { Col, Form, Radio, RadioChangeEvent, Row, Tooltip } from "antd";
import { FormInstance } from "antd/es/form/Form";
import { useEffect, useState } from "react";
import BigboxContainer from "../components/containers/bigbox-container";
import { BasicModel, OfferFormViewModel } from "../service";
import { useSecureService } from "../store/service-atoms";
import { t } from "../translate";
import TabWithCheckboxAndInput from "../components/atomics/tab-with-checkbox-and-input";
import { moment } from "../helpers/moment";
import { ReactComponent as Info } from "../components/assets/info-icon.svg";
import styles from "./styles/offer-payment-options-phase2.module.scss";
import { useAtom } from "jotai";
import { offerTabAtom } from "../store/global-atoms";
import OfferPeriodWithInput from "./offer-period-with-input";
import { ExtendedMachineDetailModel } from "../service/models/ExtendedMachineDetailModel";
import { ReactComponent as Info2 } from "../components/assets/info.svg";
import Modal from "antd/lib/modal/Modal";

export type OfferPaymentOptionsPhase2Props = {
    availableMaturityOptions: BasicModel[] | undefined;
    maxNumberOfAttachments: number;
    form: FormInstance<any>;
    offerDetail?: OfferFormViewModel;
    machine?: ExtendedMachineDetailModel;
    billingTimeOption?: number;
};

const OfferPaymentOptionsPhase2 = (props: OfferPaymentOptionsPhase2Props) => {
    const { doGetMaturityOptions, doGetCurrencies, doGetBillingTimeOptions } = useSecureService();
    const [offerTabInfo, setOfferTabInfo] = useAtom(offerTabAtom);
    const [availableCurrencies, setAvailableCurrencies] = useState<BasicModel[] | undefined>(undefined);
    const [value, setValue] = useState<number>();
    const [billingTime, setBillingTime] = useState<BasicModel[] | undefined>([]);
    const [maturityOptionCards, setMaturityOptionCards] = useState<{ name: string[]; selected: boolean[] }>({ name: [], selected: [] });
    const [isResetModalOpen, setIsResetModalOpen] = useState<{ show: boolean; tabIndex: number; selected: boolean; tabName: string }>({
        show: false,
        tabIndex: 0,
        selected: false,
        tabName: "",
    });
    const [selectedRadio, setSelectedRadio] = useState<0 | 1 | 2>(0);
    const [checkBilling, setCheckBilling] = useState(true);
    const onRadioChange = (e: RadioChangeEvent) => {
        setValue(e.target.value);
        setSelectedRadio(e.target.value);
        if (e.target.value) setCheckBilling(false);
    };

    useEffect(() => {
        const getServiceDatas = async () => {
            const curries = await doGetMaturityOptions();

            let curriesNames: string[] = [];
            if (curries)
                curries.map((e) => {
                    if (e.name) curriesNames.push(e.name);
                    return e;
                });

            setMaturityOptionCards({ name: curriesNames, selected: Array(curriesNames.length).fill(false) });
            const currencies = await doGetCurrencies();
            setAvailableCurrencies(currencies);

            const billing = await doGetBillingTimeOptions();
            setBillingTime(billing);
        };
        getServiceDatas();
        //eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (
            (document.getElementById("radio-bill-" + props.billingTimeOption) as HTMLElement) &&
            (props.billingTimeOption === 1 || props.billingTimeOption === 2)
        ) {
            (document.getElementById("radio-bill-" + props.billingTimeOption) as HTMLElement).click();
        }
        setCheckBilling(props.billingTimeOption ? false : true);
        //eslint-disable-next-line
    }, [props.billingTimeOption, document.getElementById("radio-bill-" + props.billingTimeOption) as HTMLElement]);

    useEffect(() => {
        setMaturityOptionCards((oldVal) => {
            return { name: oldVal.name, selected: offerTabInfo.maturityInterestRates.map((e) => e !== -1) };
        });
    }, [offerTabInfo.maturityInterestRates]);

    const setSelectedMaturityCardComplete = (selected: boolean, cardIndex: number, tabName: string) => {
        const newSelectedList = maturityOptionCards.selected.map((e) => e);
        if (selected) {
            if (!maturityOptionCards.selected.some((e) => e)) {
                let newList = offerTabInfo.tabTypes;
                newList[cardIndex] = 2;
                setOfferTabInfo((oldVal) => {
                    return { ...oldVal, tabTypes: newList };
                });
            } else {
                newSelectedList.every((e, index) => {
                    if (e === true && index > cardIndex) {
                        let newList = offerTabInfo.tabTypes;
                        newList[cardIndex] = 2;
                        newList[index] = 3;
                        setOfferTabInfo((oldVal) => {
                            return { ...oldVal, tabTypes: newList };
                        });
                        return false;
                    } else if (e === true && index < cardIndex) {
                        let newList = offerTabInfo.tabTypes;
                        newList[cardIndex] = 3;
                        setOfferTabInfo((oldVal) => {
                            return { ...oldVal, tabTypes: newList };
                        });
                        return false;
                    }
                    return true;
                });
            }
        } else {
            newSelectedList.every((e, index) => {
                if (e === true && index < cardIndex) {
                    let newList = offerTabInfo.tabTypes;
                    newList[cardIndex] = 1;
                    setOfferTabInfo((oldVal) => {
                        return { ...oldVal, tabTypes: newList };
                    });
                    return false;
                }
                if (e === true && index > cardIndex) {
                    let newList = offerTabInfo.tabTypes;
                    newList[cardIndex] = 1;
                    newList[index] = 2;
                    setOfferTabInfo((oldVal) => {
                        return { ...oldVal, tabTypes: newList };
                    });
                    return false;
                } else if (index + 1 === newSelectedList.length) {
                    let newList = offerTabInfo.tabTypes;
                    newList[cardIndex] = 1;
                    setOfferTabInfo((oldVal) => {
                        return { ...oldVal, tabTypes: newList };
                    });
                    return false;
                }

                return true;
            });
            if (offerTabInfo.currentTab.index === cardIndex)
                setOfferTabInfo((oldVal) => {
                    return { ...oldVal, currentTab: { name: "", index: -1 } };
                });
        }

        newSelectedList[cardIndex] = selected;
        if (cardIndex >= 0) {
            setMaturityOptionCards((oldVal) => {
                return { name: oldVal.name, selected: newSelectedList };
            });
            setOfferTabInfo((oldVal) => {
                return { ...oldVal, currentTab: { name: tabName, index: cardIndex } };
            });
        }
    };
    const setSelectedMaturityCard = (name: string, selected: boolean, cardIndex: number) => {
        const newSelectedList = maturityOptionCards.selected.map((e) => e);
        let willResetFlag = false;
        if (selected) {
            if (!maturityOptionCards.selected.some((e) => e)) {
                let newList = offerTabInfo.tabTypes;
                newList[cardIndex] = 2;
                let maturityRates = offerTabInfo.maturityInterestRates;
                maturityRates[cardIndex] = null;
                setOfferTabInfo((oldVal) => {
                    return {
                        ...oldVal,
                        tabTypes: newList,
                        currentTab: {
                            name: props.availableMaturityOptions ? props.availableMaturityOptions[cardIndex].name ?? "" : "",
                            index: cardIndex,
                        },
                        maturityInterestRates: maturityRates,
                    };
                });
            } else {
                newSelectedList.every((e, index) => {
                    if (e === true && index > cardIndex) {
                        resetModalShow(true, cardIndex, selected, name);
                        willResetFlag = true;
                        return false;
                    } else if (e === true && index < cardIndex) {
                        let newList = offerTabInfo.tabTypes;
                        newList[cardIndex] = 3;
                        let maturityRates = offerTabInfo.maturityInterestRates;
                        maturityRates[cardIndex] = null;
                        setOfferTabInfo((oldVal) => {
                            return {
                                ...oldVal,
                                tabTypes: newList,
                                maturityInterestRates: maturityRates,
                                currentTab: { name: maturityOptionCards.name[cardIndex], index: cardIndex },
                            };
                        });
                        return false;
                    }
                    return true;
                });
            }
        } else {
            newSelectedList.every((e, index) => {
                if (e === true && index < cardIndex) {
                    let newList = offerTabInfo.tabTypes;
                    newList[cardIndex] = 1;
                    if (cardIndex >= 0) {
                        let newList = offerTabInfo.maturityInterestRates;
                        newList[cardIndex] = -1;
                        setOfferTabInfo((oldVal) => {
                            return { ...oldVal, maturityInterestRates: newList };
                        });
                    }
                    setOfferTabInfo((oldVal) => {
                        return { ...oldVal, tabTypes: newList };
                    });
                    if (offerTabInfo.currentTab.index === cardIndex)
                        setOfferTabInfo((oldVal) => {
                            return { ...oldVal, currentTab: { name: "", index: -1 } };
                        });
                    offerTabInfo.tabTypes.every((e, index) => {
                        if (e === 2) {
                            setOfferTabInfo((oldVal) => {
                                return {
                                    ...oldVal,
                                    currentTab: {
                                        name: props.availableMaturityOptions ? props.availableMaturityOptions[index].name ?? "" : "",
                                        index: index,
                                    },
                                };
                            });
                            return false;
                        }
                        return true;
                    });

                    return false;
                } else if (e === true && index > cardIndex) {
                    resetModalShow(true, cardIndex, selected, name);
                    willResetFlag = true;
                    return false;
                } else if (index + 1 === newSelectedList.length) {
                    resetModalShow(true, cardIndex, selected, name);
                    willResetFlag = true;
                    return false;
                }
                return true;
            });
        }
        if (!willResetFlag) {
            newSelectedList[cardIndex] = selected;
            if (cardIndex >= 0) {
                setMaturityOptionCards((oldVal) => {
                    return { name: oldVal.name, selected: newSelectedList };
                });
            }
        }
    };

    useEffect(() => {
        let tempErrorList = offerTabInfo.includedMaturityOptionsError.map((e) => e);

        tempErrorList = tempErrorList.map((e, index) => {
            if (maturityOptionCards.selected.at(index) === false) return false;
            else return e;
        });

        setOfferTabInfo((oldVal) => {
            return {
                ...oldVal,
                includedMaturityOptions: maturityOptionCards.selected.map((e) => e),
                includedMaturityOptionsError: tempErrorList,
            };
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [maturityOptionCards.selected]);

    const resetModalShow = (show: boolean, index: number, selected: boolean, tabName: string) => {
        setIsResetModalOpen((oldVal) => {
            return { show: show, tabIndex: index, selected: selected, tabName: tabName };
        });
    };

    const onResetOk = () => {
        let lowestMaturityTab: number | undefined = undefined;
        let currentTabIndex: number | undefined = undefined;
        if (offerTabInfo.tabTypes[isResetModalOpen.tabIndex] === 2) {
            /* if(isResetModalOpen.tabIndex >= 0){
                let newList = offerTabInfo.maturityInterestRates;
                newList[isResetModalOpen.tabIndex] = undefined;
                setOfferTabInfo((oldVal) => {return {...oldVal, maturityInterestRates: newList};});
            } */
            offerTabInfo.tabTypes.every((e, index) => {
                if (e === 3 && index !== isResetModalOpen.tabIndex) {
                    lowestMaturityTab = index;
                    if (index !== isResetModalOpen.tabIndex) currentTabIndex = index;
                    return false;
                }
                return true;
            });
        }
        const newMaturityInterestRates = offerTabInfo.maturityInterestRates;
        setSelectedMaturityCardComplete(isResetModalOpen.selected, isResetModalOpen.tabIndex, isResetModalOpen.tabName);
        if (lowestMaturityTab === undefined && isResetModalOpen.selected) {
            lowestMaturityTab = isResetModalOpen.tabIndex;
            currentTabIndex = isResetModalOpen.tabIndex;
            newMaturityInterestRates[lowestMaturityTab] = null;
        }
        if (lowestMaturityTab === undefined && !isResetModalOpen.selected) {
            newMaturityInterestRates[isResetModalOpen.tabIndex] = -1;
        }
        if (lowestMaturityTab && !isResetModalOpen.selected) {
            newMaturityInterestRates[isResetModalOpen.tabIndex] = -1;
            newMaturityInterestRates[lowestMaturityTab] = null;
        }

        // resets lowest maturity tab fields
        if (offerTabInfo.selectedLowestMaturityId) {
            props.form.setFieldsValue({
                ["LowestPeriodPriceInput-" + isResetModalOpen.tabIndex]: "",
            });
        }

        if (offerTabInfo.currentTab.index !== isResetModalOpen.tabIndex) {
        }
        // resets offer info
        setOfferTabInfo((oldVal) => {
            return {
                ...oldVal,
                currentTab: currentTabIndex
                    ? {
                          name: props.availableMaturityOptions ? props.availableMaturityOptions[currentTabIndex ?? 0].name ?? "" : "",
                          index: currentTabIndex ?? -1,
                      }
                    : {
                          name: props.availableMaturityOptions ? props.availableMaturityOptions[lowestMaturityTab ?? 0].name ?? "" : "",
                          index: lowestMaturityTab ?? -1,
                      },
                selectedLowestMaturityId: lowestMaturityTab ?? -1,
                selectedAttachments:
                    offerTabInfo.selectedAttachments.map((e) => {
                        return { id: e.id, name: e.name, fee: 0, attachmentSelectId: e.attachmentSelectId };
                    }) ?? [],
                availableAttachments: offerTabInfo.availableAttachments,
                lowestMaturitySelected: lowestMaturityTab ? true : false,
                maturityInterestRates: newMaturityInterestRates,
                currentTabTotalPrice: 0,
                lowestSelectedMaturityShippingGoFee: 0,
                lowestSelectedMaturityShippingReturnFee: 0,
                lowestSelectedMaturityPeriodPrice: null,
                tabTypes: newMaturityInterestRates.map((e, index) => {
                    return (e !== undefined ? offerTabInfo.tabTypes.at(index) : 1) ?? 1;
                }),
            };
        });
        setIsResetModalOpen((oldVal) => {
            return { ...oldVal, show: false };
        });
    };

    const onResetCancel = () => {
        (document.getElementById("checkbox-tab-" + isResetModalOpen.tabIndex) as HTMLInputElement).click();
        setIsResetModalOpen((oldVal) => {
            return { ...oldVal, show: false };
        });
    };

    useEffect(() => {
        if (offerTabInfo.tabTypes[offerTabInfo.currentTab.index] === 2)
            setOfferTabInfo((oldVal) => {
                return { ...oldVal, selectedLowestMaturityId: offerTabInfo.currentTab.index, lowestMaturitySelected: true };
            });
        else
            setOfferTabInfo((oldVal) => {
                return { ...oldVal, lowestMaturitySelected: false };
            });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [offerTabInfo.currentTab]);

    return (
        <BigboxContainer title={t("offers.add.paymentOptions")}>
            <Row gutter={[4, 4]} align="middle" style={{ marginBottom: "1rem" }}>
                <Col style={{ display: "flex" }}>
                    <Info2 />
                </Col>
                <Col style={{ textAlign: "start" }}>Fiyatlarınızı KDV hariç giriniz!</Col>
            </Row>
            <div style={{ position: "absolute", width: "100%", marginTop: "0.8rem" }}>
                <Tooltip title="info">
                    <div style={{ width: "3rem", float: "right", marginRight: "2rem" }}>
                        <Info style={{ transform: "scale(0.8)" }}></Info>
                    </div>
                </Tooltip>
            </div>
            <Row>
                {maturityOptionCards && maturityOptionCards.name.length > 0 && (
                    <Row style={{ width: "100%", display: "flex", justifyContent: "space-around" }} gutter={12}>
                        {maturityOptionCards.name.map((e, index) => {
                            return (
                                <Col
                                    xs={24}
                                    sm={12}
                                    md={5}
                                    lg={5}
                                    style={{ zIndex: "0" }}
                                    onClick={(e) => {
                                        if (offerTabInfo.tabTypes[index] === 1 || offerTabInfo.tabTypes[index] === undefined) return;
                                        setOfferTabInfo((oldVal) => {
                                            return {
                                                ...oldVal,
                                                currentTab: {
                                                    name: props.availableMaturityOptions
                                                        ? props.availableMaturityOptions[index].name ?? ""
                                                        : "",
                                                    index: index,
                                                },
                                            };
                                        });
                                    }}
                                >
                                    <TabWithCheckboxAndInput
                                        index={index}
                                        checkedTabIndex={
                                            offerTabInfo.tabTypes[index] === 1
                                                ? 1
                                                : offerTabInfo.tabTypes[index] === 2
                                                ? 2
                                                : offerTabInfo.tabTypes[index] === 3
                                                ? 3
                                                : 1
                                        }
                                        selectedTabIndex={offerTabInfo.currentTab.index}
                                        setTabSelected={setSelectedMaturityCard}
                                        title={e}
                                        form={props.form}
                                    />
                                </Col>
                            );
                        })}
                    </Row>
                )}
            </Row>
            <Row gutter={[16, 16]}>
                <Col xs={24} sm={12} md={12}>
                    {offerTabInfo.currentTab.index !== -1 && (
                        <OfferPeriodWithInput
                            suffixProps={{
                                selectName: "select-period-currency",
                                items: availableCurrencies,
                                selectedItem: undefined,
                            }}
                            lowestMaturitySelected={offerTabInfo.tabTypes[offerTabInfo.currentTab.index] === 2}
                            form={props.form}
                            lowestMaturityInputName={"LowestPeriodPriceInput-" + offerTabInfo.currentTab.index}
                            inputName={"periodPriceInput-" + offerTabInfo.currentTab.index}
                            inputLabel={t("offer.add.period-input-label")}
                            maturityName={offerTabInfo.currentTab.name}
                            index={offerTabInfo.currentTab.index}
                        />
                    )}
                </Col>

                {props.machine && offerTabInfo.currentTab.index !== -1 && (
                    <Col xs={24} sm={12} md={12}>
                        <Row style={{ background: "#EEF0F2", height: "100%", width: "100%", marginTop: "2rem", borderRadius: "12px" }}>
                            <Row style={{ width: "100%", marginLeft: "2rem", height: "100%" }}>
                                <Row style={{ marginTop: "2rem", width: "100%" }}>
                                    <Col xs={22}>
                                        <span style={{ fontWeight: "800" }}>{t("offer.add.requestedMachineTitle")}</span>
                                    </Col>
                                    <Col xs={2}>
                                        <Tooltip title="info">
                                            <div style={{ width: "3rem", float: "right", marginRight: "2rem" }}>
                                                <Info style={{ transform: "scale(0.8)" }}></Info>
                                            </div>
                                        </Tooltip>
                                    </Col>
                                </Row>
                                <Row style={{ width: "100%" }} gutter={[0, 16]}>
                                    <Col xs={24} md={4} style={{ display: "flex", flexDirection: "column" }}>
                                        <span style={{ fontWeight: "500", fontSize: "0.75rem", color: "#949394" }}>
                                            {t("offer.add.requestedMachineID")}
                                        </span>
                                        <span style={{ fontWeight: "700" }}>{props.offerDetail?.id ?? 0}</span>
                                    </Col>
                                    <Col xs={24} md={20} style={{ display: "flex", flexDirection: "column" }}>
                                        <span style={{ fontWeight: "500", fontSize: "0.75rem", color: "#949394" }}>
                                            {t("offer.add.requestDateRange")}
                                        </span>
                                        <span style={{ fontWeight: "700" }}>
                                            {moment(props.offerDetail?.requestForm?.requestDateFrom) +
                                                " - " +
                                                moment(props.offerDetail?.requestForm?.requestDateTo)}
                                        </span>
                                    </Col>
                                </Row>
                                <Row style={{ width: "100%" }} gutter={[12, 16]}>
                                    <Col xs={24} md={6} style={{ display: "flex", flexDirection: "column" }}>
                                        <span style={{ fontWeight: "500", fontSize: "0.75rem", color: "#949394" }}>
                                            {t("offer.add.requestHourlyFee")}
                                        </span>
                                        <span style={{ fontWeight: "700" }}>
                                            {(props.machine?.rentalTypes?.find((e) => e.rentalType?.id === 1)?.price ?? "-") +
                                                " " +
                                                props.machine?.rentalTypes?.at(0)?.currency?.name}
                                        </span>
                                    </Col>
                                    <Col xs={24} md={6} style={{ display: "flex", flexDirection: "column" }}>
                                        <span style={{ fontWeight: "500", fontSize: "0.75rem", color: "#949394" }}>
                                            {t("offer.add.requestDailyFee")}
                                        </span>
                                        <span style={{ fontWeight: "700" }}>
                                            {(props.machine?.rentalTypes?.find((e) => e.rentalType?.id === 2)?.price ?? "-") +
                                                " " +
                                                props.machine?.rentalTypes?.at(0)?.currency?.name}
                                        </span>
                                    </Col>
                                    <Col xs={24} md={6} style={{ display: "flex", flexDirection: "column" }}>
                                        <span style={{ fontWeight: "500", fontSize: "0.75rem", color: "#949394" }}>
                                            {t("offer.add.requestWeeklyFee")}
                                        </span>
                                        <span style={{ fontWeight: "700" }}>
                                            {(props.machine?.rentalTypes?.find((e) => e.rentalType?.id === 3)?.price ?? "-") +
                                                " " +
                                                props.machine?.rentalTypes?.at(0)?.currency?.name}
                                        </span>
                                    </Col>
                                    <Col xs={24} md={6} style={{ display: "flex", flexDirection: "column" }}>
                                        <span style={{ fontWeight: "500", fontSize: "0.75rem", color: "#949394" }}>
                                            {t("offer.add.requestMonthlyFee")}
                                        </span>
                                        <span style={{ fontWeight: "700" }}>
                                            {(props.machine?.rentalTypes?.find((e) => e.rentalType?.id === 4)?.price ?? "-") +
                                                " " +
                                                props.machine?.rentalTypes?.at(0)?.currency?.name}
                                        </span>
                                    </Col>
                                </Row>
                            </Row>
                        </Row>
                    </Col>
                )}
            </Row>

            <h4 style={{ marginTop: "3rem" }}>{t("offers.add.selectInvoiceTime")}</h4>
            <Row gutter={[16, 16]}>
                <Col md={24} xs={24}>
                    <Form.Item
                        rules={[
                            {
                                required: checkBilling,
                                message: t("offer.add.noBillingTimeOptionSelected"),
                            },
                        ]}
                        name="billingTime"
                    >
                        <Radio.Group onChange={onRadioChange} value={value} style={{ display: "block" }}>
                            <Row gutter={[16, 16]}>
                                {billingTime?.map((bill) => {
                                    return (
                                        <Col md={12} xs={24}>
                                            <div className={selectedRadio === bill.id ? styles.selectedRadio : styles.notSelectedRadio}>
                                                <Radio
                                                    style={{
                                                        height: "90%",
                                                        marginTop: "1rem",
                                                        marginLeft: "1rem",
                                                        width: "100%",
                                                        display: "flex",
                                                        justifyContent: "space-between",
                                                        alignContent: "center",
                                                    }}
                                                    id={"radio-bill-" + bill.id}
                                                    value={bill.id}
                                                >
                                                    <span>{bill.name}</span>
                                                </Radio>
                                            </div>
                                        </Col>
                                    );
                                })}
                            </Row>
                        </Radio.Group>
                    </Form.Item>
                </Col>
            </Row>
            <Modal
                title={t("offer.add.resetTitle")}
                closable={false}
                okText={t("offer.add.resetOk")}
                cancelText={t("offer.add.resetCancel")}
                onOk={onResetOk}
                onCancel={onResetCancel}
                visible={isResetModalOpen.show}
            >
                <span>{t("offer.add.resetMessage")}</span>
            </Modal>
        </BigboxContainer>
    );
};

export default OfferPaymentOptionsPhase2;
