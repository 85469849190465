/* tslint:disable */
/* eslint-disable */
/**
 * MachineGo P1 API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    LessorStatus,
    LessorStatusFromJSON,
    LessorStatusFromJSONTyped,
    LessorStatusToJSON,
} from './LessorStatus';

/**
 * 
 * @export
 * @interface LessorCompanySearchFilterModel
 */
export interface LessorCompanySearchFilterModel {
    /**
     * 
     * @type {number}
     * @memberof LessorCompanySearchFilterModel
     */
    id?: number | null;
    /**
     * 
     * @type {Array<number>}
     * @memberof LessorCompanySearchFilterModel
     */
    serviceRegions?: Array<number> | null;
    /**
     * 
     * @type {number}
     * @memberof LessorCompanySearchFilterModel
     */
    categoryId?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof LessorCompanySearchFilterModel
     */
    allServiceRegionsMustBeIncluded?: boolean;
    /**
     * 
     * @type {string}
     * @memberof LessorCompanySearchFilterModel
     */
    companyName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof LessorCompanySearchFilterModel
     */
    nameLastname?: string | null;
    /**
     * 
     * @type {LessorStatus}
     * @memberof LessorCompanySearchFilterModel
     */
    lessorStatus?: LessorStatus;
}

export function LessorCompanySearchFilterModelFromJSON(json: any): LessorCompanySearchFilterModel {
    return LessorCompanySearchFilterModelFromJSONTyped(json, false);
}

export function LessorCompanySearchFilterModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): LessorCompanySearchFilterModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'serviceRegions': !exists(json, 'serviceRegions') ? undefined : json['serviceRegions'],
        'categoryId': !exists(json, 'categoryId') ? undefined : json['categoryId'],
        'allServiceRegionsMustBeIncluded': !exists(json, 'allServiceRegionsMustBeIncluded') ? undefined : json['allServiceRegionsMustBeIncluded'],
        'companyName': !exists(json, 'companyName') ? undefined : json['companyName'],
        'nameLastname': !exists(json, 'nameLastname') ? undefined : json['nameLastname'],
        'lessorStatus': !exists(json, 'lessorStatus') ? undefined : LessorStatusFromJSON(json['lessorStatus']),
    };
}

export function LessorCompanySearchFilterModelToJSON(value?: LessorCompanySearchFilterModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'serviceRegions': value.serviceRegions,
        'categoryId': value.categoryId,
        'allServiceRegionsMustBeIncluded': value.allServiceRegionsMustBeIncluded,
        'companyName': value.companyName,
        'nameLastname': value.nameLastname,
        'lessorStatus': LessorStatusToJSON(value.lessorStatus),
    };
}

