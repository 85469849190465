type LightTitleProps = {
    darkTitle?: boolean;
    children: any;
};
const LightTitle = (props: LightTitleProps) => {
    return (
        <span
            style={{
                fontSize: props.darkTitle ? "1rem" : "0.875rem",
                fontWeight: "500",
                color: props.darkTitle ? "#282828" : "#949394",
            }}
        >
            {props.children}
        </span>
    );
};

export default LightTitle;
