import { Checkbox, Col, Form, Row } from "antd";
import { useAtom } from "jotai";
import LinkButton from "../components/atomics/link-button";
import PrimaryButton from "../components/atomics/primary-button";
import ModalLayout from "../components/layouts/modal-layout";
import { LessorCompanyAgreementsViewModel, RenterCompanyAgreementsViewModel } from "../service";
import { agreementsVersions } from "../store/agreements-versions";
import { activeRootSiteUrl } from "../store/app-constants";
import { RoleType, loadingAtom, userAtom } from "../store/global-atoms";
import { useSecureService } from "../store/service-atoms";
import { t } from "../translate";

type AgreementsUpdateModalProps = {
    role: RoleType;
    agreements: any;
    show: boolean;
    onAccept: () => void;
};
const AgreementsUpdateModal = (props: AgreementsUpdateModalProps) => {
    const { doUpdateLessorUserAgreements, doUpdateRenterUserAgreements } = useSecureService();
    const [loading] = useAtom(loadingAtom);
    const [user, setUser] = useAtom(userAtom);

    const updateAgreements = async () => {
        if (props.role === "lessor") {
            let lessorAgreementsValues = {
                agreementVersion: agreementsVersions.agreementVersion,
                kvkkVersion: agreementsVersions.kvkkVersion,
                kvkkInfoVersion: agreementsVersions.kvkkInfoVersion,
            } as LessorCompanyAgreementsViewModel;
            const result = await doUpdateLessorUserAgreements(lessorAgreementsValues);
            if (result !== false) {
                let updatedUser = Object.assign({}, user, {
                    agreementVersion: lessorAgreementsValues.agreementVersion,
                    kvkkVersion: lessorAgreementsValues.kvkkVersion,
                    kvkkInfoVersion: lessorAgreementsValues.kvkkInfoVersion
                })
                setUser(updatedUser)
                props.onAccept();
            }
        } else if (props.role === "customer") {
            let renterAgreementsValues = {
                agreementVersion: agreementsVersions.agreementVersion,
                kvkkVersion: agreementsVersions.kvkkInfoVersion,
            } as RenterCompanyAgreementsViewModel;
            const result = await doUpdateRenterUserAgreements(renterAgreementsValues);
            if (result !== false) {
                let updatedUser = Object.assign({}, user, {
                    agreementVersion: renterAgreementsValues.agreementVersion,
                    kvkkVersion: renterAgreementsValues.kvkkVersion,
                });
                setUser(updatedUser);
                props.onAccept();
            }
        }
    };

    return (
        <ModalLayout show={props.show} showCloseClick={false}>
            <Form onFinish={updateAgreements}>
                <Row style={{ textAlign: "left" }} gutter={[20, 12]}>
                    <Col xs={24} md={20}>
                        <h4>{t("common.agreements")}</h4>
                    </Col>
                    <Col xs={24} md={20}>
                        {t("dashboard.agreements.update.text")}
                    </Col>
                    {props?.agreements?.agreementVersion && (
                        <Col xs={24} md={20}>
                            <Form.Item
                                name="agreementVersion"
                                valuePropName="checked"
                                rules={[
                                    {
                                        required: true,
                                        type: "boolean",
                                        message: t("signup.form.bhsValidation"),
                                        validator: (_, value) =>
                                            value ? Promise.resolve() : Promise.reject(new Error(t("common.generic-required-message"))),
                                    },
                                ]}
                            >
                                <Checkbox>
                                    <LinkButton href={activeRootSiteUrl + "/kullanici-sozlesmesi/"} target="_blank">
                                        {t("signup.form.bhsName")}
                                    </LinkButton>
                                    <span> {t("signup.form.bhsLabel")}</span>
                                </Checkbox>
                            </Form.Item>
                        </Col>
                    )}
                    {props?.agreements?.kvkkVersion && (
                        <Col xs={24}>
                            <Form.Item
                                name="kvkkVersion"
                                valuePropName="checked"
                                rules={[
                                    {
                                        type: "boolean",
                                        message: t("signup.form.kvkkValidation"),
                                        required: true,
                                        validator: (_, value) =>
                                            value ? Promise.resolve() : Promise.reject(new Error(t("common.generic-required-message"))),
                                    },
                                ]}
                            >
                                <Checkbox>
                                    <LinkButton href={activeRootSiteUrl + "/aydinlatma-metni/"} target="_blank">
                                        {t("signup.form.kvkkName")}
                                    </LinkButton>
                                    <span> {t("signup.form.kvkkLabel")}</span>
                                </Checkbox>
                            </Form.Item>
                        </Col>
                    )}
                    {props.role === "lessor" && props?.agreements?.kvkkInfoVersion && (
                        <Col xs={24}>
                            <Form.Item
                                name="kvkkInfoVersion"
                                valuePropName="checked"
                                rules={[
                                    {
                                        required: true,
                                        type: "boolean",
                                        message: t("signup.form.kvkkInfoValidation"),
                                        validator: (_, value) =>
                                            value ? Promise.resolve() : Promise.reject(new Error(t("common.generic-required-message"))),
                                    },
                                ]}
                            >
                                <Checkbox>
                                    <LinkButton href={activeRootSiteUrl + "/kurumsal-ve-bireysel-uye-acik-riza-metni/"} target="_blank">
                                        {t("signup.form.kvkkInfoName")}
                                    </LinkButton>
                                    <span>{t("signup.form.kvkkInfoLabel")}</span>
                                </Checkbox>
                            </Form.Item>
                        </Col>
                    )}
                </Row>
                <Row align="middle" justify="center">
                    <Col xs={20} md={8}>
                        <PrimaryButton loading={loading} htmlType="submit">
                            {t("common.approve")}
                        </PrimaryButton>
                    </Col>
                </Row>
            </Form>
        </ModalLayout>
    );
};

export default AgreementsUpdateModal;
