import { Col, Row } from "antd";
import { useEffect, useState } from "react";
import BasicModelViewer from "../components/atomics/basic-model-viewer";
import CheckboxOffer from "../components/atomics/checkbox-offer";
import LightTitle from "../components/atomics/light-title";
import SecondaryButton from "../components/atomics/secondary-button";
import BigboxContainer from "../components/containers/bigbox-container";
import GrayboxContainer from "../components/containers/graybox-container";
import style from "../components/styles/payment.module.scss";
import { moment } from "../helpers/moment";
import { BasicModel, DocumentModel, OfferFormBidViewModel, OfferFormRenterBidViewModel, RequestFormOfferViewModel } from "../service";
import { activeApiBaseUrl } from "../store/app-constants";
import { useSecureService } from "../store/service-atoms";
import { t } from "../translate";
import OfferViewBidParts from "./offer-view-bid-parts";
import { ReactComponent as Info2 } from "../components/assets/info.svg";
import { RoleType } from "../store/global-atoms";

type OfferViewAdditionalInfoPartProps = {
    notes?: string | null;
    bids?: (OfferFormBidViewModel & OfferFormRenterBidViewModel)[] | null;
    role?: RoleType | string;
    onlyView?: boolean;
    docs?: DocumentModel[] | null;
    onBidSelected?: (bidId: number | undefined) => void;
    acceptOfferWithoutBidSelection?: boolean;
    billing?: BasicModel[] | null | undefined;
    req: RequestFormOfferViewModel | undefined;
    offerStatus?: string | null;
    availableAttachments?: BasicModel[];
};
const OfferViewAdditionalInfoPart = (props: OfferViewAdditionalInfoPartProps) => {
    const { doGetMaturityOptions } = useSecureService();
    const [availableMaturityOptions, setAvailableMaturityOptions] = useState<BasicModel[] | undefined>(undefined);
    const [selectedBid, setSelectedBid] = useState<number>(0);
    const [checkedMaturityList, setCheckedMaturityList] = useState<boolean[]>([]);

    useEffect(() => {
        const getServiceDatas = async () => {
            const curries = await doGetMaturityOptions();
            setAvailableMaturityOptions(curries);
        };
        getServiceDatas();
        //eslint-disable-next-line
    }, []);

    useEffect(() => {
        const initialSelectedBid = props.bids?.find((bid) => bid.selected)?.id;
        setCheckedMaturityList(Array(props.bids?.length).fill(false));
        if (initialSelectedBid) setSelectedBid(initialSelectedBid);
        console.log("OFFER STATUS:" + props.offerStatus);
        //eslint-disable-next-line
    }, [props.bids]);

    const bidChange = (selected: boolean, contextId?: number, index?: number) => {
        if (index !== undefined && index >= 0 && index < checkedMaturityList.length) {
            let tempList = checkedMaturityList.map((e) => e);
            tempList = tempList.map((e, i) => {
                if (i === index) return selected;
                else return false;
            });
            setCheckedMaturityList(tempList);
        }
        if (!selected && selectedBid === contextId) {
            setSelectedBid(0);
            props.onBidSelected && props.onBidSelected(undefined);
        }

        if (selected && contextId) {
            setSelectedBid(contextId);
            props.onBidSelected && props.onBidSelected(contextId);
        }
    };

    return (
        <BigboxContainer title={t("offer.view.rentalTypeTitle")}>
            <Row gutter={[16, 16]}>
                <Col xs={24}>
                    <Row justify="space-between">
                        <Col style={{ display: "flex", alignItems: "center", padding: "0" }} md={12} className={style.labelStyle}>
                            {t("offers.view.requestDateFromTo")}
                        </Col>
                        <Col
                            style={{ display: "flex", alignItems: "center", justifyContent: "center" }}
                            md={12}
                            className={style.priceStyle}
                        >
                            {moment((props.req as RequestFormOfferViewModel)?.requestDateFrom) +
                                " - " +
                                moment((props.req as RequestFormOfferViewModel)?.requestDateTo)}
                        </Col>
                    </Row>
                    <Col style={{ display: "flex", alignItems: "center", padding: "0" }} md={12} className={style.labelStyle}>
                        {t("offers.add.paymentOptionsTitle")}
                    </Col>
                    <Row gutter={[4, 4]} align="middle" style={{ marginBottom: "1rem" }}>
                        <Col style={{ display: "flex" }}>
                            <Info2 />
                        </Col>
                        <Col style={{ textAlign: "start" }}>Fiyatlarımıza KDV dahil değildir!</Col>
                    </Row>
                    <Row gutter={[12, 12]}>
                        {props.bids?.map((bid, i) => {
                            var selected = availableMaturityOptions?.find((item) => item.id === bid.maturityOption?.id);
                            if (!selected) return <></>;

                            var checked = selectedBid === selected || selectedBid === bid.id;

                            if (props.role === "customer" || props.role === "lessor") {
                                return (
                                    <OfferViewBidParts
                                        index={i}
                                        checked={checkedMaturityList[i]}
                                        bids={bid}
                                        onCheckboxChange={bidChange}
                                        contextId={bid.id}
                                        offerStatus={props.offerStatus}
                                        periodPaymentType={bid.paymentPeriod ? bid.paymentPeriod.name ?? "" : ""}
                                        periodPaymentTypeId={bid.paymentPeriod ? bid.paymentPeriod.id ?? "" : ""}
                                        periodFee={bid.renterPeriodPayment ?? ""}
                                        availableAttachments={props.availableAttachments ?? []}
                                        role={props.role}
                                        dealAccepted={
                                            props.offerStatus?.toLowerCase() === "accepted" || props.offerStatus?.toLowerCase() === "deal"
                                        }
                                        bidSelected={bid.selected}
                                    ></OfferViewBidParts>
                                );
                            } else {
                                return (
                                    <CheckboxOffer
                                        contextId={props.role === "customer" ? bid.id : undefined}
                                        onCheckboxChange={bidChange}
                                        clickable={props.role === "customer" && !props.onlyView}
                                        xs={24}
                                        md={24}
                                        userRole={props.role === "customer" ? "customer" : "lessor"}
                                        inputVisible
                                        checkboxChecked={checked ? checked : bid.selected}
                                        checkboxLabel={bid.maturityOption?.name}
                                        bid={bid}
                                        renterbid={bid}
                                        offerStatus={props.offerStatus}
                                    />
                                );
                            }
                        })}
                        <Row style={{ width: "100%", display: "flex", justifyContent: "center", marginTop: "50px" }}>
                            <div
                                style={{
                                    background: "#FFF4E1",
                                    borderRadius: "10px",
                                    width: "97%",
                                    height: "3.5rem",
                                    display: "flex",
                                    alignItems: "center",
                                }}
                            >
                                <span style={{ marginLeft: "1.25rem", fontWeight: "800", fontSize: "1.25rem" }}>
                                    {t("offers.view.additionalInfoTitle")}
                                </span>
                            </div>
                        </Row>
                        <BasicModelViewer xs={24} md={24} objKey={t("common.description")} value={props.notes ?? " "} />
                        {props.docs && (
                            <Col xs={24}>
                                <LightTitle>
                                    {" "}
                                    <span style={{ marginLeft: "0.5rem" }}> {t("machines.add.documents")}</span>
                                </LightTitle>
                                <Row gutter={[16, 16]}>
                                    {
                                        //TODO: dokuman tipine gore filter yapmaliyiz.
                                        props?.docs?.map((doc) => {
                                            return (
                                                <Col xs={24} md={12}>
                                                    <GrayboxContainer title={doc.fileName}>
                                                        <div style={{ paddingBottom: "1rem" }}>
                                                            <SecondaryButton
                                                                onClick={() => {
                                                                    window.open(activeApiBaseUrl + "/Document/" + doc.id);
                                                                }}
                                                            >
                                                                {t("common.review")}
                                                            </SecondaryButton>
                                                        </div>
                                                    </GrayboxContainer>
                                                </Col>
                                            );
                                        })
                                    }
                                </Row>
                            </Col>
                        )}
                    </Row>
                </Col>
            </Row>
            <Row
                style={{ display: "flex", alignItems: "center", paddingRight: "0.75rem", marginTop: "30px" }}
                gutter={[0, 16]}
                justify="space-between"
            >
                <Col className={style.labelStyle} xs={16}>
                    {t("offers.view.invoiceTime")}
                </Col>

                {props.billing?.map((bill) => {
                    return (
                        <Col className={style.priceStyle} xs={8} style={{ textAlign: "end" }}>
                            {bill.name || " - "}
                        </Col>
                    );
                })}
            </Row>
            <span style={{ color: "red" }}>{props.acceptOfferWithoutBidSelection && !selectedBid && t("offers.view.bidNotSelected")}</span>
        </BigboxContainer>
    );
};

export default OfferViewAdditionalInfoPart;
