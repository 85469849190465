/* tslint:disable */
/* eslint-disable */
/**
 * MachineGo P1 API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface LessorUserCreateViewModel
 */
export interface LessorUserCreateViewModel {
    /**
     * 
     * @type {number}
     * @memberof LessorUserCreateViewModel
     */
    companyId?: number;
    /**
     * 
     * @type {string}
     * @memberof LessorUserCreateViewModel
     */
    name?: string | null;
    /**
     * 
     * @type {string}
     * @memberof LessorUserCreateViewModel
     */
    lastname?: string | null;
    /**
     * 
     * @type {string}
     * @memberof LessorUserCreateViewModel
     */
    phone?: string | null;
    /**
     * 
     * @type {string}
     * @memberof LessorUserCreateViewModel
     */
    email?: string | null;
    /**
     * 
     * @type {string}
     * @memberof LessorUserCreateViewModel
     */
    title?: string | null;
    /**
     * 
     * @type {string}
     * @memberof LessorUserCreateViewModel
     */
    agreementVersion?: string | null;
    /**
     * 
     * @type {string}
     * @memberof LessorUserCreateViewModel
     */
    kvkkVersion?: string | null;
    /**
     * 
     * @type {string}
     * @memberof LessorUserCreateViewModel
     */
    kvkkInfoVersion?: string | null;
    /**
     * 
     * @type {string}
     * @memberof LessorUserCreateViewModel
     */
    ipazVersion?: string | null;
}

export function LessorUserCreateViewModelFromJSON(json: any): LessorUserCreateViewModel {
    return LessorUserCreateViewModelFromJSONTyped(json, false);
}

export function LessorUserCreateViewModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): LessorUserCreateViewModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'companyId': !exists(json, 'companyId') ? undefined : json['companyId'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'lastname': !exists(json, 'lastname') ? undefined : json['lastname'],
        'phone': !exists(json, 'phone') ? undefined : json['phone'],
        'email': !exists(json, 'email') ? undefined : json['email'],
        'title': !exists(json, 'title') ? undefined : json['title'],
        'agreementVersion': !exists(json, 'agreementVersion') ? undefined : json['agreementVersion'],
        'kvkkVersion': !exists(json, 'kvkkVersion') ? undefined : json['kvkkVersion'],
        'kvkkInfoVersion': !exists(json, 'kvkkInfoVersion') ? undefined : json['kvkkInfoVersion'],
        'ipazVersion': !exists(json, 'ipazVersion') ? undefined : json['ipazVersion'],
    };
}

export function LessorUserCreateViewModelToJSON(value?: LessorUserCreateViewModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'companyId': value.companyId,
        'name': value.name,
        'lastname': value.lastname,
        'phone': value.phone,
        'email': value.email,
        'title': value.title,
        'agreementVersion': value.agreementVersion,
        'kvkkVersion': value.kvkkVersion,
        'kvkkInfoVersion': value.kvkkInfoVersion,
        'ipazVersion': value.ipazVersion,
    };
}

