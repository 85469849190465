import { OfferFormViewModel } from "../../service/models";
import { useNavigate } from "react-router-dom";
import BoxContainer from "./box-container";
import { t } from "../../translate";
import { Row, Col } from "antd";
import { moment } from "../../helpers/moment";
import momentoriginal from "moment";
import BasicModelViewer from "../atomics/basic-model-viewer";
import SecondaryButton from "../atomics/secondary-button";
type DashboardLessorUpcomingWorksBoxProps = {
    offerForm: OfferFormViewModel;
};

const DashboardLessorUpcomingWorksBox = (props: DashboardLessorUpcomingWorksBoxProps) => {
    const navigate = useNavigate();
    const buttons: JSX.Element[] = [];
    switch (props.offerForm.status) {
        default:
            buttons[0] = (
                <SecondaryButton
                    onClick={() => navigate("/offers/view?offerid=" + props.offerForm.id + "&reqid=" + props.offerForm?.requestForm?.id)}
                >
                    {t("common.details")}
                </SecondaryButton>
            );
    }
    const catId = props.offerForm?.requestForm?.subCategory?.category?.id;

    const dateFrom = moment(props.offerForm?.requestForm?.requestDateFrom);
    const dateTo = moment(props.offerForm?.requestForm?.requestDateTo);
    let difference =
        dateFrom !== "" && dateTo !== ""
            ? momentoriginal(props.offerForm?.requestForm?.requestDateTo).to(
                  momentoriginal(props.offerForm?.requestForm?.requestDateFrom),
                  true
              )
            : "";

    /*  const timeInMilisec: number = dateTo.getTime() - dateFrom.getTime();
    const daysBetweenDates: number = Math.ceil(timeInMilisec / (1000 * 60 * 60 * 24)); */

    return (
        <BoxContainer>
            <div
                style={{
                    display: "flex",
                    height: "100%",
                    flexDirection: "column",
                    justifyContent: "space-between",
                }}
            >
                <div>
                    <Row gutter={[8, 12]}>
                        {catId && (
                            <Col xs={4} md={6}>
                                <img style={{ width: "48px" }} src={"/img" + catId + ".png"} alt={"/img" + catId + ".png"} />
                            </Col>
                        )}
                        <BasicModelViewer
                            xs={20}
                            md={18}
                            objKey={t("machines.id") + " : " + props.offerForm?.machines?.map((mac) => mac.id).join(", ")}
                            value={props.offerForm?.requestForm?.subCategory?.name}
                        />
                        <BasicModelViewer xs={12} md={12} objKey={t("offer.id")} value={props.offerForm?.id} />
                        <BasicModelViewer xs={12} md={12} objKey={t("offer.rentalperiod")} value={difference} />
                        <BasicModelViewer
                            xs={24}
                            md={24}
                            objKey={t("request.requesteddates")}
                            value={
                                moment(props.offerForm?.requestForm?.requestDateFrom) +
                                " - " +
                                moment(props.offerForm?.requestForm?.requestDateTo)
                            }
                        />
                        <div
                            style={{
                                display: "flex",
                                width: "100%",
                                gap: "1rem",
                            }}
                        >
                            {buttons}
                        </div>
                    </Row>
                </div>
            </div>
        </BoxContainer>
    );
};

export default DashboardLessorUpcomingWorksBox;
