import { LogoutOutlined, MenuOutlined } from "@ant-design/icons";
import { Col, Grid, Row, Spin } from "antd";
import { Content, Footer, Header } from "antd/lib/layout/layout";
import { useAtom } from "jotai";
import { ReactElement, useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { switchBroadcastChannel } from "../../App";
import { lessorPurposeAtom, machineFilterAtom, roleAtom, unauthorizedResponseAtom, userAtom } from "../../store/global-atoms";
import { useSecureService } from "../../store/service-atoms";
import { t } from "../../translate";
import { ReactComponent as Logo } from "../assets/logo.svg";
import { ReactComponent as Profile } from "../assets/profile.svg";
import MenuItemButton from "../atomics/menu-item-button";
import NotificationBell from "../atomics/notification-bell";
import PrimaryButton from "../atomics/primary-button";
import SwitchButton from "../atomics/switch-button";
import styles from "../styles/secure-layout.module.scss";
import { ReactComponent as Dashboard } from "./../assets/dashboard.svg";
import { ReactComponent as GreenCircle } from "./../assets/greenCircle.svg";
import { ReactComponent as Machines } from "./../assets/machines.svg";
import { ReactComponent as YellowCircle } from "./../assets/yellowCircle.svg";
import BaseLayout, { BaseLayoutProps } from "./base-layout";
import ModalLayout from "./modal-layout";
import { LessorPurpose } from "../../service";

type PageType =
    | "dashboard"
    | "machines"
    | "requests"
    | "offers"
    | "requests-and-offers"
    | "notifications"
    | "rental-machines"
    | "rental-machine-detail"
    | "reservations"
    | "sale-machines"
    | "expertise"
    | "lessor-sale-machines";

export type SecureLayoutProps = {
    activePage?: PageType;
    machineApproved?: boolean;
    children?: any;
    title?: string;
    description?: string;
    helperButtons?: ReactElement[] | undefined;
    onlyHeader?: boolean;
    whiteBackground?: boolean;
    loading?: boolean;
} & BaseLayoutProps;

const SecureLayout = (props: SecureLayoutProps) => {
    const navigate = useNavigate();
    const { pathname } = useLocation();
    const [user] = useAtom(userAtom);
    const [lessorPurpose, setLessorPurpose] = useAtom(lessorPurposeAtom);
    const [role] = useAtom(roleAtom);
    const [unauthorizedFired, setUnauthorizedFired] = useAtom(unauthorizedResponseAtom);
    const { doSwitch, doGetUserDetails } = useSecureService();

    const [signUpVisible, setSignupVisible] = useState<boolean>(false);
    const [isMenuVisible, setMenuVisible] = useState<boolean>(false);
    const [, setMachineFilter] = useAtom(machineFilterAtom);
    const { useBreakpoint } = Grid;
    const screens = useBreakpoint();

    useEffect(() => {
        if (user === undefined || user === null || user?.id === null || user?.id === undefined) {
            const getUserDetails = async () => {
                await doGetUserDetails();
            };

            getUserDetails();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user]);

    useEffect(() => {
        if (unauthorizedFired !== "") {
            setUnauthorizedFired("");
            navigate("/login?role=" + (role || "lessor") + "&isLogout=true");
        }
        //eslint-disable-next-line
    }, [unauthorizedFired]);

    const isDesktop = useMemo(() => {
        return screens.md || screens.lg || screens.xl || screens.xxl;
    }, [screens]);

    const navigateToPage = (target: PageType) => {
        const pathParts = pathname.split("/");
        const slashCount = pathParts.length - 2;
        //let backCount = 0;
        if (slashCount > 0) {
            navigate("/" + target);
        } else navigate("/" + target, { replace: true });
        //     backCount = slashCount*-1;
        //     navigate(backCount);
        // }
    };

    useEffect(() => {
        console.log("role switched:" + JSON.stringify(role));
    }, [role]);

    const logout = async () => {
        navigate("/login?role=" + (role || "lessor") + "&isLogout=true");
    };

    const switchDashboard = async () => {
        const result = await doSwitch();
        if (result) {
            switchBroadcastChannel.postMessage("MG_LOGGED_IN_NOTIFICATION_SWITCH");
            //TODO: False geldiğinde yapılacaklar önemli. Ya da switch'in hiç gözükmemesi gereken durumlar olabilir.
            navigateToPage("dashboard");
        } else {
            setSignupVisible(true);
        }
        setMenuVisible(false);
    };

    const onMenuClick = () => {
        setMenuVisible(!isMenuVisible);
    };
    const switchMenu = (value: string) => {
        if (value === "Kiralık İşlemler") {
            setLessorPurpose(LessorPurpose.OnlyForRent);
        } else {
            setLessorPurpose(LessorPurpose.OnlyForSale);
        }
        if (props.activePage !== "dashboard") navigateToPage("dashboard");
        setMenuVisible(false);
    };

    const onProfileClick = () => {
        if (role === "customer") navigate("/customer-profile");
        else navigate("/lessor-profile");
    };
    const modalText = role === "customer" ? t("dashboard.switch.signUpLessor") : t("dashboard.switch.signUpRenter");
    const modalPrimaryButtonTarget = role === "customer" ? "/signup/form?role=lessor" : "/signup/form?role=renter";
    const style = { ...props.style, background: props.whiteBackground ? "#FFFFFF" : "#FFF6E8" };
    return (
        <BaseLayout {...props} style={style && { height: "100%", width: "100%" }}>
            <Header className={styles.header}>
                {!isDesktop && (
                    <div className={styles.mobileHeadBar}>
                        <Logo
                            className={styles.logo}
                            onClick={() => {
                                navigateToPage("dashboard");
                            }}
                        />
                        <div onClick={onMenuClick}>
                            <MenuOutlined style={{ fontSize: "1.5rem" }} />
                        </div>
                    </div>
                )}
                {(isMenuVisible || isDesktop) && (
                    <div className={styles.headBar}>
                        <div className={styles.leftSide}>
                            {isDesktop && (
                                <Logo
                                    className={styles.logo}
                                    onClick={() => {
                                        navigateToPage("dashboard");
                                    }}
                                />
                            )}
                            {role === "lessor" ? (
                                lessorPurpose !== LessorPurpose.OnlyForSale ? (
                                    <>
                                        <MenuItemButton
                                            isActive={props.activePage === "dashboard"}
                                            onClick={() => {
                                                setMachineFilter(undefined);
                                                navigateToPage("dashboard");
                                            }}
                                        >
                                            Anasayfa
                                        </MenuItemButton>
                                        <MenuItemButton
                                            isActive={props.activePage === "machines"}
                                            onClick={() => {
                                                navigateToPage("machines");
                                            }}
                                        >
                                            Makinelerim
                                        </MenuItemButton>
                                        <MenuItemButton
                                            isActive={props.activePage === "requests"}
                                            onClick={() => {
                                                navigateToPage("requests");
                                            }}
                                        >
                                            Talepler
                                        </MenuItemButton>
                                        <MenuItemButton
                                            isActive={props.activePage === "offers"}
                                            onClick={() => {
                                                navigateToPage("offers");
                                            }}
                                        >
                                            Tekliflerim
                                        </MenuItemButton>
                                    </>
                                ) : (
                                    <>
                                        <MenuItemButton
                                            isActive={props.activePage === "dashboard"}
                                            onClick={() => {
                                                setMachineFilter(undefined);
                                                navigateToPage("dashboard");
                                            }}
                                        >
                                            <Dashboard />
                                            Anasayfa
                                        </MenuItemButton>
                                        <MenuItemButton
                                            isActive={props.activePage === "lessor-sale-machines"}
                                            onClick={() => {
                                                navigateToPage("lessor-sale-machines");
                                            }}
                                        >
                                            <Machines />
                                            Makineler
                                        </MenuItemButton>
                                    </>
                                )
                            ) : (
                                <>
                                    <MenuItemButton
                                        isActive={props.activePage === "dashboard"}
                                        onClick={() => {
                                            navigateToPage("dashboard");
                                        }}
                                    >
                                        Anasayfa
                                    </MenuItemButton>
                                    <MenuItemButton
                                        isActive={props.activePage === "requests-and-offers"}
                                        onClick={() => {
                                            setMachineFilter(undefined);
                                            navigateToPage("requests-and-offers");
                                        }}
                                    >
                                        Taleplerim &amp; Tekliflerim
                                    </MenuItemButton>
                                    <MenuItemButton
                                        isActive={props.activePage === "rental-machines"}
                                        onClick={() => {
                                            setMachineFilter(undefined);
                                            navigateToPage("rental-machines");
                                        }}
                                    >
                                        Kiralık Makineler
                                    </MenuItemButton>
                                    <MenuItemButton
                                        isActive={props.activePage === "sale-machines"}
                                        onClick={() => {
                                            setMachineFilter(undefined);
                                            navigateToPage("sale-machines");
                                        }}
                                    >
                                        Satılık Makineler
                                    </MenuItemButton>
                                </>
                            )}
                        </div>
                        <Row className={styles.rigthSide} wrap={false}>
                            <Col>
                                <SwitchButton
                                    onSelect={switchMenu}
                                    onSwitch={switchDashboard}
                                    /* items={[
                                        { name: "Makine Sahibi", subs: ["Kiralık İşlemler", "Satılık İşlemler"] },
                                        { name: "Müşteri", subs: ["Kiralık İşlemler", "Satılık İşlemler"] },
                                    ]} */
                                    items={[
                                        { name: "Makine Sahibi", subs: ["Kiralık İşlemler", "Satılık İşlemler"] },
                                        { name: "Müşteri", subs: ["Kiralık İşlemler"] },
                                    ]}
                                    activeItem={role === "lessor" ? 0 : 1}
                                ></SwitchButton>
                            </Col>
                            <Col>
                                <Row gutter={[4, 4]} justify="center" wrap={false}>
                                    <Col style={{ display: "flex", alignItems: "center" }}>
                                        <div>
                                            <NotificationBell userId={user?.id.toString()} />
                                        </div>
                                    </Col>
                                    <Col style={{ display: "flex", alignItems: "center" }}>
                                        <div
                                            style={{
                                                display: "flex",
                                                alignItems: "center",
                                                gap: ".5rem",
                                            }}
                                        >
                                            <div
                                                style={{
                                                    borderRadius: "16px",
                                                    height: "32px",
                                                    width: "32px",
                                                    display: "flex",
                                                    alignItems: "center",
                                                    justifyContent: "center",
                                                    border: "1px solid #94949455",
                                                }}
                                            >
                                                <Profile />
                                            </div>
                                            <div
                                                onClick={onProfileClick}
                                                style={{
                                                    fontWeight: "800",
                                                    cursor: "pointer",
                                                }}
                                            >
                                                {user?.name + " " + user?.lastname}
                                            </div>
                                        </div>
                                    </Col>
                                    <Col style={{ display: "flex", alignItems: "center" }}>
                                        <div
                                            style={{
                                                borderRadius: "16px",
                                                cursor: "pointer",
                                                height: "32px",
                                                width: "32px",
                                                display: "flex",
                                                alignItems: "center",
                                                justifyContent: "center",
                                                border: "1px solid #949494",
                                            }}
                                            onClick={logout}
                                        >
                                            <LogoutOutlined />
                                        </div>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </div>
                )}
                <hr className={styles.hrLine} />
            </Header>
            {props.onlyHeader ? (
                props.children
            ) : (
                <>
                    {props.loading && <Spin size="large" tip="Lütfen Bekleyiniz" className={styles.spinMobile} />}
                    <Content hidden={props.loading} className={styles.content}>
                        <div className={styles.secureContainer}>
                            <Row className={styles.headerArea} style={{ alignItems: "center" }}>
                                <Col>
                                    <h1 className={styles.title}>{props.title}</h1>
                                    <span className={styles.description} style={{ fontWeight: "lighter" }}>
                                        {props.description}
                                    </span>
                                </Col>
                                {props.activePage === "machines" && (
                                    <Col className={styles.machineStatusOuterContainer} xs={24} md={4}>
                                        {props.machineApproved === true && (
                                            <div className={styles.machineStatusContainer}>
                                                <div style={{ display: "flex", alignContent: "center" }}>
                                                    <GreenCircle style={{ transform: "scale(0.7)" }}></GreenCircle>
                                                </div>
                                                <span style={{ color: "#35B934", fontWeight: "800", marginLeft: "0.25rem" }}>
                                                    {t("common.machine.status1")}
                                                </span>
                                            </div>
                                        )}
                                        {props.machineApproved === false && (
                                            <div className={styles.machineStatusContainer}>
                                                <div style={{ display: "flex", alignContent: "center" }}>
                                                    <YellowCircle style={{ transform: "scale(0.7)" }}></YellowCircle>
                                                </div>
                                                <span style={{ color: "#FEB111", fontWeight: "800", marginLeft: "0.25rem" }}>
                                                    {t("common.machine.status2")}
                                                </span>
                                            </div>
                                        )}
                                    </Col>
                                )}
                                <Col
                                    style={
                                        props.helperButtons?.length && props.helperButtons?.length > 2 ? { marginTop: "8px" } : undefined
                                    }
                                    xs={24}
                                    md={(props.helperButtons?.length || 1) * 4}
                                >
                                    <Row gutter={[8, 8]}>
                                        {props.helperButtons &&
                                            props.helperButtons.length > 0 &&
                                            props.helperButtons.map((btn: ReactElement) => {
                                                return (
                                                    <Col xs={24} md={24 / (props.helperButtons?.length || 1)}>
                                                        {btn}
                                                    </Col>
                                                );
                                            })}
                                    </Row>
                                </Col>
                            </Row>
                            {props.children}
                        </div>
                    </Content>
                    <Footer></Footer>
                </>
            )}
            <ModalLayout onCloseClick={() => setSignupVisible(false)} show={signUpVisible}>
                <div
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                    }}
                >
                    <span
                        style={{
                            fontSize: "1.75rem",
                            fontWeight: "800",
                            margin: "3rem",
                        }}
                    >
                        {modalText}
                    </span>
                    <PrimaryButton
                        style={{ width: "auto", padding: "0 3rem" }}
                        onClick={() => {
                            setSignupVisible(false);
                            navigate(modalPrimaryButtonTarget);
                        }}
                    >
                        {t("signup.form.createAccount")}
                    </PrimaryButton>
                </div>
            </ModalLayout>
        </BaseLayout>
    );
};

export default SecureLayout;
