import DataTable from "../components/atomics/data-table";
import { BasicModel, OfferFormBidViewModel, OfferFormViewModel } from "../service";
import { t } from "../translate";
import { moment } from "../helpers/moment";
import BigboxContainer from "../components/containers/bigbox-container";
import RequestAndOfferStatus from "../components/atomics/request-and-offer-status";
import "../page-parts/styles/request-offers-parts.scss";

type IncomingOfferPartsProps = {
    selectedMachineId?: number | null | undefined;
    offers?: OfferFormViewModel[] | null;
    onRow?: (record: OfferFormViewModel | undefined) => void;
};

const IncomingOfferPart = (props: IncomingOfferPartsProps) => {
    const cols = [
        {
            title: t("offers.table.id"),
            dataIndex: "id",
            key: "id",
        },
        {
            title: t("offers.table.status"),
            dataIndex: "status",
            key: "status",
            render: (val: string) => {
                return <RequestAndOfferStatus offerStatus={val} showOfferStatus={true} showRequestStatus={false}></RequestAndOfferStatus>;
            },
        },
        {
            title: t("offers.table.machines"),
            dataIndex: "machines",
            key: "machines",
            render: (val: BasicModel[]) => {
                const machineTitles = val.map((bm) => bm.name).join("\n");
                return <>{machineTitles}</>;
            },
        },
        {
            title: t("offers.table.notes"),
            dataIndex: "notes",
            key: "notes",
        },
        {
            title: t("offers.table.creationDate"),
            dataIndex: "creationDate",
            key: "creationDate",
            render: (val: Date | undefined | null) => {
                return moment(val);
            },
        },
        {
            title: t("offers.table.expireDate"),
            dataIndex: "expireDate",
            key: "expireDate",
            render: (val: Date | undefined | null) => {
                return moment(val);
            },
        },
        {
            title: t("offers.table.bid"),
            dataIndex: "bids",
            key: "bids",
            render: (val: OfferFormBidViewModel[]) => {
                const offerBids = val
                    .map((ob) => ob.maturityOption?.name + " " + ob.paymentPeriod?.name + " : " + ob.total?.toString() + ob.currency?.name)
                    .join("\n-");
                return <>{offerBids}</>;
            },
        },
    ];

    const onRowHandler = (record: any, rowIndex: number | undefined) => {
        return {
            onClick: (event: any) => {
                if (props.onRow) props.onRow(record);
            },
        };
    };

    return (
        <BigboxContainer title={t("offers.incomingOffers")}>
            {props.offers && (
                <DataTable
                    rowClassName={(record, index) => {if (record.machines.some((machine: BasicModel) => machine.id === props.selectedMachineId)) {
                        return "selected";
                    }
                    return "";}}
                    onRow={onRowHandler}
                    dataSource={props.offers}
                    columns={cols}
                    pagination={false}
                />
            )}
        </BigboxContainer>
    );
};

export default IncomingOfferPart;
