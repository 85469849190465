import { Pagination, Row } from "antd";
import { useAtom } from "jotai";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import MenuItemButton from "../../../components/atomics/menu-item-button";
import PrimaryButton from "../../../components/atomics/primary-button";
import RequestListCard from "../../../components/atomics/request-list-card";
import SecureLayout from "../../../components/layouts/secure-layout";
import { RequestFormSearchModel } from "../../../service";
import { loadingAtom, ModifiedRequestDetailModel } from "../../../store/global-atoms";
import { useSecureService } from "../../../store/service-atoms";
import { t } from "../../../translate";
import styles from "../../../components/styles/secure-layout.module.scss";

const ListRequestsAndOffers = () => {
    const navigate = useNavigate();
    const [activePage, setActivePage] = useState<number | undefined>(1);
    const [totalItems, setTotalItems] = useState<number | undefined>(0);
    const { doSearchRequests } = useSecureService();
    const [loading] = useAtom(loadingAtom);
    const [filterObj] = useState<RequestFormSearchModel>({});
    const [requests, setRequests] = useState<ModifiedRequestDetailModel[]>([]);
    const [dataFetching, setDataFetching] = useState(true);
    const [requestCategory, setRequestCategory] = useState<string>("Main");
    const date = new Date();
    const pageSize = 6;

    useEffect(() => {
        const getRequests = async (val: any) => {
            setDataFetching(true);
            const result = await doSearchRequests({
                pageNo: activePage,
                pageSize: pageSize,
                requestDateTo: val === "Past" ? date : null,
                requestDateFrom: val === "Active" ? date : null,
                ...filterObj,
            });
            if (result && result.data) {
                setRequests(result.data as ModifiedRequestDetailModel[]);
                setActivePage(result.pageNo);
                setTotalItems(result.itemCount);
            }
            setDataFetching(false);
        };
        getRequests(requestCategory);
        //eslint-disable-next-line
    }, [activePage, requestCategory]);

    const pageChange = (page: number) => {
        setActivePage(page);
    };

    const viewClick = (reqId: number | undefined) => {
        //TODO: implementation needed.
        navigate("/requests/view?reqid=" + reqId);
    };

    const requestCategorySelect = (val: any) => {
        setRequestCategory(val);
    };

    return (
        <SecureLayout
            loading={dataFetching}
            activePage={"requests-and-offers"}
            role={"customer"}
            title={t("requests-and-offers.list.title")}
            description={t("requests-and-offers.list.description")}
            helperButtons={[
                <PrimaryButton loading={loading} onClick={() => navigate("/requests-and-offers/add")}>
                    {t("requests-and-offers.list.createRequestForm")}
                </PrimaryButton>,
            ]}
        >
            <Row style={{ backgroundColor: "white", borderRadius: 30, marginBottom: 30 }}>
                <div className={styles.requestBar}>
                    <MenuItemButton
                        onClick={() => {
                            requestCategorySelect("Main");
                        }}
                        isActive={requestCategory === "Main"}
                    >
                        {t("requests-and-offers.list.mainCategory")}
                    </MenuItemButton>
                    <MenuItemButton
                        onClick={() => {
                            requestCategorySelect("Past");
                        }}
                        isActive={requestCategory === "Past"}
                    >
                        {t("requests-and-offers.list.pastRequests")}
                    </MenuItemButton>
                    <MenuItemButton
                        onClick={() => {
                            requestCategorySelect("Active");
                        }}
                        isActive={requestCategory === "Active"}
                    >
                        {t("requests-and-offers.list.continuousRequests")}
                    </MenuItemButton>
                </div>
            </Row>
            <Row gutter={[16, 16]} style={{ marginBottom: "2rem" }}>
                {!requests || requests.length === 0 ? (
                    <></>
                ) : (
                    requests.map((req) => {
                        return <RequestListCard onViewClick={viewClick} xs={24} md={8} requestForm={req} offerStatus={""} />;
                    })
                )}
            </Row>
            <Row style={{ justifyContent: "center" }}>
                <Pagination defaultCurrent={activePage} responsive current={activePage} pageSize={pageSize} total={totalItems} onChange={pageChange} />
            </Row>
        </SecureLayout>
    );
};

export default ListRequestsAndOffers;
