/* tslint:disable */
/* eslint-disable */
/**
 * MachineGo P1 API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface RenterCompanyProfileViewModel
 */
export interface RenterCompanyProfileViewModel {
    /**
     * 
     * @type {string}
     * @memberof RenterCompanyProfileViewModel
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof RenterCompanyProfileViewModel
     */
    lastname: string;
    /**
     * 
     * @type {string}
     * @memberof RenterCompanyProfileViewModel
     */
    email?: string | null;
    /**
     * 
     * @type {string}
     * @memberof RenterCompanyProfileViewModel
     */
    title?: string | null;
    /**
     * 
     * @type {string}
     * @memberof RenterCompanyProfileViewModel
     */
    phone?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof RenterCompanyProfileViewModel
     */
    creationDate?: Date;
    /**
     * 
     * @type {boolean}
     * @memberof RenterCompanyProfileViewModel
     */
    hasAnyRequest?: boolean;
}

export function RenterCompanyProfileViewModelFromJSON(json: any): RenterCompanyProfileViewModel {
    return RenterCompanyProfileViewModelFromJSONTyped(json, false);
}

export function RenterCompanyProfileViewModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): RenterCompanyProfileViewModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': json['name'],
        'lastname': json['lastname'],
        'email': !exists(json, 'email') ? undefined : json['email'],
        'title': !exists(json, 'title') ? undefined : json['title'],
        'phone': !exists(json, 'phone') ? undefined : json['phone'],
        'creationDate': !exists(json, 'creationDate') ? undefined : (new Date(json['creationDate'])),
        'hasAnyRequest': !exists(json, 'hasAnyRequest') ? undefined : json['hasAnyRequest'],
    };
}

export function RenterCompanyProfileViewModelToJSON(value?: RenterCompanyProfileViewModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'lastname': value.lastname,
        'email': value.email,
        'title': value.title,
        'phone': value.phone,
        'creationDate': value.creationDate === undefined ? undefined : (value.creationDate.toISOString()),
        'hasAnyRequest': value.hasAnyRequest,
    };
}

