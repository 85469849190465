import BoxContainer from "./box-container";
import { OfferFormRenterViewModel } from "../../service/models";
import PrimaryButton from "../atomics/primary-button";
import RequestAndOfferStatus from "../atomics/request-and-offer-status";
import { t } from "../../translate";
import BasicModelViewer from "../atomics/basic-model-viewer";
import { Col, Row } from "antd";
import SecondaryButton from "../atomics/secondary-button";
import { useNavigate } from "react-router-dom";
type DashboardWaitingworksBoxProps = {
    offerForm?: OfferFormRenterViewModel | null | undefined;
    reqId?: number;
    catId?: number;
    district?: string | null;
    fromToDate?: string;
};

const DashboardWaitingWorksBox = (props: DashboardWaitingworksBoxProps) => {
    const navigate = useNavigate();
    const buttons: JSX.Element[] = [];
    switch (props.offerForm?.status) {
        case "InReview":
            buttons[0] = (
                <SecondaryButton onClick={() => navigate("/requests/view?reqid=" + props.offerForm?.id)}>
                    {t("common.details")}
                </SecondaryButton>
            );
            buttons[1] = <PrimaryButton>{t("common.edit")}</PrimaryButton>;
            break;
        default:
            buttons[0] = (
                <SecondaryButton onClick={() => navigate("/offers/view?offerid=" + props.offerForm?.id + "&reqid=" + props.reqId)}>
                    {t("common.details")}
                </SecondaryButton>
            );
    }

    const selectedBid = props.offerForm?.bids?.find((bid) => bid.selected);
    return (
        <BoxContainer>
            <div
                style={{
                    display: "flex",
                    height: "100%",
                    flexDirection: "column",
                    justifyContent: "space-between",
                }}
            >
                <div
                    style={{
                        position: "absolute",
                        right: ".5rem",
                        top: ".5rem",
                    }}
                >
                    <RequestAndOfferStatus showOfferStatus={true} showRequestStatus={false} offerStatus={props.offerForm?.status} />
                </div>
                <div>
                    <Row gutter={[8, 12]}>
                        {props.catId && (
                            <Col xs={4} md={3}>
                                <img style={{ width: "48px" }} src={"/img" + props.catId + ".png"} alt={"/img" + props.catId + ".png"} />
                            </Col>
                        )}
                        <BasicModelViewer
                            xs={20}
                            md={21}
                            objKey={t("offer.id") + " : " + props.offerForm?.id}
                            value={props.offerForm?.machines?.[0].name}
                        />
                        <BasicModelViewer xs={12} md={12} objKey={t("request.requesteddates")} value={props.fromToDate} />
                        <BasicModelViewer xs={12} md={12} objKey={t("request.location")} value={props.district} />
                        <BasicModelViewer xs={12} md={12} objKey={t("offer.paymentinfo")} value={selectedBid?.maturityOption?.name} />
                        <BasicModelViewer xs={12} md={12} objKey={t("offer.paymentinfo")} value={selectedBid?.total} />
                        <div
                            style={{
                                display: "flex",
                                width: "100%",
                                gap: "1rem",
                            }}
                        >
                            {buttons}
                        </div>
                    </Row>
                </div>
            </div>
        </BoxContainer>
    );
};

export default DashboardWaitingWorksBox;
