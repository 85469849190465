import { Col, Form, message, Row } from "antd";
import { useEffect, useMemo, useState } from "react";
import { useAtom } from "jotai";
import PrimaryButton from "../../../components/atomics/primary-button";
import SecureLayout from "../../../components/layouts/secure-layout";
import { useQuery } from "../../../helpers/use-query";
import { extendedMachineDetailAtom, loadingAtom, postloginMessageAtom } from "../../../store/global-atoms";
import { t } from "../../../translate";
import { useSecureService } from "../../../store/service-atoms";
import {
    BasicValueModel,
    DocumentCategory,
    DocumentModel,
    MachineDocumentViewModel,
    MachineRentalTypeModel,
    MachineSaleCommissionViewModel,
    MachineSaleDepositFeeViewModel,
} from "../../../service";
import { useNavigate } from "react-router-dom";
import MachineMachineSaleInfoPart from "../../../page-parts/machine-machine-sale-info-part";
import MachineSaleAdditionalInfoPart from "../../../page-parts/machine-sale-additional-info-part";
import MachineSalePricePart from "../../../page-parts/machine-sale-price-part";

const AddSaleMachine = () => {
    const query = useQuery();
    const companyId = query.get("compid");
    const macId = query.get("macid");
    const [form] = Form.useForm();
    const [editedMachine, editMachine] = useAtom(extendedMachineDetailAtom);
    const { doPostCreateMachine, doCreateSaleFromRental, doGetMachineCommissionsForSale, doGetMachineCommissionsForFee, doGetExchangeRates } = useSecureService();
    const [docs, setDocs] = useState<MachineDocumentViewModel[]>([]);
    const navigate = useNavigate();
    const [, setMessage] = useAtom(postloginMessageAtom);
    const [loading] = useAtom(loadingAtom);
    const [fees, setFees] = useState<MachineSaleDepositFeeViewModel[] | undefined>(undefined);
    const [selectedSubcategory, setSelectedSubcategory] = useState<{ catid: Number; subCatid: Number } | undefined>(undefined);
    const [commissions, setCommissions] = useState<MachineSaleCommissionViewModel[] | undefined>(undefined);
    const [exchangeRates, setExchangeRates] = useState<BasicValueModel[] | undefined>(undefined);
    const [totalPrice, setTotalPrice] = useState(0);
    const [priceCurrency, setPriceCurrency] = useState("");
    const [dataFetching, setDataFetching] = useState(true);
    const [infoDataFetched, setInfoDataFetched] = useState(false);

    const pics = useMemo(() => {
        return editedMachine?.documents?.filter((doc) => doc.documentCategory === "Photo");
    }, [editedMachine?.documents]);

    useEffect(() => {
        editMachine((mach) => {
            return {
                ...mach,
                lessorCompanyId: companyId ? parseInt(companyId) : undefined,
                id: undefined,
            };
        });
        setDocs(editedMachine?.documents ?? []);
        const getCommissionsAndFees = async () => {
            setFees(await doGetMachineCommissionsForFee());
            setCommissions(await doGetMachineCommissionsForSale());
            setExchangeRates(await doGetExchangeRates());
        };
        getCommissionsAndFees();
        //eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (exchangeRates && commissions && fees && infoDataFetched) {
            setDataFetching(false);
        }
    }, [exchangeRates, commissions, fees, infoDataFetched]);

    const doCreateSaleMachine = async (params: any) => {
        if (editedMachine) {
            let result: boolean = false;
            if (macId === null) {
                result = await doPostCreateMachine({ ...params, documents: docs, isMachineForSale: true }, 1, params.id);
            }
            else {
                result = await doCreateSaleFromRental({ ...params, documents: docs, isMachineForSale: true }, params.id);
            }
            if (result) {
                setMessage({
                    message: t("machines.add.successCreate"),
                    type: "success",
                });
                navigate("/dashboard");
            }
        }
    };

    const checkFields = async (): Promise<boolean> => {
        var saleTitleCheck = await form.getFieldValue(['saleAdTitle']);
        if (saleTitleCheck === null || saleTitleCheck === undefined || saleTitleCheck.trim() === ""){
            message.error(t("machines.add.AdLabel-error"));
            return false
        }

        return true;
    }
    
    const onMachineFormFinish = async (params: any) => {
        let rentalTypes: MachineRentalTypeModel[] = [];
        if ( !(await checkFields()) ) {
            return;
        }
        editMachine((oldVal) => {
            return {
                ...oldVal,
                ...params,
                rentalTypes: rentalTypes,
                saleAdTitle: params.saleAdTitle.trim(),
                documents: docs,
            };
        });

        await doCreateSaleMachine(params);
    };

    const uploadedFilesChanged = (docs: DocumentModel[], category: DocumentCategory) => {
        setDocs((oldVal) => {
            var newCatDoc = oldVal?.filter((doc) => {
                return doc.documentCategory !== category;
            });
            if (!newCatDoc) newCatDoc = [];
            var addedDocs: MachineDocumentViewModel[] = docs.map((doc) => {
                return {
                    documentCategory: category,
                    document: doc,
                };
            });
            newCatDoc?.push(...addedDocs);

            return [...newCatDoc];
        });
    };

    const calculatedPrices: {
        depositFee: string;
        commissionPrice: string;
        total: string;
    } = useMemo(() => {
        var commissionRate = commissions?.find((x) => x.subCategoryId === selectedSubcategory?.subCatid)?.priceRate || 0;
        var exchangeRate = exchangeRates?.find((x) => x.name === priceCurrency)?.value || 1;
        var commission = (commissionRate / 100) * totalPrice * exchangeRate;
        var totalPriceTL = totalPrice * exchangeRate;
        var netPriceTL = totalPriceTL - commission;

        return {
            depositFee: (fees?.find((x) => x.subCategoryId === selectedSubcategory?.subCatid)?.depositFee || 0).toString() + " TL",
            commissionPrice: commission.toFixed(2).toString() + " TL",
            total: netPriceTL.toFixed(2).toString() + " TL",
        };
    }, [fees, commissions, selectedSubcategory, priceCurrency, totalPrice, exchangeRates]);

    const allInfoDataFetched = () => {
        setInfoDataFetched(true);
    }

    return (
        <SecureLayout loading={dataFetching} activePage={"machines"} role={"lessor"} title={t("machines.add.title")} description={t("machines.add.description")}>
            <Form name="add-machine" initialValues={editedMachine} form={form} onFinish={onMachineFormFinish} layout="vertical">
                <Row gutter={[4, 4]}>
                    <Col xs={24} md={12}>
                        <MachineMachineSaleInfoPart
                            allDataFetched={allInfoDataFetched}
                            uploadChange={uploadedFilesChanged}
                            docs={pics}
                            form={form}
                            subcategoryChanged={(catid, subcatid) => setSelectedSubcategory({ catid: catid, subCatid: subcatid })}
                        />
                    </Col>

                    <Col xs={24} md={12}>
                        <Row gutter={[4, 4]}>
                            <Col md={24} xs={24}>
                                <MachineSaleAdditionalInfoPart />
                            </Col>
                            <Col md={24} xs={24}>
                                <MachineSalePricePart
                                    onCurrencyChange={(val) => setPriceCurrency(val || "")}
                                    onPriceChange={(val) => setTotalPrice(val)}
                                    depositFee={calculatedPrices.depositFee}
                                    machineGoCommission={calculatedPrices.commissionPrice}
                                    totalPriceForMachineOwner={calculatedPrices.total}
                                    initialInit={false}
                                />
                            </Col>
                        </Row>
                    </Col>

                    <Col xs={6} offset={9}>
                        <Form.Item>
                            <PrimaryButton htmlType="submit" loading={loading} style={{ marginTop: "1rem" }}>
                                {t("common.save")}
                            </PrimaryButton>
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </SecureLayout>
    );
};

export default AddSaleMachine;
