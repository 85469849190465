import { Col, Form, Row } from "antd";
import TextArea from "antd/lib/input/TextArea";
import { MachinegoDocumentUploader } from "../components/atomics/machinego-document-uploader";
import BigboxContainer from "../components/containers/bigbox-container";
import { DocumentCategory, DocumentModel } from "../service";
import { postloginMessageAtom } from "../store/global-atoms";
import { t } from "../translate";
import { useAtom } from "jotai";

type OfferAdditionalInfoPartProps = {
    uploadChange?: (params: DocumentModel[], category?: DocumentCategory) => void;
    docs?: DocumentModel[];
};
const OfferAdditionalInfoPart = (props: OfferAdditionalInfoPartProps) => {
    const [, setErrorMessage] = useAtom(postloginMessageAtom);
    /* 

    const UploaderCreator = (subProps:{docCat:DocumentCategory,maxCount:number}&ColProps) => {
        return (
            <Col xs={24} md={12} {...subProps}>
                <MachinegoDocumentUploader 
                    onChange={handleUploadChange}
                    maxCount={subProps.maxCount}
                    title={subProps.docCat}
                    docType={subProps.docCat}
                    unsupportedFileError={() => setErrorMessage({ message: t("common.unsupportedFile"), type: "error" })} 
                    multiple={subProps.maxCount<=1?false:true} />
            </Col>
        )
    } */

    const handleUploadChange = (data: DocumentModel[]) => {
        if (props.uploadChange) props.uploadChange(data);
    };

    return (
        <BigboxContainer title={t("offers.add.additionalInfoTitle")}>
            <Form.Item label={t("offers.add.notesLabel")} name="notes">
                <TextArea autoSize={{ minRows: 5, maxRows: 10 }} placeholder={t("offers.add.notesPlaceholder")} />
            </Form.Item>

            <h4 style={{ marginTop: "1rem" }}>{t("machines.add.documentsTitle")}</h4>
            <Row gutter={[16, 16]}>
                <Col xs={24} md={24}>
                    <MachinegoDocumentUploader
                        onFilesChange={handleUploadChange}
                        maxCount={5}
                        title={t("offers.add.documentSubtitle")}
                        defaultDocumentFiles={props.docs}
                        description={t("offers.add.documentDescription")}
                        docType={DocumentCategory.Other}
                        unsupportedFileError={() =>
                            setErrorMessage({
                                message: t("common.unsupportedFile"),
                                type: "error",
                            })
                        }
                        multiple={true}
                    />
                </Col>
            </Row>
        </BigboxContainer>
    );
};

export default OfferAdditionalInfoPart;
