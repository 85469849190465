import { t, trLocale } from "../../translate";
import { useAtom } from "jotai";
import RequestLayout from "../../components/layouts/request-layout";
import { useNavigate } from "react-router-dom";
import Accordion from "../../components/atomics/accordion";
import { Checkbox, Col, DatePicker, Form, Row, Select } from "antd";
import { EventHandler, useState, useEffect } from "react";
import CheckboxButton from "../../components/atomics/checkbox-button";
import { BasicModel, RequestFormMachineFeatures } from "../../service/models";
import { loadingAtom, requestFormAtom } from "../../store/global-atoms";
import moment from "moment";
import "moment/locale/tr";
import { RangeValue } from "rc-picker/lib/interface";
import {
    BladeWidth,
    CleaningCapacity,
    CompressionWidth,
    ConcretePumpingCapacity,
    EnginePower,
    FlightDistance,
    FreeAirOutput,
    Frequency,
    GarbageCollectionBin,
    Height,
    LoadCapacity,
    MaxDepth,
    MaxFeedingSize,
    MaxLoadCapacity,
    MaxPower,
    MaxPressure,
    MaxWidth,
    OperatingWeight,
    OperationWidth,
    SpraySpeed,
    TankVolume,
    Volume,
    WaterTankCapacity,
    Width,
    WorkingRadius,
    WorkingSpeed,
    NominalCapacity,
    MaxHeight,
    OperatingHeight,
    FuelTypeId,
    TankCapacity,
    FlightTime,
    MinPower,
    CarryingCapacity,
} from "../../components/atomics/form-items";
import { useSecureService } from "../../store/service-atoms";
import BoxContainer from "../../components/containers/box-container";
import { CityModel } from "../../service/models/CityModel";

const { RangePicker } = DatePicker;
const { Option } = Select;

const CreateAttachmentCheckboxes = (model: BasicModel, evt: EventHandler<any>, hiddenInput: boolean, otherAttachmentContent?: string) => {
    const inputChange = (element: any) => {
        evt("{other}:" + element.target.value);
    };
    if (model.id === undefined) return <></>;
    return (
        <Col xs={24} lg={12}>
            <CheckboxButton
                hiddenInput={hiddenInput}
                inputLabel={t("machines.add-new.otherAttachmentInputlabel")}
                inputChange={hiddenInput ? inputChange : undefined}
                required={true}
                value={model.id.toString()}
                content={model.name}
                checkboxClicked={evt}
                inputInitialValue={otherAttachmentContent}
            />
        </Col>
    );
};

const LocationAndDate = (props: { secure?: boolean }) => {
    const navigate = useNavigate();
    const [continueVisible, setContinueVisible] = useState(false);
    const [selectedCheckboxes, changeSelectedCheckboxes] = useState<any[]>([]);
    const [otherInputVisible, setOtherInputVisible] = useState(false);
    const [otherAttachmentName, setOtherAttachmentName] = useState<string | undefined>(undefined);
    const { doGetAttachments, doGetExactCities, doGetFuelTypes } = useSecureService();
    const [selectedDistrict, setDistrict] = useState<string>("");
    const [selectedCity, setCity] = useState<
        | {
              id?: number | null;
              name?: string | null;
              districts?: BasicModel[] | null;
          }
        | undefined
    >(undefined);
    const [attachments, setAttachments] = useState<BasicModel[] | undefined>(undefined);
    const [districts, setDistrictsDropdown] = useState<BasicModel[] | undefined>(undefined);
    const [selectedDates, setSelectedDates] = useState<RangeValue<moment.Moment>>();
    const [editedRequest, editRequest] = useAtom(requestFormAtom);
    const [loading] = useAtom(loadingAtom);
    const [allCities, setAllCities] = useState<CityModel[] | undefined>(undefined);
    const [fuelTypes, setFuelTypes] = useState<BasicModel[] | undefined>(undefined);

    const [form] = Form.useForm();
    
    useEffect(() => {
        const fillDropdowns = async () => {
            if (editedRequest?.subCategory?.id) {
                const val = await doGetAttachments(editedRequest.subCategory.id);
                const cities = await doGetExactCities();
                setAttachments(val);
                setAllCities(cities);
            }
        };
        fillDropdowns();

        const getFuelTypes = async () => {
            const fuels = await doGetFuelTypes();
            setFuelTypes(fuels);
        };
        getFuelTypes();
        
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (selectedDates && selectedDates.length > 1 && selectedCity && selectedDistrict) setContinueVisible(true);
        else setContinueVisible(false);
    }, [selectedCheckboxes, selectedCity, selectedDistrict, selectedDates]);

    useEffect(() => {
        if (editedRequest?.attachments && editedRequest.attachments.length > 0) {
            const attachmentIds = editedRequest.attachments
                .filter((item) => item !== undefined && item.id !== undefined)
                .map((item) => item?.id?.toString());
            if (attachmentIds.includes("0") && editedRequest.attachmentsOther) {
                setOtherAttachmentName(editedRequest.attachmentsOther);
                setOtherInputVisible(true);
            }
            changeSelectedCheckboxes(attachmentIds);
        }
         if (editedRequest?.district?.name) {
            allCities?.forEach((cty) => {
                if (cty.districts?.find((dst) => dst.name === editedRequest.district?.name)) {
                    setDistrictsDropdown(cty.districts);
                    setCity(cty);
                }
            });
            setDistrict(editedRequest.district.name);
        }

        if (editedRequest?.fromDateRaw && editedRequest.toDateRaw) setSelectedDates([moment(editedRequest.fromDateRaw), moment(editedRequest.toDateRaw)]); 
        // eslint-disable-next-line
    }, [editedRequest, allCities]);

    const checkboxClicked = (val: string) => {
        if (val.startsWith("{other}:") && selectedCheckboxes.includes("0")) {
            val = val.replace("{other}:", "");
            setOtherAttachmentName(val);
            return;
        }
        let newList: any[] = [];
        if (selectedCheckboxes.includes(val)) {
            newList = selectedCheckboxes.filter((att) => {
                return att !== val;
            });
        } else newList = [...selectedCheckboxes, val];

        //Other checkbox value is 0
        if (newList.includes("0")) setOtherInputVisible(true);
        else {
            setOtherInputVisible(false);
            setOtherAttachmentName(undefined);
        }
        changeSelectedCheckboxes(newList);
    };

    const checkboxes = attachments?.map((attachment) => {
        if (attachment.id === 0)
            return CreateAttachmentCheckboxes(
                attachment,
                checkboxClicked,
                otherInputVisible,
                editedRequest && editedRequest.attachmentsOther ? editedRequest.attachmentsOther : undefined
            );
        else return CreateAttachmentCheckboxes(attachment, checkboxClicked, false);
    });

    const AccordionItems = [
        {
            key: 0,
            title: t("common.attachments"),
            children: <Row gutter={[16, 16]}>{checkboxes}</Row>,
        },
    ];

    const onCitySelected = async (selectedCityName: string) => {
        setDistrict("");
        const currentCity = allCities?.find((city) => city.name === selectedCityName);
        setCity(currentCity);
        if (currentCity && currentCity.districts) setDistrictsDropdown(currentCity.districts);
        else setDistrictsDropdown(undefined);
    };

    function disabledDate(current: any) {
        // Can not select days before today and today
        return current && current < moment().endOf("day");
    }

    const dateChanged = (val: RangeValue<moment.Moment>) => {
        setSelectedDates(val);
    };

    const continueClicked = () => {
        form.submit();
    };
    
    const onFormFinish = async (parameters: any) => {
        const allSelectedAtch: BasicModel[] = [];
        selectedCheckboxes.forEach((item: string) => {
            const selected = attachments?.find((atc) => atc.id?.toString() === item.toString());
            if (selected) allSelectedAtch.push(selected);
        });

        const distr: BasicModel | undefined = districts?.find((dst) => dst.name === selectedDistrict);
        const fuelType = fuelTypes?.find((x) => x.name === parameters.fuelTypeName);

        editRequest((oldVal) => {
            const params: RequestFormMachineFeatures = {
                operationWidth: parameters.operationWidth,
                width: parameters.width,
                volume: parameters.volume,
                height: parameters.height,
                enginePower: parameters.enginePower,
                operatingWeight: parameters.operatingWeight,
                bladeWidth: parameters.bladeWidth,
                cleaningCapacity: parameters.cleaningCapacity,
                compressionWidth: parameters.compressionWidth,
                concretePumpingCapacity: parameters.concretePumpingCapacity,
                flightDistance: parameters.flightDistance,
                freeAirOutput: parameters.freeAirOutput,
                frequency: parameters.frequency,
                garbageCollectionBin: parameters.garbageCollectionBin,
                loadCapacity: parameters.loadCapacity,
                maxDepth: parameters.maxDepth,
                maxLoadCapacity: parameters.maxLoadCapacity,
                maxPower: parameters.maxPower,
                maxPressure: parameters.maxPressure,
                maxWidth: parameters.maxWidth,
                spraySpeed: parameters.spraySpeed,
                tankVolume: parameters.tankVolume,
                workingSpeed: parameters.workingSpeed,
                workingRadius: parameters.workingRadius,
                waterTankCapacity: parameters.waterTankCapacity,
                maxHeight: parameters.maxHeight,
                nominalCapacity: parameters.nominalCapacity,
                operatingHeight: parameters.operatingHeight,
                tankCapacity: parameters.tankCapacity,
                minPower: parameters.minPower,
                flightTime: parameters.flightTime,
                carryingCapacity: parameters.carryingCapacity,
            };
            return {
                ...oldVal,
                machineFeatures: params,
                fromDateRaw: selectedDates?.[0],
                toDateRaw: selectedDates?.[1],
                district: {
                    ...distr,
                    city: {
                        id: selectedCity?.id || undefined,
                        name: selectedCity?.name,
                    },
                },
                attachmentsOther: otherAttachmentName,
                attachments: allSelectedAtch,
                fuelType: fuelType,
            };
        });
        if (props.secure) navigate("/requests-and-offers/add-machine-detail");
        else navigate("/request/machine-detail");
    };
    const subId = editedRequest?.subCategory?.id;
    const baseCategory = editedRequest?.subCategory?.category?.id;
    const selectedMachine = editedRequest?.machineFeatures;
    const mediaQueryList = window.matchMedia('(max-width: 658px)')
    console.log(mediaQueryList)
    return (
        <RequestLayout currentStep={1} continueVisible={continueVisible} onContinueClick={continueClicked}>
            <Form name="request-properties" onFinish={onFormFinish} layout="vertical" style={{ overflow: "hidden" }} form={form}>
                {attachments && (
                    <>
                        <h3>{t("request.location-and-date.selectAttachments")}</h3>
                        <Checkbox.Group defaultValue={undefined} value={selectedCheckboxes} style={{ width: "100%" }}>
                            <Accordion
                                items={AccordionItems}
                                activeKey={editedRequest?.attachments && editedRequest.attachments.length > 0 ? 0 : undefined}
                            />
                        </Checkbox.Group>
                    </>
                )}
                <h3>{t("request.location-and-date.machineFeatures")}</h3>
                <BoxContainer>
                    <Row gutter={[24, 24]}>
                        {CompressionWidth(baseCategory, subId, selectedMachine?.compressionWidth, true)}
                        {OperatingWeight(baseCategory, subId, selectedMachine?.operatingWeight, true)}
                        {SpraySpeed(baseCategory, subId, selectedMachine?.spraySpeed, true)}
                        {TankVolume(baseCategory, subId, selectedMachine?.tankVolume, true)}
                        {MaxFeedingSize(baseCategory, subId, selectedMachine?.maxFeedingSize, true)}
                        {WaterTankCapacity(baseCategory, subId, selectedMachine?.waterTankCapacity, true)}
                        {Height(baseCategory, subId, selectedMachine?.height, true)}
                        {MaxDepth(baseCategory, subId, selectedMachine?.maxDepth, true)}
                        {MaxWidth(baseCategory, subId, selectedMachine?.maxWidth, true)}
                        {ConcretePumpingCapacity(baseCategory, subId, selectedMachine?.concretePumpingCapacity, true)}
                        {MaxPressure(baseCategory, subId, selectedMachine?.maxPressure, true)}
                        {LoadCapacity(baseCategory, subId, selectedMachine?.loadCapacity, true)}
                        {BladeWidth(baseCategory, subId, selectedMachine?.bladeWidth, true)}
                        {Frequency(baseCategory, subId, selectedMachine?.frequency, true)}
                        {MinPower(baseCategory, subId, selectedMachine?.maxPower, true)}
                        {MaxPower(baseCategory, subId, selectedMachine?.maxPower, true)}
                        {FreeAirOutput(baseCategory, subId, selectedMachine?.freeAirOutput, true)}
                        {Width(baseCategory, subId, selectedMachine?.width, true)}
                        {Volume(baseCategory, subId, selectedMachine?.volume, true)}
                        {WorkingRadius(baseCategory, subId, selectedMachine?.workingRadius, true)}
                        {MaxLoadCapacity(baseCategory, subId, selectedMachine?.maxLoadCapacity, true)}
                        {EnginePower(baseCategory, subId, selectedMachine?.enginePower, true)}
                        {WorkingSpeed(baseCategory, subId, selectedMachine?.workingSpeed, true)}
                        {OperationWidth(baseCategory, subId, selectedMachine?.operationWidth, true)}
                        {GarbageCollectionBin(baseCategory, subId, selectedMachine?.garbageCollectionBin, true)}
                        {CleaningCapacity(baseCategory, subId, selectedMachine?.cleaningCapacity, true)}
                        {FlightDistance(baseCategory, subId, selectedMachine?.flightDistance, true)}
                        {NominalCapacity(baseCategory, subId, selectedMachine?.nominalCapacity, true)}
                        {MaxHeight(baseCategory, subId, selectedMachine?.maxHeight, true)}
                        {OperatingHeight(baseCategory, subId, selectedMachine?.operatingHeight, true)}
                        {TankCapacity(baseCategory, subId, selectedMachine?.tankCapacity, true)}
                        {FlightTime(baseCategory, subId, selectedMachine?.flightTime, true)}
                        {CarryingCapacity(baseCategory, subId, selectedMachine?.carryingCapacity, true)}
                        <FuelTypeId cid={baseCategory} sid={subId} defaultValue="" prelogin={true} />
                    </Row>
                </BoxContainer>
                <h3>{t("request.location-and-date.selectLocation")}</h3>
                <Row gutter={[24, 24]} style={{ marginBottom: "1.5rem" }}>
                    <Col xs={24} lg={12}>
                        <Select getPopupContainer={(trigger: any) => trigger.parentElement} loading={loading} onSelect={onCitySelected} style={{ width: "100%" }} value={selectedCity?.name}>
                            {allCities?.map((mc) => (
                                <Option value={mc.name}>{mc.name}</Option>
                            ))}
                        </Select>
                    </Col>
                    <Col xs={24} lg={12}>
                        <Select
                            loading={loading}
                            style={{ width: "100%" }}
                            value={selectedDistrict}
                            onSelect={(e: string) => setDistrict(e)}
                            getPopupContainer={(trigger: any) => trigger.parentElement}
                        >
                            {districts?.map((msc) => (
                                <Option value={msc.name}>{msc.name}</Option>
                            ))}
                        </Select>
                    </Col>
                </Row>
                <h3>{t("request.location-and-date.selectdates")}</h3>
                <RangePicker
                    value={selectedDates}
                    placement="topRight"
                    onChange={dateChanged}
                    inputReadOnly={mediaQueryList.matches}
                    locale={trLocale}
                    format="DD-MM-YYYY"
                    disabledDate={disabledDate}
                    size="large"
                    style={{
                        width: "100%",
                        height: "48px",
                        background: "white",
                        marginBottom: "1.5rem",
                    }}
                ></RangePicker>
            </Form>
        </RequestLayout>
    );
};

export default LocationAndDate;
