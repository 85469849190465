import React from "react";

export interface CenterDivProps {
    children: JSX.Element;
}

const CenterDiv = (props: CenterDivProps) => {
    return <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>{props.children}</div>;
};

export default CenterDiv;
