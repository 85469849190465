import { useEffect, useState } from "react";
import { Upload } from "antd";
import { UploadChangeParam, UploadProps } from "antd/lib/upload";
import { RcFile, UploadFile } from "antd/lib/upload/interface";
import { MachineDocumentViewModel } from "../../service";
import { activeApiBaseUrl } from "../../store/app-constants";
import TextButton from "./text-button";
import { t } from "../../translate";

export type UploaderProp = {
    maxSize: number;
    onChange?: (info: UploadChangeParam<UploadFile<unknown>>) => void;
    requestUrl: string;
    defaultImageFiles?: MachineDocumentViewModel[] | null;
    unsupportedFileError?: () => void;
    buttonText?: string;
} & UploadProps;

export const Uploader = (props: UploaderProp) => {
    const [fileList, setFileList] = useState<UploadFile[]>([]);

    const onChange = (params: any) => {
        setFileList(params.fileList);
        if (props.onChange) props.onChange(params);
    };

    const onPreview = async (file: any) => {
        let src = file.name;
        if (src !== undefined) {
            var url = window.URL.createObjectURL(file.originFileObj);
            var anchor = document.createElement('a');
            anchor.href = url;
            if (true) {
                anchor.target = '_blank';
            }
            anchor.click();
            //var w = window.open(src);
            //w?.document.write(`<!DOCTYPE html><head><title>Document preview</title></head><body><img src="${blob}" ></body></html>`);
            
        }
    };

    useEffect(() => {
        let docs: UploadFile[] = [];
        if (props.defaultImageFiles !== undefined && props.defaultImageFiles !== null && props.defaultImageFiles.length > 0) {
            props.defaultImageFiles?.forEach((pic) => {
                if (pic.document !== undefined && pic.document.id !== undefined && pic.document.fileName !== undefined)
                    docs.push({
                        uid: pic?.document?.id?.toString(),
                        name: pic.document.fileName || "",
                        status: "done",
                        url: activeApiBaseUrl + "/Document/" + pic.document.id,
                        response: {
                            id: pic.document.id,
                            fileName: pic.document.fileName,
                        },
                    });
            });
        }

        setFileList(docs);
    }, [props.defaultImageFiles]);

    const supportedFileTypes = ["image/jpeg", "image/gif", "image/png"];

    const beforeUpload = (file: RcFile) => {
        let result = supportedFileTypes.includes(file.type);
        if (!result) props.unsupportedFileError ? props.unsupportedFileError() : console.log(file.type);
        //show message with atom
        return result || Upload.LIST_IGNORE;
    };

    const uploader = (
        <Upload
            action={props.requestUrl}
            beforeUpload={beforeUpload}
            withCredentials={true}
            name="document"
            listType="picture-card"
            fileList={fileList}
            onChange={onChange}
            onPreview={onPreview}
        >
            {fileList.length < props.maxSize && (
                <TextButton>
                    +<p>{props.buttonText ?? t("common.upload")}</p>
                </TextButton>
            )}
        </Upload>
    );

    return uploader;
};
