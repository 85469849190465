/* tslint:disable */
/* eslint-disable */
/**
 * MachineGo P1 API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface MachineSaleListSearchFilterModel
 */
export interface MachineSaleListSearchFilterModel {
    /**
     * 
     * @type {number}
     * @memberof MachineSaleListSearchFilterModel
     */
    machineId?: number | null;
    /**
     * 
     * @type {number}
     * @memberof MachineSaleListSearchFilterModel
     */
    cityId?: number | null;
    /**
     * 
     * @type {number}
     * @memberof MachineSaleListSearchFilterModel
     */
    districtId?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof MachineSaleListSearchFilterModel
     */
    hasExpertise?: boolean | null;
    /**
     * 
     * @type {Array<number>}
     * @memberof MachineSaleListSearchFilterModel
     */
    categoryIds?: Array<number> | null;
    /**
     * 
     * @type {Array<number>}
     * @memberof MachineSaleListSearchFilterModel
     */
    subCategoryIds?: Array<number> | null;
    /**
     * 
     * @type {Array<number>}
     * @memberof MachineSaleListSearchFilterModel
     */
    brandIds?: Array<number> | null;
    /**
     * 
     * @type {number}
     * @memberof MachineSaleListSearchFilterModel
     */
    minPrice?: number | null;
    /**
     * 
     * @type {number}
     * @memberof MachineSaleListSearchFilterModel
     */
    maxPrice?: number | null;
}

export function MachineSaleListSearchFilterModelFromJSON(json: any): MachineSaleListSearchFilterModel {
    return MachineSaleListSearchFilterModelFromJSONTyped(json, false);
}

export function MachineSaleListSearchFilterModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): MachineSaleListSearchFilterModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'machineId': !exists(json, 'machineId') ? undefined : json['machineId'],
        'cityId': !exists(json, 'cityId') ? undefined : json['cityId'],
        'districtId': !exists(json, 'districtId') ? undefined : json['districtId'],
        'hasExpertise': !exists(json, 'hasExpertise') ? undefined : json['hasExpertise'],
        'categoryIds': !exists(json, 'categoryIds') ? undefined : json['categoryIds'],
        'subCategoryIds': !exists(json, 'subCategoryIds') ? undefined : json['subCategoryIds'],
        'brandIds': !exists(json, 'brandIds') ? undefined : json['brandIds'],
        'minPrice': !exists(json, 'minPrice') ? undefined : json['minPrice'],
        'maxPrice': !exists(json, 'maxPrice') ? undefined : json['maxPrice'],
    };
}

export function MachineSaleListSearchFilterModelToJSON(value?: MachineSaleListSearchFilterModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'machineId': value.machineId,
        'cityId': value.cityId,
        'districtId': value.districtId,
        'hasExpertise': value.hasExpertise,
        'categoryIds': value.categoryIds,
        'subCategoryIds': value.subCategoryIds,
        'brandIds': value.brandIds,
        'minPrice': value.minPrice,
        'maxPrice': value.maxPrice,
    };
}

