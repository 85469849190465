import { Col, Row, Spin } from "antd";
import { useAtom } from "jotai";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import PrimaryButton from "../../../components/atomics/primary-button";
import SecondaryButton from "../../../components/atomics/secondary-button";
import SecureLayout from "../../../components/layouts/secure-layout";
import { useQuery } from "../../../helpers/use-query";
import MachineRelatedOffersPart from "../../../page-parts/machine-related-offers-part";
import ViewAdditionalInfoPart from "../../../page-parts/view-additional-info-part";
import ViewAdditionalServicesPart from "../../../page-parts/view-additional-services-part";
import ViewMachineInfoPart from "../../../page-parts/view-machine-info-part";
import ViewServiceRegionsPart from "../../../page-parts/view-service-regions-part";
import { LessorPurpose, OfferFormMachineDetailViewModel } from "../../../service/models";
import { extendedMachineDetailAtom, lessorPurposeAtom, roleAtom, userAtom } from "../../../store/global-atoms";
import { useSecureService } from "../../../store/service-atoms";
import { t } from "../../../translate";
import style from "./machine-view.module.scss"

const ViewMachine = () => {
    const query = useQuery();
    const machineId = query.get("macid");
    const offerId = query.get("offerid");
    const [role] = useAtom(roleAtom);
    const [loading, setLoading] = useState(true);
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const offerIdN = parseInt(offerId || "NaN");
    const [user] = useAtom(userAtom);
    const [editedMachine, editMachine] = useAtom(extendedMachineDetailAtom);
    const [relatedOffers, setRelatedOffers] = useState<OfferFormMachineDetailViewModel[] | undefined>(undefined);
    const navigate = useNavigate();
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { doGetMachine, doDeleteMachine, doGetRelatedOffersOfMachine, doGetMachineForRent } = useSecureService();
    const [helperButtons, setHelperButtons] = useState<JSX.Element[]>([]);
    const [, setLessorPurpose] = useAtom(lessorPurposeAtom);
    useEffect(() => {
        if (machineId) {
            const machineIdN = parseInt(machineId);
            if (!Number.isNaN(machineIdN)) {
                if (role === "customer") {
                    doGetMachine(machineIdN, true).then((result) => {
                        editMachine(result);
                    });
                }
                if (role === "lessor") {
                    doGetMachine(machineIdN, true).then((result) => {
                        editMachine(result);
                    });
                    doGetRelatedOffersOfMachine(machineIdN).then((result) => {
                        setRelatedOffers(result);
                    });
                }
            }
        }
        //eslint-disable-next-line
    }, []);
    const deleteMachine = async () => {
        if (machineId) {
            const machineIdN = parseInt(machineId);
            if (!Number.isNaN(machineIdN)) {
                await doDeleteMachine(machineIdN);
                navigate(-1);
            }
        }
    };

    const createSale = async () => {
        if (machineId) {
            const machineIdN = parseInt(machineId);
            setLessorPurpose(LessorPurpose.OnlyForSale);
            navigate("/sale-machines/add?compid=" + user?.id + "&macid=" + machineIdN + "&macEx=t");
        }
    };

    const viewSale = async () => {
        if (machineId) {
            const machineIdN = parseInt(machineId);
            setLessorPurpose(LessorPurpose.OnlyForSale);
            navigate("/sale-machines/detail?macid=" + machineIdN);
        }
    };

    useEffect(() => {
        if (editedMachine) {
            setLoading(false);
        }
    }, [editedMachine]);

    useEffect(() => {
        if (!editedMachine?.isMachineForSale)
        {
            setHelperButtons([
                <PrimaryButton
                    onClick={() => {
                        navigate("/machines/edit?macid=" + machineId, { replace: true });
                    }}
                >
                    {t("common.edit")}
                </PrimaryButton>,
                <SecondaryButton onClick={createSale} style={{ width: "100%",  background: '#7F5808', color: '#FFFFFF', borderColor: '#7F5808'}}>
                    {t("machines.view.create-sale")}
                </SecondaryButton>,
                <SecondaryButton onClick={deleteMachine} negative>
                    {t("common.delete")}
                </SecondaryButton>,
            ]);
        }
        else {
            if (editedMachine.isMachineForSale) {
                setHelperButtons([
                    <PrimaryButton
                        onClick={() => {
                            navigate("/machines/edit?macid=" + machineId, { replace: true });
                        }}
                        style={{height: '52px'}}
                    >
                        {t("common.edit")}
                    </PrimaryButton>,
                    <PrimaryButton onClick={viewSale} style={{height: '52px', width: "100%",  background: '#7F5808', color: '#FFFFFF', borderColor: '#7F5808'}}>
                        {t("machines.view.view-sale")}
                    </PrimaryButton>,
                    <SecondaryButton onClick={deleteMachine} negative style={{height: '52px'}}>
                        {t("common.delete")}
                    </SecondaryButton>,
                ]);
            }
            else {
                setHelperButtons([
                    <PrimaryButton
                        onClick={() => {
                            navigate("/machines/edit?macid=" + machineId, { replace: true });
                        }}
                    >
                        {t("common.edit")}
                    </PrimaryButton>,
                    <SecondaryButton onClick={deleteMachine} negative>
                        {t("common.delete")}
                    </SecondaryButton>,
                ]);
            }

        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [editedMachine]);

    return (
        <SecureLayout
            activePage={"machines"}
            role={role}
            title={loading ? '' : t("machines.view.title")}
            description={loading ? '' : role === "lessor" ? t("machines.view.description.lessor") : t("machines.view.description.renter")}
            helperButtons={role === "lessor" && !loading ? helperButtons : undefined}
            machineApproved={editedMachine?.isApproved}
        >
            <Row hidden={!loading} style={{flex: 1, justifyContent: 'center', alignItems: 'center'}}>
                <Col sm={24}>
                    <Spin size="large" tip="Lütfen Bekleyiniz" className={style.spinMobile} />
                </Col>
            </Row>
            <Row hidden={loading}>
                <Row gutter={[4, 4]} style={{ marginBottom: "3rem" }}>
                    <Col xs={24} md={12}>
                        <ViewMachineInfoPart viewType="machine" machine={editedMachine} />
                    </Col>
                    <Col xs={24} md={12}>
                        <ViewAdditionalInfoPart viewType="machine" machine={editedMachine} />
                    </Col>
                    <Col xs={24} md={12}>
                        <ViewAdditionalServicesPart viewType="machine" machine={editedMachine} />
                    </Col>
                    <Col xs={24} md={12}>
                        <ViewServiceRegionsPart viewType="machine" machine={editedMachine} />
                    </Col>
                </Row>
                {relatedOffers && relatedOffers.length > 0 && <MachineRelatedOffersPart offers={relatedOffers} />}
            </Row>

        </SecureLayout>
    );
};

export default ViewMachine;
