import { Checkbox, Col, Collapse, Row } from "antd";
import { BasicModel, OfferFormBidViewModel, OfferFormRenterBidViewModel } from "../service";
import { ReactComponent as Plus } from "../components/assets/plus.svg";
import { ReactComponent as Minus } from "../components/assets/minus.svg";
import { t } from "../translate";

export type OfferViewBidPartsProps = {
    bids: (OfferFormBidViewModel & OfferFormRenterBidViewModel);
    contextId?: number;
    periodPaymentType?: string;
    periodPaymentTypeId?: number | string;
    periodFee: string | number;
    children?: any;
    onCheckboxChange?: (selected: boolean, contextId?: number, index?: number) => void;
    checked: boolean;
    index: number;
    availableAttachments: BasicModel[];
    dealAccepted?: boolean;
    bidSelected?: boolean;
    offerStatus?: string | null;
    role?: "customer" | "lessor" | "admin" | string;
}


const OfferViewBidParts = (props: OfferViewBidPartsProps) => {
    const { Panel } = Collapse;
    const getTotal = (total: number, num: number) => {
        return total + Math.ceil(num);
    };
    const offeredStatus = props.offerStatus?.toLowerCase() === "offered";
    const totalBid = 
        (props.role?.toLowerCase() === 'lessor' ?
            (
                (!offeredStatus ? (props.bids.predictedTotal ?? 0) : 0) + 
                (!offeredStatus ? (props.bids.lessorMachineGoCommission ?? 0) : 0) +
                (props.bids.shippingFee ?? 0) +
                (props.bids.shippingToBackFee ?? 0) +
                (props.bids?.attachments
                    ? props.bids.attachments.map((e) => {
                        return e.fee ?? 0;
                    })
                        ? props.bids.attachments
                            .map((e) => {
                                return e.fee ?? 0;
                            })
                            .reduce(getTotal, 0)
                        : 0
                    : 0)
            )
            :
            (
                (!offeredStatus ? (props.bids.predictedTotal ?? 0) : 0) + 
                (!offeredStatus ? (props.bids.machineGoCommission ?? 0) : 0) +
                (props.bids.shippingFeeWithCommission ?? 0) +
                (props.bids.shippingToBackFeeWithCommission ?? 0) +
                (props.bids?.attachments
                    ? props.bids.attachments.map((e) => {
                        return e.fee ?? 0;
                    })
                        ? props.bids.attachments
                            .map((e) => {
                                return e.fee ?? 0;
                            })
                            .reduce(getTotal, 0)
                        : 0
                    : 0)
            )
        );

    return (
        <Row style={{width: '100%'}}>
            <Col xs={24}>
                <Collapse
                    expandIconPosition={"right"}
                    expandIcon={(panelProps) => {
                        if (panelProps.isActive) return <Minus  />;
                        else return <Plus  />;
                    }}
                >
                    {props.role?.toLowerCase() === "customer" && !props.dealAccepted &&
                        <div style={{position: 'absolute', marginTop: '10px', marginLeft: '10px', display: 'flex', alignItems: 'center'}}>
                            <Checkbox checked={props.checked} onChange={e => props.onCheckboxChange ? props.onCheckboxChange(e.target.checked, props.contextId, props.index) : ""}></Checkbox> 
                        </div>
                    }
                    {
                        props.dealAccepted && props.bidSelected &&
                        <div style={{position: 'absolute', marginTop: '10px', marginLeft: '10px', display: 'flex', alignItems: 'center'}}>
                            <Checkbox checked></Checkbox> 
                        </div>
                    }
                    <Panel style={{fontWeight: '700'}} header={<span style={{marginLeft: '25px'}}>{props.bids?.maturityOption?.name}</span>} key={props.contextId ?? "-1"}>
                        <Row style={{width: '100%', display: 'flex', justifyContent: 'space-between'}}>
                            <Col xs={24} md={10} style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                                <Col xs={12}>
                                    <span style={{fontWeight: '600'}}>{t("offers.view.period")}</span>
                                </Col>
                                <Col xs={12} style={{borderRadius: '10px',  background: '#EEF0F2', display: 'flex', justifyContent: 'flex-end', alignItems: 'center' , height: '2rem'}}>
                                    <span style={{fontWeight: '800', marginRight: '20px'}}>{props.periodPaymentType}</span>
                                </Col>
                            </Col>

                            <Col xs={24} md={10} style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                                <Col xs={12}>
                                    <span style={{fontWeight: '600'}}>{t("offers.view.period.priceLabel" + props.periodPaymentTypeId)}</span>
                                </Col>
                                
                                <Col xs={12} style={{borderRadius: '10px',  background: '#EEF0F2', display: 'flex', justifyContent: 'flex-end', alignItems: 'center' , height: '2rem'}}>
                                    {
                                         props.role?.toLowerCase() === "lessor" ? 
                                         <span style={{marginRight: '20px'}}>{(!offeredStatus ? props.bids.lessorPeriodPayment : props.bids.bid) + " " + (props.bids.currency ? props.bids.currency.name : "")}</span>
                                     :
                                         <span style={{marginRight: '20px'}}>{(!offeredStatus ? props.bids.renterPeriodPayment : props.bids.bid) + " " + (props.bids.currency ? props.bids.currency.name : "")}</span>
                                    }
                                </Col>
                            </Col>
                        </Row>
                        <Row style={{display: 'flex', width: '100%'}}>

                            <Col xs={24} style={{display:'flex', justifyContent:'space-between', alignItems:'center', background: "#EEF0F2", height:'3rem', borderRadius: '10px', marginTop: '1.5rem'}}>

                                <Col xs={12} style={{fontWeight: '400', display:'flex', alignItems:'center', marginLeft: '15px'}}>
                                    <span style={{marginRight: '20px'}}>{t("offer.view.maturity.machine.price")}</span>
                                </Col>

                                <Col xs={12} style={{display:'flex', justifyContent:'flex-end', alignItems:'center', marginRight: '15px'}}>
                                    <span style={{marginRight: '20px'}}>{(!offeredStatus ? (props.bids.advancePayment ?? 0) : "-") + " " + (props.bids.currency ? props.bids.currency.name : "")}</span>
                                </Col>

                            </Col>

                            <Col xs={24} style={{display:'flex', justifyContent:'space-between', alignItems:'center', background: "#EEF0F2", height:'3rem', borderRadius: '10px', marginTop: '1.5rem'}}>
                                <Col xs={12} style={{fontWeight: '400', display:'flex', alignItems:'center', marginLeft: '15px'}}>
                                    <span style={{marginRight: '20px'}}>{t("offer.view.shipping.go")}</span>
                                </Col>

                                <Col xs={12} style={{display:'flex', justifyContent:'flex-end', alignItems:'center', marginRight: '15px'}}>
                                    {
                                        props.role?.toLowerCase() === "lessor" ? 
                                            <span style={{marginRight: '20px'}}>{(props.bids.shippingFee ? props.bids.shippingFee : "-") + " " + (props.bids.currency ? props.bids.currency.name : "")}</span>
                                        :
                                            <span style={{marginRight: '20px'}}>{(props.bids.shippingFeeWithCommission ? props.bids.shippingFeeWithCommission : "-") + " " + (props.bids.currency ? props.bids.currency.name : "")}</span>
                                    }
                                </Col>
                            </Col>

                            <Col xs={24} style={{display:'flex', justifyContent:'space-between', alignItems:'center', background: "#EEF0F2", height:'3rem', borderRadius: '10px', marginTop: '1.5rem'}}>

                                <Col xs={12} style={{fontWeight: '400', display:'flex', alignItems:'center', marginLeft: '15px'}}>
                                    <span style={{marginRight: '20px'}}>{t("offer.view.shipping.back")}</span>
                                </Col>

                                <Col xs={12} style={{display:'flex', justifyContent:'flex-end', alignItems:'center', marginRight: '15px'}}>
                                    {
                                        props.role?.toLowerCase() === "lessor" ? 
                                            <span style={{marginRight: '20px'}}>{(props.bids.shippingToBackFee ? props.bids.shippingToBackFee : "-") + " " + (props.bids.currency ? props.bids.currency.name : "")}</span>
                                        :
                                            <span style={{marginRight: '20px'}}>{(props.bids.shippingToBackFeeWithCommission ? props.bids.shippingToBackFeeWithCommission : "-") + " " + (props.bids.currency ? props.bids.currency.name : "")}</span>
                                    }
                                </Col>

                            </Col>
                        </Row>

                        <Row style={{display: 'flex', justifyContent: 'flex-start', width: '100%'}}>
                            <Row style={{marginTop: '30px', width: '100%'}}>
                                <Col xs={24} style={{fontWeight: '500', color: '#949394', marginLeft: '10px'}}>
                                    <span>{t("offer.view.attachments")}</span>
                                </Col>
                            </Row>
                           
                            <Row style={{display: 'flex', justifyContent: 'flex-start', marginTop: '1rem', width: '100%'}} gutter={[4,12]}>
                                {
                                    props.bids.attachments?.length === 0 ?
                                    <Col xs={24} md={3} style={{display: 'flex', alignItems: 'center', justifyContent: 'center', color: '#949394'}}>
                                        <span>{"-"}</span>
                                    </Col>
                                    :
                                    props.bids.attachments?.map((attachment) => {
                                    return(               
                                        <Col xs={24} md={12}>
                                            <Row style={{width: '100%', background: '#EEF0F2', borderRadius: '10px', display: 'flex', justifyContent: 'space-between', alignItems: 'center', fontWeight: '700', height: '3rem'}}>
                                                <Col xs={16} style={{paddingLeft: '5px'}}>
                                                    <span style={{ fontSize: '0.85rem', fontWeight: '700'}}>{props.availableAttachments.find(e=>e.id === attachment.attachmentId)?.name ?? ""}</span>
                                                </Col>
                                                <Col xs={8} style={{display: 'flex', justifyContent: 'flex-end'}}>
                                                    <span style={{marginRight: '8px',fontWeight: '800', fontSize: '1.1rem'}} >{attachment.fee + " " + (props.bids.currency ? props.bids.currency.name : "")}</span>
                                                </Col>
                                            </Row>
                                        </Col>
                                    );
                                })  
                                } 
                            </Row>
                        </Row>

                        <Row style={{width: '100%', marginTop: '20px', display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>

                                <span style={{fontWeight: '700'}}>{offeredStatus ? t("offer.view.total.offer") : t("offer.view.total.newOffer")}</span>
                                <span style={{fontWeight: '800'}}>{totalBid + " " + (props.bids.currency ? props.bids.currency.name : "")}</span>

                        </Row>
                    </Panel>
                </Collapse> 
            </Col>
        </Row>
    );
}


export default OfferViewBidParts;