export const allCategories = [
    {
        id: 12,
        name: "Asfalt ve Yol Makinalari",
        subCategories: [
            {
                id: 54,
                name: "El Silindiri",
                fields: ["expertiseReport", "modelName", "operationHour", "enginePower", "operatingWeight", "compressionWidth"],
                required: ["modelName", "brand", "modelYear", "operatingWeight"],
                requestFields: ["operatingWeight"],
            },
            {
                id: 55,
                name: "Toprak Silindiri",
                fields: ["expertiseReport", "modelName", "operationHour", "enginePower", "operatingWeight", "compressionWidth"],
                required: ["modelName", "brand", "modelYear", "operatingWeight"],
                brands: ["a", "b"],
                requestFields: ["operatingWeight"],
            },
            {
                id: 56,
                name: "Asfalt Silindiri",
                fields: [
                    "expertiseReport",
                    "modelName",
                    "operationHour",
                    "operatingWeight",
                    "enginePower",
                    "operatingWeight",
                    "compressionWidth",
                ],
                required: ["modelName", "brand", "modelYear", "operatingWeight"],
                requestFields: ["operatingWeight"],
            },
            {
                id: 77,
                name: "Mini Tandem Silindiri",
                fields: [
                    "expertiseReport",
                    "modelName",
                    "operationHour",
                    "operatingWeight",
                    "enginePower",
                    "operatingWeight",
                    "compressionWidth",
                ],
                required: ["modelName", "brand", "modelYear", "operatingWeight"],
                requestFields: ["operatingWeight"],
            },
            {
                id: 57,
                name: "Finişer (Asfalt Serici)",
                fields: ["expertiseReport", "modelName", "operationHour", "compressionWidth"],
                required: ["modelName", "brand", "modelYear"],
                requestFields: ["compressionWidth"],
            },

            {
                id: 75,
                name: "Lastik Tekerlekli Silindir",
                fields: ["expertiseReport", "modelName", "operationHour", "enginePower", "operatingWeight", "compressionWidth"],
                required: ["modelName", "brand", "modelYear", "operatingWeight"],
                requestFields: ["operatingWeight"],
            },
            {
                id: 60,
                name: "Asfalt Püskürtme Aracı",
                fields: ["expertiseReport", "modelName", "operationHour", "enginePower", "spraySpeed"],
                required: ["modelName", "brand", "modelYear", "spraySpeed"],
                requestFields: ["spraySpeed"],
            },
            {
                id: 61,
                name: "Su Tankeri",
                fields: ["expertiseReport", "modelName", "operationMileage", "operationHour", "tankVolume"],
                required: ["modelName", "brand", "modelYear", "tankVolume"],
                requestFields: ["tankVolume"],
            },
            {
                id: 62,
                name: "El Kompaktörü",
                fields: ["expertiseReport", "modelName", "operationHour", "enginePower", "operatingWeight"],
                required: ["modelName", "brand", "modelYear", "operatingWeight"],
                requestFields: ["loadCapacity"],
            },
            {
                id: 63,
                name: "Mobil Taş Kırma Makinası",
                fields: ["expertiseReport", "modelName", "operationHour", "enginePower", "maxFeedingSize", "loadCapacity"],
                required: ["modelName", "brand", "modelYear", "loadCapacity"],
                requestFields: ["loadCapacity"],
            },
        ],
    },
    {
        id: 5,
        name: "Beton Grubu",
        subCategories: [
            {
                id: 20,
                name: "Beton Transmikseri",
                fields: ["expertiseReport", "modelName", "operationMileage", "enginePower", "nominalCapacity", "waterTankCapacity"],
                required: ["modelName", "brand", "modelYear", "operationMileage", "nominalCapacity"],
                requestFields: ["nominalCapacity"],
            },
            {
                id: 21,
                name: "Mobil Beton Pompası",
                fields: [
                    "expertiseReport",
                    "modelName",
                    "operationHour",
                    "enginePower",
                    "maxHeight",
                    "maxDepth",
                    "maxWidth",
                    "concretePumpingCapacity",
                    "maxPressure",
                ],
                required: ["modelName", "brand", "modelYear", "operationHour", "maxHeight"],
                requestFields: ["maxHeight"],
            },
            {
                id: 22,
                name: "Sabit Beton Pompası",
                fields: [
                    "expertiseReport",
                    "modelName",
                    "operationHour",
                    "enginePower",
                    "maxHeight",
                    "concretePumpingCapacity",
                    "maxPressure",
                ],
                required: ["modelName", "brand", "modelYear", "operationHour", "maxHeight"],
                requestFields: ["maxHeight"],
            },
        ],
    },
    {
        id: 2,
        name: "Dozer",
        subCategories: [
            {
                id: 16,
                name: "Dozer",
                fields: ["expertiseReport", "modelName", "operationHour", "enginePower", "operatingWeight"],
                required: ["modelName", "brand", "modelYear", "operationHour", "operatingWeight"],
                requestFields: ["operatingWeight"],
            },
        ],
    },

    {
        id: 9,
        name: "Forklift ve İstifleyiciler",
        subCategories: [
            {
                id: 36,
                name: "Akülü Istifleyici",
                fields: [
                    "expertiseReport",
                    "modelName",
                    "operationHour",
                    "carryingCapacity",
                    "elevatorTypeId",
                    "liftingHeight",
                    "operatingArea",
                    "hasPlatform",
                ],
                required: ["modelName", "brand", "modelYear", "operationHour", "carryingCapacity"],
                requestFields: ["carryingCapacity"],
            },
            {
                id: 37,
                name: "Forklift",
                fields: [
                    "expertiseReport",
                    "modelName",
                    "operationHour",
                    "carryingCapacity",
                    "fuelTypeId",
                    "elevatorTypeId",
                    "liftingHeight",
                    "elevatorTypeId",
                ],
                required: ["modelName", "brand", "modelYear", "operationHour", "carryingCapacity", "fuelTypeId"],
                requestFields: ["carryingCapacity", "fuelTypeId"],
            },
            {
                id: 38,
                name: "Reachtruck",
                fields: ["expertiseReport", "modelName", "operationHour", "carryingCapacity", "liftingHeight"],
                required: ["modelName", "brand", "modelYear", "operationHour", "carryingCapacity"],
                requestFields: ["carryingCapacity"],
            },
            {
                id: 39,
                name: "Sipariş Toparlayıcı",
                fields: ["expertiseReport", "modelName", "operationHour", "carryingCapacity"],
                required: ["modelName", "brand", "modelYear", "operationHour", "carryingCapacity"],
                requestFields: ["carryingCapacity"],
            },
            {
                id: 40,
                name: "Akülü Çekici",
                fields: ["expertiseReport", "modelName", "operationHour", "carryingCapacity"],
                required: ["modelName", "brand", "modelYear", "operationHour", "carryingCapacity"],
                requestFields: ["carryingCapacity"],
            },
            {
                id: 41,
                name: "Dar Koridor Istif Makinası",
                fields: ["expertiseReport", "modelName", "operationHour", "carryingCapacity", "liftingHeight"],
                required: ["modelName", "brand", "modelYear", "operationHour", "carryingCapacity"],
                requestFields: ["carryingCapacity"],
            },
            {
                id: 42,
                name: "Akülü Transpalet",
                fields: ["expertiseReport", "modelName", "operationHour", "carryingCapacity", "hasPlatform"],
                required: ["modelName", "brand", "modelYear", "carryingCapacity"],
                requestFields: ["carryingCapacity"],
            },
        ],
    },
    {
        id: 3,
        name: "Greyder",
        subCategories: [
            {
                id: 17,
                name: "Greyder",
                fields: ["expertiseReport", "modelName", "operationHour", "enginePower", "operatingWeight", "bladeWidth"],
                required: ["modelName", "brand", "modelYear", "operationHour", "operatingWeight"],
                requestFields: ["operatingWeight"],
            },
        ],
    },
    {
        id: 11,
        name: "Jeneratör / Aydınlatma",
        subCategories: [
            {
                id: 49,
                name: "Gazlı Jeneratör",
                fields: ["expertiseReport", "modelName", "operationHour", "frequency", "maxPower", "hasCabin", "engineBrand"],
                required: ["modelName", "brand", "modelYear", "maxPower"],
                requestFields: ["minPower", "maxPower"],
            },
            {
                id: 50,
                name: "Dizel Jeneratör",
                fields: ["expertiseReport", "modelName", "operationHour", "frequency", "maxPower", "hasCabin", "engineBrand"],
                required: ["modelName", "brand", "modelYear", "maxPower"],
                requestFields: ["minPower", "maxPower"],
            },
            {
                id: 51,
                name: "Benzinli Jeneratör",
                fields: ["expertiseReport", "modelName", "operationHour", "frequency", "maxPower", "hasCabin", "engineBrand"],
                required: ["modelName", "brand", "modelYear", "maxPower"],
                requestFields: ["minPower", "maxPower"],
            },
            {
                id: 52,
                name: "Jeneratör",
                fields: ["expertiseReport", "modelName", "operationHour", "frequency", "maxPower", "fuelTypeId", "hasCabin", "engineBrand"],
                required: ["modelName", "brand", "modelYear", "maxPower"],
                requestFields: ["minPower", "maxPower"],
            },
            {
                id: 53,
                name: "Aydınlatma Kulesi",
                fields: [
                    "expertiseReport",
                    "modelName",
                    "operationHour",
                    "frequency",
                    "maxPower",
                    "fuelTypeId",
                    "hasTowerElevationSystem",
                    "lightningCapacity",
                ],
                required: ["modelName", "brand", "modelYear", "maxPower"],
                requestFields: ["minPower", "maxPower"],
            },
        ],
    },
    {
        id: 4,
        name: "Kamyonlar",
        subCategories: [
            {
                id: 10,
                name: "Kamyonlar",
                fields: ["expertiseReport", "modelName", "operationMileage", "enginePower", "maxLoadCapacity"],
                required: ["modelName", "brand", "modelYear", "operationMileage"],
                requestFields: ["maxLoadCapacity"],
            },
            {
                id: 18,
                name: "Kaya Kamyonu",
                fields: ["expertiseReport", "modelName", "operationMileage", "enginePower", "maxLoadCapacity"],
                required: ["modelName", "brand", "modelYear", "operationMileage"],
                requestFields: ["maxLoadCapacity"],
            },
            {
                id: 19,
                name: "Belden Kırmalı Damperli Kamyon",
                fields: ["expertiseReport", "modelName", "operationMileage", "enginePower", "maxLoadCapacity"],
                required: ["modelName", "brand", "modelYear", "operationMileage"],
                requestFields: ["maxLoadCapacity"],
            },
        ],
    },
    {
        id: 8,
        name: "Kompresörler",
        subCategories: [
            {
                id: 34,
                name: "Vidalı Kompresör",
                fields: ["expertiseReport", "modelName", "operationHour", "enginePower", "freeAirOutput"],
                required: ["modelName", "brand", "modelYear", "enginePower"],
                requestFields: ["enginePower"],
            },
            {
                id: 35,
                name: "Pistonlu Kompresör",
                fields: ["expertiseReport", "modelName", "operationHour", "enginePower", "freeAirOutput"],
                required: ["modelName", "brand", "modelYear", "enginePower"],
                requestFields: ["enginePower"],
            },
        ],
    },
    {
        id: 14,
        name: "Konteyner",
        subCategories: [
            {
                id: 66,
                name: "Ofis Konteyner",
                fields: ["modelName", "width", "volume", "height", "floorCount"],
                required: ["brand", "volume"],
                requestFields: ["volume"],
            },
            {
                id: 67,
                name: "Şantiye Konteynerı",
                fields: ["modelName", "width", "volume", "height", "floorCount"],
                required: ["brand", "volume"],
                requestFields: ["volume"],
            },
            {
                id: 68,
                name: "Yatakhane Konteynerı",
                fields: ["modelName", "width", "volume", "height", "floorCount"],
                required: ["brand", "volume"],
                requestFields: ["volume"],
            },
            {
                id: 69,
                name: "Bekçi Konteynerı",
                fields: ["modelName", "width", "volume", "height"],
                required: ["brand", "volume"],
                requestFields: ["volume"],
            },
            {
                id: 70,
                name: "Wc / Duş Konteyner",
                fields: ["modelName", "width", "volume", "height"],
                required: ["brand", "volume"],
                requestFields: ["volume"],
            },
        ],
    },
    {
        id: 10,
        name: "Manlift (Personel Yükselticiler) / Platformlar",
        subCategories: [
            {
                id: 43,
                name: "Eklemli Platformlar",
                fields: [
                    "expertiseReport",
                    "modelName",
                    "operationHour",
                    "fuelTypeId",
                    "operatingHeight",
                    "maxLoadCapacity",
                    "workingRadius",
                ],
                required: ["modelName", "brand", "modelYear", "operatingHeight", "fuelTypeId"],
                requestFields: ["operatingHeight"],
            },
            {
                id: 74,
                name: "Kamyon Üstü Sepetli Platform",
                fields: [
                    "expertiseReport",
                    "modelName",
                    "operationHour",
                    "fuelTypeId",
                    "bomLength",
                    "operatingHeight",
                    "maxLoadCapacity",
                    "workingRadius",
                ],
                required: ["modelName", "brand", "modelYear", "operatingHeight"],
                requestFields: ["operatingHeight"],
            },
            {
                id: 44,
                name: "Dikey Platform",
                fields: ["expertiseReport", "modelName", "operationHour", "operatingHeight", "maxLoadCapacity"],
                required: ["modelName", "brand", "modelYear", "operatingHeight"],
                requestFields: ["operatingHeight"],
            },
            {
                id: 45,
                name: "Teleskobik Platform",
                fields: [
                    "expertiseReport",
                    "modelName",
                    "operationHour",
                    "fuelTypeId",
                    "operatingHeight",
                    "maxLoadCapacity",
                    "workingRadius",
                ],
                required: ["modelName", "brand", "modelYear", "operatingHeight"],
                requestFields: ["operatingHeight"],
            },
            {
                id: 46,
                name: "Paletli Platform (Örümcek)",
                fields: ["expertiseReport", "modelName", "operationHour", "operatingHeight", "maxLoadCapacity", "workingRadius"],
                required: ["modelName", "brand", "modelYear", "operatingHeight"],
                requestFields: ["operatingHeight"],
            },
            {
                id: 47,
                name: "Iskele",
                fields: ["expertiseReport", "modelName", "operationHour", "operatingHeight", "maxLoadCapacity"],
                required: ["modelName", "brand", "modelYear", "operatingHeight"],
                requestFields: ["operatingHeight"],
            },
            {
                id: 48,
                name: "Makaslı Platform",
                fields: ["expertiseReport", "modelName", "operationHour", "fuelTypeId", "operatingHeight", "maxLoadCapacity"],
                required: ["modelName", "brand", "modelYear", "operatingHeight", "fuelTypeId"],
                requestFields: ["operatingHeight"],
            },
        ],
    },
    {
        id: 13,
        name: "Nakliye Makinalari",
        subCategories: [
            {
                id: 64,
                name: "Lowbed",
                fields: [
                    "expertiseReport",
                    "modelName",
                    "operationMileage",
                    "operationHour",
                    "enginePower",
                    "maxLoadCapacity",
                    "platformLength",
                    "platformWidth",
                ],
                required: ["modelName", "brand", "modelYear", "operationMileage", "enginePower", "maxLoadCapacity"],
                requestFields: ["maxLoadCapacity"],
            },
            {
                id: 65,
                name: "Çekici",
                fields: [
                    "expertiseReport",
                    "modelName",
                    "operationMileage",
                    "operationHour",
                    "enginePower",
                    "maxLoadCapacity",
                    "platformLength",
                    "platformWidth",
                ],
                required: ["modelName", "brand", "modelYear", "operationMileage", "enginePower", "maxLoadCapacity"],
                requestFields: ["maxLoadCapacity"],
            },
            {
                id: 71,
                name: "Traktör",
                fields: ["expertiseReport", "modelName", "operationMileage", "operationHour", "enginePower", "maxLoadCapacity"],
                required: ["modelName", "brand", "modelYear", "operationMileage", "enginePower", "maxLoadCapacity"],
                requestFields: ["maxLoadCapacity"],
            },
        ],
    },
    {
        id: 15,
        name: "Pick-up SUV",
        subCategories: [
            {
                id: 72,
                name: "Pick-up SUV",
                fields: ["modelName", "expertiseReport", "operationMileage", "enginePower", "fuelTypeId"],
                required: ["modelName", "brand", "modelYear", "enginePower", "operationMileage"],
                requestFields: ["enginePower"],
            },
        ],
    },
    {
        id: 7,
        name: "Temizlik Makineleri",
        subCategories: [
            {
                id: 29,
                name: "Yol Temizleme Makinası",
                fields: [
                    "expertiseReport",
                    "modelName",
                    "operationMileage",
                    "operationHour",
                    "enginePower",
                    "fuelTypeId",
                    "workingSpeed",
                    "cleaningCapacity",
                    "garbageCollectionBin",
                    "usageFields",
                    "operationWidth",
                ],
                required: ["modelName", "brand", "modelYear", "operationHour"],
                requestFields: ["workingSpeed"],
            },
            {
                id: 30,
                name: "Iticili Yıkama Makinası",
                fields: [
                    "expertiseReport",
                    "modelName",
                    "operationMileage",
                    "operationHour",
                    "enginePower",
                    "fuelTypeId",
                    "workingSpeed",
                    "cleaningCapacity",
                    "garbageCollectionBin",
                    "usageFields",
                    "operationWidth",
                ],
                required: ["modelName", "brand", "modelYear", "operationHour"],
                requestFields: ["workingSpeed"],
            },
            {
                id: 31,
                name: "Binicili Yıkama Makinası",
                fields: [
                    "expertiseReport",
                    "modelName",
                    "operationMileage",
                    "operationHour",
                    "enginePower",
                    "fuelTypeId",
                    "workingSpeed",
                    "cleaningCapacity",
                    "garbageCollectionBin",
                    "usageFields",
                    "operationWidth",
                ],
                required: ["modelName", "brand", "modelYear", "operationHour"],
                requestFields: ["workingSpeed"],
            },
            {
                id: 32,
                name: "Iticili Süpürme Makinası",
                fields: [
                    "expertiseReport",
                    "modelName",
                    "operationMileage",
                    "operationHour",
                    "enginePower",
                    "fuelTypeId",
                    "workingSpeed",
                    "cleaningCapacity",
                    "garbageCollectionBin",
                    "usageFields",
                    "operationWidth",
                ],
                required: ["modelName", "brand", "modelYear", "operationHour"],
                requestFields: ["workingSpeed"],
            },
            {
                id: 33,
                name: "Süpürme & Yıkama Makinası",
                fields: [
                    "expertiseReport",
                    "modelName",
                    "operationMileage",
                    "operationHour",
                    "enginePower",
                    "fuelTypeId",
                    "workingSpeed",
                    "cleaningCapacity",
                    "garbageCollectionBin",
                    "usageFields",
                    "operationWidth",
                ],
                required: ["modelName", "brand", "modelYear", "operationHour"],
                requestFields: ["workingSpeed"],
            },
        ],
    },
    {
        id: 6,
        name: "Vinçler",
        subCategories: [
            {
                id: 23,
                name: "Mobil Vinç",
                fields: ["expertiseReport", "modelName", "operationHour", "enginePower", "operatingWeight", "bomLength"],
                required: ["modelName", "brand", "modelYear", "operatingWeight"],
                requestFields: ["operatingWeight"],
            },
            {
                id: 24,
                name: "Paletli Vinç",
                fields: ["expertiseReport", "modelName", "operationHour", "enginePower", "operatingHeight", "operatingWeight"],
                required: ["modelName", "brand", "modelYear", "operatingWeight", "operationHour"],
                requestFields: ["operatingWeight"],
            },
            {
                id: 25,
                name: "Kule Vinç",
                fields: ["expertiseReport", "modelName", "operationHour", "operatingWeight", "operatingHeight", "bomLength"],
                required: ["modelName", "brand", "modelYear", "operatingWeight"],
                requestFields: ["operatingWeight"],
            },
            {
                id: 26,
                name: "Paletli Örümcek Vinç",
                fields: [
                    "expertiseReport",
                    "modelName",
                    "operationHour",
                    "operatingWeight",
                    "fuelTypeId",
                    "operatingHeight",
                    "operationWidth",
                ],
                required: ["modelName", "brand", "modelYear", "operatingWeight", "operationHour"],
                requestFields: ["operatingWeight"],
            },
            {
                id: 27,
                name: "Kamyon Üstü Vinç (Hiyap)",
                fields: ["expertiseReport", "modelName", "operationHour", "operatingWeight", "breakingInfo", "bomLength"],
                required: ["modelName", "brand", "modelYear", "operatingWeight", "operationHour"],
                requestFields: ["operatingWeight"],
            },
        ],
    },
    {
        id: 1,
        name: "Hafriyat Grubu",
        subCategories: [
            {
                id: 1,
                name: "Ekskavatörler (Kazıcı)",
                fields: [
                    "expertiseReport",
                    "modelName",
                    "operationHour",
                    "drivingEquipment",
                    "enginePower",
                    "operatingWeight",
                    "bomLength",
                    "maxDepth",
                    "loadCapacity",
                ],
                required: ["modelName", "brand", "modelYear", "operationHour", "drivingEquipment", "operatingWeight"],
                requestFields: ["operatingWeight"],
            },
            {
                id: 2,
                name: "Mini Ekskavatörler (Mini Kazıcı)",
                fields: [
                    "expertiseReport",
                    "modelName",
                    "operationHour",
                    "drivingEquipment",
                    "enginePower",
                    "tankCapacity",
                    "operatingWeight",
                    "tankCapacity",
                    "bomLength",
                    "loadCapacity",
                ],
                required: ["modelName", "brand", "modelYear", "operationHour", "drivingEquipment", "operatingWeight"],
                requestFields: ["operatingWeight"],
            },
            {
                id: 3,
                name: "Loder (Yükleyici)",
                fields: [
                    "expertiseReport",
                    "modelName",
                    "operationHour",
                    "drivingEquipment",
                    "enginePower",
                    "operatingWeight",
                    "tankCapacity",
                    "loadCapacity",
                ],
                required: ["modelName", "brand", "modelYear", "operationHour", "drivingEquipment", "operatingWeight"],
                requestFields: ["operatingWeight"],
            },
            {
                id: 4,
                name: "Beko Loder (Kazıcı-Yükleyici)",
                fields: [
                    "expertiseReport",
                    "modelName",
                    "operationHour",
                    "drivingEquipment",
                    "enginePower",
                    "operatingWeight",
                    "bomLength",
                    "loadCapacity",
                ],
                required: ["modelName", "brand", "modelYear", "operationHour", "drivingEquipment", "operatingWeight"],
                requestFields: ["operatingWeight"],
            },
            {
                id: 5,
                name: "Mini Kazıcı-Yükleyici",
                fields: [
                    "expertiseReport",
                    "modelName",
                    "operationHour",
                    "drivingEquipment",
                    "enginePower",
                    "operatingWeight",
                    "loadCapacity",
                ],
                required: ["modelName", "brand", "modelYear", "operationHour", "drivingEquipment", "operatingWeight"],
                requestFields: ["operatingWeight"],
            },
            {
                id: 76,
                name: "Mini Belden Kırmalı Yükleyici",
                fields: ["expertiseReport", "operationHour", "enginePower", "operatingWeight", "loadCapacity"],
                required: ["modelName", "brand", "modelYear", "operationHour", "operatingWeight"],
                requestFields: ["operatingWeight"],
            },
            {
                id: 6,
                name: "Çekili Skreyper",
                fields: ["expertiseReport", "modelName", "operationHour", "enginePower", "tankCapacity", "loadCapacity"],
                required: ["modelName", "brand", "modelYear", "operationHour", "tankCapacity"],
                requestFields: ["tankCapacity"],
            },
            {
                id: 7,
                name: "Motorlu Skreyper",
                fields: ["expertiseReport", "modelName", "operationHour", "enginePower", "tankCapacity", "loadCapacity"],
                required: ["modelName", "brand", "modelYear", "operationHour", "tankCapacity"],
                requestFields: ["tankCapacity"],
            },
            {
                id: 8,
                name: "Mini Yükleyiciler",
                fields: ["expertiseReport", "modelName", "operationHour", "drivingEquipment", "enginePower", "loadCapacity"],
                required: ["modelName", "brand", "modelYear", "operationHour", "drivingEquipment", "loadCapacity"],
                requestFields: ["operatingWeight"],
            },
        ],
    },
    {
        id: 16,
        name: "Diğer",
        subCategories: [
            {
                id: 73,
                name: "Drone",
                fields: ["expertiseReport", "modelName", "cameraResolution", "flightTime", "flightDistance"],
                required: ["modelName", "brand", "cameraResolution", "flightTime"],
                requestFields: ["flightTime"],
            },
        ],
    },
    {
        id: 17,
        name: "Telehandler",
        subCategories: [
            {
                id: 78,
                name: "Telehandler",
                fields: ["expertiseReport", "modelName", "operationHour", "enginePower", "operatingWeight", "bomLength", "maxLoadCapacity"],
                required: ["modelName", "brand", "modelYear", "operatingWeight"],
                requestFields: ["operatingWeight"],
            },
        ],
    },
];

export const dummyAttachments = [
    { id: 146, name: "Asfalt Traşlama" },
    { id: 155, name: "Beton Kovası Mikseri" },
    { id: 164, name: "Elek Ataşmanı" },
    { id: 169, name: "Fiber Optik Kanal Açma" },
    { id: 172, name: "Forklift Ataşmanı" },
    { id: 174, name: "Greyder Ataşmanı" },
    { id: 176, name: "Hazneli Sulu Süpürge" },
    { id: 190, name: "Kırıcı Öğütücü Kova" },
    { id: 195, name: "Lastik Sökme Ve Takma" },
    { id: 198, name: "Ray Kanalı Açma" },
    { id: 0, name: "Diğer" },
];
