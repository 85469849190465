import { Form, FormInstance, Select } from "antd"
import styles from "./machine-category-select.module.scss"
import { CategoryModel } from "../../service";
import { ReactComponent as ExcavatorIcon } from "../assets/icons/excavator-icon.svg";
import { ReactComponent as AirCompressorIcon } from "../assets/icons/air-compressor-icon.svg";
import { ReactComponent as ConcreteMixerIcon } from "../assets/icons/concrete-mixer-icon.svg";
import { ReactComponent as ForkliftIcon } from "../assets/icons/forklift-icon.svg";
import { ReactComponent as ManliftIcon } from "../assets/icons/manlift-icon.svg";
import { ReactComponent as CraneTruckIcon } from "../assets/icons/crane-truck-icon.svg";
import { ReactComponent as TelehandlerIcon } from "../assets/icons/telehandlers-icon.svg";
import { ReactComponent as LoaderIcon } from "../assets/icons/loader-icon.svg";
import { ReactComponent as GraderIcon } from "../assets/icons/grader-icon.svg";
import { ReactComponent as LorryIcon } from "../assets/icons/lorry-icon.svg";
import { ReactComponent as FloorCleanerIcon } from "../assets/icons/floor-cleaner-icon.svg";
import { ReactComponent as GeneratorIcon } from "../assets/icons/generator-icon.svg";
import { ReactComponent as RoadRollerIcon } from "../assets/icons/road-roller-icon.svg";
import { ReactComponent as TowTruckIcon } from "../assets/icons/tow-truck-icon.svg";
import { ReactComponent as ContainerIcon } from "../assets/icons/container-icon.svg";
import { ReactComponent as PickupTruckIcon } from "../assets/icons/pickup-truck-icon.svg";
import { ReactComponent as OtherIcon } from "../assets/icons/more-information-icon.svg";
import { ReactElement } from "react";

interface MachineCategorySelectProps {
    form: FormInstance<any>;
    required: boolean;
    label: string;
    errorMessage?: string;
    placeholder?: string;
    categories: CategoryModel[];
    height?: string;
    onChange: (category: CategoryModel) => void;
}

const MachineCategorySelect = (props: MachineCategorySelectProps) => {

    const categoryIcons: {id: number, icon: ReactElement}[] =  [
        {id: 1, icon: <ExcavatorIcon />}, {id: 2, icon: <LoaderIcon />},
        {id: 3, icon: <GraderIcon />}, {id: 4, icon: <LorryIcon />}, 
        {id: 5, icon: <ConcreteMixerIcon />}, {id: 6, icon: <CraneTruckIcon />},
        {id: 7, icon: <FloorCleanerIcon />}, {id: 8, icon: <AirCompressorIcon />},
        {id: 9, icon: <ForkliftIcon />}, {id: 10, icon: <ManliftIcon />}, {id: 11, icon: <GeneratorIcon />},
        {id: 12, icon: <RoadRollerIcon />}, {id: 13, icon: <TowTruckIcon />},
        {id: 14, icon: <ContainerIcon />}, {id: 15, icon: <PickupTruckIcon />},
        {id: 16, icon: <OtherIcon />}, {id: 17, icon: <TelehandlerIcon />}
    ]

    return (
        <div className={styles.container} style={props.height ? {height: props.height} : undefined}>
            <Form.Item
                name={'category'}
                label={props.label}
                rules={[
                    {
                        required: props.required,
                        message: props.errorMessage
                    }
                ]}
            >
                <Select 
                    onChange={(e: number) => {
                        props.onChange(props.categories.find(x => x.id === e) as CategoryModel);
                    }} 
                    getPopupContainer={(trigger) => trigger.parentElement} 
                    placeholder={props.placeholder} 
                    className={styles.categorySelect}
                > 
                    {
                        props.categories.map((e, i) => {
                            return (
                                <Select.Option key={'cat-sel-' + i} value={e.id}>
                                    <div className={styles.categorySelectOption}>
                                        <div className={styles.icon}>
                                            {e.id ? categoryIcons.find(x => x.id === e.id)?.icon : <></>}
                                        </div>
                                        <div className={styles.name}>
                                            {e.name}
                                        </div>
                                    </div>
                                </Select.Option>);
                        })
                    }
                </Select>
            </Form.Item>
        </div>
    );
}

export default MachineCategorySelect;