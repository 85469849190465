import { Col, Divider, Row, Select } from "antd";
import { useEffect, useMemo, useState } from "react";
import { BasicModel } from "../../service/models";
import { t } from "../../translate";
import BasicModelViewer from "./basic-model-viewer";
import PrimaryButton from "./primary-button";
import SecondaryButton from "./secondary-button";
import { ReactComponent as Remove } from "../assets/delete.svg";
import { ReactComponent as Plus } from "../assets/grayPlus.svg";
import { useAtom } from "jotai";
import { offerTabAtom } from "../../store/global-atoms";

type DropdownItemProps = {
    itemKey: number;
    dropdownLabel: string;
    items: BasicModel[] | undefined;
    value?: BasicModel | undefined;
    labels?: string[];
    seperator?: string;
    loading?: boolean;
    onChange?: (itemKey: number, value: BasicModel | undefined) => void;
};
const DropdownItem = (props: DropdownItemProps) => {
    const [currentVal, setCurrentVal] = useState<BasicModel | undefined>(props.value);
    useEffect(() => {
        if (props.value) setCurrentVal(props.value);
    }, [props.value]);

    useEffect(() => {
        if (props.onChange) props.onChange(props.itemKey, currentVal);
        //eslint-disable-next-line
    }, [currentVal]);

    const selectionChange = (activeItem: { value: number | undefined; label: string | undefined } | undefined) => {
        const selectedItem = props.items?.find((item) => item.id === activeItem?.value);
        setCurrentVal(selectedItem);
    };

    const removeClick = () => {
        setCurrentVal(undefined);
    };

    let descriptions: string[] | undefined = [""];
    if (props.seperator && props.seperator !== "") {
        descriptions = currentVal?.name?.split(props.seperator);
    }

    const cols = [];
    let colWidth = 1;
    let labelLength = 1;
    if (props.labels) {
        labelLength = props.labels.length;
        colWidth = 24 / labelLength;
    }

    for (let i = 0; i < labelLength; i++) {
        cols.push(<BasicModelViewer xs={colWidth} objKey={props.labels?.[i]} value={descriptions?.[i]}></BasicModelViewer>);
    }
    const occurrencesSlash =  (currentVal?.name?.split('/').length ?? 1) - 1;
    const defaultVal = currentVal
        ? {
              value: currentVal.id,
              label: currentVal.id + "-" + currentVal.name?.split("/")[occurrencesSlash],
          }
        : null;
    return (
        <div
            style={{
                width: "100%",
                display: "flex",
                flexDirection: "column",
                gap: "1rem",
            }}
        >
            <span>{props.dropdownLabel}</span>
            <Select getPopupContainer={trigger => trigger.parentElement} loading={props.loading} labelInValue value={defaultVal} onChange={selectionChange}>
                {props.items?.map((item) => {
                    const occurrencesSlash =  (item.name?.split('/').length ?? 1) - 1;
                    const name = item.id + "-" + item.name?.split("/")[occurrencesSlash];
                    return <Select.Option value={item.id}>{name}</Select.Option>;
                })}
            </Select>
            <Row style={{ alignItems: "center" }}>
                {cols}
                {currentVal && (
                    <Col xs={24} md={8}>
                        <SecondaryButton onClick={removeClick} negative>
                            <Remove style={{ marginRight: "0.5rem" }}></Remove>
                            {t("common.remove")}
                        </SecondaryButton>
                    </Col>
                )}
            </Row>
            <Divider />
        </div>
    );
};

type MultipleDropdownProps = {
    source: BasicModel[] | undefined;
    buttonLabel: string;
    dropdownLabel: string;
    maxCount: number;
    onChange?: (selectedItems: (BasicModel | undefined)[], key: number) => void;
    value?: BasicModel[] | undefined;
    labelSeperator?: string;
    labels?: string[];
    footerNote?: any;
    editPage?: boolean;
    loading?: boolean;
};
const MultipleDropdownOfferEdit = (props: MultipleDropdownProps) => {
    const [currentVal, setCurrentVal] = useState<BasicModel[] | undefined>(undefined);
    const [machineCount, setMachineCount] = useState<number>(props.value ? props.value.length : 1);
    const [offerTabInfo, setOfferTabInfo] = useAtom(offerTabAtom);
    
    useEffect(() => {
        if (offerTabInfo.requestedMachines && props.editPage) {
            if(offerTabInfo.requestedMachines.length > 0){
                setMachineCount(offerTabInfo.requestedMachines.length);    
                setCurrentVal(offerTabInfo.requestedMachines);
            }
        }
        // eslint-disable-next-line
    }, [offerTabInfo.requestedMachines, props.editPage]);


    const availableItems = useMemo(() => {
        console.log("SOURCE:" + JSON.stringify(props.source));
        if (!currentVal || currentVal.length === 0) return props.source;
        let available: BasicModel[] = [];
        props.source?.forEach((selected, index) => {
            if (!currentVal.some(e => e.id === selected.id)){
                available.push(selected);
            } 
        });
        setOfferTabInfo((oldVal) => {return {...oldVal, selectedMachines: currentVal}});
        return available;
        // eslint-disable-next-line
    }, [currentVal, props.source]);


    const dropdownChange = (key: number, value: BasicModel | undefined) => {
        if (!currentVal) {
            if (!value) {
                if (props.onChange) props.onChange([], key);
                return;
            } else {
                setCurrentVal([value]);
                if (props.onChange) props.onChange([value], key);
                return;
            }
        }
        var newVal: BasicModel[] = [];
        if (key >= currentVal.length && value) {
            newVal = [...currentVal];
            newVal.push(value);
            setCurrentVal(newVal);
            if (props.onChange) props.onChange(newVal, key);
            return;
        }

        for (var i = 0; i < currentVal.length; i++) {
            if (i === key) {
                if (!value) {
                    if (machineCount > 1) setMachineCount(machineCount - 1);
                    continue;
                }
                newVal.push(value);
            } else {
                newVal.push(currentVal[i]);
            }
        }

        setCurrentVal(newVal);
        if (props.onChange) props.onChange(newVal, key);
    };

    let DropDowns = [];
    for (let i = 0; i < (machineCount > 0 ? machineCount : 1); i++) {
        DropDowns.push(
            <DropdownItem
                loading={props.loading}
                itemKey={i}
                items={availableItems}
                value={currentVal?.[i]}
                labels={props.labels}
                onChange={dropdownChange}
                seperator={props.labelSeperator}
                dropdownLabel={props.dropdownLabel}
            />
        );
    } 

    const addNewClick = () => {
        setMachineCount((oldCount) => (oldCount ? oldCount + 1 : 1));
    };

    return (
        <>
            {DropDowns}
            {props.footerNote}
            {props.maxCount > machineCount && currentVal && currentVal.length > 0 && machineCount === currentVal?.length && (
                <div>
                    <PrimaryButton style={{ marginTop: "1rem", marginBottom: "0rem", background: '#EEF0F2', borderColor: '#EEF0F2'}} onClick={addNewClick}>
                    <Plus style={{transform: 'scale(0.9)translate(-5px, 5px)'}}></Plus>
                    {props.buttonLabel}

                    </PrimaryButton>
                    
                </div>
               
            )}
        </>
    );
};
export default MultipleDropdownOfferEdit;
