/* tslint:disable */
/* eslint-disable */
/**
 * MachineGo P1 API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface LessorCompanyInfoUpdateModel
 */
export interface LessorCompanyInfoUpdateModel {
    /**
     * 
     * @type {string}
     * @memberof LessorCompanyInfoUpdateModel
     */
    companyName: string;
    /**
     * 
     * @type {string}
     * @memberof LessorCompanyInfoUpdateModel
     */
    website?: string | null;
    /**
     * 
     * @type {string}
     * @memberof LessorCompanyInfoUpdateModel
     */
    address?: string | null;
    /**
     * 
     * @type {number}
     * @memberof LessorCompanyInfoUpdateModel
     */
    operatorCount?: number | null;
    /**
     * 
     * @type {number}
     * @memberof LessorCompanyInfoUpdateModel
     */
    employeeCount?: number | null;
    /**
     * 
     * @type {number}
     * @memberof LessorCompanyInfoUpdateModel
     */
    totalMachines?: number | null;
    /**
     * 
     * @type {number}
     * @memberof LessorCompanyInfoUpdateModel
     */
    locationId: number;
    /**
     * 
     * @type {Array<number>}
     * @memberof LessorCompanyInfoUpdateModel
     */
    serviceRegions?: Array<number> | null;
    /**
     * 
     * @type {Array<number>}
     * @memberof LessorCompanyInfoUpdateModel
     */
    categories?: Array<number> | null;
    /**
     * 
     * @type {string}
     * @memberof LessorCompanyInfoUpdateModel
     */
    mainServiceActivityFields?: string | null;
}

export function LessorCompanyInfoUpdateModelFromJSON(json: any): LessorCompanyInfoUpdateModel {
    return LessorCompanyInfoUpdateModelFromJSONTyped(json, false);
}

export function LessorCompanyInfoUpdateModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): LessorCompanyInfoUpdateModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'companyName': json['companyName'],
        'website': !exists(json, 'website') ? undefined : json['website'],
        'address': !exists(json, 'address') ? undefined : json['address'],
        'operatorCount': !exists(json, 'operatorCount') ? undefined : json['operatorCount'],
        'employeeCount': !exists(json, 'employeeCount') ? undefined : json['employeeCount'],
        'totalMachines': !exists(json, 'totalMachines') ? undefined : json['totalMachines'],
        'locationId': json['locationId'],
        'serviceRegions': !exists(json, 'serviceRegions') ? undefined : json['serviceRegions'],
        'categories': !exists(json, 'categories') ? undefined : json['categories'],
        'mainServiceActivityFields': !exists(json, 'mainServiceActivityFields') ? undefined : json['mainServiceActivityFields'],
    };
}

export function LessorCompanyInfoUpdateModelToJSON(value?: LessorCompanyInfoUpdateModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'companyName': value.companyName,
        'website': value.website,
        'address': value.address,
        'operatorCount': value.operatorCount,
        'employeeCount': value.employeeCount,
        'totalMachines': value.totalMachines,
        'locationId': value.locationId,
        'serviceRegions': value.serviceRegions,
        'categories': value.categories,
        'mainServiceActivityFields': value.mainServiceActivityFields,
    };
}

