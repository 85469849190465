import { Col, Form, Input } from "antd";
import { LockOutlined } from "@ant-design/icons";
import styles from "./password-input.module.scss"
export type PasswordInputProps = {
    validationMessage?: string;
    placeholder?: string;
    label?: string;
    again?: boolean;
    showIcon?: boolean;
    page?: 'login';
};

const PasswordInput = (props: PasswordInputProps) => {
    const name = props.again ? "retype" : "password";
    return (
        <Form.Item name={name} label={props.label} rules={[{ required: true, message: props.validationMessage }]}>
            {
                props.showIcon ? 
                    <Col xs={24} className={styles.inputContainer}>
                        <Input.Password type="password" placeholder={props.placeholder} />
                    </Col>
                    :
                    <Input className={props.page === 'login' ? styles.inputContainer : ''} prefix={<LockOutlined className="site-form-item-icon" />} type="password" placeholder={props.placeholder} />
            }
        </Form.Item>
    );
};
export default PasswordInput;
