/* tslint:disable */
/* eslint-disable */
/**
 * MachineGo P1 API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    BasicModel,
    BasicModelFromJSON,
    BasicModelFromJSONTyped,
    BasicModelToJSON,
} from './BasicModel';

/**
 * 
 * @export
 * @interface MachineRentalTypeModel
 */
export interface MachineRentalTypeModel {
    /**
     * 
     * @type {number}
     * @memberof MachineRentalTypeModel
     */
    price?: number;
    /**
     * 
     * @type {BasicModel}
     * @memberof MachineRentalTypeModel
     */
    rentalType?: BasicModel;
    /**
     * 
     * @type {BasicModel}
     * @memberof MachineRentalTypeModel
     */
    currency?: BasicModel;
}

export function MachineRentalTypeModelFromJSON(json: any): MachineRentalTypeModel {
    return MachineRentalTypeModelFromJSONTyped(json, false);
}

export function MachineRentalTypeModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): MachineRentalTypeModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'price': !exists(json, 'price') ? undefined : json['price'],
        'rentalType': !exists(json, 'rentalType') ? undefined : BasicModelFromJSON(json['rentalType']),
        'currency': !exists(json, 'currency') ? undefined : BasicModelFromJSON(json['currency']),
    };
}

export function MachineRentalTypeModelToJSON(value?: MachineRentalTypeModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'price': value.price,
        'rentalType': BasicModelToJSON(value.rentalType),
        'currency': BasicModelToJSON(value.currency),
    };
}

