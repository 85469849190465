import TextButton from "../components/atomics/text-button";
import AuthLayout from "../components/layouts/auth-layout";
import { useNavigate } from "react-router-dom";

const List = () => {
    const navigate = useNavigate();

    return (
        <AuthLayout>
            <div>
                <TextButton onClick={() => navigate("/login")} type="link">
                    Giriş Yap
                </TextButton>
            </div>
            <div>
                <TextButton onClick={() => navigate("/signup")} type="link">
                    Kayit ol
                </TextButton>
            </div>
            <div>
                <TextButton onClick={() => navigate("/request/create")} type="link">
                    Kiralama talebi olusturma
                </TextButton>
            </div>
        </AuthLayout>
    );
};

export default List;
