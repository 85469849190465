import BoxContainer from "./box-container";
import styles from "../styles/components.module.scss";
import SecondaryButton from "../atomics/secondary-button";
import { ReactElement } from "react";

export type BigboxContainerProps = {
    title: string;
    children?: any;
    buttonTitle?: string;
    buttonOnClick?: () => void;
    titleElement?: ReactElement<any, any>;
};

const BigboxContainer = (props: BigboxContainerProps) => {
    return (
        <BoxContainer>
            <div className={styles.bigboxContainer}>
                <div className={styles.bigboxTitle}>
                    <div>{props.title}</div>
                    {props.buttonTitle ? (
                        <div>
                            <SecondaryButton onClick={props.buttonOnClick}>{props.buttonTitle}</SecondaryButton>
                        </div>
                    ) : (
                        props.titleElement
                    )}
                </div>
                {props.children}
            </div>
        </BoxContainer>
    );
};
export default BigboxContainer;
