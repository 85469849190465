import { Row } from "antd";
import { CheckboxWithInput } from "../components/atomics/checkbox-with-input";
import BigboxContainer from "../components/containers/bigbox-container";
import { t } from "../translate";
import { EditMachinePartProps } from "../viewmodels/edit-page-part-props";

type AdditionalServicesPartProps = {
    isRequest?: boolean;
} & EditMachinePartProps;
const AdditionalServicesPart = (props: AdditionalServicesPartProps) => {
    return (
        <BigboxContainer title={t("machines.add.additionalServicesTitle")}>
            <Row gutter={[16, 16]}>
                <CheckboxWithInput
                    alwaysDisabled={props.isRequest}
                    inputOnylPositiveNumber
                    xs={24}
                    checkboxLabel={t("machines.add.operator")}
                    inputLabel={t("machines.add.experience")}
                    checkboxName="hasOperator"
                    inputName="operatorExperienceYear"
                    form={props.form}
                />
                <CheckboxWithInput
                    alwaysDisabled
                    xs={24}
                    checkboxLabel={t("machines.add.operatorCostIncluded")}
                    checkboxName="operatorCostIncluded"
                    inputLabel={""}
                    inputName={""}
                    form={props.form}
                />
                <CheckboxWithInput
                    alwaysDisabled
                    xs={24}
                    checkboxLabel={t("machines.add.operatorInsuranceIncluded")}
                    checkboxName="operatorInsuranceIncluded"
                    inputLabel={""}
                    inputName={""}
                    form={props.form}
                />
                <CheckboxWithInput
                    alwaysDisabled
                    xs={24}
                    checkboxLabel={t("machines.add.fuelIncluded")}
                    checkboxName="fuelIncluded"
                    inputLabel={""}
                    inputName={""}
                    form={props.form}
                />
            </Row>
        </BigboxContainer>
    );
};

export default AdditionalServicesPart;
