import { useAtom } from "jotai";
import PrimaryButton from "../components/atomics/primary-button";
import ModalLayout, { ModalLayoutProps } from "../components/layouts/modal-layout";
import { loadingAtom } from "../store/global-atoms";
import { t } from "../translate";
import { OfferFormRenterBidViewModel } from "../service/models";
import styles from "./request-summary.module.scss";
import { Col, Divider, Row } from "antd";
import { ReactComponent as Info } from "../components/assets/info.svg";

type OfferApproveModalProps = {
    bid?: OfferFormRenterBidViewModel | null | undefined;
    onOkClick: () => void;
    approved?: boolean;
} & ModalLayoutProps;
const OfferApproveModal = (props: OfferApproveModalProps) => {
    const [loading] = useAtom(loadingAtom);

    const viewPaymentClick = (e: any) => {
        window.open(props.bid?.paymentLink || "", "_blank", "noopener,noreferrer");
    };
    return (
        <ModalLayout {...props}>
            <h2 style={{ textAlign: "center" }}>{t("offer.approveModal.title")}</h2>
            <p style={{ textAlign: "center" }}>{t("offer.approveModal.description")}</p>
            <div className={styles.successGrayField}>
                <div className={styles.row}>
                    <span className={styles.successLabel}>
                        <strong>{t("offer.approveModal.current")}</strong>
                    </span>
                    <span className={styles.successValue}>
                        <strong>
                            {props.bid?.advancePayment} {props.bid?.currency?.name}
                        </strong>
                    </span>
                </div>
            </div>
            <Divider></Divider>
            <div>
                <div className={styles.row}>
                    <span className={styles.successLabel}>
                        <strong>{t("offer.approveModal.EFT")}</strong>
                    </span>
                </div>
                <div className={styles.row}>
                    <span className={styles.successLabel}>{t("offer.approveModal.iban")}</span>
                    <span className={styles.successValue}>{t("offer.approveModal.iban.value")}</span>
                </div>
                <div className={styles.row}>
                    <span className={styles.successLabel}>{t("offer.approveModal.accountName")}</span>
                    <span className={styles.successValue}>{t("offer.approveModal.accountName.value")}</span>
                </div>
                <div className={styles.row}>
                    <span className={styles.successLabel}>{t("offer.approveModal.branchCode")}</span>
                    <span className={styles.successValue}>{t("offer.approveModal.branchCode.value")}</span>
                </div>
                <div className={styles.row}>
                    <span className={styles.successLabel}>{t("offer.approveModal.accountNumber")}</span>
                    <span className={styles.successValue}>{t("offer.approveModal.accountNumber.value")}</span>
                </div>
                <div className={styles.row}>
                    <span className={styles.successLabel}>{t("offer.approveModal.descriptionField")}</span>
                    <span className={styles.successValue}>{t("offer.approveModal.descriptionField.value")}</span>
                </div>
            </div>
            {props.bid?.paymentLink && (
                <Row className={styles.row} gutter={[16, 16]} justify="space-between">
                    <Col style={{ alignItems: "center", display: "flex" }} md={12}>
                        <strong>{t("offer.approveModal.bankCard")}</strong>
                    </Col>
                    <Col md={12}>
                        <PrimaryButton loading={loading} onClick={viewPaymentClick}>
                            {t("offer.approveModal.viewPaymentLink")}
                        </PrimaryButton>
                    </Col>
                </Row>
            )}

            {!props.approved && (
                <Row justify="center">
                    <Col md={16}>
                        <PrimaryButton
                            loading={loading}
                            onClick={() => {
                                props.onOkClick();
                            }}
                        >
                            {t("offer.approveModal.acceptOfferPaid")}
                        </PrimaryButton>
                    </Col>
                </Row>
            )}
            <div style={{ marginTop: 10 }} className={styles.footerNote}>
                <Row gutter={[16, 16]} style={{ display: "flex", alignItems: "center" }} justify="space-between">
                    <Col xs={2} style={{paddingLeft:0}}>
                        <Info />
                    </Col>
                    <Col xs={22}>
                        <strong>
                            <p style={{ textAlign: "start" }}>{t("offer.approveModal.footerNote")}</p>{" "}
                        </strong>
                    </Col>
                </Row>
                <span></span>
            </div>
        </ModalLayout>
    );
};
export default OfferApproveModal;
