/* tslint:disable */
/* eslint-disable */
/**
 * MachineGo P1 API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface RequestFormCommentViewModel
 */
export interface RequestFormCommentViewModel {
    /**
     * 
     * @type {string}
     * @memberof RequestFormCommentViewModel
     */
    note?: string | null;
    /**
     * 
     * @type {string}
     * @memberof RequestFormCommentViewModel
     */
    user?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof RequestFormCommentViewModel
     */
    approved?: boolean;
    /**
     * 
     * @type {Date}
     * @memberof RequestFormCommentViewModel
     */
    creationDate?: Date;
}

export function RequestFormCommentViewModelFromJSON(json: any): RequestFormCommentViewModel {
    return RequestFormCommentViewModelFromJSONTyped(json, false);
}

export function RequestFormCommentViewModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): RequestFormCommentViewModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'note': !exists(json, 'note') ? undefined : json['note'],
        'user': !exists(json, 'user') ? undefined : json['user'],
        'approved': !exists(json, 'approved') ? undefined : json['approved'],
        'creationDate': !exists(json, 'creationDate') ? undefined : (new Date(json['creationDate'])),
    };
}

export function RequestFormCommentViewModelToJSON(value?: RequestFormCommentViewModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'note': value.note,
        'user': value.user,
        'approved': value.approved,
        'creationDate': value.creationDate === undefined ? undefined : (value.creationDate.toISOString()),
    };
}

