import { Checkbox, Col, Form, FormInstance, Input, Row, Select } from "antd";
import { CheckboxChangeEvent } from "antd/lib/checkbox";
import { ReactElement, useEffect, useState } from "react";
import { EnterInput, SuffixProps } from "./checkbox-with-input";
import { t } from "../../translate";
import { useAtom } from "jotai";
import { offerTabAtom } from "../../store/global-atoms";


export type CheckboxWithInputPriceProps = {
    title: string;
    tooltip?: string;
    checkboxName?: string;
    checkboxLabel?: string;
    initialValue?:  number | null | undefined;
    alwaysDisabled?: boolean;
    enterType?: EnterInput;
    isSuffixDropdown?: boolean;
    inputName: string;
    suffixProps?: SuffixProps;
    suffixDisabled?: boolean;
    suffix?: string | ReactElement;
    form: FormInstance<any>;
    isShippingFeeInput?: "goFee" | "returnFee";
}


const CheckboxWithInputPrice = (props: CheckboxWithInputPriceProps) => {
    const [offerTabInfo, setOfferTabInfo] = useAtom(offerTabAtom);
    const [inputDisabled, setInputDisabled] = useState(!props.initialValue || props.alwaysDisabled);
    const [inputVal, setInputVal] = useState("");
    const [checkNick, setCheckNick] = useState(false);
    
    const checkboxChange = (e: CheckboxChangeEvent) => {
        setInputDisabled(!!!e.target.checked);
        if(!e.target.checked){
            setInputVal("");
            setInputDisabled(true);
            if(props.isShippingFeeInput && props.isShippingFeeInput === "goFee") setOfferTabInfo((oldVal) => { return {...oldVal, lowestSelectedMaturityShippingGoFee: 0} });
            if(props.isShippingFeeInput && props.isShippingFeeInput === "returnFee") setOfferTabInfo((oldVal) => { return {...oldVal, lowestSelectedMaturityShippingReturnFee: 0} });
            props.form.setFieldsValue({
                [props.inputName] : "" 
            });
            setCheckNick(false);
        } 
        setCheckNick(e.target.checked);

    };

/*     useEffect(() => {
        if(offerTabInfo.editPage === true && editPageFillField) {
            setInputDisabled(false);
            setEditPageFillField(false);
        }
        else {
            setInputDisabled(true);
        }
    }, [offerTabInfo.editPage, props.]); */

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setInputVal(e.target.value);
        if (props.enterType !== EnterInput.Price) return;

        interface LooseObject {
            [key: string]: any;
        }
        let obj: LooseObject = {};
        let parsedValue = e.target.value;
        const regexPattern = /[^0-9]/gm;
        parsedValue = parsedValue.replace(regexPattern, "");
        if (parsedValue[0] === "0") parsedValue = "";
        obj[props.inputName] = parsedValue;
        props.form?.setFieldsValue(obj);

        if(e.target.value && e.target.value !== "" && parsedValue) {
            if(props.isShippingFeeInput && props.isShippingFeeInput === "goFee") setOfferTabInfo((oldVal) => { return {...oldVal, lowestSelectedMaturityShippingGoFee: Number.parseInt(parsedValue)} });
            if(props.isShippingFeeInput && props.isShippingFeeInput === "returnFee") setOfferTabInfo((oldVal) => { return {...oldVal, lowestSelectedMaturityShippingReturnFee: Number.parseInt(parsedValue)} });
        }
        else {
            if(props.isShippingFeeInput && props.isShippingFeeInput === "goFee") setOfferTabInfo((oldVal) => { return {...oldVal, lowestSelectedMaturityShippingGoFee: 0} });
            if(props.isShippingFeeInput && props.isShippingFeeInput === "returnFee") setOfferTabInfo((oldVal) => { return {...oldVal, lowestSelectedMaturityShippingReturnFee: 0} });
            props.form.setFieldsValue({
                [props.inputName] : "" 
            });
        }
    };

    useEffect(() => {
        if(props.suffixProps?.selectName) {
            props.form.setFieldsValue({
                [props.suffixProps?.selectName]: offerTabInfo.currencyType,
            });
        }
        //eslint-disable-next-line
    },[offerTabInfo.currencyType]);

    useEffect(() => {
        if(props.isShippingFeeInput === "goFee" && offerTabInfo.lowestSelectedMaturityShippingGoFee > 0) {
            const newPrice = Math.ceil(offerTabInfo.lowestSelectedMaturityShippingGoFee + (offerTabInfo.lowestSelectedMaturityShippingGoFee * (offerTabInfo.maturityInterestRates[offerTabInfo.currentTab.index] ?? 0) * 0.01));
            props.form.setFieldsValue({[props.inputName] : newPrice});

        }
        else if(props.isShippingFeeInput === "returnFee" && offerTabInfo.lowestSelectedMaturityShippingReturnFee > 0){
            const newPrice = Math.ceil(offerTabInfo.lowestSelectedMaturityShippingReturnFee + (offerTabInfo.lowestSelectedMaturityShippingReturnFee * (offerTabInfo.maturityInterestRates[offerTabInfo.currentTab.index] ?? 0) * 0.01));
            props.form.setFieldsValue({[props.inputName] : newPrice});
        
        }
        else {
            props.form.setFieldsValue({[props.inputName] : ""});
        }
        //eslint-disable-next-line
    },[offerTabInfo.currentTab, JSON.stringify(offerTabInfo.maturityInterestRates)]);

    const SelectAfter = (suffixProps: SuffixProps) => {
        return (
            <Form.Item
                name={suffixProps.selectName}
                initialValue={offerTabInfo.currencyType}
                noStyle
            >
                {props.suffixDisabled ?
                    <Select getPopupContainer={(trigger: any) => trigger.parentElement} disabled={true} suffixIcon={null} style={{marginLeft: '0.5rem'}} value={offerTabInfo.currencyType}>
                        {suffixProps.items?.map((model) => {
                            return <Select.Option key={model.name}>{model.name}</Select.Option>;
                        })}
                    </Select>
                :
                    <Select getPopupContainer={(trigger: any) => trigger.parentElement} style={{ width: "4.75rem" }} defaultValue={suffixProps.selectedItem?.name}>
                        {suffixProps.items?.map((model) => {
                            return <Select.Option key={model.name}>{model.name}</Select.Option>;
                        })}
                    </Select>
                }
                
            </Form.Item>
        );
    };

    return (
        <Row style={{marginTop: '0.75rem', marginLeft: '0.25rem', display: 'flex', alignItems: 'center', justifyContent: 'space-between'}} gutter={0}>  
            <Col xs={4} sm={4} md={4} lg={2} style={{display: 'flex', justifyContent: 'start'}}>
                <Form.Item name={props.checkboxName} valuePropName="checked" style={{ display:'flex'}}>
                    <Checkbox disabled={props.alwaysDisabled} onChange={checkboxChange} style={{marginTop: '2rem', transform: 'scale(1.1)'}}/>
                </Form.Item>
            </Col>
            <Col xs={20} md={20} lg={22}>
                <Form.Item
                    name={props.inputName}
                    label={props.checkboxLabel}
                    initialValue={""}
                    rules={[
                        {
                            required: checkNick || (props.form.getFieldValue(props.checkboxName ?? "")),
                            pattern: checkNick ? /^([1-9][0-9]*)$/ : /.*/ ,
                            message: t("offer.add.noFeeWarning"),
                        },
                    ]}
                >
                    {props.isSuffixDropdown !== undefined && props.isSuffixDropdown ? (
                    <Input
                        disabled={props.alwaysDisabled || !(props.form.getFieldValue(props.checkboxName ?? ""))}
                        value={inputVal || undefined}
                        onChange={handleChange}
                        type="text"
                        addonAfter={
                            <SelectAfter
                                selectName={props.suffixProps?.selectName}
                                items={props.suffixProps?.items}
                                selectedItem={props.suffixProps?.selectedItem}
                            />
                        }
                        style={{width: '100%'}}
                    />
                    ) : (
                    <Col xs={24} md={12}>
                        <Input disabled={inputDisabled} value={inputVal} onChange={handleChange} type="text" addonAfter={props.suffix} />
                    </Col>
                    )}
                </Form.Item>
            </Col>  
        </Row>
    );
}

export default CheckboxWithInputPrice;