import styles from "./segmented.module.scss"
import { useState } from "react";

interface SegmentedProps {
    values: string[];
    containerStyle?: React.CSSProperties;
    defaultOption?: number;
    onChange: (value: string) => void; 
}

const Segmented = (props: SegmentedProps) => {
    const [selectedOption, setSelectedOption] = useState<number>(props.defaultOption ?? 0);

    return (
        <div className={styles.container} style={props.containerStyle}>
            {props.values.map( (e, i) => {
                return (
                    <div 
                        className={(selectedOption === i ? styles['selectedSegment'] : styles['unSelectedSegment']  )} 
                        onClick={() => {
                        props.onChange(e);
                        setSelectedOption(i);
                        }} 
                        style={{width: 100 / props.values.length + '%'}}
                        key={'segmented-' + i}>{e}</div>
                );
            }
            )}
        </div>
    );
}

export default Segmented;