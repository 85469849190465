/* tslint:disable */
/* eslint-disable */
/**
 * MachineGo P1 API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface RenterCompanyPaymentUpdateModel
 */
export interface RenterCompanyPaymentUpdateModel {
    /**
     * 
     * @type {string}
     * @memberof RenterCompanyPaymentUpdateModel
     */
    companyName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof RenterCompanyPaymentUpdateModel
     */
    address?: string | null;
    /**
     * 
     * @type {string}
     * @memberof RenterCompanyPaymentUpdateModel
     */
    taxIdentityNo?: string | null;
    /**
     * 
     * @type {string}
     * @memberof RenterCompanyPaymentUpdateModel
     */
    taxOffice?: string | null;
    /**
     * 
     * @type {number}
     * @memberof RenterCompanyPaymentUpdateModel
     */
    taxOfficeCityId?: number | null;
    /**
     * 
     * @type {string}
     * @memberof RenterCompanyPaymentUpdateModel
     */
    iban?: string | null;
    /**
     * 
     * @type {Array<number>}
     * @memberof RenterCompanyPaymentUpdateModel
     */
    serviceRegions?: Array<number> | null;
}

export function RenterCompanyPaymentUpdateModelFromJSON(json: any): RenterCompanyPaymentUpdateModel {
    return RenterCompanyPaymentUpdateModelFromJSONTyped(json, false);
}

export function RenterCompanyPaymentUpdateModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): RenterCompanyPaymentUpdateModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'companyName': !exists(json, 'companyName') ? undefined : json['companyName'],
        'address': !exists(json, 'address') ? undefined : json['address'],
        'taxIdentityNo': !exists(json, 'taxIdentityNo') ? undefined : json['taxIdentityNo'],
        'taxOffice': !exists(json, 'taxOffice') ? undefined : json['taxOffice'],
        'taxOfficeCityId': !exists(json, 'taxOfficeCityId') ? undefined : json['taxOfficeCityId'],
        'iban': !exists(json, 'iban') ? undefined : json['iban'],
        'serviceRegions': !exists(json, 'serviceRegions') ? undefined : json['serviceRegions'],
    };
}

export function RenterCompanyPaymentUpdateModelToJSON(value?: RenterCompanyPaymentUpdateModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'companyName': value.companyName,
        'address': value.address,
        'taxIdentityNo': value.taxIdentityNo,
        'taxOffice': value.taxOffice,
        'taxOfficeCityId': value.taxOfficeCityId,
        'iban': value.iban,
        'serviceRegions': value.serviceRegions,
    };
}

