export const activeApiBaseUrl = process.env.REACT_APP_BASE_API_URL ?? "";
export const activeRootSiteUrl = process.env.REACT_APP_ROOT_SITE_URL ?? "";
export const activeAppSiteUrl = process.env.REACT_APP_APP_SITE_URL ?? "";
export const activeRecaptcha = process.env.REACT_APP_RECAPTCHA ? process.env.REACT_APP_RECAPTCHA : "";

export const googleClientId = process.env.REACT_APP_GOOGLE_CLIENT_ID ?? '';
export const facebookAppId = process.env.REACT_APP_FACEBOOK_APP_ID ?? '';
export const isNoIndexActive = process.env.REACT_APP_NOINDEX_DEV_ACTIVE === "true";
export const redirectLoginPageUri = process.env.REACT_APP_REDIRECT_LOGINPAGE_URI ?? '';
export const redirectRegisterPageUri = process.env.REACT_APP_REDIRECT_REGISTERPAGE_URI ?? '';
export const redirectRegisterAnonymPageUri = process.env.REACT_APP_REDIRECT_REGISTERANONYMPAGE_URI ?? '';

export const googleLoginActive = process.env.REACT_APP_GOOGLE_LOGIN_ACTIVE === 'true';
export const facebookLoginActive = process.env.REACT_APP_FACEBOOK_LOGIN_ACTIVE === 'true';
