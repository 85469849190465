
import { ButtonProps } from "antd/lib/button";
import { Button } from "antd";
import style from "../styles/tertiary-button.module.scss";
type TertiaryButtonProps = {
    children?:any
}&ButtonProps


const TertiaryButton = (props:TertiaryButtonProps) => {
    return (
        <Button {...props} className={style.tertiaryButton} {...props}>{props.children}</Button>
        
    )
}

export default TertiaryButton;