import { Checkbox, Col, Collapse, DatePicker, Divider, Dropdown, Form, Input, Menu, Radio, Row, Select, Space } from "antd";
import { useNavigate } from "react-router-dom";
import accordionStyles from "../../components/styles/accordion.module.scss";
import PrimaryButton from "../../components/atomics/primary-button";
import OnboardLayout from "../../components/layouts/onboard-layout";
import { t, trLocale } from "../../translate";
import { loadingAtom, ModifiedRequestDetailModel, postloginMessageAtom, roleAtom } from "../../store/global-atoms";
import { useAtom } from "jotai";
import { ChangeEvent, EventHandler, useEffect, useState } from "react";
import { BasicModel, CategoryModel, CityModel, MachineDetailModel, MachineDocumentViewModel, SubCategoryModel } from "../../service";
import { findCategory, useSecureService } from "../../store/service-atoms";
import RadioButton from "../../components/atomics/radio-button";
import BoxContainer from "../../components/containers/box-container";
import CheckboxButton from "../../components/atomics/checkbox-button";
import IncrementsInput from "../../components/atomics/increments-input";
import TextArea from "antd/lib/input/TextArea";
import { RangeValue } from "rc-picker/lib/interface";
import moment from "moment";
import { ReactComponent as Logo } from "../../components/assets/logo.svg";
import { DownOutlined } from "@ant-design/icons";
import { useQuery } from "../../helpers/use-query";
import { activeApiBaseUrl } from "../../store/app-constants";
import placeholder from "../../components/assets/placeholder.svg";
import SecondaryButton from "../../components/atomics/secondary-button";
import ModalLayout from "../../components/layouts/modal-layout";

const { RangePicker } = DatePicker;
const { Option } = Select;
const { Panel } = Collapse;

const CreateRequest = () => {
    const { doGetMachineSubcategory, doGetExactCities, doCreateNewRequest, doGetMachine } = useSecureService();
    const [loading] = useAtom(loadingAtom);
    const navigate = useNavigate();
    const query = useQuery();
    const sid = query.get("sid");
    const macid = query.get("macid");
    const [role] = useAtom(roleAtom);
    const [allMachineCategory, setAllMachineCategories] = useState<CategoryModel[] | undefined>(undefined);
    const [selectedMachineCategory, setSelectedMachineCategory] = useState<any | undefined>(undefined);
    const [selectedMachineRadio, changeSelectedRadio] = useState<number | undefined>(undefined);
    const [accordionItems, setAccordionItems] = useState<any[] | undefined>(undefined);
    const [selectedCheckboxes, changeSelectedCheckboxes] = useState<any[]>([]);
    const [createCount, setCreateCount] = useState<number | undefined>(undefined);
    const [otherSubCategoryText, setOtherSubCategoryText] = useState<string | undefined>(undefined);
    const [detailText, setDetailText] = useState<string | undefined>(undefined);
    const [form] = Form.useForm();
    const [selectedDistrict, setDistrict] = useState<string | undefined>(undefined);
    const [districts, setDistrictsDropdown] = useState<BasicModel[] | undefined>(undefined);
    const [allCities, setAllCities] = useState<CityModel[] | undefined>(undefined);
    const [selectedDates, setSelectedDates] = useState<RangeValue<moment.Moment>>();
    const [, setErrorMessage] = useAtom(postloginMessageAtom);
    const [machineAllDetail, setMachineAllDetail] = useState<MachineDetailModel>();
    const [machineImages, setMachineImages] = useState<MachineDocumentViewModel[] | undefined>([]);
    const [mainImageId, setMainImageId] = useState<number>();
    const [mainImageUrl, setMainImageUrl] = useState<string>(placeholder);
    const [showRedirectModal, setShowRedirectModal] = useState<boolean>(false);
    const Description = <>{t("request.description")}</>;
    const [selectedCity, setCity] = useState<
        | {
              id?: number | null;
              name?: string | null;
              districts?: BasicModel[] | null;
          }
        | undefined
    >(undefined);

    const CollapsePanelHeader = (
        <Row gutter={[16, 16]} className={accordionStyles.accordionItemHeader} align="middle" justify="start" wrap={false}>
            <Col xs={6} md={4}>
                <Logo width={"100%"} />
            </Col>
            <Col xs={14} md={18}>
                <h3>{t("common.select")}</h3>
                <span className={accordionStyles.selectText}>
                    {accordionItems?.map((val) => {
                        return val.title + ", ";
                    })}
                </span>
            </Col>
            <Col xs={4} md={4}>
                <DownOutlined style={{ fontSize: "1.25rem" }} />
            </Col>
        </Row>
    );

    useEffect(() => {
        const getMachineDetails = async () => {
            if (macid) {
                const macidN = parseInt(macid);
                if (!Number.isNaN(macid)) {
                    const result = await doGetMachine(macidN, true);
                    if (result) {
                        setMachineAllDetail(result);
                    }
                }
            }
        };
        getMachineDetails();
        //eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (machineAllDetail?.documents && machineAllDetail.documents.length > 0) {
            const documents = machineAllDetail?.documents?.filter((x: any) => x.documentCategory === "Photo");
            setMachineImages(documents);
        }
    }, [machineAllDetail]);

    useEffect(() => {
        if (machineImages && machineImages?.length > 0) {
            const mainImageId = machineImages[0].document?.id;
            setMainImageId(mainImageId);
        }
        // eslint-disable-next-line
    }, [machineImages]);

    useEffect(() => {
        if (mainImageId === undefined) {
            setMainImageUrl(placeholder);
        } else {
            setMainImageUrl(activeApiBaseUrl + "/document/machine-document/" + mainImageId);
        }
    }, [mainImageId]);

    useEffect(() => {
        const setServiceValues = async () => {
            const result = await doGetMachineSubcategory();
            if (result) setAllMachineCategories(result);
            const cities = await doGetExactCities();
            setAllCities(cities);
        };
        setServiceValues();
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        const AccordionItems = allMachineCategory?.map((category) => {
            const radios = category.subCategories?.map((sub) => {
                if (sub && sub.id && sub.name) return createSubcategoryRadio(sub.id?.toString(), sub.name, radioClicked);
                else return undefined;
            });
            const returnObj = {
                title: category.name,
                key: category.id,
                children: (
                    <Row gutter={[16, 16]} key={rowKey}>
                        {radios}
                    </Row>
                ),
                description: category.subCategories
                    ?.map((sub) => " " + sub.name)
                    .toString()
                    .trim(),
                imageUrl: "img" + category.id + ".png",
            };
            rowKey++;
            return returnObj;
        });
        setAccordionItems(AccordionItems);
        // eslint-disable-next-line
    }, [allMachineCategory]);

    useEffect(() => {
        let category: CategoryModel | undefined = undefined;
        if (sid && !selectedMachineRadio && allMachineCategory) {
            const sidN = Number.parseInt(sid);
            category = findCategory(sidN);
            handleCategorySelect(category?.name ?? "");
            changeSelectedRadio(sidN);
        }
        // eslint-disable-next-line
    }, [accordionItems]);

    useEffect(() => {
        form.setFieldsValue({ subCategoryOther: undefined });
        setOtherSubCategoryText(undefined);
        changeSelectedRadio(undefined);
        const selectedMachine = allMachineCategory?.find((cat) => cat.id === selectedMachineCategory.key);
        if (selectedMachine?.subCategories?.length === 1 && selectedMachine?.subCategories?.[0]) {
            changeSelectedRadio(selectedMachine.subCategories[0].id);
        }
        // eslint-disable-next-line
    }, [selectedMachineCategory]);

    useEffect(() => {
        if (selectedMachineRadio) {
            form.setFieldsValue({ subCategoryOther: undefined });
            setOtherSubCategoryText(undefined);
        }
        // eslint-disable-next-line
    }, [selectedMachineRadio]);

    const onFormFinish = async (values: any) => {
        let requestFormValues = values as ModifiedRequestDetailModel;
        const city = allCities?.find((cty) => cty.name === selectedCity?.name);
        const district = city?.districts?.find((dst) => dst.name === selectedDistrict);
        let foundSub: SubCategoryModel | undefined = undefined;
        allMachineCategory?.find((cat) => {
            foundSub = cat.subCategories?.find(
                (sub) => sub.id?.toString() === (macid ? machineAllDetail?.subCategoryId?.toString() : selectedMachineRadio?.toString())
            );
            if (foundSub) {
                foundSub.category = { id: cat.id, name: cat.name };
                return true;
            } else return false;
        });
        if (!selectedMachineRadio && !macid) {
            return setErrorMessage({
                message: t("common.generic-required-subcategory-message"),
                type: "error",
            });
        }
        if (!selectedDistrict) {
            return setErrorMessage({
                message: t("common.generic-required-district-message"),
                type: "error",
            });
        }
        if (moment().add(5, "years") < moment(values.requestDate[1]).add(1, "day")) {
            return setErrorMessage({
                message: t("request.create.date.validation.end.date"),
                type: "error",
            });
        }
        if (moment().add(1, "year") < moment(values.requestDate[0]).add(1, "day")) {
            return setErrorMessage({
                message: t("request.create.date.validation.start.date"),
                type: "error",
            });
        }
        if (role === "customer" && requestFormValues) {
            requestFormValues.subCategory = foundSub;
            requestFormValues.subCategoryOther = otherSubCategoryText;
            requestFormValues.fromDateRaw = values.requestDate[0];
            requestFormValues.toDateRaw = values.requestDate[1];
            requestFormValues.district = district;
            requestFormValues.additionalServices = {
                fuelIncluded: selectedCheckboxes.includes("fuelIncluded"),
                hasOperator: selectedCheckboxes.includes("hasOperator"),
                operatorCostIncluded: selectedCheckboxes.includes("operatorCostIncluded"),
                operatorInsuranceIncluded: selectedCheckboxes.includes("operatorInsuranceIncluded"),
                shipingIncluded: selectedCheckboxes.includes("shipingIncluded"),
            };
            if (macid) {
                requestFormValues.machineId = parseInt(macid);
            }
            const request = await doCreateNewRequest(requestFormValues);
            if (request) {
                navigate("/dashboard?role=renter");
            } else {
                setErrorMessage({
                    message: t("common.genericError"),
                    type: "error",
                });
            }
        }
    };

    const handleCategorySelect = (name: string) => {
        var machine = accordionItems?.find((item) => item.title === name);
        setSelectedMachineCategory(machine);
    };

    const dropdownMenu = () => {
        return (
            <Menu style={{ maxHeight: 300, maxWidth: "82vw", width: "auto", overflow: "auto" }}>
                {accordionItems?.map((item) => {
                    const Header = (
                        <div className={accordionStyles.accordionItemHeader}>
                            {item.imageUrl && <img style={{ height: 80, width: 80 }} src={"/" + item.imageUrl} alt={item.imageUrl} />}
                            <div>
                                <h4>{item.title}</h4>
                            </div>
                        </div>
                    );
                    return (
                        <Menu.Item
                            onClick={() => {
                                handleCategorySelect(item.title);
                            }}
                            key={item.id}
                        >
                            {
                                <Panel
                                    style={{ paddingInline: 15 }}
                                    key={item.key}
                                    header={Header}
                                    className={accordionStyles.accordionItemPanel}
                                >
                                    {item.children}
                                </Panel>
                            }
                            <Space></Space>
                        </Menu.Item>
                    );
                })}
            </Menu>
        );
    };

    const createSubcategoryRadio = (value: string, content: string, evt: EventHandler<any>) => {
        return content !== "Diğer " ? (
            <Col xs={24} lg={12}>
                <RadioButton value={value} content={content} radioClicked={evt} />
            </Col>
        ) : (
            <Col xs={24} lg={12}>
                <Form.Item name="subCategoryOther">
                    <Input
                        placeholder={t("request.create.other.subCategory.placeholder")}
                        style={{ height: "3.5rem" }}
                        onChange={(e: any) => {
                            if (e.target.value !== "") {
                                changeSelectedRadio(parseInt(value));
                                setOtherSubCategoryText(e.target.value);
                            } else {
                                changeSelectedRadio(undefined);
                            }
                        }}
                    />
                </Form.Item>
            </Col>
        );
    };

    const radioClicked = (val: any) => {
        changeSelectedRadio(val);
    };

    const checkboxClicked = (val: any) => {
        changeSelectedCheckboxes((old) => {
            if (old.includes(val)) {
                return old.filter((att) => {
                    return att !== val;
                });
            } else return [...old, val];
        });
    };
    const createCountChanged = (num: number) => {
        setCreateCount(num);
    };

    let rowKey = 0;

    const detailTextAreaChanged = (val: ChangeEvent<HTMLTextAreaElement>) => {
        setDetailText(val.currentTarget.value);
    };

    const onCitySelected = async (selectedCityName: string) => {
        setDistrict("");
        const currentCity = allCities?.find((city) => city.name === selectedCityName);
        setCity(currentCity);
        if (currentCity && currentCity.districts) setDistrictsDropdown(currentCity.districts);
        else setDistrictsDropdown(undefined);
    };

    const dateChanged = (val: RangeValue<moment.Moment>) => {
        setSelectedDates(val);
    };

    function disabledDate(current: any) {
        // Can not select days before today and today
        return current && current < moment().endOf("day");
    }

    return (
        <OnboardLayout smallLogo={true} h2={Description}>
            <Form key={1} name="add-request" layout="vertical" form={form} onFinish={onFormFinish}>
                <Row gutter={[16, 16]} align="middle" justify="center">
                    <Col xs={24} md={24} style={{ textAlign: "center", backgroundColor: "#FFF4E1", paddingTop: 5 }}>
                        <h3>{t("machines.add-info.cardTitle")}</h3>
                    </Col>
                    <Col md={20} xs={20}>
                        {macid ? (
                            <BoxContainer>
                                <Row className={accordionStyles.accordionItemHeader} gutter={[0, 8]}>
                                    {machineImages && (
                                        <Col md={2} xs={4}>
                                            <img src={mainImageUrl} alt={""} />
                                        </Col>
                                    )}
                                    <Col xs={8} md={8}>
                                        <Row style={{ flexDirection: "column" }}>
                                            <Col>
                                                <span className={accordionStyles.spanBlack}>{t("request.machine.info.machineId")}</span>
                                                <span className={accordionStyles.spanBlack}>
                                                    {machineAllDetail?.id ? machineAllDetail?.id.toString() : "-"}
                                                </span>
                                            </Col>
                                            <Col>
                                                <span className={accordionStyles.spanGrey}>{t("request.machine.info.category")} </span>
                                                <span className={accordionStyles.spanBlack}>
                                                    {machineAllDetail?.subCategory?.category?.name}
                                                </span>
                                            </Col>
                                            <Col>
                                                <span className={accordionStyles.spanGrey}>{t("request.machine.info.subCategory")}</span>
                                                <span className={accordionStyles.spanBlack}>{machineAllDetail?.subCategory?.name}</span>
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col xs={8} md={8}>
                                        <Row style={{ flexDirection: "column" }}>
                                            <Col>
                                                <span className={accordionStyles.spanGrey}>{t("request.machine.info.brand")} </span>
                                                <span className={accordionStyles.spanBlack}>
                                                    {machineAllDetail?.brand?.name ? machineAllDetail?.brand.name : "-"}
                                                </span>
                                            </Col>
                                            <Col>
                                                <span className={accordionStyles.spanGrey}>{t("request.machine.info.model")} </span>
                                                <span className={accordionStyles.spanBlack}>
                                                    {machineAllDetail?.modelName ? machineAllDetail?.modelName : "-"}
                                                </span>
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col xs={24} md={4}>
                                        <SecondaryButton onClick={() => setShowRedirectModal(true)} style={{ width: "100%" }}>
                                            {t("request.machine.info.change.button")}
                                        </SecondaryButton>
                                    </Col>
                                </Row>
                            </BoxContainer>
                        ) : (
                            <div id="area" style={{ position: "relative" }}>
                                <Dropdown
                                    overlay={dropdownMenu}
                                    trigger={["click"]}
                                    placement="bottom"
                                    getPopupContainer={() => document.getElementById("area")!}
                                >
                                    <div className="ant-dropdown-link" onClick={(e) => e.preventDefault()}>
                                        {selectedMachineCategory ? (
                                            <Panel
                                                header={
                                                    <Row className={accordionStyles.accordionItemHeader} wrap={false}>
                                                        {selectedMachineCategory?.imageUrl && (
                                                            <Col xs={4} md={2}>
                                                                <img
                                                                    src={"/" + selectedMachineCategory?.imageUrl}
                                                                    alt={selectedMachineCategory?.imageUrl}
                                                                />
                                                            </Col>
                                                        )}
                                                        <Col xs={16} md={20}>
                                                            <h4>{selectedMachineCategory?.title}</h4>
                                                            {selectedMachineCategory?.description && (
                                                                <span>{selectedMachineCategory?.description}</span>
                                                            )}
                                                        </Col>
                                                        <Col xs={4} md={2}>
                                                            <DownOutlined style={{ fontSize: "1.25rem" }} />
                                                        </Col>
                                                    </Row>
                                                }
                                                key="1"
                                                className={accordionStyles.accordionItemPanel}
                                                style={{ padding: 15 }}
                                            />
                                        ) : (
                                            <Panel
                                                header={CollapsePanelHeader}
                                                key="1"
                                                className={accordionStyles.accordionItemPanel}
                                                style={{ padding: 15 }}
                                            />
                                        )}
                                    </div>
                                </Dropdown>
                                {selectedMachineCategory && (
                                    <div>
                                        <Radio.Group value={selectedMachineRadio?.toString()} style={{ width: "100%" }}>
                                            {selectedMachineCategory?.children}
                                        </Radio.Group>
                                    </div>
                                )}
                            </div>
                        )}
                    </Col>
                    <Col xs={20} md={20}>
                        <h3>{t("request.view.machineCount")}</h3>
                    </Col>
                    <Col xs={20} md={20}>
                        <BoxContainer style={{ maxHeight: 100 }}>
                            <Form.Item name="machineCount">
                                <IncrementsInput min={1} max={50} value={createCount} onChange={createCountChanged} />
                            </Form.Item>
                        </BoxContainer>
                    </Col>
                    <Col xs={24} md={24} style={{ textAlign: "center", backgroundColor: "#FFF4E1", paddingTop: 5 }}>
                        <h3>{t("machines.add-info.additionalServices")}</h3>
                    </Col>
                    <Col md={20} xs={20}>
                        <BoxContainer>
                            <Checkbox.Group defaultValue={undefined} value={selectedCheckboxes} style={{ width: "100%" }}>
                                <Row gutter={[24, 24]}>
                                    <Col xs={24} md={8}>
                                        <CheckboxButton
                                            content={t("request.machine-detail.hasOperator")}
                                            value="hasOperator"
                                            checkboxClicked={checkboxClicked}
                                        />
                                    </Col>
                                    <Col xs={24} md={8}>
                                        <CheckboxButton
                                            content={t("request.machine-detail.fuelIncluded")}
                                            value="fuelIncluded"
                                            checkboxClicked={checkboxClicked}
                                        />
                                    </Col>
                                    <Col xs={24} md={8}>
                                        <CheckboxButton
                                            content={t("request.machine-detail.shipingIncluded")}
                                            value="shipingIncluded"
                                            checkboxClicked={checkboxClicked}
                                        />
                                    </Col>
                                </Row>
                            </Checkbox.Group>
                            <h3>{t("request.machine-detail.writeDetailsHead")}</h3>
                            <Form.Item name={"description"}>
                                <TextArea
                                    style={{ marginBottom: "2rem" }}
                                    placeholder={t("request.machine-detail.writeDetailsPlaceholder")}
                                    onChange={detailTextAreaChanged}
                                    value={detailText}
                                ></TextArea>
                            </Form.Item>
                        </BoxContainer>
                    </Col>
                    <Col xs={24} md={24} style={{ textAlign: "center", backgroundColor: "#FFF4E1", paddingTop: 5 }}>
                        <h3>{t("request.location-and-date.selectLocationDate")}</h3>
                    </Col>
                    <Col xs={20} md={20}>
                        <BoxContainer>
                            <Row justify="center">
                                <Col md={24} xs={24}>
                                    <h3>{t("request.location-and-date.selectLocation")}</h3>
                                    <Row style={{ marginBottom: "1.5rem" }} justify="end" wrap={false}>
                                        <Col xs={12} md={12}>
                                            <Select
                                                placeholder={t("request.location.city.placeholder")}
                                                loading={loading}
                                                onSelect={onCitySelected}
                                                style={{ width: "99%" }}
                                                value={selectedCity?.name}
                                                getPopupContainer={(trigger: any) => trigger.parentElement}
                                            >
                                                {allCities?.map((mc) => (
                                                    <Option value={mc.name}>{mc.name}</Option>
                                                ))}
                                            </Select>
                                        </Col>
                                        <Col xs={12} md={12}>
                                            <Select
                                                placeholder={t("request.location.district.placeholder")}
                                                loading={loading}
                                                style={{ width: "99%" }}
                                                value={selectedDistrict}
                                                onSelect={(e: string) => setDistrict(e)}
                                                getPopupContainer={(trigger: any) => trigger.parentElement}
                                            >
                                                {districts?.map((msc) => (
                                                    <Option value={msc.name}>{msc.name}</Option>
                                                ))}
                                            </Select>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col md={24} xs={24}>
                                    <h3>{t("request.location-and-date.selectdates")}</h3>
                                    <Form.Item
                                        name={"requestDate"}
                                        rules={[
                                            {
                                                required: true,
                                                validator: (_, value) =>
                                                    value
                                                        ? Promise.resolve()
                                                        : Promise.reject(new Error(t("common.generic-required-message"))),
                                            },
                                        ]}
                                    >
                                        <RangePicker
                                            value={selectedDates}
                                            placement="topRight"
                                            onChange={dateChanged}
                                            inputReadOnly={true}
                                            locale={trLocale}
                                            format="DD-MM-YYYY"
                                            disabledDate={disabledDate}
                                            size="large"
                                            style={{
                                                width: "100%",
                                                height: "48px",
                                                background: "white",
                                                marginBottom: "1.5rem",
                                            }}
                                        ></RangePicker>
                                    </Form.Item>
                                </Col>
                            </Row>
                        </BoxContainer>
                    </Col>
                    <Col xs={24} md={24}>
                        <Divider />
                    </Col>
                    <Col md={20} xs={24}>
                        <Row align="middle" justify="space-around">
                            <Col md={10} xs={16}>
                                <Form.Item>
                                    <PrimaryButton loading={loading} htmlType="submit" style={{ marginTop: "1rem" }}>
                                        {t("requests-and-offers.list.createRequestForm")}
                                    </PrimaryButton>
                                </Form.Item>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Form>
            {
                <ModalLayout
                    show={showRedirectModal}
                    onCloseClick={() => {
                        setShowRedirectModal(false);
                    }}
                >
                    <Row gutter={[16, 16]} wrap={true} justify="center" align="middle">
                        <Col md={16} xs={16}>
                            <h3 style={{ textAlign: "center" }}>{t("request.create.redirect.modal.title")}</h3>
                        </Col>
                        <Col md={16} xs={16} style={{ display: "flex", alignItems: "center" }}>
                            <PrimaryButton
                                onClick={() => {
                                    navigate("/rental-machines");
                                }}
                                style={{ width: "300px" }}
                            >
                                {t("common.ok")}
                            </PrimaryButton>
                        </Col>
                    </Row>
                </ModalLayout>
            }
        </OnboardLayout>
    );
};

export default CreateRequest;
