import { Col, ColProps } from "antd";
import BoxContainer from "./box-container";
import SecondaryButton from "../atomics/secondary-button";
import { t } from "../../translate";
type DashboardItemContainerProps = {
    title: string;
    showAllClick: () => void;
    badgeNumber?: number;
    emptyButtonLabel?: string;
    emptyButtonClick?: () => void;
    children?: any;
} & ColProps;

const DashboardItemContainer = (props: DashboardItemContainerProps) => {
    return (
        <Col xs={24} md={props.md}>
            <BoxContainer shadowless marginless style={{ height: "auto" }}>
                <div
                    style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                    }}
                >
                    <h5
                        style={{
                            fontSize: "1rem",
                            fontWeight: "800",
                            marginTop: ".5rem",
                        }}
                    >
                        {props.title}
                        {props.badgeNumber !== undefined && props.badgeNumber !== 0 && <span> ({props.badgeNumber})</span>}
                    </h5>
                    <div>
                        <SecondaryButton onClick={() => props.showAllClick()}>{t("common.all")}</SecondaryButton>
                    </div>
                </div>
                <div style={{ height: "100%", marginTop: ".5rem" }}>{props.children}</div>
            </BoxContainer>
        </Col>
    );
};

export default DashboardItemContainer;
