/* tslint:disable */
/* eslint-disable */
/**
 * MachineGo P1 API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface MachineSaleCommissionViewModel
 */
export interface MachineSaleCommissionViewModel {
    /**
     * 
     * @type {number}
     * @memberof MachineSaleCommissionViewModel
     */
    categoryId?: number;
    /**
     * 
     * @type {number}
     * @memberof MachineSaleCommissionViewModel
     */
    subCategoryId?: number;
    /**
     * 
     * @type {number}
     * @memberof MachineSaleCommissionViewModel
     */
    priceRate?: number;
}

export function MachineSaleCommissionViewModelFromJSON(json: any): MachineSaleCommissionViewModel {
    return MachineSaleCommissionViewModelFromJSONTyped(json, false);
}

export function MachineSaleCommissionViewModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): MachineSaleCommissionViewModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'categoryId': !exists(json, 'categoryId') ? undefined : json['categoryId'],
        'subCategoryId': !exists(json, 'subCategoryId') ? undefined : json['subCategoryId'],
        'priceRate': !exists(json, 'priceRate') ? undefined : json['priceRate'],
    };
}

export function MachineSaleCommissionViewModelToJSON(value?: MachineSaleCommissionViewModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'categoryId': value.categoryId,
        'subCategoryId': value.subCategoryId,
        'priceRate': value.priceRate,
    };
}

