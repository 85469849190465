import { Col, Row } from "antd";
import { useAtom } from "jotai";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ReactComponent as Danger } from "../../../components/assets/danger.svg";
import { ReactComponent as Decline } from "../../../components/assets/declineIcon.svg";
import PrimaryButton from "../../../components/atomics/primary-button";
import RequestAndOfferStatus from "../../../components/atomics/request-and-offer-status";
import SecondaryButton from "../../../components/atomics/secondary-button";
import BoxContainer from "../../../components/containers/box-container";
import SecureLayout from "../../../components/layouts/secure-layout";
import { moment } from "../../../helpers/moment";
import { useQuery } from "../../../helpers/use-query";
import CompanyDetailModal from "../../../modals/company-detail";
import OfferApproveModal from "../../../modals/offer-approve-modal";
import OfferDeclineModal from "../../../modals/offer-decline-modal";
import OfferHistoryPart from "../../../page-parts/offer-history-part";
import OfferViewAdditionalInfoPart from "../../../page-parts/offer-view-additional-info-part";
import OfferViewMachineInfoPart from "../../../page-parts/offer-view-machine-info-part";
import RequestViewPart from "../../../page-parts/request-view-part";
import {
    BasicModel,
    CompanyBasicModel,
    LessorCompanyAuthorizedSellerInfoModel,
    OfferFormCommentViewModel,
    OfferFormRenterBidViewModel,
    OfferFormRenterViewModel,
    OfferFormViewModel,
    RequestFormOfferViewModel,
} from "../../../service/models";
import { ModifiedRequestDetailModel, postloginMessageAtom, roleAtom } from "../../../store/global-atoms";
import { useSecureService } from "../../../store/service-atoms";
import { t } from "../../../translate";
import OfferAggrementPart from "../../../page-parts/offer-aggrement-part";
import OfferViewAdditionalServicesPart from "../../../page-parts/offer-view-additional-services-part";

const ViewOffer = () => {
    const query = useQuery();
    const offerId = query.get("offerid");
    const reqId = query.get("reqid");
    const [role] = useAtom(roleAtom);
    const navigate = useNavigate();
    const { doGetAttachments, doGetAuthorisedLessorDetailForRequest, doGetRequestDetail, doGetOfferDetail, doAcceptOfferForm, doGetCompanyDetailForRequest } = useSecureService();
    const [offerForm, setOfferForm] = useState<OfferFormViewModel | OfferFormRenterViewModel | undefined>(undefined);
    const [requestForm, setRequestForm] = useState<RequestFormOfferViewModel | undefined>(undefined);
    const [lightboxVisible, toggleLightboxVisible] = useState(false);
    const [companyDetailsVisible, toggelCompanyDetailsVisible] = useState(false);
    const [companyDetails, setCompanyDetails] = useState<CompanyBasicModel>();
    const reqIdN = parseInt(reqId || "NaN");
    const offerIdN = parseInt(offerId || "NaN");
    const [selectedBid, setSelectedBid] = useState<number | undefined>(undefined);
    const [, setErrorMessage] = useAtom(postloginMessageAtom);
    const [acceptOfferWithoutBidSelection, setAcceptOfferWithoutBidSelection] = useState(false);
    const [offerFormComments, setOfferFormComments] = useState<OfferFormCommentViewModel[] | undefined | null>(undefined);
    const [availableAttachments, setAvailableAttachments] = useState<BasicModel[]>([]);
    const [showDeclineOffer, setShowDeclineOffer] = useState<boolean>(false);
    const [aggrementAccepted, setAggrementAccepted] = useState<boolean>(true); // set false when agreement is active
    const [offerDetail, setOfferDetail] = useState<OfferFormViewModel>();
    const [requestedMachineId, setRequestedMachineId] = useState<number | undefined>(undefined);
    const [dataFetching, setDataFetching] = useState(true);
    const [authorisedLessorInfo, setAuthorisedLessorInfo] = useState<LessorCompanyAuthorizedSellerInfoModel>({authorisedLessors: []});


    useEffect(() => {
        const getDataForRenter = async () => {
            const result = await doGetRequestDetail(reqIdN);
            setRequestedMachineId(result?.machineId ?? undefined);
            const selectedOffer = result?.offerForms?.find((form) => form.id === offerIdN);
            if(result && result.subCategory && result.subCategory.id){
                const attachments = await doGetAttachments(result.subCategory.id);
                setAvailableAttachments(attachments ?? []);
            }
           
            setOfferForm(selectedOffer);
            setRequestForm(result);
            doGetLessorInfo(result);

            const selectedBid = selectedOffer?.bids?.find((bid) => bid.selected === true);
            if (selectedOffer?.status === "Accepted") setSelectedBid(selectedBid?.id);
        };

        if (reqId && role === "customer") {
            if (Number.isNaN(reqIdN) || Number.isNaN(offerIdN)) return;
            getDataForRenter();
        } 
        else if (offerId && role === "lessor") {
            if (!Number.isNaN(offerIdN)) {
                doGetOfferDetail(offerIdN).then((data) => {
                    setRequestedMachineId(data?.requestForm?.machineId ?? undefined);
                    setOfferForm(data);
                    setOfferDetail(data);
                    setRequestForm(data?.requestForm);
                    setOfferFormComments(data?.comments);
                    if(data && data.requestForm?.subCategory && data.requestForm?.subCategory.id){
                        doGetAttachments(data.requestForm?.subCategory.id).then(
                            (attcData) => {setAvailableAttachments(attcData ?? [])}
                        );
                        
                    }
                });
            }
        }
        //eslint-disable-next-line
    }, []);

    const onBidSelected = (bidId: number | undefined) => {
        setSelectedBid(bidId);
    };

    useEffect(() => {
        const getCompanyData = async () => {
            const result = await doGetCompanyDetailForRequest(offerIdN, reqIdN);
            setCompanyDetails(result);
        };
        if (offerForm && offerForm?.status === "Deal") getCompanyData();
        if (offerForm !== undefined) {
            setDataFetching(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [offerForm]);

    const doGetLessorInfo = async (reqFrom: ModifiedRequestDetailModel | undefined) => {
        if ( role === 'customer'  && (reqFrom?.status === 'Deal' || reqFrom?.status === 'Completed')) {
            const result = await doGetAuthorisedLessorDetailForRequest(offerIdN, reqIdN);
            if (result) {
                setAuthorisedLessorInfo(result);
            }
        }
    };

    const acceptOffer = async () => {
        if (selectedBid) {
            const result = await doAcceptOfferForm(selectedBid, offerIdN);
            if (result) {
                setErrorMessage({
                    type: "success",
                    message: "payment link: " + result.paymentLink,
                });
                navigate(-1);
            }
        } else
            setErrorMessage({
                type: "error",
                message: t("offers.view.noBidSelectedError"),
            });
    };

    const acceptOfferCheckBidSelection = () => {
        if(!aggrementAccepted) {
            setErrorMessage({
                type: "error",
                message: t("offers.view.aggrementError"),
            });
            return;
        }
        if (selectedBid) {
            toggleLightboxVisible(true);
        } else {
            setAcceptOfferWithoutBidSelection(true);
            setErrorMessage({
                type: "error",
                message: t("offers.view.bidNotSelected"),
            });
        }
    };

    const acceptAggrement = (accept: boolean) => {
        setAggrementAccepted(accept);
    }

    const showDeclineOfferModal = () => {
        setShowDeclineOffer(true);
    }

    let buttons = [];

    buttons.push(
        <RequestAndOfferStatus showOfferStatus={true} showRequestStatus={false} role={role} big offerStatus={offerForm?.status} />
    );

    if (offerForm?.status === "Declined" && requestForm?.status !== "Expired") {
        buttons.push(<SecondaryButton onClick={() => navigate("/offers/edit?reqid=" + reqId + "&offerid=" + offerForm.id)}>{t("common.edit")}</SecondaryButton>);
    }

    return (
        <SecureLayout
            activePage={role === "customer" ? "requests-and-offers" : "offers"}
            role={role}
            loading={dataFetching}
            title={t("offers.view.title")}
            description={role === "customer" ? t("offers.view.description.renter") : t("offers.view.description.lessor")}
            helperButtons={buttons}
        >
            <Row gutter={[4, 4]} justify={"center"}>
                <Col xs={24}>
                    {offerForm?.status === "PaymentDeclined" && (
                        <BoxContainer
                            style={{
                                display: "flex",
                                height: "inherit",
                                alignItems: "center",
                                flexDirection: "row",
                                borderLeft: "0.75rem solid red",
                            }}
                        >
                            <Row gutter={[16, 16]}>
                                <Col>
                                    <Danger />
                                </Col>
                                <Col style={{ color: "red", display: "flex", alignItems: "center" }}>
                                    <span>{t("offer.view.declinedPayment")}</span>
                                </Col>
                            </Row>
                        </BoxContainer>
                    )}
                    {offerForm?.status === "Deal" && (
                        <RequestViewPart
                            deal={true}
                            offerId={offerIdN}
                            onDealClick={async () => {
                                await doGetLessorInfo(requestForm);
                                toggelCompanyDetailsVisible(true);
                            }}
                            requestId={reqIdN}
                            onClick={() =>
                                navigate(
                                    role === "customer"
                                        ? "/requests/view?reqid=" + reqId
                                        : "/requests/view?reqid=" + reqId + "&offerid=" + offerId,
                                    { replace: true }
                                )
                            }
                            role={role!}
                        />
                    )}
                    {offerForm?.status !== "Deal" && (
                        <RequestViewPart
                            requestId={reqIdN}
                            offerId={offerIdN}
                            onClick={() =>
                                navigate(
                                    role === "customer"
                                        ? "/requests/view?reqid=" + reqId
                                        : "/requests/view?reqid=" + reqId + "&offerid=" + offerId,
                                    { replace: true }
                                )
                            }
                            role={role!}
                        />
                    )}
                </Col>
                <Col xs={24} md={12}>
                    <OfferViewMachineInfoPart requestedMachineId={requestedMachineId} additionalServices={offerForm?.additionalServices} bid={offerForm?.bids?.at(0)} availableAttachments={availableAttachments} role={role!} machines={offerForm?.machines} offerId={offerForm?.id} />
                </Col>
                <Col xs={24} md={12}>
                    <OfferViewAdditionalInfoPart
                        req={requestForm}
                        availableAttachments={availableAttachments}
                        docs={offerForm?.documents}
                        onlyView={offerForm?.status === "Accepted"}
                        onBidSelected={onBidSelected}
                        role={role}
                        notes={offerForm?.notes}
                        bids={offerForm?.bids}
                        billing={offerForm?.billingTimeOptions}
                        acceptOfferWithoutBidSelection={acceptOfferWithoutBidSelection}
                        offerStatus={offerForm?.status}
                    />
                </Col>
               {/*  <Col xs={24} md={24}>
                    <ViewAdditionalServicesPart viewType="offer" additionalServices={offerForm?.additionalServices} />
                </Col> */}
                {
                    role === "customer" && 
                    <Col xs={24} md={24}>
                        <OfferViewAdditionalServicesPart viewType="offer" hasOperator={offerForm?.additionalServices?.hasOperator ?? false} additionalServices={offerForm?.additionalServices} />
                    </Col>
                }
                
                {/* <Col xs={24} md={24}>
                    <BigboxContainer title={t("machines.add.additionalServicesTitle")}>
                        <Row gutter={[16, 16]}>
                            <BasicModelViewer objKey={t("offers.view.lastAvailableDateTitle")} value={moment(offerForm?.expireDate)} />
                        </Row>
                    </BigboxContainer>
                </Col> */}
                <Col xs={24} md={24}>

                    <OfferAggrementPart acceptAggrement={acceptAggrement} expireDate={moment(offerForm?.expireDate)} creationDate={moment(offerForm?.creationDate)} req={requestForm}/>

                </Col>
                {role === "customer" && offerForm?.status === "Approved" && (
                    <Col xs={16} md={5} style={{ marginTop: "1rem", marginInline: "1rem", }}>
                        <SecondaryButton onClick={() => showDeclineOfferModal()}>
                            <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', columnGap: '.5rem', color: '#EE3031'}}>
                            <Decline></Decline>
                            <span style={{marginBottom: '1px', fontWeight: '800'}}>{t("offers.view.declineOfferVersion2")}</span>
                            </div>
                            
                        </SecondaryButton>
                    </Col>
                )}
                {role === "customer" && offerForm?.status === "Approved" && (
                    <Col xs={16} md={5} style={{ marginTop: "1rem", marginInline: "1rem" }}>
                        <PrimaryButton onClick={() => acceptOfferCheckBidSelection()}>
                            {t("offers.view.acceptOffer")}
                        </PrimaryButton>
                    </Col>
                )}
                {role === "customer" && offerForm?.status === "Accepted" && (
                    <Col xs={ 24 } md={10} style={{ marginTop: "1rem", display: 'flex', justifyContent: 'center' }}>
                        <PrimaryButton  onClick={() => toggleLightboxVisible(true)}>{t("offers.view.viewPaymentInfo")}</PrimaryButton>
                    </Col>
                )}
                {offerFormComments && offerFormComments.length > 0 && (
                    <Col xs={24}>
                        <OfferHistoryPart comments={offerFormComments} />
                    </Col>
                )}
                {role === "lessor" && (offerForm?.status?.toLowerCase() === "offered" || offerForm?.status?.toLowerCase() === "rejected") && 
                    <Row style={{display: 'flex', justifyContent: 'center', width: '100%', marginTop: '1rem'}}>
                        <Col xs={24} md={8} style={{height: '3rem'}}>
                            <PrimaryButton 
                                style={{display: 'inline-block', height: '3rem'}}
                                onClick={(e) => {
                                    navigate('/offers/edit?offerid=' + offerId +
                                    "&max=" + offerDetail?.requestForm?.machineCount 
                                    );
                                }}
                            >
                                {t("offers.view.editOffer")}
                            </PrimaryButton>
                        </Col>
                    </Row>
                }
            </Row>
            <OfferApproveModal
                approved={role === "customer" && offerForm?.status === "Accepted"}
                bid={(offerForm?.bids as OfferFormRenterBidViewModel[])?.find((item: any) => item?.id === selectedBid)}
                show={lightboxVisible}
                onCloseClick={() => {
                    toggleLightboxVisible(false);
                }}
                onOkClick={acceptOffer}
            ></OfferApproveModal>

            <OfferDeclineModal
                show={showDeclineOffer}
                offerIdN={offerId ?? ""}
                onCloseClick={() => {
                    setShowDeclineOffer(false);
                }}
            />

            {offerForm?.status === "Deal" && (
                <CompanyDetailModal
                    authorisedLessors={authorisedLessorInfo}
                    role={role!}
                    details={companyDetails}
                    approved={offerForm?.status === "Deal"}
                    onCloseClick={() => {
                        toggelCompanyDetailsVisible(false);
                    }}
                    show={companyDetailsVisible}
                ></CompanyDetailModal>
            )}
        </SecureLayout>
    );
};

export default ViewOffer;
