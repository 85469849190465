import { useState } from "react";
import { useNavigate } from "react-router-dom";
import SecureLayout from "../../../components/layouts/secure-layout";
import SecureRequestSummary from "../../../modals/secure-request-summary";
import { t } from "../../../translate";
import MachineDetail from "../../request/machine-detail";

const AddMachineDetail = () => {
    const [modalVisible, setModalVisible] = useState<boolean>(false);
    const navigate = useNavigate();

    const formComplete = () => {
        setModalVisible(true);
    };

    const closeClicked = () => {
        setModalVisible(false);
    };

    const onApproveRequest = () => {
        navigate("/dashboard?role=renter&request=true");
    };

    const onEditClick = () => {
        setModalVisible(false);
    };

    return (
        <SecureLayout
            onlyHeader
            activePage={"requests-and-offers"}
            role={"customer"}
            title={t("requests-and-offers.add.title")}
            description={t("requests-and-offers.add.desceription")}
        >
            <MachineDetail secure formComplete={formComplete} />
            <SecureRequestSummary
                onApprove={onApproveRequest}
                onEdit={onEditClick}
                show={modalVisible}
                onCloseClick={closeClicked}
            ></SecureRequestSummary>
        </SecureLayout>
    );
};

export default AddMachineDetail;
