/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable array-callback-return */
import { Button, Checkbox, Col, Collapse, Drawer, List, Popover, Row } from "antd";
import CollapsePanel from "antd/lib/collapse/CollapsePanel";
import { useAtom } from "jotai";
import * as React from "react";
import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { ReactComponent as Desc } from "../../../components/assets/arrow-down-circle-outline.svg";
import { ReactComponent as Asc } from "../../../components/assets/arrow-up-circle-outline.svg";
import PrimaryButton from "../../../components/atomics/primary-button";
import SearchBox from "../../../components/atomics/search-box";
import SearchableTree from "../../../components/atomics/searchable-tree";
import StyledSelect from "../../../components/atomics/styled-select";
import TertiaryButton from "../../../components/atomics/tertiary-button";
import SecureLayout from "../../../components/layouts/secure-layout";
import { BasicModel, CategoryModel } from "../../../service";
import { SortingDataType, machineFilterAtom } from "../../../store/global-atoms";
import { useSecureService } from "../../../store/service-atoms";
import style from "./list.module.scss";
import OnSaleProductPart from "../../../page-parts/on-sale-products-part";
import { t } from "../../../translate";
import { allCategories } from "../../../store/all-categories";
import { CheckBoxList } from "../../../components/atomics/checkbox-list";

const SortingStaticData = [
    {
        label: "Önerilen",
        value: "SaleShowCases",
        icon: 0,
    },
    {
        label: "Artan Fiyat",
        value: "PriceAsc",
        icon: 1,
    },
    {
        label: "Azalan Fiyat",
        value: "PriceDesc",
        icon: 2,
    },
    {
        label: "Yeniden Eskiye",
        value: "ModifiedDateDesc",
        icon: 2,
    },
    {
        label: "Eskiden Yeniye",
        value: "ModifiedDateAsc",
        icon: 1,
    },
];

const ListSaleMachines = () => {
    const { doGetCategoryWithChildren, doGetCities, doGetBrandsByCategory } = useSecureService();
    const [sortingMethod, setSortingMethod] = useState<SortingDataType>(SortingStaticData[0]);
    const [showDrawer, setShowDrawer] = useState(false);

    const [cities, setCities] = useState<any[]>();
    const [categories, setCategories] = useState<CategoryModel[] | undefined>(undefined);
    const [brands, setBrands] = useState<{catId:Number, brandsList:BasicModel[]}[] | undefined>(undefined);

    const [selectedCity, setSelectedCity] = useState<number | null>(null);
    const [minPrice, setMinPrice] = useState<number | undefined>(undefined);
    const [maxPrice, setMaxPrice] = useState<number | undefined>(undefined);

    const [selectedCategoryIds, setSelectedCategoryIds] = useState<number[]>([]);
    const [selectedBrandIds, setSelectedBrandIds] = useState<number[]>([]);
    const [, setSelectedSubCategoryIds] = useState<number[]>([]);

    const [hasFilter, setHasFilter] = useState<boolean>(false);

    const [machineFilter, setMachineFilter] = useAtom(machineFilterAtom);
    const [searchParams, setSearchParams] = useSearchParams();
    const [expertiseStatus, setExpertiseStatus] = useState<1 | 2 | undefined>(undefined);

    useEffect(() => {
        const getAllCities = async () => {
            var result = await doGetCities();
            if (result) {
                let options: any = [];
                result?.map((city: any) => {
                    options?.push({ value: city.id, label: city.name });
                });
                setCities(options);
            }
        };
        const getAllCategories = async () => {
            const result = await doGetCategoryWithChildren();
            if (result) {
                setCategories(result as CategoryModel[]);
            }
        };
        getAllCategories();
        getAllCities();
    }, []);

    // Filter Change begins...

    const onMinPriceChange = (value: number | undefined) => {
        if (value === undefined) {
            searchParams.delete("minPrice");
        } else {
            searchParams.set("minPrice", (value || "").toString());
            searchParams.set("page", "1");
        }
        setSearchParams(searchParams, { replace: true });

        setMinPrice(value);
    };

    const onMaxPriceChange = (value: number | undefined) => {
        if (value === undefined) {
            searchParams.delete("maxPrice");
        } else {
            searchParams.set("maxPrice", (value || "").toString());
            searchParams.set("page", "1");
        }
        setSearchParams(searchParams, { replace: true });

        setMaxPrice(value);
    };

    const onCityChange = (selectedCityId: number | null) => {
        if (selectedCityId) {
            searchParams.set("selectedCity", selectedCityId.toString());
            searchParams.set("page", "1");
        } else {
            searchParams.delete("selectedCity");
        }
        setSearchParams(searchParams, { replace: true });

        setSelectedCity(selectedCityId);
    };

    const onCategorySubcategoryTreeChange = async (
        categoryIds: number[],
        subCategoryIds: number[],
        selectedKeys?:
            | React.Key[]
            | {
                  checked: React.Key[];
                  halfChecked: React.Key[];
              }
    ) => {
        if (selectedKeys === null || selectedKeys === undefined || selectedKeys.toString() === "") {
            searchParams.delete("makineKategori");
        } else {
            searchParams.set("makineKategori", selectedKeys?.toString().replaceAll(",", "--"));
        }
        searchParams.set("page", "1");

        setSelectedCategoryIds(categoryIds);
        setSelectedSubCategoryIds(subCategoryIds);

        var oldList = brands ? [...brands] : [];

        var oldSelectedBrands = brands ? [...selectedBrandIds] : [];

        var selectedCategoryIds = categoryIds;
        for (let index = 0; index < subCategoryIds.length; index++) {
            let selectedCat = allCategories.find((val) => val.subCategories.some((subCat) => subCat.id === subCategoryIds[index]))?.id
            if (!selectedCategoryIds.some((val) => val === selectedCat))
            {
                selectedCategoryIds.push(selectedCat ?? 0);
            }
        }

        //uncheck olan kategorilerin markalarını brand listesinden ve seçilmiş brand listesinden kaldırmak için
        brands?.forEach((x) => {
            if (!selectedCategoryIds.some((catid) => x.catId === catid)) {
                oldList = oldList?.filter((oldItem) => oldItem.catId !== x.catId);
                if (oldSelectedBrands.length > 0)
                    selectedBrandIds.forEach((element) => {
                        if (x.brandsList.some((val) => val.id === element)) {
                            oldSelectedBrands = oldSelectedBrands?.filter((oldItem) => oldItem !== element);
                        }
                    });
            }
        });

         //check olan kategorilerin markalarını listeye yüklemek için
         const updatedList = [];

        for (const element of selectedCategoryIds) {
            var loaded = oldList?.some((x) => x.catId === element);

            if (!loaded) {
                const result = await doGetBrandsByCategory(element);

                if (result) {
                    updatedList.push({
                        catId: element,
                        brandsList: result,
                    });
                }
            }
        }
        
        //eğer seçilmiş olan brand ilgili kategori kaldırıldıysa query stringinden de kaldırılsın
        let selectedBrands = "";

        if (oldSelectedBrands.length > 0) {
            oldSelectedBrands.forEach((element) => {
                selectedBrands = selectedBrands + element + "--";
            });

            searchParams.set("brands", selectedBrands);
        } else {
            searchParams.delete("brands");
        }

        setSearchParams(searchParams, { replace: true });

        setSelectedBrandIds(oldSelectedBrands);

        setBrands([...oldList, ...updatedList]);
    };

    const onBrandSelected = (brandIds: number[]) => {
        setSelectedBrandIds(brandIds);
        let brands = "";
        if (brandIds.length > 0) {
            brandIds.forEach((element) => {
                brands = brands + element + "--";
            });
            searchParams.set("brands", brands);
        } else {
            searchParams.delete("brands");
        }
        setSearchParams(searchParams, { replace: true });
    };

    const onExpertiseSelected = (expertiseId: 1 | 2 | undefined) => {
        if (expertiseStatus !== expertiseId) {
            setExpertiseStatus(expertiseId);
        }
        searchParams.set("expertise", expertiseId?.toString() ?? "");
        setSearchParams(searchParams, { replace: true });
    }

    // Filter Change ends...

    // Sorting Method, Artan azalan etc..
    const onSortingMethodChange = (sortingMethodValue: SortingDataType) => {
        searchParams.set("order", sortingMethodValue.value);
        searchParams.set("page", "1");
        setSearchParams(searchParams, { replace: true });

        setSortingMethod(sortingMethodValue);
    };

    // Pagination
    const onPageChange = (page: number) => {
        searchParams.set("page", page.toString());
        setSearchParams(searchParams);
    };

    const onClearFilter = () => {
        setSearchParams({ page: "1", order: SortingStaticData[0].value });
        setBrands([]);
        setExpertiseStatus(undefined);
        setHasFilter(false);
    };

    // parameter management begins...

    const multipleParameterSearchHelperString = (data: string | null, splitBy = "--") => {
        return (
            data?.split(splitBy).map((x) => {
                if (x.startsWith("cat")) {
                    return String(x);
                } else {
                    return Number(x);
                }
            }) ?? []
        );
    };

    const getNumberItemFromParams = (item: string) => {
        return searchParams.has(item) ? Number.parseInt((searchParams.get(item) ?? "").toString()) : null;
    };

    useEffect(() => {
        const activePageNo = Number.parseInt(searchParams.get("page") || "1");
        const activeSortingMethod = SortingStaticData.find((x) => x.value === searchParams.get("order")) ?? SortingStaticData[0];

        var hasDefaultValues = true;
        if (!searchParams.has("page")) {
            hasDefaultValues = false;
            searchParams.set("page", activePageNo.toString());
        }

        if (!searchParams.has("order")) {
            hasDefaultValues = false;
            searchParams.set("order", activeSortingMethod.value);
        }

        if (!hasDefaultValues) {
            return setSearchParams(searchParams);
        }

        const searchSelectedKeys: (string | number)[] = multipleParameterSearchHelperString(searchParams.get("makineKategori"), "--");
        const searchCategoryIds = searchSelectedKeys
            .filter((x) => x.toString().startsWith("cat-"))
            .map((x) => Number(x.toString().split("-")[1]));
        const searchSubCategoryIds = searchSelectedKeys.filter((x) => !x.toString().startsWith("cat-")).flatMap((x) => Number(x));
        const searchBrandIds = multipleParameterSearchHelperString(searchParams.get("brands"), "--").flatMap((x) => Number(x));
        //const searchExpertiseStatus = searchParams.get("expertise") ? searchParams.get("expertise") === "1" : undefined;
        setMachineFilter((old) => {
            return {
                ...old,
                categoryIds: searchCategoryIds,
                subCategoryIds: searchSubCategoryIds,
                brandIds: searchBrandIds,
                //hasExpertise: searchExpertiseStatus,
                cityId: getNumberItemFromParams("selectedCity"),
                maxPrice: getNumberItemFromParams("maxPrice"),
                minPrice: getNumberItemFromParams("minPrice"),
                activePage: activePageNo,
                order: activeSortingMethod.value,
                selectedKeys: searchSelectedKeys,
            };
        });

        if (Array.from(searchParams).length > 2 || activePageNo !== 1 || activeSortingMethod.value !== SortingStaticData[0].value) {
            setHasFilter(true);
        }

        setSortingMethod(activeSortingMethod);

        //eslint-disable-next-line
    }, [searchParams]);
    // parameter management ends...

    // getValuesFromParameters
    useEffect(() => {
        if (machineFilter) {
            setMinPrice(machineFilter.minPrice || undefined);
            setMaxPrice(machineFilter.maxPrice || undefined);

            setSelectedCity(machineFilter.cityId || null);

            setSelectedCategoryIds(machineFilter.categoryIds || []);
            setSelectedSubCategoryIds(machineFilter.categoryIds || []);

            if ( 
                (machineFilter.brandIds?.length ?? 0) > 0 || 
                (machineFilter.categoryIds?.length ?? 0) > 0 ||
                (machineFilter.subCategoryIds?.length ?? 0) > 0 ||
                (machineFilter.cityId !== undefined && machineFilter.cityId !== null) ||
                (machineFilter.districtId !== undefined && machineFilter.districtId !== null) ||
                (machineFilter.rentalTypeId !== undefined && machineFilter.rentalTypeId !== null)  || 
                (machineFilter.maxPrice !== undefined && machineFilter.maxPrice !== null) || 
                (machineFilter.minPrice !== undefined && machineFilter.minPrice !== null)  ||                                                  
                (machineFilter.categoryIds !== undefined && machineFilter.categoryIds.length > 0) 
            ) {
                setHasFilter(true);
            } else {
                setHasFilter(false);
            }
        }
        
    }, [machineFilter]);

    return (
        <SecureLayout activePage={"sale-machines"}>
            <Row className={style.mobileFilter} gutter={8} style={{ padding: "2rem 0" }}>
                <Col md={12} xs={8}>
                    <Button className={style.filterButtons} onClick={() => setShowDrawer(true)}>
                        Filtrele
                    </Button>
                </Col>
                <Col md={12} xs={16}>
                    <Popover
                        className={style.filterButtons}
                        placement="bottom"
                        arrowContent={false}
                        getPopupContainer={(trigger) => trigger.parentNode as HTMLElement}
                        content={
                            <List
                                className={style.orderListContainer}
                                dataSource={SortingStaticData}
                                renderItem={(item) => (
                                    <List.Item style={{ border: "none" }}>
                                        <a onClick={() => onSortingMethodChange(item)} className={style.orderList}>
                                            {item.label}
                                        </a>
                                    </List.Item>
                                )}
                            />
                        }
                        trigger="focus"
                    >
                        <Button onClick={(e) => e.currentTarget.focus()}>
                            <Row style={{ alignItems: "center", justifyContent: "center" }}>
                                <span style={{ marginRight: 5 }}>Sırala: </span>
                                <span className={style.orderSelection}>{sortingMethod.label}</span>
                                {sortingMethod.icon === 0 ? <></> : sortingMethod.icon === 1 ? <Asc /> : <Desc />}
                            </Row>
                        </Button>
                    </Popover>
                </Col>
            </Row>
            <Drawer
                style={{ zIndex: "1" }}
                bodyStyle={{ margin: "5rem 0 0 0" }}
                height="100vh"
                placement="top"
                closable={false}
                visible={showDrawer}
            >
                <Collapse className={style.CollapsePanel} defaultActiveKey={["1", "2", "3", "4", "5"]}>
                    <CollapsePanel className="searchable" header="İhtiyacınız Olan Makine Tipini Seçiniz.." key="1">
                        <SearchableTree
                            categories={selectedCategoryIds.flatMap((x) => String(x))}
                            selectedKeys={machineFilter?.selectedKeys ?? []}
                            onItemChecked={onCategorySubcategoryTreeChange}
                            data={categories}
                        ></SearchableTree>
                    </CollapsePanel>
                    {brands && brands.length > 0 && (
                        <CollapsePanel className="searchable" header={t("sale-machines.brand.header")} key="2">
                            <CheckBoxList onItemChecked={onBrandSelected} data={brands?.flatMap((x) => x.brandsList)} />
                        </CollapsePanel>
                    )}
                    {false && (
                    <CollapsePanel className="searchable" header={t("sale-machines.expertise.header")} key="3">
                        <Row align="middle" justify="center" gutter={[8, 8]}>
                            <Col md={24} xs={24}>
                                <Checkbox checked={expertiseStatus === 1} onChange={() => onExpertiseSelected(1)}>
                                    {t("sale-machines.expertise.op-1")}
                                </Checkbox>
                            </Col>
                            <Col md={24} xs={24}>
                                <Checkbox checked={expertiseStatus === 2} onChange={() => onExpertiseSelected(2)}>
                                    {t("sale-machines.expertise.op-2")}
                                </Checkbox>
                            </Col>
                        </Row>
                    </CollapsePanel>
                    )}
                    <CollapsePanel header="Fiyat" key="4">
                        <Row gutter={[24, 24]}>
                            <Col md={24} xs={24}>
                                <SearchBox
                                    onPriceChange={onMinPriceChange}
                                    value={minPrice}
                                    placeholder={"Minimum Fiyat"}
                                    style={{ width: "100%" }}
                                ></SearchBox>
                            </Col>
                            <Col md={24} xs={24}>
                                <SearchBox
                                    onPriceChange={onMaxPriceChange}
                                    value={maxPrice}
                                    placeholder={"Maximum Fiyat"}
                                    style={{ width: "100%" }}
                                ></SearchBox>
                            </Col>
                        </Row>
                    </CollapsePanel>
                    <CollapsePanel header="Lokasyon Seçiniz" key="5">
                        <Row gutter={[24, 24]}>
                            <Col md={24} xs={24}>
                                <StyledSelect
                                    placeholder="İl"
                                    clearIcon
                                    value={selectedCity}
                                    optionFilterProp="children"
                                    onChange={e => {
                                        setSelectedCity(e);
                                        onCityChange(e);
                                    }}
                                    filterOption={(input, option) =>
                                        option?.label &&
                                        typeof option.label === "string" &&
                                        option.label.toLowerCase().includes(input.toLowerCase())
                                            ? true
                                            : false
                                    }
                                    options={cities}
                                />
                            </Col>
                        </Row>
                    </CollapsePanel>
                </Collapse>
                <Row gutter={16} style={{ padding: "2rem 0", paddingBottom: '80px'}}>
                    <Col md={12} xs={12}>
                        <TertiaryButton hidden={!hasFilter} style={{ width: "100%" }} onClick={onClearFilter}>
                            {t(`common.filter.reset`)}
                        </TertiaryButton>
                    </Col>
                    <Col md={hasFilter ? 12 : 24} xs={hasFilter ? 12 : 24}>
                        <PrimaryButton onClick={() => setShowDrawer(false)} style={{ width: "100%" }}>
                            Filtrele
                        </PrimaryButton>
                    </Col>
                </Row>
            </Drawer>
            <Row gutter={16}>
                <Col md={5} style={{ marginTop: "3rem" }} className={style.desktopVisible}>
                    <Collapse className={style.CollapsePanel} defaultActiveKey={["1", "2", "3", "4", "5", "6"]} bordered={false}>
                        <CollapsePanel
                            style={{ border: "none" }}
                            className="searchable"
                            header="İhtiyacınız Olan Makine Tipini Seçiniz.."
                            key="1"
                        >
                            <SearchableTree
                                selectedKeys={machineFilter?.selectedKeys}
                                categories={selectedCategoryIds.flatMap((x) => String(x))}
                                onItemChecked={onCategorySubcategoryTreeChange}
                                data={categories}
                            ></SearchableTree>
                        </CollapsePanel>
                        {brands && brands.length > 0 && (
                            <CollapsePanel className="searchable" header={t("sale-machines.brand.header")} key="2">
                                <CheckBoxList onItemChecked={onBrandSelected} data={brands?.flatMap((x) => x.brandsList)} />
                            </CollapsePanel>
                        )}
                        {
                            false && (
                            <CollapsePanel className="searchable" header={t("sale-machines.expertise.header")} key="3">
                                <Row align="middle" justify="center" gutter={[8, 8]}>
                                    <Col md={24} xs={24}>
                                        <Checkbox checked={expertiseStatus === 1} onChange={() => onExpertiseSelected(1)}>
                                            {t("sale-machines.expertise.op-1")}
                                        </Checkbox>
                                    </Col>
                                    <Col md={24} xs={24}>
                                        <Checkbox checked={expertiseStatus === 2} onChange={() => onExpertiseSelected(2)}>
                                            {t("sale-machines.expertise.op-2")}
                                        </Checkbox>
                                    </Col>
                                </Row>
                            </CollapsePanel>
                            )
                        }
                        <CollapsePanel style={{ border: "none" }} header="Fiyat" key="4">
                            <Row gutter={[24, 24]}>
                                <Col md={24} xs={24}>
                                    <SearchBox
                                        onPriceChange={onMinPriceChange}
                                        value={minPrice}
                                        placeholder={"Minimum Fiyat"}
                                        style={{ width: "100%" }}
                                    ></SearchBox>
                                </Col>
                                <Col md={24} xs={24}>
                                    <SearchBox
                                        onPriceChange={onMaxPriceChange}
                                        value={maxPrice}
                                        placeholder={"Maximum Fiyat"}
                                        style={{ width: "100%" }}
                                    ></SearchBox>
                                </Col>
                            </Row>
                        </CollapsePanel>
                        <CollapsePanel style={{ border: "none" }} header="Lokasyon Seçiniz" key="5">
                            <Row gutter={[24, 24]}>
                                <Col md={24} xs={24}>
                                    <StyledSelect
                                        allowClear
                                        placeholder="İl"
                                        optionFilterProp="children"
                                        value={selectedCity}
                                        onChange={onCityChange}
                                        filterOption={(input, option) =>
                                            option?.label &&
                                            typeof option.label === "string" &&
                                            option.label.toLowerCase().includes(input.toLowerCase())
                                                ? true
                                                : false
                                        }
                                        options={cities}
                                    />
                                </Col>
                            </Row>
                        </CollapsePanel>
                        {hasFilter && (
                            <CollapsePanel style={{ border: "none" }} header="Filtreleri Temizleyin" key="6">
                                <Row gutter={[24, 24]}>
                                    <Col md={24} xs={24}>
                                        <PrimaryButton onClick={onClearFilter} style={{ width: "100%" }}>
                                            Filtreleri Temizleyin
                                        </PrimaryButton>
                                    </Col>
                                </Row>
                            </CollapsePanel>
                        )}
                    </Collapse>
                </Col>
                <Col md={19}>
                    <OnSaleProductPart
                        showPagination
                        orderButton={
                            <Popover
                                className={style.filterButtons}
                                placement="bottom"
                                arrowContent={false}
                                getPopupContainer={(trigger) => trigger.parentNode as HTMLElement}
                                content={
                                    <List
                                        className={style.orderListContainer}
                                        dataSource={SortingStaticData}
                                        renderItem={(item) => (
                                            <List.Item style={{ border: "none" }}>
                                                <a onClick={() => onSortingMethodChange(item)} className={style.orderList}>
                                                    {item.label}
                                                </a>
                                            </List.Item>
                                        )}
                                    />
                                }
                                trigger="focus"
                            >
                                <Button onClick={(e) => e.currentTarget.focus()} style={{ width: "94%" }}>
                                    <Row style={{ alignItems: "center", justifyContent: "center" }}>
                                        <span style={{ marginRight: 5 }}>Sırala: </span>
                                        <span className={style.orderSelection}>{sortingMethod.label}</span>
                                        {sortingMethod.icon === 0 ? <></> : sortingMethod.icon === 1 ? <Asc /> : <Desc />}
                                    </Row>
                                </Button>
                            </Popover>
                        }
                        machineFilter={machineFilter}
                        showHeader={false}
                        showButton={false}
                        onPageChange={onPageChange}
                    />
                </Col>
            </Row>
        </SecureLayout>
    );
};

export default ListSaleMachines;
