import { Col, Form, FormInstance, InputNumber, Row } from "antd";
import styles from "./min-max-input.module.scss"
import { useState } from "react";

interface MinMaxInputProps {
    form: FormInstance<any>;
    minFieldName: string;
    maxFieldName: string;
    minPlaceholder?: string;
    maxPlaceholder?: string;
    defaultMinValue?: number; 
    defaultMaxValue?: number; 
    min?: number;
    max?: number;
    label: string;
    required?: boolean;
}

const MinMaxInput = (props: MinMaxInputProps) => {
    const [minVal, setMinVal] = useState(props.defaultMinValue);
    const [maxVal, setMaxVal] = useState(props.defaultMaxValue);

    return(
        <Row className={styles.container} justify="space-between" gutter={[4, 4]}>
            <Col xs={24}>
                <h3>{props.label}{props.required ? <span className={styles.required}>{' *'}</span> : ""}</h3>
            </Col>
            <Col xs={12} className={styles.inputContainer} style={{paddingLeft: '0'}}>
                <Form.Item
                    rules={[
                        {
                            required: props.required,
                            message: ""
                        }
                    ]}
                    name={props.minFieldName}
                >
                    <InputNumber 
                    min={props.min}
                    max={maxVal ?? props.max} 
                    value={minVal} 
                    onChange={(e) => setMinVal(e)} className={styles.input} placeholder={props.minPlaceholder}></InputNumber>
                </Form.Item>
            </Col> 
            <Col xs={12} className={styles.inputContainer} style={{paddingRight: '0'}}>
                <Form.Item
                    rules={[
                        {
                            required: props.required,
                            message: ""
                        }
                    ]}
                    name={props.maxFieldName}
                >
                    <InputNumber
                    min={minVal ?? props.min}
                    max={props.max}
                    value={maxVal} 
                    onChange={(e) => setMaxVal(e)} 
                    className={styles.input} 
                    placeholder={props.maxPlaceholder}></InputNumber>
                </Form.Item>
            </Col>                   
        </Row>
    );
}

export default MinMaxInput;