/* tslint:disable */
/* eslint-disable */
/**
 * MachineGo P1 API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface MachineListSearchFilterModel
 */
export interface MachineListSearchFilterModel {
    /**
     * 
     * @type {number}
     * @memberof MachineListSearchFilterModel
     */
    machineId?: number | null;
    /**
     * 
     * @type {number}
     * @memberof MachineListSearchFilterModel
     */
    cityId?: number | null;
    /**
     * 
     * @type {number}
     * @memberof MachineListSearchFilterModel
     */
    districtId?: number | null;
    /**
     * 
     * @type {Array<number>}
     * @memberof MachineListSearchFilterModel
     */
    categoryIds?: Array<number> | null;
    /**
     * 
     * @type {Array<number>}
     * @memberof MachineListSearchFilterModel
     */
    subCategoryIds?: Array<number> | null;
    /**
     * 
     * @type {number}
     * @memberof MachineListSearchFilterModel
     */
    rentalTypeId?: number | null;
    /**
     * 
     * @type {number}
     * @memberof MachineListSearchFilterModel
     */
    minPrice?: number | null;
    /**
     * 
     * @type {number}
     * @memberof MachineListSearchFilterModel
     */
    maxPrice?: number | null;
}

export function MachineListSearchFilterModelFromJSON(json: any): MachineListSearchFilterModel {
    return MachineListSearchFilterModelFromJSONTyped(json, false);
}

export function MachineListSearchFilterModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): MachineListSearchFilterModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'machineId': !exists(json, 'machineId') ? undefined : json['machineId'],
        'cityId': !exists(json, 'cityId') ? undefined : json['cityId'],
        'districtId': !exists(json, 'districtId') ? undefined : json['districtId'],
        'categoryIds': !exists(json, 'categoryIds') ? undefined : json['categoryIds'],
        'subCategoryIds': !exists(json, 'subCategoryIds') ? undefined : json['subCategoryIds'],
        'rentalTypeId': !exists(json, 'rentalTypeId') ? undefined : json['rentalTypeId'],
        'minPrice': !exists(json, 'minPrice') ? undefined : json['minPrice'],
        'maxPrice': !exists(json, 'maxPrice') ? undefined : json['maxPrice'],
    };
}

export function MachineListSearchFilterModelToJSON(value?: MachineListSearchFilterModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'machineId': value.machineId,
        'cityId': value.cityId,
        'districtId': value.districtId,
        'categoryIds': value.categoryIds,
        'subCategoryIds': value.subCategoryIds,
        'rentalTypeId': value.rentalTypeId,
        'minPrice': value.minPrice,
        'maxPrice': value.maxPrice,
    };
}

