/* tslint:disable */
/* eslint-disable */
/**
 * MachineGo P1 API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    LessorCompanySearchFilterModel,
    LessorCompanySearchFilterModelFromJSON,
    LessorCompanySearchFilterModelFromJSONTyped,
    LessorCompanySearchFilterModelToJSON,
} from './LessorCompanySearchFilterModel';
import {
    LessorCompanySearchSortField,
    LessorCompanySearchSortFieldFromJSON,
    LessorCompanySearchSortFieldFromJSONTyped,
    LessorCompanySearchSortFieldToJSON,
} from './LessorCompanySearchSortField';

/**
 * 
 * @export
 * @interface LessorCompanySearchModel
 */
export interface LessorCompanySearchModel {
    /**
     * 
     * @type {number}
     * @memberof LessorCompanySearchModel
     */
    pageNo?: number;
    /**
     * 
     * @type {number}
     * @memberof LessorCompanySearchModel
     */
    pageSize?: number;
    /**
     * 
     * @type {number}
     * @memberof LessorCompanySearchModel
     */
    readonly rowIndex?: number;
    /**
     * 
     * @type {LessorCompanySearchFilterModel}
     * @memberof LessorCompanySearchModel
     */
    filter?: LessorCompanySearchFilterModel;
    /**
     * 
     * @type {LessorCompanySearchSortField}
     * @memberof LessorCompanySearchModel
     */
    sortBy?: LessorCompanySearchSortField;
    /**
     * 
     * @type {boolean}
     * @memberof LessorCompanySearchModel
     */
    sortAscending?: boolean;
}

export function LessorCompanySearchModelFromJSON(json: any): LessorCompanySearchModel {
    return LessorCompanySearchModelFromJSONTyped(json, false);
}

export function LessorCompanySearchModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): LessorCompanySearchModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'pageNo': !exists(json, 'pageNo') ? undefined : json['pageNo'],
        'pageSize': !exists(json, 'pageSize') ? undefined : json['pageSize'],
        'rowIndex': !exists(json, 'rowIndex') ? undefined : json['rowIndex'],
        'filter': !exists(json, 'filter') ? undefined : LessorCompanySearchFilterModelFromJSON(json['filter']),
        'sortBy': !exists(json, 'sortBy') ? undefined : LessorCompanySearchSortFieldFromJSON(json['sortBy']),
        'sortAscending': !exists(json, 'sortAscending') ? undefined : json['sortAscending'],
    };
}

export function LessorCompanySearchModelToJSON(value?: LessorCompanySearchModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'pageNo': value.pageNo,
        'pageSize': value.pageSize,
        'filter': LessorCompanySearchFilterModelToJSON(value.filter),
        'sortBy': LessorCompanySearchSortFieldToJSON(value.sortBy),
        'sortAscending': value.sortAscending,
    };
}

