/* tslint:disable */
/* eslint-disable */
/**
 * MachineGo P1 API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface MachineCommissionCreateModel
 */
export interface MachineCommissionCreateModel {
    /**
     * 
     * @type {number}
     * @memberof MachineCommissionCreateModel
     */
    categoryId?: number;
    /**
     * 
     * @type {number}
     * @memberof MachineCommissionCreateModel
     */
    subCategoryId?: number;
    /**
     * 
     * @type {number}
     * @memberof MachineCommissionCreateModel
     */
    hourlyRate?: number;
    /**
     * 
     * @type {number}
     * @memberof MachineCommissionCreateModel
     */
    dailyRate?: number;
    /**
     * 
     * @type {number}
     * @memberof MachineCommissionCreateModel
     */
    monthlyRate?: number;
    /**
     * 
     * @type {number}
     * @memberof MachineCommissionCreateModel
     */
    yearlyRate?: number;
}

export function MachineCommissionCreateModelFromJSON(json: any): MachineCommissionCreateModel {
    return MachineCommissionCreateModelFromJSONTyped(json, false);
}

export function MachineCommissionCreateModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): MachineCommissionCreateModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'categoryId': !exists(json, 'categoryId') ? undefined : json['categoryId'],
        'subCategoryId': !exists(json, 'subCategoryId') ? undefined : json['subCategoryId'],
        'hourlyRate': !exists(json, 'hourlyRate') ? undefined : json['hourlyRate'],
        'dailyRate': !exists(json, 'dailyRate') ? undefined : json['dailyRate'],
        'monthlyRate': !exists(json, 'monthlyRate') ? undefined : json['monthlyRate'],
        'yearlyRate': !exists(json, 'yearlyRate') ? undefined : json['yearlyRate'],
    };
}

export function MachineCommissionCreateModelToJSON(value?: MachineCommissionCreateModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'categoryId': value.categoryId,
        'subCategoryId': value.subCategoryId,
        'hourlyRate': value.hourlyRate,
        'dailyRate': value.dailyRate,
        'monthlyRate': value.monthlyRate,
        'yearlyRate': value.yearlyRate,
    };
}

