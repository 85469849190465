import { Button, Col, Row } from "antd";
import {ReactComponent as GoogleIcon} from "../assets/google.svg"
import {ReactComponent as FacebookIcon} from "../assets/facebook.svg"
import {ReactComponent as MailOpenIcon} from "../assets/MailOpenIcon.svg"
import styles from "./social-auth-button.module.scss"

interface SocialAuthButtonProps {
    type: 'facebook' | 'google' | 'email';
    disabled?: boolean;
    onClick: (e: React.MouseEvent<HTMLElement, MouseEvent>) => void;
    text: string;
    page?: 'login';
    style?: React.CSSProperties;
}

const SocialAuthButton = (props: SocialAuthButtonProps) => {

    return (
        <Row className={styles.container}>
            <Col xs={24} className={styles.innerContainer}>
                <Button disabled={props.disabled === true} className={styles['social-login-button']  + ' ' + (props.page === 'login' ? styles.transparentBackground : '')} style={props.style} onClick={props.onClick}>
                    {
                        props.type === 'facebook' ?
                            <FacebookIcon width={20}/>
                        :
                        props.type === 'google' ?
                            <GoogleIcon width={20}/>
                        :
                        props.type === 'email' ?
                            <MailOpenIcon width={20}/>
                        :
                        undefined
                    }
                    <span>{props.text}</span>
                </Button>
            </Col>
        </Row>
    );
}

export default SocialAuthButton;