//eslint-disable-next-line no-extend-native
String.prototype.truncate = function (n: number, useWordBoundary: boolean): String {
    let d = String(this);
    if (d.length <= n) {
        return d;
    }
    const subString = d.slice(0, n - 1);
    return (useWordBoundary ? subString.slice(0, subString.lastIndexOf(" ")) : subString) + "...";
};

export {};
