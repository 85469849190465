/* tslint:disable */
/* eslint-disable */
/**
 * MachineGo P1 API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    BasicModel,
    BasicModelFromJSON,
    BasicModelFromJSONTyped,
    BasicModelToJSON,
} from './BasicModel';
import {
    SubCategoryWithCategoryModel,
    SubCategoryWithCategoryModelFromJSON,
    SubCategoryWithCategoryModelFromJSONTyped,
    SubCategoryWithCategoryModelToJSON,
} from './SubCategoryWithCategoryModel';

/**
 * 
 * @export
 * @interface CategoryModel
 */
export interface CategoryModel {
    /**
     * 
     * @type {number}
     * @memberof CategoryModel
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof CategoryModel
     */
    name?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CategoryModel
     */
    slug?: string | null;
    /**
     * 
     * @type {Array<BasicModel>}
     * @memberof CategoryModel
     */
    categoryTags?: Array<BasicModel> | null;
    /**
     * 
     * @type {Array<SubCategoryWithCategoryModel>}
     * @memberof CategoryModel
     */
    subCategories?: Array<SubCategoryWithCategoryModel> | null;
}

export function CategoryModelFromJSON(json: any): CategoryModel {
    return CategoryModelFromJSONTyped(json, false);
}

export function CategoryModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): CategoryModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'slug': !exists(json, 'slug') ? undefined : json['slug'],
        'categoryTags': !exists(json, 'categoryTags') ? undefined : (json['categoryTags'] === null ? null : (json['categoryTags'] as Array<any>).map(BasicModelFromJSON)),
        'subCategories': !exists(json, 'subCategories') ? undefined : (json['subCategories'] === null ? null : (json['subCategories'] as Array<any>).map(SubCategoryWithCategoryModelFromJSON)),
    };
}

export function CategoryModelToJSON(value?: CategoryModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
        'slug': value.slug,
        'categoryTags': value.categoryTags === undefined ? undefined : (value.categoryTags === null ? null : (value.categoryTags as Array<any>).map(BasicModelToJSON)),
        'subCategories': value.subCategories === undefined ? undefined : (value.subCategories === null ? null : (value.subCategories as Array<any>).map(SubCategoryWithCategoryModelToJSON)),
    };
}

