import { Input, InputProps } from "antd";
import { ChangeEvent } from "react";
import style from "./search-box.module.scss";

type SearchBoxProps = {
    placeholder?: string;
    prefix?: any;
    onPriceChange?: (value: number | undefined) => void;
} & InputProps;

const SearchBox = (props: SearchBoxProps) => {
    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
        const { value } = event.target;

        const numericValue = value.replace(/\D/g, "");

        if (props.onPriceChange) {
            props.onPriceChange(numericValue === "" ? undefined : parseInt(numericValue));
        }
    };

    const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
        const keyValue = event.key;

        if (!/^[0-9]+$/.test(keyValue)) {
            event.preventDefault();
        }
    };

    return (
        <Input
            className={style.customInput}
            size="large"
            onChange={handleChange}
            onKeyPress={handleKeyPress}
            placeholder={props.placeholder}
            prefix={props.prefix}
            value={props.value}
            disabled={props.disabled}
        />
    );
};

export default SearchBox;
