import { t } from "../../translate";
import { useAtom } from "jotai";
import { useNavigate } from "react-router-dom";
import RequestLayout from "../../components/layouts/request-layout";
import Accordion from "../../components/atomics/accordion";
import { Col, Form, Input, Radio, Row } from "antd";
import RadioButton from "../../components/atomics/radio-button";
import { EventHandler, useEffect, useState } from "react";
import { ModifiedRequestDetailModel, requestFormAtom } from "../../store/global-atoms";
import { findCategory, useSecureService } from "../../store/service-atoms";
import { CategoryModel, MachineDetailModel, MachineDocumentViewModel, SubCategoryModel } from "../../service/models";
import { useQuery } from "../../helpers/use-query";
import { ExtendedMachineDetailModel } from "../../service/models/ExtendedMachineDetailModel";
import moment from "moment";
import placeholder from "../../components/assets/placeholder.svg";
import { activeApiBaseUrl } from "../../store/app-constants";
import BoxContainer from "../../components/containers/box-container";
import accordionStyles from "../../components/styles/accordion.module.scss";

const SelectMachine = (props: { secure?: boolean }) => {
    const navigate = useNavigate();
    const query = useQuery();
    const sid = query.get("sid");
    const reqId = query.get("reqid");
    const macid = query.get("macid");
    const [selectedRadio, changeSelectedRadio] = useState<number | undefined>(undefined);
    const [continueVisible, setContinueVisible] = useState(false);
    const [allMachineCategory, setAllMachineCategories] = useState<CategoryModel[] | undefined>(undefined);
    const [accordionItems, setAccordionItems] = useState<any[] | undefined>(undefined);
    const [activePanelKey, setActivePanelKey] = useState<number | undefined>(undefined);
    const { doGetMachineSubcategory, doGetRequestDetail, doGetMachine } = useSecureService();
    const [requestForm, editRequest] = useAtom(requestFormAtom);
    const [otherSubCategoryText, setOtherSubCategoryText] = useState<string | undefined>(undefined);
    const [form] = Form.useForm();
    const [requestId, setRequestId] = useState<string>();
    const [machineAllDetail, setMachineAllDetail] = useState<MachineDetailModel>();
    const [machineImages, setMachineImages] = useState<MachineDocumentViewModel[] | undefined>([]);
    const [mainImageId, setMainImageId] = useState<number>();
    const [mainImageUrl, setMainImageUrl] = useState<string>(placeholder);

    useEffect(() => {
        const getRequestDetail = async (reqIdN: number) => {
            if (!Number.isNaN(reqIdN))
                await doGetRequestDetail(reqIdN).then((data) => {
                    let editedData = data as ModifiedRequestDetailModel & ExtendedMachineDetailModel;
                    editedData.fromDateRaw = moment(data?.requestDateFrom);
                    editedData.toDateRaw = moment(data?.requestDateTo);
                    editedData.subcategoryName = editedData.subCategory?.name || "";
                    editedData.categoryName = editedData.subCategory?.category?.name || "";
                    const attachmentsList = editedData.attachments?.map((atc) => atc.name);
                    const documentList = editedData.documents?.map((doc) => doc);
                    editedData.machineId = macid ? parseInt(macid) : 0;
                    form.setFieldsValue({
                        ...editedData,
                        ...editedData.machineFeatures,
                        ...editedData.additionalServices,
                        attachmentsList,
                        district: editedData.district?.name,
                        city: editedData.district?.city?.name,
                        documents: documentList,
                    });
                    editRequest((oldVal) => editedData);
                    let subcategoryId = data?.subCategory?.id;
                    let category: CategoryModel | undefined = undefined;
                    if (subcategoryId && (!requestForm || !requestForm.subCategory)) {
                        category = findCategory(subcategoryId);
                        setActivePanelKey(category?.id);
                        changeSelectedRadio(subcategoryId);
                        setContinueVisible(true);
                    }
                });
        };
        if (requestId) {
            const reqIdN = parseInt(requestId);
            getRequestDetail(reqIdN);
        }
        //eslint-disable-next-line
    }, [requestId]);

    useEffect(() => {
        const getMachineDetails = async () => {
            if (macid) {
                const macidN = parseInt(macid);
                if (!Number.isNaN(macid)) {
                    const result = await doGetMachine(macidN, true);
                    if (result) {
                        setMachineAllDetail(result);
                    }
                }
            }
        };
        getMachineDetails();
        //eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (machineAllDetail?.documents && machineAllDetail.documents.length > 0) {
            const documents = machineAllDetail?.documents?.filter((x: any) => x.documentCategory === "Photo");
            setMachineImages(documents);
        }
    }, [machineAllDetail]);

    useEffect(() => {
        if (machineImages && machineImages?.length > 0) {
            const mainImageId = machineImages[0].document?.id;
            setMainImageId(mainImageId);
        }
        // eslint-disable-next-line
    }, [machineImages]);

    useEffect(() => {
        if (mainImageId === undefined) {
            setMainImageUrl(placeholder);
        } else {
            setMainImageUrl(activeApiBaseUrl + "/document/machine-document/" + mainImageId);
        }
    }, [mainImageId]);

    useEffect(() => {
        if (reqId) {
            setRequestId(reqId);
        }
        //eslint-disable-next-line
    }, []);

    const CreateSubcategoryRadio = (value: string, content: string, evt: EventHandler<any>) => {
        return content !== "Diğer " ? (
            <Col xs={24} lg={12}>
                <RadioButton value={value} content={content} radioClicked={evt} />
            </Col>
        ) : (
            <Col xs={24} lg={12}>
                <Form form={form}>
                    <Form.Item name="subCategoryOther">
                        <Input
                            placeholder={t("request.create.other.subCategory.placeholder")}
                            style={{ height: "3.5rem" }}
                            onChange={(e: any) => {
                                changeSelectedRadio(parseInt(value));
                                if (e.target.value !== "") {
                                    setOtherSubCategoryText(e.target.value);
                                    setContinueVisible(true);
                                } else {
                                    setContinueVisible(false);
                                }
                            }}
                        />
                    </Form.Item>
                </Form>
            </Col>
        );
    };
    const continueClick = () => {
        if (!selectedRadio) return;
        let foundSub: SubCategoryModel | undefined = undefined;
        allMachineCategory?.find((cat) => {
            foundSub = cat.subCategories?.find((sub) => sub.id?.toString() === selectedRadio.toString());
            if (foundSub) {
                foundSub.category = { id: cat.id, name: cat.name };
                return true;
            } else return false;
        });

        editRequest((prev: ModifiedRequestDetailModel | undefined) => {
            if (prev)
                return {
                    ...prev,
                    subCategory: foundSub,
                    subCategoryOther: otherSubCategoryText,
                };
            else
                return {
                    subCategory: foundSub,
                    subCategoryOther: otherSubCategoryText,
                };
        });
        if (props.secure) navigate("/requests-and-offers/add-location-and-date");
        else navigate("/request/location-and-date");
    };

    useEffect(() => {
        const setSubCategories = async () => {
            const result = await doGetMachineSubcategory();
            if (result) setAllMachineCategories(result);
        };
        setSubCategories();
        editRequest((oldVal) => {
            return {
                baseCategoryId: oldVal?.subCategory?.category?.id,
                subCategory: oldVal?.subCategory,
            };
        });
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        let category: CategoryModel | undefined = undefined;
        if (sid && (!requestForm || !requestForm.subCategory)) {
            const sidN = Number.parseInt(sid);
            category = findCategory(sidN);
            setActivePanelKey(category?.id);
            changeSelectedRadio(sidN);
            setContinueVisible(true);
        }

        const AccordionItems = allMachineCategory?.map((category) => {
            const radios = category.subCategories?.map((sub) => {
                if (sub && sub.id && sub.name) return CreateSubcategoryRadio(sub.id?.toString(), sub.name, radioClicked);
                else return undefined;
            });
            const returnObj = {
                title: category.name,
                key: category.id,
                children: (
                    <Row gutter={[16, 16]} key={rowKey}>
                        {radios}
                    </Row>
                ),
                description: category.subCategories
                    ?.map((sub) => " " + sub.name)
                    .toString()
                    .trim(),
                imageUrl: "img" + category.id + ".png",
            };
            rowKey++;
            return returnObj;
        });
        if (requestForm?.subCategory?.id) {
            changeSelectedRadio(requestForm.subCategory.id);
            setContinueVisible(true);
            setActivePanelKey(requestForm.subCategory.category?.id);
        }
        setAccordionItems(AccordionItems);
        // eslint-disable-next-line
    }, [allMachineCategory]);

    const radioClicked = (val: any) => {
        form.setFieldsValue({ subCategoryOther: undefined });
        changeSelectedRadio(val);
        setContinueVisible(true);
    };

    let rowKey = 0;
    return (
        <RequestLayout currentStep={0} continueVisible={continueVisible} onContinueClick={continueClick}>
            <h3>{t("request.select-machine.h3")}</h3>
            {macid ? (
                <BoxContainer>
                    <Row className={accordionStyles.expandedAccordionItemHeader} align="middle">
                        {machineImages && (
                            <Col md={12} xs={10} className={accordionStyles.imageContainer}>
                                <img src={mainImageUrl} alt={""} />
                            </Col>
                        )}
                        <Col md={8} xs={12}>
                            <Row gutter={[16, 16]} style={{ flexDirection: "column", marginTop: "5%" }}>
                                <Col>
                                    <h3>
                                        {t("request.machine.info.machineId") +
                                            (machineAllDetail?.id ? machineAllDetail?.id.toString() : "-")}
                                    </h3>
                                </Col>
                                <Col className={accordionStyles.expandedAccordionItemDesc}>
                                    <Row>
                                        <Col>
                                            <span className={accordionStyles.spanGrey}>{t("request.machine.info.category")} </span>
                                            <span className={accordionStyles.spanBlack}>
                                                {machineAllDetail?.subCategory?.category?.name}
                                            </span>
                                        </Col>
                                        <Col>
                                            <span className={accordionStyles.spanGrey}>{t("request.machine.info.subCategory")} </span>
                                            <span className={accordionStyles.spanBlack}>{machineAllDetail?.subCategory?.name}</span>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </BoxContainer>
            ) : (
                <Radio.Group value={selectedRadio?.toString()}>
                    <Accordion items={accordionItems} activeKey={activePanelKey} />
                </Radio.Group>
            )}
        </RequestLayout>
    );
};

export default SelectMachine;
