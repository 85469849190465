/* tslint:disable */
/* eslint-disable */
/**
 * MachineGo P1 API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    DistrictModel,
    DistrictModelFromJSON,
    DistrictModelFromJSONTyped,
    DistrictModelToJSON,
} from './DistrictModel';
import {
    OfferStatus,
    OfferStatusFromJSON,
    OfferStatusFromJSONTyped,
    OfferStatusToJSON,
} from './OfferStatus';

/**
 * 
 * @export
 * @interface OfferFormMachineDetailViewModel
 */
export interface OfferFormMachineDetailViewModel {
    /**
     * 
     * @type {number}
     * @memberof OfferFormMachineDetailViewModel
     */
    offerFormId?: number;
    /**
     * 
     * @type {number}
     * @memberof OfferFormMachineDetailViewModel
     */
    requestFormId?: number;
    /**
     * 
     * @type {DistrictModel}
     * @memberof OfferFormMachineDetailViewModel
     */
    district?: DistrictModel;
    /**
     * 
     * @type {Date}
     * @memberof OfferFormMachineDetailViewModel
     */
    reqeustDateFrom?: Date;
    /**
     * 
     * @type {Date}
     * @memberof OfferFormMachineDetailViewModel
     */
    reqeustDateTo?: Date;
    /**
     * 
     * @type {OfferStatus}
     * @memberof OfferFormMachineDetailViewModel
     */
    status?: OfferStatus;
    /**
     * 
     * @type {number}
     * @memberof OfferFormMachineDetailViewModel
     */
    bid?: number;
}

export function OfferFormMachineDetailViewModelFromJSON(json: any): OfferFormMachineDetailViewModel {
    return OfferFormMachineDetailViewModelFromJSONTyped(json, false);
}

export function OfferFormMachineDetailViewModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): OfferFormMachineDetailViewModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'offerFormId': !exists(json, 'offerFormId') ? undefined : json['offerFormId'],
        'requestFormId': !exists(json, 'requestFormId') ? undefined : json['requestFormId'],
        'district': !exists(json, 'district') ? undefined : DistrictModelFromJSON(json['district']),
        'reqeustDateFrom': !exists(json, 'reqeustDateFrom') ? undefined : (new Date(json['reqeustDateFrom'])),
        'reqeustDateTo': !exists(json, 'reqeustDateTo') ? undefined : (new Date(json['reqeustDateTo'])),
        'status': !exists(json, 'status') ? undefined : OfferStatusFromJSON(json['status']),
        'bid': !exists(json, 'bid') ? undefined : json['bid'],
    };
}

export function OfferFormMachineDetailViewModelToJSON(value?: OfferFormMachineDetailViewModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'offerFormId': value.offerFormId,
        'requestFormId': value.requestFormId,
        'district': DistrictModelToJSON(value.district),
        'reqeustDateFrom': value.reqeustDateFrom === undefined ? undefined : (value.reqeustDateFrom.toISOString()),
        'reqeustDateTo': value.reqeustDateTo === undefined ? undefined : (value.reqeustDateTo.toISOString()),
        'status': OfferStatusToJSON(value.status),
        'bid': value.bid,
    };
}

