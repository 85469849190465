import { Col, Row } from "antd";
import React from "react";
import style from "./info-card.module.scss";
type InfoCardProps = {
    icon: any;
    header: string;
    children?: any;
    isProductPart?: boolean;
};

const InfoCard = (props: InfoCardProps) => {
    return (
        <Row gutter={[16, 8]} style={{ margin: "0" }} className={style.cardContainer}>
            <Col className={props.isProductPart ? style.productIcon : style.cardIcon}>{props.icon}</Col>
            <Col className={props.isProductPart ? style.productHeader : style.cardHeader}>{props.header}</Col>
            {props.children}
        </Row>
    );
};

export default InfoCard;
