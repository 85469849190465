import BoxContainer from "./box-container";
import { OfferFormViewModel } from "../../service/models";
import PrimaryButton from "../atomics/primary-button";
import RequestAndOfferStatus from "../atomics/request-and-offer-status";
import { t } from "../../translate";
import { useNavigate } from "react-router-dom";
import { Row, Col } from "antd";
import { moment } from "../../helpers/moment";
import BasicModelViewer from "../atomics/basic-model-viewer";
import SecondaryButton from "../atomics/secondary-button";
type DashboardIncomingRequestsBoxProps = {
    offerForm: OfferFormViewModel;
};

const DashboardIncomingRequestsBox = (props: DashboardIncomingRequestsBoxProps) => {
    const navigate = useNavigate();
    const buttons: JSX.Element[] = [];
    switch (props.offerForm.status) {
        default:
            buttons[0] = (
                <PrimaryButton
                    onClick={() =>
                        navigate(
                            "/offers/add?reqid=" +
                                props.offerForm.requestForm?.id +
                                "&offerid=" +
                                props.offerForm.id +
                                "&max=" +
                                props.offerForm.requestForm?.availableMachineCount
                        )
                    }
                >
                    {t("common.makeOffer")}
                </PrimaryButton>
            );
            buttons[1] = (
                <SecondaryButton
                    onClick={() => navigate("/requests/view?reqid=" + props.offerForm.requestForm?.id + "&offerid=" + props.offerForm.id)}
                >
                    {t("common.details")}
                </SecondaryButton>
            );
    }
    const catId = props.offerForm?.requestForm?.subCategory?.category?.id;
    return (
        <BoxContainer>
            <div
                style={{
                    display: "flex",
                    height: "100%",
                    flexDirection: "column",
                    justifyContent: "space-between",
                }}
            >
                <div
                    style={{
                        position: "absolute",
                        right: ".5rem",
                        top: ".5rem",
                    }}
                >
                    <RequestAndOfferStatus
                        role="lessor"
                        offerStatus={props.offerForm?.status}
                        showOfferStatus={true}
                        showRequestStatus={false}
                    />
                </div>
                <div>
                    <Row gutter={[8, 12]}>
                        {catId && (
                            <Col xs={4} md={6}>
                                <img style={{ width: "48px" }} src={"/img" + catId + ".png"} alt={"/img" + catId + ".png"} />
                            </Col>
                        )}
                        <BasicModelViewer
                            xs={20}
                            md={18}
                            objKey={t("request.id") + " : " + props.offerForm?.requestForm?.id}
                            value={props.offerForm?.requestForm?.subCategory?.name}
                        />
                        <BasicModelViewer
                            xs={24}
                            md={24}
                            objKey={t("request.requesteddates")}
                            value={
                                moment(props.offerForm?.requestForm?.requestDateFrom) +
                                " - " +
                                moment(props.offerForm?.requestForm?.requestDateTo)
                            }
                        />
                        <BasicModelViewer
                            xs={24}
                            md={24}
                            objKey={t("request.location")}
                            value={
                                props.offerForm?.requestForm?.district?.name + " - " + props.offerForm?.requestForm?.district?.city?.name
                            }
                        />
                        <div
                            style={{
                                display: "flex",
                                width: "100%",
                                gap: "1rem",
                            }}
                        >
                            {buttons}
                        </div>
                    </Row>
                </div>
            </div>
        </BoxContainer>
    );
};

export default DashboardIncomingRequestsBox;
