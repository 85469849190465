/* tslint:disable */
/* eslint-disable */
/**
 * MachineGo P1 API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    BasicModel,
    BasicModelFromJSON,
    BasicModelFromJSONTyped,
    BasicModelToJSON,
} from './BasicModel';
import {
    DistrictModel,
    DistrictModelFromJSON,
    DistrictModelFromJSONTyped,
    DistrictModelToJSON,
} from './DistrictModel';

/**
 * 
 * @export
 * @interface LessorCompanyInfoModel
 */
export interface LessorCompanyInfoModel {
    /**
     * 
     * @type {string}
     * @memberof LessorCompanyInfoModel
     */
    companyName: string;
    /**
     * 
     * @type {string}
     * @memberof LessorCompanyInfoModel
     */
    website?: string | null;
    /**
     * 
     * @type {string}
     * @memberof LessorCompanyInfoModel
     */
    address?: string | null;
    /**
     * 
     * @type {number}
     * @memberof LessorCompanyInfoModel
     */
    operatorCount?: number | null;
    /**
     * 
     * @type {number}
     * @memberof LessorCompanyInfoModel
     */
    employeeCount?: number | null;
    /**
     * 
     * @type {number}
     * @memberof LessorCompanyInfoModel
     */
    totalMachines?: number | null;
    /**
     * 
     * @type {DistrictModel}
     * @memberof LessorCompanyInfoModel
     */
    location?: DistrictModel;
    /**
     * 
     * @type {Array<BasicModel>}
     * @memberof LessorCompanyInfoModel
     */
    serviceRegions?: Array<BasicModel> | null;
    /**
     * 
     * @type {Array<BasicModel>}
     * @memberof LessorCompanyInfoModel
     */
    categories?: Array<BasicModel> | null;
    /**
     * 
     * @type {string}
     * @memberof LessorCompanyInfoModel
     */
    mainServiceActivityFields?: string | null;
}

export function LessorCompanyInfoModelFromJSON(json: any): LessorCompanyInfoModel {
    return LessorCompanyInfoModelFromJSONTyped(json, false);
}

export function LessorCompanyInfoModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): LessorCompanyInfoModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'companyName': json['companyName'],
        'website': !exists(json, 'website') ? undefined : json['website'],
        'address': !exists(json, 'address') ? undefined : json['address'],
        'operatorCount': !exists(json, 'operatorCount') ? undefined : json['operatorCount'],
        'employeeCount': !exists(json, 'employeeCount') ? undefined : json['employeeCount'],
        'totalMachines': !exists(json, 'totalMachines') ? undefined : json['totalMachines'],
        'location': !exists(json, 'location') ? undefined : DistrictModelFromJSON(json['location']),
        'serviceRegions': !exists(json, 'serviceRegions') ? undefined : (json['serviceRegions'] === null ? null : (json['serviceRegions'] as Array<any>).map(BasicModelFromJSON)),
        'categories': !exists(json, 'categories') ? undefined : (json['categories'] === null ? null : (json['categories'] as Array<any>).map(BasicModelFromJSON)),
        'mainServiceActivityFields': !exists(json, 'mainServiceActivityFields') ? undefined : json['mainServiceActivityFields'],
    };
}

export function LessorCompanyInfoModelToJSON(value?: LessorCompanyInfoModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'companyName': value.companyName,
        'website': value.website,
        'address': value.address,
        'operatorCount': value.operatorCount,
        'employeeCount': value.employeeCount,
        'totalMachines': value.totalMachines,
        'location': DistrictModelToJSON(value.location),
        'serviceRegions': value.serviceRegions === undefined ? undefined : (value.serviceRegions === null ? null : (value.serviceRegions as Array<any>).map(BasicModelToJSON)),
        'categories': value.categories === undefined ? undefined : (value.categories === null ? null : (value.categories as Array<any>).map(BasicModelToJSON)),
        'mainServiceActivityFields': value.mainServiceActivityFields,
    };
}

