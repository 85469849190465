import { Col, ColProps, Row } from "antd";
import { DocumentCategory } from "../../service";
import { ExtendedMachineDetailModel } from "../../service/models/ExtendedMachineDetailModel";
import { activeApiBaseUrl } from "../../store/app-constants";
import { t } from "../../translate";
import BoxContainer from "../containers/box-container";
import styles from "../styles/machine-list-card.module.scss";

import { ReactComponent as Placeholder } from "../assets/placeholder.svg";
import BasicModelViewer from "../atomics/basic-model-viewer";
import { useNavigate } from "react-router-dom";
import SecondaryButton from "../atomics/secondary-button";

type MachineListCardProps = {
    machine: ExtendedMachineDetailModel;
    onViewClick?: (param: number | undefined) => void;
    onDeleteClick?: (param: number | undefined) => void;
} & ColProps;

const DashboardLessorSaleMachineListCard = (props: MachineListCardProps) => {
    const navigate = useNavigate();
    const docs = props.machine?.documents?.filter((doc) => doc.documentCategory === DocumentCategory.Photo);
    let imageId: number | undefined = undefined;
    if (docs && docs.length > 0) {
        imageId = docs[0].document?.id;
    }
    const imageUrl = activeApiBaseUrl + "/document/" + imageId;
    return (
        <Col {...props}>
            <BoxContainer
                actions={[
                    <SecondaryButton
                        style={{
                            width: "100%",
                        }}
                        onClick={() => navigate("/sale-machines/detail?macid=" + props.machine.id)}
                    >
                        {t("common.details")}
                    </SecondaryButton>,
                ]}
                style={{
                    backgroundColor: "white",
                    borderRadius: "1rem",
                    padding: "1rem 1rem 0 1rem ",
                    border: "1px solid #ffebc3",
                }}
            >
                <div
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "space-between",
                        height: "14rem",
                    }}
                >
                    <Row gutter={[4, 4]}>
                        <Col md={10} xs={10}>
                            <div className={styles.imageContainer}>
                                {imageId ? (
                                    <img style={{ height: "8rem" }} className={styles.image} src={imageUrl} alt={"document " + imageId} />
                                ) : (
                                    <Placeholder height={"8rem"} />
                                )}
                            </div>
                        </Col>
                        <Col md={14} xs={14}>
                            <BasicModelViewer
                                xs={24}
                                md={24}
                                value={
                                    props.machine.isApprovedForSale
                                        ? props.machine.isMachineRemovedFromSale
                                            ? t("machines.status.3") //yayından kaldırıldı
                                            : t("machines.status.1") //onaylandı
                                        : props.machine.isMachineRemovedFromSale ? t("machines.status.3") : t("machines.status.2") //onay bekliyor
                                }
                                valueColor={
                                    props.machine.isMachineRemovedFromSale
                                        ? "#FC5A5A"
                                        : props.machine.isApprovedForSale
                                        ? "#35B934"
                                        : "#FEB111"
                                }
                            />
                            <BasicModelViewer
                                xs={24}
                                md={24}
                                objKey={t(" ") + props.machine.category?.name?.truncate(40, true)}
                                value={props.machine.subCategory?.name?.truncate(40, true)}
                            />
                        </Col>

                        <BasicModelViewer xs={8} md={8} objKey={t("machines.id")} value={props.machine.id} />
                        <BasicModelViewer xs={8} md={8} objKey={t("machines.model")} value={props.machine.modelName} />
                        <BasicModelViewer xs={8} md={8} objKey={t("machines.brand")} value={props.machine.brand?.name} />
                    </Row>
                </div>
            </BoxContainer>
        </Col>
    );
};

export default DashboardLessorSaleMachineListCard;
