import { Space } from "antd";
import BorderedBoxContainer from "../containers/bordered-box-container";
import SelectedItemText from "./selected-item-text";
import { CheckCircleOutlined } from "@ant-design/icons";
import { ColProps } from "antd/es/grid/col";

type CheckboxReviewerProps = {
    checkboxChecked?: boolean;
    checkboxLabel?: string | null;
    inputLabel?: string;
    inputVal?: string | number | null;
    inputVisible?: boolean;
    clickable?: boolean;
    contextId?: number;
    onCheckboxChange?: (selected: boolean, contextId?: number) => void;
} & ColProps;

const CheckboxReviewer = (props: CheckboxReviewerProps) => {
    const passiveColor = "#CCCCCC";
    const tickClicked = () => {
        if (props.onCheckboxChange) {
            if (props.checkboxChecked === undefined) props.onCheckboxChange(true, props.contextId);
            else props.onCheckboxChange(!props.checkboxChecked, props.contextId);
        }
    };
    return (
        <BorderedBoxContainer xs={props.xs || 24} md={props.md || 24} {...props}>
            <Space
                direction="vertical"
                size={"large"}
                style={{
                    width: "100%",
                    height: "100%",
                    justifyContent: "space-between",
                }}
            >
                <div
                    style={{
                        display: "flex",
                        height: "100%",
                        justifyContent: "space-between",
                        alignItems: "stretch",
                    }}
                >
                    <span
                        style={{
                            color: props.checkboxChecked ? "#282828" : "#cccccc",
                        }}
                    >
                        {props.checkboxLabel}
                    </span>
                    <CheckCircleOutlined
                        onClick={props.clickable ? tickClicked : undefined}
                        style={{
                            fontSize: "1.5rem",
                            cursor: props.clickable ? "pointer" : "default",
                            color: props.checkboxChecked ? "#FEB111" : passiveColor,
                        }}
                    />
                </div>
                {props.inputVisible && (
                    <div>
                        <span>{props.inputLabel}</span>
                        <SelectedItemText style={{ minHeight: "40px" }}>{props.inputVal}</SelectedItemText>
                    </div>
                )}
            </Space>
        </BorderedBoxContainer>
    );
};
export default CheckboxReviewer;
