/* tslint:disable */
/* eslint-disable */
/**
 * MachineGo P1 API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    BasicModel,
    BasicModelFromJSON,
    BasicModelFromJSONTyped,
    BasicModelToJSON,
} from './BasicModel';

/**
 * 
 * @export
 * @interface CityModel
 */
export interface CityModel {
    /**
     * 
     * @type {number}
     * @memberof CityModel
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof CityModel
     */
    name?: string | null;
    /**
     * 
     * @type {Array<BasicModel>}
     * @memberof CityModel
     */
    districts?: Array<BasicModel> | null;
}

export function CityModelFromJSON(json: any): CityModel {
    return CityModelFromJSONTyped(json, false);
}

export function CityModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): CityModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'districts': !exists(json, 'districts') ? undefined : (json['districts'] === null ? null : (json['districts'] as Array<any>).map(BasicModelFromJSON)),
    };
}

export function CityModelToJSON(value?: CityModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
        'districts': value.districts === undefined ? undefined : (value.districts === null ? null : (value.districts as Array<any>).map(BasicModelToJSON)),
    };
}

