/* tslint:disable */
/* eslint-disable */
/**
 * MachineGo P1 API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    AuthType,
    AuthTypeFromJSON,
    AuthTypeFromJSONTyped,
    AuthTypeToJSON,
} from './AuthType';

/**
 * 
 * @export
 * @interface LessorCompanyCreateModel
 */
export interface LessorCompanyCreateModel {
    /**
     * 
     * @type {string}
     * @memberof LessorCompanyCreateModel
     */
    name?: string | null;
    /**
     * 
     * @type {string}
     * @memberof LessorCompanyCreateModel
     */
    lastName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof LessorCompanyCreateModel
     */
    email?: string | null;
    /**
     * 
     * @type {string}
     * @memberof LessorCompanyCreateModel
     */
    phone: string;
    /**
     * 
     * @type {string}
     * @memberof LessorCompanyCreateModel
     */
    companyName: string;
    /**
     * 
     * @type {string}
     * @memberof LessorCompanyCreateModel
     */
    password?: string | null;
    /**
     * 
     * @type {number}
     * @memberof LessorCompanyCreateModel
     */
    totalMachines?: number | null;
    /**
     * 
     * @type {string}
     * @memberof LessorCompanyCreateModel
     */
    agreementVersion: string;
    /**
     * 
     * @type {string}
     * @memberof LessorCompanyCreateModel
     */
    kvkkVersion: string;
    /**
     * 
     * @type {string}
     * @memberof LessorCompanyCreateModel
     */
    kvkkInfoVersion: string;
    /**
     * 
     * @type {string}
     * @memberof LessorCompanyCreateModel
     */
    ipazVersion?: string | null;
    /**
     * 
     * @type {number}
     * @memberof LessorCompanyCreateModel
     */
    locationId: number;
    /**
     * 
     * @type {Array<number>}
     * @memberof LessorCompanyCreateModel
     */
    serviceRegions?: Array<number> | null;
    /**
     * 
     * @type {Array<number>}
     * @memberof LessorCompanyCreateModel
     */
    categories?: Array<number> | null;
    /**
     * 
     * @type {AuthType}
     * @memberof LessorCompanyCreateModel
     */
    authType?: AuthType;
    /**
     * 
     * @type {string}
     * @memberof LessorCompanyCreateModel
     */
    googleIdToken?: string | null;
}

export function LessorCompanyCreateModelFromJSON(json: any): LessorCompanyCreateModel {
    return LessorCompanyCreateModelFromJSONTyped(json, false);
}

export function LessorCompanyCreateModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): LessorCompanyCreateModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': !exists(json, 'name') ? undefined : json['name'],
        'lastName': !exists(json, 'lastName') ? undefined : json['lastName'],
        'email': !exists(json, 'email') ? undefined : json['email'],
        'phone': json['phone'],
        'companyName': json['companyName'],
        'password': !exists(json, 'password') ? undefined : json['password'],
        'totalMachines': !exists(json, 'totalMachines') ? undefined : json['totalMachines'],
        'agreementVersion': json['agreementVersion'],
        'kvkkVersion': json['kvkkVersion'],
        'kvkkInfoVersion': json['kvkkInfoVersion'],
        'ipazVersion': !exists(json, 'ipazVersion') ? undefined : json['ipazVersion'],
        'locationId': json['locationId'],
        'serviceRegions': !exists(json, 'serviceRegions') ? undefined : json['serviceRegions'],
        'categories': !exists(json, 'categories') ? undefined : json['categories'],
        'authType': !exists(json, 'authType') ? undefined : AuthTypeFromJSON(json['authType']),
        'googleIdToken': !exists(json, 'googleIdToken') ? undefined : json['googleIdToken'],
    };
}

export function LessorCompanyCreateModelToJSON(value?: LessorCompanyCreateModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'lastName': value.lastName,
        'email': value.email,
        'phone': value.phone,
        'companyName': value.companyName,
        'password': value.password,
        'totalMachines': value.totalMachines,
        'agreementVersion': value.agreementVersion,
        'kvkkVersion': value.kvkkVersion,
        'kvkkInfoVersion': value.kvkkInfoVersion,
        'ipazVersion': value.ipazVersion,
        'locationId': value.locationId,
        'serviceRegions': value.serviceRegions,
        'categories': value.categories,
        'authType': AuthTypeToJSON(value.authType),
        'googleIdToken': value.googleIdToken,
    };
}

