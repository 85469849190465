/* tslint:disable */
/* eslint-disable */
/**
 * MachineGo P1 API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface RenterCompanyDetailViewModel
 */
export interface RenterCompanyDetailViewModel {
    /**
     * 
     * @type {number}
     * @memberof RenterCompanyDetailViewModel
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof RenterCompanyDetailViewModel
     */
    companyName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof RenterCompanyDetailViewModel
     */
    name?: string | null;
    /**
     * 
     * @type {string}
     * @memberof RenterCompanyDetailViewModel
     */
    lastname?: string | null;
    /**
     * 
     * @type {string}
     * @memberof RenterCompanyDetailViewModel
     */
    phone?: string | null;
    /**
     * 
     * @type {string}
     * @memberof RenterCompanyDetailViewModel
     */
    email?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof RenterCompanyDetailViewModel
     */
    isDeleted?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof RenterCompanyDetailViewModel
     */
    hasAnyRequest?: boolean;
    /**
     * 
     * @type {Date}
     * @memberof RenterCompanyDetailViewModel
     */
    creationDate?: Date;
}

export function RenterCompanyDetailViewModelFromJSON(json: any): RenterCompanyDetailViewModel {
    return RenterCompanyDetailViewModelFromJSONTyped(json, false);
}

export function RenterCompanyDetailViewModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): RenterCompanyDetailViewModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'companyName': !exists(json, 'companyName') ? undefined : json['companyName'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'lastname': !exists(json, 'lastname') ? undefined : json['lastname'],
        'phone': !exists(json, 'phone') ? undefined : json['phone'],
        'email': !exists(json, 'email') ? undefined : json['email'],
        'isDeleted': !exists(json, 'isDeleted') ? undefined : json['isDeleted'],
        'hasAnyRequest': !exists(json, 'hasAnyRequest') ? undefined : json['hasAnyRequest'],
        'creationDate': !exists(json, 'creationDate') ? undefined : (new Date(json['creationDate'])),
    };
}

export function RenterCompanyDetailViewModelToJSON(value?: RenterCompanyDetailViewModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'companyName': value.companyName,
        'name': value.name,
        'lastname': value.lastname,
        'phone': value.phone,
        'email': value.email,
        'isDeleted': value.isDeleted,
        'hasAnyRequest': value.hasAnyRequest,
        'creationDate': value.creationDate === undefined ? undefined : (value.creationDate.toISOString()),
    };
}

