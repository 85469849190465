/* tslint:disable */
/* eslint-disable */
/**
 * MachineGo P1 API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const SocialAuthPageType = {
    Login: 'LOGIN',
    Register: 'REGISTER',
    Anonymregister: 'ANONYMREGISTER'
} as const;
export type SocialAuthPageType = typeof SocialAuthPageType[keyof typeof SocialAuthPageType];


export function SocialAuthPageTypeFromJSON(json: any): SocialAuthPageType {
    return SocialAuthPageTypeFromJSONTyped(json, false);
}

export function SocialAuthPageTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): SocialAuthPageType {
    return json as SocialAuthPageType;
}

export function SocialAuthPageTypeToJSON(value?: SocialAuthPageType | null): any {
    return value as any;
}

