import { Form } from "antd";
import PrimaryButton from "../../components/atomics/primary-button";
import UsernameInput from "../../components/atomics/username-input";
import AuthLayout from "../../components/layouts/auth-layout";
import { t } from "../../translate";
import { useNavigate } from "react-router-dom";
import { useQuery } from "../../helpers/use-query";
import { useSecureService } from "../../store/service-atoms";
import { useAtom } from "jotai";
import { RoleType, loadingAtom } from "../../store/global-atoms";

const ForgotPassword = () => {
    const query = useQuery();
    const role = query.get("role") as RoleType || "customer";
    const navigate = useNavigate();
    const { doForgotPassword } = useSecureService();
    const [loading] = useAtom(loadingAtom);

    const formFinishHandler = async (params: { phone: string }) => {
        var editedPhone = params.phone.replace("+90", "");
        editedPhone = editedPhone.replace(/\s+/g, "");
        const result = await doForgotPassword(role, editedPhone);
        if (result) navigate("/sms-verification?role=" + role + "&id=" + result + "&phone=" + editedPhone + "&op=forgot-password");
    };

    return (
        <AuthLayout>
            <Form name="normal_login" className="login-form" layout="vertical" onFinish={formFinishHandler}>
                <div>
                    <UsernameInput prefix />
                </div>
                <div>
                    <PrimaryButton loading={loading} htmlType="submit">
                        {t("common.continue")}
                    </PrimaryButton>
                </div>
            </Form>
        </AuthLayout>
    );
};
export default ForgotPassword;
