import SecureLayout from "../../../components/layouts/secure-layout";
import { t } from "../../../translate";
import LocationAndDate from "../../request/location-and-date";

const AddLocationAndDate = () => {
    return (
        <SecureLayout
            onlyHeader
            activePage={"requests-and-offers"}
            role={"customer"}
            title={t("requests-and-offers.add.title")}
            description={t("requests-and-offers.add.desceription")}
        >
            <LocationAndDate secure />
        </SecureLayout>
    );
};

export default AddLocationAndDate;
