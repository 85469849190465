import { Col, Row } from "antd";
import { useAtom } from "jotai";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import IncrementsInput from "../../../components/atomics/increments-input";
import PrimaryButton from "../../../components/atomics/primary-button";
import SecondaryButton from "../../../components/atomics/secondary-button";
import BoxContainer from "../../../components/containers/box-container";
import SecureLayout from "../../../components/layouts/secure-layout";
import ViewAdditionalInfoPart from "../../../page-parts/view-additional-info-part";
import ViewAdditionalServicesPart from "../../../page-parts/view-additional-services-part";
import ViewMachineInfoPart from "../../../page-parts/view-machine-info-part";
import ViewServiceRegionsPart from "../../../page-parts/view-service-regions-part";
import { extendedMachineDetailAtom, loadingAtom, postloginMessageAtom } from "../../../store/global-atoms";
import { useSecureService } from "../../../store/service-atoms";
import { t } from "../../../translate";

const ReviewMachine = () => {
    const [editedMachine, editMachine] = useAtom(extendedMachineDetailAtom);
    const [count, setCount] = useState<number>(1);
    const navigate = useNavigate();
    const { doPostCreateMachine } = useSecureService();
    const [, setMessage] = useAtom(postloginMessageAtom);
    const [loading] = useAtom(loadingAtom)
    const saveClicked = async () => {
        if (editedMachine) {
            const result = await doPostCreateMachine(editedMachine, count);
            if (result) {
                setMessage({
                    message: t("machines.add.successCreate"),
                    type: "success",
                });
                navigate(-2);
            }
        }
    };

    return (
        <SecureLayout activePage={"machines"} role={"lessor"} title={t("machines.add.title")} description={t("machines.add.description")}>
            <Row gutter={[4, 4]}>
                <Col xs={24} md={12}>
                    <ViewMachineInfoPart viewType="machine" machine={editedMachine} />
                </Col>
                <Col xs={24} md={12}>
                    <ViewAdditionalInfoPart viewType="machine" machine={editedMachine} />
                </Col>
                <Col xs={24} md={12}>
                    <ViewAdditionalServicesPart viewType="machine" machine={editedMachine} />
                </Col>
                <Col xs={24} md={12}>
                    <ViewServiceRegionsPart viewType="machine" machine={editedMachine} />
                </Col>
                <Col xs={24}>
                    <h3
                        style={{
                            fontSize: "1.25rem",
                            fontWeight: "800",
                            marginTop: "1rem",
                            marginBottom: "1rem",
                            textAlign: "center",
                        }}
                    >
                        {t("machines.add.createCount")}
                    </h3>
                </Col>
                <Col xs={24}>
                    <BoxContainer>
                        <IncrementsInput
                            onChange={(i) => {
                                setCount(i);
                            }}
                            min={1}
                            max={100}
                        />
                    </BoxContainer>
                </Col>
                <Col xs={{ span: 24 }} md={{ span: 18, offset: 3 }} style={{ marginTop: "1rem" }}>
                    <Row gutter={16}>
                        <Col xs={24} md={8}>
                            <SecondaryButton
                                onClick={() => {
                                    editMachine(undefined);
                                    navigate(-2);
                                }}
                                negative
                            >
                                {t("common.delete")}
                            </SecondaryButton>
                        </Col>
                        <Col xs={24} md={8}>
                            <SecondaryButton onClick={() => navigate(-1)}>{t("common.edit")}</SecondaryButton>
                        </Col>
                        <Col xs={24} md={8}>
                            <PrimaryButton loading={loading} onClick={saveClicked}>{t("common.save")}</PrimaryButton>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </SecureLayout>
    );
};

export default ReviewMachine;
