type GrayboxContainerProps = {
    children?: any;
    title?: string | null;
};
const GrayboxContainer = (props: GrayboxContainerProps) => {
    return (
        <div
            style={{
                background: "#EEF0F2",
                borderRadius: "0.5rem",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "stretch",
            }}
        >
            <h5
                style={{
                    textAlign: "center",
                    fontSize: "1rem",
                    fontWeight: "800",
                    marginTop: ".5rem",
                }}
            >
                {props.title?.charAt(0).toUpperCase() + "" + props.title?.slice(1)}
            </h5>
            <div
                style={{
                    display: "flex",
                    gap: "1rem",
                    marginLeft: "1rem",
                    marginRight: "1rem",
                    flexDirection: "row",
                    justifyContent: "center",
                    alignItems: "stretch",
                }}
            >
                {props.children}
            </div>
        </div>
    );
};
export default GrayboxContainer;
