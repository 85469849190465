import DataTable from "../components/atomics/data-table";
import { RequestFormCommentViewModel } from "../service";
import { t } from "../translate";
import BigboxContainer from "../components/containers/bigbox-container";
import { moment } from "../helpers/moment";

type RequestHistoryPartProps = {
    comments?: RequestFormCommentViewModel[] | null;
};

const RequestHistoryPart = (props: RequestHistoryPartProps) => {
    const cols = [
        {
            title: t("comments.table.date"),
            dataIndex: "creationDate",
            key: "creationDate",
            render: (val: Date | undefined | null) => {
                return moment(val);
            },
        },
        {
            title: t("comments.table.user"),
            dataIndex: "user",
            key: "user",
        },
        {
            title: t("comments.table.status"),
            dataIndex: "approved",
            key: "approved",
            render: (val: boolean) => <>{val ? "OK" : "REJECT"}</>,
        },
        {
            title: t("comments.table.note"),
            dataIndex: "note",
            key: "note",
        },
    ];
    return (
        <BigboxContainer title={t("requests.view.notesTitle")}>
            {props.comments && <DataTable dataSource={props.comments} columns={cols} pagination={false} />}
        </BigboxContainer>
    );
};

export default RequestHistoryPart;
