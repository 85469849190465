import { useEffect, useState } from "react";
import styles from "./increments-input-version-2.module.scss";

type IncrementsInputVersion2Prop = {
    onChange?: (val: number) => void;
    min: number;
    max: number;
    value?: number;
};
const IncrementsInputVersion2 = (props: IncrementsInputVersion2Prop) => {
    const [plusVisible, setPlusVisible] = useState(false);
    const [minusVisible, setMinusVisible] = useState(false);
    const [currentVal, setCurrentVal] = useState(props.value || props.min);
    useEffect(() => {
        if (currentVal <= props.min) setMinusVisible(false);
        else setMinusVisible(true);

        if (currentVal >= props.max) setPlusVisible(false);
        else setPlusVisible(true);
        if (props.onChange) props.onChange(currentVal);
        // eslint-disable-next-line
    }, [currentVal]);

    useEffect(() => {
        if (props.value) setCurrentVal(props.value);
    }, [props.value]);

    const minusClicked = () => {
        if (minusVisible) setCurrentVal(currentVal - 1);
    };

    const plusClicked = () => {
        if (plusVisible) setCurrentVal(currentVal + 1);
    };

    return (
        <div
            className={styles.mainContainer}
            style={{
                display: "flex",
                justifyContent: "space-around",
                alignItems: "center",
                height: "3.75rem",
                columnGap: '12px',
            }}
        >
            <div onClick={minusClicked} className={minusVisible ? styles.minusButtonContainer : `${styles.minusButtonContainer} ${styles.disable}`}>
                -
            </div>
            <div className={styles.valueContainer}>
                <span>{currentVal}</span>
            </div>
            <div onClick={plusClicked} className={plusVisible ? styles.plusButtonContainer : `${styles.plusButtonContainer} ${styles.disable}`}>
                +
            </div>
        </div>
    );
};

export default IncrementsInputVersion2;
