/* tslint:disable */
/* eslint-disable */
/**
 * MachineGo P1 API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface OfferFormPaymentLinkModel
 */
export interface OfferFormPaymentLinkModel {
    /**
     * 
     * @type {string}
     * @memberof OfferFormPaymentLinkModel
     */
    paymentLink?: string | null;
}

export function OfferFormPaymentLinkModelFromJSON(json: any): OfferFormPaymentLinkModel {
    return OfferFormPaymentLinkModelFromJSONTyped(json, false);
}

export function OfferFormPaymentLinkModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): OfferFormPaymentLinkModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'paymentLink': !exists(json, 'paymentLink') ? undefined : json['paymentLink'],
    };
}

export function OfferFormPaymentLinkModelToJSON(value?: OfferFormPaymentLinkModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'paymentLink': value.paymentLink,
    };
}

