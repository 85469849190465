import { SelectProps, Select } from "antd";
import style from "./styled-select.module.scss";
type StyledCheckboxProps = {
    children?:any
}&SelectProps


const StyledSelect = (props:StyledCheckboxProps) => {
    return (
        <Select getPopupContainer={(trigger: any) => trigger.parentElement} size="large" bordered={false} {...props} className={style.styledSelect}></Select>
    )
}

export default StyledSelect;