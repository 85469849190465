import { t } from "../../translate";
import { useNavigate, useSearchParams } from "react-router-dom";
import SignupLayout from "../../components/layouts/signup-layout";
import styles from "../../components/styles/signup-index.module.scss";
import { Col, Row, Spin } from "antd";
import { useEffect, useState } from "react";
import SocialLoginButton from "../../components/atomics/social-auth-button";
import { useAtom } from "jotai";
import { postloginMessageAtom, signUpAtom, RoleType} from "../../store/global-atoms";
import { AuthType, SocialAuthPageType, SocialLoginMessageType } from "../../service";
import { useSecureService } from "../../store/service-atoms";
import { facebookLogin, googleLogin } from "../../utils/auth-utils";
import { facebookLoginActive, googleLoginActive, redirectRegisterPageUri } from "../../store/app-constants";
import TabButton from "../../components/atomics/tab-button";
import { ReactComponent as OrangeRightArrowIcon } from "../../components/assets/orangeRightArrow.svg"
import { ReactComponent as UserColorful } from "../../components/assets/ic_user_colorful.svg";
import { ReactComponent as CorpColorful } from "../../components/assets/ic_corp_colorful.svg";


const Signup = () => {
    const navigate = useNavigate();
    const [query] = useSearchParams();
    const socialLoginCode = query.get("code"); // code injected by facebook login dialog
    const [loading, setLoading] = useState(socialLoginCode !== null);
    const queryRole = ((query.get("role") || query.get("state")?.split('role:')[1]) as RoleType) || null // 'state' holds roletype info for facebook redirection
    const socialLoginQuery = query.get("state")?.split('loginType:')[1].split(',')[0];
    const socialLoginType = (socialLoginQuery as AuthType) || null; // code injected by facebook login dialog
    const [selectedCard, setSelectedCard] = useState(queryRole === null  ? 0 : queryRole === 'customer' ? 1 : 2);
    const [, setSignUp] = useAtom(signUpAtom);
    const [,setWarningMessage] = useAtom(postloginMessageAtom);
    const { doSocialLogin } = useSecureService();
    const description = t("signup.form.description");


    useEffect(() => {
        const socialLoginAsync = async (authType: AuthType) => {
            var res = await doSocialLogin(queryRole, {authType: authType, socialToken: socialLoginCode, socialAuthPageType: SocialAuthPageType.Register});
            switch(res?.type) {
                case SocialLoginMessageType.NotFound:
                case SocialLoginMessageType.SwitchMessage:
                    setSignUp({
                        authType: authType,
                        email: res.email!!,
                        name: res.name,
                        lastname: res.lastname
                    });
                    if (selectedCard === 1) { 
                        navigate("/signup/form?role=customer");
                    }
                    if (selectedCard === 2) {
                        navigate("/signup/form?role=lessor");
                    }
                    break;
                case SocialLoginMessageType.Blocked:
                    setWarningMessage({message: res.message, type: 'error'});  
                    setLoading(false);
                    break;  
                case SocialLoginMessageType.Success:
                    navigate("/dashboard?role=" + queryRole);
                    break;
                case SocialLoginMessageType.Otp:
                    navigate("/sms-verification?id=" + res?.id + "&op=register&phone=" + res?.message + "&role=" + queryRole);
                    break;
                default:
                    setLoading(false);
                    break;
            }
        }
        if (socialLoginCode && socialLoginType != null) {
            socialLoginAsync(socialLoginType);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <>
            {loading ? 
                <Spin size="large" tip="Lütfen Bekleyiniz" className={styles.spinMobile} />
                : (
                    <SignupLayout className={styles.container} description={<div className={styles.titleContainer}><div className={styles.title}><h1>{t("signup.form.hello")}</h1></div><div className={styles.description}><span>{description}</span></div></div>}>
                        <Row className={styles.signupOptionsContainer} gutter={[12, 12]}>
                            <Col xs={12}>
                                <TabButton
                                    icon={<CorpColorful height="20px" width="20px" />}
                                    label={t("signup.index.secondCardTitle")}
                                    active={selectedCard === 2}
                                    onClick={(e: any) => {
                                        setSelectedCard(2);
                                    }}
                                />
                            </Col>
                            <Col xs={12}>
                                <TabButton
                                    icon={<UserColorful height="20px" width="20px" />}
                                    label={t("signup.index.firstCardTitle")}
                                    active={selectedCard === 1}
                                    onClick={(e: any) => {
                                        setSelectedCard(1);
                                    }}
                                />
                            </Col>
                            <Col xs={24} className={styles.loginContainer}>
                                    <Col xs={24} className={styles.loginTextUpper}>
                                        <span>{t("signup.form.loginText1")}</span>
                                    </Col>
                                    <Col className={styles.loginTextContainer} onClick={() => {navigate("/login" + (queryRole ? '?role=' + queryRole : ''))}} xs={24}>
                                        <span>{t("signup.form.loginText2")}</span>
                                        <OrangeRightArrowIcon />
                                    </Col>
                            </Col>
                        </Row>
                        <Row className={styles['button-container']} gutter={[0,12]}>
                            <Col hidden={!facebookLoginActive} className={styles['button-container-inner']}>
                                <SocialLoginButton disabled={selectedCard === 0} type="facebook" text={t("signup.facebook.button-text")} onClick={() => {
                                    if (selectedCard === 1) {
                                        facebookLogin(redirectRegisterPageUri, "loginType:" + AuthType.Facebook + ",role:customer");
                                    }
                                    if (selectedCard === 2) {
                                        facebookLogin(redirectRegisterPageUri, "loginType:" + AuthType.Facebook + ",role:lessor");
                                    }
                                }}/>
                            </Col>
                            <Col hidden={!googleLoginActive} className={styles['button-container-inner']}>
                                <SocialLoginButton disabled={selectedCard === 0} type="google" text={t("signup.google.button-text")} onClick={() => {
                                    if (selectedCard === 1) {
                                        googleLogin(redirectRegisterPageUri, "loginType:" + AuthType.Google + ",role:customer");
                                    }
                                    if (selectedCard === 2) {
                                        googleLogin(redirectRegisterPageUri, "loginType:" + AuthType.Google + ",role:lessor");
                                    }
                                }}/>
                            </Col>
                            <Col className={styles['button-container-inner']}>
                                <SocialLoginButton disabled={selectedCard === 0} type="email" text={t("signup.email.button-text")} onClick={() => {
                                    if (selectedCard === 1) {
                                        setSignUp({
                                            authType: AuthType.Email,
                                            email: ""
                                        });
                                        navigate("/signup/form?role=customer");
                                    }
                                    if (selectedCard === 2) {
                                        setSignUp({
                                            authType: AuthType.Email,
                                            email: ""
                                        });
                                        navigate("/signup/form?role=lessor")
                                    }
                                }}/>
                            </Col>
                        </Row>
                    </SignupLayout>
                )
            }
        </>

    );
};

export default Signup;
