import { Button } from "antd";
import { ButtonProps } from "antd/lib/button";
import styles from "../styles/btn.module.scss";

const PrimaryButton = (props: ButtonProps) => {
    return (
        <>
            <Button {...props} type="primary" className={styles.primaryBtn}>
                {props.children}
            </Button>
        </>
    );
};
export default PrimaryButton;
