import { Steps } from "antd";
import { t } from "../translate";
import PrimaryButton from "../components/atomics/primary-button";
import ModalLayout, { ModalLayoutProps } from "../components/layouts/modal-layout";
import styles from "./request-summary.module.scss";
import { useAtom } from "jotai";
import { requestFormAtom } from "../store/global-atoms";
import LinkButton from "../components/atomics/link-button";
import { ReactComponent as SuccessIcon } from "../components/assets/minisuccess.svg";
import { ReactComponent as MiniLogo } from "../components/assets/minilogo.svg";
import { useNavigate } from "react-router-dom";
import moment from "moment";

export const RequestSummary = (props: ModalLayoutProps) => {
    const navigate = useNavigate();
    const [editedRequest] = useAtom(requestFormAtom);
    const { Step } = Steps;

    let attachmentsValue = editedRequest?.attachments?.map((atc) => {
        return atc.name;
    });
    if (editedRequest?.attachmentsOther !== undefined) attachmentsValue?.push(editedRequest.attachmentsOther);

    const loginClick = () => {
        navigate("/login?role=renter&request=true");
    };

    const signupClick = () => {
        navigate("/signup/form?role=renter&request=true");
    };

    return (
        <ModalLayout show={props.show} onCloseClick={props.onCloseClick}>
            <div className={styles.lightboxContent}>
                <div className={styles.stepsContainer}>
                    <Steps
                        direction="horizontal"
                        size="default"
                        current={1}
                        responsive={false}
                        labelPlacement="vertical"
                        className={styles.steps}
                    >
                        <Step title={<p>{t("request.select-machine.title.v1")}</p>} icon={<SuccessIcon />}></Step>
                        <Step title={<p>{t("request.location-and-date.title.v1")}</p>} icon={<MiniLogo />}></Step>
                    </Steps>
                </div>
                <p>{t("request.machine-detail.summaryDescription")}</p>
                <div className={styles.successGrayField}>
                    <div className={styles.row}>
                        <span className={styles.successLabel}>{t("request.machine-detail.summaryMachineLabel")}</span>
                        <span className={styles.successValue}>{editedRequest?.subCategory?.name}</span>
                    </div>
                    <div className={styles.row}>
                        <span className={styles.successLabel}>{t("request.machine-detail.summaryAttachmentsLabel")}</span>
                        <span className={styles.successValue}>{attachmentsValue?.join(", ")}</span>
                    </div>
                    <div className={styles.row}>
                        <span className={styles.successLabel}>{t("request.machine-detail.summaryLocationsLabel")}</span>
                        <span className={styles.successValue}>{editedRequest?.district?.name}</span>
                    </div>
                    <div className={styles.row}>
                        <span className={styles.successLabel}>{t("request.machine-detail.summaryDatesLabel")}</span>
                        <span className={styles.successValue}>
                            {moment(editedRequest?.fromDateRaw).format("DD.MM.YYYY") + " - " + moment(editedRequest?.toDateRaw).format("DD.MM.YYYY")}
                        </span>
                    </div>
                    <div className={styles.row}>
                        <span className={styles.successLabel}>{t("request.machine-detail.summaryDetailsLabel")}</span>
                        <span className={styles.successValue}>{editedRequest?.description}</span>
                    </div>
                    <div></div>
                </div>
                <PrimaryButton type="primary" onClick={signupClick}>
                    {t("common.signupNow")}
                </PrimaryButton>
                <span>
                    {t("common.ifAccountExists")} <LinkButton onClick={loginClick}>{t("common.signin")}</LinkButton>
                </span>
            </div>
        </ModalLayout>
    );
};
