import { Checkbox, Col, Divider, Row } from "antd";
import styles from '../styles/tab-with-checkbox-and-input.module.scss'
import { FormInstance } from "antd/es/form/Form";
import { t } from "../../translate";
import { useEffect, useState } from "react";
import { offerTabAtom } from "../../store/global-atoms";
import { useAtom } from "jotai";

export type TabWithCheckboxAndInputProps = {
    title: string;
    form: FormInstance<any>;
    setTabSelected?: (tabTitle: string, selected: boolean, index: number) => void;
    checkedTabIndex?: 1 | 2 | 3;
    selectedTabIndex?: number;
    index: number;
}


const TabWithCheckboxAndInput = (props: TabWithCheckboxAndInputProps) => {
    const [tabSelected, setTabSelected] = useState(false);
    const [offerTabInfo,] = useAtom(offerTabAtom);

    useEffect(() => {
        setTabSelected(offerTabInfo.maturityInterestRates[props.index] !== -1 ? true : false);
        //eslint-disable-next-line
    }, [ offerTabInfo.maturityInterestRates[props.index] ]);

    return (
        <div>
            {props.selectedTabIndex !== null && props.setTabSelected !== null && props.checkedTabIndex !== null &&
            <div className={ offerTabInfo.includedMaturityOptionsError[props.index] === false ? (props.selectedTabIndex !== undefined && props.selectedTabIndex === props.index ? styles.checkedAndSelectedContainer : styles.container) : styles.errorContainer}>
                <Row style={{width: '100%', display: 'flex', justifyContent: 'center'}}>
                    <Row style={{width: '80%', marginTop: '1rem', display: 'flex', justifyContent: 'space-between'}}>
                        <Col sm={10} md={10} lg={20}>
                            <span className={props.selectedTabIndex !== undefined && props.selectedTabIndex === props.index  ? styles.titleSelectedTab : styles.title }>{props.title}</span>
                        </Col>  
                        <Col sm={4} md={4} lg={4}>
                            <Checkbox id={"checkbox-tab-"+ props.index} value={offerTabInfo.maturityInterestRates[props.index] !== -1} checked={tabSelected} style={{zIndex: '1'}} onClick={e => {
                                e.stopPropagation(); 
                                if(props.setTabSelected) props.setTabSelected(props.title, !tabSelected, props.index)
                            }}/>              
                        </Col>
                        <Divider style={{width: '100%', marginTop: '0.75rem'}}></Divider>
                    </Row>
                    
                    <Row style={{width: '85%', marginTop: '-0.4rem', display: 'flex', alignContent: 'center', alignItems: 'center'}}>
                        <Col sm={24} md={24} className={props.checkedTabIndex && props.checkedTabIndex === 2 ? styles.messageContainerType2 : styles.messageContainerType1 }>
                            
                            {props.checkedTabIndex && props.checkedTabIndex === 1 &&
                                <>
                                    <span style={{marginLeft:'5px'}}>-</span>
                                    <span style={{marginRight:'5px'}}>%</span>
                                </>
                            }
                            {props.checkedTabIndex && props.checkedTabIndex === 2 &&
                                <>
                                    <span>{t("offer.add.maturityTab.text1")}</span>
                                </>
                            }
                            {props.checkedTabIndex && props.checkedTabIndex === 3 && offerTabInfo.maturityInterestRates[props.index] === null &&
                                <>
                                    <span style={{marginLeft:'5px'}}>{t("offer.add.maturityTab.text2")}</span>
                                    <span style={{marginRight:'5px'}}>%</span>
                                </>
                            }

                            {props.checkedTabIndex && props.checkedTabIndex === 3 && offerTabInfo.maturityInterestRates[props.index] !== null &&
                                <>
                                    <span style={{marginLeft:'5px'}}>{offerTabInfo.maturityInterestRates[props.index]}</span>
                                    <span style={{marginRight:'5px'}}>%</span>
                                </>
                            }


                        </Col>
                    </Row>
                </Row> 
            </div>
            }
        </div>
        
        
        
        
    );
}

export default TabWithCheckboxAndInput;