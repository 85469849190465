import OnboardLayout from "./onboard-layout";
import styles from "../styles/request-layout.module.scss";
import { t } from "../../translate";
import { Col, Row, Steps, Grid } from "antd";
import { MouseEventHandler, useEffect, useRef } from "react";
import PrimaryButton from "../atomics/primary-button";
import { useNavigate } from "react-router-dom";

export type RequestLayoutProps = {
    children: any;
    currentStep: 0 | 1 | 2;
    continueVisible: boolean;
    onContinueClick: MouseEventHandler<HTMLElement>;
};

const RequestLayout = (props: RequestLayoutProps) => {
    const navigate = useNavigate();
    const stepContainerRef = useRef<HTMLHeadingElement>(null);
    const { lg } = Grid.useBreakpoint();
    const { Step } = Steps;

    const Description = <>{t("request.description")}</>;

    const stepClick = (param: number) => {
        navigate(param - props.currentStep);
    };

    useEffect(() => {
        if (!lg)
            stepContainerRef.current?.scrollTo({
                left: 200 * props.currentStep,
                behavior: "smooth",
            });
    }, [props.currentStep, lg]);

    return (
        <OnboardLayout smallLogo={true}  h2={Description}>
            <div className={styles.contentContainer}>
                <div className={styles.requestContent}>
                    <div className={lg ? styles.requestStepsContainer : styles.requestStepsContainerMobile} ref={stepContainerRef}>
                        <Steps
                            direction={lg ? "vertical" : "horizontal"}
                            size={lg ? "default" : "small"}
                            current={props.currentStep}
                            responsive={false}
                        >
                            <Step
                                onStepClick={props.currentStep > 0 ? stepClick : undefined}
                                title={<p>{t("request.select-machine.title")}</p>}
                                className={styles.requestStepItem}
                            >
                                <div></div>
                            </Step>
                            <Step
                                onStepClick={props.currentStep > 1 ? stepClick : undefined}
                                title={<p id="step2">{t("request.location-and-date.title")}</p>}
                                className={styles.requestStepItem}
                            ></Step>
                            <Step
                                onStepClick={props.currentStep > 2 ? stepClick : undefined}
                                title={<p id="step3">{t("request.machine-detail.title")}</p>}
                                className={styles.requestStepItem}
                            ></Step>
                        </Steps>
                    </div>

                    <div className={styles.requestMain}>
                        <div className={styles.contentContainer}>
                            {props.children}
                            {props.continueVisible && <div style={{ height: "6rem" }}></div>}
                        </div>
                        <Row
                            className={
                                !props.continueVisible
                                    ? styles.continueButtonContainer
                                    : `${styles.continueButtonContainer} ${styles.continueButtonContainerShow}`
                            }
                        >
                            <Col xs={18} lg={6}>
                                <PrimaryButton onClick={props.onContinueClick}>{t("common.continue")}</PrimaryButton>
                            </Col>
                        </Row>
                    </div>
                </div>
            </div>
        </OnboardLayout>
    );
};

export default RequestLayout;
