import { Col, Form, Input, Row, Select } from "antd";
import { useEffect, useState } from "react";

export type DropdownWithInputProps = {
    loading: boolean;
    dropdownValidationMessage: string;
    formItemName: string;
    formItemLabel: string;
    otherChoiceValue: string;
    inputName: string;
    initVal?: string | string[] | null;
    dropdownPlaceholder?: string | null;
    data?: { name?: string | null; id?: number }[] | null;
    inputLabel?: string;
    inputValidationMessage?: string;
    inputInitialVal?: string;
    required?: boolean;
    multiple?: boolean;
};

export const DropdownWithInput = (props: DropdownWithInputProps) => {
    const [checkNick, setCheckNick] = useState(false);
    const [inputVal, setInputVal] = useState("");
    const [inputVisible, setInputVisible] = useState(false);
    const { Option } = Select;
    const otherItem = {
        id: -1,
        name: props.otherChoiceValue,
    };
    let dropdownItems: { name?: string | null; id?: number }[] | null | undefined = props.data;
    if (!!dropdownItems && !dropdownItems.find((item) => item.name === props.otherChoiceValue)) dropdownItems.push(otherItem);

    const dropdownChange = (val: string | string[]) => {
        let otherSelected = false;
        if (typeof val === "string") {
            otherSelected = val === props.otherChoiceValue;
        } else {
            otherSelected = val.includes(props.otherChoiceValue);
        }

        setInputVisible(otherSelected);
        setCheckNick(otherSelected);
        setInputVal("");
    };

    useEffect(() => {
        setInputVisible(props.inputInitialVal !== undefined)
        //eslint-disable-next-line
    },[props.inputInitialVal])

    const SelectComponent = props.multiple ? (
        <Select getPopupContainer={(trigger: any) => trigger.parentElement} dropdownStyle = {{ position: "relative" }} mode="multiple" onChange={dropdownChange} showSearch loading={props.loading} placeholder={props.dropdownPlaceholder}>
            {dropdownItems?.map((br) => (
                <Option value={br.name}>{br.name}</Option>
            ))}
        </Select>
    ) : (
        <Select getPopupContainer={(trigger: any) => trigger.parentElement} dropdownStyle = {{ position: "relative" }} onChange={dropdownChange} showSearch loading={props.loading} placeholder={props.dropdownPlaceholder}>
            {dropdownItems?.map((br) => (
                <Option value={br.name}>{br.name}</Option>
            ))}
        </Select>
    );

    return (
        <Row gutter={[8, 8]}>
            <Col xs={inputVisible ? 12 : 24}>
                <Form.Item
                    name={props.formItemName}
                    label={props.formItemLabel}
                    initialValue={props.initVal}
                    rules={[
                        {
                            required: props.required,
                            message: props.dropdownValidationMessage,
                        },
                    ]}
                >
                    {SelectComponent}
                </Form.Item>
            </Col>
            <Col xs={12}>
                <Form.Item
                    style={inputVisible ? undefined : { display: "none" }}
                    name={props.inputName}
                    label={props.inputLabel}
                    rules={[
                        {
                            required: checkNick,
                            message: props.inputValidationMessage,
                        },
                    ]}
                    initialValue={props.inputInitialVal}
                >
                    <Input disabled={!inputVisible} value={inputVal}></Input>
                </Form.Item>
            </Col>
        </Row>
    );
};
