import { Col, Row } from "antd";
import { useAtom } from "jotai";
import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import PrimaryButton from "../../../components/atomics/primary-button";
import RequestAndOfferStatus from "../../../components/atomics/request-and-offer-status";
import SecondaryButton from "../../../components/atomics/secondary-button";
import SecureLayout from "../../../components/layouts/secure-layout";
import { useQuery } from "../../../helpers/use-query";
import AccepteOfferPart from "../../../page-parts/accepted-offer-parts";
import IncomingOfferPart from "../../../page-parts/incoming-offer-parts";
import RequestHistoryPart from "../../../page-parts/request-history-part";
import ViewAdditionalInfoPart from "../../../page-parts/view-additional-info-part";
import ViewAdditionalServicesPart from "../../../page-parts/view-additional-services-part";
import ViewMachineInfoPart from "../../../page-parts/view-machine-info-part";
import ViewServiceRegionsPart from "../../../page-parts/view-service-regions-part";
import { OfferFormViewModel } from "../../../service";
import { ModifiedRequestDetailModel, roleAtom } from "../../../store/global-atoms";
import { useSecureService } from "../../../store/service-atoms";
import { t } from "../../../translate";
/* import OfferViewPart from "../../../page-parts/offer-view-part"; */

const ViewRequest = () => {
    const query = useQuery();
    const reqId = query.get("reqid");
    const offerId = query.get("offerid");
    const navigate = useNavigate();
    const [role] = useAtom(roleAtom);
    const { doGetRequestDetail, doGetOfferDetail } = useSecureService();
    const [request, setRequest] = useState<ModifiedRequestDetailModel | undefined>(undefined);
    const notesRef = useRef<HTMLDivElement>(null);
    const [offer, setOffer] = useState<OfferFormViewModel>();
    const [dataFetching, setDataFetching] = useState(true);
    const [availableMachineCount, setAvailableMachineCount] = useState<Number | undefined>(0);

    useEffect(() => {
        if (reqId && role === "customer") {
            const reqIdN = parseInt(reqId);
            if (!Number.isNaN(reqIdN)) {
                doGetRequestDetail(reqIdN).then((data) => {
                    setRequest(data as ModifiedRequestDetailModel);
                });
            }
        } else if (offerId && role === "lessor") {
            const offerIdN = parseInt(offerId);
            if (!Number.isNaN(offerIdN)) {
                doGetOfferDetail(offerIdN).then((data) => {
                    setOffer(data);
                    setAvailableMachineCount(data?.requestForm?.availableMachineCount);
                    setRequest(data?.requestForm as ModifiedRequestDetailModel);
                });
            }
        }
        //eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (request !== undefined) {
            setDataFetching(false);
        }
    }, [request]);

    let buttons = [];
    if (role === "lessor" && (request?.status === "Suggested" || request?.status === "Offered")) {
        buttons.push(
            <PrimaryButton onClick={() => navigate("/offers/add?reqid=" + reqId + "&offerid=" + offerId + "&max=" + availableMachineCount)}>
                {t("common.makeOffer")}
            </PrimaryButton>
        );
    }
    buttons.push(
        <RequestAndOfferStatus
            showOfferStatus={false}
            showRequestStatus={true}
            role={role}
            big
            offerStatus={offer?.status}
            requestStatus={request?.status}
        />
    );
    if ((request?.status === "Rejected" || request?.status === "InReview" || request?.status === "PreRegistration") && role === "customer")
        buttons.push(
            <SecondaryButton
                onClick={() =>
                    navigate("/requests/edit?reqid=" + reqId + (request.machineId ? "&macid=" + request.machineId : ""))
                }
            >
                {t("common.edit")}
            </SecondaryButton>
        );
    /* if (request?.status === "Rejected" || request?.status === "Approved")
        buttons.push(
            <PrimaryButton onClick={() => notesRef?.current?.scrollIntoView({ behavior: "smooth" })}>{t("common.seeNote")}</PrimaryButton>
        ); */

    const onRowClick = (item: OfferFormViewModel | undefined) => {
        navigate("/offers/view?offerid=" + item?.id + "&reqid=" + reqId);
    };
    return (
        <SecureLayout
            activePage={role === "customer" ? "requests-and-offers" : "requests"}
            role={role}
            loading={dataFetching}
            title={t("requests.view.title")}
            description={role === "customer" ? t("requests.view.description.renter") : t("requests.view.description.lessor")}
            helperButtons={buttons}
        >
            <Row gutter={[4, 4]}>
                {/* {role==="customer" && request?.status==="Deal" &&<Col xs={24}>
                    <OfferViewPart  offerId={offerIdN} onClick={()=>navigate(role==="customer"?"/offers/view?offerid="+offerId:"/requests/view?offerid="+offerId+"&reqid="+reqId,{replace:true})}/>
                </Col>} */}
                <Col xs={24} md={12}>
                    <ViewMachineInfoPart viewType="request" request={request} />
                </Col>
                <Col xs={24} md={12}>
                    <ViewAdditionalInfoPart viewType="request" request={request} />
                </Col>
                <Col xs={24} md={12}>
                    <ViewAdditionalServicesPart viewType="request" request={request} />
                </Col>
                <Col xs={24} md={12}>
                    <ViewServiceRegionsPart viewType="request" request={request} />
                </Col>
                <Col xs={24}>
                    {role === "customer" && (
                        <IncomingOfferPart
                            selectedMachineId={request?.machineId}
                            onRow={onRowClick}
                            offers={request?.offerForms?.filter((offer) => offer.status === "Approved" || offer.status === "Expired")}
                        />
                    )}
                </Col>
                <Col xs={24}>
                    {role === "customer" && (
                        <AccepteOfferPart
                            selectedMachineId={request?.machineId}
                            onRow={onRowClick}
                            Acceptedoffers={request?.offerForms?.filter(
                                (off) => off.status === "Accepted" || off.status === "Deal" || off.status === "PaymentDeclined"
                            )}
                        ></AccepteOfferPart>
                    )}
                </Col>

                {request?.requestFormComments && request.requestFormComments.length > 0 && (
                    <Col xs={24} ref={notesRef}>
                        <RequestHistoryPart comments={request.requestFormComments} />
                    </Col>
                )}
            </Row>
        </SecureLayout>
    );
};

export default ViewRequest;
