import { Form, FormInstance, Select } from "antd";
import styles from "./machine-subcategory-select.module.scss"
import { SubCategoryModel, SubCategoryWithCategoryModel } from "../../service";
import { ReactComponent as RadioFillIcon } from "../assets/icons/radio-fill.svg";
import { ReactComponent as GreyCircleIcon } from "../assets/icons/grey-circle.svg";
import { useState } from "react";

interface MachineSubcategorySelectProps {
    form: FormInstance<any>;
    required: boolean;
    label: string;
    errorMessage?: string;
    placeholder?: string;
    subcategories: SubCategoryWithCategoryModel[];
    disabled?: boolean;
    height?:string;
    onChange: (subcategory?: SubCategoryModel) => void;
}
const MachineSubcategorySelect = (props: MachineSubcategorySelectProps) => {
    const [selected, setSelected] = useState<number | undefined>();

    return (
        <div className={styles.container} style={props.height ? {height: props.height} : undefined}>
        <Form.Item
            name={'subcategory'}
            label={props.label}
            rules={[
                {
                    required: props.required,
                    message: props.errorMessage,
                }
            ]}
        >
            <Select 
                placeholder={props.placeholder}
                disabled={props.disabled}
                getPopupContainer={(trigger) => trigger.parentElement}
                onChange={(e: number) => {
                    setSelected(e);
                    props.onChange(props.subcategories.find(x => x.id === e));
                }} 
                className={styles.subCategorySelect}
            > 
                {
                    props.subcategories.map((e, i) => {
                        return (
                            <Select.Option key={'select-item-sub-' + i} label={e.name} value={e.id}>
                                <div className={styles.categorySelectOption}>
                                    <div className={styles.icon}>
                                        {selected === e.id ? <RadioFillIcon /> : <GreyCircleIcon />}
                                    </div>
                                    <div className={styles.name}>
                                        {e.name}
                                    </div>
                                </div>
                            </Select.Option>);
                    })
                }
            </Select>
        </Form.Item>
        </div>
    );
}

export default MachineSubcategorySelect;