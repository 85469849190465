/* tslint:disable */
/* eslint-disable */
/**
 * MachineGo P1 API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface OfferFormCommentViewModel
 */
export interface OfferFormCommentViewModel {
    /**
     * 
     * @type {string}
     * @memberof OfferFormCommentViewModel
     */
    comment?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof OfferFormCommentViewModel
     */
    creationDate?: Date;
    /**
     * 
     * @type {string}
     * @memberof OfferFormCommentViewModel
     */
    user?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OfferFormCommentViewModel
     */
    status?: string | null;
}

export function OfferFormCommentViewModelFromJSON(json: any): OfferFormCommentViewModel {
    return OfferFormCommentViewModelFromJSONTyped(json, false);
}

export function OfferFormCommentViewModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): OfferFormCommentViewModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'comment': !exists(json, 'comment') ? undefined : json['comment'],
        'creationDate': !exists(json, 'creationDate') ? undefined : (new Date(json['creationDate'])),
        'user': !exists(json, 'user') ? undefined : json['user'],
        'status': !exists(json, 'status') ? undefined : json['status'],
    };
}

export function OfferFormCommentViewModelToJSON(value?: OfferFormCommentViewModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'comment': value.comment,
        'creationDate': value.creationDate === undefined ? undefined : (value.creationDate.toISOString()),
        'user': value.user,
        'status': value.status,
    };
}

