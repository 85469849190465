import { Col, Divider, FormInstance, Row, Tooltip } from "antd";
import BigboxContainer from "../components/containers/bigbox-container";
import { t } from "../translate";
import { ReactComponent as Info } from "../components/assets/info-icon.svg";
import { useAtom } from "jotai";
import { offerTabAtom } from "../store/global-atoms";


export type OfferMaturityTotalProps = {
    form: FormInstance<any>; 
    maturityType: string;
}

const OfferMaturityTotal = (props: OfferMaturityTotalProps) => {
    const [offerTabInfo,] = useAtom(offerTabAtom);

    
    return (
        <BigboxContainer  title={t("offer.add.maturityTotal.title")}>
            <div style={{position: 'absolute', width: '100%', marginTop: '0.8rem'}}>    
                <Tooltip title='info'>
                    <div style={{width: '3rem', float: 'right', marginRight: '2rem'}}>
                        <Info style={{transform: 'scale(0.8)'}}></Info>
                    </div>
                    
                </Tooltip>
            </div>
            <Row style={{marginLeft: '0rem', marginTop: '0.75rem'}}>
                <Col xs={24} md={24}>
                    <span style={{fontWeight: '700', fontSize: '1rem'}}>{props.maturityType}</span>
                </Col>
            </Row>

            <Row gutter={12}>
                <Col xs={24} md={12} style={{marginTop: '1.5rem'}}>
                    <Row>
                        <Col xs={24} md={24}>
                            <div style={{display: 'flex', justifyContent: 'space-between', alignContent: 'center', alignItems: 'center', background: '#EEF0F2', borderRadius: '10px', height: '3rem'}}>
                                <span style={{marginLeft: '1rem', fontSize: '0.75rem'}}>{t("offer.add.maturityTotal.periodAmount")}</span>
                                <span style={{fontWeight: '700', marginRight: '1rem', fontSize: '0.75rem'}}>
                                    {offerTabInfo.lowestSelectedMaturityPeriodPrice && offerTabInfo.lowestSelectedMaturityPeriodPrice !== 0 ?      
                                    (offerTabInfo.lowestSelectedMaturityPeriodPrice + (!offerTabInfo.lowestMaturitySelected ? (Math.ceil(offerTabInfo.lowestSelectedMaturityPeriodPrice * ((offerTabInfo.maturityInterestRates[offerTabInfo.currentTab.index] ?? 0) * 0.01 ))) : 0)) 
                                    + " " + offerTabInfo.currencyType : "-"}
                                
                                </span>
                            </div>              
                        </Col>
                    </Row>

                    <Row style={{marginTop: '0.5rem'}}>
                        <Col xs={24} md={24}>
                            <div style={{display: 'flex', justifyContent: 'space-between', alignContent: 'center', alignItems: 'center', background: '#EEF0F2', borderRadius: '10px', height: '3rem'}}>
                                <span style={{marginLeft: '1rem', fontSize: '0.75rem'}}>{t("offer.add.maturityTotal.shippingGoingFee")}</span>
                                <span style={{fontWeight: '700', marginRight: '1rem', fontSize: '0.75rem'}}>
                                    {offerTabInfo.lowestSelectedMaturityShippingGoFee !== 0 ? 
                                    (offerTabInfo.lowestSelectedMaturityShippingGoFee + (!offerTabInfo.lowestMaturitySelected ? (Math.ceil(offerTabInfo.lowestSelectedMaturityShippingGoFee * ((offerTabInfo.maturityInterestRates[offerTabInfo.currentTab.index] ?? 0) * 0.01 ))) : 0)  ) + " " + offerTabInfo.currencyType : '-'}
                                
                                </span>
                            </div>
                        </Col>
                    </Row>

                    <Row style={{marginTop: '0.5rem'}}>
                        <Col xs={24} md={24}>
                            <div style={{display: 'flex', justifyContent: 'space-between', alignContent: 'center', alignItems: 'center', background: '#EEF0F2', borderRadius: '10px', height: '3rem'}}>
                                <span style={{marginLeft: '1rem', fontSize: '0.75rem'}}>{t("offer.add.maturityTotal.shippingReturnFee")}</span>
                                <span style={{fontWeight: '700', marginRight: '1rem', fontSize: '0.75rem'}}>
                                    {
                                    offerTabInfo.lowestSelectedMaturityShippingReturnFee !== 0 ?  
                                    (offerTabInfo.lowestSelectedMaturityShippingReturnFee + (!offerTabInfo.lowestMaturitySelected ? Math.ceil(offerTabInfo.lowestSelectedMaturityShippingReturnFee * ((offerTabInfo.maturityInterestRates[offerTabInfo.currentTab.index] ?? 0) * 0.01 )) : 0)) 
                                    + " " + offerTabInfo.currencyType : '-'}</span>
                            </div>
                        </Col>
                    </Row>
                </Col>

                <Col xs={24} md={12}>
                    <Row style={{marginTop: '-0.6rem', marginLeft: '0', display: 'flex', justifyContent: 'right'}}>
                        <Col xs={24} md={23}>
                            <div style={{display: 'flex', justifyContent: 'space-between', alignContent: 'center', alignItems: 'center'}}>
                                <span style={{color: '#949394', fontWeight: '500'}}>{t("offer.add.maturityTotal.attachmentsTitle")}</span>
                            </div>
                        </Col>
                    </Row>
                    {offerTabInfo.selectedAttachments && offerTabInfo.selectedAttachments.length > 0 &&
                        offerTabInfo.selectedAttachments.map((e, index) => {
                            return (
                                <Row style={{display: 'flex', justifyContent: 'right', marginTop: '0.5rem'}}>
                                    <Col xs={24} md={24}>
                                        <div style={{display: 'flex', justifyContent: 'space-between', alignContent: 'center', alignItems: 'center', background: '#EEF0F2', borderRadius: '10px', height: '3rem'}}>
                                            <span style={{marginLeft: '1rem', fontSize: '0.75rem'}}>{e.name}</span>
                                            <span style={{fontWeight: '700', fontSize: '0.75rem', marginRight: '1rem'}}>
                                                {(Math.ceil(e.fee + (!offerTabInfo.lowestMaturitySelected ? e.fee * ((offerTabInfo.maturityInterestRates[offerTabInfo.currentTab.index] ?? 0) * 0.01 ) : 0) ) + " " + offerTabInfo.currencyType).toString()}
                                            </span>
                                        </div>
                                    </Col>
                                </Row>
                            );
                        })
                    }
                </Col>
            </Row>
            <Row>
                <Col xs={24} md={24}>
                    <Divider></Divider>
                </Col>
                
                <Col xs={24} md={24}>
                    <div style={{display: 'flex', justifyContent: 'space-between', alignContent: 'center', alignItems: 'center', background: '#EEF0F2', borderRadius: '10px', height: '3rem'}}>
                        <span style={{fontWeight: '800', fontSize: '1rem', marginLeft: '1rem'}}>{t("offer.add.maturityTotal.totalPrice")}</span>
                        <span style={{fontWeight: '800', fontSize: '1rem', marginRight: '1rem'}}>
                            { offerTabInfo.currentTab.index < 0 ? "0" : (offerTabInfo.lowestMaturitySelected ? offerTabInfo.currentTabTotalPrice + " " + offerTabInfo.currencyType : Math.ceil(offerTabInfo.currentTabTotalPrice + ((offerTabInfo.maturityInterestRates[offerTabInfo.currentTab.index] ?? 0) > 0 ? (offerTabInfo.currentTabTotalPrice * (offerTabInfo.maturityInterestRates[offerTabInfo.currentTab.index] ?? 0) * 0.01 ): 0)) + " " + offerTabInfo.currencyType)}
                        </span>
                    </div>
                </Col>
            </Row>

        </BigboxContainer>
    );
}

export default OfferMaturityTotal;