/* eslint-disable react-hooks/exhaustive-deps */
import { Col, Pagination, Row, Spin } from "antd";
import React, { useEffect, useState } from "react";
import { ReactComponent as InfoLg } from "../components/assets/info-icon-lg.svg";
import style from "./product-part.module.scss";
import TertiaryButton from "../components/atomics/tertiary-button";
import ProductCard from "../components/atomics/product-card";
import { activeApiBaseUrl, activeAppSiteUrl } from "../store/app-constants";
import { t } from "../translate";
import { useSecureService } from "../store/service-atoms";

import { MachineListModelPagedDataModel } from "../service";

import placeholder from "../components/assets/placeholder.svg";
import { formatCurrency } from "../helpers/formatters";
import InfoCard from "../components/atomics/info-card";
import { MachineFilterType } from "../store/global-atoms";

type ProductPartProps = {
    showHeader: boolean;
    showButton: boolean;
    showPagination?: boolean;
    orderButton?: React.ReactElement;
    machineFilter?: MachineFilterType;
    imgId?: number;

    onPageChange?: (page: number) => void;
};

const ProductPart = (props: ProductPartProps) => {
    const { doGetMachines } = useSecureService();
    const [machines, setMachines] = useState<MachineListModelPagedDataModel>();
    const [pageSize, setPageSize] = useState<number | undefined>(20);
    const [totalItems, setTotalItems] = useState<number | undefined>(-1);
    const [loading, setLoading] = useState(true);
    const [, setIsHaveMachine] = useState(false);

    const pageChange = (page: number) => {
        if (props.onPageChange) {
            props.onPageChange(page);
        }
    };

    const getFilteredMachines = async (pageNo: number, order: string) => {
        setLoading(true);

        const cityIdFilter =
            props.machineFilter?.cityId !== undefined && props.machineFilter?.cityId !== 1000 ? props.machineFilter?.cityId : null;

        const filter = {
            pageNo: pageNo,
            pageSize: pageSize,
            filter: {
                cityId: cityIdFilter,
                districtId: props.machineFilter?.districtId,
                categoryId: props.machineFilter?.categoryIds || [],
                subCategoryIds: props.machineFilter?.subCategoryIds || [],
                rentalTypeId: props.machineFilter?.rentalTypeId,
                minPrice: props.machineFilter?.minPrice,
                maxPrice: props.machineFilter?.maxPrice,
            },
            sortBy: order,
        };

        doGetMachines(filter)
            .then((result) => {
                if (result?.data && result?.data?.length > 0) {
                    setMachines(result as MachineListModelPagedDataModel);
                    setPageSize(result?.pageSize);
                    setTotalItems(result?.itemCount);
                    setIsHaveMachine(false);
                }
                if (result?.data && result?.data?.length === 0) {
                    setTotalItems(0);
                    setIsHaveMachine(true);
                }
            })
            .finally(() => {
                setLoading(false);
            });
    };

    useEffect(() => {
        if (props.machineFilter) {
            getFilteredMachines(props.machineFilter.activePage, props.machineFilter.order);
        }
    }, [props.machineFilter]);

    const placeholderImage = placeholder;
    return (
        <Row className={style.margin0 + " container"} style={{ height: "75%" }} justify="center">
            {props.showHeader ? (
                <Col style={{ marginBottom: "2rem" }} className="heading-3" md={12}>
                    <span>{t("products.header")}</span>
                </Col>
            ) : (
                <Col style={{ marginBottom: "1rem" }} md={24} xs={24}>
                    <Row style={{ alignItems: "center" }}>
                        <Col md={12}>
                            {/* <Row gutter={[8, 8]} align="middle">
                                <Col style={{ display: "flex" }}>
                                    <Info />
                                </Col>
                                <Col style={{ textAlign: "start" }}>
                                    <span style={{ fontWeight: 700 }}>{t("machine-detail.tax.info")}</span>
                                </Col>
                            </Row> */}
                        </Col>

                        <Col md={12} className={style.desktopVisible}>
                            {props.orderButton}
                        </Col>
                    </Row>
                </Col>
            )}

            {loading && <Spin size="large" tip="Lütfen Bekleyiniz" className={style.spinMobile} />}
            {!loading && totalItems === 0 ? (
                <Col md={12} xs={24}>
                    <InfoCard isProductPart header="Sonuç Bulunamadı" icon={<InfoLg></InfoLg>}></InfoCard>
                </Col>
            ) : (
                <Col md={24}>
                    <Row gutter={[0, 16]} style={{ marginBottom: "2rem", columnGap: "6px" }}>
                        {machines &&
                            machines?.data?.map((mac: any, index) => {
                                let fallbackUsed = false;
                                return (
                                    <ProductCard
                                        productCount={machines.data?.length ?? 1} // added for styling issue
                                        key={mac.id}
                                        id={mac.id}
                                        clickable
                                        head={mac.category}
                                        text={mac.subCategory}
                                        categoryId={mac.categoryId}
                                        subCategoryId={mac.subCategoryId}
                                        brandName={mac.brandName}
                                        brandOther={mac.brandOther}
                                        modelName={mac.modelName}
                                        modelYear={mac.modelYear}
                                        operatingWeight={mac.operatingWeight}
                                        operatingHeight={mac.operatingHeight}
                                        bomLength={mac.bomLength}
                                        carryingCapacity={mac.carryingCapacity}
                                        enginePower={mac.enginePower}
                                        fuelType={mac.fuelType}
                                        maxHeight={mac.maxHeight}
                                        maxPower={mac.maxPower}
                                        minPower={mac.minPower}
                                        nominalCapacity={mac.nominalCapacity}
                                        operationMileage={mac.operationMileage}
                                        spraySpeed={mac.spraySpeed}
                                        tankVolume={mac.tankVolume}
                                        volume={mac.volume}
                                        maxLoadCapacity={mac.maxLoadCapacity}
                                        operationHour={mac.operationHour}
                                        cameraResolution={mac.cameraResolution}
                                        flightTime={mac.flightTime}
                                        loadCapacity={mac.loadCapacity}
                                        hasOperator={mac.hasOperator}
                                        image={
                                            <img
                                                style={
                                                    mac.photoId !== 0
                                                        ? { width: "12rem", height: "12rem", objectFit: "cover", borderRadius: "15px" }
                                                        : { width: "12rem", height: "12rem", borderRadius: "15px", objectFit: "scale-down" }
                                                }
                                                src={
                                                    activeApiBaseUrl + "/document/machine-document/" + mac.photoId
                                                        ? activeApiBaseUrl + "/document/machine-document/" + mac.photoId
                                                        : placeholderImage
                                                }
                                                alt={mac.photoId}
                                                onError={(e) => {
                                                    if (!fallbackUsed) {
                                                        fallbackUsed = true;
                                                        e.currentTarget.src = placeholder; // Provide the path or URL of the fallback photo
                                                        e.currentTarget.style.width = "12rem";
                                                    }
                                                }}
                                            ></img>
                                        }
                                        daily={formatCurrency(mac.dailyPrice)}
                                        monthly={formatCurrency(mac.monthlyPrice)}
                                    ></ProductCard>
                                );
                            })}
                    </Row>
                    {props.showPagination && !loading && (
                        <Row justify="center">
                            <Pagination
                                defaultCurrent={props.machineFilter?.activePage ?? 1}
                                current={props.machineFilter?.activePage ?? 1}
                                pageSize={pageSize}
                                total={totalItems}
                                onChange={pageChange}
                                showSizeChanger={false}
                                responsive
                            ></Pagination>
                        </Row>
                    )}
                </Col>
            )}

            {props.showButton && (
                <Col>
                    <TertiaryButton
                        onClick={() => window.open(activeAppSiteUrl + "/rental-machines", "_self")}
                        style={{
                            borderColor: "#FFEBC3",
                            borderStyle: "solid",
                            borderWidth: "1px",
                        }}
                    >
                        {t("common.show-all")}
                    </TertiaryButton>
                </Col>
            )}
        </Row>
    );
};

export default ProductPart;
