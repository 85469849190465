/* tslint:disable */
/* eslint-disable */
/**
 * MachineGo P1 API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    BasicModel,
    BasicModelFromJSON,
    BasicModelFromJSONTyped,
    BasicModelToJSON,
} from './BasicModel';

/**
 * 
 * @export
 * @interface DistrictModel
 */
export interface DistrictModel {
    /**
     * 
     * @type {number}
     * @memberof DistrictModel
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof DistrictModel
     */
    name?: string | null;
    /**
     * 
     * @type {BasicModel}
     * @memberof DistrictModel
     */
    city?: BasicModel;
}

export function DistrictModelFromJSON(json: any): DistrictModel {
    return DistrictModelFromJSONTyped(json, false);
}

export function DistrictModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): DistrictModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'city': !exists(json, 'city') ? undefined : BasicModelFromJSON(json['city']),
    };
}

export function DistrictModelToJSON(value?: DistrictModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
        'city': BasicModelToJSON(value.city),
    };
}

