/* tslint:disable */
/* eslint-disable */
/**
 * MachineGo P1 API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    DocumentCategory,
    DocumentCategoryFromJSON,
    DocumentCategoryFromJSONTyped,
    DocumentCategoryToJSON,
} from './DocumentCategory';
import {
    DocumentModel,
    DocumentModelFromJSON,
    DocumentModelFromJSONTyped,
    DocumentModelToJSON,
} from './DocumentModel';

/**
 * 
 * @export
 * @interface MachineDocumentViewModel
 */
export interface MachineDocumentViewModel {
    /**
     * 
     * @type {DocumentModel}
     * @memberof MachineDocumentViewModel
     */
    document?: DocumentModel;
    /**
     * 
     * @type {DocumentCategory}
     * @memberof MachineDocumentViewModel
     */
    documentCategory?: DocumentCategory;
}

export function MachineDocumentViewModelFromJSON(json: any): MachineDocumentViewModel {
    return MachineDocumentViewModelFromJSONTyped(json, false);
}

export function MachineDocumentViewModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): MachineDocumentViewModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'document': !exists(json, 'document') ? undefined : DocumentModelFromJSON(json['document']),
        'documentCategory': !exists(json, 'documentCategory') ? undefined : DocumentCategoryFromJSON(json['documentCategory']),
    };
}

export function MachineDocumentViewModelToJSON(value?: MachineDocumentViewModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'document': DocumentModelToJSON(value.document),
        'documentCategory': DocumentCategoryToJSON(value.documentCategory),
    };
}

