import { useState } from "react";
import { Checkbox, Col, Input, Row } from "antd";
import { BasicModel } from "../../service";
import { t } from "../../translate";

type CheckBoxListProps = {
    data: BasicModel[];
    onItemChecked: (brandIds: number[]) => void;
};

const { Search } = Input;

export const CheckBoxList = (props: CheckBoxListProps) => {
    const [searchText, setSearchText] = useState<string>('');
    const [selectedCheckboxes, setSelectedCheckboxes] = useState<number[]>([]);
    
    const filteredData = props.data.filter((val: BasicModel) =>
        val.name?.toLowerCase().includes(searchText.toLowerCase())
    );

    const handleCheckboxChange = (id: number) => {
        const selectedIndex = selectedCheckboxes.indexOf(id);

        const newSelectedCheckboxes = [...selectedCheckboxes];
        if (selectedIndex === -1 && id !== 0) {
            newSelectedCheckboxes.push(id);
        } else {
            newSelectedCheckboxes.splice(selectedIndex, 1);
        }
        setSelectedCheckboxes(newSelectedCheckboxes);
        props.onItemChecked(newSelectedCheckboxes);
    };

    return (
        <div>
            <Search
                style={{ marginBottom: "1rem" }}
                value={searchText}
                placeholder={t("common.search")}
                onChange={(e) => setSearchText(e.target.value)}
            />
            <div style={{ maxHeight: 300, overflowY: "auto" }}>
                <Row>
                    {filteredData.map((item: BasicModel) => (
                        <Col key={item.id} md={24} xs={24}>
                            <Checkbox onChange={() => handleCheckboxChange(item.id ?? 0)} checked={selectedCheckboxes.includes(item.id ?? 0)}>
                                {item.name}
                            </Checkbox>
                        </Col>
                    ))}
                </Row>
            </div>
        </div>
    );
};