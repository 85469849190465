import { Col, Row, Space } from "antd";
import BorderedBoxContainer from "../containers/bordered-box-container";
import { CheckCircleOutlined } from "@ant-design/icons";
import { ColProps } from "antd/es/grid/col";
import { OfferFormBidViewModel, OfferFormRenterBidViewModel } from "../../service";
import { t } from "../../translate";
import styles from "../styles/payment.module.scss";
import BasicModelViewer from "./basic-model-viewer";
import LightTitle from "./light-title";
import { RoleType } from "../../store/global-atoms";

type CheckboxReviewerProps = {
    checkboxChecked?: boolean;
    checkboxLabel?: string | null;
    bid?: OfferFormBidViewModel;
    renterbid?: OfferFormRenterBidViewModel;
    inputVisible?: boolean;
    clickable?: boolean;
    contextId?: number;
    userRole: RoleType;
    offerStatus?: string | null;
    onCheckboxChange?: (selected: boolean, contextId?: number) => void;
} & ColProps;

const CheckboxOffer = (props: CheckboxReviewerProps) => {
    const passiveColor = "#CCCCCC";
    const tickClicked = () => {
        if (props.onCheckboxChange) {
            if (props.checkboxChecked === undefined) props.onCheckboxChange(true, props.contextId);
            else props.onCheckboxChange(!props.checkboxChecked, props.contextId);
        }
    };
    return (
        <BorderedBoxContainer style={{ marginBottom: "1rem" }} xs={props.xs || 24} md={props.md || 24} {...props}>
            <Space
                direction="vertical"
                size={"large"}
                style={{
                    width: "100%",
                    height: "100%",
                    justifyContent: "space-between",
                }}
            >
                <div
                    style={{
                        display: "flex",
                        height: "100%",
                        justifyContent: "space-between",
                        alignItems: "stretch",
                    }}
                >
                    <span
                        style={{
                            color: props.checkboxChecked ? "#282828" : "#cccccc",
                        }}
                    >
                        {props.checkboxLabel}
                    </span>
                    <CheckCircleOutlined
                        onClick={props.clickable ? tickClicked : undefined}
                        style={{
                            fontSize: "1.5rem",
                            cursor: props.clickable ? "pointer" : "default",
                            color: props.checkboxChecked ? "#FEB111" : passiveColor,
                        }}
                    />
                </div>
                {props.inputVisible && (
                    <div>
                        {props.offerStatus === "Offered" && (
                            <div style={{ marginBottom: 10 }}>
                                {" "}
                                <LightTitle>{t("offer.view.OfferedBidView")}</LightTitle>
                            </div>
                        )}
                        <Row gutter={[16, 16]} justify="space-between">
                            <Col md={12} xs={12}>
                                <Row justify="space-between">
                                    <Col
                                        style={{ display: "flex", alignItems: "center", padding: "0" }}
                                        md={12}
                                        className={styles.labelStyle}
                                    >
                                        {t("offers.view.period")}
                                    </Col>
                                    <Col style={{ display: "flex", alignItems: "center" }} md={12} className={styles.priceStyle}>
                                        {props?.bid?.paymentPeriod?.name}
                                    </Col>
                                </Row>
                            </Col>
                            <Col md={12} xs={12}>
                                <Row justify="space-between">
                                    <Col
                                        md={12}
                                        className={styles.labelStyle}
                                        style={{ display: "flex", alignItems: "center", padding: "0" }}
                                    >
                                        {t("offers.view.price")}
                                    </Col>
                                    <Col md={12} className={styles.priceStyle} style={{ display: "flex", alignItems: "center" }}>
                                        {props.userRole === "customer"
                                            ? props?.renterbid?.renterPeriodPayment
                                            : props.bid?.lessorPeriodPayment
                                            ? props.bid.lessorPeriodPayment
                                            : props.bid?.bid}{" "}
                                        {props?.bid?.currency?.name}
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                        <Row style={{ display: "flex", alignItems: "center" }} gutter={[16, 16]} justify="space-between">
                            <BasicModelViewer
                                xs={24}
                                md={24}
                                horizontal
                                objKey={t("offers.view.prepayAmount")}
                                value={props?.bid?.advancePayment || " - "}
                                suffix={props?.bid?.currency?.name?.toString()}
                            />
                            <BasicModelViewer
                                xs={24}
                                md={24}
                                horizontal
                                objKey={t("offers.view.postpayAmount")}
                                value={(props?.bid?.total || 0) - (props?.bid?.advancePayment || 0) || " - "}
                                suffix={props?.bid?.currency?.name?.toString()}
                            />
                            <BasicModelViewer
                                xs={24}
                                md={24}
                                horizontal
                                objKey={t("offers.view.totalAmount")}
                                value={props?.bid?.total || " - "}
                                suffix={props?.bid?.currency?.name?.toString()}
                            />
                        </Row>
                    </div>
                )}
            </Space>
        </BorderedBoxContainer>
    );
};
export default CheckboxOffer;
