/* tslint:disable */
/* eslint-disable */
/**
 * MachineGo P1 API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface RequestFormAdditionalServices
 */
export interface RequestFormAdditionalServices {
    /**
     * 
     * @type {boolean}
     * @memberof RequestFormAdditionalServices
     */
    hasOperator?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof RequestFormAdditionalServices
     */
    operatorCostIncluded?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof RequestFormAdditionalServices
     */
    operatorInsuranceIncluded?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof RequestFormAdditionalServices
     */
    fuelIncluded?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof RequestFormAdditionalServices
     */
    shipingIncluded?: boolean;
    /**
     * 
     * @type {number}
     * @memberof RequestFormAdditionalServices
     */
    operatorExperienceYear?: number | null;
}

export function RequestFormAdditionalServicesFromJSON(json: any): RequestFormAdditionalServices {
    return RequestFormAdditionalServicesFromJSONTyped(json, false);
}

export function RequestFormAdditionalServicesFromJSONTyped(json: any, ignoreDiscriminator: boolean): RequestFormAdditionalServices {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'hasOperator': !exists(json, 'hasOperator') ? undefined : json['hasOperator'],
        'operatorCostIncluded': !exists(json, 'operatorCostIncluded') ? undefined : json['operatorCostIncluded'],
        'operatorInsuranceIncluded': !exists(json, 'operatorInsuranceIncluded') ? undefined : json['operatorInsuranceIncluded'],
        'fuelIncluded': !exists(json, 'fuelIncluded') ? undefined : json['fuelIncluded'],
        'shipingIncluded': !exists(json, 'shipingIncluded') ? undefined : json['shipingIncluded'],
        'operatorExperienceYear': !exists(json, 'operatorExperienceYear') ? undefined : json['operatorExperienceYear'],
    };
}

export function RequestFormAdditionalServicesToJSON(value?: RequestFormAdditionalServices | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'hasOperator': value.hasOperator,
        'operatorCostIncluded': value.operatorCostIncluded,
        'operatorInsuranceIncluded': value.operatorInsuranceIncluded,
        'fuelIncluded': value.fuelIncluded,
        'shipingIncluded': value.shipingIncluded,
        'operatorExperienceYear': value.operatorExperienceYear,
    };
}

