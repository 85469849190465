import { Col, Row } from "antd";
import React from "react";
import  style from "./info-box.module.scss";
type InfoBoxProps = {
    head:string,
    text:string;
    children?:any;
}


const InfoBox = (props:InfoBoxProps) => {
    return (
        <Row gutter={[16,8]}  className={style.boxContainer}>
            <Col className={style.head}>{props.head}</Col>
            <Col className={style.text}>{props.text}</Col>
            {props.children}
        </Row>
    )
}

export default InfoBox;