/* tslint:disable */
/* eslint-disable */
/**
 * MachineGo P1 API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    DocumentCategory,
    DocumentCategoryFromJSON,
    DocumentCategoryFromJSONTyped,
    DocumentCategoryToJSON,
} from './DocumentCategory';

/**
 * 
 * @export
 * @interface MachineDocumentCreateModel
 */
export interface MachineDocumentCreateModel {
    /**
     * 
     * @type {number}
     * @memberof MachineDocumentCreateModel
     */
    documentId?: number;
    /**
     * 
     * @type {DocumentCategory}
     * @memberof MachineDocumentCreateModel
     */
    documentCategory?: DocumentCategory;
}

export function MachineDocumentCreateModelFromJSON(json: any): MachineDocumentCreateModel {
    return MachineDocumentCreateModelFromJSONTyped(json, false);
}

export function MachineDocumentCreateModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): MachineDocumentCreateModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'documentId': !exists(json, 'documentId') ? undefined : json['documentId'],
        'documentCategory': !exists(json, 'documentCategory') ? undefined : DocumentCategoryFromJSON(json['documentCategory']),
    };
}

export function MachineDocumentCreateModelToJSON(value?: MachineDocumentCreateModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'documentId': value.documentId,
        'documentCategory': DocumentCategoryToJSON(value.documentCategory),
    };
}

