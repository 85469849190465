import ModalLayout, { ModalLayoutProps } from "../components/layouts/modal-layout";
import { t } from "../translate";
import { CompanyBasicModel, LessorCompanyAuthorizedSellerInfoModel } from "../service/models";
import styles from "./request-summary.module.scss";
import { RoleType } from "../store/global-atoms";
import { Divider } from "antd";

type CompanyDetailModalProps = {
    reqid?: number;
    details?: CompanyBasicModel;
    approved?: boolean;
    offerid?: number;
    role: RoleType;
    authorisedLessors: LessorCompanyAuthorizedSellerInfoModel;
} & ModalLayoutProps;
const CompanyDetailModal = (props: CompanyDetailModalProps) => {
    //TODO: Bu modaldaki stiller güncellenecek. Bu haliyle yayına çıkmayalım.
    return (
        <ModalLayout {...props}>
            <h2 style={{ textAlign: "center" }}>
                {props.role === "customer" ? t("offer.deal.lessorCompanyView.title") : t("offer.deal.renterCompanyView.title")}
            </h2>

                {
                    props.role === 'customer' ? 
                        props.authorisedLessors.authorisedLessors?.map((lessor, index) => {
                            return (
                                <div>
                                    <div className={styles.row}>
                                        <span className={styles.successLabel}>{t("offer.deal.companyview.companyName")}</span>
                                        <span className={styles.successValue}>{props.details?.companyName}</span>
                                    </div>
                                    <div className={styles.row}>
                                        <span className={styles.successLabel}>{t("offer.deal.companyview.name")}</span>
                                        <span className={styles.successValue}>{(lessor?.name ?? '') + " " + (lessor.lastname ?? '')}</span>
                                    </div>
                                    <div className={styles.row}>
                                        <span className={styles.successLabel}>{t("offer.deal.companyview.phone")}</span>
                                        <span className={styles.successValue}>{lessor.phone}</span>
                                    </div>
                                    <div className={styles.row}>
                                        <span className={styles.successLabel}>{t("offer.deal.companyview.email")}</span>
                                        <span className={styles.successValue}>
                                            <strong>{lessor.email}</strong>
                                        </span>
                                    </div>
                                    {index <((props.authorisedLessors.authorisedLessors?.length ?? 0) - 1) && <Divider />}
                                </div>
                            );
                        })
                    :
                    <div>
                        <div className={styles.row}>
                            <span className={styles.successLabel}>{t("offer.deal.companyview.companyName")}</span>
                            <span className={styles.successValue}>{props.details?.companyName}</span>
                        </div>
                        <div className={styles.row}>
                            <span className={styles.successLabel}>{t("offer.deal.companyview.name")}</span>
                            <span className={styles.successValue}>{props.details?.name + " " + props.details?.lastname}</span>
                        </div>
                        <div className={styles.row}>
                            <span className={styles.successLabel}>{t("offer.deal.companyview.phone")}</span>
                            <span className={styles.successValue}>{props.details?.phone}</span>
                        </div>
                        <div className={styles.row}>
                            <span className={styles.successLabel}>{t("offer.deal.companyview.email")}</span>
                            <span className={styles.successValue}>
                                <strong>{props.details?.email}</strong>
                            </span>
                        </div>
                    </div>
                }

        </ModalLayout>
    );
};
export default CompanyDetailModal;
