/* tslint:disable */
/* eslint-disable */
/**
 * MachineGo P1 API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    BasicModel,
    BasicModelFromJSON,
    BasicModelFromJSONTyped,
    BasicModelToJSON,
} from './BasicModel';
import {
    DistrictModel,
    DistrictModelFromJSON,
    DistrictModelFromJSONTyped,
    DistrictModelToJSON,
} from './DistrictModel';
import {
    DocumentModel,
    DocumentModelFromJSON,
    DocumentModelFromJSONTyped,
    DocumentModelToJSON,
} from './DocumentModel';
import {
    OfferFormRenterViewModel,
    OfferFormRenterViewModelFromJSON,
    OfferFormRenterViewModelFromJSONTyped,
    OfferFormRenterViewModelToJSON,
} from './OfferFormRenterViewModel';
import {
    RequestFormAdditionalServices,
    RequestFormAdditionalServicesFromJSON,
    RequestFormAdditionalServicesFromJSONTyped,
    RequestFormAdditionalServicesToJSON,
} from './RequestFormAdditionalServices';
import {
    RequestFormCommentViewModel,
    RequestFormCommentViewModelFromJSON,
    RequestFormCommentViewModelFromJSONTyped,
    RequestFormCommentViewModelToJSON,
} from './RequestFormCommentViewModel';
import {
    RequestFormMachineFeatures,
    RequestFormMachineFeaturesFromJSON,
    RequestFormMachineFeaturesFromJSONTyped,
    RequestFormMachineFeaturesToJSON,
} from './RequestFormMachineFeatures';
import {
    SubCategoryModel,
    SubCategoryModelFromJSON,
    SubCategoryModelFromJSONTyped,
    SubCategoryModelToJSON,
} from './SubCategoryModel';

/**
 * 
 * @export
 * @interface RequestFormViewModel
 */
export interface RequestFormViewModel {
    /**
     * 
     * @type {number}
     * @memberof RequestFormViewModel
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof RequestFormViewModel
     */
    name?: string | null;
    /**
     * 
     * @type {string}
     * @memberof RequestFormViewModel
     */
    lastName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof RequestFormViewModel
     */
    email?: string | null;
    /**
     * 
     * @type {string}
     * @memberof RequestFormViewModel
     */
    phone?: string | null;
    /**
     * 
     * @type {string}
     * @memberof RequestFormViewModel
     */
    companyName?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof RequestFormViewModel
     */
    isUserActive?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof RequestFormViewModel
     */
    dl?: boolean | null;
    /**
     * 
     * @type {SubCategoryModel}
     * @memberof RequestFormViewModel
     */
    subCategory?: SubCategoryModel;
    /**
     * 
     * @type {number}
     * @memberof RequestFormViewModel
     */
    machineId?: number | null;
    /**
     * 
     * @type {string}
     * @memberof RequestFormViewModel
     */
    subCategoryOther?: string | null;
    /**
     * 
     * @type {Array<BasicModel>}
     * @memberof RequestFormViewModel
     */
    attachments?: Array<BasicModel> | null;
    /**
     * 
     * @type {string}
     * @memberof RequestFormViewModel
     */
    attachmentsOther?: string | null;
    /**
     * 
     * @type {DistrictModel}
     * @memberof RequestFormViewModel
     */
    district?: DistrictModel;
    /**
     * 
     * @type {Date}
     * @memberof RequestFormViewModel
     */
    requestDateFrom?: Date;
    /**
     * 
     * @type {Date}
     * @memberof RequestFormViewModel
     */
    requestDateTo?: Date;
    /**
     * 
     * @type {number}
     * @memberof RequestFormViewModel
     */
    machineCount?: number;
    /**
     * 
     * @type {number}
     * @memberof RequestFormViewModel
     */
    dailyWorkingTime?: number;
    /**
     * 
     * @type {RequestFormMachineFeatures}
     * @memberof RequestFormViewModel
     */
    machineFeatures?: RequestFormMachineFeatures;
    /**
     * 
     * @type {RequestFormAdditionalServices}
     * @memberof RequestFormViewModel
     */
    additionalServices?: RequestFormAdditionalServices;
    /**
     * 
     * @type {string}
     * @memberof RequestFormViewModel
     */
    status?: string | null;
    /**
     * 
     * @type {string}
     * @memberof RequestFormViewModel
     */
    description?: string | null;
    /**
     * 
     * @type {BasicModel}
     * @memberof RequestFormViewModel
     */
    fuelType?: BasicModel;
    /**
     * 
     * @type {Date}
     * @memberof RequestFormViewModel
     */
    creationDate?: Date;
    /**
     * 
     * @type {Array<DocumentModel>}
     * @memberof RequestFormViewModel
     */
    documents?: Array<DocumentModel> | null;
    /**
     * 
     * @type {Array<OfferFormRenterViewModel>}
     * @memberof RequestFormViewModel
     */
    offerForms?: Array<OfferFormRenterViewModel> | null;
    /**
     * 
     * @type {Array<RequestFormCommentViewModel>}
     * @memberof RequestFormViewModel
     */
    requestFormComments?: Array<RequestFormCommentViewModel> | null;
}

export function RequestFormViewModelFromJSON(json: any): RequestFormViewModel {
    return RequestFormViewModelFromJSONTyped(json, false);
}

export function RequestFormViewModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): RequestFormViewModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'lastName': !exists(json, 'lastName') ? undefined : json['lastName'],
        'email': !exists(json, 'email') ? undefined : json['email'],
        'phone': !exists(json, 'phone') ? undefined : json['phone'],
        'companyName': !exists(json, 'companyName') ? undefined : json['companyName'],
        'isUserActive': !exists(json, 'isUserActive') ? undefined : json['isUserActive'],
        'dl': !exists(json, 'dl') ? undefined : json['dl'],
        'subCategory': !exists(json, 'subCategory') ? undefined : SubCategoryModelFromJSON(json['subCategory']),
        'machineId': !exists(json, 'machineId') ? undefined : json['machineId'],
        'subCategoryOther': !exists(json, 'subCategoryOther') ? undefined : json['subCategoryOther'],
        'attachments': !exists(json, 'attachments') ? undefined : (json['attachments'] === null ? null : (json['attachments'] as Array<any>).map(BasicModelFromJSON)),
        'attachmentsOther': !exists(json, 'attachmentsOther') ? undefined : json['attachmentsOther'],
        'district': !exists(json, 'district') ? undefined : DistrictModelFromJSON(json['district']),
        'requestDateFrom': !exists(json, 'requestDateFrom') ? undefined : (new Date(json['requestDateFrom'])),
        'requestDateTo': !exists(json, 'requestDateTo') ? undefined : (new Date(json['requestDateTo'])),
        'machineCount': !exists(json, 'machineCount') ? undefined : json['machineCount'],
        'dailyWorkingTime': !exists(json, 'dailyWorkingTime') ? undefined : json['dailyWorkingTime'],
        'machineFeatures': !exists(json, 'machineFeatures') ? undefined : RequestFormMachineFeaturesFromJSON(json['machineFeatures']),
        'additionalServices': !exists(json, 'additionalServices') ? undefined : RequestFormAdditionalServicesFromJSON(json['additionalServices']),
        'status': !exists(json, 'status') ? undefined : json['status'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'fuelType': !exists(json, 'fuelType') ? undefined : BasicModelFromJSON(json['fuelType']),
        'creationDate': !exists(json, 'creationDate') ? undefined : (new Date(json['creationDate'])),
        'documents': !exists(json, 'documents') ? undefined : (json['documents'] === null ? null : (json['documents'] as Array<any>).map(DocumentModelFromJSON)),
        'offerForms': !exists(json, 'offerForms') ? undefined : (json['offerForms'] === null ? null : (json['offerForms'] as Array<any>).map(OfferFormRenterViewModelFromJSON)),
        'requestFormComments': !exists(json, 'requestFormComments') ? undefined : (json['requestFormComments'] === null ? null : (json['requestFormComments'] as Array<any>).map(RequestFormCommentViewModelFromJSON)),
    };
}

export function RequestFormViewModelToJSON(value?: RequestFormViewModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
        'lastName': value.lastName,
        'email': value.email,
        'phone': value.phone,
        'companyName': value.companyName,
        'isUserActive': value.isUserActive,
        'dl': value.dl,
        'subCategory': SubCategoryModelToJSON(value.subCategory),
        'machineId': value.machineId,
        'subCategoryOther': value.subCategoryOther,
        'attachments': value.attachments === undefined ? undefined : (value.attachments === null ? null : (value.attachments as Array<any>).map(BasicModelToJSON)),
        'attachmentsOther': value.attachmentsOther,
        'district': DistrictModelToJSON(value.district),
        'requestDateFrom': value.requestDateFrom === undefined ? undefined : (value.requestDateFrom.toISOString()),
        'requestDateTo': value.requestDateTo === undefined ? undefined : (value.requestDateTo.toISOString()),
        'machineCount': value.machineCount,
        'dailyWorkingTime': value.dailyWorkingTime,
        'machineFeatures': RequestFormMachineFeaturesToJSON(value.machineFeatures),
        'additionalServices': RequestFormAdditionalServicesToJSON(value.additionalServices),
        'status': value.status,
        'description': value.description,
        'fuelType': BasicModelToJSON(value.fuelType),
        'creationDate': value.creationDate === undefined ? undefined : (value.creationDate.toISOString()),
        'documents': value.documents === undefined ? undefined : (value.documents === null ? null : (value.documents as Array<any>).map(DocumentModelToJSON)),
        'offerForms': value.offerForms === undefined ? undefined : (value.offerForms === null ? null : (value.offerForms as Array<any>).map(OfferFormRenterViewModelToJSON)),
        'requestFormComments': value.requestFormComments === undefined ? undefined : (value.requestFormComments === null ? null : (value.requestFormComments as Array<any>).map(RequestFormCommentViewModelToJSON)),
    };
}

