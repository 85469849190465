import { Col, Row } from "antd";
import { EventHandler } from "react";
import PrimaryButton from "../components/atomics/primary-button";
import ModalLayout from "../components/layouts/modal-layout";
import { t } from "../translate";

type AgreementReadModalProps = {
    show: boolean;
    agreementSrc: string;
    title?: string;
    onButtonClick: () => void;
    onCloseClick: EventHandler<any>;
};

const AgreementReadModal = (props: AgreementReadModalProps) => {
    return (
        <ModalLayout show={props.show} onCloseClick={props.onCloseClick}>
            <Row gutter={[16,16]} align="middle" justify="center">
                <Col md={24} xs={24}>
                    <iframe width={"100%"} height={"500vh"} title={props.title} src={props.agreementSrc} />
                </Col>
                <Col xs={20} md={8}>
                    <PrimaryButton onClick={props.onButtonClick}>{t("common.iapproved")}</PrimaryButton>
                </Col>
            </Row>
        </ModalLayout>
    );
    
}

export default AgreementReadModal;