/* tslint:disable */
/* eslint-disable */
/**
 * MachineGo P1 API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface LessorCompanyAgreementsViewModel
 */
export interface LessorCompanyAgreementsViewModel {
    /**
     * 
     * @type {string}
     * @memberof LessorCompanyAgreementsViewModel
     */
    agreementVersion?: string | null;
    /**
     * 
     * @type {string}
     * @memberof LessorCompanyAgreementsViewModel
     */
    kvkkVersion?: string | null;
    /**
     * 
     * @type {string}
     * @memberof LessorCompanyAgreementsViewModel
     */
    kvkkInfoVersion?: string | null;
    /**
     * 
     * @type {string}
     * @memberof LessorCompanyAgreementsViewModel
     */
    ipazVersion?: string | null;
}

export function LessorCompanyAgreementsViewModelFromJSON(json: any): LessorCompanyAgreementsViewModel {
    return LessorCompanyAgreementsViewModelFromJSONTyped(json, false);
}

export function LessorCompanyAgreementsViewModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): LessorCompanyAgreementsViewModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'agreementVersion': !exists(json, 'agreementVersion') ? undefined : json['agreementVersion'],
        'kvkkVersion': !exists(json, 'kvkkVersion') ? undefined : json['kvkkVersion'],
        'kvkkInfoVersion': !exists(json, 'kvkkInfoVersion') ? undefined : json['kvkkInfoVersion'],
        'ipazVersion': !exists(json, 'ipazVersion') ? undefined : json['ipazVersion'],
    };
}

export function LessorCompanyAgreementsViewModelToJSON(value?: LessorCompanyAgreementsViewModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'agreementVersion': value.agreementVersion,
        'kvkkVersion': value.kvkkVersion,
        'kvkkInfoVersion': value.kvkkInfoVersion,
        'ipazVersion': value.ipazVersion,
    };
}

