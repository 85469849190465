import { Collapse } from "antd";
import styles from "../styles/accordion.module.scss";
import { ReactComponent as Plus } from "../assets/plus.svg";
import { ReactComponent as Minus } from "../assets/minus.svg";
import { useEffect, useState } from "react";

type AccordionItems = {
    title?: string | null;
    children: any;
    description?: string;
    imageUrl?: string;
    defaultOpen?: boolean;
    key: number;
};
type AccordionProps = {
    items?: AccordionItems[];
    activeKey?: number;
};

const Accordion = (props: AccordionProps) => {
    const { Panel } = Collapse;
    const [activeKey, setActiveKey ] = useState<number|undefined>();
    const children = props.items?.map((item) => {
        const Header = (
            <div className={styles.accordionItemHeader}>
                {item.imageUrl && <img src={"/" + item.imageUrl} alt={item.imageUrl} />}
                <div>
                    <h4>{item.title}</h4>
                    {item.description && <span>{item.description}</span>}
                </div>
            </div>
        );
        if (item) {
            return (
                <Panel key={item.key} header={Header} className={styles.accordionItemPanel}>
                    {item.children}
                </Panel>
            );
        } else return <></>;
    });
    useEffect(()=>{
        setActiveKey(props.activeKey);
    },[props.activeKey])
    const onCollapseChanged = (data:string|string[]) => {
        if(data === "")
            setActiveKey(undefined);
        else if(typeof data === "string"){
            const cid = Number.parseInt(data);
            setActiveKey(cid);
        }
    }

    return (
        <Collapse
            expandIconPosition="right"
            bordered={false}
            onChange={onCollapseChanged}
            accordion
            activeKey={activeKey}
            className={styles.accordionCollapse}
            expandIcon={(panelProps) => {
                if (panelProps.isActive) return (
                    <Minus
                        onClick={() => {
                            setActiveKey(undefined);
                        }}
                        className={styles.expandIcons}
                    />
                );
                else return <Plus className={styles.expandIcons} />;
            }}
        >
            {children}
        </Collapse>
    );
};

export default Accordion;
