/* tslint:disable */
/* eslint-disable */
/**
 * MachineGo P1 API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    BasicModel,
    BasicModelFromJSON,
    BasicModelFromJSONTyped,
    BasicModelToJSON,
} from './BasicModel';
import {
    SubCategoryModel,
    SubCategoryModelFromJSON,
    SubCategoryModelFromJSONTyped,
    SubCategoryModelToJSON,
} from './SubCategoryModel';

/**
 * 
 * @export
 * @interface LessorCompanyRequestViewModel
 */
export interface LessorCompanyRequestViewModel {
    /**
     * 
     * @type {number}
     * @memberof LessorCompanyRequestViewModel
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof LessorCompanyRequestViewModel
     */
    companyName?: string | null;
    /**
     * 
     * @type {number}
     * @memberof LessorCompanyRequestViewModel
     */
    offerCount?: number;
    /**
     * 
     * @type {number}
     * @memberof LessorCompanyRequestViewModel
     */
    acceptedOfferCount?: number;
    /**
     * 
     * @type {Date}
     * @memberof LessorCompanyRequestViewModel
     */
    lastOfferDate?: Date | null;
    /**
     * 
     * @type {boolean}
     * @memberof LessorCompanyRequestViewModel
     */
    serviceRegionMatched?: boolean;
    /**
     * 
     * @type {Array<BasicModel>}
     * @memberof LessorCompanyRequestViewModel
     */
    serviceRegions?: Array<BasicModel> | null;
    /**
     * 
     * @type {Array<SubCategoryModel>}
     * @memberof LessorCompanyRequestViewModel
     */
    machines?: Array<SubCategoryModel> | null;
}

export function LessorCompanyRequestViewModelFromJSON(json: any): LessorCompanyRequestViewModel {
    return LessorCompanyRequestViewModelFromJSONTyped(json, false);
}

export function LessorCompanyRequestViewModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): LessorCompanyRequestViewModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'companyName': !exists(json, 'companyName') ? undefined : json['companyName'],
        'offerCount': !exists(json, 'offerCount') ? undefined : json['offerCount'],
        'acceptedOfferCount': !exists(json, 'acceptedOfferCount') ? undefined : json['acceptedOfferCount'],
        'lastOfferDate': !exists(json, 'lastOfferDate') ? undefined : (json['lastOfferDate'] === null ? null : new Date(json['lastOfferDate'])),
        'serviceRegionMatched': !exists(json, 'serviceRegionMatched') ? undefined : json['serviceRegionMatched'],
        'serviceRegions': !exists(json, 'serviceRegions') ? undefined : (json['serviceRegions'] === null ? null : (json['serviceRegions'] as Array<any>).map(BasicModelFromJSON)),
        'machines': !exists(json, 'machines') ? undefined : (json['machines'] === null ? null : (json['machines'] as Array<any>).map(SubCategoryModelFromJSON)),
    };
}

export function LessorCompanyRequestViewModelToJSON(value?: LessorCompanyRequestViewModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'companyName': value.companyName,
        'offerCount': value.offerCount,
        'acceptedOfferCount': value.acceptedOfferCount,
        'lastOfferDate': value.lastOfferDate === undefined ? undefined : (value.lastOfferDate === null ? null : value.lastOfferDate.toISOString()),
        'serviceRegionMatched': value.serviceRegionMatched,
        'serviceRegions': value.serviceRegions === undefined ? undefined : (value.serviceRegions === null ? null : (value.serviceRegions as Array<any>).map(BasicModelToJSON)),
        'machines': value.machines === undefined ? undefined : (value.machines === null ? null : (value.machines as Array<any>).map(SubCategoryModelToJSON)),
    };
}

