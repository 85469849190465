/* tslint:disable */
/* eslint-disable */
/**
 * MachineGo P1 API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    RequestStatus,
    RequestStatusFromJSON,
    RequestStatusFromJSONTyped,
    RequestStatusToJSON,
} from './RequestStatus';

/**
 * 
 * @export
 * @interface RequestFormSearchModel
 */
export interface RequestFormSearchModel {
    /**
     * 
     * @type {number}
     * @memberof RequestFormSearchModel
     */
    pageNo?: number;
    /**
     * 
     * @type {number}
     * @memberof RequestFormSearchModel
     */
    pageSize?: number;
    /**
     * 
     * @type {number}
     * @memberof RequestFormSearchModel
     */
    readonly rowIndex?: number;
    /**
     * 
     * @type {number}
     * @memberof RequestFormSearchModel
     */
    id?: number | null;
    /**
     * 
     * @type {number}
     * @memberof RequestFormSearchModel
     */
    categoryId?: number | null;
    /**
     * 
     * @type {number}
     * @memberof RequestFormSearchModel
     */
    subCategoryId?: number | null;
    /**
     * 
     * @type {number}
     * @memberof RequestFormSearchModel
     */
    renterCompanyId?: number | null;
    /**
     * 
     * @type {Date}
     * @memberof RequestFormSearchModel
     */
    creationDateFrom?: Date | null;
    /**
     * 
     * @type {Date}
     * @memberof RequestFormSearchModel
     */
    creationDateTo?: Date | null;
    /**
     * 
     * @type {Date}
     * @memberof RequestFormSearchModel
     */
    requestDateFrom?: Date | null;
    /**
     * 
     * @type {Date}
     * @memberof RequestFormSearchModel
     */
    requestDateTo?: Date | null;
    /**
     * 
     * @type {number}
     * @memberof RequestFormSearchModel
     */
    districtId?: number | null;
    /**
     * 
     * @type {number}
     * @memberof RequestFormSearchModel
     */
    cityId?: number | null;
    /**
     * 
     * @type {string}
     * @memberof RequestFormSearchModel
     */
    nameSurname?: string | null;
    /**
     * 
     * @type {string}
     * @memberof RequestFormSearchModel
     */
    companyName?: string | null;
    /**
     * 
     * @type {Array<RequestStatus>}
     * @memberof RequestFormSearchModel
     */
    status?: Array<RequestStatus> | null;
}

export function RequestFormSearchModelFromJSON(json: any): RequestFormSearchModel {
    return RequestFormSearchModelFromJSONTyped(json, false);
}

export function RequestFormSearchModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): RequestFormSearchModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'pageNo': !exists(json, 'pageNo') ? undefined : json['pageNo'],
        'pageSize': !exists(json, 'pageSize') ? undefined : json['pageSize'],
        'rowIndex': !exists(json, 'rowIndex') ? undefined : json['rowIndex'],
        'id': !exists(json, 'id') ? undefined : json['id'],
        'categoryId': !exists(json, 'categoryId') ? undefined : json['categoryId'],
        'subCategoryId': !exists(json, 'subCategoryId') ? undefined : json['subCategoryId'],
        'renterCompanyId': !exists(json, 'renterCompanyId') ? undefined : json['renterCompanyId'],
        'creationDateFrom': !exists(json, 'creationDateFrom') ? undefined : (json['creationDateFrom'] === null ? null : new Date(json['creationDateFrom'])),
        'creationDateTo': !exists(json, 'creationDateTo') ? undefined : (json['creationDateTo'] === null ? null : new Date(json['creationDateTo'])),
        'requestDateFrom': !exists(json, 'requestDateFrom') ? undefined : (json['requestDateFrom'] === null ? null : new Date(json['requestDateFrom'])),
        'requestDateTo': !exists(json, 'requestDateTo') ? undefined : (json['requestDateTo'] === null ? null : new Date(json['requestDateTo'])),
        'districtId': !exists(json, 'districtId') ? undefined : json['districtId'],
        'cityId': !exists(json, 'cityId') ? undefined : json['cityId'],
        'nameSurname': !exists(json, 'nameSurname') ? undefined : json['nameSurname'],
        'companyName': !exists(json, 'companyName') ? undefined : json['companyName'],
        'status': !exists(json, 'status') ? undefined : (json['status'] === null ? null : (json['status'] as Array<any>).map(RequestStatusFromJSON)),
    };
}

export function RequestFormSearchModelToJSON(value?: RequestFormSearchModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'pageNo': value.pageNo,
        'pageSize': value.pageSize,
        'id': value.id,
        'categoryId': value.categoryId,
        'subCategoryId': value.subCategoryId,
        'renterCompanyId': value.renterCompanyId,
        'creationDateFrom': value.creationDateFrom === undefined ? undefined : (value.creationDateFrom === null ? null : value.creationDateFrom.toISOString()),
        'creationDateTo': value.creationDateTo === undefined ? undefined : (value.creationDateTo === null ? null : value.creationDateTo.toISOString()),
        'requestDateFrom': value.requestDateFrom === undefined ? undefined : (value.requestDateFrom === null ? null : value.requestDateFrom.toISOString()),
        'requestDateTo': value.requestDateTo === undefined ? undefined : (value.requestDateTo === null ? null : value.requestDateTo.toISOString()),
        'districtId': value.districtId,
        'cityId': value.cityId,
        'nameSurname': value.nameSurname,
        'companyName': value.companyName,
        'status': value.status === undefined ? undefined : (value.status === null ? null : (value.status as Array<any>).map(RequestStatusToJSON)),
    };
}

