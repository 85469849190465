import { t } from "../translate";
import PrimaryButton from "../components/atomics/primary-button";
import ModalLayout, { ModalLayoutProps } from "../components/layouts/modal-layout";
import styles from "./request-summary.module.scss";
import { CityModel, RenterCompanyPaymentUpdateModel, RenterCompanyPaymentInfoViewModel } from "../service";
import { Col, Form, Input, Row, Select } from "antd";
import { useEffect } from "react";

type UpdatePaymentModalProps = {
    onUpdate: (profileData: RenterCompanyPaymentUpdateModel | undefined) => void;
    paymentData: RenterCompanyPaymentInfoViewModel | undefined;
    cities: CityModel[] | undefined;
} & ModalLayoutProps;

const UpdateRenterPaymnetModal = (props: UpdatePaymentModalProps) => {
    const [form] = Form.useForm();

    useEffect(() => {
        form.setFieldsValue({
            ...props.paymentData,
            serviceRegionsList:props.paymentData?.serviceRegions?.map(cty=>cty.name),
            taxOfficeCityName:props.cities?.find((cty)=>cty.id===props.paymentData?.taxOfficeCityId)?.name
        });
        //eslint-disable-next-line
    }, [props.paymentData, props.cities]);

    const onPaymentUpdateFormFinish = (values: any) => {
        const selectedCities = values.serviceRegionsList.map((selected:string)=>props.cities?.find(cty=>cty.name===selected)?.id)
        values.serviceRegions = selectedCities;
        const taxOfficeId = props.cities?.find(cty => cty.name === values.taxOfficeCityName)?.id;
        values.taxOfficeCityId = taxOfficeId;
        props.onUpdate(values);
    };

    return (
        <ModalLayout show={props.show} onCloseClick={props.onCloseClick}>
            <h3 style={{ textAlign: "center", marginBottom: "1rem" }}>{t("lessor-profile.update.title")}</h3>
            <div className={styles.lightboxContent}>
                <Form
                    name="update-profile"
                    initialValues={props.paymentData}
                    form={form}
                    onFinish={onPaymentUpdateFormFinish}
                    layout="vertical"
                >
                    <Row gutter={[16, 0]}>
                        <Col xs={24} md={{ offset: 6, span: 12 }}>
                            <Form.Item label={t("customer-profile.update.CompanyNameLabel")} name="companyName">
                                <Input placeholder={t("customer-profile.update.CompanyNameLabel")} />
                            </Form.Item>
                        </Col>
                        <Col xs={24} md={{ offset: 6, span: 12 }}>
                            <Form.Item name="serviceRegionsList" label={t("customer-profile.update.serviceRegionsLabel")}>
                                <Select 
                                    getPopupContainer={(trigger: any) => trigger.parentElement}
                                    onKeyDown={(event) => {
                                        const re = /[0-9]/;
                                        if (re.test(event.key)) {
                                            event.preventDefault();
                                    }
                                    }}
                                    mode="multiple"
                                >
                                    {props.cities?.map((city) => (
                                        <Select.Option value={city.name}>{city.name}</Select.Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col xs={24} md={{ offset: 6, span: 12 }}>
                            <Form.Item label={t("customer-profile.update.addressLabel")} name="address">
                                <Input placeholder={t("customer-profile.update.addressLabel")} />
                            </Form.Item>
                        </Col>
                        <Col xs={24} md={{ offset: 6, span: 12 }}>
                            <Form.Item label={t("customer-profile.companyView.taxIdentityNo")} name="taxIdentityNo">
                                <Input
                                    placeholder={t("customer-profile.update.surnamePlaceholder")}
                                    onKeyPress={(event) => {
                                        if (!/[1-9,+]/.test(event.key)) {
                                            event.preventDefault();
                                        }
                                    }}
                                />
                            </Form.Item>
                        </Col>
                        <Col xs={24} md={{ offset: 6, span: 12 }}>
                            <Form.Item key={"taxOffice-name-key"} label={t("customer-profile.companyView.taxOffice")} name="taxOffice">
                                <Input placeholder={t("customer-profile.update.taxOfficePlaceholder")} />
                            </Form.Item>
                        </Col>
                        <Col xs={24} md={{ offset: 6, span: 12 }}>
                            <Form.Item label={t("customer-profile.companyView.taxOfficeCityName")} name="taxOfficeCityName">
                                <Select getPopupContainer={(trigger: any) => trigger.parentElement}>
                                    {props.cities?.map((city) => (
                                        <Select.Option value={city.name}>{city.name}</Select.Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col xs={24} md={{ offset: 6, span: 12 }}>
                            <Form.Item label={t("customer-profile.companyView.iban")} name="iban" 
                                    rules={[{pattern: /^[a-zA-Z0-9ğüşıöçĞÜŞİÖÇ\s]*$/,message: t('common.generic-required-iban')}]}>
                                <Input placeholder={t("customer-profile.update.ibanPlaceholder")} />
                            </Form.Item>
                        </Col>

                        <Col xs={24} md={{ offset: 6, span: 12 }}>
                            <PrimaryButton type="primary" htmlType="submit">
                                {t("common.approveNow")}
                            </PrimaryButton>
                        </Col>
                    </Row>
                </Form>
            </div>
        </ModalLayout>
    );
};

export default UpdateRenterPaymnetModal;
