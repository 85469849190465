/* tslint:disable */
/* eslint-disable */
/**
 * MachineGo P1 API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface SaleMachineStatisticViewModel
 */
export interface SaleMachineStatisticViewModel {
    /**
     * 
     * @type {number}
     * @memberof SaleMachineStatisticViewModel
     */
    totalMachineSaleDetailView?: number;
    /**
     * 
     * @type {number}
     * @memberof SaleMachineStatisticViewModel
     */
    totalMachineSaleLessorView?: number;
}

export function SaleMachineStatisticViewModelFromJSON(json: any): SaleMachineStatisticViewModel {
    return SaleMachineStatisticViewModelFromJSONTyped(json, false);
}

export function SaleMachineStatisticViewModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): SaleMachineStatisticViewModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'totalMachineSaleDetailView': !exists(json, 'totalMachineSaleDetailView') ? undefined : json['totalMachineSaleDetailView'],
        'totalMachineSaleLessorView': !exists(json, 'totalMachineSaleLessorView') ? undefined : json['totalMachineSaleLessorView'],
    };
}

export function SaleMachineStatisticViewModelToJSON(value?: SaleMachineStatisticViewModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'totalMachineSaleDetailView': value.totalMachineSaleDetailView,
        'totalMachineSaleLessorView': value.totalMachineSaleLessorView,
    };
}

