import { Form, Row, Col, Spin } from "antd";
import { useForm } from "antd/lib/form/Form";
import { useAtom } from "jotai";
import moment from "moment";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import PrimaryButton from "../../../components/atomics/primary-button";
import SecureLayout from "../../../components/layouts/secure-layout";
import { useQuery } from "../../../helpers/use-query";
import AdditionalServicesPart from "../../../page-parts/additional-services-part";
import OfferAddAttachments from "../../../page-parts/offer-add-attachments";
import OfferAdditionalInfoPart from "../../../page-parts/offer-additional-info-part";
import OfferMaturityTotal from "../../../page-parts/offer-maturity-total";
import OfferPaymentOptionsPhase2 from "../../../page-parts/offer-payment-options-phase2";
import OfferSelectMachinePart from "../../../page-parts/offer-select-machine-part";
import OfferShippingOptions from "../../../page-parts/offer-shipping-options";
import { BasicModel, DocumentModel, OfferFormViewModel, OfferFormBidCreateModel, OfferFormEditModel } from "../../../service";
import { ExtendedMachineDetailModel } from "../../../service/models/ExtendedMachineDetailModel";
import { offerTabAtom, postloginMessageAtom } from "../../../store/global-atoms";
import { useSecureService } from "../../../store/service-atoms";
import { t } from "../../../translate";
import { OfferAttachmentObject } from "./add";
import styles from "./edit.module.scss"


const EditOffer = () => {
    const query = useQuery();
    const maxNumberOfAttachments = 6;
    //const reqId = query.get("reqid");
    const offerId = query.get("offerid");
    const maxMachine = query.get("max");
    const [availableMaturityOptions, setAvailableMaturityOptions] = useState<BasicModel[] | undefined>(undefined);
    const [form] = useForm();
    const [offerTabInfo, setOfferTabInfo] = useAtom(offerTabAtom);
    let offerIdN: number = NaN;
    let maxMachineN: number = NaN;
    const navigate = useNavigate();
    const [, setErrorMessage] = useAtom(postloginMessageAtom);
    const [docs, setDocs] = useState<DocumentModel[]>([]);
    const { doGetAttachments, doGetSuitableMachinesToOffer, doGetMaturityOptions, doUpdateOffer, doGetCurrencies, doGetOfferDetail, doGetMachine } = useSecureService();
    const [availableCurrencies, setAvailableCurrencies] = useState<BasicModel[] | undefined>(undefined);
    const [offerDetail, setOfferDetail] = useState<OfferFormViewModel>();
    const [requestedMachine, setRequestedMachine] = useState<ExtendedMachineDetailModel | undefined>(undefined);
    const [availableMachines, setAvailableMachines] = useState<BasicModel[]>([]);
    const [offerSelectedAttachments, setOfferSelectedAttachments] = useState<OfferAttachmentObject[] | undefined>(undefined);
    const [requestedMachines, setRequestedMachines] = useState<BasicModel[] | undefined>(undefined);
    const [loading, setLoading] = useState(true);

    if (offerId) offerIdN = parseInt(offerId);
    if (maxMachine) maxMachineN = parseInt(maxMachine);
    useEffect(() => {
        setOfferTabInfo({    
            lowestMaturitySelected: false, 
            selectedLowestMaturityId: -1,
            currencyType: "", 
            maturityInterestRates: Array(4).fill(-1), 
            currentTab: {name: "", index: -1}, 
            currentTabTotalPrice: 0,
            lowestSelectedMaturityPeriodPrice: null,
            lowestSelectedMaturityShippingGoFee: 0,
            lowestSelectedMaturityShippingReturnFee: 0,
            availableAttachments: undefined,
            selectedAttachments: [],
            selectedPeriodType: 1,
            includedMaturityOptions: Array(4).fill(false),
            includedMaturityOptionsError: Array(4).fill(false),
            tabTypes: Array(4).fill(1),
            editPage: false,
            selectedMachines: undefined,
            requestedMachines: undefined
        });
        const getServiceDatas = async () => {
            const curries = await doGetMaturityOptions();
            setAvailableMaturityOptions(curries);

            const currencies = await doGetCurrencies();
            setAvailableCurrencies(currencies);

            const offerDetail = await doGetOfferDetail(offerIdN);


            let machines : BasicModel[] | undefined; 
            if(offerDetail?.id) machines = await doGetSuitableMachinesToOffer(offerDetail.id);
            if(offerDetail?.requestForm?.machineId && machines?.some(e => e.id === offerDetail.requestForm?.machineId)) {
                const machine = await doGetMachine(offerDetail.requestForm?.machineId, true);
                if(offerDetail?.machines){
                    setRequestedMachines(offerDetail.machines.map(e => {
                        if(machine && e.id === machine.id) return {id: e.id, name: (machine?.category?.name?.concat(" / " + machine?.subCategory?.name + "-Talep Edilen")) };
                        else return e;
                    }));
                }
                setRequestedMachine(machine ?? undefined);
              //  console.log("sutable machines:" + JSON.stringify(machines) );
                if(machines && machine){
                    machines = machines.map((mac, index) => {
                        if(mac.id === machine.id) return {id: mac.id, name: (machine?.category?.name?.concat( " / " +  machine?.subCategory?.name + "-Talep Edilen"))};
                        else return mac;
                    });
                } 
              //  console.log("sutable machines2:" + JSON.stringify(machines) );
                setAvailableMachines(machines ?? []);
            }
            else {
                setRequestedMachines(offerDetail?.machines ?? []);
                setAvailableMachines(machines ?? []);
            }
            
            setOfferDetail(offerDetail);
            let attachments = await doGetAttachments(offerDetail?.requestForm?.subCategory?.id ?? 0);


            // sets page info
            let lowestMaturityId = Number.POSITIVE_INFINITY; 
            let offerCurrencyType = "";
            let offerMaturityInterestRates = Array(4).fill(-1);
            let offerCurrentTab = {name: "", index: -1};
            let offerLowestSelectedMaturityPeriodPrice : number | null = null;
            let offerLowestSelectedMaturityShippingGoFee = 0;
            let offerLowestSelectedMaturityShippingReturnFee = 0;
            let offerSelectedPeriodType = 1; 
            let offerTabTypes : number[] = Array(4).fill(1);
            let offerSelectedAttachments : OfferAttachmentObject[] = []; 
            offerDetail?.paymentOptions?.forEach((option) => {
                if(option.maturityOptionId && lowestMaturityId > option.maturityOptionId) lowestMaturityId = option.maturityOptionId;
                if(option.maturityOptionId && (option.maturityOptionId - 1) < offerMaturityInterestRates.length) offerMaturityInterestRates[option.maturityOptionId - 1] = option.percent;
            });

            offerDetail?.bids?.forEach((bid) => {
                if(bid.maturityOption?.id === lowestMaturityId){

                    bid.attachments?.forEach((e, index) => {
                        if( e.attachmentId && e.fee ) offerSelectedAttachments.push({id: e.attachmentId, name: attachments?.find(attc => attc.id === e.attachmentId)?.name ?? "", fee: e.fee, attachmentSelectId: index});
                    });

                    offerCurrentTab = {name: bid.maturityOption.name ?? "", index: bid.maturityOption.id - 1}
                    offerLowestSelectedMaturityPeriodPrice = bid.bid ?? null;
                    offerLowestSelectedMaturityShippingGoFee = bid.shippingFee ?? 0;
                    offerLowestSelectedMaturityShippingReturnFee = bid.shippingToBackFee ?? 0;
                    offerSelectedPeriodType = bid.paymentPeriod?.id ?? 1;
                } 
            });
            offerCurrencyType = offerDetail?.bids?.at(0)?.currency?.name ?? "" ;
            let offerIncludedMaturityOptions = offerMaturityInterestRates.map((e, index)=> {
                if(e !== -1){
                    (document.getElementById("checkbox-tab-" + index) as HTMLInputElement)?.setAttribute('checked', "true");
                    return true;
                } 
                else return false;
            }); 

            offerTabTypes = offerMaturityInterestRates.map((e,index) => {
                if(e === -1){
                    return 1;
                }
                else if(lowestMaturityId - 1 === index) return 2;
                else return 3;
            });

            form.setFieldsValue({
                hasOperator: offerDetail?.additionalServices?.hasOperator,
                operatorExperienceYear:  offerDetail?.additionalServices?.hasOperator ? offerDetail?.additionalServices?.operatorExperienceYear : "",
                operatorCostIncluded: offerDetail?.additionalServices?.operatorCostIncluded,
                operatorInsuranceIncluded: offerDetail?.additionalServices?.operatorInsuranceIncluded,
                fuelIncluded: offerDetail?.additionalServices?.fuelIncluded,
                ['LowestPeriodPriceInput-' + (lowestMaturityId - 1).toString()]: offerLowestSelectedMaturityPeriodPrice,
                //eslint-disable-next-line
                ['select-period-currency']: offerDetail?.bids?.at(0)?.currency?.name,
                goFee: offerLowestSelectedMaturityShippingGoFee,
                returnFee: offerLowestSelectedMaturityShippingReturnFee,
                shippingOption1: offerLowestSelectedMaturityShippingGoFee && offerLowestSelectedMaturityShippingGoFee !== 0 ? true : false,
                shippingOption2: offerLowestSelectedMaturityShippingReturnFee  && offerLowestSelectedMaturityShippingReturnFee !== 0 ? true : false,
                notes: offerDetail?.notes,
                machines: machines
            });

            setOfferTabInfo((oldVal) => { return {    
                ...oldVal,
                lowestMaturitySelected: true, 
                selectedLowestMaturityId: lowestMaturityId,
                currencyType: offerCurrencyType, 
                maturityInterestRates: offerMaturityInterestRates, 
                currentTab: offerCurrentTab, 
                lowestSelectedMaturityPeriodPrice: offerLowestSelectedMaturityPeriodPrice,
                lowestSelectedMaturityShippingGoFee: offerLowestSelectedMaturityShippingGoFee,
                lowestSelectedMaturityShippingReturnFee: offerLowestSelectedMaturityShippingReturnFee,
                availableAttachments: attachments,
                selectedAttachments: offerSelectedAttachments,
                selectedPeriodType: offerSelectedPeriodType,
                includedMaturityOptions: offerIncludedMaturityOptions,
                includedMaturityOptionsError: Array(4).fill(false),
                tabTypes: offerTabTypes,
                currentTabTotalPrice: 0,
                editPage: true,
            }});
            
            setOfferSelectedAttachments(offerSelectedAttachments);
            setLoading(false);
            if(offerDetail?.documents) setDocs(offerDetail.documents);
            
        };
        getServiceDatas();

        //eslint-disable-next-line
    }, []);

    useEffect(() => {
        setOfferTabInfo((oldVal) => { return {...oldVal, requestedMachines: requestedMachines}});
        //eslint-disable-next-line
    } , [requestedMachines])

    const getCurrenyIdByName = (name: string) => {
        return availableCurrencies?.find((x) => x.name === name)?.id;
    };

    const onOfferFormFinish = async (formData: any) => {
        //let anyMaturity = false;
        setOfferTabInfo((oldVal) => {
            return {...oldVal, includedMaturityOptionsError: Array(offerTabInfo.includedMaturityOptionsError.length).fill(false)}
        });
        let bids: OfferFormBidCreateModel[] = [];
        let errorMessage = "";
        let maturityErrorFlag = false;
        let tempErrorList =  offerTabInfo.includedMaturityOptionsError.map(e=>e);
        offerTabInfo.includedMaturityOptions.forEach(
            (e, index) => {
                if(e === true && (offerTabInfo.maturityInterestRates.at(index) === null || offerTabInfo.maturityInterestRates.at(index) === undefined)) {
                    maturityErrorFlag = true;
                    tempErrorList[index] = true;
                }
            }
        );

        setOfferTabInfo((oldVal) => {
            return {...oldVal, includedMaturityOptionsError: tempErrorList}
        });
        if (offerTabInfo.selectedLowestMaturityId === -1 || maturityErrorFlag) errorMessage = t("offer.add.noPaymentOptionsSelected");
        if (errorMessage !== "") {
            setErrorMessage({
                type: "error",
                message: errorMessage,
            });
            return;
        }

        const billT: number[] = [formData.billingTime];     
        
        if (!billT[0]){
            errorMessage = t("offer.add.noBillingTimeOptionSelected");
            setErrorMessage({
                type: "error",
                message: errorMessage,
            });
            return;
        } 

        availableMaturityOptions?.forEach((mo, index) => {
            let bid: OfferFormBidCreateModel | undefined = undefined;
            if(index === offerTabInfo.selectedLowestMaturityId) {
                bid = {
                    bid: offerTabInfo.lowestSelectedMaturityPeriodPrice ?? undefined,
                    maturityOptionId: offerTabInfo.selectedLowestMaturityId + 1,
                    paymentPeriodId: offerTabInfo.selectedPeriodType,
                    currencyId: getCurrenyIdByName(offerTabInfo.currencyType),
                    shippingFee: offerTabInfo.lowestSelectedMaturityShippingGoFee,
                    shippingToBackFee: offerTabInfo.lowestSelectedMaturityShippingReturnFee,
                    attachments: offerTabInfo.selectedAttachments.map(e => {
                            return {attachmentId: e.id, fee: e.fee}
                        })              
                };
            }
            else if(offerTabInfo.maturityInterestRates[index] && offerTabInfo.maturityInterestRates[index] !== -1) {
                bid = {
                    bid: offerTabInfo.lowestSelectedMaturityPeriodPrice ?  offerTabInfo.lowestSelectedMaturityPeriodPrice + Math.ceil( offerTabInfo.lowestSelectedMaturityPeriodPrice * (offerTabInfo.maturityInterestRates[index] ?? 0) * 0.01) : undefined,
                    maturityOptionId: index + 1,
                    paymentPeriodId: offerTabInfo.selectedPeriodType,
                    currencyId: getCurrenyIdByName(offerTabInfo.currencyType),
                    shippingFee: offerTabInfo.lowestSelectedMaturityShippingGoFee + Math.ceil( offerTabInfo.lowestSelectedMaturityShippingGoFee * (offerTabInfo.maturityInterestRates[index] ?? 0) * 0.01),
                    shippingToBackFee: offerTabInfo.lowestSelectedMaturityShippingReturnFee + Math.ceil( offerTabInfo.lowestSelectedMaturityShippingReturnFee * (offerTabInfo.maturityInterestRates[index] ?? 0) * 0.01),
                    attachments: offerTabInfo.selectedAttachments.map(e => {
                            return {attachmentId: e.id, fee: e.fee + Math.ceil( e.fee * (offerTabInfo.maturityInterestRates[index] ?? 0) * 0.01)}
                        })
                };
            }
            if(bid) bids.push(bid);
        }); 

        const machines: number[] = [];
       /*  if (formData.machines) {
            formData.machines.forEach((mch: BasicModel | undefined) => {
                if (mch?.id) machines.push(mch.id);
            });
        } */
        if (offerTabInfo.selectedMachines) {
            offerTabInfo.selectedMachines.forEach((mch: BasicModel | undefined) => {
                if (mch?.id) machines.push(mch.id);
            });
        } 
        
        const offer: OfferFormEditModel = {
            billingTimeOptions:billT,
            id: offerIdN,
            machines: machines,
            bids: bids,
            expireDate: moment(moment.now()).add(15, "days").toDate(),
            paymentTypes: [3],
            additionalServices: { ...formData },
            notes: formData.notes,
            documents: docs.map((doc) => doc.id || 0),
            paymentOptions: (offerTabInfo.maturityInterestRates.map((e, index) => {
                if(e !== -1) return {maturityOptionId: index + 1, percent: e ?? 0};
                return {}
            })).filter(e => e.maturityOptionId !== undefined)
        };

        const result = await doUpdateOffer(offer);

        if (result) {
            setOfferTabInfo({    
                lowestMaturitySelected: false, 
                selectedLowestMaturityId: -1,
                currencyType: "", 
                maturityInterestRates: Array(4).fill(-1), 
                currentTab: {name: "", index: -1}, 
                currentTabTotalPrice: 0,
                lowestSelectedMaturityPeriodPrice: null,
                lowestSelectedMaturityShippingGoFee: 0,
                lowestSelectedMaturityShippingReturnFee: 0,
                availableAttachments: undefined,
                selectedAttachments: [],
                selectedPeriodType: 1,
                includedMaturityOptions: Array(4).fill(false),
                includedMaturityOptionsError: Array(4).fill(false),
                tabTypes: Array(4).fill(1),
                editPage: false,
                selectedMachines: undefined,
                requestedMachines: undefined
            });
            navigate("/offers", { replace: true });
        } 
        else
            setErrorMessage({
                type: "error",
                message: t("common.genericError"),
            });
    };

    const documentUploadChange = (data: DocumentModel[]) => {
        setDocs(data);
    };

    const getSum = (total: number, num: number) => {
        return total + Math.round(num);
    }

    useEffect(() => {
        setOfferTabInfo((oldVal) => {
            return {...oldVal, currentTabTotalPrice: offerTabInfo.lowestSelectedMaturityShippingGoFee + offerTabInfo.lowestSelectedMaturityShippingReturnFee + (offerTabInfo.lowestSelectedMaturityPeriodPrice ?? 0) + (offerTabInfo.selectedAttachments.map(e => e.fee).reduce(getSum, 0))}
        });
        //eslint-disable-next-line
    },[offerTabInfo.lowestSelectedMaturityShippingGoFee, offerTabInfo.lowestSelectedMaturityShippingReturnFee, offerTabInfo.lowestSelectedMaturityPeriodPrice, offerTabInfo.selectedAttachments]);

/*     useEffect(() => {console.log("ERROR:" + JSON.stringify(offerTabInfo.includedMaturityOptionsError))},[offerTabInfo.includedMaturityOptionsError]);

    useEffect(() => console.log("RATES:" + offerTabInfo.maturityInterestRates),[offerTabInfo.maturityInterestRates]);  */

    return (
        <SecureLayout activePage={"offers"} role={"lessor"} title={t("offers.add.title")} description={t("offers.add.description")}>
            <Form name="add-offer" form={form} onFinish={onOfferFormFinish} layout="vertical">
                {loading ?
                    <Spin
                    size="large"
                    tip="Lütfen Bekleyiniz"
                    className={styles.spinMobile}
                    />
                :
                    <Row gutter={[4, 4]}>
                        <Col xs={24} md={12}>

                            <OfferSelectMachinePart editPage={true} editPageOfferedMachines={requestedMachines} availableMachines={availableMachines} maxMachineCount={maxMachineN} offerId={offerIdN} />

                        </Col>
                        <Col xs={24} md={12}>
                            <OfferAdditionalInfoPart uploadChange={documentUploadChange} docs={docs} />
                        </Col>
                        <Col xs={24} md={24}>
                            <AdditionalServicesPart isRequest={false} form={form} />
                        </Col>
                        <Col xs={24} md={24}>
                            <OfferPaymentOptionsPhase2        
                            maxNumberOfAttachments={maxNumberOfAttachments} 
                            availableMaturityOptions={availableMaturityOptions}
                            offerDetail={offerDetail}
                            machine={requestedMachine}
                            billingTimeOption={offerDetail?.billingTimeOptions?.at(0)?.id}
                            form={form} />
                        </Col>
                        <Col xs={24} md={24}>
                            <OfferShippingOptions 
                            form={form}
                            />
                        </Col>
                        <Col xs={24} md={24}>
                            <OfferAddAttachments 
                            maxAttachmentNumber={maxNumberOfAttachments} 
                            editPageOfferAttachments={offerSelectedAttachments}
                            form={form}
                            />
                        </Col>
                        <Col xs={24} md={24}>
                            <OfferMaturityTotal 
                            maturityType={offerTabInfo.currentTab.name}
                            form={form}/>
                        </Col>
                        <Col xs={{span:16, offset:4}} md={{span:6, offset:9}}>
                            <Form.Item>
                                <PrimaryButton loading={loading} htmlType="submit" style={{ marginTop: "1rem" }}>
                                    {t("offer.add.sendToAdmin")}
                                </PrimaryButton>
                            </Form.Item>
                        </Col>
                    </Row>
                }
               
            </Form>
        </SecureLayout>
    );
};

export default EditOffer;
