import { facebookAppId, googleClientId } from "../store/app-constants";

export const facebookLogin = (redirectUri: string, state?: string) => {
    const link =`https://www.facebook.com/v20.0/dialog/oauth?client_id=${facebookAppId}&scope=email&redirect_uri=${encodeURIComponent(redirectUri)}` + (state ? `&state=${encodeURIComponent(state)}`: '');
    window.location.href = link;
}

export const googleLogin = (redirectUri: string, state?: string) => {
    const link =`https://accounts.google.com/o/oauth2/v2/auth?response_type=code&client_id=${googleClientId}&scope=email profile&redirect_uri=${encodeURIComponent(redirectUri)}` + (state ? `&state=${encodeURIComponent(state)}`: '');
    window.location.href = link;
}