import { Col, Row } from "antd";
import { t } from "../translate";
import { RequestFormOfferViewModel } from "../service";

export type OfferAggrementPartProps = {
    req: RequestFormOfferViewModel | undefined;
    expireDate: String | number;
    creationDate: String | number;
    acceptAggrement: (accept: boolean) => void;
}


const OfferAggrementPart = (props: OfferAggrementPartProps) => {

    return (
        <Row style={{display: 'flex', justifyContent: 'center', marginTop: '2rem'}}>
            <Row style={{width: '100%'}}>
                <Col xs={24} md={24} >
                    <div style={{fontWeight: '800', fontSize: '1.5rem', display: 'flex', justifyContent: 'center'}}>
                        <span>{t("offer.view.requestDateandAgreement")}</span>
                    </div>
                </Col>  
            </Row>

            <Row style={{marginTop: '2rem', width: '100%', background: '#FFFFFF', borderRadius: '14px', height: '6rem'}}>
                <Col xs={24} md={24} >
                    <Row style={{display: 'flex', justifyContent: 'center', marginTop: '2.5rem'}}>
                        <Col xs={20} sm={15} md={5} style={{display: 'flex', justifyContent: 'center', alignItems: 'center', height: '20px', background: '#FFF6E8', borderRadius: '8px', fontWeight: '700'}}>
                        
                            {props.creationDate + "-" + props.expireDate}
              
                        </Col>
                    </Row>
                   {/*  <Row style={{display: 'flex', justifyContent: 'center', marginTop: '1rem'}}>
                        <Col xs={20} sm={15} md={6} style={{display: 'flex', justifyContent: 'center', alignItems: 'center', gap: '10px', fontWeight: '600'}}>
                            <Checkbox onChange={
                                (e) => {
                                    props.acceptAggrement(e.target.checked);
                                }
                            }></Checkbox>
                            <span>{t("offer.view.aggrementTitle")}</span>
                        </Col>
                    </Row> */}
                   
                    
                </Col>  
            </Row>

        </Row>
    );
}


export default OfferAggrementPart;