import { Form } from "antd";
import PrimaryButton from "../../components/atomics/primary-button";
import AuthLayout from "../../components/layouts/auth-layout";
import { t } from "../../translate";
import { useNavigate } from "react-router-dom";
import { ReactComponent as Lock } from "../../components/assets/ic_lock.svg";
import styles from "../../components/styles/auth-layout.module.scss";
import SmsInput from "../../components/atomics/sms-input";
import { useQuery } from "../../helpers/use-query";
import { useEffect, useState } from "react";
import { useSecureService } from "../../store/service-atoms";
import { RoleType, loadingAtom, postloginMessageAtom } from "../../store/global-atoms";
import { useAtom } from "jotai";
import SecondaryButton from "../../components/atomics/secondary-button";
import TextButton from "../../components/atomics/text-button";

const maxCount = 120;
const SmsVerification = () => {
    const query = useQuery();
    const userId = query.get("id") || "0";
    const operation = query.get("op") || "";
    const phone = query.get("phone") || "";
    const roleQuery = (query.get("role") as RoleType) || "customer";
    const fromRequest = query.get("request") ? "&request=true" : "";
    const navigate = useNavigate();
    const [loading] = useAtom(loadingAtom);
    const [, message] = useAtom(postloginMessageAtom);
    const [counter, setCounter] = useState(maxCount);
    const { doResendActivationCode, doResendAccountActivationCode, doResetPasswordOTP, doActivateAccount } = useSecureService();
    const [smsCode, setSmsCode] = useState<string>("");

    const handleSmsChange = (code: string) => {
        setSmsCode(code); // Child'dan gelen SMS kodunu al
    };
    console.log("smsCode:" + smsCode);  
    const formFinishHandler = async (params: { sms: string }) => {
    console.log("smsCode:" + smsCode);  

        if (operation === "register") {
            const result = await doActivateAccount(roleQuery, {
                code: smsCode,
                id: parseInt(userId),
            });
            if (result) {
                message({message: t('lessor.createUser.success'), type: 'success'});
                navigate("/dashboard?role=" + roleQuery + fromRequest);
            } else {
               
            }
        } else {
            const result = await doResetPasswordOTP(roleQuery, {
                code: smsCode,
                id: parseInt(userId),
            });
            console.log("result:" + result);
            if (result) {
                navigate("/reset-password?phone=" + phone + "&otp=" + smsCode + "&role=" + roleQuery);
            }
        }
    };

    useEffect(() => {
        counter > 0 && setTimeout(() => setCounter(counter - 1), 1000);
    }, [counter]);

    const resendClick = () => {
        if (operation === 'register')
            doResendAccountActivationCode(roleQuery, parseInt(userId));
        else 
            doResendActivationCode(roleQuery, parseInt(userId));
        setCounter(maxCount);
    };

    const formatPhoneNumber = (phone: any) => {
        const phoneStr = phone.toString(); // Sayıyı stringe çeviriyoruz
        const formattedPhone = `0 (${phoneStr.slice(0, 3)}) ${phoneStr.slice(3, 6)} ${phoneStr.slice(6, 8)} ${phoneStr.slice(8, 10)}`;
        return formattedPhone;
    };

    return (
        <AuthLayout>
            <div className={styles.verticalCentralizedFlexContainer}>
                <div className={styles.smsVerificationIconCycle}>
                    <Lock width={"3.75rem"} height={"3.75rem"} />
                </div>

                <div>
                    <p className={styles.smsVerificationNumber}>{formatPhoneNumber(phone)}</p>
                    <p className={styles.smsVerificationInfo}>{t("sms-verification.info")}</p>
                </div>
            </div>
            <Form name="normal_login" className={styles.smsVerificationForm} layout="vertical" onFinish={formFinishHandler}>
                <div className={styles.smsInputContainer}>
                    <SmsInput
                        onChange={handleSmsChange}
                        placeholder={t("sms-verification.smsInput.placeholder")}
                        validationMessage={t("sms-verification.smsInput.validationMessage")}
                        label={t("sms-verification.smsInput.label")}
                    />
                </div>
                <div>
                    <PrimaryButton loading={loading} htmlType="submit">
                        {t("sms-verification.verifyCode")}
                    </PrimaryButton>
                </div>
                <div>
                    <SecondaryButton onClick={() => resendClick()} type="link" disabled={counter !== 0}>
                        {t("sms-verification.resend")}
                        {counter !== 0 && <span>{`(${counter} s)`}</span>}
                    </SecondaryButton>
                </div>
                <div>
                    <TextButton
                        style={{ marginTop: "1rem", width: "100%", color: "#949394" }}
                        onClick={() => (window.location.href = "tel:02127060330")}
                        type="link"
                    >
                        <p style={{ color: "#949394", textDecoration: "underline" }}>{t("sms-verification.signupProblem")}</p>
                    </TextButton>
                </div>
            </Form>
        </AuthLayout>
    );
};

export default SmsVerification;
