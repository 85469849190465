import { Col, Form, message, Row } from "antd";
import { useEffect, useMemo, useState } from "react";
import { useAtom } from "jotai";
import PrimaryButton from "../../../components/atomics/primary-button";
import SecureLayout from "../../../components/layouts/secure-layout";
import { useQuery } from "../../../helpers/use-query";
import { extendedMachineDetailAtom, loadingAtom } from "../../../store/global-atoms";
import { t } from "../../../translate";
import { useSecureService } from "../../../store/service-atoms";
import {
    BasicModel,
    BasicValueModel,
    DocumentCategory,
    DocumentModel,
    MachineDocumentViewModel,
    MachineRentalTypeModel,
    MachineSaleCommissionViewModel,
    MachineSaleDepositFeeViewModel,
} from "../../../service";
import { useNavigate } from "react-router-dom";
import MachineMachineSaleInfoPart from "../../../page-parts/machine-machine-sale-info-part";
import MachineSaleAdditionalInfoPart from "../../../page-parts/machine-sale-additional-info-part";
import MachineSalePricePart from "../../../page-parts/machine-sale-price-part";

const EditSaleMachine = () => {
    const query = useQuery();
    const machineId = query.get("macid");
    const [form] = Form.useForm();
    const [editedMachine, editMachine] = useAtom(extendedMachineDetailAtom);
    const { doUpdateSaleMachine, doGetMachine, doGetCurrencies, doGetMachineCommissionsForFee, doGetMachineCommissionsForSale, doGetExchangeRates } =
        useSecureService();
    const [docs, setDocs] = useState<MachineDocumentViewModel[]>([]);
    const navigate = useNavigate();
    const [loading] = useAtom(loadingAtom);
    const [fees, setFees] = useState<MachineSaleDepositFeeViewModel[] | undefined>(undefined);
    const [selectedSubcategory, setSelectedSubcategory] = useState<{ catid: Number; subCatid: Number } | undefined>(undefined);
    const [commissions, setCommissions] = useState<MachineSaleCommissionViewModel[] | undefined>(undefined);
    const [exchangeRates, setExchangeRates] = useState<BasicValueModel[] | undefined>(undefined);
    const [saleMachineRemoved, setSaleMachineRemoved] = useState<undefined | boolean>(undefined);
    const [totalPrice, setTotalPrice] = useState(0);
    const [priceCurrency, setPriceCurrency] = useState("");

    const [dataFetching, setDataFetching] = useState(true);
    const [infoDataFetched, setInfoDataFetched] = useState(false);
    const [availableCurrencies, setAvailableCurrencies] = useState<BasicModel[] | undefined>(undefined);
    const pics = useMemo(() => {
        return editedMachine?.documents?.filter((doc) => doc.documentCategory === "Photo");
    }, [editedMachine?.documents]);

    useEffect(() => {
        if (machineId !== null) {
            getMachine(Number.parseInt(machineId));
        } else {
            setDataFetching(false);
        }
        const getCurrencies = async () => {
            const currencies = await doGetCurrencies();
            setAvailableCurrencies(currencies);
        };
        getCurrencies();
        const getCommissionsAndFees = async () => {
            setFees(await doGetMachineCommissionsForFee());
            setCommissions(await doGetMachineCommissionsForSale());
            setExchangeRates(await doGetExchangeRates());
        };
        getCommissionsAndFees();
        //eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (exchangeRates && commissions && fees && infoDataFetched) {
            setTimeout(() => {
                setDataFetching(false);
            }, 2500);

        }
    }, [exchangeRates, commissions, fees, infoDataFetched]);

    const getMachine = async (machineId: number) => {
        const result = await doGetMachine(machineId, false);
        if (result?.isMachineRemovedFromSale) {
            setSaleMachineRemoved(true);
        } else {
            setSaleMachineRemoved(false);
        }
        if (result?.documents) setDocs(result?.documents);

        editMachine(result);
    };

    const checkFields = async (): Promise<boolean> => {
        var saleTitleCheck = await form.getFieldValue(['saleAdTitle']);
        if (saleTitleCheck === null || saleTitleCheck === undefined || saleTitleCheck.trim() === ""){
            message.error(t("machines.add.AdLabel-error"));
            return false
        }

        return true;
    }

    const onUpdateSaleMachine = async (params: any) => {
        if ( !(await checkFields()) ) {
            return;
        }
        if (editedMachine && machineId) {
            const result = await doUpdateSaleMachine({
                ...editedMachine,
                ...params,
                saleAdTitle: params.saleAdTitle.trim(),
                documents: docs,
                isMachineForSale: true,
            }, Number.parseInt(machineId)
            );
            if (result) {
                editMachine(undefined);
                navigate("/lessor-sale-machines", { replace: true });
            }
        }
    };
    const onMachineFormFinish = async (params: any) => {
        let rentalTypes: MachineRentalTypeModel[] = [];

        editMachine((oldVal) => {
            return {
                ...oldVal,
                ...params,
                rentalTypes: rentalTypes,
                saleAdTitle: params.saleAdTitle.trim(),
                documents: docs,
            };
        });

        await onUpdateSaleMachine(params);
    };

    const uploadedFilesChanged = (docs: DocumentModel[], category: DocumentCategory) => {
        setDocs((oldVal) => {
            var newCatDoc = oldVal?.filter((doc) => {
                return doc.documentCategory !== category;
            });
            if (!newCatDoc) newCatDoc = [];
            var addedDocs: MachineDocumentViewModel[] = docs.map((doc) => {
                return {
                    documentCategory: category,
                    document: doc,
                };
            });
            newCatDoc?.push(...addedDocs);

            return [...newCatDoc];
        });
    };

    const calculatedPrices: {
        depositFee: string;
        commissionPrice: string;
        total: string;
    } = useMemo(() => {
        var commissionRate = commissions?.find((x) => x.subCategoryId === selectedSubcategory?.subCatid)?.priceRate || 0;
        var exchangeRate = exchangeRates?.find((x) => x.name === priceCurrency)?.value || 1;
        var commission = (commissionRate / 100) * totalPrice * exchangeRate;
        var totalPriceTL = totalPrice * exchangeRate;
        var netPriceTL = totalPriceTL - commission;

        return {
            depositFee: (fees?.find((x) => x.subCategoryId === selectedSubcategory?.subCatid)?.depositFee || 0).toString() + " TL",
            commissionPrice: commission.toFixed(2).toString() + " TL",
            total: netPriceTL.toFixed(2).toString() + " TL",
        };
    }, [fees, commissions, selectedSubcategory, priceCurrency, totalPrice, exchangeRates]);

    const allInfoDataFetched = () => {
        setInfoDataFetched(true);
    }

    return (
        <SecureLayout loading={dataFetching} activePage={"machines"} role={"lessor"} title={saleMachineRemoved === true ? undefined : t("machines.add.title")} description={saleMachineRemoved === true ? undefined : t("machines.add.description")}>
            {saleMachineRemoved === true && (<Row style={{display: 'flex', height: '100%'}}>
                <Col sm={24} style={{display: 'flex', textAlign:'center', alignItems: 'center', justifyContent: 'center'}}>
                    <span style={{verticalAlign: 'center', fontWeight: 'bold'}}>{t("machine-detail.machine-not-found")}</span>
                </Col>
            </Row>)}
            {saleMachineRemoved === false && (<Form name="add-machine" initialValues={editedMachine} form={form} onFinish={onMachineFormFinish} layout="vertical">
                <Row gutter={[4, 4]}>
                    <Col xs={24} md={12}>
                        <MachineMachineSaleInfoPart
                            allDataFetched={allInfoDataFetched}
                            uploadChange={uploadedFilesChanged}
                            docs={pics}
                            form={form}
                            initialSubcategory={editedMachine?.subCategory ?  {id: editedMachine.subCategory.id , name: editedMachine.subCategory.name} : undefined}
                            initialCategory={editedMachine?.subCategory?.category?.name ?? undefined}
                            initialLocation={editedMachine?.machineLocationCity?.name ?? undefined}
                            subcategoryChanged={(catid, subcatid) => setSelectedSubcategory({ catid: catid, subCatid: subcatid })}
                        />
                    </Col>

                    <Col xs={24} md={12}>
                        <Row gutter={[4, 4]}>
                            <Col md={24} xs={24}>
                                <MachineSaleAdditionalInfoPart />
                            </Col>
                            <Col md={24} xs={24}>
                                <MachineSalePricePart
                                    onCurrencyChange={(val) => setPriceCurrency(val || "")}
                                    onPriceChange={(val) => setTotalPrice(val)}
                                    depositFee={calculatedPrices.depositFee}
                                    machineGoCommission={calculatedPrices.commissionPrice}
                                    totalPriceForMachineOwner={calculatedPrices.total}
                                    form={form}
                                    availableCurrencies={availableCurrencies}
                                    initialCurId={editedMachine?.machineSalePriceCurrencyId ?? undefined}
                                    initialInit
                                />
                            </Col>
                        </Row>
                    </Col>

                    <Col xs={6} offset={9}>
                        <Form.Item>
                            <PrimaryButton htmlType="submit" loading={loading} style={{ marginTop: "1rem" }}>
                                {t("common.update")}
                            </PrimaryButton>
                        </Form.Item>
                    </Col>
                </Row>
            </Form>)}
        </SecureLayout>
    );
};

export default EditSaleMachine;
