/* tslint:disable */
/* eslint-disable */
/**
 * MachineGo P1 API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    ErrorModel,
    ErrorModelFromJSON,
    ErrorModelToJSON,
    OfferFormBidCommissionCreateModel,
    OfferFormBidCommissionCreateModelFromJSON,
    OfferFormBidCommissionCreateModelToJSON,
    OfferFormCommentModel,
    OfferFormCommentModelFromJSON,
    OfferFormCommentModelToJSON,
    OfferFormEditModel,
    OfferFormEditModelFromJSON,
    OfferFormEditModelToJSON,
    OfferFormPaymentLinkModel,
    OfferFormPaymentLinkModelFromJSON,
    OfferFormPaymentLinkModelToJSON,
    OfferFormSearchModel,
    OfferFormSearchModelFromJSON,
    OfferFormSearchModelToJSON,
    OfferFormViewModel,
    OfferFormViewModelFromJSON,
    OfferFormViewModelToJSON,
    OfferFormViewModelPagedDataModel,
    OfferFormViewModelPagedDataModelFromJSON,
    OfferFormViewModelPagedDataModelToJSON,
} from '../models';

export interface OfferFormAcceptOfferIdBidIdGetRequest {
    offerId: number;
    bidId: number;
}

export interface OfferFormApprovePostRequest {
    offerFormCommentModel?: OfferFormCommentModel;
}

export interface OfferFormDealOfferIdGetRequest {
    offerId: number;
}

export interface OfferFormDeclinePostRequest {
    offerFormCommentModel?: OfferFormCommentModel;
}

export interface OfferFormIdGetRequest {
    id: number;
}

export interface OfferFormOfferPostRequest {
    offerFormEditModel?: OfferFormEditModel;
}

export interface OfferFormPaymentDeclineOfferIdGetRequest {
    offerId: number;
}

export interface OfferFormRejectPostRequest {
    offerFormCommentModel?: OfferFormCommentModel;
}

export interface OfferFormSearchPostRequest {
    offerFormSearchModel?: OfferFormSearchModel;
}

export interface OfferFormSetCommissionsOfferIdPostRequest {
    offerId: number;
    offerFormBidCommissionCreateModel?: Array<OfferFormBidCommissionCreateModel>;
}

export interface OfferFormUpdatePostRequest {
    offerFormEditModel?: OfferFormEditModel;
}

/**
 * 
 */
export class OfferFormApi extends runtime.BaseAPI {

    /**
     * Customer
     */
    async offerFormAcceptOfferIdBidIdGetRaw(requestParameters: OfferFormAcceptOfferIdBidIdGetRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<OfferFormPaymentLinkModel>> {
        if (requestParameters.offerId === null || requestParameters.offerId === undefined) {
            throw new runtime.RequiredError('offerId','Required parameter requestParameters.offerId was null or undefined when calling offerFormAcceptOfferIdBidIdGet.');
        }

        if (requestParameters.bidId === null || requestParameters.bidId === undefined) {
            throw new runtime.RequiredError('bidId','Required parameter requestParameters.bidId was null or undefined when calling offerFormAcceptOfferIdBidIdGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/offer-form/accept/{offerId}/{bidId}`.replace(`{${"offerId"}}`, encodeURIComponent(String(requestParameters.offerId))).replace(`{${"bidId"}}`, encodeURIComponent(String(requestParameters.bidId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => OfferFormPaymentLinkModelFromJSON(jsonValue));
    }

    /**
     * Customer
     */
    async offerFormAcceptOfferIdBidIdGet(requestParameters: OfferFormAcceptOfferIdBidIdGetRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<OfferFormPaymentLinkModel> {
        const response = await this.offerFormAcceptOfferIdBidIdGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Admin
     */
    async offerFormApprovePostRaw(requestParameters: OfferFormApprovePostRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/offer-form/approve`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: OfferFormCommentModelToJSON(requestParameters.offerFormCommentModel),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Admin
     */
    async offerFormApprovePost(requestParameters: OfferFormApprovePostRequest = {}, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<void> {
        await this.offerFormApprovePostRaw(requestParameters, initOverrides);
    }

    /**
     * Admin
     */
    async offerFormDealOfferIdGetRaw(requestParameters: OfferFormDealOfferIdGetRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.offerId === null || requestParameters.offerId === undefined) {
            throw new runtime.RequiredError('offerId','Required parameter requestParameters.offerId was null or undefined when calling offerFormDealOfferIdGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/offer-form/deal/{offerId}`.replace(`{${"offerId"}}`, encodeURIComponent(String(requestParameters.offerId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Admin
     */
    async offerFormDealOfferIdGet(requestParameters: OfferFormDealOfferIdGetRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<void> {
        await this.offerFormDealOfferIdGetRaw(requestParameters, initOverrides);
    }

    /**
     * Customer
     */
    async offerFormDeclinePostRaw(requestParameters: OfferFormDeclinePostRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/offer-form/decline`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: OfferFormCommentModelToJSON(requestParameters.offerFormCommentModel),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Customer
     */
    async offerFormDeclinePost(requestParameters: OfferFormDeclinePostRequest = {}, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<void> {
        await this.offerFormDeclinePostRaw(requestParameters, initOverrides);
    }

    /**
     * Admin, Lessor
     */
    async offerFormIdGetRaw(requestParameters: OfferFormIdGetRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<OfferFormViewModel>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling offerFormIdGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/offer-form/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => OfferFormViewModelFromJSON(jsonValue));
    }

    /**
     * Admin, Lessor
     */
    async offerFormIdGet(requestParameters: OfferFormIdGetRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<OfferFormViewModel> {
        const response = await this.offerFormIdGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Lessor
     */
    async offerFormOfferPostRaw(requestParameters: OfferFormOfferPostRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<OfferFormEditModel>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/offer-form/offer`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: OfferFormEditModelToJSON(requestParameters.offerFormEditModel),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => OfferFormEditModelFromJSON(jsonValue));
    }

    /**
     * Lessor
     */
    async offerFormOfferPost(requestParameters: OfferFormOfferPostRequest = {}, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<OfferFormEditModel> {
        const response = await this.offerFormOfferPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Admin
     */
    async offerFormPaymentDeclineOfferIdGetRaw(requestParameters: OfferFormPaymentDeclineOfferIdGetRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.offerId === null || requestParameters.offerId === undefined) {
            throw new runtime.RequiredError('offerId','Required parameter requestParameters.offerId was null or undefined when calling offerFormPaymentDeclineOfferIdGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/offer-form/paymentDecline/{offerId}`.replace(`{${"offerId"}}`, encodeURIComponent(String(requestParameters.offerId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Admin
     */
    async offerFormPaymentDeclineOfferIdGet(requestParameters: OfferFormPaymentDeclineOfferIdGetRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<void> {
        await this.offerFormPaymentDeclineOfferIdGetRaw(requestParameters, initOverrides);
    }

    /**
     * Admin
     */
    async offerFormRejectPostRaw(requestParameters: OfferFormRejectPostRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/offer-form/reject`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: OfferFormCommentModelToJSON(requestParameters.offerFormCommentModel),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Admin
     */
    async offerFormRejectPost(requestParameters: OfferFormRejectPostRequest = {}, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<void> {
        await this.offerFormRejectPostRaw(requestParameters, initOverrides);
    }

    /**
     * Admin, Lessor
     */
    async offerFormSearchPostRaw(requestParameters: OfferFormSearchPostRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<OfferFormViewModelPagedDataModel>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/offer-form/search`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: OfferFormSearchModelToJSON(requestParameters.offerFormSearchModel),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => OfferFormViewModelPagedDataModelFromJSON(jsonValue));
    }

    /**
     * Admin, Lessor
     */
    async offerFormSearchPost(requestParameters: OfferFormSearchPostRequest = {}, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<OfferFormViewModelPagedDataModel> {
        const response = await this.offerFormSearchPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Admin
     */
    async offerFormSetCommissionsOfferIdPostRaw(requestParameters: OfferFormSetCommissionsOfferIdPostRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<Array<OfferFormBidCommissionCreateModel>>> {
        if (requestParameters.offerId === null || requestParameters.offerId === undefined) {
            throw new runtime.RequiredError('offerId','Required parameter requestParameters.offerId was null or undefined when calling offerFormSetCommissionsOfferIdPost.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/offer-form/set-commissions/{offerId}`.replace(`{${"offerId"}}`, encodeURIComponent(String(requestParameters.offerId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.offerFormBidCommissionCreateModel?.map(OfferFormBidCommissionCreateModelToJSON),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(OfferFormBidCommissionCreateModelFromJSON));
    }

    /**
     * Admin
     */
    async offerFormSetCommissionsOfferIdPost(requestParameters: OfferFormSetCommissionsOfferIdPostRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<Array<OfferFormBidCommissionCreateModel>> {
        const response = await this.offerFormSetCommissionsOfferIdPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Captcha ( Guest = guest )
     */
    async offerFormStatusGetRaw(initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<Array<string>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/offer-form/status`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     * Captcha ( Guest = guest )
     */
    async offerFormStatusGet(initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<Array<string>> {
        const response = await this.offerFormStatusGetRaw(initOverrides);
        return await response.value();
    }

    /**
     * Lessor
     */
    async offerFormUpdatePostRaw(requestParameters: OfferFormUpdatePostRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<OfferFormEditModel>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/offer-form/update`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: OfferFormEditModelToJSON(requestParameters.offerFormEditModel),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => OfferFormEditModelFromJSON(jsonValue));
    }

    /**
     * Lessor
     */
    async offerFormUpdatePost(requestParameters: OfferFormUpdatePostRequest = {}, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<OfferFormEditModel> {
        const response = await this.offerFormUpdatePostRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
