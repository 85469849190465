import { Col, Form, Row, Spin } from "antd";
import { useForm } from "antd/es/form/Form";
import { useAtom } from "jotai";
import moment from "moment";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import PrimaryButton from "../../../components/atomics/primary-button";
import SecureLayout from "../../../components/layouts/secure-layout";
import { useQuery } from "../../../helpers/use-query";
import AdditionalServicesPart from "../../../page-parts/additional-services-part";
import OfferAdditionalInfoPart from "../../../page-parts/offer-additional-info-part";
import OfferSelectMachinePart from "../../../page-parts/offer-select-machine-part";
import { BasicModel, DocumentModel, OfferFormBidCreateModel, OfferFormEditModel, OfferFormViewModel } from "../../../service/models";
import { loadingAtom, offerTabAtom, postloginMessageAtom } from "../../../store/global-atoms";
import { useSecureService } from "../../../store/service-atoms";
import { t } from "../../../translate";
import OfferShippingOptions from "../../../page-parts/offer-shipping-options";
import OfferAddAttachments from "../../../page-parts/offer-add-attachments";
import OfferMaturityTotal from "../../../page-parts/offer-maturity-total";
import OfferPaymentOptionsPhase2 from "../../../page-parts/offer-payment-options-phase2";
import { ExtendedMachineDetailModel } from "../../../service/models/ExtendedMachineDetailModel";
import styles from "./edit.module.scss";

export type OfferAttachmentObject = {
    id: number;
    name: string;
    fee: number;
    attachmentSelectId: number;
};

const AddOffer = () => {
    const query = useQuery();
    const maxNumberOfAttachments = 6;
    //const reqId = query.get("reqid");
    const offerId = query.get("offerid");
    const maxMachine = query.get("max");
    const [availableMaturityOptions, setAvailableMaturityOptions] = useState<BasicModel[] | undefined>(undefined);
    const [form] = useForm();
    const [offerTabInfo, setOfferTabInfo] = useAtom(offerTabAtom);
    let offerIdN: number = NaN;
    let maxMachineN: number = NaN;
    const navigate = useNavigate();
    const [, setErrorMessage] = useAtom(postloginMessageAtom);
    const [docs, setDocs] = useState<DocumentModel[]>([]);
    const {
        doGetAttachments,
        doGetSuitableMachinesToOffer,
        doGetMaturityOptions,
        doPostOffer,
        doGetCurrencies,
        doGetOfferDetail,
        doGetMachine,
    } = useSecureService();
    const [availableCurrencies, setAvailableCurrencies] = useState<BasicModel[] | undefined>(undefined);
    const [offerDetail, setOfferDetail] = useState<OfferFormViewModel>();
    const [requestedMachine, setRequestedMachine] = useState<ExtendedMachineDetailModel | undefined>(undefined);
    const [availableMachines, setAvailableMachines] = useState<BasicModel[]>([]);
    const [loading, setLoading] = useState(true);
    const [serviceLoading] = useAtom(loadingAtom);

    if (offerId) offerIdN = parseInt(offerId);
    if (maxMachine) maxMachineN = parseInt(maxMachine);
    useEffect(() => {
        const getServiceDatas = async () => {
            setOfferTabInfo({
                lowestMaturitySelected: false,
                selectedLowestMaturityId: -1,
                currencyType: "",
                maturityInterestRates: Array(4).fill(-1),
                currentTab: { name: "", index: -1 },
                currentTabTotalPrice: 0,
                lowestSelectedMaturityPeriodPrice: null,
                lowestSelectedMaturityShippingGoFee: 0,
                lowestSelectedMaturityShippingReturnFee: 0,
                availableAttachments: undefined,
                selectedAttachments: [],
                selectedPeriodType: 1,
                includedMaturityOptions: Array(4).fill(false),
                includedMaturityOptionsError: Array(4).fill(false),
                tabTypes: Array(4).fill(1),
                editPage: false,
                selectedMachines: undefined,
                requestedMachines: undefined,
            });
            const curries = await doGetMaturityOptions();
            setAvailableMaturityOptions(curries);

            const currencies = await doGetCurrencies();
            setAvailableCurrencies(currencies);

            const offerDetail = await doGetOfferDetail(offerIdN);
            let machines: BasicModel[] | undefined;
            if (offerDetail?.id) machines = await doGetSuitableMachinesToOffer(offerDetail.id);
            if (offerDetail?.requestForm?.machineId && machines?.some((e) => e.id === offerDetail.requestForm?.machineId)) {
                const machine = await doGetMachine(offerDetail.requestForm?.machineId, true);
                setRequestedMachine(machine ?? undefined);
                //  console.log("sutable machines:" + JSON.stringify(machines) );
                if (machines && machine) {
                    machines = machines.map((mac, index) => {
                        if (mac.id === offerDetail.requestForm?.machineId)
                            return {
                                id: mac.id,
                                name: machine.category?.name?.concat(" / " + machine?.subCategory?.name + "-Talep Edilen"),
                            };
                        else return mac;
                    });
                }
                //  console.log("sutable machines2:" + JSON.stringify(machines) );
            }
            setAvailableMachines(machines ?? []);
            setOfferDetail(offerDetail);
            let attachments = await doGetAttachments(offerDetail?.requestForm?.subCategory?.id ?? 0);
            setOfferTabInfo((oldVal) => {
                return { ...oldVal, availableAttachments: attachments };
            });
            setLoading(false);
        };
        getServiceDatas();

        //eslint-disable-next-line
    }, []);

    const getCurrenyIdByName = (name: string) => {
        return availableCurrencies?.find((x) => x.name === name)?.id;
    };

    const onOfferFormFinish = async (formData: any) => {
        //let anyMaturity = false;
        setOfferTabInfo((oldVal) => {
            return { ...oldVal, includedMaturityOptionsError: Array(offerTabInfo.includedMaturityOptionsError.length).fill(false) };
        });
        let bids: OfferFormBidCreateModel[] = [];
        let errorMessage = "";
        let maturityErrorFlag = false;
        let tempErrorList = offerTabInfo.includedMaturityOptionsError.map((e) => e);
        offerTabInfo.includedMaturityOptions.forEach((e, index) => {
            if (
                e === true &&
                (offerTabInfo.maturityInterestRates.at(index) === null || offerTabInfo.maturityInterestRates.at(index) === undefined)
            ) {
                maturityErrorFlag = true;
                tempErrorList[index] = true;
            }
        });

        setOfferTabInfo((oldVal) => {
            return { ...oldVal, includedMaturityOptionsError: tempErrorList };
        });
        if (offerTabInfo.selectedLowestMaturityId === -1 || maturityErrorFlag) errorMessage = t("offer.add.noPaymentOptionsSelected");
        if (errorMessage !== "") {
            setErrorMessage({
                type: "error",
                message: errorMessage,
            });
            return;
        }

        const billT: number[] = [formData.billingTime];

        if (!billT[0]) {
            errorMessage = t("offer.add.noBillingTimeOptionSelected");
            setErrorMessage({
                type: "error",
                message: errorMessage,
            });
            return;
        }

        availableMaturityOptions?.forEach((mo, index) => {
            let bid: OfferFormBidCreateModel | undefined = undefined;
            if (index === offerTabInfo.selectedLowestMaturityId) {
                bid = {
                    bid: offerTabInfo.lowestSelectedMaturityPeriodPrice ?? undefined,
                    maturityOptionId: offerTabInfo.selectedLowestMaturityId + 1,
                    paymentPeriodId: offerTabInfo.selectedPeriodType,
                    currencyId: getCurrenyIdByName(offerTabInfo.currencyType),
                    shippingFee: offerTabInfo.lowestSelectedMaturityShippingGoFee,
                    shippingToBackFee: offerTabInfo.lowestSelectedMaturityShippingReturnFee,
                    attachments: offerTabInfo.selectedAttachments.map((e) => {
                        return { attachmentId: e.id, fee: e.fee };
                    }),
                };
            } else if (offerTabInfo.maturityInterestRates[index] && offerTabInfo.maturityInterestRates[index] !== -1) {
                bid = {
                    bid: offerTabInfo.lowestSelectedMaturityPeriodPrice
                        ? offerTabInfo.lowestSelectedMaturityPeriodPrice +
                          Math.ceil(
                              offerTabInfo.lowestSelectedMaturityPeriodPrice * (offerTabInfo.maturityInterestRates[index] ?? 0) * 0.01
                          )
                        : undefined,
                    maturityOptionId: index + 1,
                    paymentPeriodId: offerTabInfo.selectedPeriodType,
                    currencyId: getCurrenyIdByName(offerTabInfo.currencyType),
                    shippingFee:
                        offerTabInfo.lowestSelectedMaturityShippingGoFee +
                        Math.ceil(
                            offerTabInfo.lowestSelectedMaturityShippingGoFee * (offerTabInfo.maturityInterestRates[index] ?? 0) * 0.01
                        ),
                    shippingToBackFee:
                        offerTabInfo.lowestSelectedMaturityShippingReturnFee +
                        Math.ceil(
                            offerTabInfo.lowestSelectedMaturityShippingReturnFee * (offerTabInfo.maturityInterestRates[index] ?? 0) * 0.01
                        ),
                    attachments: offerTabInfo.selectedAttachments.map((e) => {
                        return {
                            attachmentId: e.id,
                            fee: e.fee + Math.ceil(e.fee * (offerTabInfo.maturityInterestRates[index] ?? 0) * 0.01),
                        };
                    }),
                };
            }
            if (bid) bids.push(bid);
        });

        const machines: number[] = [];
        if (formData.machines) {
            formData.machines.forEach((mch: BasicModel | undefined) => {
                if (mch?.id) machines.push(mch.id);
            });
        }

        const offer: OfferFormEditModel = {
            billingTimeOptions: billT,
            id: offerIdN,
            machines: machines,
            bids: bids,
            expireDate: moment(moment.now()).add(15, "days").toDate(),
            paymentTypes: [3],
            additionalServices: { ...formData },
            notes: formData.notes,
            documents: docs.map((doc) => doc.id || 0),
            paymentOptions: offerTabInfo.maturityInterestRates
                .map((e, index) => {
                    if (e !== -1) return { maturityOptionId: index + 1, percent: e ?? 0 };
                    return {};
                })
                .filter((e) => e.maturityOptionId !== undefined),
        };

        const result = await doPostOffer(offer);

        if (result) {
            setOfferTabInfo({
                lowestMaturitySelected: false,
                selectedLowestMaturityId: -1,
                currencyType: "",
                maturityInterestRates: Array(4).fill(-1),
                currentTab: { name: "", index: -1 },
                currentTabTotalPrice: 0,
                lowestSelectedMaturityPeriodPrice: null,
                lowestSelectedMaturityShippingGoFee: 0,
                lowestSelectedMaturityShippingReturnFee: 0,
                availableAttachments: undefined,
                selectedAttachments: [],
                selectedPeriodType: 1,
                includedMaturityOptions: Array(4).fill(false),
                includedMaturityOptionsError: Array(4).fill(false),
                tabTypes: Array(4).fill(1),
                editPage: false,
                selectedMachines: undefined,
                requestedMachines: undefined,
            });
            navigate("/offers", { replace: true });
        } else
            setErrorMessage({
                type: "error",
                message: t("common.genericError"),
            });
    };

    const documentUploadChange = (data: DocumentModel[]) => {
        setDocs(data);
    };

    const getSum = (total: number, num: number) => {
        return total + Math.round(num);
    };

    useEffect(() => {
        setOfferTabInfo((oldVal) => {
            return {
                ...oldVal,
                currentTabTotalPrice:
                    offerTabInfo.lowestSelectedMaturityShippingGoFee +
                    offerTabInfo.lowestSelectedMaturityShippingReturnFee +
                    (offerTabInfo.lowestSelectedMaturityPeriodPrice ?? 0) +
                    offerTabInfo.selectedAttachments.map((e) => e.fee).reduce(getSum, 0),
            };
        });
        //eslint-disable-next-line
    }, [
        offerTabInfo.lowestSelectedMaturityShippingGoFee,
        offerTabInfo.lowestSelectedMaturityShippingReturnFee,
        offerTabInfo.lowestSelectedMaturityPeriodPrice,
        offerTabInfo.selectedAttachments,
    ]);

    /*     useEffect(() => {console.log("ERROR:" + JSON.stringify(offerTabInfo.includedMaturityOptionsError))},[offerTabInfo.includedMaturityOptionsError]);

    useEffect(() => console.log("RATES:" + offerTabInfo.maturityInterestRates),[offerTabInfo.maturityInterestRates]);  */

    return (
        <SecureLayout activePage={"offers"} role={"lessor"} title={t("offers.add.title")} description={t("offers.add.description")}>
            <Form name="add-offer" form={form} onFinish={onOfferFormFinish} layout="vertical">
                {loading ? (
                    <Spin size="large" tip="Lütfen Bekleyiniz" className={styles.spinMobile} />
                ) : (
                    <Row gutter={[4, 4]}>
                        <Col xs={24} md={12}>
                            <OfferSelectMachinePart
                                availableMachines={availableMachines}
                                maxMachineCount={maxMachineN}
                                offerId={offerIdN}
                            />
                        </Col>
                        <Col xs={24} md={12}>
                            <OfferAdditionalInfoPart docs={docs} uploadChange={documentUploadChange} />
                        </Col>
                        <Col xs={24} md={24}>
                            <AdditionalServicesPart isRequest={false} form={form} />
                        </Col>
                        <Col xs={24} md={24}>
                            <OfferPaymentOptionsPhase2
                                maxNumberOfAttachments={maxNumberOfAttachments}
                                availableMaturityOptions={availableMaturityOptions}
                                offerDetail={offerDetail}
                                machine={requestedMachine}
                                form={form}
                            />
                        </Col>
                        <Col xs={24} md={24}>
                            <OfferShippingOptions form={form} />
                        </Col>
                        <Col xs={24} md={24}>
                            <OfferAddAttachments maxAttachmentNumber={maxNumberOfAttachments} form={form} />
                        </Col>
                        <Col xs={24} md={24}>
                            <OfferMaturityTotal maturityType={offerTabInfo.currentTab.name} form={form} />
                        </Col>
                        <Col xs={{ span: 16, offset: 4 }} md={{ span: 6, offset: 9 }}>
                            <Form.Item>
                                <PrimaryButton loading={serviceLoading} htmlType="submit" style={{ marginTop: "1rem" }}>
                                    {t("offer.add.sendToAdmin")}
                                </PrimaryButton>
                            </Form.Item>
                        </Col>
                    </Row>
                )}
            </Form>
        </SecureLayout>
    );
};

export default AddOffer;
