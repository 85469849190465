import { Select, SelectProps } from "antd";
import { restrictNumbers, restrictSpecialCharacters } from "../../utils/input-rules";
import { useState } from "react";

type MgCharacterSelectProps = {
    allowClear?:boolean;
    showSearch?:boolean;
} & SelectProps

const MgCharacterSelect = (props: MgCharacterSelectProps)=>{

    const [searchValue,setSearchValue] = useState<string|undefined>(undefined)

    return (
        <Select 
            allowClear={props.allowClear ?? true}
            showSearch={props.showSearch ?? true}
            style={{ width: "100%" }}
            onSearch={(value:string)=>{
                const input = restrictNumbers(restrictSpecialCharacters(value))
                setSearchValue(input)
            }}
            searchValue={searchValue}
            getPopupContainer={(trigger: any) => trigger.parentElement}
            {...props}
        />
    )
}  

export default MgCharacterSelect;