import { Pagination, Row } from "antd";
import { useAtom } from "jotai";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import MachineListCard from "../../../components/atomics/machine-list-card";
import MachineListFilter from "../../../components/layouts/machine-list-filter";
import PrimaryButton from "../../../components/atomics/primary-button";
import SecureLayout from "../../../components/layouts/secure-layout";
import { MachineSearchFilterModel, MachineSearchSortField } from "../../../service";
import { SearchMachineModel } from "../../../service/models/ExtendedMachineDetailModel";
import { extendedMachineDetailAtom, loadingAtom, userAtom } from "../../../store/global-atoms";
import { useSecureService } from "../../../store/service-atoms";
import { t } from "../../../translate";
import styles from "./list.module.scss"

const ListMachines = () => {
    const navigate = useNavigate();
    const [user] = useAtom(userAtom);
    const [, editMachine] = useAtom(extendedMachineDetailAtom);
    const [activePage, setActivePage] = useState<number | undefined>(1);
    const [totalItems, setTotalItems] = useState<number | undefined>(0);
    const { doSearchMachine, doDeleteMachine } = useSecureService();
    const [loading] = useAtom(loadingAtom);
    const [filterObj, setFilterObj] = useState<{
        filterModel: MachineSearchFilterModel;
        sortBy: {
            field: MachineSearchSortField;
            sortAscending: boolean;
        };
    }>({
        filterModel: {},
        sortBy: {
            field: MachineSearchSortField.CreationDate,
            sortAscending: false,
        },
    });
    const [machines, setMachines] = useState<SearchMachineModel>({});
    const pageSize = 6;
    useEffect(() => {
        getMachines();
        //eslint-disable-next-line
    }, [activePage, filterObj]);

    const getMachines = async () => {
        const machines = await doSearchMachine({
            pageNo: activePage,
            pageSize: pageSize,
            filter: filterObj.filterModel,
            sortAscending: filterObj.sortBy.sortAscending,
            sortBy: filterObj.sortBy.field,
        });
        if (machines) {
            setMachines(machines);
            setActivePage(machines.pageNo);
            setTotalItems(machines.itemCount);
        }
    };

    const pageChange = (page: number) => {
        setActivePage(page);
    };
    const deleteClick = async (machineId: number | undefined) => {
        if (machineId) {
            const result = await doDeleteMachine(machineId);
            if (result) await getMachines();
        }
    };

    const viewClick = (machineId: number | undefined) => {
        editMachine(undefined);
        navigate("/machines/view?macid=" + machineId);
    };

    return (
        <SecureLayout
            activePage={"machines"}
            role={"lessor"}
            title={t("machines.list.title") + " (" + machines.itemCount + ")"}
            description={t("machines.list.description")}
            helperButtons={[
                <PrimaryButton
                    loading={loading}
                    onClick={() => {
                        editMachine(undefined);
                        navigate("/machines/add?compid=" + user?.id);
                    }}
                    style={{ minWidth: "240px" }}
                >
                    Makine Ekle
                </PrimaryButton>,
            ]}
        >
            <MachineListFilter
                onChange={(params) => {
                    setFilterObj({
                        filterModel: params.searchFilterModel || {},
                        sortBy: params.sortBy,
                    });
                }}
            ></MachineListFilter>
            <Row gutter={[16, 16]} style={{ marginBottom: "2rem" }}>
                {machines.machines?.map((mach) => {
                    return <MachineListCard onDeleteClick={deleteClick} onViewClick={viewClick} machine={mach} xs={24} md={8} />;
                })}
            </Row>
            <Row className={styles.paginationContainer}>
                <Pagination defaultCurrent={activePage} responsive showSizeChanger={false} pageSize={pageSize} total={totalItems} onChange={pageChange} />
            </Row>
        </SecureLayout>
    );
};

export default ListMachines;
