import { InfoCircleOutlined } from "@ant-design/icons";
import { Checkbox, Col, ColProps, Form, FormInstance, Input, InputNumber, Radio,RadioChangeEvent, Row, Select, Tooltip } from "antd";
import { CheckboxChangeEvent } from "antd/lib/checkbox";
import React, { ReactElement, useEffect, useState } from "react";
import { BasicModel } from "../../service/models/BasicModel";
import { t } from "../../translate";
import BorderedBoxContainer from "../containers/bordered-box-container";
import styles from "../styles/components.module.scss";
import { restrictPositiveNumbersInRange } from "../../utils/input-rules";

export type SuffixProps = {
    items: BasicModel[] | undefined;
    selectedItem: BasicModel | undefined;
    selectName: string | undefined;
    

};

export enum EnterInput {
    Price,
}

export type CheckboxWithInputProps = {
    checkboxLabel: string | null | undefined;
    inputLabel: string;
    inputOnylPositiveNumber?:boolean;
    checkboxName: string;
    inputName: string;
    radioButtonName?:string;
    radio?:boolean;
    initialValue?: number | null | undefined;
    suffix?: string | ReactElement;
    isSuffixDropdown?: boolean;
    suffixProps?: SuffixProps;
    tooltip?: string;
    form: FormInstance<any>;
    alwaysDisabled?: boolean;
    enterType?: EnterInput;
    radioTabs?: BasicModel[] | undefined;
    help?: string;
    checkBoxChecked?: (checked: boolean, inputName: string, selectName?: string) => void;
} & ColProps;

export const CheckboxWithInput = (props: CheckboxWithInputProps) => {
    const [checkNick, setCheckNick] = useState(false);
    const [inputVal, setInputVal] = useState("");
    const [radioValue, setRadioValue] = useState();
    const [inputDisabled, setInputDisabled] = useState(!props.initialValue || props.alwaysDisabled);
    //eslint-disable-next-line
    useEffect(() => {
        const inputVal = props.form.getFieldValue(props.inputName);
        if (inputVal && inputDisabled) setInputDisabled(false);
    });

    useEffect(() => {
        setInputVal(props.form.getFieldValue(props.inputName))
    }
    //eslint-disable-next-line
    , [props.form.getFieldValue(props.inputName)]);

    const checkboxChange = (e: CheckboxChangeEvent) => {
        setInputDisabled(!!!e.target.checked);
        setCheckNick(e.target.checked);
        if(props.checkBoxChecked) props.checkBoxChecked(e.target.checked, props.inputName, props.suffixProps?.selectName);
        setInputVal("");
    };
    const onRadioChange = (e: RadioChangeEvent) => {
        setRadioValue(e.target.value);
    };
    const containerClick = () => {
        setInputDisabled(!!!inputDisabled);
        setCheckNick(!checkNick);
        setInputVal("");
        interface LooseObject {
            [key: string]: any;
        }
        let obj: LooseObject = {};
        obj[props.checkboxName] = inputDisabled;
        props.form?.setFieldsValue(obj);
    };

    const checkboxComponent =
        props.tooltip === undefined ? (
            <div
                style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                }}
            >
                <span style={{ fontWeight: "700", cursor: "pointer" }} onClick={containerClick}>
                    {props.checkboxLabel}
                </span>
                <Form.Item name={props.checkboxName} valuePropName="checked" initialValue={!inputDisabled} style={{ marginBottom: "0rem" }}>
                    <Checkbox onChange={checkboxChange} style={{ minHeight: "0" }}></Checkbox>
                </Form.Item>
            </div>
        ) : (
            <Tooltip title={props.tooltip}>
                <Form.Item name={props.checkboxName} valuePropName="checked" initialValue={!inputDisabled} style={{ marginBottom: "0rem" }}>
                    <Checkbox onChange={checkboxChange}>
                        {props.checkboxLabel} <InfoCircleOutlined />
                    </Checkbox>
                </Form.Item>
            </Tooltip>
        );

    const SelectAfter = (suffixProps: SuffixProps) => {
        return (
            <Form.Item
                name={suffixProps.selectName}
                initialValue={suffixProps.selectedItem?.name}
                noStyle
                rules={[
                    {
                        required: checkNick,
                        message: t("common.generic-required-currency-message"),
                    },
                ]}
            >
                <Select getPopupContainer={(trigger: any) => trigger.parentElement} style={{ width: "4.75rem" }} defaultValue={suffixProps.selectedItem?.name}>
                    {suffixProps.items?.map((model) => {
                        return <Select.Option key={model.name}>{model.name}</Select.Option>;
                    })}
                </Select>
            </Form.Item>
        );
    };
    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setInputVal(e.target.value);
        if (props.enterType !== EnterInput.Price) return;

        interface LooseObject {
            [key: string]: any;
        }
        let obj: LooseObject = {};
        let parsedValue = e.target.value;
        const regexPattern = /[^0-9]/gm;
        parsedValue = parsedValue.replace(regexPattern, "");
        if (parsedValue[0] === "0") parsedValue = "";
        obj[props.inputName] = parsedValue;
        props.form?.setFieldsValue(obj);
    };

    return (
        <BorderedBoxContainer {...props}>
            {checkboxComponent}
            
            {!inputDisabled && !props.alwaysDisabled && (
                
                    <Row gutter={[16, 16]} >
                        {props.radio ===true  && (
                            <Col xs={24} md={16}>
                                <Form.Item 
                                name={props.radioButtonName}
                                label={t("common.generic-required-period-label")}
                                rules={[
                                    {
                                        required: checkNick,
                                        message: t("common.generic-required-period-message"),
                                    },
                                ]}
                                >
                                    <Radio.Group onChange={onRadioChange} value={radioValue} buttonStyle="solid" className={styles.periodRadio} >
                                        {props.radioTabs?.map((bm)=>{
                                            return(
                                                <Radio.Button value={bm.id}>{bm.name}</Radio.Button> 
                                            )
                                        })}
                                    </Radio.Group>
                                </Form.Item>
                            </Col>
                        )}
                       <Col xs={24} md={props.radio === true ? 8 : 16}>
                            <Form.Item
                                name={props.inputName}
                                label={props.inputLabel}
                                help={props.help}
                                rules={[
                                    {
                                        required: checkNick,
                                        message: t("common.generic-required-message"),
                                    },
                                ]}
                                initialValue={props.initialValue}
                            >
                                {props.isSuffixDropdown !== undefined && props.isSuffixDropdown ? (
                                <Input
                                    disabled={inputDisabled}
                                    value={inputVal || undefined}
                                    onChange={handleChange}
                                    type="text"
                                    addonAfter={
                                        <SelectAfter
                                            selectName={props.suffixProps?.selectName}
                                            items={props.suffixProps?.items}
                                            selectedItem={props.suffixProps?.selectedItem}
                                        />
                                    }
                                />):(
                                <Col xs={24} md={12}>
                                    {props.inputOnylPositiveNumber ? (
                                        <InputNumber 
                                            disabled={inputDisabled}
                                            style={{width: '100%'}}
                                            value={inputVal || undefined}
                                            onKeyDown={(event) => {
                                                const re = /[+-]/;
                                                if (re.test(event.key)) {
                                                  event.preventDefault();
                                                }
                                            }}
                                            onChange={(val) => {
                                                var num = restrictPositiveNumbersInRange(val ?? undefined, 0, Infinity);
                                                setInputVal(num ? num.toString() : "");
                                            }}
                                            type="number"
                                            addonAfter={props.suffix}
                                        />
                                    ) :  
                                    <Input disabled={inputDisabled} value={inputVal} onChange={handleChange} type="text" addonAfter={props.suffix} />}
                                </Col>
                        )}
                         </Form.Item>
                        </Col>
                    </Row>
               
            )}
        </BorderedBoxContainer>
    );
};
