import { Row } from "antd";
import CheckboxReviewer from "../components/atomics/checkbox-reviewer";
import BigboxContainer from "../components/containers/bigbox-container";
import { t } from "../translate";
import { ViewPartProps } from "../viewmodels/view-page-part-props";

const ViewAdditionalServicesPart = (props: ViewPartProps) => {
    let editedMachine = props.machine || {};
    editedMachine = {
        ...editedMachine,
        ...props.request?.additionalServices,
        ...props.additionalServices,
    };

    return (
        <BigboxContainer title={t("machines.add.additionalServicesTitle")}>
            <Row gutter={[16, 16]}>
                <CheckboxReviewer
                    inputVisible={props.viewType === "machine" || props.viewType === "offer" ? true : false}
                    checkboxLabel={t("machines.add.operator")}
                    checkboxChecked={editedMachine?.hasOperator}
                    inputLabel={t("machines.add.experience")}
                    inputVal={editedMachine?.operatorExperienceYear}
                />
                <CheckboxReviewer
                    checkboxLabel={t("machines.add.operatorCostIncluded")}
                    checkboxChecked={editedMachine?.operatorCostIncluded}
                />
                <CheckboxReviewer
                    checkboxLabel={t("machines.add.operatorInsuranceIncluded")}
                    checkboxChecked={editedMachine?.operatorInsuranceIncluded}
                />
                <CheckboxReviewer checkboxLabel={t("machines.add.shipingIncluded")} checkboxChecked={editedMachine?.shipingIncluded} />
                <CheckboxReviewer checkboxLabel={t("machines.add.fuelIncluded")} checkboxChecked={editedMachine?.fuelIncluded} />
            </Row>
        </BigboxContainer>
    );
};

export default ViewAdditionalServicesPart;
