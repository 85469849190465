import { Currency, CurrencySymbols } from "./types/currency";

const getCurrencyNameById = (currencyId: number): Currency => {
    switch (currencyId) {
        case 1:
            return "TRY";
        case 2:
            return "USD";
        case 3: 
            return "EUR";
        default:
            return "TRY";
    }
};
export const formatCurrency = (number: number | undefined, currencyId?: number): string => {
    var currencyName = getCurrencyNameById(currencyId ?? 1);
    var RoundedNumber;
    if (typeof number === "number") {
        RoundedNumber = Math.round(number);
    } else {
        RoundedNumber = number;
    }
    const formattedNumber = RoundedNumber?.toLocaleString("tr-TR", {
        style: "decimal",
        currency: currencyName,
        minimumFractionDigits: 0,
    });
  
    return `${formattedNumber} ${CurrencySymbols[currencyName]}`;
};

export const formatPriceInput = (price: string) => {

    if (price === '' || price.includes(',')) return price;

    price = price.replaceAll(".", "");
    let decimalPart = false;
    const priceArr = Array.from(price);
    var formattedPrice = priceArr.map((e, index) => {
        if (e === ','){ 
            decimalPart = true;
            return e;
        }
        else if (!decimalPart && index !== 0 && (priceArr.length - index) % 3 === 0) {
            return '.' + e;
        }
        return e;
    });
    return formattedPrice.join('');

}