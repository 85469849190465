import { Col, Form, FormInstance, Input, Row, Select } from "antd";
import { ReactElement, useEffect, useState } from "react";
import styles from "./styles/attachment-price-input.module.scss"
import { ReactComponent as DeleteIcon } from "./../components/assets/delete.svg";
import { useAtom } from "jotai";
import { BasicModel } from "../service";
import { EnterInput, SuffixProps } from "../components/atomics/checkbox-with-input";
import { offerTabAtom } from "../store/global-atoms";
import { t } from "../translate";


export type AttachmentPriceInputProps = {
    attachments: BasicModel[];
    attachmentDropdownLabel?: string;
    attachmentDropdownName: string;
    attachmentPriceInputLabel?: string;
    attachmentPriceInputName: string;
    initialValue?:  number | null | undefined;
    alwaysDisabled?: boolean;
    removeButton?: boolean;
    enterType?: EnterInput;
    suffixProps?: SuffixProps;
    suffix?: string | ReactElement;
    isSuffixDropdown?: boolean;
    isSuffixDisabled?: boolean;
    index: number;
    disabled?: boolean;
    initialAttachment?: number;
    onRemoveClick?: (index: number | undefined) => void;
    form: FormInstance<any>;
}


const AttachmentPriceInput = (props : AttachmentPriceInputProps) => {
    const [offerTabInfo, setOfferTabInfo] = useAtom(offerTabAtom);
    const [inputVal, setInputVal] = useState("");
    const [priceDisabled, setPriceDisabled] = useState<boolean>();


    useEffect(() => {
        if(props.disabled === false) setPriceDisabled(false);
        else setPriceDisabled(true);
        //eslint-disable-next-line
    }, []);

    useEffect(() => {
        if(props.suffixProps?.selectName) {
            props.form.setFieldsValue({
                [props.suffixProps?.selectName]: offerTabInfo.currencyType,
            });
        }
        //eslint-disable-next-line
    },[offerTabInfo.currencyType]);

    useEffect(() => {
        if(offerTabInfo.selectedAttachments.find((e)=>e.attachmentSelectId === props.index)){
            const newPrice = Math.ceil( (offerTabInfo.selectedAttachments.find((e)=>e.attachmentSelectId === props.index)?.fee ?? 0) + ((offerTabInfo.selectedAttachments.find((e)=>e.attachmentSelectId === props.index)?.fee ?? 0) * (offerTabInfo.maturityInterestRates[offerTabInfo.currentTab.index] ?? 0) * 0.01));
            props.form.setFieldsValue({[props.attachmentPriceInputName] : newPrice});
        }
        else {
            props.form.setFieldsValue({[props.attachmentPriceInputName] : ""});
        }
        //eslint-disable-next-line
    },[offerTabInfo.currentTab, JSON.stringify(offerTabInfo.maturityInterestRates)]);


    const SelectAfter = (suffixProps: SuffixProps) => {
        return (
            <Form.Item
                name={suffixProps.selectName}
                initialValue={suffixProps?.selectedItem?.name}
                noStyle
            >
                {props.isSuffixDisabled ? 
                    <Select getPopupContainer={(trigger: any) => trigger.parentElement} disabled suffixIcon={null} style={{ width: "4.75rem", marginLeft: '0.5rem' }} value={offerTabInfo.currencyType}>
                        {suffixProps.items?.map((model) => {
                            return <Select.Option key={model.name}>{model.name}</Select.Option>;
                        })}
                    </Select>
                : 
                    <Select getPopupContainer={(trigger: any) => trigger.parentElement} style={{ width: "4.75rem" }} defaultValue={suffixProps.selectedItem?.name}>
                        {suffixProps.items?.map((model) => {
                            return <Select.Option key={model.name}>{model.name}</Select.Option>;
                        })}
                    </Select>
                }
                
            </Form.Item>
        );
    };

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if(offerTabInfo.lowestMaturitySelected){
            setInputVal(e.target.value);
            if (props.enterType !== EnterInput.Price) return;
            interface LooseObject {
                [key: string]: any;
            }
            let obj: LooseObject = {};
            let parsedValue = e.target.value;
            const regexPattern = /[^0-9]/gm;
            parsedValue = parsedValue.replace(regexPattern, "");
            if (parsedValue[0] === "0") parsedValue = "";
            obj[props.attachmentPriceInputName] = parsedValue;
            props.form?.setFieldsValue(obj);
    
            let val = 0;
            if(e.target.value !== "" && parsedValue) {
                val = Number.parseInt(props.form.getFieldValue(props.attachmentPriceInputName));
            }
            else {
                val = 0;
            }

            if(parsedValue) {
                let attachmentList = offerTabInfo.selectedAttachments;
                attachmentList = attachmentList.map(e=> {
                    if(e.id === props.form.getFieldValue(props.attachmentDropdownName) && props.index === e.attachmentSelectId) return {id: e.id, name: e.name, fee: val , attachmentSelectId: e.attachmentSelectId}
                    else return e;
                });     
                setOfferTabInfo((oldVal) => { return {...oldVal, selectedAttachments: attachmentList}});
            }
            else if(e.target.value === ""){
                let attachmentList = offerTabInfo.selectedAttachments;
                attachmentList = attachmentList.map(e=> {
                    if(e.id === props.form.getFieldValue(props.attachmentDropdownName) && props.index === e.attachmentSelectId) return {id: e.id, name: e.name, fee: 0 , attachmentSelectId: e.attachmentSelectId}
                    else return e;
                });
        
                setOfferTabInfo((oldVal) => { return {...oldVal, selectedAttachments: attachmentList}});
            }
        }

    };

    return (
        <div>
            <Row style={{width: '100%'}}>
                <Col xs={24} md={24}>
                <Form.Item
                    name={props.attachmentDropdownName}
                    label={props.attachmentDropdownLabel}
                    initialValue={props.attachments.find(e=> e.id === props.initialAttachment)?.id}
                    rules={[
                        {
                            required: true,
                            message: t("offer.add.noAttachmentSelected"),
                        },
                    ]}
                    style={{width: '100%'}}
                >
                        <Select getPopupContainer={(trigger: any) => trigger.parentElement} disabled={!offerTabInfo.lowestMaturitySelected} className={styles.attachmentSelect} onChange={e => {
                            if(e !== undefined && e !== null){
                                setPriceDisabled(false);
                                let attachmentList = offerTabInfo.selectedAttachments;
                                if(attachmentList.find(el=> el.attachmentSelectId === props.index)){
                                    attachmentList = attachmentList.map( (attac) => {
                                        if(attac.attachmentSelectId === props.index) return {id: e, fee: attac.fee, attachmentSelectId: attac.attachmentSelectId, name: offerTabInfo.availableAttachments?.filter(el=> el.id === e).at(0)?.name ?? ''};
                                        else return attac;
                                    });
                                }
                                else {
                                    attachmentList.push({id: e, name: offerTabInfo.availableAttachments?.filter(el=> el.id === e).at(0)?.name ?? '', fee: 0, attachmentSelectId: props.index ?? 0});          
                                }
                                setOfferTabInfo((oldVal) => {return {...oldVal, selectedAttachments: attachmentList}});
                            }
                            
                            } } 
                            options={
                                offerTabInfo.availableAttachments?.map((e) => {
                                    return {value: e.id, label: e.name}
                                })
                            } 
                        >
                        </Select>
                </Form.Item>
                </Col>
            </Row>
            <Row style={{width: '100%'}}>
                <Col xs={24} md={24}>
                    <Form.Item
                        name={props.attachmentPriceInputName}
                        label={props.attachmentPriceInputLabel}
                        rules={[
                            {
                                required: true,
                                message: t("offer.add.noFeeWarning"),
                            },
                        ]}
                    >
                        {props.isSuffixDropdown !== undefined && props.isSuffixDropdown ? (
                        <Input
                            disabled={!offerTabInfo.lowestMaturitySelected || priceDisabled}
                            value={inputVal || undefined}
                            onChange={(e) => {
                                handleChange(e);
                            }
                            
                            }
                            defaultValue={""}
                            type="text"
                            addonAfter={
                                <SelectAfter
                                    selectName={props.suffixProps?.selectName}
                                    items={props.suffixProps?.items}
                                    selectedItem={props.suffixProps?.selectedItem}
                                />
                            }
                        />
                        ) : (
                        <Col xs={24} md={12}>
                            <Input disabled={props.disabled} value={inputVal} onChange={handleChange} type="text" addonAfter={props.suffix} />
                        </Col>
                        )}
                    </Form.Item>
                </Col>  
            </Row>
                
            {props.removeButton && props.removeButton === true && 
                <Row style={{width: '100%'}}>
                    <Col xs={24} md={24}>
                        <div className={styles.removeContainer}>
                            <div className={styles.removeDiv} onClick={(e) => {
                                let attachmentList = offerTabInfo.selectedAttachments.map(e=>e);
                                let newattachmentList = attachmentList.filter(e => {return e.attachmentSelectId !== props.index});
                                setOfferTabInfo((oldVal) => {return {...oldVal, selectedAttachments: newattachmentList}});
                                if(props.onRemoveClick) props.onRemoveClick(props.index);
                                props.form.setFieldsValue({
                                    [props.attachmentPriceInputName]: 2,
                                    [props.attachmentDropdownName]: null,
                                });
                                
                                }}>
                                <DeleteIcon></DeleteIcon>
                                <span>{t("attachment.add.removeButton")}</span>
                            </div>
                        </div>
                    </Col>
                </Row>
            }
            
        </div>
    );
}


export default AttachmentPriceInput;