import { Col } from "antd";
import BoxContainer from "../components/containers/box-container";
import { t } from "../translate";
import style from "./styles/machine-sale-statistic-part.module.scss"
import InfoBox from "../components/atomics/info-box";
import { SaleMachineStatisticViewModel } from "../service/models";

export interface MachineSaleStatisticPartProps {
    statistics: SaleMachineStatisticViewModel | null
}


const MachineSaleStatisticPart = ( props: MachineSaleStatisticPartProps ) => {

    return (
        <BoxContainer>
            <Col className={style.header}>{t("machine-detail.view-statistic")}</Col>
            <Col xs={24} className={style.period}>
                <InfoBox
                    head={t("machine-detail.view-statistic.detail-view")}
                    text={props.statistics?.totalMachineSaleDetailView?.toString() ?? ""}
                />
            </Col>
            <Col xs={24} className={style.period}>
                <InfoBox
                    head={t("machine-detail.view-statistic.lessor-view")}
                    text={props.statistics?.totalMachineSaleLessorView?.toString() ?? ""}
                />
            </Col>
        </BoxContainer>
    );
}

export default MachineSaleStatisticPart;