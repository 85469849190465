/* tslint:disable */
/* eslint-disable */
/**
 * MachineGo P1 API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    AuthType,
    AuthTypeFromJSON,
    AuthTypeFromJSONTyped,
    AuthTypeToJSON,
} from './AuthType';

/**
 * 
 * @export
 * @interface RenterCompanyCreateModel
 */
export interface RenterCompanyCreateModel {
    /**
     * 
     * @type {string}
     * @memberof RenterCompanyCreateModel
     */
    name?: string | null;
    /**
     * 
     * @type {string}
     * @memberof RenterCompanyCreateModel
     */
    lastName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof RenterCompanyCreateModel
     */
    email?: string | null;
    /**
     * 
     * @type {string}
     * @memberof RenterCompanyCreateModel
     */
    phone: string;
    /**
     * 
     * @type {string}
     * @memberof RenterCompanyCreateModel
     */
    companyName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof RenterCompanyCreateModel
     */
    password?: string | null;
    /**
     * 
     * @type {string}
     * @memberof RenterCompanyCreateModel
     */
    agreementVersion: string;
    /**
     * 
     * @type {string}
     * @memberof RenterCompanyCreateModel
     */
    kvkkVersion: string;
    /**
     * 
     * @type {string}
     * @memberof RenterCompanyCreateModel
     */
    ipazVersion?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof RenterCompanyCreateModel
     */
    sendActivationOTP: boolean;
    /**
     * 
     * @type {AuthType}
     * @memberof RenterCompanyCreateModel
     */
    authType?: AuthType;
}

export function RenterCompanyCreateModelFromJSON(json: any): RenterCompanyCreateModel {
    return RenterCompanyCreateModelFromJSONTyped(json, false);
}

export function RenterCompanyCreateModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): RenterCompanyCreateModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': !exists(json, 'name') ? undefined : json['name'],
        'lastName': !exists(json, 'lastName') ? undefined : json['lastName'],
        'email': !exists(json, 'email') ? undefined : json['email'],
        'phone': json['phone'],
        'companyName': !exists(json, 'companyName') ? undefined : json['companyName'],
        'password': !exists(json, 'password') ? undefined : json['password'],
        'agreementVersion': json['agreementVersion'],
        'kvkkVersion': json['kvkkVersion'],
        'ipazVersion': !exists(json, 'ipazVersion') ? undefined : json['ipazVersion'],
        'sendActivationOTP': json['sendActivationOTP'],
        'authType': !exists(json, 'authType') ? undefined : AuthTypeFromJSON(json['authType']),
    };
}

export function RenterCompanyCreateModelToJSON(value?: RenterCompanyCreateModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'lastName': value.lastName,
        'email': value.email,
        'phone': value.phone,
        'companyName': value.companyName,
        'password': value.password,
        'agreementVersion': value.agreementVersion,
        'kvkkVersion': value.kvkkVersion,
        'ipazVersion': value.ipazVersion,
        'sendActivationOTP': value.sendActivationOTP,
        'authType': AuthTypeToJSON(value.authType),
    };
}

