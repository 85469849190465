import { t } from "../translate";
import PrimaryButton from "../components/atomics/primary-button";
import ModalLayout, { ModalLayoutProps } from "../components/layouts/modal-layout";
import styles from "./request-summary.module.scss";
import { LessorCompanyProfileCreateModel, LessorCompanyProfileViewModel } from "../service";
import { Col, Form, Input, Row } from "antd";
import { useEffect } from "react";

type UpdateProfileModalProps = {
    onApprove: (profileData: LessorCompanyProfileCreateModel | undefined) => void;
    profileData: LessorCompanyProfileViewModel | undefined;
} & ModalLayoutProps;

const UpdateProfileModal = (props: UpdateProfileModalProps) => {
    const [form] = Form.useForm();

    useEffect(() => {
        form.setFieldsValue(props.profileData);
        //eslint-disable-next-line
    }, [props.profileData]);

    const onProfileUpdateFormFinish = (values: LessorCompanyProfileCreateModel | undefined) => {
        props.onApprove(values);
    };

    return (
        <ModalLayout show={props.show} onCloseClick={props.onCloseClick}>
            <h3 style={{ textAlign: "center", marginBottom: "1rem" }}>{t("lessor-profile.update.title")}</h3>
            <div className={styles.lightboxContent}>
                <Form
                    name="update-profile"
                    initialValues={props.profileData}
                    form={form}
                    onFinish={onProfileUpdateFormFinish}
                    layout="vertical"
                >
                    <Row>
                        <Col xs={24} md={{offset:6,span:12}}>
                            <Form.Item label={t("lessor-profile.update.nameLabel")} name="name"
                             rules={[
                                {pattern: /^[a-zA-ZğüşıöçĞÜŞİÖÇ\s]*$/,message: t('update-profile-info-modal.name-field')}
                            ]}
                            >
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col xs={24} md={{offset:6,span:12}}>
                            <Form.Item label={t("lessor-profile.update.surnameLabel")} name="lastName"
                             rules={[
                                {pattern: /^[a-zA-ZğüşıöçĞÜŞİÖÇ\s]*$/,message: t('update-profile-info-modal.surname-field')}
                            ]}
                            >
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col xs={24} md={{offset:6,span:12}}>
                            <Form.Item label={t("lessor-profile.update.phoneLabel")} name="phone">
                                <Input disabled />
                            </Form.Item>
                        </Col>
                        <Col xs={24} md={{offset:6,span:12}}>
                            <Form.Item label={t("lessor-profile.update.emailLabel")} name="email">
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col xs={24} md={{offset:6,span:12}}>
                            <Form.Item label={t("lessor-profile.update.titleLabel")} name="title">
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col xs={24} md={{offset:6,span:12}}>
                            <PrimaryButton type="primary" htmlType="submit">
                                {t("common.approveNow")}
                            </PrimaryButton>
                        </Col>
                    </Row>
                </Form>
            </div>
        </ModalLayout>
    );
};

export default UpdateProfileModal;
