import { useAtom } from "jotai";
import { useEffect } from "react";
import BigboxContainer from "../components/containers/bigbox-container";
import { BasicModel } from "../service/models";
import { loadingAtom } from "../store/global-atoms";
import { t } from "../translate";
import { ReactComponent as InfoCircle } from "../components/assets/infocircle.svg";
import { Form } from "antd";
import MultipleDropdownOfferEdit from "../components/atomics/multiple-dropdown-offer-edit";

type OfferSelectMachinePartProps = {
    offerId: number;
    maxMachineCount: number;
    availableMachines?: BasicModel[];
    editPageOfferedMachines?: BasicModel[];
    editPage?: boolean;
    selectedMachinesChanged?: (machines: number[]) => void;
};
const OfferSelectMachinePart = (props: OfferSelectMachinePartProps) => {
    const [loading] = useAtom(loadingAtom);

/*     useEffect(() => {
        const getSuitableMachinesToOffer = async () => {
            let result = await doGetSuitableMachinesToOffer(props.offerId);
            if(props.requestedMachine && result){
                result = [{id: props.requestedMachine.id, name: ( " - " + props.requestedMachine.subcategoryName)}, ...result]
            } 
            if(result === undefined || !result[0].id)
                return;
            const machine = await doGetMachine(result[0].id);
            if(!machine)
                return;
            if(!machine["subCategoryId"])
                return;
            const attachments = await doGetAttachments(machine["subCategoryId"]);
            setOfferTabInfo((oldVal) => { return {...oldVal, availableAttachments: attachments}}); 
        };
        getSuitableMachinesToOffer();
        // eslint-disable-next-line
    }, []);
 */
/*     useEffect(() => {
        if(availableMachines?.length !== 0 && props.requestedMachine?.subCategory){
            console.log("name:"+ props.requestedMachine?.subCategory?.name);
            setAvailableMachines((oldVal) => {
                if(props.requestedMachine && oldVal) return [{id: props.requestedMachine?.id, name: (" - " + props.requestedMachine?.subCategory?.name)}, ...oldVal];
                else if(oldVal) return [...oldVal];
                else return [];
            });
        }
    }, [props.requestedMachine?.subCategory]); */

    const checkMachines = (_: any, value: (BasicModel | undefined)[]) => {
        let valid = true;
        if (!value) valid = false;
        if (value.length === 0) valid = false;
        const anyUndefined = value.indexOf(undefined);
        if (anyUndefined > -1) valid = false;
        if (valid) {
            return Promise.resolve();
        }

        return Promise.reject(new Error(t("offers.add.noMachineSelectedError")));
    };

    const footerNote = (
        <div style={{ display: "flex", alignItems: "center", gap: ".25rem" }}>
            <InfoCircle />
            <span>{t("offers.add.machineDocumentInfoText")}</span>
        </div>
    );

    useEffect(()=> console.log("machies props:" + JSON.stringify(props.editPageOfferedMachines)),[props.editPageOfferedMachines])
    return (
        <BigboxContainer title={t("offers.add.selectMachineTitle")}> 
            <Form.Item
                name="machines"
                rules={[
                    {
                        validator: checkMachines,
                    },
                ]}
                validateTrigger= 'onSubmit'
            >
                <MultipleDropdownOfferEdit
                    loading={loading}
                    maxCount={props.maxMachineCount}
                    buttonLabel={t("offers.add.addMachine")}
                    dropdownLabel={t("offers.add.machineSelectionDropdownLabel")}
                    source={props.availableMachines}
                    editPage={props.editPage}
                    footerNote={footerNote}
                    labelSeperator=" / "
                    labels={[t("offers.add.machineCategoryLabel"), t("offers.add.machineSubcategoryLabel")]}
                /> 
            </Form.Item>
            
        </BigboxContainer>
    );
};

export default OfferSelectMachinePart;
