import { t } from "../../translate";
import SignupLayout from "../../components/layouts/signup-layout";
import { ReactComponent as UserColorful } from "../../components/assets/ic_user_colorful.svg";
import { ReactComponent as CorpColorful } from "../../components/assets/ic_corp_colorful.svg";
import styles from "../../components/styles/signup-index.module.scss";
import { Checkbox, Col, Form, Input, Row, Select } from "antd";
import TabButton from "../../components/atomics/tab-button";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import LinkButton from "../../components/atomics/link-button";
import { useQuery } from "../../helpers/use-query";
import PrimaryButton from "../../components/atomics/primary-button";
import { useForm } from "antd/lib/form/Form";
import { useSecureService } from "../../store/service-atoms";
import { useAtom } from "jotai";
import { loadingAtom, postloginMessageAtom, signUpAtom, signupServiceErrorAtom, userCredentialsAtom } from "../../store/global-atoms";
import UsernameInput from "../../components/atomics/username-input";
import { activeRootSiteUrl } from "../../store/app-constants";
import { agreementsVersions } from "../../store/agreements-versions";
import AgreementReadModal from "../../modals/agreement-read-modal";
import { AuthType, BasicModel, CategoryModel, CityModel, RequestFormCreateWithRegisterModel, UserRole } from "../../service";
import moment from "moment";
import { ReactComponent as OrangeRightArrowIcon } from "../../components/assets/orangeRightArrow.svg"
import _ from "lodash";

type FormParams = {
    name: string;
    lastName: string;
    email: string;
    phone: string;
    companyName: string;
    location: number;
    categories: [];
    totalMachines: string;
    password: string;
    agreementVersion: string;
    kvkkVersion: string;
    kvkkInfoVersion: string;
    ipazVersion: string;
};
const SignupForm = () => {
    const query = useQuery();
    const userRole = query.get("role");
    const fromRequest = query.get("request") ? "&request=true" : "";
    const [form] = useForm();
    const [isLessor, setIsLessor] = useState(userRole === "lessor");
    const navigate = useNavigate();
    const [loading] = useAtom(loadingAtom);
    const [, setLogin] = useAtom(userCredentialsAtom);
    const { doLessorSignup, doRenterSignup } = useSecureService();
    const [showReadAgreementModal, setShowReadAgreementModal] = useState<boolean>(false);
    const [signUpServiceError, setSignUpServiceError] = useAtom(signupServiceErrorAtom);
    const [selectedAgreementSrc, setSelectedAgreementSrc] = useState<string>();
    const [selectedAgreementTitle, setSelectedAgreementTitle] = useState<string>();
    const { doGetExactCities, doGetMachineSubcategory, doRenterRegisterWithRequest, doCheckUser } = useSecureService();
    const [cities, setCities] = useState<CityModel[] | undefined>(undefined);
    const [, setErrorMessage] = useAtom(postloginMessageAtom);
    const [categories, setCategories] = useState<CategoryModel[] | undefined>(undefined);
    const [selectedDistrict, setDistrict] = useState<string | undefined>(undefined);
    const [districts, setDistrictsDropdown] = useState<BasicModel[] | undefined>(undefined);
    const [signUpInfo] = useAtom(signUpAtom);
    const [selectedCity, setCity] = useState<
        | {
              id?: number | null;
              name?: string | null;
              districts?: BasicModel[] | null;
          }
        | undefined
    >(undefined);
    const { Option } = Select;
    const [isSocialSignup] = useState(signUpInfo?.authType === AuthType.Facebook || signUpInfo?.authType === AuthType.Google); 

    const description = t("signup.form.description");
    
    useEffect(() => {
        setSignUpServiceError(null);
        doGetExactCities().then((result) => {
            setCities(result);
        });

        doGetMachineSubcategory().then((result) => {
            setCategories(result);
        });

        return () => {
            setSignUpServiceError(null);
        }
        //eslint-disable-next-line
    }, []);

    const checkUser = _.debounce(async (email: string) => {
        const res = await doCheckUser({role: isLessor ? UserRole.Lessor : UserRole.Customer, email: email});
        if (typeof res === "string"){ 
            if (res === 'true') setSignUpServiceError("usedEmail");
        } else if (res) setSignUpServiceError("usedEmail");
    }, 2000);

    useEffect(() => {
        if (signUpServiceError === 'usedEmail') {
            form.validateFields(['email']);
        } else if (signUpServiceError === 'usedPhone') {
            form.validateFields(['phone']);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [signUpServiceError]);

    useEffect(() => {
        if (signUpInfo?.name) {
            form.setFieldsValue({
                name: signUpInfo.name
            });
        }
        if (signUpInfo?.lastname) {
            form.setFieldsValue({
                lastName: signUpInfo.lastname
            });
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [signUpInfo]);
    const onFormFinished = async (params: FormParams) => {
        params.phone = params.phone.replace("+90", "");
        params.phone = params.phone.replace(/\s+/g, "");
        let id: number | undefined = undefined;
        if (fromRequest === "") {
            params.agreementVersion = agreementsVersions.agreementVersion;
            params.kvkkVersion = agreementsVersions.kvkkVersion;
            params.kvkkInfoVersion = agreementsVersions.kvkkInfoVersion; 
            if (params.ipazVersion) {
                params.ipazVersion = agreementsVersions.ipazVersion;
            }  
        }
        let categorieslist: any = params.categories?.map((catName: string) => categories?.find((cat: any) => cat.name === catName)?.id);
        params.categories = categorieslist;
        if (isLessor && !selectedCity) {
            return setErrorMessage({
                message: t("common.generic-required-location-message"),
                type: "error",
            });
        }
        else if (isLessor && !selectedDistrict) {
            return setErrorMessage({
                message: t("common.generic-required-district-message"),
                type: "error",
            });
        }
        let location = selectedCity?.districts?.find((dst) => dst.name === selectedDistrict)?.id;
        params.location = location ?? 0;
        params.totalMachines = params.totalMachines === "" ? "0" : params.totalMachines;
        setLogin({
            password: params.password,
            username: params.email,
        });
        if (isLessor) {
            if (isSocialSignup)
                id = await doLessorSignup({ ...params, 
                    authType: signUpInfo?.authType, 
                    email: signUpInfo?.email, 
                    locationId: location ?? 0,  companyName: params.companyName ,totalMachines: parseInt(params.totalMachines)});
            else 
                id = await doLessorSignup({ ...params, 
                    authType: signUpInfo?.authType, 
                    locationId: location ?? 0,  
                    companyName: params.companyName,
                    totalMachines: parseInt(params.totalMachines)});
        } else {
            if (isSocialSignup) {
                if (fromRequest !== '') {
                    if (signUpInfo?.request) {
                        var req : RequestFormCreateWithRegisterModel = {
                            ...signUpInfo.request,
                            requestDateFrom: moment(signUpInfo.request.requestDateFrom).toDate(),
                            requestDateTo: moment(signUpInfo.request.requestDateTo).toDate(),
                            renterUser: {
                                ...params, 
                                ...signUpInfo.request.renterUser,
                                phone: params.phone,
                                email: signUpInfo?.authType !== AuthType.Email ? signUpInfo?.email : null, 
                                sendActivationOTP: true
                            }
                        };
                        id = await doRenterRegisterWithRequest(req);
                    }
                } else {
                    id = await doRenterSignup({...params, 
                        authType: signUpInfo?.authType, 
                        email: signUpInfo?.email, sendActivationOTP: true});
                }
            }
            else
                id = await doRenterSignup({...params, authType: signUpInfo?.authType, sendActivationOTP: true});
        }

        if (id) {
            navigate("/sms-verification?id=" + id + "&op=register&phone=" + params.phone + "&role=" + userRole + fromRequest);
        }
    };

    const tabChange = (type: "lessor" | "customer") => {
        form.resetFields();
        navigate("/signup/form?role=" + type, { replace: true });
        setIsLessor(type === "lessor");
    };

    const handleSelectedAgreement = (showModal: boolean, linkSrc: string, title: string) => {
        setShowReadAgreementModal(showModal);
        setSelectedAgreementSrc(linkSrc);
        setSelectedAgreementTitle(title);
    };
    const onCitySelected = async (selectedCityName: string) => {
        setDistrict("");
        const currentCity = cities?.find((city) => city.name === selectedCityName);
        setCity(currentCity);
        if (currentCity && currentCity.districts) setDistrictsDropdown(currentCity.districts);
        else setDistrictsDropdown(undefined);
    };

    return (
        <SignupLayout className={styles.container} descriptionBold={isSocialSignup} 
            description={isSocialSignup ? t('signup.form.socialDescription') : <div className={styles.titleContainer}><div className={styles.title}><h1>{t("signup.form.hello")}</h1></div><div className={styles.description}><span>{description}</span></div></div>}>
            <Row hidden={isSocialSignup} className={styles.signupOptionsContainer} gutter={[12, 12]}>
                <Col xs={12}>
                    <TabButton
                        icon={<CorpColorful height="20px" width="20px" />}
                        label={t("signup.index.secondCardTitle")}
                        active={isLessor}
                        onClick={(e: any) => {
                            tabChange("lessor");
                        }}
                    />
                </Col>
                <Col xs={12}>
                    <TabButton
                        icon={<UserColorful height="20px" width="20px" />}
                        label={t("signup.index.firstCardTitle")}
                        active={!isLessor}
                        onClick={(e: any) => {
                            tabChange("customer");
                        }}
                    />
                </Col>
            </Row>
            <Col xs={24} className={styles.loginContainer}>
                <Col xs={24} className={styles.loginTextUpper}>
                    <span>{t("signup.form.loginText1")}</span>
                </Col>
                <Col className={styles.loginTextContainer} onClick={() => {navigate("/login?role=" + userRole)}} xs={24}>
                    <span>{t("signup.form.loginText2")}</span>
                    <OrangeRightArrowIcon />
                </Col>
            </Col>
            <Form onFinishFailed={(e) => {
                for (let err of e.errorFields) {
                    form.scrollToField(err.name, {behavior: 'smooth', block: 'center',inline: 'center'});
                    break;
                }
            }} className={styles.formContainer} layout="vertical" form={form} onFinish={onFormFinished}>
                <Row className={styles.signupOptionsContainer} style={{ textAlign: "left" }} gutter={[20, 12]}>
                    {(
                        <Col xs={24}>
                            <Form.Item
                                label={t("signup.form.nameLabel")}
                                name="name"
                                rules={[
                                    {
                                        required: true,
                                        type: "string",
                                        message: t("signup.form.nameValidation"),
                                        pattern: /[A-Za-zıİğĞüÜşŞöÖçÇ]+/gm,
                                    },
                                ]}
                            >
                                <Input
                                    className={styles.customInput}
                                    onKeyPress={(event) => {
                                        if (!/[A-Za-zıİğĞüÜşŞöÖçÇ]+/gm.test(event.key)) {
                                            event.preventDefault();
                                        }
                                    }}
                                    placeholder={t("signup.form.namePlaceholder")}
                                />
                            </Form.Item>
                        </Col>
                        )
                    }
                    {(
                        <Col xs={24}>
                            <Form.Item
                                label={t("signup.form.surnameLabel")}
                                name="lastName"
                                rules={[
                                    {
                                        required: true,
                                        type: "string",
                                        message: t("signup.form.surnameValidation"),
                                        pattern: /[A-Za-zıİğĞüÜşŞöÖçÇ]+/gm,
                                    },
                                ]}
                            >
                                <Input
                                    className={styles.customInput}
                                    onKeyPress={(event) => {
                                        if (!/[A-Za-zıİğĞüÜşŞöÖçÇ]+/gm.test(event.key)) {
                                            event.preventDefault();
                                        }
                                    }}
                                    placeholder={t("signup.form.surnamePlaceholder")}
                                />
                            </Form.Item>
                        </Col>
                        )
                    }
                    {!isSocialSignup && (
                        <Col xs={24}>
                            <Form.Item
                                label={t("signup.form.emailLabel")}
                                name="email"
                                dependencies={signUpServiceError ? [[signUpServiceError]] : undefined}
                                rules={[
                                    {
                                        required: true,
                                        type: "email",
                                        message: t("signup.form.emailValidation"),
                                    },
                                    ({ getFieldValue }) => ({
                                        validator(_, value) {
                                          if (signUpServiceError === 'usedEmail') {
                                            return Promise.reject(new Error(t("signup.form.emailIsUsedValidationError")));
                                          } else {
                                            return Promise.resolve();
                                          }
                                        },
                                    }),
                                ]}
                            >
                                <Input onChange={(e) => {
                                    checkUser(e.currentTarget.value);
                                    if (signUpServiceError === 'usedEmail') {
                                        setSignUpServiceError(null);
                                    }
                                }} className={styles.customInput} placeholder={t("signup.form.emailPlaceholder")} />
                            </Form.Item>
                        </Col>
                        )
                    }
                    <Col xs={24}>
                        <UsernameInput className={styles.customInput} type="phone" prefix={false} />
                    </Col>
                    {!isSocialSignup && (
                        <Col xs={24}>
                            <Form.Item
                                label={t("signup.form.passwordLabel")}
                                name="password"
                                rules={[
                                    {
                                        required: true,
                                        type: "string",
                                        message: t("signup.form.passwordValidation"),
                                    },
                                ]}
                            >
                                <Input.Password className={styles.customInput} type={"password"} placeholder={t("signup.form.passwordPlaceholder")} />
                            </Form.Item>
                        </Col>
                        )
                    }
                    {!isSocialSignup && (
                        <Col xs={24}>
                            <Form.Item
                                label={t("signup.form.passwordRepeatLabel")}
                                name="passwordRepeat"
                                dependencies={['password']}
                                rules={[
                                    {
                                        required: true,
                                        type: "string",
                                        message: t("signup.form.passwordRepeatValidation"),
                                    },
                                    ({ getFieldValue }) => ({
                                        validator(_, value) {
                                          if (!value || getFieldValue('password') === value) {
                                            return Promise.resolve();
                                          }
                                          return Promise.reject(new Error(t("signup.form.passwordRepeatValidation")));
                                        },
                                    }),
                                ]}
                            >
                                <Input.Password className={styles.customInput}  placeholder={t("signup.form.passwordRepeatPlaceholder")} />
                            </Form.Item>
                        </Col>
                        )
                    }
                    <Col xs={24}>
                        <Form.Item
                            label={t("signup.form.corpLabel")}
                            name="companyName"
                            rules={[
                                {
                                    required: true,
                                    type: "string",
                                    message: t("signup.form.corpValidation"),
                                },
                            ]}
                        >
                            <Input className={styles.customInput} placeholder={t("signup.form.corpPlaceholder")} />
                        </Form.Item>
                    </Col>
                    {isLessor && (
                        <Col xs={24}>
                            <Form.Item name="location" label={t("signup.form.locationLabel")}>
                                <Row justify="end" wrap={false}>
                                    <Col xs={12} md={12}>
                                        <Select
                                            placeholder={t("request.location.city.placeholder")}
                                            loading={loading}
                                            onSelect={onCitySelected}
                                            style={{ width: "99%", borderColor: 'red' }}
                                            value={selectedCity?.name}
                                            getPopupContainer={(trigger: any) => trigger.parentElement}
                                            showArrow
                                        >
                                            {cities?.map((mc) => (
                                                <Option value={mc.name}>{mc.name}</Option>
                                            ))}
                                        </Select>
                                    </Col>
                                    <Col xs={12} md={12}>
                                        <Select
                                            placeholder={t("request.location.district.placeholder")}
                                            loading={loading}
                                            style={{ width: "99%" }}
                                            value={selectedDistrict}
                                            onSelect={(e: string) => setDistrict(e)}
                                            getPopupContainer={(trigger: any) => trigger.parentElement}
                                            showArrow
                                        >
                                            {districts?.map((msc) => (
                                                <Option value={msc.name}>{msc.name}</Option>
                                            ))}
                                        </Select>
                                    </Col>
                                </Row>
                            </Form.Item>
                        </Col>
                    )}
                    {isLessor && (
                        <Col xs={24}>
                            <Form.Item
                                name="categories"
                                label={t("signup.form.sectorLabel")}
                                rules={[
                                    {
                                        required: true,
                                        message: t("signup.form.sectorValidation"),
                                    },
                                ]}
                            >
                                <Select showArrow getPopupContainer={(trigger: any) => trigger.parentElement} onKeyDown={(event) => {
                                    if (/[0-9]/.test(event.key))
                                        event.preventDefault();
                                }} mode="multiple" placeholder={t("common.select")}>
                                    {categories?.map((city) => (
                                        <Select.Option value={city.name}>{city.name}</Select.Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                    )}
                    {isLessor && (
                        <Col style={{marginTop: 0}} xs={24}>
                            <Form.Item
                                label={t("signup.form.totalMachinesLabel")}
                                name="totalMachines"
                                rules={[
                                    {
                                        type: "string",
                                        pattern: /^[0-9]*$/,
                                        message: t("common.only.number"),
                                    },
                                ]}
                            >
                                <Input
                                    onKeyPress={(event) => {
                                        if (!/[0-9]/.test(event.key)) {
                                            event.preventDefault();
                                        }
                                    }}
                                    className={styles.customInput}
                                    placeholder={t("signup.form.totalMachinesPlaceHolder")}
                                />
                            </Form.Item>
                        </Col>
                    )}
                    {!isLessor && (
                        <Col className={styles.agreementCol} style={{marginTop: 0}} hidden={fromRequest !== ""}xs={24}>
                            <Form.Item
                                name="agreementVersion"
                                valuePropName="checked"
                                rules={[
                                    {
                                        required: fromRequest === "",
                                        type: "boolean",
                                        message: t("signup.form.bhsValidation"),
                                        validator: (_, value) =>
                                            value || fromRequest !== "" ? Promise.resolve() : Promise.reject(new Error(t("common.generic-required-message"))),
                                    },
                                ]}
                            >
                                <Checkbox className={styles.agreementCheckbox}>
                                    <LinkButton
                                        onClick={() =>
                                            handleSelectedAgreement(true, activeRootSiteUrl + "/kullanici-sozlesmesi/", "agreementVersion")
                                        }
                                        target="_blank"
                                    >
                                        <span className={styles.agreementButton}>{t("signup.form.bhsName")}</span>
                                    </LinkButton>
                                    <span className={styles.agreementSpan}>{t("signup.form.bhsLabel")}</span>
                                </Checkbox>
                            </Form.Item>
                        </Col>
                    )}
                    {isLessor && (
                        <Col className={styles.agreementCol} xs={24}>
                            <Form.Item
                                name="agreementVersion"
                                valuePropName="checked"
                                rules={[
                                    {
                                        required: true,
                                        type: "boolean",
                                        message: t("signup.form.agreementValidation"),
                                        validator: (_, value) =>
                                            value ? Promise.resolve() : Promise.reject(new Error(t("common.generic-required-message"))),
                                    },
                                ]}
                            >
                                <Checkbox className={styles.agreementCheckbox}>
                                    <LinkButton
                                        onClick={() =>
                                            handleSelectedAgreement(true, activeRootSiteUrl + "/kullanici-sozlesmesi/", "agreementVersion")
                                        }
                                        target="_blank"
                                        className={styles.agreementButton}
                                    >
                                        <span className={styles.agreementButton}>{t("signup.form.agreementName")}</span>
                                    </LinkButton>{" "}
                                    <span className={styles.agreementSpan}>{t("signup.form.agreementLabel")}</span>
                                </Checkbox>
                            </Form.Item>
                        </Col>
                    )}
                    <Col className={styles.agreementCol} hidden={!isLessor && fromRequest !== ""} xs={24}>
                        <Form.Item
                            name="kvkkVersion"
                            valuePropName="checked"
                            rules={[
                                {
                                    type: "boolean",
                                    message: t("signup.form.kvkkValidation"),
                                    required: fromRequest === "" || isLessor,
                                    validator: (_, value) =>
                                        value || fromRequest !== "" ? Promise.resolve() : Promise.reject(new Error(t("common.generic-required-message"))),
                                },
                            ]}
                        >
                            <Checkbox className={styles.agreementCheckbox}>
                                <LinkButton
                                    onClick={() => handleSelectedAgreement(true, activeRootSiteUrl + "/aydinlatma-metni/", "kvkkVersion")}
                                    target="_blank"
                                >
                                    <span className={styles.agreementButton}>{t("signup.form.kvkkName")}</span>
                                </LinkButton>
                                <span className={styles.agreementSpan}> {t("signup.form.kvkkLabel")}</span>
                            </Checkbox>
                        </Form.Item>
                    </Col>
                    {isLessor && (
                        <Col className={styles.agreementCol} xs={24}>
                            <Form.Item
                                name="kvkkInfoVersion"
                                valuePropName="checked"
                                rules={[
                                    {
                                        required: true,
                                        type: "boolean",
                                        message: t("signup.form.kvkkInfoValidation"),
                                        validator: (_, value) => {
                                            if (value){
                                                return Promise.resolve();
                                            } else {
                                                return Promise.reject(new Error(t("common.generic-required-message")));
                                            }  

                                        }
                                    },
                                ]}
                            >
                                <Checkbox className={styles.agreementCheckbox}>
                                    <LinkButton
                                        onClick={() =>
                                            handleSelectedAgreement(
                                                true,
                                                activeRootSiteUrl + "/kurumsal-ve-bireysel-uye-acik-riza-metni/",
                                                "kvkkInfoVersion"
                                            )
                                        }
                                        target="_blank"
                                    >
                                        <span className={styles.agreementButton}>{t("signup.form.kvkkInfoName")}</span>
                                    </LinkButton>
                                    <span className={styles.agreementSpan}>{t("signup.form.kvkkInfoLabel")}</span>
                                </Checkbox>
                            </Form.Item>
                        </Col>
                    )}
                    <Col className={styles.agreementCol + (userRole === 'lessor' ? (' ' +  styles.ipazMargin) : '') } hidden={!isLessor && fromRequest !== ""} xs={24}>
                        <Form.Item
                            name="ipazVersion"
                            valuePropName="checked"
                            rules={[
                                {
                                    type: "boolean",
                                    message: t("signup.form.ipazValidation"),
                                },
                            ]}
                        >
                            <Checkbox  className={styles.agreementCheckbox}>
                                <LinkButton
                                    onClick={() =>
                                        handleSelectedAgreement(true, activeRootSiteUrl + "/izinli-pazarlama-onay-metni/", "ipazVersion")
                                    }
                                    target="_blank"
                                >
                                    <span className={styles.agreementButton}>{t("signup.form.ipazName")}</span>
                                </LinkButton>
                                <span className={styles.agreementSpan}> {t("signup.form.ipazLabel")}</span>
                            </Checkbox>
                        </Form.Item>
                    </Col>
                </Row>
                <Row align="middle" justify="center">
                    <Col xs={24}>
                        <PrimaryButton loading={loading} onClick={()=> {
                            console.log(JSON.stringify(form.validateFields().catch(e => {})));
                        }} htmlType="submit">
                            {t("signup.form.submit")}
                        </PrimaryButton>
                    </Col>

                    <Col xs={24} className={styles.hasProblemContainer}>
                        <a href={'tel:0212 706 0330'}>{t("signup.form.hasProblemText")}</a>
                    </Col>
                </Row>
            </Form>
            {selectedAgreementTitle && selectedAgreementSrc && (
                <AgreementReadModal
                    show={showReadAgreementModal}
                    title={selectedAgreementTitle}
                    agreementSrc={selectedAgreementSrc}
                    onCloseClick={() => {
                        setShowReadAgreementModal(false);
                    }}
                    onButtonClick={() => {
                        form.setFieldsValue({ [selectedAgreementTitle]: true });
                        setShowReadAgreementModal(false);
                    }}
                />
            )}
        </SignupLayout>
    );
};

export default SignupForm;
