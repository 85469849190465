import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import "./extensions/impls/string.extensions";
import { BrowserRouter } from "react-router-dom";
import ScrollToTop from "./helpers/scroll-to-top";
import { ConfigProvider } from "antd";
import { isNoIndexActive } from "./store/app-constants";

if (process.env.NODE_ENV === "production") {
    console.log = () => {};
    console.error = () => {};
    console.debug = () => {};
} 
if (isNoIndexActive) {
    const noindexMeta = document.createElement('meta');
    noindexMeta.name = 'robots';
    noindexMeta.content = 'noindex,nofollow';
    document.getElementsByTagName('head')[0].appendChild(noindexMeta);
}
console.log("\n\n:::MACHINEGO:::\n\n");

//initGoogleSDK();
//initFacebookSDK();

ReactDOM.render(
        <BrowserRouter>
            <ScrollToTop />
            <ConfigProvider virtual={false}>
                <App />
            </ConfigProvider>
        </BrowserRouter>    
    ,
    document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
