import React, { Key, useEffect, useState } from "react";
import { Input, Tree } from "antd";
import type { TreeProps } from "antd/es/tree";
import { BasicModel, CategoryModel } from "../../service";

type SearchableTreeProps = {
    data: CategoryModel[] | BasicModel[] | undefined;
    categories: string[];
    selectedKeys?:
        | Key[]
        | {
              checked: Key[];
              halfChecked: Key[];
          };
    onItemChecked: (
        categoryIds: number[],
        subcategoryIds: number[],
        selectedKeys?:
            | Key[]
            | {
                  checked: Key[];
                  halfChecked: Key[];
              }
    ) => void;
};

const { Search } = Input;

const SearchableTree = (props: SearchableTreeProps) => {
    const [searchText, setSearchText] = useState<string>("");
    const [searchQuery, setSearchQuery] = useState<string>("");
    const [expandedKeys, setExpandedKeys] = useState<React.Key[]>([]);
    const [, setSelectedKeys] = useState<React.Key[]>([]);
    const [autoExpandParent, setAutoExpandParent] = useState(false);

    const onSelect: TreeProps["onSelect"] = (selectedKeys, info) => {
        console.log("selected", selectedKeys, info);
    };

    const onCheck: TreeProps["onCheck"] = (checkedKeys, info) => {
        const checkedItems = info.checkedNodes.map((node) => node.key.toString());

        const categoryIds: number[] = [];
        const subcategoryIds: number[] = [];

        checkedItems.forEach((item) => {
            if (item.startsWith("cat-")) {
                const categoryId = parseInt(item.replace("cat-", ""), 10);
                categoryIds.push(categoryId);
            } else {
                const subcategoryId = parseInt(item, 10);
                subcategoryIds.push(subcategoryId);
            }
        });

        // Call the callback with the category IDs and subcategory IDs
        props.onItemChecked(categoryIds, subcategoryIds, checkedKeys);
    };

    const handleSearch = (value: string) => {
        const pattern = /^[a-zA-ZğüşıöçĞÜŞİÖÇ\s]*$/;
        if (pattern.test(value)) {
            setSearchText(value);
            setSearchQuery(value);
            setAutoExpandParent(true);
        }

        const matchedKeys: string[] = [];
        const keys = Object.keys(misspelledWords);

        keys.forEach((key) => {
            const misspelledVariants = misspelledWords[key];
            const lowerCaseValue = value.toLowerCase();

            if (
                key.toLowerCase().includes(lowerCaseValue) ||
                misspelledVariants.some((word) => word.toLowerCase().includes(lowerCaseValue))
            ) {
                matchedKeys.push(key);
            }
        });

        if (matchedKeys.length > 0) {
            const updatedSearchQuery = matchedKeys.join(",") + "," + value;
            console.log(updatedSearchQuery);
            setSearchQuery(updatedSearchQuery);
            setAutoExpandParent(true);
        } else {
            setAutoExpandParent(false);
        }
    };

    const onExpand = (newExpandedKeys: React.Key[]) => {
        setExpandedKeys(newExpandedKeys);
        setAutoExpandParent(false);
    };

    const misspelledWords = {
        ekskavatör: ["Kato", "Excavator", "Excavatör", "Eskavatör", "Eskavator", "Kepçe", "Ekskavator"],
        "Mini Ekskavatör": [
            "Mini Excavator",
            "Mini Excavatör",
            "Mini Eskavatör",
            "Mini Eskavator",
            "Mini Kepçe",
            "Mini Ekskavator",
            "Mini Kato",
        ],
        Loder: ["Löder", "Loader", "lader"],
        Kamyon: ["Kırkayak", "Kırk ayak"],
        Forklift: ["Porflift", "Forlift", "Porklift", "portif"],
        "Kamyon üstü Vinç": ["Hiyap", "Hiyap", "Hiyip", "Kamyonüstü vinç", "Hiyap vinç", "hayap"],
        "Makaslı Platform": ["Uçan Halı", "personel yükseltici", "manlift", "makas platform"],
        "Beko Loder": ["JCB", "jisibi", "jicibi", "cisibi", "Beko loader", "Beko löder", "Beko lader", "bekolader"],
        Reachtruck: ["Yengeç", "Riçtruck", "Riçtrak", "Riçtırak", "Rictruck", "Rictrak", "Rictırak", "reach truck"],
        Telehandler: ["Manitou", "Manitu", "Manito", "Manuto"],

        // Add more misspelled words for other keys as needed
    } as { [key: string]: string[] };

    const filterCategories = (categories: CategoryModel[] | undefined, query: string) => {
        if (!categories) return [];

        const queries = query.split(",").map((q) => q.trim()); // Split the query by commas and trim each part
        const filteredCategories: CategoryModel[] = [];

        categories.forEach((category) => {
            const categoryName = category.name?.toLowerCase();

            if (categoryName && queries.some((q) => categoryName.includes(q.toLowerCase()))) {
                filteredCategories.push(category);
            } else if (category.subCategories && category.subCategories.length > 0) {
                const filteredSubCategories = filterCategories(category.subCategories, query);
                if (filteredSubCategories.length > 0) {
                    filteredCategories.push({
                        ...category,
                        subCategories: filteredSubCategories,
                    });
                }
            }
        });

        return filteredCategories;
    };

    const generateTreeData = (categories: CategoryModel[] | undefined, isSubCategory = false) => {
        if (!categories) return [];

        return categories.map((item) => {
            const isExpanded = expandedKeys.includes(String(item.id)); // Check if the node should be expanded
            const key = isSubCategory ? item.id : "cat-" + item.id; // Adjust the key based on whether it's a subcategory or category
            const treeNode: any = {
                key: key,
                title: item.name,
                id: item.id?.toString(),
                expanded: isExpanded, // Set the expanded prop based on isExpanded
            };

            if (item.subCategories && item.subCategories.length > 0) {
                treeNode.children = generateTreeData(item.subCategories, true); // Pass 'true' to indicate it's a subcategory
            }

            return treeNode;
        });
    };

    useEffect(() => {
        // Update expandedKeys when categories change
        if (props.data) {
            const allKeys = props.data.flatMap((category) => String(category.id));
            setExpandedKeys(allKeys);
        }
    }, [props.data]);

    useEffect(() => {
        if (props.categories) {
            setSelectedKeys(props.categories);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.categories]);

    const filteredCategories = filterCategories(props?.data, searchQuery);
    const treeData = generateTreeData(filteredCategories);

    return (
        <div>
            <Search style={{ marginBottom: "1rem" }} value={searchText} placeholder="Ara" onChange={(e) => handleSearch(e.target.value)} />
            <Tree
                style={{ maxHeight: "50vh", overflow: "scroll" }}
                checkedKeys={props.selectedKeys}
                checkable
                onSelect={onSelect}
                onCheck={onCheck}
                icon={false}
                multiple={false}
                expandedKeys={expandedKeys} // Pass the expandedKeys to the Tree component
                onExpand={onExpand} // Update expandedKeys when nodes are expanded or collapsed
                autoExpandParent={autoExpandParent}
                treeData={treeData} // Pass the generated tree data to the Tree component
            />
        </div>
    );
};

export default SearchableTree;
