/* tslint:disable */
/* eslint-disable */
/**
 * MachineGo P1 API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface OfferFormBidCommissionCreateModel
 */
export interface OfferFormBidCommissionCreateModel {
    /**
     * 
     * @type {number}
     * @memberof OfferFormBidCommissionCreateModel
     */
    maturityOptionId?: number;
    /**
     * 
     * @type {number}
     * @memberof OfferFormBidCommissionCreateModel
     */
    predictedTotal?: number;
    /**
     * 
     * @type {number}
     * @memberof OfferFormBidCommissionCreateModel
     */
    machineGoCommission?: number;
    /**
     * 
     * @type {number}
     * @memberof OfferFormBidCommissionCreateModel
     */
    advancePayment?: number;
    /**
     * 
     * @type {number}
     * @memberof OfferFormBidCommissionCreateModel
     */
    renterPeriodPayment?: number;
    /**
     * 
     * @type {number}
     * @memberof OfferFormBidCommissionCreateModel
     */
    lessorMachineGoCommission?: number;
    /**
     * 
     * @type {number}
     * @memberof OfferFormBidCommissionCreateModel
     */
    lessorPeriodPayment?: number;
    /**
     * 
     * @type {string}
     * @memberof OfferFormBidCommissionCreateModel
     */
    paymentLink?: string | null;
    /**
     * 
     * @type {number}
     * @memberof OfferFormBidCommissionCreateModel
     */
    shippingFee?: number;
    /**
     * 
     * @type {number}
     * @memberof OfferFormBidCommissionCreateModel
     */
    shippingToBackFee?: number;
}

export function OfferFormBidCommissionCreateModelFromJSON(json: any): OfferFormBidCommissionCreateModel {
    return OfferFormBidCommissionCreateModelFromJSONTyped(json, false);
}

export function OfferFormBidCommissionCreateModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): OfferFormBidCommissionCreateModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'maturityOptionId': !exists(json, 'maturityOptionId') ? undefined : json['maturityOptionId'],
        'predictedTotal': !exists(json, 'predictedTotal') ? undefined : json['predictedTotal'],
        'machineGoCommission': !exists(json, 'machineGoCommission') ? undefined : json['machineGoCommission'],
        'advancePayment': !exists(json, 'advancePayment') ? undefined : json['advancePayment'],
        'renterPeriodPayment': !exists(json, 'renterPeriodPayment') ? undefined : json['renterPeriodPayment'],
        'lessorMachineGoCommission': !exists(json, 'lessorMachineGoCommission') ? undefined : json['lessorMachineGoCommission'],
        'lessorPeriodPayment': !exists(json, 'lessorPeriodPayment') ? undefined : json['lessorPeriodPayment'],
        'paymentLink': !exists(json, 'paymentLink') ? undefined : json['paymentLink'],
        'shippingFee': !exists(json, 'shippingFee') ? undefined : json['shippingFee'],
        'shippingToBackFee': !exists(json, 'shippingToBackFee') ? undefined : json['shippingToBackFee'],
    };
}

export function OfferFormBidCommissionCreateModelToJSON(value?: OfferFormBidCommissionCreateModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'maturityOptionId': value.maturityOptionId,
        'predictedTotal': value.predictedTotal,
        'machineGoCommission': value.machineGoCommission,
        'advancePayment': value.advancePayment,
        'renterPeriodPayment': value.renterPeriodPayment,
        'lessorMachineGoCommission': value.lessorMachineGoCommission,
        'lessorPeriodPayment': value.lessorPeriodPayment,
        'paymentLink': value.paymentLink,
        'shippingFee': value.shippingFee,
        'shippingToBackFee': value.shippingToBackFee,
    };
}

