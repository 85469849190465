import { Card, Col, Row, Tooltip } from "antd";
import { t } from "../../translate";
import PrimaryButton from "./primary-button";
import style from "./product-card.module.scss";
import { useNavigate } from "react-router-dom";
import { formatCurrency } from "../../helpers/formatters";
import  { ReactComponent as OperatorIcon} from '../assets/operator.svg';

import {
    BomLength,
    BrandName,
    BrandOther,
    CarryingCapacity,
    EnginePower,
    CameraResolution,
    FlightTime,
    FuelType,
    MaxHeight,
    MaxLoadCapacity,
    MaxPower,
    MinPower,
    ModelName,
    ModelYear,
    NoMustProp,
    NominalCapacity,
    OperatingHeight,
    OperatingWeight,
    OperationHour,
    OperationMileage,
    SpraySpeed,
    TankVolume,
    Volume,
    LoadCapacity,
} from "./card-items";

type ProductProps = {
    id: string;
    categoryId: number;
    subCategoryId: number;
    children?: any;
    head: string;
    text: string;
    image: any;
    daily: string;
    clickable?: boolean;
    monthly: string;
    brandName: string;
    brandOther: string;
    modelName: string;
    modelYear: string;
    operatingWeight: string;
    operatingHeight: string;
    bomLength: string;
    carryingCapacity: string;
    enginePower: string;
    fuelType: string;
    maxHeight: string;
    maxPower: string;
    minPower: string;
    maxLoadCapacity: number;
    nominalCapacity: string;
    operationHour: string;
    operationMileage: string;
    spraySpeed: string;
    tankVolume: string;
    volume: string;
    cameraResolution: string;
    flightTime: string;
    loadCapacity: number;
    hasOperator: boolean;
    productCount: number; 
};
const ProductCard = (props: ProductProps) => {
    const navigate = useNavigate();

    const handleClick = () => {
        navigate("/rental-machine-detail?macid=" + props.id);
    };

    let isDailyNull = props.daily === formatCurrency(0);
    let isMonthlyNull = props.monthly === formatCurrency(0);

    return (
        <Card
            size="default"
            hoverable
            className={props.productCount > 1 ? style.productCard : style.oneProductCard}
            bordered
            // onClick={() => { handleClick() }}
            cover={
                <a href={`/rental-machine-detail?macid=` + props.id}>
                    <Col className={style.productCardImage}>{props.image}</Col>
                </a>
            }
        >
            <a href={`/rental-machine-detail?macid=` + props.id}>
                <Col className={style.productBrand}>
                    {props.brandOther ? (
                        <>
                            {BrandOther(props.categoryId, props.subCategoryId, props?.brandOther)} -
                            {ModelName(props.categoryId, props.subCategoryId, props?.modelName)}
                        </>
                    ) : (
                        props.brandName && (
                            <>
                                {BrandName(props.categoryId, props.subCategoryId, props?.brandName)} -
                                {ModelName(props.categoryId, props.subCategoryId, props?.modelName)}
                            </>
                        )
                    )}
                </Col>
                <Col className={style.productSubCategory}>{props.text}</Col>
                <Col>
                    <Row style={{ alignItems: "center" }}>
                        {props.brandName && (
                            <>
                                {ModelYear(props.categoryId, props.subCategoryId, props?.modelYear)}
                                {OperatingWeight(props.categoryId, props.subCategoryId, props?.operatingWeight)}
                                {OperatingHeight(props.categoryId, props.subCategoryId, props?.operatingHeight)}
                                {BomLength(props.categoryId, props.subCategoryId, props?.bomLength)}
                                {CarryingCapacity(props.categoryId, props.subCategoryId, props?.carryingCapacity)}
                                {EnginePower(props.categoryId, props.subCategoryId, props?.enginePower)}
                                {FuelType(props.categoryId, props.subCategoryId, props?.fuelType)}
                                {MaxHeight(props.categoryId, props.subCategoryId, props?.maxHeight)}
                                {MaxPower(props.categoryId, props.subCategoryId, props?.maxPower)}
                                {MinPower(props.categoryId, props.subCategoryId, props?.minPower)}
                                {MaxLoadCapacity(props.categoryId, props.subCategoryId, props?.maxLoadCapacity)}
                                {NominalCapacity(props.categoryId, props.subCategoryId, props?.nominalCapacity)}
                                {OperationHour(props.categoryId, props.subCategoryId, props?.operationHour)}
                                {OperationMileage(props.categoryId, props.subCategoryId, props?.operationMileage)}
                                {SpraySpeed(props.categoryId, props.subCategoryId, props?.spraySpeed)}
                                {TankVolume(props.categoryId, props.subCategoryId, props?.tankVolume)}
                                {Volume(props.categoryId, props.subCategoryId, props?.volume)}
                                {FlightTime(props.categoryId, props.subCategoryId, props?.flightTime)}
                                {CameraResolution(props.categoryId, props.subCategoryId, props?.cameraResolution)}
                                {LoadCapacity(props.categoryId, props.subCategoryId, props?.loadCapacity)}
                                {NoMustProp(props.categoryId, props.subCategoryId, props?.volume)}
                            </>
                        )}
                        <Col className={style.period} md={8} xs={6} style={{ display:'flex', alignItems:'center', visibility: isDailyNull ? "hidden" : "visible" }}>
                            {t("common.daily")}
                            {props.hasOperator && (
                                <Col md={1} xs={1}>
                                    <Tooltip placement="top" title={"Operatör Dahil"}>
                                        <OperatorIcon />
                                    </Tooltip>
                                </Col>
                            )}
                        </Col>
                        <Col className={style.price} md={16} xs={18} style={{ visibility: isDailyNull ? "hidden" : "visible" }}>
                            {props.daily}
                        </Col>
                        <Col className={style.period} md={8} xs={6} style={{ display:'flex', alignItems:'center',visibility: isMonthlyNull ? "hidden" : "visible" }}>
                            {t("common.monthly")}
                            {props.hasOperator && (
                                <Col md={1} xs={1}>
                                    <Tooltip placement="top" title={"Operatör Dahil"}>
                                        <OperatorIcon />
                                    </Tooltip>
                                </Col>
                            )}
                        </Col>
                        <Col className={style.price} md={16} xs={18} style={{ visibility: isMonthlyNull ? "hidden" : "visible" }}>
                            {props.monthly}
                        </Col>
                    </Row>
                </Col>
                <Col>
                    <PrimaryButton onClick={handleClick} style={{ width: "100%", marginTop: "1rem" }}>
                        {t("common.rent")}
                    </PrimaryButton>
                </Col>
            </a>
        </Card>
    );
};
export default ProductCard;
