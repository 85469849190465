import { Col, Row } from "antd";
import { t } from "../translate";
import styles from "../components/styles/secure-layout.module.scss";
import { OfferFormMachineDetailViewModel } from "../service/models";
import BoxContainer from "../components/containers/box-container";
import BasicModelViewer from "../components/atomics/basic-model-viewer";
import { moment } from "../helpers/moment";
import PrimaryButton from "../components/atomics/primary-button";
import { useNavigate } from "react-router-dom";

const MachineRelatedOffersPart = (props: { offers?: OfferFormMachineDetailViewModel[] }) => {
    const navigate = useNavigate();
    return (
        <>
            <Row className={styles.headerArea}>
                <Col xs={24}>
                    <h1 className={styles.title}>{t("machines.view.relatedOffersTitle")}</h1>
                    <span className={styles.description} style={{ fontWeight: "lighter" }}>
                        {t("machines.view.relatedOffersDescription")}
                    </span>
                </Col>
            </Row>
            <Row gutter={[16, 16]} style={{ paddingBottom: "2rem" }}>
                {props.offers?.map((offer) => {
                    return (
                        <Col xs={24} md={8}>
                            <BoxContainer marginless>
                                <Row gutter={[8, 8]}>
                                    <BasicModelViewer objKey={t("machines.view.offerIdTitle")} value={offer.offerFormId} />
                                    <BasicModelViewer objKey={t("machines.view.requestIdTitle")} value={offer.requestFormId} />
                                    <BasicModelViewer
                                        objKey={t("machines.view.locationTitle")}
                                        value={offer.district?.name + " - " + offer.district?.city?.name}
                                    />
                                    <BasicModelViewer
                                        objKey={t("machines.view.offerDateRange")}
                                        value={moment(offer.reqeustDateFrom) + " - " + moment(offer.reqeustDateTo)}
                                    />
                                    <BasicModelViewer objKey={t("machines.view.bidTitle")} value={offer.bid} />
                                    <BasicModelViewer objKey={t("machines.view.statusTitle")} value={t("offer-status." + offer.status)} />
                                    <Col xs={24}>
                                        <PrimaryButton
                                            onClick={() =>
                                                navigate("/offers/view?offerid=" + offer.offerFormId + "&reqid=" + offer.requestFormId)
                                            }
                                        >
                                            {t("common.details")}
                                        </PrimaryButton>
                                    </Col>
                                </Row>
                            </BoxContainer>
                        </Col>
                    );
                })}
            </Row>
        </>
    );
};

export default MachineRelatedOffersPart;
