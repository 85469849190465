import { Badge, Button, Dropdown, Menu, Space } from "antd";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { NotificationDetailModel } from "../../service/models/NotificationDetailModel";
import { useSecureService } from "../../store/service-atoms";
import { t } from "../../translate";
import notificationImage from "../assets/ic_notification.png";

type NotificationBellProps = {
    userId: string | undefined;
};

const NotificationBell = (props: NotificationBellProps) => {
    const { doGetNotifications } = useSecureService();
    const { doGetNotificationCount } = useSecureService();
    const [notifications, setNotifications] = useState<NotificationDetailModel[]>();
    const [notificationCount, setNotificationCount] = useState<string>();

    const navigate = useNavigate();

    useEffect(() => {
        const getNotifications = async () => {
            const result = await doGetNotificationCount(props.userId);
            setNotificationCount(result?.data?.toString());
            if (result?.data === 0) {
                setNotificationCount("");
            }
        };
        getNotifications();
        //eslint-disable-next-line
    }, []);

    const menu = () => {
        return notifications?.length ? (
            <Menu>
                {notifications?.map((val) => {
                    return (
                        <Menu.Item key={val.id}>
                            <div
                                onClick={() => {
                                    if (val.receiverRole === "Renter") {
                                        navigate("/requests/view?reqid=" + val.requestId);
                                    } else if (val.receiverRole === "Lessor") {
                                        if (val.status !== "Suggested") {
                                            navigate("/offers/view?reqid=" + val.requestId + "&offerid=" + val.offerId);
                                        }
                                        else {
                                            navigate("/requests/view?reqid=" + val.requestId + "&offerid=" + val.offerId)
                                        }         
                                    }
                                }}
                            >
                                {val.text?.toString()}
                            </div>
                            <Space></Space>
                        </Menu.Item>
                    );
                })}
                <Menu.Item>
                    <Button
                        onClick={() => {
                            navigate("/notifications");
                        }}
                    >
                        {t("notification.show.all")}
                    </Button>
                </Menu.Item>
            </Menu>
        ) : (
            <Menu>
                <Menu.Item>
                    <p>{t("notification.null.message")}</p>
                </Menu.Item>
                <Menu.Item>
                    <Button
                        onClick={() => {
                            navigate("/notifications");
                        }}
                    >
                        {t("notification.show.all")}
                    </Button>
                </Menu.Item>
            </Menu>
        );
    };

    const getNotifications = async () => {
        const result = await doGetNotifications(props.userId);
        setNotifications(result);
        setNotificationCount("");
    };

    return (
        <Button
            style={{ border: "none" }}
            onClick={async () => {
                await getNotifications();
            }}
        >
            <Dropdown overlay={menu} trigger={["click"]}>
                <div className="ant-dropdown-link" onClick={(e) => e.preventDefault()}>
                    <Badge count={notificationCount}>
                        <img alt="bell-icon" src={notificationImage} />
                    </Badge>
                </div>
            </Dropdown>
        </Button>
    );
};

export default NotificationBell;
