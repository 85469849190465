/* tslint:disable */
/* eslint-disable */
/**
 * MachineGo P1 API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface MachineSaleDepositFeeCreateModel
 */
export interface MachineSaleDepositFeeCreateModel {
    /**
     * 
     * @type {number}
     * @memberof MachineSaleDepositFeeCreateModel
     */
    categoryId?: number;
    /**
     * 
     * @type {number}
     * @memberof MachineSaleDepositFeeCreateModel
     */
    subCategoryId?: number;
    /**
     * 
     * @type {number}
     * @memberof MachineSaleDepositFeeCreateModel
     */
    depositFee?: number;
}

export function MachineSaleDepositFeeCreateModelFromJSON(json: any): MachineSaleDepositFeeCreateModel {
    return MachineSaleDepositFeeCreateModelFromJSONTyped(json, false);
}

export function MachineSaleDepositFeeCreateModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): MachineSaleDepositFeeCreateModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'categoryId': !exists(json, 'categoryId') ? undefined : json['categoryId'],
        'subCategoryId': !exists(json, 'subCategoryId') ? undefined : json['subCategoryId'],
        'depositFee': !exists(json, 'depositFee') ? undefined : json['depositFee'],
    };
}

export function MachineSaleDepositFeeCreateModelToJSON(value?: MachineSaleDepositFeeCreateModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'categoryId': value.categoryId,
        'subCategoryId': value.subCategoryId,
        'depositFee': value.depositFee,
    };
}

