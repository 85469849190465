import { t } from "../translate";
import PrimaryButton from "../components/atomics/primary-button";
import ModalLayout, { ModalLayoutProps } from "../components/layouts/modal-layout";
import styles from "./request-summary.module.scss";
import { BasicModel, CategoryModel, CityModel, LessorCompanyDetailViewModel, LessorCompanyInfoUpdateModel } from "../service";
import { Col, Form, Input, Row, Select } from "antd";
import { useEffect, useState } from "react";
import TextArea from "antd/lib/input/TextArea";
import { loadingAtom, postloginMessageAtom } from "../store/global-atoms";
import { useAtom } from "jotai";

type UpdateCompanyInfoModalProps = {
    onApprove: (infoData: LessorCompanyInfoUpdateModel | undefined) => void;
    infoData: LessorCompanyDetailViewModel | undefined;
    citiesList: CityModel[] | undefined;
    categoriesList: CategoryModel[] | undefined;
} & ModalLayoutProps;

const UpdateCompanyInfoModal = (props: UpdateCompanyInfoModalProps) => {
    const [form] = Form.useForm();
    const { Option } = Select;
    const [loading] = useAtom(loadingAtom);
    const [selectedDistrict, setDistrict] = useState<string | null |undefined>(undefined);
    const [districts, setDistrictsDropdown] = useState<BasicModel[] | undefined>(undefined);
    const [, setErrorMessage] = useAtom(postloginMessageAtom);
    const [selectedCity, setCity] = useState<
        | {
              id?: number | null;
              name?: string | null;
              districts?: BasicModel[] | null;
          }
        | undefined
    >(undefined);
    useEffect(() => {
        form.setFieldsValue({
            ...props.infoData,
            serviceRegionsList: props.infoData?.serviceRegions?.map((item) => item.name),
            categories: props.infoData?.categories?.map((item) => item.name),
        });
        if (props.infoData?.location) {
            setCity(props.citiesList?.find((city) => city.name === props.infoData?.location?.city?.name));
        }
        //eslint-disable-next-line
    }, [props.infoData]);
    useEffect(() => {
        setDistrict(selectedCity?.districts?.find((district) => district.name === props.infoData?.location?.name)?.name);
        //eslint-disable-next-line
    },[selectedCity])

    const onInfoUpdateFormFinish = (values: any) => {
        values.serviceRegions = values.serviceRegionsList?.map(
            (itemName: string) => props.citiesList?.find((item) => item.name === itemName)?.id
        );
        values.categories = values.categories?.map(
            (itemName: string) => props.categoriesList?.find((item) => item.name === itemName)?.id
        );
        values.locationId = selectedCity?.districts?.find((dist) => dist.name === selectedDistrict)?.id;
        if (!selectedDistrict && !selectedCity) {
            return setErrorMessage({
                message: t("common.generic-required-district-message"),
                type: "error",
            });
        }
        values.totalMachines = values.totalMachines === "" ? 0 : values.totalMachines;
        values.operatorCount = values.operatorCount === "" ? 0 : values.operatorCount;
        values.employeeCount = values.employeeCount === "" ? 0 : values.employeeCount;
        props.onApprove(values);
    };
    const onCitySelected = async (selectedCityName: string) => {
        setDistrict("");
        const currentCity = props.citiesList?.find((city) => city.name === selectedCityName);
        setCity(currentCity);
        if (currentCity && currentCity.districts) setDistrictsDropdown(currentCity.districts);
        else setDistrictsDropdown(undefined);
    };


    return (
        <ModalLayout show={props.show} onCloseClick={props.onCloseClick}>
            <h3 style={{ textAlign: "center", marginBottom: "1rem" }}>{t("lessor-profile.updateInfo.title")}</h3>
            <div className={styles.lightboxContent}>
                <Form name="update-info" initialValues={props.infoData} form={form} onFinish={onInfoUpdateFormFinish} layout="vertical">
                    <Row gutter={[16, 0]}>
                        <Col xs={24} md={12}>
                            <Form.Item label={t("lessor-profile.updateInfo.companyNameLabel")} name="companyName">
                                <Input placeholder={t("lessor-profile.updateInfo.companyNamePlaceholder")} />
                            </Form.Item>
                        </Col>
                        <Col xs={24} md={12}>
                            <Form.Item label={t("lessor-profile.updateInfo.websiteLabel")} name="website">
                                <Input placeholder={t("lessor-profile.updateInfo.websitePlaceholder")} />
                            </Form.Item>
                        </Col>
                        <Col xs={24} md={12}>
                            <Form.Item label={t("lessor-profile.updateInfo.addressLabel")} name="address">
                                <Input placeholder={t("lessor-profile.updateInfo.addressPlaceholder")} />
                            </Form.Item>
                        </Col>
                        <Col xs={24} md={12}>
                            <Form.Item
                                name="serviceRegionsList"
                                label={t("machines.add-info.selectRegions")}
                                rules={[
                                    {
                                        required: true,
                                        message: t("common.generic-required-message"),
                                    },
                                ]}
                            >
                                <Select getPopupContainer={(trigger: any) => trigger.parentElement} mode="multiple" onKeyDown={(e) => {
                                    if (!/[A-Za-z]/.test(e.key) && (e.key !== "Delete" && e.key !== 'Backspace') ) {
                                        e.preventDefault();
                                    }
                                }}>
                                    {props.citiesList?.map((city) => (
                                        <Select.Option value={city.name}>{city.name}</Select.Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col xs={24} md={12}>
                            <Form.Item name="locationId" label={t("lessor-profile.updateInfo.location")}>
                                <Row style={{ marginBottom: "1.5rem" }} justify="end" wrap={false}>
                                    <Col xs={12} md={12}>
                                        <Select
                                            placeholder={t("request.location.city.placeholder")}
                                            loading={loading}
                                            onSelect={onCitySelected}
                                            style={{ width: "99%" }}
                                            value={selectedCity?.name}
                                            getPopupContainer={(trigger: any) => trigger.parentElement}
                                        >
                                            {props.citiesList?.map((mc) => (
                                                <Option value={mc.name}>{mc.name}</Option>
                                            ))}
                                        </Select>
                                    </Col>
                                    <Col xs={12} md={12}>
                                        <Select
                                            placeholder={t("request.location.district.placeholder")}
                                            loading={loading}
                                            style={{ width: "99%" }}
                                            value={selectedDistrict}
                                            onSelect={(e: string) => setDistrict(e)}
                                            getPopupContainer={(trigger: any) => trigger.parentElement}
                                        >
                                            {districts?.map((msc) => (
                                                <Option value={msc.name}>{msc.name}</Option>
                                            ))}
                                        </Select>
                                    </Col>
                                </Row>
                            </Form.Item>
                        </Col>
                        <Col xs={24} md={12}>
                            <Form.Item
                                name="categories"
                                label={t("lessor-profile.updateInfo.sector")}
                                rules={[
                                    {
                                        required: true,
                                        message: t("common.generic-required-message"),
                                    },
                                ]}
                            >
                                <Select getPopupContainer={(trigger: any) => trigger.parentElement} mode="multiple" onKeyDown={(e) => {
                                    if (!/[A-Za-z]/.test(e.key) && (e.key !== "Delete" && e.key !== 'Backspace') ) {
                                        e.preventDefault();
                                    }
                                }}>
                                    {props.categoriesList?.map((cat) => (
                                        <Select.Option value={cat.name}>{cat.name}</Select.Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col xs={24} md={12}>
                            <Form.Item label={t("lessor-profile.updateInfo.totalMachines")} name="totalMachines">
                                <Input
                                    type={"number"}
                                    onWheel={(e: any) => e.target.blur()}
                                    placeholder={t("lessor-profile.updateInfo.totalMachinesPlaceHolder")}
                                    onKeyPress={(event) => {
                                        if (!/[0-9]/.test(event.key)) {
                                            event.preventDefault();
                                        }
                                    }}
                                />
                            </Form.Item>
                        </Col>
                        <Col xs={24} md={12}>
                            <Form.Item label={t("lessor-profile.updateInfo.operatorCountLabel")} name="operatorCount">
                                <Input
                                    type={"number"}
                                    onWheel={(e: any) => e.target.blur()}
                                    placeholder={t("lessor-profile.updateInfo.operatorCountPlaceholder")}
                                    onKeyPress={(event) => {
                                        if (!/[0-9]/.test(event.key)) {
                                            event.preventDefault();
                                        }
                                    }}
                                />
                            </Form.Item>
                        </Col>

                        <Col xs={24} md={12}>
                            <Form.Item label={t("lessor-profile.updateInfo.employeeCountLabel")} name="employeeCount">
                                <Input
                                    type={"number"}
                                    onWheel={(e: any) => e.target.blur()}
                                    placeholder={t("lessor-profile.updateInfo.employeeCountPlaceholder")}
                                    onKeyPress={(event) => {
                                        if (!/[0-9]/.test(event.key)) {
                                            event.preventDefault();
                                        }
                                    }}
                                />
                            </Form.Item>
                        </Col>

                        <Col xs={24}>
                            <Form.Item
                                label={t("lessor-profile.updateInfo.mainServiceActivityFieldsLabel")}
                                name="mainServiceActivityFields"
                            >
                                <TextArea placeholder={t("lessor-profile.updateInfo.mainServiceActivityFieldsPlaceholder")} />
                            </Form.Item>
                        </Col>
                        <Col xs={24} md={{ span: 12, offset: 6 }}>
                            <PrimaryButton type="primary" htmlType="submit">
                                {t("common.save")}
                            </PrimaryButton>
                        </Col>
                    </Row>
                </Form>
            </div>
        </ModalLayout>
    );
};

export default UpdateCompanyInfoModal;
