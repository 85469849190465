import { t } from "../translate";
import PrimaryButton from "../components/atomics/primary-button";
import ModalLayout, { ModalLayoutProps } from "../components/layouts/modal-layout";
import styles from "./request-summary.module.scss";
import { CityModel, LessorCompanyPaymentInfoViewModel, LessorCompanyViewModel } from "../service";
import { Col, Form, Input, Row, Select } from "antd";
import { useEffect } from "react";

type UpdatePaymentModalProps = {
    onUpdate: (profileData: LessorCompanyPaymentInfoViewModel | undefined) => void;
    paymentData: LessorCompanyViewModel | undefined;
    cities: CityModel[] | undefined;
} & ModalLayoutProps;

const UpdatePaymentModal = (props: UpdatePaymentModalProps) => {
    const [form] = Form.useForm();

    useEffect(() => {
        form.setFieldsValue(props.paymentData);
        //eslint-disable-next-line
    }, [props.paymentData]);

    const onPaymentUpdateFormFinish = (values: LessorCompanyPaymentInfoViewModel | undefined) => {
        props.onUpdate(values);
    };

    return (
        <ModalLayout show={props.show} onCloseClick={props.onCloseClick}>
            <h3 style={{ textAlign: "center", marginBottom: "1rem" }}>{t("lessor-profile.update.title")}</h3>
            <div className={styles.lightboxContent}>
                <Form
                    name="update-profile"
                    initialValues={props.paymentData}
                    form={form}
                    onFinish={onPaymentUpdateFormFinish}
                    layout="vertical"
                >
                    <Row gutter={16}>
                        <Col xs={24} md={{offset:6,span:12}}>
                            <Form.Item label={t("lessor-profile.companyView.taxIdentityNo")} name="taxIdentityNo"
                                rules={[
                                    {
                                        pattern: /^([1-9][0-9]*)$/,
                                        message:t('common.generic-required-numeric-input-message')
                                    },
                                ]}
                            >
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col xs={24} md={{offset:6,span:12}}>
                            <Form.Item label={t("lessor-profile.companyView.taxOffice")} name="taxOffice">
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col xs={24} md={{offset:6,span:12}}>
                            <Form.Item label={t("lessor-profile.companyView.taxOfficeCityId")} name="taxOfficeCityId">
                                <Select getPopupContainer={(trigger: any) => trigger.parentElement}>
                                    {props.cities?.map((city) => (
                                        <Select.Option value={city.id}>{city.name}</Select.Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col xs={24} md={{offset:6,span:12}}>
                            <Form.Item label={t("lessor-profile.companyView.iban")} name="iban">
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col xs={24} md={{offset:6,span:12}}>
                            <PrimaryButton type="primary" htmlType="submit">
                                {t("common.approveNow")}
                            </PrimaryButton>
                        </Col>
                    </Row>
                </Form>
            </div>
        </ModalLayout>
    );
};

export default UpdatePaymentModal;
