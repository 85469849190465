import { Col, ColProps, Row } from "antd";
import { DocumentCategory } from "../../service";
import { ExtendedMachineDetailModel } from "../../service/models/ExtendedMachineDetailModel";
import { activeApiBaseUrl } from "../../store/app-constants";
import { t } from "../../translate";
import BoxContainer from "../containers/box-container";
import styles from "../styles/machine-list-card.module.scss";
import BasicModelViewer from "./basic-model-viewer";
import PrimaryButton from "./primary-button";
import SecondaryButton from "./secondary-button";
import { ReactComponent as Placeholder } from "../assets/placeholder.svg";

type MachineListCardProps = {
    machine: ExtendedMachineDetailModel;
    onViewClick?: (param: number | undefined) => void;
    onDeleteClick?: (param: number | undefined) => void;
} & ColProps;

const MachineListCard = (props: MachineListCardProps) => {
    const docs = props.machine?.documents?.filter((doc) => doc.documentCategory === DocumentCategory.Photo);
    let imageId: number | undefined = undefined;
    if (docs && docs.length > 0) {
        imageId = docs[0].document?.id;
    }
    const imageUrl = activeApiBaseUrl + "/document/" + imageId;

    const editMachineClick = () => {
        props.onViewClick && props.onViewClick(props.machine.id);
    };
    const deleteMachineClick = () => {
        props.onDeleteClick && props.onDeleteClick(props.machine.id);
    };
    return (
        <Col {...props}>
            <BoxContainer marginless style={{ display: "flex" }}>
                <div
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "space-between",
                        height: "100%",
                    }}
                >
                    <Row gutter={[16, 16]}>
                        <Col xs={24}>
                            <div className={styles.imageContainer}>
                                {imageId ? (
                                    <img className={styles.image} src={imageUrl} alt={"document " + imageId} />
                                ) : (
                                    <Placeholder height={"12rem"} />
                                )}
                            </div>
                        </Col>
                        <BasicModelViewer xs={12} md={8} objKey={t("machines.id")} value={props.machine.id} />
                        <BasicModelViewer xs={12} md={8} objKey={t("machines.category")} value={props.machine.category?.name} />
                        <BasicModelViewer xs={12} md={8} objKey={t("machines.subcategory")} value={props.machine.subCategory?.name} />
                        <BasicModelViewer xs={12} md={8} objKey={t("machines.brand")} value={props.machine.brandOther ?? props.machine.brand?.name} />
                        <BasicModelViewer xs={12} md={8} objKey={t("machines.model")} value={props.machine.modelName} />
                        <BasicModelViewer xs={12} md={8} objKey={t("machines.productionYear")} value={props.machine.productionYear} />
                        <BasicModelViewer xs={12} md={8} objKey={t("machines.statusTitle")} value={props.machine.isApproved ? t("machines.status.1") : t("machines.status.2")} valueColor={props.machine.isApproved ? "#35B934" : "#FEB111"} />
                        
                    </Row>
                    <Row gutter={[16, 16]} style={{ marginTop: "2rem" }}>
                        <Col xs={12}>
                            <PrimaryButton onClick={editMachineClick} style={{ marginBottom: "0" }}>
                                {t("common.review")}
                            </PrimaryButton>
                        </Col>
                        <Col xs={12}>
                            <SecondaryButton onClick={deleteMachineClick} style={{ marginBottom: "0" }} negative>
                                {t("common.delete")}
                            </SecondaryButton>
                        </Col>
                    </Row>
                </div>
            </BoxContainer>
        </Col>
    );
};

export default MachineListCard;
