/* tslint:disable */
/* eslint-disable */
/**
 * MachineGo P1 API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface MachineSaleDetailModel
 */
export interface MachineSaleDetailModel {
    /**
     * 
     * @type {number}
     * @memberof MachineSaleDetailModel
     */
    id?: number;
    /**
     * 
     * @type {number}
     * @memberof MachineSaleDetailModel
     */
    salePrice?: number | null;
    /**
     * 
     * @type {number}
     * @memberof MachineSaleDetailModel
     */
    depositFee?: number | null;
    /**
     * 
     * @type {number}
     * @memberof MachineSaleDetailModel
     */
    machineGoCommission?: number | null;
}

export function MachineSaleDetailModelFromJSON(json: any): MachineSaleDetailModel {
    return MachineSaleDetailModelFromJSONTyped(json, false);
}

export function MachineSaleDetailModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): MachineSaleDetailModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'salePrice': !exists(json, 'salePrice') ? undefined : json['salePrice'],
        'depositFee': !exists(json, 'depositFee') ? undefined : json['depositFee'],
        'machineGoCommission': !exists(json, 'machineGoCommission') ? undefined : json['machineGoCommission'],
    };
}

export function MachineSaleDetailModelToJSON(value?: MachineSaleDetailModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'salePrice': value.salePrice,
        'depositFee': value.depositFee,
        'machineGoCommission': value.machineGoCommission,
    };
}

