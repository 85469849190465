/* tslint:disable */
/* eslint-disable */
/**
 * MachineGo P1 API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    UserRole,
    UserRoleFromJSON,
    UserRoleFromJSONTyped,
    UserRoleToJSON,
} from './UserRole';

/**
 * 
 * @export
 * @interface CheckUserModel
 */
export interface CheckUserModel {
    /**
     * 
     * @type {string}
     * @memberof CheckUserModel
     */
    email?: string | null;
    /**
     * 
     * @type {UserRole}
     * @memberof CheckUserModel
     */
    role: UserRole;
}

export function CheckUserModelFromJSON(json: any): CheckUserModel {
    return CheckUserModelFromJSONTyped(json, false);
}

export function CheckUserModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): CheckUserModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'email': !exists(json, 'email') ? undefined : json['email'],
        'role': UserRoleFromJSON(json['role']),
    };
}

export function CheckUserModelToJSON(value?: CheckUserModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'email': value.email,
        'role': UserRoleToJSON(value.role),
    };
}

