import { Card, CardProps } from "antd";
import React from "react";
import styles from "../styles/components.module.scss";

export type BoxContainerProps = {
    shadowless?: boolean;
    marginless?: boolean;
    bodyStyle?: React.CSSProperties;
} & CardProps;

const BoxContainer = (props: BoxContainerProps) => {
    let activeStyle = `${styles.box}`;
    if (!props.shadowless) activeStyle += ` ${styles.boxShadow}`;
    if (!props.marginless) activeStyle += ` ${styles.boxMargin}`;
    return (
        <Card className={activeStyle} {...props} bodyStyle={{ padding: "1.25rem", ...props.bodyStyle }}>
            {props.children}
        </Card>
    );
};

export default BoxContainer;
