import { Col, Form, Input, InputNumber, Row, Select, Switch } from "antd";
import { useAtom } from "jotai";
import { useEffect, useState } from "react";
import { BasicModel } from "../../service/models";
import { allCategories } from "../../store/all-categories";
import { loadingAtom } from "../../store/global-atoms";
import { useSecureService } from "../../store/service-atoms";
import { t } from "../../translate";

type FormItemProps = {
    cid?: number;
    sid?: number;
    defaultValue?: string | null;
    prelogin?: boolean;
};

export const WorkingRadius = (cid?: number, sid?: number, defaultValue?: number | null | undefined, prelogin?: boolean) => {
    if (!isFieldExists(cid, sid, "workingRadius", prelogin)) return <></>;
    let required = prelogin ? true : isRequired(cid, sid, "workingRadius");
    return CreateGenericNumericInput(
        "workingRadius", 
        required, 
        defaultValue, 
        t("machines.additional-fields.meterSuffix"),
        undefined,
        t('machines.additional-fields.workingRadiusValidationMessage'),
        0,
        2147483647
    );
};

export const FlightDistance = (cid?: number, sid?: number, defaultValue?: number | null | undefined, prelogin?: boolean) => {
    if (!isFieldExists(cid, sid, "flightDistance", prelogin)) return <></>;
    let required = prelogin ? true : isRequired(cid, sid, "flightDistance");
    return CreateGenericNumericInput(
        "flightDistance", 
        required, 
        defaultValue, 
        t("machines.additional-fields.flightDistanceSuffix"),
        undefined,
        t('machines.additional-fields.flightDistanceValidationMessage'),
        0,
        2147483647
    );
};

export const FlightTime = (cid?: number, sid?: number, defaultValue?: number | null | undefined, prelogin?: boolean) => {
    if (!isFieldExists(cid, sid, "flightTime", prelogin)) return <></>;
    let required = prelogin ? true : isRequired(cid, sid, "flightTime");
    return CreateGenericNumericInput(
        "flightTime",
        required,
        defaultValue,
        t("machines.additional-fields.flightSuffix"),
        t("machines.additional-fields.flightTimePlaceHolder"),
        t("machines.additional-fields.flightTimeValidationMessage"),
        1,
        150
    );
};

export const LoadCapacity = (cid?: number, sid?: number, defaultValue?: number | null | undefined, prelogin?: boolean) => {
    if (!isFieldExists(cid, sid, "loadCapacity", prelogin)) return <></>;
    let required = prelogin ? true : isRequired(cid, sid, "loadCapacity");
    return CreateGenericNumericInput(
        "loadCapacity", 
        required, 
        defaultValue, 
        t("machines.additional-fields.loadSuffix"),
        undefined,
       t('machines.additional-fields.loadCapacityValidationMessage'),
        0,
        2147483647
    );
};

export const TankVolume = (cid?: number, sid?: number, defaultValue?: number | null | undefined, prelogin?: boolean) => {
    if (!isFieldExists(cid, sid, "tankVolume", prelogin)) return <></>;
    let required = prelogin ? true : isRequired(cid, sid, "tankVolume");
    return CreateGenericNumericInput(
        "tankVolume", 
        required,
        defaultValue, 
        t("machines.additional-fields.capacitySuffix"),
        undefined,
        t('machines.additional-fields.tankVolumeValidationMessage'),
        0,
        2147483647
    );
};

export const SpraySpeed = (cid?: number, sid?: number, defaultValue?: number | null | undefined, prelogin?: boolean) => {
    if (!isFieldExists(cid, sid, "spraySpeed", prelogin)) return <></>;
    let required = prelogin ? true : isRequired(cid, sid, "spraySpeed");
    return CreateGenericNumericInput(
        "spraySpeed", 
        required, 
        defaultValue,
        t("machines.additional-fields.spraySuffix"),
        undefined,
        t('machines.additional-fields.spraySpeedValidationMessage'),
        0,
        2147483647
    );
};

export const FreeAirOutput = (cid?: number, sid?: number, defaultValue?: number | null | undefined, prelogin?: boolean) => {
    if (!isFieldExists(cid, sid, "freeAirOutput", prelogin)) return <></>;
    let required = prelogin ? true : isRequired(cid, sid, "freeAirOutput");
    return CreateGenericNumericInput(
        "freeAirOutput", 
        required, 
        defaultValue, 
        t("machines.additional-fields.freeAirSuffix"),
        undefined,
        t('machines.additional-fields.freeAirOutputBinValidationMessage'),
        0,
        2147483647
    );
};

export const MaxPressure = (cid?: number, sid?: number, defaultValue?: number | null | undefined, prelogin?: boolean) => {
    if (!isFieldExists(cid, sid, "maxPressure", prelogin)) return <></>;
    let required = prelogin ? true : isRequired(cid, sid, "maxPressure");
    return CreateGenericNumericInput(
        "maxPressure", 
        required, 
        defaultValue, 
        t("machines.additional-fields.pressureSuffix"),
        undefined,
        t('machines.additional-fields.pressureValidationMessage'),
        0,
        2147483647    
    );
};

export const ConcretePumpingCapacity = (cid?: number, sid?: number, defaultValue?: number | null | undefined, prelogin?: boolean) => {
    if (!isFieldExists(cid, sid, "concretePumpingCapacity", prelogin)) return <></>;
    let required = prelogin ? true : isRequired(cid, sid, "concretePumpingCapacity");
    return CreateGenericNumericInput(
        "concretePumpingCapacity", 
        required, 
        defaultValue, 
        t("machines.additional-fields.concreteSuffix"),
        undefined,
        t('machines.additional-fields.concretePumpingCapacityValidationMessage'),
        0,
        2147483647
    );
};

export const PlatformWidth = (cid?: number, sid?: number, defaultValue?: number | null | undefined, prelogin?: boolean) => {
    if (!isFieldExists(cid, sid, "platformWidth", prelogin)) return <></>;
    let required = prelogin ? true : isRequired(cid, sid, "platformWidth");
    return CreateGenericNumericInput(
        "platformWidth", 
        required, 
        defaultValue, 
        t("machines.additional-fields.meterSuffix"),
        undefined,
        t('machines.additional-fields.platformWidthValidationMessage'),
        0,
        2147483647    
    );
};

export const PlatformLength = (cid?: number, sid?: number, defaultValue?: number | null | undefined, prelogin?: boolean) => {
    if (!isFieldExists(cid, sid, "platformLength", prelogin)) return <></>;
    let required = prelogin ? true : isRequired(cid, sid, "platformLength");
    return CreateGenericNumericInput(
        "platformLength", 
        required, 
        defaultValue, 
        t("machines.additional-fields.meterSuffix"),
        undefined,
        t('machines.additional-fields.platformLengthValidationMessage'),
        0,
        2147483647    
    );
};

export const CompressionWidth = (cid?: number, sid?: number, defaultValue?: number | null | undefined, prelogin?: boolean) => {
    if (!isFieldExists(cid, sid, "compressionWidth", prelogin)) return <></>;
    let required = prelogin ? true : isRequired(cid, sid, "compressionWidth");
    return CreateGenericNumericInput(
        "compressionWidth",
        required,
        defaultValue,
        t("machines.additional-fields.meterSuffix"),
        t("machines.additional-fields.compressionWidthPlaceHolder"),
        t("machines.additional-fields.compressionWidthValidationMessage"),
        0,
        10
    );
};

export const MaxWidth = (cid?: number, sid?: number, defaultValue?: number | null | undefined, prelogin?: boolean) => {
    if (!isFieldExists(cid, sid, "maxWidth", prelogin)) return <></>;
    let required = prelogin ? true : isRequired(cid, sid, "maxWidth");
    return CreateGenericNumericInput(
        "maxWidth", 
        required, 
        defaultValue, 
        t("machines.additional-fields.meterSuffix"),
        undefined,
        t('machines.additional-fields.maxWidthValidationMessage'),
        0,
        2147483647
    );
};

export const MaxDepth = (cid?: number, sid?: number, defaultValue?: number | null | undefined, prelogin?: boolean) => {
    if (!isFieldExists(cid, sid, "maxDepth", prelogin)) return <></>;
    let required = prelogin ? true : isRequired(cid, sid, "maxDepth");
    return CreateGenericNumericInput(
        "maxDepth", 
        required, 
        defaultValue, 
        t("machines.additional-fields.meterSuffix"),
        undefined,
        t('machines.additional-fields.bmaxDepthValidationMessage'),
        0,
        2147483647
    );
};

export const MaxHeight = (cid?: number, sid?: number, defaultValue?: number | null | undefined, prelogin?: boolean) => {
    if (!isFieldExists(cid, sid, "maxHeight", prelogin)) return <></>;
    let required = prelogin ? true : isRequired(cid, sid, "maxHeight");
    return CreateGenericNumericInput(
        "maxHeight", 
        required, 
        defaultValue, 
        t("machines.additional-fields.meterSuffix"),
        undefined,
        t('machines.additional-fields.heightValidationMessage'),
        0,
        2147483647
    );
};

export const BomLength = (cid?: number, sid?: number, defaultValue?: number | null | undefined, prelogin?: boolean) => {
    if (!isFieldExists(cid, sid, "bomLength", prelogin)) return <></>;
    let required = prelogin ? true : isRequired(cid, sid, "bomLength");
    return CreateGenericNumericInput(
        "bomLength",
        required,
        defaultValue,
        t("machines.additional-fields.meterSuffix"),
        t("machines.additional-fields.bomLenghtPlaceHolder"),
        t("machines.additional-fields.bomLengthValidationMessage"),
        0,
        50
    );
};

export const BreakingInfo = (cid?: number, sid?: number, defaultValue?: number | null | undefined, prelogin?: boolean) => {
    if (!isFieldExists(cid, sid, "breakingInfo", prelogin)) return <></>;
    let required = prelogin ? true : isRequired(cid, sid, "breakingInfo");
    return CreateGenericNumericInput(
        "breakingInfo", 
        required, 
        defaultValue,
        undefined,
        undefined,
        t('machines.additional-fields.breakingInfoValidationMessage'),
        0,
        2147483647
    );
};

export const GarbageCollectionBin = (cid?: number, sid?: number, defaultValue?: number | null | undefined, prelogin?: boolean) => {
    if (!isFieldExists(cid, sid, "garbageCollectionBin", prelogin)) return <></>;
    let required = prelogin ? true : isRequired(cid, sid, "garbageCollectionBin");
    return CreateGenericNumericInput(
        "garbageCollectionBin", 
        required, 
        defaultValue, 
        t("machines.additional-fields.tankCapacitySuffix"),
        undefined,
        t('machines.additional-fields.garbageCollectionBinValidationMessage'),
        0,
        2147483647
    );
};

export const CleaningCapacity = (cid?: number, sid?: number, defaultValue?: number | null | undefined, prelogin?: boolean) => {
    if (!isFieldExists(cid, sid, "cleaningCapacity", prelogin)) return <></>;
    let required = prelogin ? true : isRequired(cid, sid, "cleaningCapacity");
    return CreateGenericNumericInput(
        "cleaningCapacity", 
        required, 
        defaultValue, 
        t("machines.additional-fields.cleaningSuffix"),
        undefined,
        t('machines.additional-fields.cleaningCapacityValidationMessage'),
        0,
        2147483647
    );
};

export const WorkingSpeed = (cid?: number, sid?: number, defaultValue?: number | null | undefined, prelogin?: boolean) => {
    if (!isFieldExists(cid, sid, "workingSpeed", prelogin)) return <></>;
    let required = prelogin ? true : isRequired(cid, sid, "workingSpeed");
    return CreateGenericNumericInput(
        "workingSpeed", 
        required, 
        defaultValue, 
        t("machines.additional-fields.workingSpeedSuffix"),
        undefined,
       t('machines.additional-fields.workingSpeedValidationMessage'),
        0,
        2147483647
        );
};

export const MaxFeedingSize = (cid?: number, sid?: number, defaultValue?: number | null | undefined, prelogin?: boolean) => {
    if (!isFieldExists(cid, sid, "maxFeedingSize", prelogin)) return <></>;
    let required = prelogin ? true : isRequired(cid, sid, "maxFeedingSize");
    return CreateGenericNumericInput(
        "maxFeedingSize", 
        required, 
        defaultValue, 
        t("machines.additional-fields.maxFeedingSizeSuffix"),
        undefined,
        t('machines.additional-fields.feedingSizeValidationMessage'),
        0,
        2147483647
        );
};

export const WaterTankCapacity = (cid?: number, sid?: number, defaultValue?: number | null | undefined, prelogin?: boolean) => {
    if (!isFieldExists(cid, sid, "waterTankCapacity", prelogin)) return <></>;
    let required = prelogin ? true : isRequired(cid, sid, "waterTankCapacity");
    return CreateGenericNumericInput(
        "waterTankCapacity",
        required,
        defaultValue,
        t("machines.additional-fields.tankCapacitySuffix"),
        t("machines.additional-fields.waterTankCapacityPlaceHolder"),
        t("machines.additional-fields.waterTankCapacityValidationMessage"),
        100,
        1500
    );
};

export const NominalCapacity = (cid?: number, sid?: number, defaultValue?: number | null | undefined, prelogin?: boolean) => {
    if (!isFieldExists(cid, sid, "nominalCapacity", prelogin)) return <></>;
    let required = prelogin ? true : isRequired(cid, sid, "nominalCapacity");
    return CreateGenericNumericInput(
        "nominalCapacity",
        required,
        defaultValue,
        t("machines.additional-fields.capacitySuffix"),
        t("machines.additional-fields.nominalCapacityPlaceHolder"),
        t("machines.additional-fields.nominalCapacityValidationMessage"),
        5,
        50
    );
};

export const MaxLoadCapacity = (cid?: number, sid?: number, defaultValue?: number | null | undefined, prelogin?: boolean) => {
    if (!isFieldExists(cid, sid, "maxLoadCapacity", prelogin)) return <></>;
    let required = prelogin ? true : isRequired(cid, sid, "maxLoadCapacity");
    const suffix = [10].includes(cid ?? -1) ? t("common.kg") : t("common.ton");
    return CreateGenericNumericInput(
        "maxLoadCapacity", 
        required, 
        defaultValue, 
        `${suffix}`,
        undefined,
        t('machines.additional-fields.maxLoadCapacityValidationMessage'),
        0,
        2147483647
        );
};

export const MaxPower = (cid?: number, sid?: number, defaultValue?: number | null | undefined, prelogin?: boolean) => {
    if (!isFieldExists(cid, sid, "maxPower", prelogin)) return <></>;
    let required = prelogin ? true : isRequired(cid, sid, "maxPower");
    return CreateGenericNumericInput(
        "maxPower", 
        required, 
        defaultValue, 
        t("machines.additional-fields.powerSuffix"),
        undefined,
        t('machines.additional-fields.maxPowerValidationMessage'),
        0,
        2147483647
        );
};

export const MinPower = (cid?: number, sid?: number, defaultValue?: number | null | undefined, prelogin?: boolean) => {
    if (!isFieldExists(cid, sid, "minPower", prelogin)) return <></>;
    let required = prelogin ? true : isRequired(cid, sid, "minPower");
    return CreateGenericNumericInput(
        "minPower", 
        required, 
        defaultValue, 
        t("machines.additional-fields.powerSuffix"),      
        undefined,
        t('machines.additional-fields.minPowerValidationMessage'),
        0,
        2147483647);
};

export const Frequency = (cid?: number, sid?: number, defaultValue?: number | null | undefined, prelogin?: boolean) => {
    if (!isFieldExists(cid, sid, "frequency", prelogin)) return <></>;
    let required = prelogin ? true : isRequired(cid, sid, "frequency");
    return CreateGenericNumericInput(
        "frequency", 
        required, 
        defaultValue, 
        t("machines.additional-fields.frequencySuffix"),
        undefined,
        t('machines.additional-fields.frequencyValidationMessage'),
        0,
        2147483647
        );
};

export const BladeWidth = (cid?: number, sid?: number, defaultValue?: number | null | undefined, prelogin?: boolean) => {
    if (!isFieldExists(cid, sid, "bladeWidth", prelogin)) return <></>;
    let required = prelogin ? true : isRequired(cid, sid, "bladeWidth");
    return CreateGenericNumericInput(
        "bladeWidth", 
        required, 
        defaultValue, 
        t("machines.additional-fields.bladeWidthSuffix"),
        undefined,
        t('machines.additional-fields.bladeWidthValidationMessage'),
        0,
        2147483647);
};

export const FloorCount = (cid?: number, sid?: number, defaultValue?: number | null | undefined, prelogin?: boolean) => {
    if (!isFieldExists(cid, sid, "floorCount", prelogin)) return <></>;
    let required = prelogin ? true : isRequired(cid, sid, "floorCount");
    return CreateGenericNumericInput(
        "floorCount", 
        required, 
        defaultValue,
        undefined,
        undefined,
        t("machines.additional-fields.floorCountValidationMessage"),
        0,
        2147483647
    );
};

export const Height = (cid?: number, sid?: number, defaultValue?: number | null | undefined, prelogin?: boolean) => {
    if (!isFieldExists(cid, sid, "height", prelogin)) return <></>;
    let required = prelogin ? true : isRequired(cid, sid, "height");
    return CreateGenericNumericInput(
        "height", 
        required, 
        defaultValue, 
        t("machines.additional-fields.heightSuffix"),
        undefined,
        t('machines.additional-fields.heightValidationMessage'),
        0,
        2147483647);
};

export const Volume = (cid?: number, sid?: number, defaultValue?: number | null | undefined, prelogin?: boolean) => {
    if (!isFieldExists(cid, sid, "volume", prelogin)) return <></>;
    let required = prelogin ? true : isRequired(cid, sid, "volume");
    return CreateGenericNumericInput(
        "volume", 
        required, 
        defaultValue, 
        t("machines.additional-fields.volumeSuffix"),    
        undefined,
        t('machines.additional-fields.volumeValidationMessage'),
        0,
        2147483647
    );
};

export const Width = (cid?: number, sid?: number, defaultValue?: number | null | undefined, prelogin?: boolean) => {
    if (!isFieldExists(cid, sid, "width", prelogin)) return <></>;
    let required = prelogin ? true : isRequired(cid, sid, "width");
    return CreateGenericNumericInput(
        "width", 
        required, 
        defaultValue, 
        t("machines.additional-fields.widthSuffix"),      
        undefined,
        t('machines.additional-fields.widthValidationMessage'),
        0,
        2147483647
    );
};

export const CarryingCapacity = (cid?: number, sid?: number, defaultValue?: number | null | undefined, prelogin?: boolean) => {
    if (!isFieldExists(cid, sid, "carryingCapacity", prelogin)) return <></>;
    let required = prelogin ? true : isRequired(cid, sid, "carryingCapacity");
    return CreateGenericNumericInput(
        "carryingCapacity", 
        required, 
        defaultValue, 
        t("machines.additional-fields.carryingCapacitySuffix"),      
        undefined,
        t('machines.additional-fields.carryingCapacityValidationMessage'),
        0,
        2147483647
    );
};

export const LightningCapacity = (cid?: number, sid?: number, defaultValue?: number | null | undefined, prelogin?: boolean) => {
    if (!isFieldExists(cid, sid, "lightningCapacity", prelogin)) return <></>;
    let required = prelogin ? true : isRequired(cid, sid, "lightningCapacity");
    return CreateGenericNumericInput(
        "lightningCapacity", 
        required, 
        defaultValue, 
        t("machines.additional-fields.lightningCapacitySuffix"),
        undefined,
        t('machines.additional-fields.lightingCapacityValidationMessage'),
        0,
        2147483647
    );
};

export const HasTowerElevationSystem = (cid?: number, sid?: number, defaultValue?: boolean | null | undefined, prelogin?: boolean) => {
    if (!isFieldExists(cid, sid, "hasTowerElevationSystem", prelogin)) return <></>;
    let required = prelogin ? true : isRequired(cid, sid, "hasTowerElevationSystem");
    return CreateGenericSwitchInput("hasTowerElevationSystem", required, defaultValue);
};

export const HasPlatform = (cid?: number, sid?: number, defaultValue?: boolean | null | undefined, prelogin?: boolean) => {
    if (!isFieldExists(cid, sid, "hasPlatform", prelogin)) return <></>;
    let required = prelogin ? true : isRequired(cid, sid, "hasPlatform");
    return CreateGenericSwitchInput("hasPlatform", required, defaultValue);
};

export const EngineBrand = (cid?: number, sid?: number, defaultValue?: string | null | undefined, prelogin?: boolean) => {
    if (!isFieldExists(cid, sid, "engineBrand", prelogin)) return <></>;
    let required = prelogin ? true : isRequired(cid, sid, "engineBrand");
    return CreateGenericInput("engineBrand", required, defaultValue);
};

export const HasCabin = (cid?: number, sid?: number, defaultValue?: boolean | null | undefined, prelogin?: boolean) => {
    if (!isFieldExists(cid, sid, "hasCabin", prelogin)) return <></>;
    let required = prelogin ? true : isRequired(cid, sid, "hasCabin");
    return CreateGenericSwitchInput("hasCabin", required, defaultValue);
};

export const UsageFields = (cid?: number, sid?: number, defaultValue?: string | null | undefined, prelogin?: boolean) => {
    if (!isFieldExists(cid, sid, "usageFields", prelogin)) return <></>;
    let required = prelogin ? true : isRequired(cid, sid, "usageFields");
    return CreateGenericInput("usageFields", required, defaultValue);
};

export const OperatingCapacity = (cid?: number, sid?: number, defaultValue?: number | null | undefined, prelogin?: boolean) => {
    if (!isFieldExists(cid, sid, "operatingCapacity", prelogin)) return <></>;
    let required = prelogin ? true : isRequired(cid, sid, "operatingCapacity");
    return CreateGenericNumericInput(
        "operatingCapacity", 
        required, 
        defaultValue,
        undefined,
        undefined,
        t("machines.additional-fields.operatingCapacityValidationMessage344"),
        0,
        2147483647
        );
};

export const OperationWidth = (cid?: number, sid?: number, defaultValue?: number | null | undefined, prelogin?: boolean) => {
    if (!isFieldExists(cid, sid, "operationWidth", prelogin)) return <></>;
    let required = prelogin ? true : isRequired(cid, sid, "operationWidth");
    return CreateGenericNumericInput("operationWidth", required, defaultValue, t("machines.additional-fields.operationWidthSuffix"),
    undefined,
    t('machines.additional-fields.operationWidthValidationMessage'),
    0,
    2147483647
    );
};

export const OperatingLength = (cid?: number, sid?: number, defaultValue?: number | null | undefined, prelogin?: boolean) => {
    if (!isFieldExists(cid, sid, "operatingLength", prelogin)) return <></>;
    let required = prelogin ? true : isRequired(cid, sid, "operatingLength");
    return CreateGenericNumericInput(
        "operatingLength", 
        required, 
        defaultValue, 
        t("machines.additional-fields.operationLengthSuffix"),      
        undefined,
        t('machines.additional-fields.operatingLengthValidationMessage'),
        0,
        2147483647
    );
};

export const OperatingHeight = (cid?: number, sid?: number, defaultValue?: number | null | undefined, prelogin?: boolean) => {
    if (!isFieldExists(cid, sid, "operatingHeight", prelogin)) return <></>;
    let required = prelogin ? true : isRequired(cid, sid, "operatingHeight");
    return CreateGenericNumericInput(
        "operatingHeight",
        required,
        defaultValue,
        t("machines.additional-fields.meterSuffix"),
        undefined,
        t("machines.additional-fields.operatingHeightValidationMessage"),
        0,
        2147483647
    );
};

export const LiftingHeight = (cid?: number, sid?: number, defaultValue?: number | null | undefined, prelogin?: boolean) => {
    if (!isFieldExists(cid, sid, "liftingHeight", prelogin)) return <></>;
    let required = prelogin ? true : isRequired(cid, sid, "liftingHeight");
    return CreateGenericNumericInput(
        "liftingHeight",
        required,
        defaultValue,
        t("machines.additional-fields.liftingHeightSuffix"),
        undefined,
        t("machines.additional-fields.liftingHeightValidationMessage"),
        0,
        2147483647
    );
};

export const CameraResolution = (props: FormItemProps) => {
    const [cameraResolutions, setCameraResolutions] = useState<BasicModel[] | undefined>(undefined);
    const [loading] = useAtom(loadingAtom);
    const { doGetCameraResolutionTypes } = useSecureService();
    const { Option } = Select;

    useEffect(() => {
        const getCameraResolutionTypes = async () => {
            const response = await doGetCameraResolutionTypes();
            if (response) {
                setCameraResolutions(response);
            }
        };
        getCameraResolutionTypes();
        // eslint-disable-next-line
    }, []);

    if (!isFieldExists(props.cid, props.sid, "cameraResolution", props.prelogin)) {
        return <></>;
    }
    let required = props.prelogin ? true : isRequired(props.cid, props.sid, "cameraResolution");
    return (
        <Col xs={24} md={12}>
            <Form.Item
                name="cameraResolutionName"
                label={t("machines.additional-fields.cameraResolution")}
                initialValue={props.defaultValue}
                rules={[
                    {
                        required: required,
                        message: t("common.generic-required-message"),
                    },
                ]}
            >
                <Select getPopupContainer={(trigger: any) => trigger.parentElement} loading={loading}>
                    {cameraResolutions?.map((msc: any) => (
                        <Option value={msc.name}>{msc.name}</Option>
                    ))}
                </Select>
            </Form.Item>
        </Col>
    );
};

export const ElevatorTypeId = (props: FormItemProps) => {
    const [elevatorTypes, setElevatorTypes] = useState<BasicModel[] | undefined>(undefined);
    const [loading] = useAtom(loadingAtom);
    const { doGetElevatorTypes } = useSecureService();
    const { Option } = Select;
    const exists = isFieldExists(props.cid, props.sid, "elevatorTypeId", props.prelogin);
    useEffect(() => {
        const getElevatorTypes = async () => {
            const response = await doGetElevatorTypes();
            if (response) {
                setElevatorTypes(response);
            }
        };
        if (!elevatorTypes && exists) getElevatorTypes();
        // eslint-disable-next-line
    }, []);

    if (!exists) {
        return <></>;
    }
    let required = props.prelogin ? true : isRequired(props.cid, props.sid, "elevatorTypeId");
    return (
        <Col xs={24} md={12}>
            <Form.Item
                name="elevatorTypeName"
                label={t("machines.additional-fields.elevatorTypeId")}
                initialValue={props.defaultValue}
                rules={[
                    {
                        required: required,
                        message: t("common.generic-required-message"),
                    },
                ]}
            >
                <Select getPopupContainer={(trigger: any) => trigger.parentElement} loading={loading}>
                    {elevatorTypes?.map((msc) => (
                        <Option value={msc.name}>{msc.name}</Option>
                    ))}
                </Select>
            </Form.Item>
        </Col>
    );
};

export const FuelTypeId = (props: FormItemProps) => {
    const [fuelTypes, setFuelTypes] = useState<BasicModel[] | undefined>(undefined);
    const [loading] = useAtom(loadingAtom);
    const { doGetFuelTypes } = useSecureService();
    const { Option } = Select;
    useEffect(() => {
        const getFuelTypes = async () => {
            const response = await doGetFuelTypes();
            if (response) {
                setFuelTypes(response);
            }
        };
        getFuelTypes();
        // eslint-disable-next-line
    }, []);
    if (!isFieldExists(props.cid, props.sid, "fuelTypeId", props.prelogin)) {
        return <></>;
    }
    let required = props.prelogin ? true : isRequired(props.cid, props.sid, "fuelTypeId");
    return (
        <Col xs={24} md={12}>
            <Form.Item
                name="fuelTypeName"
                label={t("machines.additional-fields.fuelTypeId")}
                initialValue={props.defaultValue}
                rules={[
                    {
                        required: required,
                        message: t("common.generic-required-message"),
                    },
                ]}
            >
                <Select getPopupContainer={(trigger: any) => trigger.parentElement} loading={loading}>
                    {fuelTypes?.map((msc) => (
                        <Option key={msc.name} value={msc.name}>
                            {msc.name}
                        </Option>
                    ))}
                </Select>
            </Form.Item>
        </Col>
    );
};

export const TankCapacity = (cid?: number, sid?: number, defaultValue?: number | null | undefined, prelogin?: boolean) => {
    if (!isFieldExists(cid, sid, "tankCapacity", prelogin)) return <></>;
    let required = prelogin ? true : isRequired(cid, sid, "tankCapacity");
    return CreateGenericNumericInput(
        "tankCapacity",
        required,
        defaultValue,
        t("machines.additional-fields.capacitySuffix"),
        t("machines.additional-fields.tankCapacityPlaceholder"),
        t("machines.additional-fields.tankCapacityValidationMessage"),
        0,
        10
    );
};

export const OperatingWeight = (cid?: number, sid?: number, defaultValue?: number | null | undefined, prelogin?: boolean) => {
    if (!isFieldExists(cid, sid, "operatingWeight", prelogin)) return <></>;
    let required = prelogin ? true : isRequired(cid, sid, "operatingWeight");
    return CreateGenericNumericInput("operatingWeight", required, defaultValue, t("machines.additional-fields.operatingSuffix"),      
    undefined,
    t('machines.additional-fields.operatingWeightValidationMessage'),
    0,
    2147483647);
};

export const EnginePower = (cid?: number, sid?: number, defaultValue?: number | null | undefined, prelogin?: boolean) => {
    if (!isFieldExists(cid, sid, "enginePower", prelogin)) return <></>;
    let required = prelogin ? true : isRequired(cid, sid, "enginePower");
    return CreateGenericNumericInput(
        "enginePower",
        required,
        defaultValue,
        t("machines.additional-fields.hpSuffix"),
        t("machines.additional-fields.enginePowerPlaceholder"),
        t("machines.additional-fields.enginePowerValidationMessage"),
        0,
        2147483647
    );
};

export const DrivingEquipment = (cid?: number, sid?: number, defaultValue?: string | null | undefined, prelogin?: boolean) => {
    if (!isFieldExists(cid, sid, "drivingEquipment", prelogin)) return <></>;
    let required = prelogin ? true : isRequired(cid, sid, "drivingEquipment");
    const { Option } = Select;

    return (
        <Col xs={24} md={12}>
            <Form.Item
                name="drivingEquipment"
                label={t("machines.additional-fields.drivingEquipment")}
                initialValue={defaultValue}
                rules={[
                    {
                        required: required,
                        message: t("common.generic-required-message"),
                    },
                ]}
            >
                <Select getPopupContainer={(trigger: any) => trigger.parentElement}>
                    <Option value="Paletli">Paletli</Option>
                    <Option value="Lastikli">Lastikli</Option>
                    <Option value="Dört eşit tekerlek">Dört eşit tekerlek</Option>
                    <Option value="Ön tekerler küçük">Ön tekerler küçük</Option>
                    <Option value="Diger">Diger</Option>
                </Select>
            </Form.Item>
        </Col>
    );
};

export const OperationHour = (cid?: number, sid?: number, defaultValue?: number | null | undefined, prelogin?: boolean) => {
    if (!isFieldExists(cid, sid, "operationHour", prelogin)) return <></>;
    let required = prelogin ? true : isRequired(cid, sid, "operationHour");
    return CreateGenericNumericInput(
        "operationHour",
        required,
        defaultValue,
        t("machines.additional-fields.hourSuffix"),
        undefined,
        t("machines.additional-fields.operationHourValidationMessage"),
        0,
        2147483647
    );
};

export const OperationMileage = (cid?: number, sid?: number, defaultValue?: number | null | undefined, prelogin?: boolean) => {
    if (!isFieldExists(cid, sid, "operationMileage", prelogin)) return <></>;
    let required = prelogin ? true : isRequired(cid, sid, "operationMileage");
    return CreateGenericNumericInput(
        "operationMileage",
        required,
        defaultValue,
        t("machines.additional-fields.mileageSuffix"),
        undefined,
        t("machines.additional-fields.operationMileageValidationMessage"),
        0,
        2147483647
    );
};

const CreateGenericNumericInput = (
    fieldName: string,
    required: boolean,
    defaultValue: number | null | undefined,
    suffix?: string,
    placeholderMessage?: string,
    validationMessage?: string,
    min?: number,
    max?: number
) => {
    return (
        <Col xs={24} md={12}>
            <Form.Item
                name={fieldName}
                initialValue={defaultValue}
                label={t("machines.additional-fields." + fieldName)}
                rules={[
                    {
                        required: required,
                        message: validationMessage ? validationMessage : t("common.generic-required-message"),
                        validator: (_, value) => {
                            const rejectPromise = Promise.reject(
                                new Error(validationMessage ? validationMessage : t("common.generic-required-message"))
                            );
                            if (required && isNaN(value)) return rejectPromise;
                            if (min !== undefined && value < min) return rejectPromise;
                            if (max !== undefined && value > max) return rejectPromise;
                            return Promise.resolve();
                        },
                    },
                ]}
            >
                <InputNumber
                    onWheel={(e: any) => e.target.blur()}
                    controls={false}
                    type={"number"}
                    style={{ width: "100%" }}
                    placeholder={placeholderMessage}
                    addonAfter={suffix}
                />
            </Form.Item>
        </Col>
    );
};

const CreateGenericInput = (fieldName: string, required: boolean, defaultValue: string | null | undefined) => {
    return (
        <Col xs={24} md={12}>
            <Form.Item
                name={fieldName}
                label={t("machines.additional-fields." + fieldName)}
                initialValue={defaultValue}
                rules={[
                    {
                        required: required,
                        message: t("common.generic-required-message"),
                    },
                ]}
            >
                <Input />
            </Form.Item>
        </Col>
    );
};

const CreateGenericSwitchInput = (fieldName: string, required: boolean, defaultValue: boolean | undefined | null) => {
    return (
        <Col xs={24} md={12} style={{ marginTop: ".5rem" }}>
            <Row
                justify="space-between"
                align="middle"
                style={{
                    height: "100%",
                    marginBottom: ".5rem",
                    alignSelf: "center",
                }}
            >
                <Col xs={18}>
                    <span>{t("machines.additional-fields." + fieldName)}</span>
                </Col>
                <Col xs={4}>
                    <Form.Item name={fieldName} initialValue={defaultValue} valuePropName="checked" style={{ margin: "0" }}>
                        <Switch />
                    </Form.Item>
                </Col>
            </Row>
        </Col>
    );
};

const isFieldExists = (cid: number | undefined, sid: number | undefined, paramName: string, prelogin?: boolean) => {
    const base = allCategories.find((item) => {
        return cid?.toString() === item.id.toString();
    });

    if (base === undefined) return false;

    const subItem = base?.subCategories?.find((sub) => {
        return sub.id.toString() === sid?.toString();
    });

    if (subItem === undefined) return false;

    if (prelogin) return subItem.requestFields.includes(paramName);
    else return subItem.fields.includes(paramName);
};

const isRequired = (cid: number | undefined, sid: number | undefined, paramName: string) => {
    const base = allCategories.find((item) => {
        return cid?.toString() === item.id.toString();
    });

    if (base === undefined) return false;

    const subItem = base?.subCategories?.find((sub) => {
        return sub.id.toString() === sid?.toString();
    });

    if (subItem?.required?.includes(paramName)) return true;
    return false;
};
