import { Content, Header } from "antd/lib/layout/layout";
import { ReactElement } from "react";
import BaseLayout from "./base-layout";
import { ReactComponent as Logo } from "../assets/logo.svg";
import styles from "../styles/onboard-layout.module.scss";
import logoWhite from "../../logo-white.png";
import { activeRootSiteUrl } from "../../store/app-constants";
import { Row, Col } from "antd";

export type OnboardLayoutProps = {
    children: any;
    header?: string | ReactElement;
    smallLogo?: boolean;
    h2?: string | ReactElement;
    footerVisible?: boolean;
    hideLogo?: boolean;
    hideBackgroundImg?: boolean;
};

const OnboardLayout = (props: OnboardLayoutProps) => {
    const logo = props.smallLogo ? (
        <Logo
            height={"2.5rem"}
            onClick={() => {
                window.location.replace(activeRootSiteUrl);
            }}
        />
    ) : (
        <Logo
            onClick={() => {
                window.location.replace(activeRootSiteUrl);
            }}
        />
    );
    return (
        <BaseLayout>
            <Header className={styles.header}>
                {props.hideLogo ? <></> : logo}
                {props.header && <h1>{props.header}</h1>}
                {props.h2 && <h2 className={styles.signupHeaderOne}>{props.h2}</h2>}
            </Header>
            <Content className={styles.content} style={props.hideBackgroundImg ? {backgroundImage: 'none'} : undefined}>{props.children}</Content>
            {props.footerVisible && (
                <footer className={styles.footer}>
                    <div className={styles.footerWrapper}>
                        <Col xs={24} md={10} className={styles.footerContainer}>
                            <Row className={styles.footerContent}>
                                <Col xs={24} md={12} className={styles.footerLeft}>
                                    <img src={logoWhite} alt="Logo" width={156} height={42} />
                                    <p>
                                        {/* {translate("description")}  */}
                                        Projeleriniz için ihtiyacınız olan iş ve endüstri makineleri MachineGo'da! İster kiralayın, ister
                                        alın, ister satın. Türkiye'nin en geniş makine yelpazesine sahip dijital platformu MachineGo ile
                                        zaman kazanın, maliyetlerinizi düşürün ve işlerinizi kolaylaştırın.
                                    </p>
                                    <p className={styles.rightsDesktop}>{new Date().getFullYear()}© Machinego. Tüm Hakları Saklıdır.</p>
                                </Col>
                                <Col xs={24} md={10} className={styles.footerRight}>
                                    <ul>
                                        <li>
                                            {/* <CustomLink href="/#hakkimizda">{hakkımızda}</CustomLink>
                                             */}
                                            <a href="/hakkimizda" target="_blank" rel="noopener noreferrer">
                                                Hakkımızda
                                            </a>
                                        </li>
                                        <li>
                                            {/* <CustomLink href="/#teklifler">teklifler</CustomLink>
                                             */}
                                            <a href="https://www.machinego.com/#teklifler" target="_blank" rel="noopener noreferrer">
                                                Teklifler
                                            </a>
                                        </li>
                                        <li>
                                            {/* <CustomLink href="blog">blog</CustomLink> */}
                                            <a href="https://www.machinego.com/blog" target="_blank" rel="noopener noreferrer">
                                                Blog
                                            </a>
                                        </li>
                                        <li>
                                            <a href="tel:+902127060330">İletişim: +902127060330</a>
                                        </li>
                                        <li>
                                            {" "}
                                            <a href="/kullanici-sozlesmesi" target="_blank" rel="noopener noreferrer">
                                                Kullanıcı Sözleşmesi
                                            </a>
                                        </li>
                                        <li>
                                            {" "}
                                            <a href="/kurumsal-ve-bireysel-uye-acik-riza-metni" target="_blank" rel="noopener noreferrer">
                                                Kurumsal ve Bireysel Üye Açık Rıza Metni
                                            </a>
                                        </li>
                                    </ul>
                                    <ul>
                                        <li>
                                            <a target="_blank" href="https://www.facebook.com/machinegocom/" rel="noreferrer">
                                                Facebook
                                            </a>
                                        </li>
                                        <li>
                                            <a target="_blank" href="https://twitter.com/machinegocom" rel="noreferrer">
                                                Twitter
                                            </a>
                                        </li>
                                        <li>
                                            <a target="_blank" href="https://www.instagram.com/machinegocom/" rel="noreferrer">
                                                Instagram
                                            </a>
                                        </li>
                                        <li>
                                            <a target="_blank" href="https://www.linkedin.com/company/machinego/" rel="noreferrer">
                                                LinkedIn
                                            </a>
                                        </li>
                                        <li>
                                            <a target="_blank" href="/aydinlatma-metni">
                                                Gizlilik & Kişisel Verilerin Korunması Poliikası
                                            </a>
                                        </li>
                                        <li>
                                            <a target="_blank" href="/izinli-pazarlama-onay-metni">
                                                İzinli Pazarlama Onay Metni
                                            </a>
                                        </li>
                                    </ul>
                                </Col>
                            </Row>
                        </Col>
                    </div>
                </footer>
            )}
        </BaseLayout>
    );
};

export default OnboardLayout;
