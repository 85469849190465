/* tslint:disable */
/* eslint-disable */
/**
 * MachineGo P1 API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ErrorModel,
    ErrorModelFromJSON,
    ErrorModelFromJSONTyped,
    ErrorModelToJSON,
} from './ErrorModel';

/**
 * 
 * @export
 * @interface Int32ValidatedModel
 */
export interface Int32ValidatedModel {
    /**
     * 
     * @type {ErrorModel}
     * @memberof Int32ValidatedModel
     */
    error?: ErrorModel;
    /**
     * 
     * @type {boolean}
     * @memberof Int32ValidatedModel
     */
    readonly success?: boolean;
    /**
     * 
     * @type {number}
     * @memberof Int32ValidatedModel
     */
    data?: number;
}

export function Int32ValidatedModelFromJSON(json: any): Int32ValidatedModel {
    return Int32ValidatedModelFromJSONTyped(json, false);
}

export function Int32ValidatedModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): Int32ValidatedModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'error': !exists(json, 'error') ? undefined : ErrorModelFromJSON(json['error']),
        'success': !exists(json, 'success') ? undefined : json['success'],
        'data': !exists(json, 'data') ? undefined : json['data'],
    };
}

export function Int32ValidatedModelToJSON(value?: Int32ValidatedModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'error': ErrorModelToJSON(value.error),
        'data': value.data,
    };
}

