import { Form, Input } from "antd";
import { UserOutlined } from "@ant-design/icons";
import { t } from "../../translate";
import { useEffect, useState } from "react";
import { ReactComponent as PhoneIcon } from "../../components/assets/phoneIcon.svg"
import { useAtom } from "jotai";
import { signupServiceErrorAtom } from "../../store/global-atoms";

export type UsernameInputProps = {
    prefix: boolean;
    initialValue?: string;
    type?: 'phone' | 'other';
    className?: string;
    style?: React.CSSProperties;
    placeholder?: string;
};

const UsernameInput = (props: UsernameInputProps) => {
    const [phoneInput, setPhoneInput] = useState<string>(props.initialValue ?? "");
    const [signUpServiceError, setSignUpServiceError] = useAtom(signupServiceErrorAtom);
    const Change = (e: React.ChangeEvent<HTMLInputElement>) => {
        let phone = "";
        if (e.target.value.includes("+90")) {
            let val = e.target.value;
            phone = val;
        }
        else {
            if (!e.target.value.includes("+")) {
                let val = "+90" + e.target.value;
                phone = val;
            }
        }
        if(signUpServiceError === 'usedPhone') {
            setSignUpServiceError(null);
        }
        phone = phone.replace(/ /gm, "");
        let num = `${phone.substring(0, 3)} ${phone.substring(3, 6)} ${phone.substring(6, 9)} ${phone.substring(9, 13)}`;
        num = num.trim();
        setPhoneInput(num);
    };

    useEffect(() => {
        if (props.initialValue) {
            let phone = "";
            if (props.initialValue.includes("+90")) {
                let val = props.initialValue;
                phone = val;
            } else {
                if (!props.initialValue.includes("+")) {
                    let val = "+90" + props.initialValue;
                    phone = val;
                }
            }
            phone = phone.replace(/ /gm, "");
            let num = `${phone.substring(0, 3)} ${phone.substring(3, 6)} ${phone.substring(6, 9)} ${phone.substring(9, 13)}`;
            num = num.trim();
            setPhoneInput(num);
        }
        //eslint-disable-next-line
    }, [props.initialValue]);
    return (
        <Form.Item
            name="phone"
            label={t("login.username.label")}
            dependencies={signUpServiceError ? [[signUpServiceError]]: undefined}
            rules={[
                {
                    pattern: /^[ 0-9+]*$/,
                    message: t("signup.form.phoneValidation"),
                    required: true,
                },
                ({ getFieldValue }) => ({
                    validator(_, value) {
                        if (signUpServiceError === 'usedPhone') {
                        return Promise.reject(new Error(t("signup.form.phoneIsUsedValidationError")));
                        } else {
                        return Promise.resolve();
                        }
                    },
                }),
            ]}
        >
            <div>
                <Input
                    prefix={props.prefix ? (props.type === 'phone' ? <PhoneIcon /> :  <UserOutlined className="site-form-item-icon" /> ): undefined}
                    maxLength={16}
                    style={props.style ? props.style : { width: "100%" }}
                    type={"tel"}
                    onChange={Change}
                    placeholder={props.placeholder ?? (props.type === 'phone' ? '+90' : '')}
                    value={phoneInput}
                    defaultValue={"+90 "}
                    onKeyPress={(event) => {
                        if (!/[0-9,+]/.test(event.key)) {
                            event.preventDefault();
                        }
                    }}
                    className={props.className ? props.className: undefined}
                />
            </div>
        </Form.Item>
    );
};

export default UsernameInput;
