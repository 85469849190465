/* tslint:disable */
/* eslint-disable */
/**
 * MachineGo P1 API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const LessorPurpose = {
    All: 'All',
    OnlyForRent: 'OnlyForRent',
    OnlyForSale: 'OnlyForSale'
} as const;
export type LessorPurpose = typeof LessorPurpose[keyof typeof LessorPurpose];


export function LessorPurposeFromJSON(json: any): LessorPurpose {
    return LessorPurposeFromJSONTyped(json, false);
}

export function LessorPurposeFromJSONTyped(json: any, ignoreDiscriminator: boolean): LessorPurpose {
    return json as LessorPurpose;
}

export function LessorPurposeToJSON(value?: LessorPurpose | null): any {
    return value as any;
}

