/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable array-callback-return */
import { Button, Col, Collapse, Drawer, List, Popover, Radio, RadioChangeEvent, Row } from "antd";
import CollapsePanel from "antd/lib/collapse/CollapsePanel";
import { useAtom } from "jotai";
import * as React from "react";
import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { ReactComponent as Desc } from "../../../components/assets/arrow-down-circle-outline.svg";
import { ReactComponent as Asc } from "../../../components/assets/arrow-up-circle-outline.svg";
import PrimaryButton from "../../../components/atomics/primary-button";
import SearchBox from "../../../components/atomics/search-box";
import SearchableTree from "../../../components/atomics/searchable-tree";
import StyledSelect from "../../../components/atomics/styled-select";
import TertiaryButton from "../../../components/atomics/tertiary-button";
import SecureLayout from "../../../components/layouts/secure-layout";
import ProductPart from "../../../page-parts/products-part";
import { CategoryModel } from "../../../service";
import { SortingDataType, machineFilterAtom } from "../../../store/global-atoms";
import { useSecureService } from "../../../store/service-atoms";
import style from "./list.module.scss";
import { t } from "../../../translate";

const SortingStaticData = [
    {
        label: "Önerilen",
        value: "ShowCases",
        icon: 0,
    },
    {
        label: "Aylık Artan Fiyat",
        value: "MonthlyPriceAsc",
        icon: 1,
    },
    {
        label: "Aylık Azalan Fiyat",
        value: "MonthlyPriceDesc",
        icon: 2,
    },
    {
        label: "Günlük Artan Fiyat",
        value: "DailyPriceAsc",
        icon: 1,
    },
    {
        label: "Günlük Azalan Fiyat",
        value: "DailyPriceDesc",
        icon: 2,
    },
    {
        label: "Yeniden Eskiye",
        value: "ModifiedDateDesc",
        icon: 2,
    },
    {
        label: "Eskiden Yeniye",
        value: "ModifiedDateAsc",
        icon: 1,
    },
];

const ListRentalMachines = () => {
    const { doGetCategoryWithChildren, doGetCities } = useSecureService();
    const [sortingMethod, setSortingMethod] = useState<SortingDataType>(SortingStaticData[0]);
    const [showDrawer, setShowDrawer] = useState(false);

    const [cities, setCities] = useState<any[]>();
    const [categories, setCategories] = useState<CategoryModel[] | undefined>(undefined);
    const [priceOption, setPriceOption] = useState<number | null>(null);

    const [selectedCity, setSelectedCity] = useState<number | null>(null);
    const [minPrice, setMinPrice] = useState<number | undefined>(undefined);
    const [maxPrice, setMaxPrice] = useState<number | undefined>(undefined);

    const [selectedCategoryIds, setSelectedCategoryIds] = useState<number[]>([]);
    const [, setSelectedSubCategoryIds] = useState<number[]>([]);

    const [hasFilter, setHasFilter] = useState<boolean>(false);

    const [machineFilter, setMachineFilter] = useAtom(machineFilterAtom);
    const [searchParams, setSearchParams] = useSearchParams();

    useEffect(() => {
        const getAllCities = async () => {
            var result = await doGetCities();
            if (result) {
                let options: any = [];
                result?.map((city: any) => {
                    options?.push({ value: city.id, label: city.name });
                });
                setCities(options);
            }
        };
        const getAllCategories = async () => {
            const result = await doGetCategoryWithChildren();
            if (result) {
                setCategories(result as CategoryModel[]);
            }
        };
        getAllCategories();
        getAllCities();
    }, []);

    // Filter Change begins...

    const onPriceOptionChange = (e: RadioChangeEvent) => {
        if (e.target.value) {
            searchParams.set("priceOption", e.target.value);
            searchParams.set("page", "1");
        } else {
            searchParams.delete("priceOption");
        }
        setSearchParams(searchParams, { replace: true });

        setPriceOption(e.target.value);
    };

    const onMinPriceChange = (value: number | undefined) => {
        if (value === undefined) {
            searchParams.delete("minPrice");
        } else {
            searchParams.set("minPrice", (value || "").toString());
            searchParams.set("page", "1");
        }
        setSearchParams(searchParams, { replace: true });

        setMinPrice(value);
    };

    const onMaxPriceChange = (value: number | undefined) => {
        if (value === undefined) {
            searchParams.delete("maxPrice");
        } else {
            searchParams.set("maxPrice", (value || "").toString());
            searchParams.set("page", "1");
        }
        setSearchParams(searchParams, { replace: true });

        setMaxPrice(value);
    };

    const onCityChange = (selectedCityId: number | null) => {
        if (selectedCityId) {
            searchParams.set("selectedCity", selectedCityId.toString());
            searchParams.set("page", "1");
        } else {
            searchParams.delete("selectedCity");
        }
        setSearchParams(searchParams, { replace: true });

        setSelectedCity(selectedCityId);
    };

    const onCategorySubcategoryTreeChange = (
        categoryIds: number[],
        subCategoryIds: number[],
        selectedKeys?:
            | React.Key[]
            | {
                  checked: React.Key[];
                  halfChecked: React.Key[];
              }
    ) => {
        if (selectedKeys === null || selectedKeys === undefined || selectedKeys.toString() === "") {
            searchParams.delete("makineKategori");
        } else {
            searchParams.set("makineKategori", selectedKeys?.toString().replaceAll(",", "--"));
        }
        searchParams.set("page", "1");
        setSearchParams(searchParams, { replace: true });

        setSelectedCategoryIds(categoryIds);
        setSelectedSubCategoryIds(subCategoryIds);
    };

    // Filter Change ends...

    // Sorting Method, Artan azalan etc..
    const onSortingMethodChange = (sortingMethodValue: SortingDataType) => {
        searchParams.set("order", sortingMethodValue.value);
        searchParams.set("page", "1");
        setSearchParams(searchParams, { replace: true });

        setSortingMethod(sortingMethodValue);
    };

    // Pagination
    const onPageChange = (page: number) => {
        searchParams.set("page", page.toString());
        setSearchParams(searchParams);
    };

    const onClearFilter = () => {
        setSearchParams({ page: "1", order: SortingStaticData[0].value });

        setHasFilter(false);
    };

    // parameter management begins...

    const multipleParameterSearchHelperString = (data: string | null, splitBy = "--") => {
        return (
            data?.split(splitBy).map((x) => {
                if (x.startsWith("cat")) {
                    return String(x);
                } else {
                    return Number(x);
                }
            }) ?? []
        );
    };

    const getNumberItemFromParams = (item: string) => {
        return searchParams.has(item) ? Number.parseInt((searchParams.get(item) ?? "").toString()) : null;
    };

    useEffect(() => {
        const activePageNo = Number.parseInt(searchParams.get("page") || "1");
        const activeSortingMethod = SortingStaticData.find((x) => x.value === searchParams.get("order")) ?? SortingStaticData[0];

        var hasDefaultValues = true;
        if (!searchParams.has("page")) {
            hasDefaultValues = false;
            searchParams.set("page", activePageNo.toString());
        }

        if (!searchParams.has("order")) {
            hasDefaultValues = false;
            searchParams.set("order", activeSortingMethod.value);
        }

        if (!hasDefaultValues) {
            return setSearchParams(searchParams);
        }

        const searchSelectedKeys: (string | number)[] = multipleParameterSearchHelperString(searchParams.get("makineKategori"), "--");
        const searchCategoryIds = searchSelectedKeys
            .filter((x) => x.toString().startsWith("cat-"))
            .map((x) => Number(x.toString().split("-")[1]));
        const searchSubCategoryIds = searchSelectedKeys.filter((x) => !x.toString().startsWith("cat-")).flatMap((x) => Number(x));

        setMachineFilter((old) => {
            return {
                ...old,
                categoryIds: searchCategoryIds,
                subCategoryIds: searchSubCategoryIds,
                cityId: getNumberItemFromParams("selectedCity"),
                maxPrice: getNumberItemFromParams("maxPrice"),
                minPrice: getNumberItemFromParams("minPrice"),
                rentalTypeId: getNumberItemFromParams("priceOption"),
                activePage: activePageNo,
                order: activeSortingMethod.value,
                selectedKeys: searchSelectedKeys,
            };
        });

        if (Array.from(searchParams).length > 2 || activePageNo !== 1 || activeSortingMethod.value !== SortingStaticData[0].value) {
            setHasFilter(true);
        }

        //eslint-disable-next-line
    }, [searchParams]);
    // parameter management ends...

    // getValuesFromParameters
    useEffect(() => {
        if (machineFilter) {
            setPriceOption(machineFilter.rentalTypeId || null);
            setMinPrice(machineFilter.minPrice || undefined);
            setMaxPrice(machineFilter.maxPrice || undefined);

            setSelectedCity(machineFilter.cityId || null);

            setSelectedCategoryIds(machineFilter.categoryIds || []);
            setSelectedSubCategoryIds(machineFilter.categoryIds || []);
            if ( 
                (machineFilter.brandIds?.length ?? 0) > 0 || 
                (machineFilter.categoryIds?.length ?? 0) > 0 ||
                (machineFilter.subCategoryIds?.length ?? 0) > 0 ||
                (machineFilter.cityId !== undefined && machineFilter.cityId !== null) ||
                (machineFilter.districtId !== undefined && machineFilter.districtId !== null) ||
                (machineFilter.rentalTypeId !== undefined && machineFilter.rentalTypeId !== null)  || 
                (machineFilter.maxPrice !== undefined && machineFilter.maxPrice !== null) || 
                (machineFilter.minPrice !== undefined && machineFilter.minPrice !== null)  ||                                                  
                (machineFilter.categoryIds !== undefined && machineFilter.categoryIds.length > 0) 
            ) {
                setHasFilter(true);
            } else {
                setHasFilter(false);
            }
        }
    }, [machineFilter]);

    return (
        <SecureLayout activePage={"rental-machines"}>
            <Row className={style.mobileFilter} gutter={8} style={{ padding: "2rem 0" }}>
                <Col md={12} xs={8}>
                    <Button className={style.filterButtons} onClick={() => setShowDrawer(true)}>
                        Filtrele
                    </Button>
                </Col>
                <Col md={12} xs={16}>
                    <Popover
                        className={style.filterButtons}
                        placement="bottom"
                        arrowContent={false}
                        getPopupContainer={(trigger) => trigger.parentNode as HTMLElement}
                        content={
                            <List
                                className={style.orderListContainer}
                                dataSource={SortingStaticData}
                                renderItem={(item) => (
                                    <List.Item style={{ border: "none" }}>
                                        <a onClick={() => onSortingMethodChange(item)} className={style.orderList}>
                                            {item.label}
                                        </a>
                                    </List.Item>
                                )}
                            />
                        }
                        trigger="focus"
                    >
                        <Button onClick={(e) => e.currentTarget.focus()}>
                            <Row style={{ alignItems: "center", justifyContent: "center" }}>
                                <span style={{ marginRight: 5 }}>Sırala: </span>
                                <span className={style.orderSelection}>{sortingMethod.label}</span>
                                {sortingMethod.icon === 0 ? <></> : sortingMethod.icon === 1 ? <Asc /> : <Desc />}
                            </Row>
                        </Button>
                    </Popover>
                </Col>
            </Row>
            <Drawer
                style={{ zIndex: "1"}}
                bodyStyle={{ margin: "5rem 0 0 0" }}
                height="100vh"
                placement="top"
                closable={false}
                visible={showDrawer}
            >
                <Collapse className={style.CollapsePanel} defaultActiveKey={["1", "2", "3", "4"]}>
                    <CollapsePanel className="searchable" header="İhtiyacınız Olan Makine Tipini Seçiniz.." key="1">
                        <SearchableTree
                            categories={selectedCategoryIds.flatMap((x) => String(x))}
                            selectedKeys={machineFilter?.selectedKeys ?? []}
                            onItemChecked={onCategorySubcategoryTreeChange}
                            data={categories}
                        ></SearchableTree>
                    </CollapsePanel>
                    <CollapsePanel header="Fiyat" key="2">
                        <Row gutter={[24, 24]}>
                            <Col md={24} xs={24}>
                                <Radio.Group onChange={onPriceOptionChange} value={priceOption}>
                                    <Radio value={3}>Aylık</Radio>
                                    <Radio value={2}>Günlük</Radio>
                                </Radio.Group>
                            </Col>
                            <Col md={24} xs={24}>
                                <SearchBox
                                    onPriceChange={onMinPriceChange}
                                    disabled={priceOption === null}
                                    value={minPrice}
                                    placeholder={"Minimum Fiyat"}
                                    style={{ width: "100%" }}
                                ></SearchBox>
                            </Col>
                            <Col md={24} xs={24}>
                                <SearchBox
                                    onPriceChange={onMaxPriceChange}
                                    disabled={priceOption === null}
                                    value={maxPrice}
                                    placeholder={"Maximum Fiyat"}
                                    style={{ width: "100%" }}
                                ></SearchBox>
                            </Col>
                        </Row>
                    </CollapsePanel>
                    <CollapsePanel header="Lokasyon Seçiniz" key="3">
                        <Row gutter={[24, 24]}>
                            <Col md={24} xs={24}>
                                <StyledSelect
                                    placeholder="İl"
                                    clearIcon
                                    value={selectedCity}
                                    optionFilterProp="children"
                                    onChange={e => {
                                        setSelectedCity(e);
                                        onCityChange(e);
                                    }}
                                    filterOption={(input, option) =>
                                        option?.label &&
                                        typeof option.label === "string" &&
                                        option.label.toLowerCase().includes(input.toLowerCase())
                                            ? true
                                            : false
                                    }
                                    options={cities}
                                />
                            </Col>
                        </Row>
                    </CollapsePanel>
                </Collapse>
                <Row gutter={16} style={{ padding: "2rem 0", paddingBottom: '80px' }} >
                    <Col hidden={!hasFilter} md={12} xs={12}>
                        <TertiaryButton style={{ width: "100%" }} onClick={onClearFilter}>
                            {t(`common.filter.reset`)}
                        </TertiaryButton>
                    </Col>
                    <Col md={hasFilter ? 12 : 24} xs={hasFilter ? 12 : 24}>
                        <PrimaryButton onClick={() => setShowDrawer(false)} style={{ width: "100%" }}>
                            Filtrele
                        </PrimaryButton>
                    </Col>
                </Row>
            </Drawer>
            <Row gutter={16}>
                <Col md={5} style={{ marginTop: "3rem" }} className={style.desktopVisible}>
                    <Collapse className={style.CollapsePanel} defaultActiveKey={["1", "2", "3", "4"]} bordered={false}>
                        <CollapsePanel
                            style={{ border: "none" }}
                            className="searchable"
                            header="İhtiyacınız Olan Makine Tipini Seçiniz.."
                            key="1"
                        >
                            <SearchableTree
                                selectedKeys={machineFilter?.selectedKeys}
                                categories={selectedCategoryIds.flatMap((x) => String(x))}
                                onItemChecked={onCategorySubcategoryTreeChange}
                                data={categories}
                            ></SearchableTree>
                        </CollapsePanel>
                        <CollapsePanel style={{ border: "none" }} header="Fiyat" key="2">
                            <Row gutter={[24, 24]}>
                                <Col md={24} xs={24}>
                                    <Radio.Group onChange={onPriceOptionChange} value={priceOption}>
                                        <Radio value={3}>Aylık</Radio>
                                        <Radio value={2}>Günlük</Radio>
                                    </Radio.Group>
                                </Col>
                                <Col md={24} xs={24}>
                                    <SearchBox
                                        onPriceChange={onMinPriceChange}
                                        disabled={priceOption === null}
                                        value={minPrice}
                                        placeholder={"Minimum Fiyat"}
                                        style={{ width: "100%" }}
                                    ></SearchBox>
                                </Col>
                                <Col md={24} xs={24}>
                                    <SearchBox
                                        onPriceChange={onMaxPriceChange}
                                        disabled={priceOption === null}
                                        value={maxPrice}
                                        placeholder={"Maximum Fiyat"}
                                        style={{ width: "100%" }}
                                    ></SearchBox>
                                </Col>
                            </Row>
                        </CollapsePanel>
                        <CollapsePanel style={{ border: "none" }} header="Lokasyon Seçiniz" key="3">
                            <Row gutter={[24, 24]}>
                                <Col md={24} xs={24}>
                                    <StyledSelect
                                        allowClear
                                        placeholder="İl"
                                        optionFilterProp="children"
                                        value={selectedCity}
                                        onChange={onCityChange}
                                        filterOption={(input, option) =>
                                            option?.label &&
                                            typeof option.label === "string" &&
                                            option.label.toLowerCase().includes(input.toLowerCase())
                                                ? true
                                                : false
                                        }
                                        options={cities}
                                    />
                                </Col>
                            </Row>
                        </CollapsePanel>
                        {hasFilter && (
                            <CollapsePanel style={{ border: "none" }} header="Filtreleri Temizleyin" key="4">
                                <Row gutter={[24, 24]}>
                                    <Col md={24} xs={24}>
                                        <PrimaryButton onClick={onClearFilter} style={{ width: "100%" }}>
                                            Filtreleri Temizleyin
                                        </PrimaryButton>
                                    </Col>
                                </Row>
                            </CollapsePanel>
                        )}
                    </Collapse>
                </Col>
                <Col md={19}>
                    <ProductPart
                        showPagination
                        orderButton={
                            <Popover
                                className={style.filterButtons}
                                placement="bottom"
                                arrowContent={false}
                                getPopupContainer={(trigger) => trigger.parentNode as HTMLElement}
                                content={
                                    <List
                                        className={style.orderListContainer}
                                        dataSource={SortingStaticData}
                                        renderItem={(item) => (
                                            <List.Item style={{ border: "none" }}>
                                                <a onClick={() => onSortingMethodChange(item)} className={style.orderList}>
                                                    {item.label}
                                                </a>
                                            </List.Item>
                                        )}
                                    />
                                }
                                trigger="focus"
                            >
                                <Button onClick={(e) => e.currentTarget.focus()} style={{ width: "94%" }}>
                                    <Row style={{ alignItems: "center", justifyContent: "center" }}>
                                        <span style={{ marginRight: 5 }}>Sırala: </span>
                                        <span className={style.orderSelection}>{sortingMethod.label}</span>
                                        {sortingMethod.icon === 0 ? <></> : sortingMethod.icon === 1 ? <Asc /> : <Desc />}
                                    </Row>
                                </Button>
                            </Popover>
                        }
                        machineFilter={machineFilter}
                        showHeader={false}
                        showButton={false}
                        onPageChange={onPageChange}
                    />
                </Col>
            </Row>
        </SecureLayout>
    );
};

export default ListRentalMachines;
