/* tslint:disable */
/* eslint-disable */
/**
 * MachineGo P1 API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface OfferFormCommentModel
 */
export interface OfferFormCommentModel {
    /**
     * 
     * @type {number}
     * @memberof OfferFormCommentModel
     */
    offerFormId: number;
    /**
     * 
     * @type {string}
     * @memberof OfferFormCommentModel
     */
    comment?: string | null;
}

export function OfferFormCommentModelFromJSON(json: any): OfferFormCommentModel {
    return OfferFormCommentModelFromJSONTyped(json, false);
}

export function OfferFormCommentModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): OfferFormCommentModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'offerFormId': json['offerFormId'],
        'comment': !exists(json, 'comment') ? undefined : json['comment'],
    };
}

export function OfferFormCommentModelToJSON(value?: OfferFormCommentModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'offerFormId': value.offerFormId,
        'comment': value.comment,
    };
}

