/* tslint:disable */
/* eslint-disable */
/**
 * MachineGo P1 API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface DocumentModel
 */
export interface DocumentModel {
    /**
     * 
     * @type {number}
     * @memberof DocumentModel
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof DocumentModel
     */
    fileName?: string | null;
    /**
     * 
     * @type {number}
     * @memberof DocumentModel
     */
    contentLength?: number;
    /**
     * 
     * @type {string}
     * @memberof DocumentModel
     */
    contentType?: string | null;
}

export function DocumentModelFromJSON(json: any): DocumentModel {
    return DocumentModelFromJSONTyped(json, false);
}

export function DocumentModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): DocumentModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'fileName': !exists(json, 'fileName') ? undefined : json['fileName'],
        'contentLength': !exists(json, 'contentLength') ? undefined : json['contentLength'],
        'contentType': !exists(json, 'contentType') ? undefined : json['contentType'],
    };
}

export function DocumentModelToJSON(value?: DocumentModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'fileName': value.fileName,
        'contentLength': value.contentLength,
        'contentType': value.contentType,
    };
}

