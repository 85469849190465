import { Col, Divider, Row } from "antd";
import { useEffect, useState } from "react";
import { useAtom } from "jotai";
import BoxContainer from "../../components/containers/box-container";
import SecureLayout from "../../components/layouts/secure-layout";
import { t } from "../../translate";
import { ReactComponent as Profile } from "../../components/assets/profile.svg";
import { useSecureService } from "../../store/service-atoms";
import {
    CityModel,
    RenterCompanyPaymentInfoViewModel,
    RenterCompanyProfileCreateModel,
    RenterCompanyProfileViewModel,
    RenterCompanyPaymentUpdateModel,
} from "../../service";
//import SecondaryButton from "../../components/atomics/secondary-button";
import BasicModelViewer from "../../components/atomics/basic-model-viewer";
import { postloginMessageAtom, userAtom } from "../../store/global-atoms";
import UpdateRenterProfileModal from "../../modals/update-renter-profile-modal";
import UpdateRenterPaymnetModal from "../../modals/update-renter-payment-info-modal";
import styles from "./customer-profile.module.scss"
import PrimaryButton from "../../components/atomics/primary-button";
import DeleteAccountModal from "../../modals/delete-account-modal";
import { useNavigate } from "react-router-dom";
import SMSVerificationModal from "../../modals/sms-verification-modal";

const CustomerProfile = () => {
    const [user] = useAtom(userAtom);
    const [profile, setCompanyProfile] = useState<RenterCompanyProfileViewModel | undefined>(undefined);
    const [companyDetail, setCompanyDetail] = useState<RenterCompanyPaymentInfoViewModel | undefined>(undefined);
    const [updateProfileModalVisible, setUpdateProfileModalVisible] = useState<boolean>(false);
    const [updatePaymentModalVisible, setUpdatePaymentModalVisible] = useState<boolean>(false);
    const [cities, setCities] = useState<CityModel[] | undefined>(undefined);
    const [, setErrorMessage] = useAtom(postloginMessageAtom);
    const [render, reRender] = useState<number>(0);
    const [dataFetching, setDataFetching] = useState(true);
    const [deleteAccountModalVisible, setDeleteAccountModalVisible] = useState(false);
    const [deleteAccountSMSVerificationModalVisible, setDeleteAccountSMSVerificationModalVisible] = useState(false);

    const navigate = useNavigate();


    const { doGetRenterCompanyDetail, doDeleteRenterUser, doGenerateDeleteRenterUserOTP, doGetCities, doGetRenterCompanyProfile, doSaveRenterCompanyProfile, doUpdateRenterCompanyInfo } =
        useSecureService();

    useEffect(() => {
        doGetRenterCompanyDetail(user?.id).then((result) => {
            setCompanyDetail(result);
        });
        doGetRenterCompanyProfile(user?.id).then((result) => {
            setCompanyProfile(result);
        });
        doGetCities().then((result) => {
            setCities(result);
        });

        //eslint-disable-next-line
    }, [render]);

    useEffect(() => {
        if (companyDetail && profile && cities) {
            setDataFetching(false);
        }
    }, [companyDetail, profile, cities]);

    const onUpdateProfileClick = () => {
        setUpdateProfileModalVisible(true);
    };
    const onUpdatePaymentInfoClick = () => {
        setUpdatePaymentModalVisible(true);
    };
    const onDeleteAccountClick = () => {
        setDeleteAccountModalVisible(true);
    };

    const profileUpdateApprove = async (data: RenterCompanyProfileCreateModel | undefined) => {
        if (data) {
            const result = await doSaveRenterCompanyProfile(user?.id, data);
            if (result) {
                setErrorMessage({
                    type: "success",
                    message: t("lessor-profile.companyProfile.editSuccess"),
                });
                reRender(render + 1);
            }
            setUpdateProfileModalVisible(false);
        }
    };

    const PaymentInfoUpdateApprove = async (data: RenterCompanyPaymentUpdateModel | undefined) => {
        if (data) {
            const result = await doUpdateRenterCompanyInfo(user?.id, data);
            
            if (result) {
                setErrorMessage({
                    type: "success",
                    message: t("lessor-profile.companyInfo.editSuccess"),
                });
                reRender(render + 1);
            }
            setUpdatePaymentModalVisible(false);
        }
    };

    const onDeleteClick = async () => {
        var id = await doGenerateDeleteRenterUserOTP();
        if (id) {
            setDeleteAccountSMSVerificationModalVisible(true);
            setDeleteAccountModalVisible(false);
        }
    }

    const onDeleteSMSVerificationClick = async (otp: string) => {

        console.log('otp to send:' + otp);
        const result = await doDeleteRenterUser(otp);

        if (result) {
            setErrorMessage({message: t("common.deleteSuccess"), type: 'success'});
            navigate("/login");
        } 
    }

    return (
        <SecureLayout loading={dataFetching} activePage={undefined} role={"lessor"} title={t("lessor-profile.title")}>
            <Row gutter={[24, 24]}>
                <Col xs={24} md={7} style={{ height: "fit-content" }}>
                    <BoxContainer>
                        <div style={{ display: "flex", flexDirection: "column", alignItems: "center", gap: "1rem" }}>
                            <div
                                style={{
                                    borderRadius: "50px",
                                    height: "100px",
                                    width: "100px",
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    border: "1px solid #94949455",
                                }}
                            >
                                <Profile height={50} width={50} />
                            </div>
                            <div
                                style={{
                                    color: "#FFFFFF",
                                    background: "#949394",
                                    borderRadius: "5rem",
                                    padding: ".25rem 1rem",
                                    fontSize: ".875rem",
                                    fontWeight: "700",
                                    marginTop: "-2rem",
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                }}
                            >
                                <span style={{ textAlign: "center" }}>{profile?.name}</span>
                            </div>
                            <span
                                style={{
                                    fontWeight: "700",
                                    fontSize: "1.25rem",
                                }}
                            >
                                {profile?.phone || ""}
                            </span>
                            <span
                                style={{
                                    fontWeight: "700",
                                    fontSize: "1rem",
                                }}
                            >
                                {profile?.email}
                            </span>
                            <div>
                                
                                <PrimaryButton onClick={onUpdateProfileClick}>{t("common.updateInfo")}</PrimaryButton>
                                {/* <SecondaryButton>{t("common.changePassword")}</SecondaryButton> */}
                            </div>
                        </div>
                    </BoxContainer>
                </Col>
                <Col xs={24} md={17} style={{ height: "fit-content" }}>
                    <BoxContainer>
                        <Row gutter={[8, 8]}>
                            <BasicModelViewer objKey={t("common.companyName")} value={companyDetail?.companyName} />
                            <BasicModelViewer
                                objKey={t("customer-profile.update.serviceRegionsLabel")}
                                value={companyDetail?.serviceRegions?.map((reg) => reg.name).join(", ")}
                            />
                            <BasicModelViewer objKey={t("customer-profile.update.addressLabel")} value={companyDetail?.address} />
                            <BasicModelViewer
                                objKey={t("lessor-profile.companyView.taxIdentityNo")}
                                value={companyDetail?.taxIdentityNo || "-"}
                            />
                            <BasicModelViewer
                                objKey={t("lessor-profile.companyView.taxOfficeCityName")}
                                value={cities?.find(cty=>cty.id===companyDetail?.taxOfficeCityId)?.name || "-"}
                            />
                            <BasicModelViewer objKey={t("lessor-profile.companyView.taxOffice")} value={companyDetail?.taxOffice || "-"} />
                            <BasicModelViewer objKey={t("lessor-profile.companyView.iban")} value={companyDetail?.iban || "-"} />
                        </Row>
                        <Divider />
                        <Row className={styles['button-container']}>
                            <Col xs={24} sm={11} md={9}>
                                <PrimaryButton onClick={onUpdatePaymentInfoClick}>
                                    {t("common.updateInfo")}
                                </PrimaryButton>            
                            </Col>
                            <Col xs={24} sm={11} md={9}>
                                <PrimaryButton style={{backgroundColor: 'red', borderColor: 'transparent'}} onClick={onDeleteAccountClick}>
                                    {t("common.deleteAccount")}
                                </PrimaryButton>
                            </Col>
                        </Row>
                    </BoxContainer>
                </Col>
            </Row>
            <UpdateRenterProfileModal
                show={updateProfileModalVisible}
                onCloseClick={() => setUpdateProfileModalVisible(false)}
                onApprove={profileUpdateApprove}
                profileData={profile}
                cities={cities}
            />
            <UpdateRenterPaymnetModal
                show={updatePaymentModalVisible}
                onCloseClick={() => setUpdatePaymentModalVisible(false)}
                onUpdate={PaymentInfoUpdateApprove}
                paymentData={companyDetail}
                cities={cities}
            ></UpdateRenterPaymnetModal>
            <DeleteAccountModal 
                type='customer'
                hasAnyRequest={profile?.hasAnyRequest}
                onDeleteClick={onDeleteClick}
                onCloseClick={() => setDeleteAccountModalVisible(false)}
                show={deleteAccountModalVisible}
            />
            <SMSVerificationModal 
                key={'delete-verification-modal-customer-' + deleteAccountSMSVerificationModalVisible}
                type='userDeletion'
                onSendOtpClicked={onDeleteSMSVerificationClick}
                phone={profile?.phone ?? ''}
                show={deleteAccountSMSVerificationModalVisible}
                onCloseClick={() => setDeleteAccountSMSVerificationModalVisible(false)}
            />
        </SecureLayout>
    );
};
export default CustomerProfile;
