export type Currency =
  | 'ALL'
  | 'AFN'
  | 'ARS'
  | 'AWG'
  | 'AUD'
  | 'AZN'
  | 'BSD'
  | 'BBD'
  | 'BYN'
  | 'BZD'
  | 'BMD'
  | 'BOB'
  | 'BAM'
  | 'BWP'
  | 'BGN'
  | 'BRL'
  | 'BND'
  | 'KHR'
  | 'CAD'
  | 'KYD'
  | 'CLP'
  | 'CNY'
  | 'COP'
  | 'CRC'
  | 'HRK'
  | 'CUP'
  | 'CZK'
  | 'DKK'
  | 'DOP'
  | 'EGP'
  | 'SVC'
  | 'EUR'
  | 'FKP'
  | 'FJD'
  | 'GHS'
  | 'GIP'
  | 'GTQ'
  | 'GGP'
  | 'GYD'
  | 'HNL'
  | 'HKD'
  | 'HUF'
  | 'ISK'
  | 'INR'
  | 'IDR'
  | 'IRR'
  | 'IMP'
  | 'ILS'
  | 'JMD'
  | 'JPY'
  | 'KZT'
  | 'KRW'
  | 'KGS'
  | 'LAK'
  | 'LBP'
  | 'LRD'
  | 'MKD'
  | 'MYR'
  | 'MUR'
  | 'MXN'
  | 'MNT'
  | 'MZN'
  | 'NAD'
  | 'NPR'
  | 'NZD'
  | 'NIO'
  | 'NGN'
  | 'NOK'
  | 'OMR'
  | 'PKR'
  | 'PAB'
  | 'PYG'
  | 'PEN'
  | 'PHP'
  | 'PLN'
  | 'QAR'
  | 'RON'
  | 'RUB'
  | 'SAR'
  | 'RSD'
  | 'SCR'
  | 'SGD'
  | 'SBD'
  | 'SOS'
  | 'ZAR'
  | 'LKR'
  | 'SEK'
  | 'CHF'
  | 'SRD'
  | 'SYP'
  | 'TWD'
  | 'THB'
  | 'TTD'
  | 'TRY'
  | 'TVD'
  | 'UAH'
  | 'AED'
  | 'GBP'
  | 'USD'
  | 'UYU'
  | 'UZS'
  | 'VEF'
  | 'VND'
  | 'YER'
  | 'ZWD';

export const CurrencySymbols: Record<Currency, string> = {
  ALL: 'Lek',
  AFN: '؋',
  ARS: '$',
  AWG: 'ƒ',
  AUD: '$',
  AZN: '₼',
  BSD: '$',
  BBD: '$',
  BYN: 'Br',
  BZD: 'BZ$',
  BMD: '$',
  BOB: '$b',
  BAM: 'KM',
  BWP: 'P',
  BGN: 'лв',
  BRL: 'R$',
  BND: '$',
  KHR: '៛',
  CAD: '$',
  KYD: '$',
  CLP: '$',
  CNY: '¥',
  COP: '$',
  CRC: '₡',
  HRK: 'kn',
  CUP: '₱',
  CZK: 'Kč',
  DKK: 'kr',
  DOP: 'RD$',
  EGP: '£',
  SVC: '$',
  EUR: '€',
  FKP: '£',
  FJD: '$',
  GHS: '¢',
  GIP: '£',
  GTQ: 'Q',
  GGP: '£',
  GYD: '$',
  HNL: 'L',
  HKD: '$',
  HUF: 'Ft',
  ISK: 'kr',
  INR: '₹',
  IDR: 'Rp',
  IRR: '﷼',
  IMP: '£',
  ILS: '₪',
  JMD: 'J$',
  JPY: '¥',
  KZT: 'лв',
  KRW: '₩',
  KGS: 'лв',
  LAK: '₭',
  LBP: '£',
  LRD: '$',
  MKD: 'ден',
  MYR: 'RM',
  MUR: '₨',
  MXN: '$',
  MNT: '₮',
  MZN: 'MT',
  NAD: '$',
  NPR: '₨',
  NZD: '$',
  NIO: 'C$',
  NGN: '₦',
  NOK: 'kr',
  OMR: '﷼',
  PKR: '₨',
  PAB: 'B/.',
  PYG: 'Gs',
  PEN: 'S/.',
  PHP: '₱',
  PLN: 'zł',
  QAR: '﷼',
  RON: 'lei',
  RUB: '₽',
  SAR: '﷼',
  RSD: 'Дин.',
  SCR: '₨',
  SGD: '$',
  SBD: '$',
  SOS: 'S',
  ZAR: 'R',
  LKR: '₨',
  SEK: 'kr',
  CHF: 'CHF',
  SRD: '$',
  SYP: '£',
  TWD: 'NT$',
  THB: '฿',
  TTD: 'TT$',
  TRY: '₺',
  TVD: '$',
  UAH: '₴',
  AED: 'د.إ',
  GBP: '£',
  USD: '$',
  UYU: '$U',
  UZS: 'лв',
  VEF: 'Bs',
  VND: '₫',
  YER: '﷼',
  ZWD: 'Z$',
};
