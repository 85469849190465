import { Checkbox, Col, Row } from "antd";
import { t } from "../translate";
import BigboxContainer from "../components/containers/bigbox-container";
import styles from "./styles/offer-view-additional-services-part.module.scss"
import { ViewPartProps } from "../viewmodels/view-page-part-props";

export type OfferViewAdditionalServicesPartProps = {
    hasOperator: boolean;
}

const OfferViewAdditionalServicesPart = (props: ViewPartProps & OfferViewAdditionalServicesPartProps) => {

    let editedMachine = props.machine || {};
    editedMachine = {
        ...editedMachine,
        ...props.request?.additionalServices,
        ...props.additionalServices,
    };

    return (

        <BigboxContainer title={t("machines.add.additionalServicesTitle")}>
            <Row gutter={[16, 24]} style={{display: 'flex', justifyContent: 'space-between', height: 'auto'}}>
                <Col xs={24} md={7} className={styles.serviceOperatorContainer}>
                    <div style={{marginTop: '10px', display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                        <span style={{fontWeight: '700', marginLeft: '10px'}}>{t("machines.add.operator")}</span>
                        <div style={{marginRight: '10px'}}>
                            <Checkbox checked={props.hasOperator}></Checkbox>
                        </div>
                    </div>
                        
                    <span style={{fontWeight: '700', marginLeft: '10px', marginTop: '15px'}}>{t("machines.add.experience")}</span>
                    <div style={{display: 'flex', justifyContent: 'center'}}>
                        <div className={styles.operatorExperienceContainer}>
                            <span style={{marginRight: '8px', color: 'rgb(204, 204, 204)'}}>{props.additionalServices?.operatorExperienceYear ?? "-"}</span>
                        </div>
                    </div>
                        
                </Col>

                <Col xs={24} md={16} style={{display: 'flex', justifyContent: 'space-between', flexWrap: 'wrap', alignContent: 'space-between'}}>
                    <Col xs={24} md={11} className={styles.otherServiceContainer}>   

                        <div style={{marginLeft: '10px', width: '80%'}}>
                            <span>{t("machines.add.operatorCostIncluded")}</span>
                        </div>

                        <div style={{marginRight: '10px'}}>
                            <Checkbox checked={editedMachine?.operatorCostIncluded}></Checkbox>
                        </div>

                    </Col>

                    <Col xs={24} md={11} className={styles.otherServiceContainer}>
                        <div style={{marginLeft: '10px', width: '80%'}}>
                            <span>{t("machines.add.operatorInsuranceIncluded")}</span>
                        </div>
                        
                        <div style={{marginRight: '10px'}}>
                            <Checkbox checked={editedMachine?.operatorInsuranceIncluded}></Checkbox>
                        </div>
                
                    </Col>

                    <Col xs={24} md={11} className={styles.otherServiceContainer}>
                        <div style={{marginLeft: '10px', width: '80%'}}>
                            <span>{t("machines.add.fuelIncluded")}</span>
                        </div>
                        
                        <div style={{marginRight: '10px'}}>
                            <Checkbox checked={editedMachine?.fuelIncluded}></Checkbox>
                        </div>
                    </Col>
                </Col>

               
            </Row>
           
        </BigboxContainer>

    );
}


export default OfferViewAdditionalServicesPart;