import { Col, ColProps, Row } from "antd";
import { moment } from "../../helpers/moment";
import { ModifiedOfferDetailModel } from "../../store/global-atoms";
import { t } from "../../translate";
import BoxContainer from "../containers/box-container";
import BasicModelViewer from "./basic-model-viewer";
import PrimaryButton from "./primary-button";
import RequestAndOfferStatus from "./request-and-offer-status";

type OfferListCardProps = {
    offerForm: ModifiedOfferDetailModel | undefined;
    onViewClick?: (requestId: number | undefined, offerId: number | undefined) => void;
} & ColProps;

const OfferListCard = (props: OfferListCardProps) => {
    const viewRequestClick = () => {
        props.onViewClick && props.onViewClick(props.offerForm?.requestForm?.id, props.offerForm?.id);
    };
    const subCat = props.offerForm?.requestForm?.subCategory;
    const cat = subCat?.category;
    const bids = props.offerForm?.bids
        ?.map((bid) => {
            if (bid.lessorPeriodPayment) {
                return (
                    bid.maturityOption?.name + "/" + bid.paymentPeriod?.name + " - " + bid.lessorPeriodPayment + " " + bid.currency?.name
                );
            } else {
                return bid.maturityOption?.name + "/" + bid.paymentPeriod?.name + " - " + bid.bid + " " + bid.currency?.name;
            }
        })
        .join("\n");

    return (
        <Col {...props}>
            <BoxContainer style={{ display: "flex" }}>
                <div
                    style={{
                        display: "flex",
                        height: "100%",
                        flexDirection: "column",
                        justifyContent: "space-between",
                    }}
                >
                    <div>
                        <RequestAndOfferStatus
                            showOfferStatus={true}
                            showRequestStatus={true}
                            role="lessor"
                            offerStatus={props.offerForm?.status}
                            requestStatus={props.offerForm?.requestForm?.status}
                        />
                        <Row gutter={[8, 8]}>
                            <BasicModelViewer xs={12} md={8} objKey={t("offer.id")} value={props.offerForm?.id} />
                            <BasicModelViewer xs={12} md={8} objKey={t("offer.requestId")} value={props.offerForm?.requestForm?.id} />
                            <BasicModelViewer
                                xs={12}
                                md={8}
                                objKey={t("offer.machines.Id")}
                                value={props.offerForm?.machines?.map((mac) => mac.id).join(", ")}
                            />
                            <BasicModelViewer xs={12} md={8} objKey={t("offer.machineCount")} value={props.offerForm?.machines?.length} />
                            <BasicModelViewer xs={12} md={8} objKey={t("offer.machineCategory")} value={cat?.name} />
                            <BasicModelViewer xs={12} md={8} objKey={t("offer.machineSubcategory")} value={subCat?.name} />
                        </Row>
                        <Row gutter={[8, 8]}>
                            <BasicModelViewer
                                xs={12}
                                md={8}
                                objKey={t("offer.location")}
                                value={props.offerForm?.requestForm?.district?.city?.name}
                            />
                            <BasicModelViewer
                                xs={24}
                                md={16}
                                objKey={t("offer.dates")}
                                value={
                                    moment(props.offerForm?.requestForm?.requestDateFrom) +
                                    " - " +
                                    moment(props.offerForm?.requestForm?.requestDateTo)
                                }
                            />
                            <div style={{zIndex: 1}}>
                                <BasicModelViewer xs={24} md={24} objKey={t("offer.offers")} value={bids} />
                            </div>
                            <Col xs={24} style={{zIndex:1}}>{t("common.vatExlcluded")}</Col>
                        </Row>
                    </div>
                    <div>
                        <Col xs={16} md={14}>
                            <PrimaryButton
                                onClick={viewRequestClick}
                                style={{
                                    marginBottom: "0",
                                    marginTop: "2.5rem",
                                }}
                            >
                                {t("common.view")}
                            </PrimaryButton>
                        </Col>
                    </div>
                    <div
                        style={{
                            width: "11rem",
                            height: "11rem",
                            position: "absolute",
                            right: "0",
                            bottom: "0",
                            overflow: "hidden",
                        }}
                    >
                        {cat?.id && (
                            <img
                                style={{
                                    width: "100%",
                                    position: "relative",
                                    right: "-2rem",
                                }}
                                src={"/img" + cat?.id + ".png"}
                                alt={"/img" + cat?.id + ".png"}
                            />
                        )}
                    </div>
                </div>
            </BoxContainer>
        </Col>
    );
};

export default OfferListCard;
