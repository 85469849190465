/* tslint:disable */
/* eslint-disable */
/**
 * MachineGo P1 API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface OfferFormPaymentOptionViewModel
 */
export interface OfferFormPaymentOptionViewModel {
    /**
     * 
     * @type {number}
     * @memberof OfferFormPaymentOptionViewModel
     */
    maturityOptionId?: number;
    /**
     * 
     * @type {number}
     * @memberof OfferFormPaymentOptionViewModel
     */
    percent?: number;
}

export function OfferFormPaymentOptionViewModelFromJSON(json: any): OfferFormPaymentOptionViewModel {
    return OfferFormPaymentOptionViewModelFromJSONTyped(json, false);
}

export function OfferFormPaymentOptionViewModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): OfferFormPaymentOptionViewModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'maturityOptionId': !exists(json, 'maturityOptionId') ? undefined : json['maturityOptionId'],
        'percent': !exists(json, 'percent') ? undefined : json['percent'],
    };
}

export function OfferFormPaymentOptionViewModelToJSON(value?: OfferFormPaymentOptionViewModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'maturityOptionId': value.maturityOptionId,
        'percent': value.percent,
    };
}

