import styles from "./styles/contact-machinego.module.scss"
import {ReactComponent as HeadphonesIcon} from "../components/assets/icons/headphonesIcon.svg"
import { t } from "../translate";

interface ContactMachinegoProps {
    type: 'tel';
}

const ContactMachinego = (props: ContactMachinegoProps) => {
    return(
        <div className={styles.container}>
            <div className={styles.headphoneIconContainer}>
                <HeadphonesIcon className={styles.headphoneIcon} />
            </div>
            <div className={styles.title}>
                <p><span>{t("contact.machinego-title-1")}</span>{' ' + t("contact.machinego-title-2")}</p>
            </div>
            <div className={styles.contact}>
                {
                    props.type === 'tel' ? (<a href="tel:0212 706 0330" className={styles.tel}><span>{"0212 706 0330"}</span></a>) : (<></>)
                }
            </div>

        </div>
    );
}

export default ContactMachinego;