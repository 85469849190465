import { Select } from 'antd';
import { useEffect } from 'react';
import { ReactComponent as Suffix } from '../assets/arrowDown.svg';
import { lessorPurposeAtom, selectedOptionAtom } from '../../store/global-atoms';
import { useAtom } from 'jotai';
import { LessorPurpose } from '../../service';
import styles from '../styles/btn.module.scss';
type ItemType = {
  name: string;
  subs: string[];
};

type SwitchProps = {
  activeItem: number;
  items: ItemType[];
  hideItem?: number[];
  onSwitch: () => void;
  onSelect: (value: string) => void;
};
const baseStyle = {
  paddingTop: '.5rem',
  paddingBottom: '.5rem',
  paddingRight: '1rem',
  paddingLeft: '1rem',
  borderRadius: '18px',
  height: '28px',
  display: 'flex',
  alignItems: 'center',
};
const activeStyle = {
  ...baseStyle,
  background: 'white',
  fontWeight: '800',
  color: '#282828FF',
  cursor: 'default'
};
const passiveStyle = {
  ...baseStyle,
  fontWeight: '600',
  color: '#282828AA',
  cursor: 'pointer'
};
const LinkButton = (props: SwitchProps) => {

  useEffect(()=>{
    if(!selectedOption)
      setSelectedOption(props.items[0].subs[0]);
    else {
      if(lessorPurpose === LessorPurpose.OnlyForRent)
        setSelectedOption(props.items[0].subs[0]);
      else if(lessorPurpose === LessorPurpose.OnlyForSale)
        setSelectedOption(props.items[0].subs[1]);
      else
        setSelectedOption(props.items[0].subs[0]);
    }
    //eslint-disable-next-line
  },[]);

  const [selectedOption, setSelectedOption] = useAtom(selectedOptionAtom);
  const [lessorPurpose] = useAtom(lessorPurposeAtom);

  const handleSelect = (value: string) => {
    setSelectedOption(value);
    props.onSelect(value);
  };

  return (
    <div
      style={{
        background: '#FFF4E1',
        borderRadius: '20px',
        height: '34px',
        display: 'flex',
        padding: '3px',
      }}
    >
      {props.activeItem === 0 ? (
        <div style={activeStyle}>
          <Select
            className={styles.switchButtonText}
            suffixIcon={<Suffix />}
            dropdownStyle={{ border: 'solid 1px #FFEBC3', borderRadius: '20px', padding: '10px' ,fontSize:'1rem'}}
            onSelect={handleSelect}
            bordered={false}
            value={props.items[0].name + '/' + selectedOption + 'im'}
            getPopupContainer={(trigger: any) => trigger.parentElement}
          >
            {props.items[0].subs.map((option, index) => {
              const isActive = option === selectedOption;
              const isLastItem = index === props.items[1].subs.length - 1;

              return (
                <Select.Option
                  style={{
                    borderBottom: isLastItem ? 'none' : 'solid 1px #eae9ea',
                    padding: '20px',
                    fontSize:'1rem',
                    backgroundColor: 'transparent',
                    ...(isActive
                      ? {
                          color: isActive ? '#feb111' : 'initial'
                        }
                      : {})
                  }}
                  onClick={props.onSelect}
                  key={option}
                >
                  {option}
                </Select.Option>
              );
            })}
          </Select>
        </div>
      ) : (
        <div onClick={props.onSwitch} style={passiveStyle} className={styles.switchButtonText}>
          {props.items[0].name}
        </div>
      )}

      {props.activeItem === 1  ? (
         <div onClick={props.onSwitch} style={activeStyle} className={styles.switchButtonText}>
         {props.items[1].name}
       </div>
      ) : (
        <div onClick={props.onSwitch} style={passiveStyle} className={styles.switchButtonText}>
          {props.items[1].name}
        </div>
      ) }
    </div>
  );
};

export default LinkButton;
