/* tslint:disable */
/* eslint-disable */
/**
 * MachineGo P1 API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface LessorCompanyPaymentInfoViewModel
 */
export interface LessorCompanyPaymentInfoViewModel {
    /**
     * 
     * @type {number}
     * @memberof LessorCompanyPaymentInfoViewModel
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof LessorCompanyPaymentInfoViewModel
     */
    taxIdentityNo?: string | null;
    /**
     * 
     * @type {string}
     * @memberof LessorCompanyPaymentInfoViewModel
     */
    taxOffice?: string | null;
    /**
     * 
     * @type {number}
     * @memberof LessorCompanyPaymentInfoViewModel
     */
    taxOfficeCityId?: number | null;
    /**
     * 
     * @type {string}
     * @memberof LessorCompanyPaymentInfoViewModel
     */
    iban?: string | null;
}

export function LessorCompanyPaymentInfoViewModelFromJSON(json: any): LessorCompanyPaymentInfoViewModel {
    return LessorCompanyPaymentInfoViewModelFromJSONTyped(json, false);
}

export function LessorCompanyPaymentInfoViewModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): LessorCompanyPaymentInfoViewModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'taxIdentityNo': !exists(json, 'taxIdentityNo') ? undefined : json['taxIdentityNo'],
        'taxOffice': !exists(json, 'taxOffice') ? undefined : json['taxOffice'],
        'taxOfficeCityId': !exists(json, 'taxOfficeCityId') ? undefined : json['taxOfficeCityId'],
        'iban': !exists(json, 'iban') ? undefined : json['iban'],
    };
}

export function LessorCompanyPaymentInfoViewModelToJSON(value?: LessorCompanyPaymentInfoViewModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'taxIdentityNo': value.taxIdentityNo,
        'taxOffice': value.taxOffice,
        'taxOfficeCityId': value.taxOfficeCityId,
        'iban': value.iban,
    };
}

