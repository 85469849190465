import { LayoutProps } from "antd";
import { Layout } from "antd";
import styles from "../styles/base-layout.module.scss";

export type BaseLayoutProps = {
    children: any;
} & LayoutProps;

const BaseLayout = (props: BaseLayoutProps) => {
    return (
        <Layout {...props} className={styles.base}>
            {props.children}
        </Layout>
    );
};

export default BaseLayout;
