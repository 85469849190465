import { Row, Col } from "antd";
import OnboardLayout from "../../components/layouts/onboard-layout";


import styles from "./AboutUsSection.module.scss";
import CustomSvg from "../../components/atomics/custom-svg";


const AboutUs = () => {
    const lookingMachinesData = {
        iconName: "machine",
        title: "Makine arayanlar için",
        details: [
            {
                icon: "safe",
                title: "Güvenli",
            },
            {
                icon: "machinePortfolio",
                title: "En geniş makine portfoyü",
            },
            {
                icon: "multipleOffers",
                title: "Çoklu Teklif Alma",
            },
            {
                icon: "fastServices",
                title: "En Hızlı & Müşteri Dostu Hizmet",
            },
            {
                icon: "expertize",
                title: "Ekspertiz & Ücretsiz Danışmanlık",
            },
            {
                icon: "operator",
                title: "İkame Makine & Operatör Hizmeti",
            },
        ],
    };

    const machineOwnersData = {
        iconName: "keys",
        title: "Makine sahipleri için",
        details: [
            {
                icon: "users",
                title: "Yeni & Güvenilir Müşterilere Kolayca Ulaşma",
            },
            {
                icon: "safeMarketplace",
                title: "İnternetteki En Güvenilir Pazar Yeri",
            },
            {
                icon: "layoutMarketplace",
                title: "İnternetteki En Hacimli Pazar Yeri",
            },
            {
                icon: "chartUp",
                title: "Pazarlama Harcaması Yapmadan Gelirinizi Arttırma",
            },
            {
                icon: "reliable",
                title: "Güvenilir Firmaların Ödüllendirildiği bir Yapı",
            },
            {
                icon: "reliableOperator",
                title:"Güvenilir Operatör Kiralama İmkanı",
            },
        ],
    };

    return (
        <OnboardLayout>
            <section className={styles.aboutUsWrapper} id="hakkimizda">
                <div>
                <h1 className={styles.pageTitle}>MachineGo Nedir?</h1>
                <h2 className={styles.pageDescription}>MachineGo, iş ve endüstri makineleriniz için hem kiralama hem de satış imkanı sunan yenilikçi bir platformdur. Bu pazar yerinde makine sahibi olan firmalar, makinelerini listeleyebilir ve güvenilir müşterilere ulaşarak bu makineleri kiraya verebilirler. Tek yapmanız gereken size gelen taleplere, teklifinizi sunmanız. MachineGo ekibi kiralama sürecinin tüm aşamalarında uzman ekibiyle müşterilerimize en iyi hizmeti almaları için destek verir.
                </h2>
                    {/* <SectionTitle title={translate("sectionTitle")} description={translate("sectionDescription")} /> */}
                    <Row className="w-full" gutter={[16, 16]}>
                        <Col xs={24} md={12}>
                            <AboutUsCard data={lookingMachinesData} />
                        </Col>
                        <Col xs={24} md={12} >
                            <AboutUsCard data={machineOwnersData} />
                        </Col>
                    </Row>
                </div>
            </section>
        </OnboardLayout>
    );
};

const AboutUsCard = ({ data }: any) => {
    return (
        <div className={styles.cardWrapper}>
            <CustomSvg name={data.iconName} size="68" />
            <h3 className={styles.cardTitle}>{data.title}</h3>
            <div>
                {data.details.map((detail: any, index: number) => (
                    <div key={index} className={styles.detailWrapper}>
                        <CustomSvg name={detail.icon} size="46" />
                        <span className={styles.detailTitle}>{detail.title}</span>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default AboutUs;
