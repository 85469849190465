import { Col, FormInstance, Row, Tooltip } from "antd";
import { EnterInput } from "../components/atomics/checkbox-with-input";
import BigboxContainer from "../components/containers/bigbox-container";
import { t } from "../translate";
import { useLayoutEffect, useState } from "react";
import { BasicModel } from "../service";
import { useSecureService } from "../store/service-atoms";
import CheckboxWithInputPrice from "../components/atomics/checkbox-with-input-price";
import { ReactComponent as Info } from "../components/assets/info-icon.svg";
import { useAtom } from "jotai";
import { offerTabAtom } from "../store/global-atoms";

export type OfferShippingOptionsProps = {
}


const OfferShippingOptions = (props: { form: FormInstance<any> } & OfferShippingOptionsProps) => {
    const [availableCurrencies, setAvailableCurrencies] = useState<BasicModel[] | undefined>(undefined);
    const [offerTabInfo,] = useAtom(offerTabAtom);
    const { doGetCurrencies } = useSecureService();

    useLayoutEffect(
        () => {

            const getServiceData = async() => {
                const currencies = await doGetCurrencies();
                setAvailableCurrencies(currencies);
            }
            
            getServiceData();
        }
        ,[doGetCurrencies]);

    return (
        <BigboxContainer title={t("offers.shipping.title")}>
            <div style={{position: 'absolute', width: '100%', marginTop: '0.8rem'}}>    
                <Tooltip title='info'>
                    <div style={{width: '3rem', float: 'right', marginRight: '2rem'}}>
                        <Info style={{transform: 'scale(0.8)'}}></Info>
                    </div>
                    
                </Tooltip>
            </div>
            <Row gutter={12}>
                <Col xs={24} sm={12} md={12}>
                    <CheckboxWithInputPrice 
                        checkboxLabel={t("offers.shipping.option1")}
                        checkboxName={"shippingOption1"}
                        inputName={"goFee"}
                        alwaysDisabled={!offerTabInfo.lowestMaturitySelected}
                        form={props.form}
                        enterType={EnterInput.Price} 
                        title={""} 
                        isSuffixDropdown={true}
                        suffixProps={{
                            selectName: "select-shippingOption1",
                            items: availableCurrencies,
                            selectedItem: {name: offerTabInfo.currencyType},
                        }}            
                        suffixDisabled={true}
                        isShippingFeeInput={"goFee"}        
                        initialValue={0}
          
                    />
                </Col>
                <Col xs={24} sm={12} md={12}>
                    <CheckboxWithInputPrice 
                        checkboxLabel={t("offers.shipping.option2")}
                        checkboxName={"shippingOption2"}
                        inputName={"returnFee"}
                        alwaysDisabled={!offerTabInfo.lowestMaturitySelected}
                        form={props.form}
                        enterType={EnterInput.Price} 
                        title={""}     
                        isSuffixDropdown={true}
                        suffixProps={{
                            selectName: "select-shippingOption2",
                            items: availableCurrencies,
                            selectedItem: {name: offerTabInfo.currencyType},
                        }}        
                        suffixDisabled={true}
                        isShippingFeeInput={"returnFee"} 
                        initialValue={0}
                    />
                </Col>
            </Row>

            <Row>
                <Col xs={24} md={12}>
                    
                </Col>
            </Row>
            
        </BigboxContainer>
    );
}

export default OfferShippingOptions;