import { Col } from "antd";
import style from "./product-card.module.scss";
import React from "react";
import { allMustFields } from "../../store/all-must-fields";
import { t } from "../../translate";

export const BrandName = (cid?: number, sid?: number, defaultValue?: number | null | string | undefined, prelogin?: boolean) => {
    if (!isFieldExists(cid, sid, "brandName", prelogin)) return <></>;
    let required = prelogin ? true : isRequired(cid, sid, "brandName");
    return CreateGenericLabel("brandName", required, "", defaultValue, "");
};
export const BrandOther = (cid?: number, sid?: number, defaultValue?: number | null | string | undefined, prelogin?: boolean) => {
    let required = prelogin ? true : isRequired(cid, sid, "brandOther");
    return CreateGenericLabel("brandOther", required, "", defaultValue, "");
};
export const ModelName = (cid?: number, sid?: number, defaultValue?: number | null | string | undefined, prelogin?: boolean) => {
    if (!isFieldExists(cid, sid, "modelName", prelogin)) return <></>;
    let required = prelogin ? true : isRequired(cid, sid, "modelName");
    return CreateGenericLabel("modelName", required, "", defaultValue, "");
};
export const ModelYear = (cid?: number, sid?: number, defaultValue?: number | null | string | undefined, prelogin?: boolean) => {
    if (!isFieldExists(cid, sid, "modelYear", prelogin)) return <></>;
    let required = prelogin ? true : isRequired(cid, sid, "modelYear");
    return CreateGenericLabel("modelYear", required, "Üretim yılı", defaultValue, "");
};

export const OperatingWeight = (cid?: number, sid?: number, defaultValue?: number | null | string | undefined, prelogin?: boolean) => {
    if (!isFieldExists(cid, sid, "operatingWeight", prelogin)) return <></>;
    let required = prelogin ? true : isRequired(cid, sid, "operatingWeight");
    return CreateGenericLabel("operatingWeight", required, "Çalışma ağırlığı", defaultValue, "(t)");
};
export const OperatingHeight = (cid?: number, sid?: number, defaultValue?: number | null | string | undefined, prelogin?: boolean) => {
    if (!isFieldExists(cid, sid, "operatingHeight", prelogin)) return <></>;
    let required = prelogin ? true : isRequired(cid, sid, "operatingHeight");
    return CreateGenericLabel("operatingHeight", required, "Çalışma yüksekliği", defaultValue, "(m)");
};
export const BomLength = (cid?: number, sid?: number, defaultValue?: number | null | string | undefined, prelogin?: boolean) => {
    if (!isFieldExists(cid, sid, "bomLength", prelogin)) return <></>;
    let required = prelogin ? true : isRequired(cid, sid, "bomLength");
    return CreateGenericLabel("bomLength", required, "Bom uzunluğu", defaultValue, "(m)");
};
export const CarryingCapacity = (cid?: number, sid?: number, defaultValue?: number | null | string | undefined, prelogin?: boolean) => {
    if (!isFieldExists(cid, sid, "carryingCapacity", prelogin)) return <></>;
    let required = prelogin ? true : isRequired(cid, sid, "carryingCapacity");
    return CreateGenericLabel("carryingCapacity", required, "Taşıma kapasitesi", defaultValue, "(t)");
};
export const EnginePower = (cid?: number, sid?: number, defaultValue?: number | null | string | undefined, prelogin?: boolean) => {
    if (!isFieldExists(cid, sid, "enginePower", prelogin)) return <></>;
    let required = prelogin ? true : isRequired(cid, sid, "enginePower");
    return CreateGenericLabel("enginePower", required, "Motor gücü", defaultValue, "(hp)");
};
export const FuelType = (cid?: number, sid?: number, defaultValue?: number | null | string | undefined, prelogin?: boolean) => {
    if (!isFieldExists(cid, sid, "fuelType", prelogin)) return <></>;
    let required = prelogin ? true : isRequired(cid, sid, "fuelType");
    return CreateGenericLabel("fuelType", required, "Yakıt tipi", defaultValue, "");
};
export const MaxHeight = (cid?: number, sid?: number, defaultValue?: number | null | string | undefined, prelogin?: boolean) => {
    if (!isFieldExists(cid, sid, "maxHeight", prelogin)) return <></>;
    let required = prelogin ? true : isRequired(cid, sid, "fuelTmaxHeightype");
    return CreateGenericLabel("maxHeight", required, "Maksimum yükseklik", defaultValue, "(m)");
};
export const MaxPower = (cid?: number, sid?: number, defaultValue?: number | null | string | undefined, prelogin?: boolean) => {
    if (!isFieldExists(cid, sid, "maxPower", prelogin)) return <></>;
    let required = prelogin ? true : isRequired(cid, sid, "maxPower");
    return CreateGenericLabel("maxPower", required, "Maksimum güç", defaultValue, "Kw");
};
export const MinPower = (cid?: number, sid?: number, defaultValue?: number | null | string | undefined, prelogin?: boolean) => {
    if (!isFieldExists(cid, sid, "minPower", prelogin)) return <></>;
    let required = prelogin ? true : isRequired(cid, sid, "minPower");
    return CreateGenericLabel("minPower", required, "Minimum güç", defaultValue, "Kw");
};

export const MaxLoadCapacity = (cid?: number, sid?: number, defaultValue?: number | null | string | undefined, prelogin?: boolean) => {
    if (!isFieldExists(cid, sid, "maxLoadCapacity", prelogin)) return <></>;
    let required = prelogin ? true : isRequired(cid, sid, "maxLoadCapacity");
    const kgUnit = [10].includes(cid ?? -1);
    const suffix = kgUnit ? t("common.kg") : 't';
    return CreateGenericLabel(defaultValue ? "maxLoadCapacity" : "noMustProp", required, "Maksimum yük kapasitesi", defaultValue,  `(${suffix})`);
};

export const LoadCapacity = (cid?: number, sid?: number, defaultValue?: number | null | string | undefined, prelogin?: boolean) => {
    if (!isFieldExists(cid, sid, 'loadCapacity', prelogin)) return <></>;
    let required = prelogin ? true : isRequired(cid, sid, 'loadCapacity');
    return CreateGenericLabel('loadCapacity', required, 'Yük kapasitesi', defaultValue, '(t)');
  };
export const NominalCapacity = (cid?: number, sid?: number, defaultValue?: number | null | string | undefined, prelogin?: boolean) => {
    if (!isFieldExists(cid, sid, "nominalCapacity", prelogin)) return <></>;
    let required = prelogin ? true : isRequired(cid, sid, "nominalCapacity");
    return CreateGenericLabel("nominalCapacity", required, "Nominal kapasite", defaultValue, "(m3)");
};
export const OperationHour = (cid?: number, sid?: number, defaultValue?: number | null | string | undefined, prelogin?: boolean) => {
    if (!isFieldExists(cid, sid, "operationHour", prelogin)) return <></>;
    let required = prelogin ? true : isRequired(cid, sid, "operationHour");
    return CreateGenericLabel("operationHour", required, "Çalışma saati", defaultValue, "(s)");
};
export const OperationMileage = (cid?: number, sid?: number, defaultValue?: number | null | string | undefined, prelogin?: boolean) => {
    if (!isFieldExists(cid, sid, "operationMileage", prelogin)) return <></>;
    let required = prelogin ? true : isRequired(cid, sid, "operationMileage");
    return CreateGenericLabel("operationMileage", required, "Çalışma kilometresi", defaultValue, "Km");
};
export const SpraySpeed = (cid?: number, sid?: number, defaultValue?: number | null | string | undefined, prelogin?: boolean) => {
    if (!isFieldExists(cid, sid, "spraySpeed", prelogin)) return null;
    let required = prelogin ? true : isRequired(cid, sid, "spraySpeed");
    return CreateGenericLabel("spraySpeed", required, "Püskürtme Hızı", defaultValue, "(m2/d)");
};
export const TankVolume = (cid?: number, sid?: number, defaultValue?: number | null | string | undefined, prelogin?: boolean) => {
    if (!isFieldExists(cid, sid, "tankVolume", prelogin)) return <></>;
    let required = prelogin ? true : isRequired(cid, sid, "tankVolume");
    return CreateGenericLabel("tankVolume", required, "Tank kapasitesi", defaultValue, "m3");
};
export const Volume = (cid?: number, sid?: number, defaultValue?: number | null | string | undefined, prelogin?: boolean) => {
    if (!isFieldExists(cid, sid, "volume", prelogin)) return <></>;
    let required = prelogin ? true : isRequired(cid, sid, "volume");
    return CreateGenericLabel("volume", required, "Hacim:", defaultValue, "m3");
};

export const FlightTime = (cid?: number, sid?: number, defaultValue?: number | null | string | undefined, prelogin?: boolean) => {
    if (!isFieldExists(cid, sid, "flightTime", prelogin)) return <></>;
    let required = prelogin ? true : isRequired(cid, sid, "flightTime");
    return CreateGenericLabel("flightTime", required, "Uçuş süresi", defaultValue, "dk");
};

export const FlightDistance = (cid?: number, sid?: number, defaultValue?: number | null | string | undefined, prelogin?: boolean) => {
    if (!isFieldExists(cid, sid, "flightDistance", prelogin)) return <></>;
    let required = prelogin ? true : isRequired(cid, sid, "flightDistance");
    return CreateGenericLabel("flightDistance", required, "Uçuş mesafesi", defaultValue, "km");
};
export const CameraResolution = (cid?: number, sid?: number, defaultValue?: number | null | string | undefined, prelogin?: boolean) => {
    if (!isFieldExists(cid, sid, "cameraResolution", prelogin)) return <></>;
    let required = prelogin ? true : isRequired(cid, sid, "cameraResolution");
    return CreateGenericLabel("cameraResolution", required, "Görüntü çözünürlüğü", defaultValue, "");
};
export const NoMustProp = (cid?: number, sid?: number, defaultValue?: number | null | string | undefined, prelogin?: boolean) => {
    if (!isFieldExists(cid, sid, "noMustProp", prelogin)) return <></>;
    let required = prelogin ? true : isRequired(cid, sid, "noMustProp");
    return CreateGenericLabel("noMustProp", required, "", defaultValue, "");
};

const mustPropertySubstring = (word: string | any) => {
    // Ekran genişliğini almak için window.innerWidth kullanıyoruz
    let screenWidth = window.innerWidth;
    // Eğer ekran genişliği 400px'den küçük ise substringi 14 yapıyoruz
    if (screenWidth < 600) {
        if (word.length <= 14) {
            return word;
        } else {
            return word.substring(0, 14) + "...";
        }
    }
    // Aksi halde, orijinal kodu çalıştırın
    else {
        if (word.length <= 15) {
            return word;
        } else {
            return word.substring(0, 15) + "...";
        }
    }
};

const CreateGenericLabel = (
    fieldName: string,
    required: boolean,
    mustProperty: number | null | string | undefined,
    defaultValue: number | null | string | undefined,
    Unit: number | null | string | undefined
) => {
    if (fieldName === "brandName" || fieldName === "modelName" || fieldName === "brandOther") {
        return (
            <>
                {mustProperty} <strong>{defaultValue}</strong>
            </>
        );
    }
    if (fieldName === "noMustProp") {
        return (
            <>
                <Col className={style.noMustProperty} md={15} xs={15}></Col>
                <Col className={style.noMustProperty} md={9} xs={9}></Col>
            </>
        );
    }
    return (
        <>
            <Col className={style.mustProperty} md={15} xs={15}>
                {mustPropertySubstring(mustProperty)}
            </Col>
            <Col className={style.mustPropertyValues} md={9} xs={9}>
                {defaultValue + " " + Unit}
            </Col>
        </>
    );
};

const isFieldExists = (cid: number | undefined, sid: number | undefined, paramName: string, prelogin?: boolean) => {
    const base = allMustFields.find((item) => {
        return cid?.toString() === item.id.toString();
    });

    if (base === undefined) return false;

    const subItem = base?.subCategories?.find((sub) => {
        return sub.id.toString() === sid?.toString();
    });

    if (subItem === undefined) return false;

    if (prelogin) return subItem.requestFields.includes(paramName);
    else return subItem.required.includes(paramName);
};

const isRequired = (cid: number | undefined, sid: number | undefined, paramName: string) => {
    const base = allMustFields.find((item) => {
        return cid?.toString() === item.id.toString();
    });

    if (base === undefined) return false;

    const subItem = base?.subCategories?.find((sub) => {
        return sub.id.toString() === sid?.toString();
    });

    if (subItem?.required?.includes(paramName)) return true;
    return false;
};
