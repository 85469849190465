/* tslint:disable */
/* eslint-disable */
/**
 * MachineGo P1 API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    RenterStatus,
    RenterStatusFromJSON,
    RenterStatusFromJSONTyped,
    RenterStatusToJSON,
} from './RenterStatus';

/**
 * 
 * @export
 * @interface RenterCompanySearchFilterModel
 */
export interface RenterCompanySearchFilterModel {
    /**
     * 
     * @type {number}
     * @memberof RenterCompanySearchFilterModel
     */
    id?: number | null;
    /**
     * 
     * @type {string}
     * @memberof RenterCompanySearchFilterModel
     */
    companyName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof RenterCompanySearchFilterModel
     */
    nameLastname?: string | null;
    /**
     * 
     * @type {string}
     * @memberof RenterCompanySearchFilterModel
     */
    phone?: string | null;
    /**
     * 
     * @type {RenterStatus}
     * @memberof RenterCompanySearchFilterModel
     */
    status?: RenterStatus;
    /**
     * 
     * @type {boolean}
     * @memberof RenterCompanySearchFilterModel
     */
    isDeleted?: boolean | null;
    /**
     * 
     * @type {Date}
     * @memberof RenterCompanySearchFilterModel
     */
    creationDate?: Date | null;
}

export function RenterCompanySearchFilterModelFromJSON(json: any): RenterCompanySearchFilterModel {
    return RenterCompanySearchFilterModelFromJSONTyped(json, false);
}

export function RenterCompanySearchFilterModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): RenterCompanySearchFilterModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'companyName': !exists(json, 'companyName') ? undefined : json['companyName'],
        'nameLastname': !exists(json, 'nameLastname') ? undefined : json['nameLastname'],
        'phone': !exists(json, 'phone') ? undefined : json['phone'],
        'status': !exists(json, 'status') ? undefined : RenterStatusFromJSON(json['status']),
        'isDeleted': !exists(json, 'isDeleted') ? undefined : json['isDeleted'],
        'creationDate': !exists(json, 'creationDate') ? undefined : (json['creationDate'] === null ? null : new Date(json['creationDate'])),
    };
}

export function RenterCompanySearchFilterModelToJSON(value?: RenterCompanySearchFilterModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'companyName': value.companyName,
        'nameLastname': value.nameLastname,
        'phone': value.phone,
        'status': RenterStatusToJSON(value.status),
        'isDeleted': value.isDeleted,
        'creationDate': value.creationDate === undefined ? undefined : (value.creationDate === null ? null : value.creationDate.toISOString()),
    };
}

