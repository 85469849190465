/* tslint:disable */
/* eslint-disable */
/**
 * MachineGo P1 API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    BasicModel,
    BasicModelFromJSON,
    BasicModelFromJSONTyped,
    BasicModelToJSON,
} from './BasicModel';

/**
 * 
 * @export
 * @interface SubCategoryModel
 */
export interface SubCategoryModel {
    /**
     * 
     * @type {number}
     * @memberof SubCategoryModel
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof SubCategoryModel
     */
    name?: string | null;
    /**
     * 
     * @type {BasicModel}
     * @memberof SubCategoryModel
     */
    category?: BasicModel;
}

export function SubCategoryModelFromJSON(json: any): SubCategoryModel {
    return SubCategoryModelFromJSONTyped(json, false);
}

export function SubCategoryModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): SubCategoryModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'category': !exists(json, 'category') ? undefined : BasicModelFromJSON(json['category']),
    };
}

export function SubCategoryModelToJSON(value?: SubCategoryModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
        'category': BasicModelToJSON(value.category),
    };
}

