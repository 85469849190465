/* tslint:disable */
/* eslint-disable */
/**
 * MachineGo P1 API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface RenterCompanyInfoUpdateModel
 */
export interface RenterCompanyInfoUpdateModel {
    /**
     * 
     * @type {string}
     * @memberof RenterCompanyInfoUpdateModel
     */
    companyName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof RenterCompanyInfoUpdateModel
     */
    address?: string | null;
    /**
     * 
     * @type {Array<number>}
     * @memberof RenterCompanyInfoUpdateModel
     */
    serviceRegions?: Array<number> | null;
}

export function RenterCompanyInfoUpdateModelFromJSON(json: any): RenterCompanyInfoUpdateModel {
    return RenterCompanyInfoUpdateModelFromJSONTyped(json, false);
}

export function RenterCompanyInfoUpdateModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): RenterCompanyInfoUpdateModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'companyName': !exists(json, 'companyName') ? undefined : json['companyName'],
        'address': !exists(json, 'address') ? undefined : json['address'],
        'serviceRegions': !exists(json, 'serviceRegions') ? undefined : json['serviceRegions'],
    };
}

export function RenterCompanyInfoUpdateModelToJSON(value?: RenterCompanyInfoUpdateModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'companyName': value.companyName,
        'address': value.address,
        'serviceRegions': value.serviceRegions,
    };
}

