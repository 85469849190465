/* tslint:disable */
/* eslint-disable */
/**
 * MachineGo P1 API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    LessorCompanyViewModel,
    LessorCompanyViewModelFromJSON,
    LessorCompanyViewModelFromJSONTyped,
    LessorCompanyViewModelToJSON,
} from './LessorCompanyViewModel';

/**
 * 
 * @export
 * @interface LessorCompanyViewModelPagedDataModel
 */
export interface LessorCompanyViewModelPagedDataModel {
    /**
     * 
     * @type {number}
     * @memberof LessorCompanyViewModelPagedDataModel
     */
    pageNo?: number;
    /**
     * 
     * @type {number}
     * @memberof LessorCompanyViewModelPagedDataModel
     */
    pageSize?: number;
    /**
     * 
     * @type {number}
     * @memberof LessorCompanyViewModelPagedDataModel
     */
    readonly rowIndex?: number;
    /**
     * 
     * @type {number}
     * @memberof LessorCompanyViewModelPagedDataModel
     */
    readonly pageCount?: number;
    /**
     * 
     * @type {number}
     * @memberof LessorCompanyViewModelPagedDataModel
     */
    itemCount?: number;
    /**
     * 
     * @type {Array<LessorCompanyViewModel>}
     * @memberof LessorCompanyViewModelPagedDataModel
     */
    data?: Array<LessorCompanyViewModel> | null;
}

export function LessorCompanyViewModelPagedDataModelFromJSON(json: any): LessorCompanyViewModelPagedDataModel {
    return LessorCompanyViewModelPagedDataModelFromJSONTyped(json, false);
}

export function LessorCompanyViewModelPagedDataModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): LessorCompanyViewModelPagedDataModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'pageNo': !exists(json, 'pageNo') ? undefined : json['pageNo'],
        'pageSize': !exists(json, 'pageSize') ? undefined : json['pageSize'],
        'rowIndex': !exists(json, 'rowIndex') ? undefined : json['rowIndex'],
        'pageCount': !exists(json, 'pageCount') ? undefined : json['pageCount'],
        'itemCount': !exists(json, 'itemCount') ? undefined : json['itemCount'],
        'data': !exists(json, 'data') ? undefined : (json['data'] === null ? null : (json['data'] as Array<any>).map(LessorCompanyViewModelFromJSON)),
    };
}

export function LessorCompanyViewModelPagedDataModelToJSON(value?: LessorCompanyViewModelPagedDataModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'pageNo': value.pageNo,
        'pageSize': value.pageSize,
        'itemCount': value.itemCount,
        'data': value.data === undefined ? undefined : (value.data === null ? null : (value.data as Array<any>).map(LessorCompanyViewModelToJSON)),
    };
}

