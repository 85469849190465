import { Col, InputNumber, Row } from "antd";
import { useAtom } from "jotai";
import { useEffect, useState } from "react";
import { BasicModel, CategoryModel, MachineSearchFilterModel, MachineSearchSortField } from "../../service/models";
import { loadingAtom } from "../../store/global-atoms";
import { t } from "../../translate";
import BoxContainer from "../containers/box-container";
//import styles from '../styles/filter-components.module.scss';
import { useSecureService } from "../../store/service-atoms";
import MgCharacterSelect from "./mg-character-select";
type MachineListFilterProps = {
    onChange?: (params: MachineListFilterViewModel) => void;
    children?: any;
};

type MachineListFilterViewModel = {
    searchFilterModel?: MachineSearchFilterModel;
    categoryName?: string | null;
    subCategoryName?: string | null;
    brandName?: string | null;
    sortBy: {
        field: MachineSearchSortField;
        sortAscending: boolean;
    };
};

const MachineSaleListFilter = (props: MachineListFilterProps) => {
    const [allCategories, setAllCategories] = useState<undefined | CategoryModel[]>(undefined);
    const [availableSubcategories, setAvailableSubcategories] = useState<undefined | null | BasicModel[]>(undefined);
    const [filterModel, setFilterModel] = useState<MachineListFilterViewModel>({
        searchFilterModel: {
            onlySaleMachines: true
        },
        sortBy: {
            field: MachineSearchSortField.CreationDate,
            sortAscending: false,
        },
    });
    const [loading] = useAtom(loadingAtom);
    const { doGetMachineSubcategory } = useSecureService();

    useEffect(() => {
        const getCategoriesAndSubcategories = async () => {
            const result = await doGetMachineSubcategory();
            setAllCategories(result);
        };
        getCategoriesAndSubcategories();
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        props.onChange && props.onChange(filterModel);
        // eslint-disable-next-line
    }, [filterModel]);

    const onMachineCategorySelected = async (selectedCategoryName?: string) => {
        const selectedCategory = allCategories?.find((cat) => cat.name === selectedCategoryName);
        setAvailableSubcategories(selectedCategory?.subCategories);
        setFilterModel((filt) => {
            return {
                ...filt,
                categoryName: selectedCategory?.name,
                subCategoryName: undefined,
                brandName: undefined,
                searchFilterModel: {
                    ...filt.searchFilterModel,
                    subCategoryId: undefined,
                    brandId: undefined,
                    categoryId: selectedCategory?.id,
                },
            };
        });
    };

    const onSubcategorySelected = async (selectedSubcategoryName?: string) => {
        let selectedSub: BasicModel | undefined;
        allCategories?.find((category) => {
            selectedSub = category.subCategories?.find((subCategory) => {
                return subCategory.name === selectedSubcategoryName;
            });
            return selectedSub !== undefined;
        });
        setFilterModel((filt) => {
            return {
                ...filt,
                subCategoryName: selectedSub?.name,
                searchFilterModel: {
                    ...filt.searchFilterModel,
                    subCategoryId: selectedSub?.id,
                },
            };
        });
    };

    const onStatusChanged = async (status?: string) => {
         setFilterModel((filt) => {
             return {
                 ...filt,
                 searchFilterModel: {
                     ...filt.searchFilterModel,
                     isMachineForSale: status === t("common.machine.status2"),
                     isApprovedForSale: status === t("common.machine.status1"),
                     isMachineRemovedFromSale: status === t("common.machine.status3"),
                 },
             };
         });
    }

    const machineIdChange = (val: string) => {
        setFilterModel((filter) => {
            return {
                ...filter,
                searchFilterModel: {
                    ...filter.searchFilterModel,
                    searchKeyword: val,
                },
            };
        });
    };

    return (
        <Row gutter={[8, 8]} style={{ marginBottom: "1rem" }}>
            <Col xs={24} md={24}>
                <BoxContainer shadowless marginless>
                    <Row gutter={[16,16]} justify="space-between" align="middle">
                        <Col xs={24} md={18}>
                            <Row gutter={[12, 12]} align="middle">
                                <Col>{t("common.filterBy")}</Col>
                                <Col xs={12} md={4}>
                                    <MgCharacterSelect 
                                        placeholder={t("machines.add.category")}
                                        value={filterModel.categoryName}
                                        loading={loading}
                                        onSelect={onMachineCategorySelected}
                                        onClear={onMachineCategorySelected}
                                        options={
                                            allCategories?.map((ac)=>({label:ac.name,value:ac.name }))
                                        }
                                    />
                                </Col>
                                <Col xs={12} md={4}>
                                    <MgCharacterSelect 
                                        placeholder={t("machines.add.subcategory")}
                                        value={filterModel?.subCategoryName}
                                        loading={loading}
                                        onSelect={onSubcategorySelected}
                                        onClear={onSubcategorySelected}
                                        options={
                                            availableSubcategories?.map((asc)=>({label:asc.name,value:asc.name}))
                                        }
                                    />
                                </Col>
                                <Col xs={12} md={6}>
                                    <MgCharacterSelect 
                                        placeholder={t("common.statusFilter")}
                                        loading={loading}
                                        onSelect={onStatusChanged}
                                        onClear={onStatusChanged}
                                        options={[
                                            {label:t("common.machine.status1"),value:t("common.machine.status1")},
                                            {label:t("common.machine.status2"),value:t("common.machine.status2")},
                                            {label:t("common.machine.status3"),value:t("common.machine.status3")}
                                        ]}
                                    />
                                </Col>
                            </Row>
                        </Col>
                        <Col xs={24} md={6}>
                            <InputNumber
                                type="number"
                                style={{ width: "100%" }}
                                placeholder={t("machines.add.machineIdLabel")}
                                value={filterModel.searchFilterModel?.machineId || undefined}
                                onKeyDown={(event) => {
                                    const re = /[+-]/;
                                    if (re.test(event.key)) {
                                      event.preventDefault();
                                    }
                                }}
                                onChange={(val) => {
                                    const pattern = /^\+?(0|[1-9]\d*)$/
                                    if(val === null || val === undefined || pattern.test(val.toString()) || val.toString() === '')
                                        machineIdChange(val ? val.toString() : "");
                                }}
                            />
                        </Col>
                    </Row>
                </BoxContainer>
            </Col>
        </Row>
    );
};

export default MachineSaleListFilter;
