/* tslint:disable */
/* eslint-disable */
/**
 * MachineGo P1 API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface LessorCompanyBasicInfoForMachineViewModel
 */
export interface LessorCompanyBasicInfoForMachineViewModel {
    /**
     * 
     * @type {string}
     * @memberof LessorCompanyBasicInfoForMachineViewModel
     */
    name?: string | null;
    /**
     * 
     * @type {string}
     * @memberof LessorCompanyBasicInfoForMachineViewModel
     */
    phone?: string | null;
}

export function LessorCompanyBasicInfoForMachineViewModelFromJSON(json: any): LessorCompanyBasicInfoForMachineViewModel {
    return LessorCompanyBasicInfoForMachineViewModelFromJSONTyped(json, false);
}

export function LessorCompanyBasicInfoForMachineViewModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): LessorCompanyBasicInfoForMachineViewModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': !exists(json, 'name') ? undefined : json['name'],
        'phone': !exists(json, 'phone') ? undefined : json['phone'],
    };
}

export function LessorCompanyBasicInfoForMachineViewModelToJSON(value?: LessorCompanyBasicInfoForMachineViewModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'phone': value.phone,
    };
}

