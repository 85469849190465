import styles from "../styles/tab-button.module.scss";
import {ReactComponent as RadioUnchecked} from "./../assets/radioUncheckedIcon.svg"
import {ReactComponent as RadioChecked} from "./../assets/radioCheckedIcon.svg"
import { MouseEventHandler } from "react";

export type TabButtonProps = {
    icon: any;
    label: string;
    active?: boolean;
    onClick?: MouseEventHandler<HTMLDivElement>;
};

const TabButton = (props: TabButtonProps) => {
    const cursorString = !props.active ? "pointer" : "default";
    const activeStyle = props.active ? styles.activeTabButton : undefined;

    return (
        <div
            className={`${styles.tabButton} ${activeStyle}`}
            onClick={(e) => {
                if (!!!props.active && props.onClick) props.onClick(e);
            }}
            style={{ cursor: cursorString }}
        >
            <div className={styles.icon}>
                {props.icon}
            </div>
            <div className={styles.labelContainer}>
                <span className={styles.label} style={{ width: "100%" }}>{props.label}</span>
                {
                    props.active ? 
                        <RadioChecked />
                        :
                        <RadioUnchecked />
                }
            </div>
        </div>
    );
};

export default TabButton;
