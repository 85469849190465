/* tslint:disable */
/* eslint-disable */
/**
 * MachineGo P1 API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    BasicModel,
    BasicModelFromJSON,
    BasicModelFromJSONTyped,
    BasicModelToJSON,
} from './BasicModel';

/**
 * 
 * @export
 * @interface RenterCompanyInfoModel
 */
export interface RenterCompanyInfoModel {
    /**
     * 
     * @type {number}
     * @memberof RenterCompanyInfoModel
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof RenterCompanyInfoModel
     */
    companyName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof RenterCompanyInfoModel
     */
    phone?: string | null;
    /**
     * 
     * @type {string}
     * @memberof RenterCompanyInfoModel
     */
    address?: string | null;
    /**
     * 
     * @type {Array<BasicModel>}
     * @memberof RenterCompanyInfoModel
     */
    serviceRegions?: Array<BasicModel> | null;
}

export function RenterCompanyInfoModelFromJSON(json: any): RenterCompanyInfoModel {
    return RenterCompanyInfoModelFromJSONTyped(json, false);
}

export function RenterCompanyInfoModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): RenterCompanyInfoModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'companyName': !exists(json, 'companyName') ? undefined : json['companyName'],
        'phone': !exists(json, 'phone') ? undefined : json['phone'],
        'address': !exists(json, 'address') ? undefined : json['address'],
        'serviceRegions': !exists(json, 'serviceRegions') ? undefined : (json['serviceRegions'] === null ? null : (json['serviceRegions'] as Array<any>).map(BasicModelFromJSON)),
    };
}

export function RenterCompanyInfoModelToJSON(value?: RenterCompanyInfoModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'companyName': value.companyName,
        'phone': value.phone,
        'address': value.address,
        'serviceRegions': value.serviceRegions === undefined ? undefined : (value.serviceRegions === null ? null : (value.serviceRegions as Array<any>).map(BasicModelToJSON)),
    };
}

