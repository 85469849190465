/* tslint:disable */
/* eslint-disable */
/**
 * MachineGo P1 API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface RenterCompanyProfileCreateModel
 */
export interface RenterCompanyProfileCreateModel {
    /**
     * 
     * @type {string}
     * @memberof RenterCompanyProfileCreateModel
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof RenterCompanyProfileCreateModel
     */
    lastname: string;
    /**
     * 
     * @type {string}
     * @memberof RenterCompanyProfileCreateModel
     */
    email?: string | null;
    /**
     * 
     * @type {string}
     * @memberof RenterCompanyProfileCreateModel
     */
    title?: string | null;
}

export function RenterCompanyProfileCreateModelFromJSON(json: any): RenterCompanyProfileCreateModel {
    return RenterCompanyProfileCreateModelFromJSONTyped(json, false);
}

export function RenterCompanyProfileCreateModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): RenterCompanyProfileCreateModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': json['name'],
        'lastname': json['lastname'],
        'email': !exists(json, 'email') ? undefined : json['email'],
        'title': !exists(json, 'title') ? undefined : json['title'],
    };
}

export function RenterCompanyProfileCreateModelToJSON(value?: RenterCompanyProfileCreateModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'lastname': value.lastname,
        'email': value.email,
        'title': value.title,
    };
}

