type SelectedItemTextProps = {
    children?: any;
    style?: React.CSSProperties | undefined;
};
const SelectedItemText = (props: SelectedItemTextProps) => {
    return (
        <div
            style={{
                margin: "0rem",
                padding: ".5rem",
                background: "#EEF0F2",
                borderRadius: "0.5rem",
                ...props.style,
            }}
        >
            {props.children}
        </div>
    );
};

export default SelectedItemText;
