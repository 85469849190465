import SecureLayout from "../../../components/layouts/secure-layout";
import { t } from "../../../translate";
import SelectMachine from "../../request/select-machine";

const EditRequest = () => {
    return (
        <SecureLayout
            onlyHeader
            activePage={"requests-and-offers"}
            role={"customer"}
            title={t("requests-and-offers.add.title")}
            description={t("requests-and-offers.add.desceription")}
        >
            <SelectMachine secure />
        </SecureLayout>
    );
};

export default EditRequest;
