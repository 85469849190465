/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from "react";
import style from "./machine-detail.module.scss";
import { Col, Row, Spin, Tooltip } from "antd";
import { ReactComponent as Info } from "../../../components/assets/info.svg";
import { useState } from "react";
import Carousel from "react-multi-carousel";
import SecureLayout from "../../../components/layouts/secure-layout";
import { useSecureService } from "../../../store/service-atoms";
import PrimaryButton from "../../../components/atomics/primary-button";
import { t } from "../../../translate";
import { MachineDetailModel, MachineDocumentViewModel } from "../../../service";
import InfoBox from "../../../components/atomics/info-box";
import { ReactComponent as OperatorIcon } from "../../../components/assets/operator.svg";
import { activeApiBaseUrl } from "../../../store/app-constants";
import CheckboxReviewer from "../../../components/atomics/checkbox-reviewer";
import CustomArrows from "../../../components/atomics/custom-arrows";
import { useQuery } from "../../../helpers/use-query";
import BoxContainer from "../../../components/containers/box-container";
import placeholder from "../../../components/assets/placeholder.svg";
import { formatCurrency } from "../../../helpers/formatters";
import { useNavigate } from "react-router-dom";

const RentalMachineDetail = () => {
    const [machineAllDetail, setMachineAllDetail] = useState<MachineDetailModel>();
    const query = useQuery();
    const macid = query.get("macid");
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);
    const [documents, setDocuments] = useState<MachineDocumentViewModel[] | undefined>([]);
    const [disableRequestButton, setDisableRequestButton] = useState(true);
    const [modifiedDate, setModifiedDate] = useState<Date>();
    const { doGetMachine } = useSecureService();
    const navigate = useNavigate();

    useEffect(() => {
        if (machineAllDetail) {
            setDisableRequestButton(false);
        }
    }, [machineAllDetail]);

    useEffect(() => {
        const getMachineDetails = async () => {
            if (macid) {
                const macidN = parseInt(macid);
                if (!Number.isNaN(macidN)) {
                    const result = await doGetMachine(macidN, true);
                    if (result) {
                        setMachineAllDetail(result);
                        setModifiedDate(result?.modifiedDate);
                    } else {
                        setLoading(false);
                        setError(true);
                    }
                }
            }
        };

        getMachineDetails();
    }, [macid]);
    useEffect(() => {
        if (machineAllDetail?.documents && machineAllDetail.documents.length > 0) {
            const documents = machineAllDetail?.documents?.filter((x) => x.documentCategory !== "Photo");
            setDocuments(documents);
        }
    }, [machineAllDetail]);
    const [mainImageId, setMainImageId] = useState<number>();
    const [mainImageUrl, setMainImageUrl] = useState<string>(placeholder);
    const machineImages = machineAllDetail?.documents?.filter((x) => x.documentCategory === "Photo");
    useEffect(() => {
        setMainImageUrl(activeApiBaseUrl + "/document/machine-document/" + mainImageId);
    }, [mainImageId]);

    useEffect(() => {
        if (machineAllDetail?.documents && machineAllDetail.documents.length > 0) {
            if ( machineImages && machineImages.length > 0) {
                const mainImageId = machineImages && machineImages[0].document?.id;
                setMainImageId(mainImageId);
            }
        }
        if (machineAllDetail) {
            setLoading(false);
        }
    }, [machineAllDetail]);
    useEffect(() => {
        if (mainImageId === undefined) {
            setMainImageUrl(placeholder);
        }
    }, [mainImageId]);

    let formattedModifiedDate: string | undefined = "";
    if (!disableRequestButton) {
        formattedModifiedDate = modifiedDate?.toLocaleDateString("tr-TR"); // tarih nesnesine dönüştür
    }
    return (
        <SecureLayout activePage={"rental-machine-detail"} role={"lessor"}>
            <Row hidden={!loading} style={{ flex: 1, justifyContent: "center", alignItems: "center" }}>
                <Col sm={24}>
                    <Spin size="large" tip="Lütfen Bekleyiniz" className={style.spinMobile} />
                </Col>
            </Row>
            <Row hidden={loading || !error} style={{ display: "flex", height: "100%" }}>
                <Col sm={24} style={{ display: "flex", textAlign: "center", alignItems: "center", justifyContent: "center" }}>
                    <span style={{ verticalAlign: "center", fontWeight: "bold" }}>{t("machine-detail.machine-not-found")}</span>
                </Col>
            </Row>
            <Row gutter={[24, 24]} className={style.machineDetail} hidden={loading || error}>

                <Row className={style.machineHeader} justify={"space-between"}>
                    <Col> {t("machine-detail.info")}</Col>
                    <Col className={style.kdv}>
                        <Col style={{ padding: 2 }}>
                            <Info />
                        </Col>
                        <Col style={{ padding: 2 }}>{t("machine-detail.tax.detailed.info")}</Col>
                    </Col>
                </Row>

                <Col style={{ marginBottom: "1rem" }}>
                    <Row gutter={[24, 24]}>
                        <Col md={16}>
                            <Col
                                style={{
                                    height: "50vh",
                                    textAlign: "center",
                                    backgroundColor: "#fff",
                                    width: "100%",
                                    borderRadius: "10px",
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                }}
                                md={24}
                            >
                                {machineImages && machineImages?.length && machineImages?.length > 0 ? (
                                    <img
                                        style={{
                                            objectFit: "contain",
                                            maxWidth: "100%",
                                            maxHeight: "100%",
                                            width: "auto",
                                            height: "auto",
                                        }}
                                        src={mainImageUrl}
                                        alt={""}
                                    ></img>
                                ) : (
                                    <img
                                        className={style.placeholderImage}
                                        src={placeholder}
                                        alt={""}
                                    ></img>
                                )}
                            </Col>
                            <Col md={24} style={{ paddingTop: "0.75rem" }}>
                                <Carousel
                                    additionalTransfrom={0}
                                    arrows
                                    showDots={false}
                                    autoPlaySpeed={3000}
                                    centerMode={false}
                                    className=""
                                    containerClass={style.containerWithDts}
                                    dotListClass={style.showcaseDots}
                                    draggable
                                    focusOnSelect={false}
                                    infinite={false}
                                    itemClass=""
                                    keyBoardControl
                                    minimumTouchDrag={80}
                                    pauseOnHover
                                    customLeftArrow={<CustomArrows direction="left" />}
                                    customRightArrow={<CustomArrows direction="right" />}
                                    renderArrowsWhenDisabled={false}
                                    renderButtonGroupOutside={false}
                                    renderDotsOutside
                                    responsive={{
                                        desktop: {
                                            breakpoint: {
                                                max: 3000,
                                                min: 1024,
                                            },
                                            items: 6,
                                            partialVisibilityGutter: 40,
                                        },
                                        mobile: {
                                            breakpoint: {
                                                max: 464,
                                                min: 0,
                                            },
                                            items: 6,
                                            partialVisibilityGutter: 30,
                                        },
                                        tablet: {
                                            breakpoint: {
                                                max: 1024,
                                                min: 464,
                                            },
                                            items: 2,
                                            partialVisibilityGutter: 30,
                                        },
                                    }}
                                    rewind={false}
                                    rewindWithAnimation={false}
                                    rtl={false}
                                    shouldResetAutoplay
                                    sliderClass=""
                                    slidesToSlide={1}
                                    afterChange={undefined}
                                    beforeChange={undefined}
                                    swipeable
                                >
                                    <Row gutter={4}>
                                        {machineImages &&
                                            machineImages?.map((x) => (
                                                <Col
                                                    className={style.imgContainer}
                                                    md={3}
                                                    key={x.document?.id}
                                                    onClick={() =>
                                                        setMainImageUrl(activeApiBaseUrl + "/document/machine-document/" + x.document?.id)
                                                    }
                                                >
                                                    <img
                                                        className={style.carouselImg}
                                                        src={activeApiBaseUrl + "/document/machine-document/" + x.document?.id}
                                                        alt={"machine-document"}
                                                    />
                                                </Col>
                                            ))}
                                    </Row>
                                </Carousel>
                            </Col>
                        </Col>
                        <Col md={8}>
                            <BoxContainer bodyStyle={{ height: "100%" }}>
                                <Row
                                    align={"stretch"}
                                    justify="space-between"
                                    style={{ height: "100%" }}
                                    gutter={[8, 8]}
                                    className={style.machineDetail}
                                >
                                    <Col>
                                        <Row className={style.machineDetail}>
                                            <Col className={style.detailHead}>
                                                {machineAllDetail?.subCategory?.category?.name +
                                                    " > " +
                                                    machineAllDetail?.subCategory?.name}
                                            </Col>
                                            <Col className={style.location}>
                                                {machineAllDetail?.serviceRegions &&
                                                    machineAllDetail.serviceRegions.map((x, index) => (
                                                        <span key={index}>
                                                            {x.name}
                                                            {index !== machineAllDetail.serviceRegions!.length - 1 && ","}{" "}
                                                        </span>
                                                    ))}
                                            </Col>
                                        </Row>
                                    </Col>

                                    <div className={style.hr}></div>
                                    <Col className={style.period}>
                                        {t("machine-detail.adTitle-header")}{": "}
                                        <strong style={{ fontWeight: 800, color: "black" }}>
                                            {machineAllDetail?.rentalAdTitle ?? ""}
                                        </strong>
                                    </Col>
                                    <div className={style.hr}></div>
                                    <Col className={style.period}>
                                        {t("machine-detail.modifiedDate")}{" "}
                                        <strong style={{ fontWeight: 800, color: "black" }}>
                                            {formattedModifiedDate ? formattedModifiedDate : ""}
                                        </strong>
                                    </Col>
                                    <Col className={style.attachmentHeader}>{t("machine-detail.pricing")}</Col>
                                    <Col>
                                        <Row>
                                            {machineAllDetail?.rentalTypes?.map((item) => {
                                                return (
                                                    <>
                                                        <Col
                                                            style={{ display: "flex", alignItems: "center" }}
                                                            key={item.rentalType?.id}
                                                            className={style.period}
                                                            md={12}
                                                            xs={12}
                                                        >
                                                            {item.rentalType?.name}
                                                            {machineAllDetail.hasOperator && (
                                                                <Tooltip placement="top" title={"Operatör Dahil"}>
                                                                    <OperatorIcon />
                                                                </Tooltip>
                                                            )}
                                                        </Col>
                                                        <Col key={item.rentalType?.id + "cr"} className={style.right} md={12} xs={12}>
                                                            {formatCurrency(item.price, item.currency?.id ?? 1)}
                                                        </Col>
                                                    </>
                                                );
                                            })}
                                        </Row>
                                    </Col>
                                    <div className={style.hr}></div>
                                    <Col>
                                        <Row gutter={[16, 16]}>
                                            <Col md={8} xs={8} className={style.period}>
                                                <InfoBox
                                                    head={t("machine-detail.machine-id")}
                                                    text={machineAllDetail?.id ? machineAllDetail?.id.toString() : "-"}
                                                />
                                            </Col>
                                            <Col md={8} xs={8} className={style.period}>
                                                <InfoBox
                                                    head={t("machine-detail.model")}
                                                    text={machineAllDetail?.modelName ? machineAllDetail?.modelName : "-"}
                                                />
                                            </Col>
                                            {machineAllDetail?.operatingWeight && (
                                                <Col md={8} xs={8} className={style.period}>
                                                    <InfoBox
                                                        head={t("machine-detail.working-weight")}
                                                        text={machineAllDetail?.operatingWeight.toString() + "(ton)"}
                                                    />
                                                </Col>
                                            )}
                                            <Col md={8} xs={8} className={style.period}>
                                                <InfoBox
                                                    head={t("machine-detail.brand")}
                                                    text={
                                                        machineAllDetail?.brandOther
                                                            ? machineAllDetail?.brandOther ?? ""
                                                            : machineAllDetail?.brand?.name ?? ""
                                                    }
                                                />
                                            </Col>
                                            <Col md={8} xs={8} className={style.period}>
                                                <InfoBox
                                                    head={t("machine-production-year")}
                                                    text={
                                                        machineAllDetail?.productionYear ? machineAllDetail?.productionYear.toString() : "-"
                                                    }
                                                />
                                            </Col>
                                        </Row>
                                    </Col>
                                    {/* <Col>
                                        <Row gutter={[8, 8]} align="middle">
                                            <Col style={{ display: "flex", margin: "1rem 0" }}>
                                                <Info />
                                            </Col>
                                            <Col className={style.kdv}>{t("machine-detail.tax.detailed.info")}</Col>
                                        </Row>
                                    </Col> */}
                                    <Col>
                                        <PrimaryButton
                                            onClick={() => navigate(`/requests-and-offers/add?macid=` + machineAllDetail?.id)}
                                            style={{ width: "100%" }}
                                            disabled={disableRequestButton}
                                        >
                                            {t("machine-detail.create-request")}
                                        </PrimaryButton>
                                    </Col>
                                </Row>
                            </BoxContainer>
                        </Col>
                    </Row>
                </Col>
                <BoxContainer>
                    <Col className={style.machineHeader}>{t("machine-detail.specs")}</Col>
                    <Col>
                        <Row gutter={[16, 16]}>
                            <Col md={4} xs={8} className={style.period}>
                                <InfoBox head={t("machine-detail.machine-id")} text={machineAllDetail?.id?.toString() || "-"} />
                            </Col>
                            <Col md={4} xs={8} className={style.period}>
                                <InfoBox head={t("machines.category")} text={machineAllDetail?.subCategory?.category?.name || "-"} />
                            </Col>
                            <Col md={4} xs={8} className={style.period}>
                                <InfoBox head={t("machines.subcategory")} text={machineAllDetail?.subCategory?.name || "-"} />
                            </Col>
                            {machineAllDetail?.brandOther ? (
                                <Col md={4} xs={8} className={style.period}>
                                    <InfoBox head={t("machine-detail.brand")} text={machineAllDetail?.brandOther || "-"} />
                                </Col>
                            ) : (
                                <Col md={4} xs={8} className={style.period}>
                                    <InfoBox head={t("machine-detail.brand")} text={machineAllDetail?.brand?.name || "-"} />
                                </Col>
                            )}
                            <Col md={4} xs={8} className={style.period}>
                                <InfoBox head={t("machine-detail.model")} text={machineAllDetail?.modelName || "-"} />
                            </Col>
                            <Col md={4} xs={8} className={style.period}>
                                <InfoBox head={t("machine-production-year")} text={machineAllDetail?.productionYear?.toString() || "-"} />
                            </Col>
                            {machineAllDetail?.bladeWidth && (
                                <Col md={4} xs={8} className={style.period}>
                                    <InfoBox
                                        head={t("machines.additional-fields.bladeWidth")}
                                        text={
                                            machineAllDetail?.bladeWidth.toString() + " " + t("machines.additional-fields.bladeWidthSuffix")
                                        }
                                    />
                                </Col>
                            )}
                            {machineAllDetail?.bomLength && (
                                <Col md={4} xs={8} className={style.period}>
                                    <InfoBox
                                        head={t("machines.additional-fields.bomLength")}
                                        text={machineAllDetail?.bomLength.toString()}
                                    />
                                </Col>
                            )}
                            {machineAllDetail?.breakingInfo && (
                                <Col md={4} xs={8} className={style.period}>
                                    <InfoBox
                                        head={t("machines.additional-fields.breakingInfo")}
                                        text={machineAllDetail?.breakingInfo.toString()}
                                    />
                                </Col>
                            )}
                            {machineAllDetail?.cameraResolution?.name && (
                                <Col md={4} xs={8} className={style.period}>
                                    <InfoBox
                                        head={t("machines.additional-fields.cameraResolution")}
                                        text={machineAllDetail?.cameraResolution?.name?.toString()}
                                    />
                                </Col>
                            )}
                            {machineAllDetail?.carryingCapacity && (
                                <Col md={4} xs={8} className={style.period}>
                                    <InfoBox
                                        head={t("machines.additional-fields.carryingCapacity")}
                                        text={
                                            machineAllDetail?.carryingCapacity.toString() +
                                            " " +
                                            t("machines.additional-fields.carryingCapacitySuffix")
                                        }
                                    />
                                </Col>
                            )}
                            {machineAllDetail?.cleaningCapacity && (
                                <Col md={4} xs={8} className={style.period}>
                                    <InfoBox
                                        head={t("machines.additional-fields.cleaningCapacity")}
                                        text={
                                            machineAllDetail?.cleaningCapacity.toString() +
                                            " " +
                                            t("machines.additional-fields.cleaningSuffix")
                                        }
                                    />
                                </Col>
                            )}
                            {machineAllDetail?.compressionWidth && (
                                <Col md={4} xs={8} className={style.period}>
                                    <InfoBox
                                        head={t("machines.additional-fields.compressionWidth")}
                                        text={machineAllDetail?.compressionWidth.toString()}
                                    />
                                </Col>
                            )}
                            {machineAllDetail?.concretePumpingCapacity && (
                                <Col md={4} xs={8} className={style.period}>
                                    <InfoBox
                                        head={t("machines.additional-fields.concretePumpingCapacity")}
                                        text={
                                            machineAllDetail?.concretePumpingCapacity.toString() +
                                            " " +
                                            t("machines.additional-fields.concreteSuffix")
                                        }
                                    />
                                </Col>
                            )}
                            {machineAllDetail?.drivingEquipment && (
                                <Col md={4} xs={8} className={style.period}>
                                    <InfoBox
                                        head={t("machines.additional-fields.drivingEquipment")}
                                        text={machineAllDetail?.drivingEquipment}
                                    />
                                </Col>
                            )}
                            {machineAllDetail?.elevatorType?.name && (
                                <Col md={4} xs={8} className={style.period}>
                                    <InfoBox
                                        head={t("machines.additional-fields.elevatorTypeId")}
                                        text={machineAllDetail?.elevatorType.name}
                                    />
                                </Col>
                            )}
                            {machineAllDetail?.engineBrand && (
                                <Col md={4} xs={8} className={style.period}>
                                    <InfoBox head={t("machines.additional-fields.engineBrand")} text={machineAllDetail?.engineBrand} />
                                </Col>
                            )}
                            {machineAllDetail?.enginePower && (
                                <Col md={4} xs={8} className={style.period}>
                                    <InfoBox
                                        head={t("machines.additional-fields.enginePower")}
                                        text={machineAllDetail?.enginePower.toString()}
                                    />
                                </Col>
                            )}
                            {machineAllDetail?.flightDistance && (
                                <Col md={4} xs={8} className={style.period}>
                                    <InfoBox
                                        head={t("machines.additional-fields.flightDistance")}
                                        text={
                                            machineAllDetail?.flightDistance.toString() +
                                            " " +
                                            t("machines.additional-fields.flightDistanceSuffix")
                                        }
                                    />
                                </Col>
                            )}
                            {machineAllDetail?.flightTime && (
                                <Col md={4} xs={8} className={style.period}>
                                    <InfoBox
                                        head={t("machines.additional-fields.flightTime")}
                                        text={machineAllDetail?.flightTime.toString() + " " + t("machines.additional-fields.flightSuffix")}
                                    />
                                </Col>
                            )}
                            {machineAllDetail?.floorCount && (
                                <Col md={4} xs={8} className={style.period}>
                                    <InfoBox
                                        head={t("machines.additional-fields.floorCount")}
                                        text={machineAllDetail?.floorCount.toString()}
                                    />
                                </Col>
                            )}
                            {machineAllDetail?.freeAirOutput && (
                                <Col md={4} xs={8} className={style.period}>
                                    <InfoBox
                                        head={t("machines.additional-fields.freeAirOutput")}
                                        text={
                                            machineAllDetail?.freeAirOutput.toString() + " " + t("machines.additional-fields.freeAirSuffix")
                                        }
                                    />
                                </Col>
                            )}
                            {machineAllDetail?.frequency && (
                                <Col md={4} xs={8} className={style.period}>
                                    <InfoBox
                                        head={t("machines.additional-fields.frequency")}
                                        text={
                                            machineAllDetail?.frequency.toString() + " " + t("machines.additional-fields.frequencySuffix")
                                        }
                                    />
                                </Col>
                            )}
                            {machineAllDetail?.fuelType?.name && (
                                <Col md={4} xs={8} className={style.period}>
                                    <InfoBox head={t("machines.additional-fields.fuelTypeId")} text={machineAllDetail?.fuelType.name} />
                                </Col>
                            )}
                            {machineAllDetail?.garbageCollectionBin && (
                                <Col md={4} xs={8} className={style.period}>
                                    <InfoBox
                                        head={t("machines.additional-fields.garbageCollectionBin")}
                                        text={machineAllDetail?.garbageCollectionBin.toString()}
                                    />
                                </Col>
                            )}
                            {machineAllDetail?.hasCabin && (
                                <Col md={4} xs={8} className={style.period}>
                                    <InfoBox head={t("machines.additional-fields.hasCabin")} text="Mevcut" />
                                </Col>
                            )}
                            {machineAllDetail?.hasPlatform && (
                                <Col md={4} xs={8} className={style.period}>
                                    <InfoBox head={t("machines.additional-fields.hasPlatform")} text="Mevcut" />
                                </Col>
                            )}
                            {machineAllDetail?.hasTowerElevationSystem && (
                                <Col md={4} xs={8} className={style.period}>
                                    <InfoBox head={t("machines.additional-fields.hasTowerElevationSystem")} text="Mevcut" />
                                </Col>
                            )}
                            {machineAllDetail?.height && (
                                <Col md={4} xs={8} className={style.period}>
                                    <InfoBox
                                        head={t("machines.additional-fields.height")}
                                        text={machineAllDetail?.height.toString() + " " + t("machines.additional-fields.heightSuffix")}
                                    />
                                </Col>
                            )}
                            {machineAllDetail?.lightningCapacity && (
                                <Col md={4} xs={8} className={style.period}>
                                    <InfoBox
                                        head={t("machines.additional-fields.lightningCapacity")}
                                        text={
                                            machineAllDetail?.lightningCapacity.toString() +
                                            " " +
                                            t("machines.additional-fields.lightningCapacitySuffix")
                                        }
                                    />
                                </Col>
                            )}
                            {machineAllDetail?.loadCapacity && (
                                <Col md={4} xs={8} className={style.period}>
                                    <InfoBox
                                        head={t("machines.additional-fields.loadCapacity")}
                                        text={machineAllDetail?.loadCapacity.toString() + " " + t("machines.additional-fields.loadSuffix")}
                                    />
                                </Col>
                            )}
                            {machineAllDetail?.maxDepth && (
                                <Col md={4} xs={8} className={style.period}>
                                    <InfoBox head={t("machines.additional-fields.maxDepth")} text={machineAllDetail?.maxDepth.toString()} />
                                </Col>
                            )}
                            {machineAllDetail?.maxFeedingSize && (
                                <Col md={4} xs={8} className={style.period}>
                                    <InfoBox
                                        head={t("machines.additional-fields.maxFeedingSize")}
                                        text={
                                            machineAllDetail?.maxFeedingSize.toString() +
                                            " " +
                                            t("machines.additional-fields.maxFeedingSizeSuffix")
                                        }
                                    />
                                </Col>
                            )}
                            {machineAllDetail?.maxHeight && (
                                <Col md={4} xs={8} className={style.period}>
                                    <InfoBox
                                        head={t("machines.additional-fields.maxHeight")}
                                        text={machineAllDetail?.maxHeight.toString() + " " + t("machines.additional-fields.heightSuffix")}
                                    />
                                </Col>
                            )}
                            {machineAllDetail?.maxLoadCapacity && (
                                <Col md={4} xs={8} className={style.period}>
                                    <InfoBox
                                        head={t("machines.additional-fields.maxLoadCapacity")}
                                        text={
                                            machineAllDetail?.maxLoadCapacity.toString() +
                                            " " +
                                            ([10].includes(machineAllDetail.subCategory?.category?.id ?? -1) ? t('common.kg') : t("common.ton"))
                                        }
                                    />
                                </Col>
                            )}
                            {machineAllDetail?.maxPower && (
                                <Col md={4} xs={8} className={style.period}>
                                    <InfoBox
                                        head={t("machines.additional-fields.maxPower")}
                                        text={machineAllDetail?.maxPower.toString() + " " + t("machines.additional-fields.powerSuffix")}
                                    />
                                </Col>
                            )}
                            {machineAllDetail?.maxPressure && (
                                <Col md={4} xs={8} className={style.period}>
                                    <InfoBox
                                        head={t("machines.additional-fields.maxPressure")}
                                        text={
                                            machineAllDetail?.maxPressure.toString() + " " + t("machines.additional-fields.pressureSuffix")
                                        }
                                    />
                                </Col>
                            )}
                            {machineAllDetail?.maxWidth && (
                                <Col md={4} xs={8} className={style.period}>
                                    <InfoBox
                                        head={t("machines.additional-fields.maxWidth")}
                                        text={machineAllDetail?.maxWidth.toString() + " " + t("machines.additional-fields.widthSuffix")}
                                    />
                                </Col>
                            )}
                            {machineAllDetail?.minPower && (
                                <Col md={4} xs={8} className={style.period}>
                                    <InfoBox
                                        head={t("machines.additional-fields.minPower")}
                                        text={machineAllDetail?.minPower.toString() + " " + t("machines.additional-fields.powerSuffix")}
                                    />
                                </Col>
                            )}
                            {machineAllDetail?.nominalCapacity && (
                                <Col md={4} xs={8} className={style.period}>
                                    <InfoBox
                                        head={t("machines.additional-fields.nominalCapacity")}
                                        text={
                                            machineAllDetail?.nominalCapacity.toString() +
                                            " " +
                                            t("machines.additional-fields.capacitySuffix")
                                        }
                                    />
                                </Col>
                            )}
                            {machineAllDetail?.operatingCapacity && (
                                <Col md={4} xs={8} className={style.period}>
                                    <InfoBox
                                        head={t("machines.additional-fields.operatingCapacity")}
                                        text={
                                            machineAllDetail?.operatingCapacity.toString() +
                                            " " +
                                            t("machines.additional-fields.capacitySuffix")
                                        }
                                    />
                                </Col>
                            )}
                            {machineAllDetail?.operatingHeight && (
                                <Col md={4} xs={8} className={style.period}>
                                    <InfoBox
                                        head={t("machines.additional-fields.operatingHeight")}
                                        text={
                                            machineAllDetail?.operatingHeight.toString() +
                                            " " +
                                            t("machines.additional-fields.heightSuffix")
                                        }
                                    />
                                </Col>
                            )}
                            {machineAllDetail?.operatingLength && (
                                <Col md={4} xs={8} className={style.period}>
                                    <InfoBox
                                        head={t("machines.additional-fields.operatingLength")}
                                        text={
                                            machineAllDetail?.operatingLength.toString() +
                                            " " +
                                            t("machines.additional-fields.operationLengthSuffix")
                                        }
                                    />
                                </Col>
                            )}
                            {machineAllDetail?.operatingWeight && (
                                <Col md={4} xs={8} className={style.period}>
                                    <InfoBox
                                        head={t("machines.additional-fields.operatingWeight")}
                                        text={machineAllDetail?.operatingWeight.toString()}
                                    />
                                </Col>
                            )}
                            {machineAllDetail?.operationHour && (
                                <Col md={4} xs={8} className={style.period}>
                                    <InfoBox
                                        head={t("machines.additional-fields.operationHour")}
                                        text={machineAllDetail?.operationHour.toString()}
                                    />
                                </Col>
                            )}
                            {machineAllDetail?.operationMileage && (
                                <Col md={4} xs={8} className={style.period}>
                                    <InfoBox
                                        head={t("machines.additional-fields.operationMileage")}
                                        text={
                                            machineAllDetail?.operationMileage.toString() +
                                            " " +
                                            t("machines.additional-fields.mileageSuffix")
                                        }
                                    />
                                </Col>
                            )}
                            {machineAllDetail?.operationWidth && (
                                <Col md={4} xs={8} className={style.period}>
                                    <InfoBox
                                        head={t("machines.additional-fields.operationWidth")}
                                        text={
                                            machineAllDetail?.operationWidth.toString() + " " + t("machines.additional-fields.widthSuffix")
                                        }
                                    />
                                </Col>
                            )}
                            {machineAllDetail?.platformLength && (
                                <Col md={4} xs={8} className={style.period}>
                                    <InfoBox
                                        head={t("machines.additional-fields.platformLength")}
                                        text={
                                            machineAllDetail?.platformLength.toString() +
                                            " " +
                                            t("machines.additional-fields.operationLengthSuffix")
                                        }
                                    />
                                </Col>
                            )}
                            {machineAllDetail?.platformWidth && (
                                <Col md={4} xs={8} className={style.period}>
                                    <InfoBox
                                        head={t("machines.additional-fields.platformWidth")}
                                        text={
                                            machineAllDetail?.platformWidth.toString() + " " + t("machines.additional-fields.widthSuffix")
                                        }
                                    />
                                </Col>
                            )}
                            {machineAllDetail?.spraySpeed && (
                                <Col md={4} xs={8} className={style.period}>
                                    <InfoBox
                                        head={t("machines.additional-fields.spraySpeed")}
                                        text={machineAllDetail?.spraySpeed.toString() + " " + t("machines.additional-fields.spraySuffix")}
                                    />
                                </Col>
                            )}
                            {machineAllDetail?.tankCapacity && (
                                <Col md={4} xs={8} className={style.period}>
                                    <InfoBox
                                        head={t("machines.additional-fields.tankCapacity")}
                                        text={
                                            machineAllDetail?.tankCapacity.toString() +
                                            " " +
                                            t("machines.additional-fields.tankCapacitySuffix")
                                        }
                                    />
                                </Col>
                            )}
                            {machineAllDetail?.tankVolume && (
                                <Col md={4} xs={8} className={style.period}>
                                    <InfoBox
                                        head={t("machines.additional-fields.tankVolume")}
                                        text={machineAllDetail?.tankVolume.toString() + " " + t("machines.additional-fields.volumeSuffix")}
                                    />
                                </Col>
                            )}
                            {machineAllDetail?.usageFields && (
                                <Col md={4} xs={8} className={style.period}>
                                    <InfoBox
                                        head={t("machines.additional-fields.usageFields")}
                                        text={machineAllDetail?.usageFields.toString()}
                                    />
                                </Col>
                            )}
                            {machineAllDetail?.volume && (
                                <Col md={4} xs={8} className={style.period}>
                                    <InfoBox
                                        head={t("machines.additional-fields.volume")}
                                        text={machineAllDetail?.volume.toString() + " " + t("machines.additional-fields.volumeSuffix")}
                                    />
                                </Col>
                            )}
                            {machineAllDetail?.waterTankCapacity && (
                                <Col md={4} xs={8} className={style.period}>
                                    <InfoBox
                                        head={t("machines.additional-fields.waterTankCapacity")}
                                        text={
                                            machineAllDetail?.waterTankCapacity.toString() +
                                            " " +
                                            t("machines.additional-fields.capacitySuffix")
                                        }
                                    />
                                </Col>
                            )}
                            {machineAllDetail?.width && (
                                <Col md={4} xs={8} className={style.period}>
                                    <InfoBox
                                        head={t("machines.additional-fields.width")}
                                        text={machineAllDetail?.width.toString() + " " + t("machines.additional-fields.widthSuffix")}
                                    />
                                </Col>
                            )}
                            {machineAllDetail?.workingRadius && (
                                <Col md={4} xs={8} className={style.period}>
                                    <InfoBox
                                        head={t("machines.additional-fields.workingRadius")}
                                        text={machineAllDetail?.workingRadius.toString()}
                                    />
                                </Col>
                            )}
                            {machineAllDetail?.workingSpeed && (
                                <Col md={4} xs={8} className={style.period}>
                                    <InfoBox
                                        head={t("machines.additional-fields.workingSpeed")}
                                        text={
                                            machineAllDetail?.workingSpeed.toString() +
                                            " " +
                                            t("machines.additional-fields.workingSpeedSuffix")
                                        }
                                    />
                                </Col>
                            )}
                        </Row>
                    </Col>
                </BoxContainer>
                <BoxContainer>
                    <Col className={style.machineHeader}>{t("common.attachments")} </Col>
                    <Col>
                        <Row gutter={[16, 16]}>
                            {machineAllDetail?.attachments && machineAllDetail.attachments.length <= 0 ? (
                                <Col>
                                    <Row gutter={[8, 8]} align="middle">
                                        <Col style={{ display: "flex", margin: "1rem 0" }}>
                                            <Info />
                                        </Col>
                                        <Col className={style.kdv}>{t("machine-detail.none-attachment-text")}</Col>
                                    </Row>
                                </Col>
                            ) : (
                                machineAllDetail?.attachments?.map((item) => {
                                    return (
                                        <Col key={item.id} md={6} xs={12}>
                                            <span className={style.attachment}>{item.name || " - "} </span>
                                        </Col>
                                    );
                                })
                            )}
                        </Row>
                    </Col>
                </BoxContainer>
                <BoxContainer>
                    <Col className={style.machineHeader}>{t("machine-detail.additional-info")}</Col>
                    <Col className={style.period}>{t("machine-detail.description-header")}</Col>
                    <Col>{machineAllDetail?.notes || t("machine-detail.none-notes-text")}</Col>
                </BoxContainer>
                <BoxContainer>
                    <Col className={style.machineHeader}>
                        {documents && documents?.length >= 1 ? "Belgeler (" + documents.length + " kayıtlı belge)" : t("common.documents")}
                    </Col>
                    <Col>
                        <Row gutter={[24, 24]}>
                            {documents && documents?.length >= 0 ? (
                                documents?.map((item) => {
                                    return (
                                        <Col key={item.document?.id} md={6} xs={12}>
                                            <span className={style.attachment}>{item.documentCategory}</span>
                                        </Col>
                                    );
                                })
                            ) : (
                                <Col>
                                    <Row gutter={[8, 8]} align="middle">
                                        <Col style={{ display: "flex", margin: "1rem 0" }}>
                                            <Info />
                                        </Col>
                                        <Col className={style.kdv}>{t("machine-detail.none-document-text")}</Col>
                                    </Row>
                                </Col>
                            )}
                        </Row>
                    </Col>
                </BoxContainer>
                <BoxContainer>
                    <Col className={style.machineHeader}>{t("machine-detail.additional-services")}</Col>
                    <Col>
                        <Row gutter={[24, 36]}>
                            <Col md={12} xs={24}>
                                <Row gutter={[36, 36]}>
                                    <Col md={24} xs={24}>
                                        <CheckboxReviewer
                                            inputVisible={true}
                                            checkboxLabel={t("machines.add.operator")}
                                            checkboxChecked={machineAllDetail?.hasOperator}
                                            inputLabel={t("machines.add.experience")}
                                            inputVal={machineAllDetail?.operatorExperienceYear}
                                        />
                                    </Col>
                                    <Col md={24} xs={24}>
                                        <CheckboxReviewer
                                            checkboxLabel={t("machines.add.operatorInsuranceIncluded")}
                                            checkboxChecked={machineAllDetail?.operatorCostIncluded}
                                        />
                                    </Col>
                                </Row>
                            </Col>
                            <Col md={12} xs={24}>
                                <Row gutter={[32, 32]}>
                                    <Col md={24} xs={24}>
                                        {" "}
                                        <CheckboxReviewer
                                            checkboxLabel={t("machines.add.operatorCostIncluded")}
                                            checkboxChecked={machineAllDetail?.operatorInsuranceIncluded}
                                        />
                                    </Col>

                                    <Col md={24} xs={24}>
                                        <CheckboxReviewer
                                            checkboxLabel={t("machines.add.shipingIncluded")}
                                            checkboxChecked={machineAllDetail?.shipingIncluded}
                                        />
                                    </Col>
                                    <Col md={24} xs={24}>
                                        <CheckboxReviewer
                                            checkboxLabel={t("machines.add.fuelIncluded")}
                                            checkboxChecked={machineAllDetail?.fuelIncluded}
                                        />
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Col>
                </BoxContainer>
            </Row>
        </SecureLayout>
    );
};

export default RentalMachineDetail;
