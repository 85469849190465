import { Checkbox, Col, Input, Row } from "antd";
import { EventHandler, useState } from "react";
import styles from "../styles/radio-button.module.scss";

type CheckboxProps = {
    content: string | null | undefined;
    value: string;
    checkboxClicked?: EventHandler<any>;
    hiddenInput?: boolean;
    inputLabel?: string;
    inputValidationMessage?: string;
    inputInitialValue?: string;
    required?: boolean;
    inputChange?: EventHandler<any>;
};

const CheckboxButton = (props: CheckboxProps) => {
    const [otherValue, setOtherValue] = useState(props.inputInitialValue);
    const valueChanged = (element: any) => {
        if (props.inputChange) props.inputChange(element);
        setOtherValue(element.target.value);
    };
    return (
        <Row gutter={[8, 8]}>
            <Col xs={props.hiddenInput ? 12 : 24}>
                <div
                    className={styles.container}
                    onClick={() => {
                        if (props.checkboxClicked) props.checkboxClicked(props.value);
                    }}
                >
                    <span>{props.content}</span>
                    <Checkbox value={props.value}></Checkbox>
                </div>
            </Col>
            <Col xs={12}>
                <Input
                    style={props.hiddenInput ? { height: "4rem" } : { display: "none" }}
                    placeholder={props.inputLabel}
                    disabled={!props.hiddenInput}
                    onChange={valueChanged}
                    value={otherValue}
                ></Input>
            </Col>
        </Row>
    );
};

export default CheckboxButton;
