import { Col, Row } from "antd";
import { EventHandler } from "react";
import PrimaryButton from "../components/atomics/primary-button";
import SecondaryButton from "../components/atomics/secondary-button";
import BoxContainer from "../components/containers/box-container";
import { t } from "../translate";
import { RoleType } from "../store/global-atoms";

type requestViewPartProps = {
    requestId: number;
    offerId: number;
    onClick?: EventHandler<any>;
    onDealClick?: EventHandler<any>;
    deal?: boolean;
    role: RoleType;
};

const RequestViewPart = (props: requestViewPartProps) => {
    return (
        <BoxContainer style={{ height: "inherit" }}>
            <Row
                gutter={[16, 16]}
                style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    marginBottom: "0px",
                }}
            >
                <Col md={4}>
                    <Row justify="space-between">
                        <Col style={{ color: "#949394", fontWeight: "800" }}>{t("request.requestID")}</Col>
                        <Col style={{ color: "#171725", fontWeight: "800" }}>{props.requestId}</Col>
                    </Row>
                </Col>
                <Col>
                    <Row gutter={[16, 16]}>
                        {props.deal === true && (
                            <Col>
                                <SecondaryButton style={{ marginBottom: "0" }} onClick={props.onDealClick}>
                                    {props.role === "lessor"
                                        ? t("offer.deal.renterCompanyView.title")
                                        : t("offer.deal.lessorCompanyView.title")}
                                </SecondaryButton>
                            </Col>
                        )}
                        <Col>
                            <PrimaryButton style={{ marginBottom: "0" }} onClick={props.onClick}>
                                {t("request.viewRequest")}
                            </PrimaryButton>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </BoxContainer>
    );
};
export default RequestViewPart;
