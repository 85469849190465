import { Col, Divider, Row, Tabs } from "antd";
import { useEffect, useState } from "react";
import { useAtom } from "jotai";
import BoxContainer from "../../components/containers/box-container";
import SecureLayout from "../../components/layouts/secure-layout";
import { t } from "../../translate";
import { ReactComponent as Profile } from "../../components/assets/profile.svg";
import { useSecureService } from "../../store/service-atoms";
import {
    CategoryModel,
    CityModel,
    LessorCompanyDetailViewModel,
    LessorCompanyInfoModel,
    LessorCompanyInfoUpdateModel,
    LessorCompanyPaymentInfoViewModel,
    LessorCompanyProfileCreateModel,
    LessorCompanyProfileViewModel,
    LessorUserCreateViewModel,
    LessorUserViewModel,
} from "../../service";
import PrimaryButton from "../../components/atomics/primary-button";
import BasicModelViewer from "../../components/atomics/basic-model-viewer";
import UpdateProfileModal from "../../modals/update-profile-modal";
import { postloginMessageAtom } from "../../store/global-atoms";
import UpdateCompanyInfoModal from "../../modals/update-company-info-modal";
import SecondaryButton from "../../components/atomics/secondary-button";
import { ReactComponent as PlusB } from "../../components/assets/plusB.svg";
import AddLessorUserModal from "../../modals/add-company-user-modal";
import UpdatePaymentModal from "../../modals/update-payment-info-modal";
import UpdateLessorAuthorizationModal from "../../modals/update-lessor-authorization-modal";
import _ from "lodash";
import styles from "./lessor-profile.module.scss"
import DeleteAccountModal from "../../modals/delete-account-modal";
import { useNavigate } from "react-router-dom";
import SMSVerificationModal from "../../modals/sms-verification-modal";


const LessorProfile = () => {
    //const [user] = useAtom(userAtom);
    const [profile, setCompanyProfile] = useState<LessorCompanyProfileViewModel | undefined>(undefined);
    const [companyDetails, setCompanyDetails] = useState<(LessorCompanyInfoModel & LessorCompanyDetailViewModel) | undefined>(undefined);
    const [companyDetail, setCompanyDetail] = useState<LessorCompanyDetailViewModel | undefined>(undefined);
    const [lessorUsers, setLessorUsers] = useState<LessorUserViewModel[] | undefined>(undefined);
    const [updateProfileModalVisible, setUpdateProfileModalVisible] = useState<boolean>(false);
    const [updatePaymentModalVisible, setUpdatePaymentModalVisible] = useState<boolean>(false);
    const [updateInfoModalVisible, setUpdateInfoModalVisible] = useState<boolean>(false);
    const [createLessorUserModalVisible, setCreateLessorUserModalVisible] = useState<boolean>(false);
    const [updateLessorAuthorizationModalVisible, setUpdateLessorAuthorizationModalVisible] = useState<boolean>(false);
    const [cities, setCities] = useState<CityModel[] | undefined>(undefined);
    const [categories, setCategories] = useState<CategoryModel[] | undefined>(undefined);
    const [, setErrorMessage] = useAtom(postloginMessageAtom);
    const [render, reRender] = useState<number>(0);
    const [dataFetching, setDataFetching] = useState(true);
    const [deleteAccountSMSVerificationModalVisible, setDeleteAccountSMSVerificationModalVisible] = useState(false);
    const navigate = useNavigate();
    const [deleteAccountModalVisible, setDeleteAccountModalVisible] = useState(false);
    const {
        doGetLessorCompanyDetails,
        doGetLessorCompanyDetail,
        doGetLessorCompanyUsers,
        doGetLessorCompanyProfile,
        doSaveLessorCompanyProfile,
        doSaveLessorCompanyInfo,
        doGetCities, 
        doCreateLessorUser,
        doUpdateLessorPaymentInfo,
        doGetMachineSubcategory,
        doAuthorizeLessorsForSale,
        doDeleteLessorUser,
        doDeleteLessorUserV2,
        doGenerateDeleteLessorUserOTP,
    } = useSecureService();

    useEffect(() => {
        doGetLessorCompanyDetails().then((result) => {
            setCompanyDetails(result);
        });
        doGetLessorCompanyDetail(0).then((result) => {
            setCompanyDetail(result);
        });

        doGetLessorCompanyUsers(0).then((result) => {
            setLessorUsers(result);
        });

        doGetCities().then((result) => {
            setCities(result);
            doGetLessorCompanyProfile().then((result) => {
                setCompanyProfile(result);
            });
        });
        doGetMachineSubcategory().then((result) => {
            setCategories(result);
        })

        //eslint-disable-next-line
    }, [render]);

    useEffect(() => {
        if (companyDetail && companyDetails && profile && categories && cities && lessorUsers) {
            setDataFetching(false);
        }
    }, [companyDetail, companyDetails, profile, categories, cities, lessorUsers]);
    const onUpdateCompanyInfoClick = () => {
        setUpdateInfoModalVisible(true);
    };
    const onUpdatePaymentInfoClick = () => {
        setUpdatePaymentModalVisible(true);
    };

    const onUpdateProfileClick = () => {
        setUpdateProfileModalVisible(true);
    };
    const onCreateUserClick = () => {
        setCreateLessorUserModalVisible(true);
    };

    const profileUpdateApprove = async (data: LessorCompanyProfileCreateModel | undefined) => {
        if (data) {
            const result = await doSaveLessorCompanyProfile(data);
            if (result) {
                setErrorMessage({
                    type: "success",
                    message: t("lessor-profile.companyProfile.editSuccess"),
                });
                reRender(render + 1);
            }
            setUpdateProfileModalVisible(false);
        }
    };
    const createLessorUser = async (data: LessorUserCreateViewModel | undefined) => {
        if (data) {
            const result = await doCreateLessorUser(data);
            if (result) {
                setErrorMessage({
                    type: "success",
                    message: t("lessor-profile.createUser.success"),
                });
                reRender(render + 1);

            }
            setCreateLessorUserModalVisible(false);
        }
    };

    const onDeleteLessorClicked = () => {
        setDeleteAccountModalVisible(true);
    };

    const onDeleteClick = async () => {
        var id = await doGenerateDeleteLessorUserOTP();
        if (id) {
            setDeleteAccountSMSVerificationModalVisible(true);
            setDeleteAccountModalVisible(false);
        }
    }

    const onDeleteSMSVerificationClick = async (otp: string) => {
        const result = await doDeleteLessorUserV2(otp);

        if (result) {
            setErrorMessage({message: t("common.deleteSuccess"), type: 'success'});
            navigate("/login");
        } 
    }
    
    const infoUpdateApprove = async (data: LessorCompanyInfoUpdateModel | undefined) => {
        if (data) {
            const result = await doSaveLessorCompanyInfo(data);
            if (result) {
                setErrorMessage({
                    type: "success",
                    message: t("lessor-profile.companyInfo.editSuccess"),
                });
                reRender(render + 1);
            }
            setUpdateInfoModalVisible(false);
        }
    };
    const PaymentInfoUpdateApprove = async (data: LessorCompanyPaymentInfoViewModel | undefined) => {
        if (data) {
            doUpdateLessorPaymentInfo(data);
            setErrorMessage({
                type: "success",
                message: t("lessor-profile.companyInfo.editSuccess"),
            });
            reRender(render + 1);
            setUpdateInfoModalVisible(false);
        }
    };
    const { TabPane } = Tabs;

    const onChange = (key: string) => {
        console.log(key);
    };

    const onUpdateLessorAuthorization = async (lessors: number[]) => {
        const unauthorisedLessors = _.compact(lessorUsers?.map(e => {
            if (lessors.some(x => x === e.id)) {
                return undefined;
            } else {
                return e.id;
            }
        })); 

        const result = await doAuthorizeLessorsForSale(lessors, unauthorisedLessors);

        if (result) {
            setUpdateLessorAuthorizationModalVisible(false);
            doGetLessorCompanyUsers(0).then((result) => {
                setLessorUsers(result);
            });
        } 

    }

    return (
        <SecureLayout
            activePage={undefined}
            role={"lessor"}
            title={t("lessor-profile.title")}
            description={t("lessor-profile.desceription")}
            loading={dataFetching}
        >
            <Row gutter={[24, 24]}>
                <Col xs={24} md={7} style={{ height: "fit-content" }}>
                    <BoxContainer>
                        <div
                            style={{
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "center",
                                gap: "1rem",
                            }}
                        >
                            <div
                                style={{
                                    borderRadius: "50px",
                                    height: "100px",
                                    width: "100px",
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    border: "1px solid #94949455",
                                }}
                            >
                                <Profile height={50} width={50} />
                            </div>
                            <div
                                style={{
                                    color: "#FFFFFF",
                                    background: "#949394",
                                    borderRadius: "5rem",
                                    padding: ".25rem 1rem",
                                    fontSize: ".875rem",
                                    fontWeight: "700",
                                    marginTop: "-2rem",
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                }}
                            >
                                <span style={{ textAlign: "center" }}>{profile?.title}</span>
                            </div>
                            <span
                                style={{
                                    fontWeight: "700",
                                    fontSize: "1.25rem",
                                }}
                            >
                                {(profile?.name || "") + " " + profile?.lastName}
                            </span>
                            <span
                                style={{
                                    fontWeight: "700",
                                    fontSize: "1rem",
                                }}
                            >
                                {profile?.phone}
                            </span>
                            <span
                                style={{
                                    fontWeight: "700",
                                    fontSize: "1rem",
                                }}
                            >
                                {profile?.email}
                            </span>
                            <div>
                                <PrimaryButton onClick={onUpdateProfileClick}>{t("common.updateInfo")}</PrimaryButton>
                                {/* <SecondaryButton>{t("common.changePassword")}</SecondaryButton> */}
                            </div>
                        </div>
                    </BoxContainer>
                </Col>
                <Col xs={24} md={17} style={{ height: "fit-content" }}>
                    <BoxContainer>
                        <Tabs type="card" defaultActiveKey="1" onChange={onChange}>
                            <TabPane closable key="1" tab={t("lessor-profile.companyProfile.title")}>
                                <Row gutter={[8, 8]}>
                                    <BasicModelViewer objKey={t("common.companyName")} value={companyDetails?.companyName || "-"} />
                                    <BasicModelViewer
                                        objKey={t("lessor-profile.companyProfile.companyWebsite")}
                                        value={companyDetails?.website || "-"}
                                    />
                                    <BasicModelViewer
                                        objKey={t("lessor-profile.companyProfile.location")}
                                        value={companyDetails?.location?.name ? companyDetails?.location?.name + ", " + companyDetails?.location?.city?.name : "-"}
                                    />
                                    <BasicModelViewer
                                        objKey={t("lessor-profile.companyProfile.serviceRegions")}
                                        value={companyDetails?.serviceRegions?.map((reg) => reg.name).join(", ") || "-"}
                                    />
                                    <BasicModelViewer
                                        objKey={t("lessor-profile.companyProfile.sector")}
                                        value={companyDetails?.categories?.map((reg) => reg.name).join(", ") || "-"}
                                    />
                                    <BasicModelViewer
                                        objKey={t("lessor-profile.companyProfile.totalMachines")}
                                        value={companyDetails?.totalMachines || "-"}
                                    />
                                    <BasicModelViewer
                                        objKey={t("lessor-profile.companyProfile.companyAddress")}
                                        value={companyDetails?.address || "-"}
                                    />
                                    <BasicModelViewer
                                        objKey={t("lessor-profile.companyProfile.operatorCount")}
                                        value={companyDetails?.operatorCount || "-"}
                                    />
                                    <BasicModelViewer
                                        objKey={t("lessor-profile.companyProfile.employeeCount")}
                                        value={companyDetails?.employeeCount || "-"}
                                    />
                                    <BasicModelViewer
                                        xs={24}
                                        md={24}
                                        objKey={t("lessor-profile.companyProfile.activityFields")}
                                        value={companyDetails?.mainServiceActivityFields || "-"}
                                    />
                                </Row>
                                <Divider />
                                <Row className={styles['button-container']}>
                                    <Col xs={24} sm={11} md={9}>
                                        <PrimaryButton onClick={onUpdateCompanyInfoClick}>
                                            {t("common.updateInfo")}
                                        </PrimaryButton>
                                    </Col>
                                    <Col xs={24} sm={11} md={9}>
                                        <PrimaryButton style={{backgroundColor: 'red', borderColor: 'transparent'}} onClick={onDeleteLessorClicked}>
                                            {t("common.deleteAccount")}
                                        </PrimaryButton>
                                    </Col>
                                </Row>
                            </TabPane>
                            <TabPane closable key="2" tab={t("lessor-profile.companyUsers.title")}>
                                <Row gutter={[8, 8]}>
                                    {lessorUsers?.map((user) => {
                                        const userId = Number(user?.id);
                                        return (
                                            <Col md={8}>
                                                <BoxContainer>
                                                    <div hidden={!user.isAuthorisedForSale} className={styles['authorized-lessor-tag-container']} >
                                                        <span className={styles['authorized-lessor-tag']}>{t("lessor-profile.updateAuthorizedLessors.tag")}</span>
                                                    </div>
                                                    <div hidden={user.isAuthorisedForSale} style={{ height: '1.5rem', width: '100%', marginBottom: '12px'}} />
                                                    <BasicModelViewer
                                                        xs={24}
                                                        md={24}
                                                        objKey={t("lessor-profile.companyUser.nameLastName")}
                                                        value={user?.name + " " + user.lastname}
                                                        height="4rem"
                                                        verticalPaddingDisable
                                                    />
                                                    <BasicModelViewer
                                                        xs={24}
                                                        md={24}
                                                        objKey={t("lessor-profile.companyUser.phone")}
                                                        value={user?.phone || "-"}
                                                        height="4rem"
                                                        verticalPaddingDisable
                                                    />
                                                    <BasicModelViewer
                                                        xs={24}
                                                        md={24}
                                                        objKey={t("lessor-profile.companyUser.email")}
                                                        value={user?.email || "-"}
                                                        height="4rem"
                                                        verticalPaddingDisable
                                                    />
                                                    <BasicModelViewer
                                                        xs={24}
                                                        md={24}
                                                        objKey={t("lessor-profile.companyUser.title")}
                                                        value={user?.title || "-"}
                                                        height="4rem"
                                                        verticalPaddingDisable
                                                    /> 
                                                    <Divider />
                                                    <div style={{ display: "flex" }}>
                                                        <SecondaryButton
                                                            hidden={!profile?.isManager || user.isManager}
                                                            value={userId}
                                                            negative
                                                            onClick={async () => {
                                                                await doDeleteLessorUser(userId).then(() => {
                                                                    console.log('kullanıcı log');
                                                                    setErrorMessage({
                                                                        type: "success",
                                                                        message: t("lessor-profile.deleteUser.success"),
                                                                    });    
                                                                });
                                                                reRender((oldVal) => {return oldVal + 1});
                                                            }}
                                                        >
                                                            {t("common.delete")}
                                                        </SecondaryButton>            
                                                    </div>
                                                </BoxContainer>
                                            </Col>
                                        );
                                    })}
                                    <Col hidden={!profile?.isManager} md={8}>
                                        <BoxContainer
                                            style={{
                                                backgroundColor: "#FFF6E8",
                                                display: "flex",
                                                alignItems: "center",
                                                justifyContent: "center",
                                            }}
                                        >
                                            <Row gutter={[16, 16]}>
                                                <Col md={24} xs={24} style={{ display: "block", textAlign: "center" }}>
                                                    <PlusB onClick={onCreateUserClick}></PlusB>
                                                </Col>
                                                <Col md={24} xs={24} style={{ display: "block", textAlign: "center" }}>
                                                    {t("lessor-profile.addNewUser")}
                                                </Col>
                                            </Row>
                                        </BoxContainer>
                                    </Col> 
                                </Row>
                                <Row style={{marginTop: '12px'}}>
                                    <PrimaryButton onClick={() => {
                                        setUpdateLessorAuthorizationModalVisible(true);
                                    }}>{t("lessor-profile.updateAuthorizedLessors")}</PrimaryButton>
                                </Row>
                            </TabPane>
                            <TabPane closable key="3" tab={t("lessor-profile.paymentInfo.title")}>
                                <Row gutter={[8, 8]}>
                                    <BasicModelViewer objKey={t("common.companyName")} value={companyDetail?.companyName} />
                                    <BasicModelViewer
                                        objKey={t("lessor-profile.companyView.taxIdentityNo")}
                                        value={companyDetail?.taxIdentityNo || "-"}
                                    />
                                    <BasicModelViewer
                                        objKey={t("lessor-profile.companyView.taxOffice")}
                                        value={companyDetail?.taxOffice || "-"}
                                    />
                                    <BasicModelViewer
                                        objKey={t("lessor-profile.companyView.taxOfficeCityName")}
                                        value={companyDetail?.taxOfficeCity?.name || "-"}
                                    />
                                    <BasicModelViewer objKey={t("lessor-profile.companyView.iban")} value={companyDetail?.iban || "-"} />
                                </Row>
                                <Divider />
                                <div style={{ display: "flex" }}>
                                    <PrimaryButton onClick={onUpdatePaymentInfoClick} style={{ width: "auto" }}>
                                        {t("common.updateInfo")}
                                    </PrimaryButton>
                                </div>
                            </TabPane>
                        </Tabs>
                    </BoxContainer>
                </Col>
            </Row>
            <UpdateProfileModal
                show={updateProfileModalVisible}
                onCloseClick={() => setUpdateProfileModalVisible(false)}
                onApprove={profileUpdateApprove}
                profileData={profile}
            />
            <UpdateCompanyInfoModal
                categoriesList={categories}
                citiesList={cities}
                show={updateInfoModalVisible}
                onCloseClick={() => setUpdateInfoModalVisible(false)}
                onApprove={infoUpdateApprove}
                infoData={companyDetails}
            />
            <AddLessorUserModal
                show={createLessorUserModalVisible}
                onCloseClick={() => setCreateLessorUserModalVisible(false)}
                onClick={createLessorUser}
            ></AddLessorUserModal>
            <UpdatePaymentModal
                show={updatePaymentModalVisible}
                onCloseClick={() => setUpdatePaymentModalVisible(false)}
                onUpdate={PaymentInfoUpdateApprove}
                paymentData={companyDetail}
                cities={cities}
            ></UpdatePaymentModal>
            <UpdateLessorAuthorizationModal 
                show={updateLessorAuthorizationModalVisible}
                onCloseClick={() => {
                    setUpdateLessorAuthorizationModalVisible(false);
                }}
                key={'lessor-auth-modal-' + updateLessorAuthorizationModalVisible}
                lessors={lessorUsers ?? []}
                onSaveClick={onUpdateLessorAuthorization}
            />
            <DeleteAccountModal 
                type={profile?.isManager ? 'manager' : 'lessor'}
                onDeleteClick={onDeleteClick}
                hasAnyOffer={companyDetail?.hasAnyOffer && profile?.isManager}
                onCloseClick={() => setDeleteAccountModalVisible(false)}
                show={deleteAccountModalVisible}
            />
            <SMSVerificationModal 
                key={'delete-verification-modal-lessor-' + deleteAccountSMSVerificationModalVisible}
                type='userDeletion'
                onSendOtpClicked={onDeleteSMSVerificationClick}
                phone={profile?.phone ?? ''}
                show={deleteAccountSMSVerificationModalVisible}
                onCloseClick={() => setDeleteAccountSMSVerificationModalVisible(false)}
            />
        </SecureLayout>
    );
};
export default LessorProfile;
