import { Col, Form, Radio, Row, Spin } from "antd";
import PasswordInput from "../../components/atomics/password-input";
import PrimaryButton from "../../components/atomics/primary-button";
import AuthLayout from "../../components/layouts/auth-layout";
import { t } from "../../translate";
import { ArrowRightOutlined } from "@ant-design/icons";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useAtom } from "jotai";
import { RoleType, loadingAtom, postloginMessageAtom, signUpAtom } from "../../store/global-atoms";
import { useSecureService } from "../../store/service-atoms";
import { useEffect, useState } from "react";
import { logoutChannel } from "../../App";
import EmailInput from "../../components/atomics/email-input";
import SocialAuthButton from "../../components/atomics/social-auth-button";
import { AuthType, SocialAuthPageType, SocialLoginMessageType } from "../../service";
import LinkButton from "../../components/atomics/link-button";
import { facebookLogin, googleLogin } from "../../utils/auth-utils";
import { facebookLoginActive, googleLoginActive, redirectLoginPageUri } from "../../store/app-constants";
import styles from "./login.module.scss"
import TabButton from "../../components/atomics/tab-button";
import { ReactComponent as CorpColorful } from "../../components/assets/ic_corp_colorful.svg";
import { ReactComponent as UserColorful } from "../../components/assets/ic_user_colorful.svg";

const Login = () => {
    const { doLogin, doSocialLogin, doLogout } = useSecureService();
    const navigate = useNavigate();
    const [query, setQuery] = useSearchParams();
    const queryRole = ((query.get("role") || query.get("state")?.split('role:')[1]) as RoleType); // 'state' holds roletype info for facebook redirection
    const socialLoginQuery = query.get("state")?.split('loginType:')[1].split(',')[0];
    const socialLoginType = (socialLoginQuery as AuthType) || null ; // code injected by facebook login dialog
    const socialLoginCode = query.get("code"); // code injected by facebook login dialog
    const queryLogout = query.get("isLogout");
    const [nonsecureRole, setNonsecureRole] = useState<RoleType | undefined>(undefined);
    const [showSelectErrorMessage, setShowSelectErrorMessage] = useState(false);
    const fromRequest = query.get("request") ? "&request=true" : "";
    const [loading] = useAtom(loadingAtom);
    const [socialLoginLoading, setSocialLoginLoading] = useState(query.get("code") !== null);
    const [,setWarningMessage] = useAtom(postloginMessageAtom);
    const [, setSignUp] = useAtom(signUpAtom);


    useEffect(() => {
        console.log(queryLogout, " ", queryRole);
        if (queryLogout && queryRole) {
            if (queryRole === "lessor") {
                logoutChannel.postMessage("MG_LOGGED_OUT_NOTIFICATION_LESSOR");
            } else {
                logoutChannel.postMessage("MG_LOGGED_OUT_NOTIFICATION_RENTER");
            }

            const logoutCall = async () => {
                await doLogout();
                navigate("/login?role=" + queryRole);
            };
            logoutCall();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [queryLogout, queryRole]);

    useEffect(() => {
        const socialLoginAsync = async (loginType: AuthType) => {
            var res = await doSocialLogin(queryRole, {authType: loginType, socialToken: socialLoginCode, socialAuthPageType: SocialAuthPageType.Login});
            switch(res?.type) {
                case SocialLoginMessageType.NotFound:
                case SocialLoginMessageType.SwitchMessage:
                    setSignUp({
                        authType: loginType,
                        email: res.email!!,
                        name: res.name,
                        lastname: res.lastname
                    });
                    if (queryRole === 'customer') { 
                        navigate("/signup/form?role=customer");
                    }
                    else if (queryRole === 'lessor') {
                        navigate("/signup/form?role=lessor");
                    }
                    break;
                case SocialLoginMessageType.Blocked:
                    setWarningMessage({message: res.message, type: 'error'});  
                    setSocialLoginLoading(false);
                    break;  
                case SocialLoginMessageType.Success:
                    navigate("/dashboard?role=" + queryRole + fromRequest);
                    break;
                case SocialLoginMessageType.Otp:
                    navigate("/sms-verification?id=" + res?.id + "&op=register&phone=" + res?.message + "&role=" + queryRole + fromRequest);
                    break;
                default:
                    setSocialLoginLoading(false);
                    break;
            }
        }
        if (socialLoginCode && socialLoginType !== null) {
            socialLoginAsync(socialLoginType);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if (queryRole === "customer") {
            setNonsecureRole("customer");
        } else if (queryRole === "lessor") {
            setNonsecureRole("lessor");
        }
        //eslint-disable-next-line
    }, [queryRole]);

    const formFinishHandler = async (parameters: { email: string; password: string }) => {
        if (!checkRoleSelected()) {
            return;
        } 
        var isEmail = parameters.email.includes("@");   
        var username = parameters.email;
        if (!isEmail) 
            username =  parameters.email.replace(/^0/, '')

        const result = await doLogin(queryRole, { password: parameters.password, username: username });
        if (result) {
            navigate("/dashboard?role=" + queryRole + fromRequest);
        }
    };

    const onRadioDivClicked = (param: string) => {
        setShowSelectErrorMessage(false);
        setNonsecureRole(param === "lessor" ? "lessor" : "customer");
        query.set("role", param);
        setQuery(query);
    };
    const checkRoleSelected = () => {
        if (nonsecureRole === undefined) {
            setShowSelectErrorMessage(true);
            return false;
        } 
        return true;
    }
    return (
        <AuthLayout hideUpperLogo hideBackgroundImg hideMachineLogo>
            {socialLoginLoading ? (
                    <Spin size="large" tip="Lütfen Bekleyiniz" className={styles.spinMobile} />
            ) : ( <>
            <div className={styles.container}>
                <Form
                    name="normal_login"
                    className="login-form"
                    layout="vertical"
                    validateTrigger="onBlur"
                    onFinish={formFinishHandler}
                >
                    <div className={styles.radioContainer}>
                        {
                            showSelectErrorMessage ?
                                <Row justify="center">
                                    <span className={styles.selectErrorMessage}>{t("login.cardTitle")}</span>
                                </Row>
                                : 
                                <></>
                        }
                        <Radio.Group style={{width: '100%'}} value={nonsecureRole}>
                            <Row className={styles.radioInnerContainer}>
                                <Col xs={11} className={styles.radioButton}>
                                    <TabButton
                                        icon={<CorpColorful height="20px" width="20px" />}
                                        label={t("signup.index.secondCardTitle")}
                                        active={nonsecureRole === 'lessor'}
                                        onClick={(e: any) => {
                                            onRadioDivClicked("lessor");
                                        }}
                                    />
                                </Col>
                                <Col xs={11} className={styles.radioButton}>
                                    <TabButton
                                        icon={<UserColorful height="20px" width="20px" />}
                                        label={t("signup.index.firstCardTitle")}
                                        active={nonsecureRole === 'customer'}
                                        onClick={(e: any) => {
                                            onRadioDivClicked("customer");
                                        }}
                                    />
                                </Col>
                            </Row>
                        </Radio.Group>
                    </div>
                    <Row className={styles.loginInputContainer} gutter={[0, 12]}>
                        <Row style={{width: '100%'}} gutter={[0,12]}>
                            <Row style={{width: '100%', justifyContent: 'center'}}>
                                <Col xs={24}>
                                    <EmailInput />
                                </Col>
                            </Row>
                            <Row style={{width: '100%', justifyContent: 'center', marginTop: '-16px'}}>
                                <Col xs={24}>
                                    <PasswordInput
                                        page="login"
                                        showIcon
                                        placeholder={t("login.password.placeholder")}
                                        validationMessage={t("login.password.validationMessage")}
                                        label={t("login.password.label")}
                                    />
                                </Col>
                            </Row>
                            <Row className={styles.forgotPassword}>
                                <span onClick={() => navigate("/forgot-password?role=" + nonsecureRole)}>
                                    {t("login.forgotPassword")}
                                </span>
                            </Row>
                            <Row style={{width: '100%'}}>
                                <PrimaryButton loading={loading} htmlType="submit">
                                    <span className={styles.signinText}>{queryRole === 'lessor' ? t('login.continue.lessor') : queryRole === 'customer' ? t('login.continue.renter') : t("common.signin")}</span>
                                </PrimaryButton>
                            </Row>
                            <Col hidden={!facebookLoginActive} xs={24}>
                                <SocialAuthButton page="login" type="facebook" text={t("login.facebook.button-text")} onClick={() => {
                                    if (!checkRoleSelected()) 
                                        return;
                                    facebookLogin(redirectLoginPageUri, "loginType:" + AuthType.Facebook + ",role:" + queryRole);
                                }}/>
                            </Col>
                            <Col hidden={!googleLoginActive} xs={24}>
                                <SocialAuthButton page="login" type="google" text={t("login.google.button-text")} onClick={() => {
                                    if (!checkRoleSelected()) 
                                        return;
                                    googleLogin(redirectLoginPageUri, "loginType:" + AuthType.Google + ",role:" + queryRole);
                                }}/>
                            </Col>
                        </Row>
                    </Row>
                    <Row justify="center" className={styles.registerTextContainer}>
                        <Col xs={24}>
                            <Row justify="center">
                                <span className={styles.registerText}>{t("login.signup.init")}</span>
                            </Row>
                            <Row justify="center" style={{alignItems: 'center', columnGap: '4px'}}>
                                <LinkButton style={{fontSize: '14px', height: '24px'}} loading={loading} onClick={() => navigate("/signup" + (nonsecureRole ? '?role=' + nonsecureRole : ''))}>
                                    {t("login.signup.link")}
                                </LinkButton>
                                <ArrowRightOutlined style={{ color: "#FEB111" }} />
                            </Row>
                        </Col>
                        <div className={styles.agreementContainer}>
                            <span>
                                {t("login.agreement.part-1") + ' ' } 
                                <span onClick={() => navigate("/aydinlatma-metni")} style={{fontWeight: 800, textDecoration: 'underline', cursor: 'pointer'}}>{t("login.agreement.part-2") + ' '}</span>
                                {t("login.agreement.part-3") + ' '} 
                                <span onClick={() => navigate("/kullanici-sozlesmesi")} style={{fontWeight: 800, textDecoration: 'underline', cursor: 'pointer'}}>{t("login.agreement.part-4")}</span>
                                {t("login.agreement.part-5") + ' '} 
                                <span onClick={() => navigate("/kurumsal-ve-bireysel-uye-acik-riza-metni")} style={{fontWeight: 800, textDecoration: 'underline', cursor: 'pointer'}}>{t("login.agreement.part-6")}</span>
                                {t("login.agreement.part-7")}
                            </span>
                        </div>  
                    </Row>
                </Form>
            </div> 
            </>)}
        </AuthLayout>
    );
};

export default Login;
