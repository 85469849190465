import { useEffect } from "react";
import { createSearchParams, useNavigate, useSearchParams } from "react-router-dom";

export const PaymentRedirect = () => {
    const navigate = useNavigate();
    const [query, ] = useSearchParams();
    const to = query.get("to");
    const id = query.get("id") ?? "";
    const status = query.get("status") as ("fail" | "success") ?? "fail";
    const message = query.get("message") ?? "";

    const redirectDepozitFee = () => {
        const pathName = status === "fail" ? "/reservation-fail" : "/reservation-success";
        const searchParams: URLSearchParams = createSearchParams({});
        if(id) searchParams.append("id", id);
        if(message) searchParams.append("message", message);

        return navigate({
            pathname: pathName, 
            search: `${searchParams}`
        }, 
        {replace:true});
    }

    const redirectDefault = () => {
        return navigate("/login", {replace:true})
    }

    useEffect(() => {
        if(to === 'depozit-fee'){
            return redirectDepozitFee();
        } else {
            return redirectDefault();
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [to]);
    
    return <></>
}