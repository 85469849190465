/* tslint:disable */
/* eslint-disable */
/**
 * MachineGo P1 API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ActivationModel
 */
export interface ActivationModel {
    /**
     * 
     * @type {number}
     * @memberof ActivationModel
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof ActivationModel
     */
    code: string;
}

export function ActivationModelFromJSON(json: any): ActivationModel {
    return ActivationModelFromJSONTyped(json, false);
}

export function ActivationModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): ActivationModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'code': json['code'],
    };
}

export function ActivationModelToJSON(value?: ActivationModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'code': value.code,
    };
}

