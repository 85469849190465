import { Pagination, Row } from "antd";
import { useAtom } from "jotai";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import OfferListCard from "../../../components/atomics/offer-list-card";
import PrimaryButton from "../../../components/atomics/primary-button";
import SecureLayout from "../../../components/layouts/secure-layout";
import { OfferFormSearchModel, OfferFormViewModel } from "../../../service";
import { loadingAtom } from "../../../store/global-atoms";
import { useSecureService } from "../../../store/service-atoms";
import { t } from "../../../translate";

const ListOffers = () => {
    const navigate = useNavigate();
    const [totalItems, setTotalItems] = useState<number | undefined>(0);
    const [activePage, setActivePage] = useState<number | undefined>(1);
    const { doSearchOffers } = useSecureService();
    const [loading] = useAtom(loadingAtom);
    const [dataFetching, setDataFetching] = useState(true);
    const [filterObj] = useState<OfferFormSearchModel>({
        status: ["Accepted", "Approved", "Deal", "Declined", "Expired", "Offered", "Rejected", "PaymentDeclined"],
    });
    const [offers, setOffers] = useState<OfferFormViewModel[]>([]);
    const pageSize = 6;
    useEffect(() => {
        const getOffers = async () => {
            setDataFetching(true);
            const result = await doSearchOffers({
                pageNo: activePage,
                pageSize: pageSize,
                ...filterObj,
            });
            if (result && result.data) {
                setOffers(result.data);
                setActivePage(result.pageNo);
                setTotalItems(result.itemCount);
            }
            setDataFetching(false);
        };
        getOffers();
        //eslint-disable-next-line
    }, [activePage]);

    const pageChange = (page: number) => {
        setActivePage(page);
    };

    const viewClick = (reqId: number | undefined, offerId: number | undefined) => {
        navigate("/offers/view?reqid=" + reqId + "&offerid=" + offerId);
    };
    return (
        <SecureLayout
            activePage={"offers"}
            role={"lessor"}
            title={t("offers.list.title")}
            description={t("offers.list.description")}
            helperButtons={[<PrimaryButton loading={loading}>{t("common.search")}</PrimaryButton>]}
            loading={dataFetching}
        >
            <Row gutter={[16, 16]} style={{ marginBottom: "2rem" }}>
                {!offers || offers.length === 0 ? (
                    <></>
                ) : (
                    offers.map((offer) => {
                        return <OfferListCard onViewClick={viewClick} xs={24} md={8} offerForm={offer} />;
                    })
                )}
            </Row>
            <Row style={{ justifyContent: "center" }}>
                <Pagination defaultCurrent={activePage} responsive current={activePage} pageSize={pageSize} total={totalItems} onChange={pageChange} />
            </Row>
        </SecureLayout>
    );
};

export default ListOffers;
