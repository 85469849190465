import { Button, Col, Form, Input, Row, Select } from "antd";
import { useEffect } from "react";
import styles from "./styles/offer-period-with-input.module.scss"
import { FormInstance } from "antd/es/form/Form";
import { SuffixProps } from "./../components/atomics/checkbox-with-input";
import { useAtom } from "jotai";
import { offerTabAtom } from "./../store/global-atoms";
import { t } from "../translate";

export type OfferPeriodWithInputProps = {
    form: FormInstance<any>;
    inputName: string;
    inputLabel: string;
    maturityName?: string;
    lowestMaturitySelected?: boolean;
    suffixProps?: SuffixProps;
    lowestMaturityInputName: string;
    index: number;
}


const OfferPeriodWithInput = (props: OfferPeriodWithInputProps) => {
    const [offerTabInfo, setOfferTabInfo] = useAtom(offerTabAtom);

    useEffect(() => {
        const newPrice = offerTabInfo.lowestSelectedMaturityPeriodPrice ? Math.ceil(offerTabInfo.lowestSelectedMaturityPeriodPrice + (offerTabInfo.lowestSelectedMaturityPeriodPrice * (offerTabInfo.maturityInterestRates[offerTabInfo.currentTab.index] ?? 0) * 0.01)) : null;
        if(offerTabInfo.maturityInterestRates[offerTabInfo.currentTab.index]) props.form.setFieldsValue({[props.inputName] : newPrice});
        else props.form.setFieldsValue({[props.inputName] : offerTabInfo.lowestSelectedMaturityPeriodPrice});
        if(offerTabInfo.maturityInterestRates[offerTabInfo.currentTab.index]) props.form.setFieldsValue({[props.maturityName + "-input"] : offerTabInfo.maturityInterestRates[offerTabInfo.currentTab.index]});
        else if(offerTabInfo.maturityInterestRates[offerTabInfo.currentTab.index] === 0) props.form.setFieldsValue({[props.maturityName+ "-input"] : 0});
        else  props.form.setFieldsValue({[props.maturityName+ "-input"] : ""});
        //eslint-disable-next-line
    },[JSON.stringify(offerTabInfo.maturityInterestRates), offerTabInfo.currentTab]);

/*     useEffect(() => {
        if(offerTabInfo.selectedLowestMaturityId) props.form.setFieldsValue({[props.lowestMaturityInputName] : ""});
        if(offerTabInfo.selectedLowestMaturityId === offerTabInfo.currentTab.index) {
            props.form.setFieldsValue({
                [props.maturityName + "-input"] : 0,
                [props.inputName] : 0
            });
        } 
        
        //eslint-disable-next-line
    }, [offerTabInfo.selectedLowestMaturityId]); */

    const SelectAfter = (suffixProps: SuffixProps) => {
        return (
            <Form.Item
                name={suffixProps.selectName}
                noStyle
                rules={[
                    {
                        required: offerTabInfo.lowestMaturitySelected,
                        message: t("offer.add.noCurrencyWarning"),
                    },
                ]}
            >
                {
                    props.lowestMaturitySelected ?
                        <Select getPopupContainer={trigger => trigger.parentElement} defaultValue={null} style={{ width: "4.75rem" }} 

                            onChange={(e) => {
                                if(props.suffixProps && props.suffixProps.selectName) {
                                    setOfferTabInfo((oldVal) => {return {...oldVal, currencyType: props.form.getFieldValue(props.suffixProps?.selectName ?? "")}});
                                }            
                            }} 
                            >
                            {suffixProps.items?.map((model) => {
                                return <Select.Option key={model.name}>{model.name}</Select.Option>;
                            })}
                        </Select>
                        :
                        <Select getPopupContainer={trigger => trigger.parentElement} suffixIcon={null} disabled style={{ width: "4.75rem", marginLeft: '0.5rem' }} defaultValue={suffixProps.selectedItem?.name}>
                            {suffixProps.items?.map((model) => {
                                return <Select.Option key={model.name}>{model.name}</Select.Option>;
                            })}
                        </Select>
                }
                
            </Form.Item>
        );
    };

    return (
        <div className={styles.container}>
            {props.lowestMaturitySelected ? 
                <Row style={{width: '100%', display: 'flex', justifyContent: 'center'}} >

                    <Col xs={24} sm={24} md={22}>
                        <Row className={styles.periodSelectionTitle}>
                            <span>{t("offer.add.period.title")}</span>
                        </Row>
                        <Row className={styles.periodSelectionLowestMaturityOptionContainer} gutter={4}>
                            <Col onClick={e => {
                                setOfferTabInfo((oldVal) => {return {...oldVal, selectedPeriodType: 1}});
                            }} 
                                xs={6} md={6} style={{display: 'flex', alignItems: 'center'}}>
                                <Button type="primary" className={offerTabInfo.selectedPeriodType === 1 ? styles.selectedButton : styles.nonSelectedButton}>
                                    <span className={offerTabInfo.selectedPeriodType === 1 ? styles.selectedText : styles.nonSelectedText}>{t("offer.add.period.hourly")}</span>
                                </Button>
                            </Col>
                            <Col onClick={e => {
                                setOfferTabInfo((oldVal) => {return {...oldVal, selectedPeriodType: 2}});
                            }} xs={6} md={6} style={{display: 'flex', alignItems: 'center'}}>
                                <Button type="primary" className={offerTabInfo.selectedPeriodType === 2 ? styles.selectedButton : styles.nonSelectedButton}>
                                    <span className={offerTabInfo.selectedPeriodType === 2 ? styles.selectedText : styles.nonSelectedText}>{t("offer.add.period.daily")}</span>
                                </Button>
                            </Col>
                            <Col onClick={e => {
                                setOfferTabInfo((oldVal) => {return {...oldVal, selectedPeriodType: 3}});
                            }} xs={6} md={6} style={{display: 'flex', alignItems: 'center'}}>
                                <Button type="primary" className={offerTabInfo.selectedPeriodType === 3 ? styles.selectedButton : styles.nonSelectedButton}>
                                    <span className={offerTabInfo.selectedPeriodType === 3 ? styles.selectedText : styles.nonSelectedText}>{t("offer.add.period.weekly")}</span>
                                </Button>
                            </Col>
                            <Col onClick={e => {
                                setOfferTabInfo((oldVal) => {return {...oldVal, selectedPeriodType: 4}});
                            }} xs={6} md={6} style={{display: 'flex', alignItems: 'center'}}>
                                <Button type="primary" className={offerTabInfo.selectedPeriodType === 4 ? styles.selectedButton : styles.nonSelectedButton}>
                                    <span className={offerTabInfo.selectedPeriodType === 4 ? styles.selectedText : styles.nonSelectedText}>{t("offer.add.period.monthly")}</span>
                                </Button>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={24} sm={24} md={24}>
                                <Form.Item
                                        name={props.lowestMaturityInputName}
                                        label={props.inputLabel}
                                        style={{width: '100%', fontWeight: '600', marginTop: '1rem'}}
                                        rules={[
                                            {
                                              required: true,
                                              pattern: /^([1-9][0-9]*)$/,
                                              message: t("offer.add.noFeeWarning"),
                                            },
                                        ]}
                                >
                                    <Input 
                                    className={styles.periodInput}
                                    value={offerTabInfo.lowestSelectedMaturityPeriodPrice ?? undefined}
                                    addonAfter={
                                        <SelectAfter
                                            selectName={props.suffixProps?.selectName}
                                            items={props.suffixProps?.items}
                                            selectedItem={props.suffixProps?.selectedItem}
                                        />
                                    }
                                    onChange={(e) => {
                                        const re = /^(0|[1-9][0-9]*)$/;
                                        if(!re.test(e.target.value)){
                                            e.target.value="";
                                        } 
                                        
                                        if(e.currentTarget.value !== "" && re.test(e.target.value)){
                                            setOfferTabInfo((oldVal) => { return { ...oldVal, lowestSelectedMaturityPeriodPrice: Number.parseInt(e.currentTarget.value) }});
                                        } 
                                        else setOfferTabInfo((oldVal) => { return { ...oldVal, lowestSelectedMaturityPeriodPrice: null}});
                                        
                                        let newList = offerTabInfo.maturityInterestRates;
                                        newList[offerTabInfo.currentTab.index] = 0;
                                        setOfferTabInfo((oldVal) => { return {...oldVal, maturityInterestRates: newList } });
                                        
                                    }}
                                    >
                                    </Input>
                                </Form.Item>
                            </Col>
                        </Row>
                    </Col>
                </Row>
                :   
                <Row>
                    <Row style={{width: '100%', display: 'flex', justifyContent: 'center'}}>
                        <Col xs={23} sm={23} md={23}>
                            <Form.Item
                                    name={props.maturityName + "-input"}
                                    label={props.maturityName ?? props.inputLabel}
                                    style={{width: '100%', marginTop: '1rem'}}
                                    rules={[
                                        {
                                          required: true,
                                          pattern: /^(0|[1-9][0-9]*)$/,
                                          message: t("common.generic-required-numeric-input-message"),
                                        },
                                    ]}
                            >
                                <Input 
                                className={ offerTabInfo.includedMaturityOptionsError.at(props.index) ? styles.periodInputWithError : styles.periodInput}
                                placeholder={t("offer.add.maturityTab.text2")}
                                value={offerTabInfo.maturityInterestRates[offerTabInfo.currentTab.index] ?? undefined}
                                onChange={(e) => {
                                    const re = /^(0|[1-9][0-9]*)$/;
                                    if (!(re.test(e.target.value))){
                                        e.target.value="";
                                        let newList = offerTabInfo.maturityInterestRates;
                                        newList[offerTabInfo.currentTab.index] = null;
                                        setOfferTabInfo((oldVal) => { return {...oldVal, maturityInterestRates: newList } });
                                        return;
                                    } 
                                    else if(e.target.value !== ""){
                                        let newList = offerTabInfo.maturityInterestRates;
                                        newList[offerTabInfo.currentTab.index] = Number.parseInt(e.target.value);
                                        let newErrorList = offerTabInfo.includedMaturityOptionsError;
                                        newErrorList[props.index] = false;
                                        setOfferTabInfo((oldVal) => { return {...oldVal, maturityInterestRates: newList, includedMaturityOptionsError: newErrorList } });
                                    }
                                    else {
                                        let newList = offerTabInfo.maturityInterestRates;
                                        newList[offerTabInfo.currentTab.index] = Number.parseInt(e.target.value);
                                        setOfferTabInfo((oldVal) => { return {...oldVal, maturityInterestRates: newList } });
                                    }
                                }}
                                addonAfter={
                                    <SelectAfter
                                        selectName={"%"}
                                        items={props.suffixProps?.items}
                                        selectedItem={{name:"%", id: undefined}}
                                    />
                                }
                                >
                                </Input>
                            </Form.Item>
                    </Col>
                    </Row>
                    <Row style={{marginLeft:'0px', marginTop: '-1.25rem',width: '100%', display: 'flex', justifyContent: 'space-around'}} gutter={12}>
                        <Col xs={24} sm={24} md={12}>
                            <Row className={styles.periodSelectionTitle}>
                                <span>{t("offer.add.period.title")}</span>
                            </Row>
                            <Row className={styles.periodSelectionContainer}>
                                <Col xs={6} sm={6} md={6} style={{display: 'flex', alignContent: 'center', justifyContent: 'center' }}>
                                    <span className={styles[offerTabInfo.selectedPeriodType === 1 ? "periodSelected" : "period"]}>{t("offer.add.period.hourly")}</span>
                                </Col>
                                <Col xs={6} sm={6} md={6} style={{display: 'flex', alignContent: 'center', justifyContent: 'center' }}>
                                    <span className={styles[offerTabInfo.selectedPeriodType === 2 ? "periodSelected" : "period"]}>{t("offer.add.period.daily")}</span>
                                </Col>
                                <Col xs={6} sm={6} md={6} style={{display: 'flex', alignContent: 'center', justifyContent: 'center' }}>
                                    <span className={styles[offerTabInfo.selectedPeriodType === 3 ? "periodSelected" : "period"]}>{t("offer.add.period.weekly")}</span>
                                </Col>
                                <Col xs={6} sm={6} md={6} style={{display: 'flex', alignContent: 'center', justifyContent: 'center' }}>
                                    <span className={styles[offerTabInfo.selectedPeriodType === 4 ? "periodSelected" : "period"]}>{t("offer.add.period.monthly")}</span>
                                </Col>
                            </Row>
                        </Col>
                        <Col sm={24} md={11}>
                            <Form.Item
                                    name={props.inputName}
                                    initialValue={0}
                                    label={props.inputLabel}                
                                    style={{width: '100%', marginTop: '1rem'}}
                                    rules={[
                                        {
                                          pattern: /^(0|[1-9][0-9]*)$/,
                                          message: t("common.generic-required-numeric-input-message"),
                                        },
                                    ]}
                            >
                                <Input 
                                className={styles.periodInput}
                                disabled={!props.lowestMaturitySelected}
                                value={offerTabInfo.lowestSelectedMaturityPeriodPrice ? Math.ceil(offerTabInfo.lowestSelectedMaturityPeriodPrice + (offerTabInfo.lowestSelectedMaturityPeriodPrice * (offerTabInfo.maturityInterestRates[offerTabInfo.currentTab.index] ?? 0) * 0.01)) : undefined}
                                addonAfter={
                                    <SelectAfter
                                        selectName={props.suffixProps?.selectName}
                                        items={props.suffixProps?.items}
                                        selectedItem={props.suffixProps?.selectedItem}
                                    />
                                }
                                >
                                </Input>
                            </Form.Item>
                        </Col>
                    </Row>
                    
                </Row>
                
            
            }
        </div>
        
        
    
    );

}


export default OfferPeriodWithInput;