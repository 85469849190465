/* tslint:disable */
/* eslint-disable */
/**
 * MachineGo P1 API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    BasicModel,
    BasicModelFromJSON,
    BasicModelFromJSONTyped,
    BasicModelToJSON,
} from './BasicModel';
import {
    OfferFormBidAttachmentViewModel,
    OfferFormBidAttachmentViewModelFromJSON,
    OfferFormBidAttachmentViewModelFromJSONTyped,
    OfferFormBidAttachmentViewModelToJSON,
} from './OfferFormBidAttachmentViewModel';

/**
 * 
 * @export
 * @interface OfferFormBidViewModel
 */
export interface OfferFormBidViewModel {
    /**
     * 
     * @type {number}
     * @memberof OfferFormBidViewModel
     */
    bid?: number;
    /**
     * 
     * @type {number}
     * @memberof OfferFormBidViewModel
     */
    predictedTotal?: number;
    /**
     * 
     * @type {number}
     * @memberof OfferFormBidViewModel
     */
    machineGoCommission?: number;
    /**
     * 
     * @type {number}
     * @memberof OfferFormBidViewModel
     */
    advancePayment?: number;
    /**
     * 
     * @type {number}
     * @memberof OfferFormBidViewModel
     */
    renterPeriodPayment?: number;
    /**
     * 
     * @type {number}
     * @memberof OfferFormBidViewModel
     */
    lessorMachineGoCommission?: number;
    /**
     * 
     * @type {number}
     * @memberof OfferFormBidViewModel
     */
    lessorPeriodPayment?: number;
    /**
     * 
     * @type {number}
     * @memberof OfferFormBidViewModel
     */
    readonly total?: number;
    /**
     * 
     * @type {boolean}
     * @memberof OfferFormBidViewModel
     */
    selected?: boolean;
    /**
     * 
     * @type {string}
     * @memberof OfferFormBidViewModel
     */
    paymentLink?: string | null;
    /**
     * 
     * @type {number}
     * @memberof OfferFormBidViewModel
     */
    shippingFee?: number | null;
    /**
     * 
     * @type {number}
     * @memberof OfferFormBidViewModel
     */
    shippingToBackFee?: number | null;
    /**
     * 
     * @type {number}
     * @memberof OfferFormBidViewModel
     */
    shippingFeeWithCommission?: number | null;
    /**
     * 
     * @type {number}
     * @memberof OfferFormBidViewModel
     */
    shippingToBackFeeWithCommission?: number | null;
    /**
     * 
     * @type {number}
     * @memberof OfferFormBidViewModel
     */
    paymentPeriodId?: number | null;
    /**
     * 
     * @type {number}
     * @memberof OfferFormBidViewModel
     */
    maturityOptionId?: number | null;
    /**
     * 
     * @type {BasicModel}
     * @memberof OfferFormBidViewModel
     */
    maturityOption?: BasicModel;
    /**
     * 
     * @type {BasicModel}
     * @memberof OfferFormBidViewModel
     */
    currency?: BasicModel;
    /**
     * 
     * @type {BasicModel}
     * @memberof OfferFormBidViewModel
     */
    paymentPeriod?: BasicModel;
    /**
     * 
     * @type {Array<OfferFormBidAttachmentViewModel>}
     * @memberof OfferFormBidViewModel
     */
    attachments?: Array<OfferFormBidAttachmentViewModel> | null;
}

export function OfferFormBidViewModelFromJSON(json: any): OfferFormBidViewModel {
    return OfferFormBidViewModelFromJSONTyped(json, false);
}

export function OfferFormBidViewModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): OfferFormBidViewModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'bid': !exists(json, 'bid') ? undefined : json['bid'],
        'predictedTotal': !exists(json, 'predictedTotal') ? undefined : json['predictedTotal'],
        'machineGoCommission': !exists(json, 'machineGoCommission') ? undefined : json['machineGoCommission'],
        'advancePayment': !exists(json, 'advancePayment') ? undefined : json['advancePayment'],
        'renterPeriodPayment': !exists(json, 'renterPeriodPayment') ? undefined : json['renterPeriodPayment'],
        'lessorMachineGoCommission': !exists(json, 'lessorMachineGoCommission') ? undefined : json['lessorMachineGoCommission'],
        'lessorPeriodPayment': !exists(json, 'lessorPeriodPayment') ? undefined : json['lessorPeriodPayment'],
        'total': !exists(json, 'total') ? undefined : json['total'],
        'selected': !exists(json, 'selected') ? undefined : json['selected'],
        'paymentLink': !exists(json, 'paymentLink') ? undefined : json['paymentLink'],
        'shippingFee': !exists(json, 'shippingFee') ? undefined : json['shippingFee'],
        'shippingToBackFee': !exists(json, 'shippingToBackFee') ? undefined : json['shippingToBackFee'],
        'shippingFeeWithCommission': !exists(json, 'shippingFeeWithCommission') ? undefined : json['shippingFeeWithCommission'],
        'shippingToBackFeeWithCommission': !exists(json, 'shippingToBackFeeWithCommission') ? undefined : json['shippingToBackFeeWithCommission'],
        'paymentPeriodId': !exists(json, 'paymentPeriodId') ? undefined : json['paymentPeriodId'],
        'maturityOptionId': !exists(json, 'maturityOptionId') ? undefined : json['maturityOptionId'],
        'maturityOption': !exists(json, 'maturityOption') ? undefined : BasicModelFromJSON(json['maturityOption']),
        'currency': !exists(json, 'currency') ? undefined : BasicModelFromJSON(json['currency']),
        'paymentPeriod': !exists(json, 'paymentPeriod') ? undefined : BasicModelFromJSON(json['paymentPeriod']),
        'attachments': !exists(json, 'attachments') ? undefined : (json['attachments'] === null ? null : (json['attachments'] as Array<any>).map(OfferFormBidAttachmentViewModelFromJSON)),
    };
}

export function OfferFormBidViewModelToJSON(value?: OfferFormBidViewModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'bid': value.bid,
        'predictedTotal': value.predictedTotal,
        'machineGoCommission': value.machineGoCommission,
        'advancePayment': value.advancePayment,
        'renterPeriodPayment': value.renterPeriodPayment,
        'lessorMachineGoCommission': value.lessorMachineGoCommission,
        'lessorPeriodPayment': value.lessorPeriodPayment,
        'selected': value.selected,
        'paymentLink': value.paymentLink,
        'shippingFee': value.shippingFee,
        'shippingToBackFee': value.shippingToBackFee,
        'shippingFeeWithCommission': value.shippingFeeWithCommission,
        'shippingToBackFeeWithCommission': value.shippingToBackFeeWithCommission,
        'paymentPeriodId': value.paymentPeriodId,
        'maturityOptionId': value.maturityOptionId,
        'maturityOption': BasicModelToJSON(value.maturityOption),
        'currency': BasicModelToJSON(value.currency),
        'paymentPeriod': BasicModelToJSON(value.paymentPeriod),
        'attachments': value.attachments === undefined ? undefined : (value.attachments === null ? null : (value.attachments as Array<any>).map(OfferFormBidAttachmentViewModelToJSON)),
    };
}

