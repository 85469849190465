import { t } from "../../translate";
import { useAtom } from "jotai";
import RequestLayout from "../../components/layouts/request-layout";
import { Checkbox, Col, Radio, Row } from "antd";
import RadioButton from "../../components/atomics/radio-button";
import { ChangeEvent, useEffect, useState } from "react";
import { ModifiedRequestDetailModel, postloginMessageAtom, requestFormAtom, userAtom } from "../../store/global-atoms";
import BoxContainer from "../../components/containers/box-container";
import IncrementsInput from "../../components/atomics/increments-input";
import CheckboxButton from "../../components/atomics/checkbox-button";
import TextArea from "antd/lib/input/TextArea";
import { RequestSummary } from "../../modals/request-summary";
import { MachinegoDocumentUploader } from "../../components/atomics/machinego-document-uploader";
import { DocumentModel } from "../../service/models";

const MachineDetail = (props: {
    secure?: boolean;
    formComplete?: (model: ModifiedRequestDetailModel) => void;
}) => {
    const [selectedRadio, changeSelectedRadio] = useState<string | undefined>(undefined);
    const [detailText, setDetailText] = useState<string | undefined>(undefined);
    const [createCount, setCreateCount] = useState<number | undefined>(undefined);
    const [lightboxVisible, toggleLightboxVisible] = useState<boolean>(false);
    const [editedRequest, editRequest] = useAtom(requestFormAtom);
    const [selectedCheckboxes, changeSelectedCheckboxes] = useState<any[]>([]);
    const [documents, setDocuments] = useState<DocumentModel[]>([]);
    const [, setErrorMessage] = useAtom(postloginMessageAtom);
    const [user] = useAtom(userAtom);

    const continueClick = () => {
        if (!selectedRadio) return;
        var requestModelFinalState: ModifiedRequestDetailModel = {
            ...editedRequest,
            machineCount: createCount,
            documents: documents,
            dailyWorkingTime: parseInt(selectedRadio),
            additionalServices: {
                fuelIncluded: selectedCheckboxes.includes("fuelIncluded"),
                hasOperator: selectedCheckboxes.includes("hasOperator"),
                operatorCostIncluded: selectedCheckboxes.includes("operatorCostIncluded"),
                operatorInsuranceIncluded: selectedCheckboxes.includes("operatorInsuranceIncluded"),
                shipingIncluded: selectedCheckboxes.includes("shipingIncluded"),
            },
            description: detailText,
        };
        editRequest(requestModelFinalState);
        if (props.secure && props.formComplete) {
            props.formComplete(requestModelFinalState);
        } else toggleLightboxVisible(true);
    };

    useEffect(() => {
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
        changeSelectedRadio(editedRequest?.dailyWorkingTime?.toString() ?? "");
        let additionalServices = [];
        if (editedRequest?.additionalServices?.fuelIncluded === true) {
            additionalServices.push("fuelIncluded");
        }
        if (editedRequest?.additionalServices?.hasOperator === true) {
            additionalServices.push("hasOperator");
        }
        if (editedRequest?.additionalServices?.operatorCostIncluded === true) {
            additionalServices.push("operatorCostIncluded");
        }
        if (editedRequest?.additionalServices?.operatorInsuranceIncluded === true) {
            additionalServices.push("operatorInsuranceIncluded");
        }
        if (editedRequest?.additionalServices?.shipingIncluded === true) {
            additionalServices.push("shipingIncluded");
        }
        changeSelectedCheckboxes(additionalServices);
        setDetailText(editedRequest?.description ?? "");
        setDocuments(editedRequest?.documents ?? []);
        //eslint-disable-next-line
    }, []);

    const radioClicked = (val: any) => {
        changeSelectedRadio(val);
    };
    const createCountChanged = (num: number) => {
        setCreateCount(num);
    };

    const checkboxClicked = (val: any) => {
        changeSelectedCheckboxes((old) => {
            if (old.includes(val)) {
                return old.filter((att) => {
                    return att !== val;
                });
            } else return [...old, val];
        });
    };

    const detailTextAreaChanged = (val: ChangeEvent<HTMLTextAreaElement>) => {
        setDetailText(val.currentTarget.value);
    };

    return (
        <RequestLayout currentStep={2} continueVisible={true} onContinueClick={continueClick}>
            <h3>{t("request.machine-detail.createCount")}</h3>
            <BoxContainer>
                <IncrementsInput min={1} max={50} onChange={createCountChanged} />
            </BoxContainer>
            <h3>{t("request.machine-detail.workingHours")}</h3>
            <BoxContainer>
                <Radio.Group defaultValue={undefined} value={selectedRadio} style={{ width: "100%" }}>
                    <Row gutter={[24, 24]}>
                        <Col xs={24} md={8}>
                            <RadioButton value="8" content={t("request.machine-detail.8hours")} radioClicked={radioClicked} />
                        </Col>
                        <Col xs={24} md={8}>
                            <RadioButton value="16" content={t("request.machine-detail.16hours")} radioClicked={radioClicked} />
                        </Col>
                        <Col xs={24} md={8}>
                            <RadioButton value="24" content={t("request.machine-detail.24hours")} radioClicked={radioClicked} />
                        </Col>
                    </Row>
                </Radio.Group>
            </BoxContainer>
            <h3>{t("request.machine-detail.additionalServices")}</h3>
            <BoxContainer>
                <Checkbox.Group defaultValue={undefined} value={selectedCheckboxes} style={{ width: "100%" }}>
                    <Row gutter={[24, 24]}>
                        <Col xs={24} lg={12}>
                            <CheckboxButton
                                content={t("request.machine-detail.hasOperator")}
                                value="hasOperator"
                                checkboxClicked={checkboxClicked}
                            />
                        </Col>
                        <Col xs={24} lg={12}>
                            <CheckboxButton
                                content={t("request.machine-detail.operatorCostIncluded")}
                                value="operatorCostIncluded"
                                checkboxClicked={checkboxClicked}
                            />
                        </Col>
                        <Col xs={24} lg={12}>
                            <CheckboxButton
                                content={t("request.machine-detail.operatorInsuranceIncluded")}
                                value="operatorInsuranceIncluded"
                                checkboxClicked={checkboxClicked}
                            />
                        </Col>
                        <Col xs={24} lg={12}>
                            <CheckboxButton
                                content={t("request.machine-detail.fuelIncluded")}
                                value="fuelIncluded"
                                checkboxClicked={checkboxClicked}
                            />
                        </Col>
                        <Col xs={24} lg={12}>
                            <CheckboxButton
                                content={t("request.machine-detail.shipingIncluded")}
                                value="shipingIncluded"
                                checkboxClicked={checkboxClicked}
                            />
                        </Col>
                    </Row>
                </Checkbox.Group>
            </BoxContainer>
            <h3>{t("request.machine-detail.uploaderTitle")}</h3>
            {user && (
                <BoxContainer>
                    <MachinegoDocumentUploader
                        defaultDocumentFiles={documents}
                        unsupportedFileError={() =>
                            setErrorMessage({
                                message: t("common.unsupportedFile"),
                                type: "error",
                            })
                        }
                        onFilesChange={(files) => {
                            console.log("files: ", files);
                            setDocuments(files);
                        }}
                        title={t("request.machine-detail.uploaderSubtitle")}
                        description={t("request.machine-detail.uploaderDescription")}
                        multiple={true}
                        docType={"Other"}
                    />
                </BoxContainer>
            )}
            <h3>{t("request.machine-detail.writeDetailsHead")}</h3>
            <TextArea
                defaultValue={editedRequest?.description ?? ""}
                style={{ marginBottom: "2rem" }}
                placeholder={t("request.machine-detail.writeDetailsPlaceholder")}
                onChange={detailTextAreaChanged}
            ></TextArea>
            <RequestSummary
                show={lightboxVisible}
                onCloseClick={() => {
                    toggleLightboxVisible(false);
                }}
            ></RequestSummary>
        </RequestLayout>
    );
};

export default MachineDetail;
