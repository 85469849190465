/* tslint:disable */
/* eslint-disable */
/**
 * MachineGo P1 API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const RequestStatus = {
    InReview: 'InReview',
    Rejected: 'Rejected',
    Approved: 'Approved',
    Suggested: 'Suggested',
    Offered: 'Offered',
    Deal: 'Deal',
    InProgress: 'InProgress',
    Completed: 'Completed',
    Expired: 'Expired',
    PreRegistration: 'PreRegistration'
} as const;
export type RequestStatus = typeof RequestStatus[keyof typeof RequestStatus];


export function RequestStatusFromJSON(json: any): RequestStatus {
    return RequestStatusFromJSONTyped(json, false);
}

export function RequestStatusFromJSONTyped(json: any, ignoreDiscriminator: boolean): RequestStatus {
    return json as RequestStatus;
}

export function RequestStatusToJSON(value?: RequestStatus | null): any {
    return value as any;
}

