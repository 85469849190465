/* tslint:disable */
/* eslint-disable */
/**
 * MachineGo P1 API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    RenterCompanySearchFilterModel,
    RenterCompanySearchFilterModelFromJSON,
    RenterCompanySearchFilterModelFromJSONTyped,
    RenterCompanySearchFilterModelToJSON,
} from './RenterCompanySearchFilterModel';
import {
    RenterCompanySearchSortField,
    RenterCompanySearchSortFieldFromJSON,
    RenterCompanySearchSortFieldFromJSONTyped,
    RenterCompanySearchSortFieldToJSON,
} from './RenterCompanySearchSortField';

/**
 * 
 * @export
 * @interface RenterCompanySearchModel
 */
export interface RenterCompanySearchModel {
    /**
     * 
     * @type {number}
     * @memberof RenterCompanySearchModel
     */
    pageNo?: number;
    /**
     * 
     * @type {number}
     * @memberof RenterCompanySearchModel
     */
    pageSize?: number;
    /**
     * 
     * @type {number}
     * @memberof RenterCompanySearchModel
     */
    readonly rowIndex?: number;
    /**
     * 
     * @type {RenterCompanySearchFilterModel}
     * @memberof RenterCompanySearchModel
     */
    filter?: RenterCompanySearchFilterModel;
    /**
     * 
     * @type {RenterCompanySearchSortField}
     * @memberof RenterCompanySearchModel
     */
    sortBy?: RenterCompanySearchSortField;
    /**
     * 
     * @type {boolean}
     * @memberof RenterCompanySearchModel
     */
    sortAscending?: boolean;
}

export function RenterCompanySearchModelFromJSON(json: any): RenterCompanySearchModel {
    return RenterCompanySearchModelFromJSONTyped(json, false);
}

export function RenterCompanySearchModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): RenterCompanySearchModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'pageNo': !exists(json, 'pageNo') ? undefined : json['pageNo'],
        'pageSize': !exists(json, 'pageSize') ? undefined : json['pageSize'],
        'rowIndex': !exists(json, 'rowIndex') ? undefined : json['rowIndex'],
        'filter': !exists(json, 'filter') ? undefined : RenterCompanySearchFilterModelFromJSON(json['filter']),
        'sortBy': !exists(json, 'sortBy') ? undefined : RenterCompanySearchSortFieldFromJSON(json['sortBy']),
        'sortAscending': !exists(json, 'sortAscending') ? undefined : json['sortAscending'],
    };
}

export function RenterCompanySearchModelToJSON(value?: RenterCompanySearchModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'pageNo': value.pageNo,
        'pageSize': value.pageSize,
        'filter': RenterCompanySearchFilterModelToJSON(value.filter),
        'sortBy': RenterCompanySearchSortFieldToJSON(value.sortBy),
        'sortAscending': value.sortAscending,
    };
}

