/* tslint:disable */
/* eslint-disable */
/**
 * MachineGo P1 API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    BasicModel,
    BasicModelFromJSON,
    BasicModelFromJSONTyped,
    BasicModelToJSON,
} from './BasicModel';
import {
    DistrictModel,
    DistrictModelFromJSON,
    DistrictModelFromJSONTyped,
    DistrictModelToJSON,
} from './DistrictModel';
import {
    LessorUserViewModel,
    LessorUserViewModelFromJSON,
    LessorUserViewModelFromJSONTyped,
    LessorUserViewModelToJSON,
} from './LessorUserViewModel';
import {
    SubCategoryModel,
    SubCategoryModelFromJSON,
    SubCategoryModelFromJSONTyped,
    SubCategoryModelToJSON,
} from './SubCategoryModel';

/**
 * 
 * @export
 * @interface LessorCompanyDetailViewModel
 */
export interface LessorCompanyDetailViewModel {
    /**
     * 
     * @type {number}
     * @memberof LessorCompanyDetailViewModel
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof LessorCompanyDetailViewModel
     */
    companyName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof LessorCompanyDetailViewModel
     */
    address?: string | null;
    /**
     * 
     * @type {string}
     * @memberof LessorCompanyDetailViewModel
     */
    mainServiceActivityFields?: string | null;
    /**
     * 
     * @type {number}
     * @memberof LessorCompanyDetailViewModel
     */
    machineCount?: number;
    /**
     * 
     * @type {number}
     * @memberof LessorCompanyDetailViewModel
     */
    operatorCount?: number | null;
    /**
     * 
     * @type {number}
     * @memberof LessorCompanyDetailViewModel
     */
    employeeCount?: number | null;
    /**
     * 
     * @type {string}
     * @memberof LessorCompanyDetailViewModel
     */
    taxIdentityNo?: string | null;
    /**
     * 
     * @type {string}
     * @memberof LessorCompanyDetailViewModel
     */
    taxOffice?: string | null;
    /**
     * 
     * @type {BasicModel}
     * @memberof LessorCompanyDetailViewModel
     */
    taxOfficeCity?: BasicModel;
    /**
     * 
     * @type {string}
     * @memberof LessorCompanyDetailViewModel
     */
    iban?: string | null;
    /**
     * 
     * @type {number}
     * @memberof LessorCompanyDetailViewModel
     */
    offerCount?: number;
    /**
     * 
     * @type {number}
     * @memberof LessorCompanyDetailViewModel
     */
    acceptedOfferCount?: number;
    /**
     * 
     * @type {Date}
     * @memberof LessorCompanyDetailViewModel
     */
    lastOfferDate?: Date | null;
    /**
     * 
     * @type {number}
     * @memberof LessorCompanyDetailViewModel
     */
    totalMachines?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof LessorCompanyDetailViewModel
     */
    hasAnyOffer?: boolean;
    /**
     * 
     * @type {DistrictModel}
     * @memberof LessorCompanyDetailViewModel
     */
    location?: DistrictModel;
    /**
     * 
     * @type {Array<SubCategoryModel>}
     * @memberof LessorCompanyDetailViewModel
     */
    machines?: Array<SubCategoryModel> | null;
    /**
     * 
     * @type {LessorUserViewModel}
     * @memberof LessorCompanyDetailViewModel
     */
    companyOwner?: LessorUserViewModel;
    /**
     * 
     * @type {Array<LessorUserViewModel>}
     * @memberof LessorCompanyDetailViewModel
     */
    companyUsers?: Array<LessorUserViewModel> | null;
    /**
     * 
     * @type {Array<BasicModel>}
     * @memberof LessorCompanyDetailViewModel
     */
    serviceRegions?: Array<BasicModel> | null;
    /**
     * 
     * @type {Array<BasicModel>}
     * @memberof LessorCompanyDetailViewModel
     */
    categories?: Array<BasicModel> | null;
}

export function LessorCompanyDetailViewModelFromJSON(json: any): LessorCompanyDetailViewModel {
    return LessorCompanyDetailViewModelFromJSONTyped(json, false);
}

export function LessorCompanyDetailViewModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): LessorCompanyDetailViewModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'companyName': !exists(json, 'companyName') ? undefined : json['companyName'],
        'address': !exists(json, 'address') ? undefined : json['address'],
        'mainServiceActivityFields': !exists(json, 'mainServiceActivityFields') ? undefined : json['mainServiceActivityFields'],
        'machineCount': !exists(json, 'machineCount') ? undefined : json['machineCount'],
        'operatorCount': !exists(json, 'operatorCount') ? undefined : json['operatorCount'],
        'employeeCount': !exists(json, 'employeeCount') ? undefined : json['employeeCount'],
        'taxIdentityNo': !exists(json, 'taxIdentityNo') ? undefined : json['taxIdentityNo'],
        'taxOffice': !exists(json, 'taxOffice') ? undefined : json['taxOffice'],
        'taxOfficeCity': !exists(json, 'taxOfficeCity') ? undefined : BasicModelFromJSON(json['taxOfficeCity']),
        'iban': !exists(json, 'iban') ? undefined : json['iban'],
        'offerCount': !exists(json, 'offerCount') ? undefined : json['offerCount'],
        'acceptedOfferCount': !exists(json, 'acceptedOfferCount') ? undefined : json['acceptedOfferCount'],
        'lastOfferDate': !exists(json, 'lastOfferDate') ? undefined : (json['lastOfferDate'] === null ? null : new Date(json['lastOfferDate'])),
        'totalMachines': !exists(json, 'totalMachines') ? undefined : json['totalMachines'],
        'hasAnyOffer': !exists(json, 'hasAnyOffer') ? undefined : json['hasAnyOffer'],
        'location': !exists(json, 'location') ? undefined : DistrictModelFromJSON(json['location']),
        'machines': !exists(json, 'machines') ? undefined : (json['machines'] === null ? null : (json['machines'] as Array<any>).map(SubCategoryModelFromJSON)),
        'companyOwner': !exists(json, 'companyOwner') ? undefined : LessorUserViewModelFromJSON(json['companyOwner']),
        'companyUsers': !exists(json, 'companyUsers') ? undefined : (json['companyUsers'] === null ? null : (json['companyUsers'] as Array<any>).map(LessorUserViewModelFromJSON)),
        'serviceRegions': !exists(json, 'serviceRegions') ? undefined : (json['serviceRegions'] === null ? null : (json['serviceRegions'] as Array<any>).map(BasicModelFromJSON)),
        'categories': !exists(json, 'categories') ? undefined : (json['categories'] === null ? null : (json['categories'] as Array<any>).map(BasicModelFromJSON)),
    };
}

export function LessorCompanyDetailViewModelToJSON(value?: LessorCompanyDetailViewModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'companyName': value.companyName,
        'address': value.address,
        'mainServiceActivityFields': value.mainServiceActivityFields,
        'machineCount': value.machineCount,
        'operatorCount': value.operatorCount,
        'employeeCount': value.employeeCount,
        'taxIdentityNo': value.taxIdentityNo,
        'taxOffice': value.taxOffice,
        'taxOfficeCity': BasicModelToJSON(value.taxOfficeCity),
        'iban': value.iban,
        'offerCount': value.offerCount,
        'acceptedOfferCount': value.acceptedOfferCount,
        'lastOfferDate': value.lastOfferDate === undefined ? undefined : (value.lastOfferDate === null ? null : value.lastOfferDate.toISOString()),
        'totalMachines': value.totalMachines,
        'hasAnyOffer': value.hasAnyOffer,
        'location': DistrictModelToJSON(value.location),
        'machines': value.machines === undefined ? undefined : (value.machines === null ? null : (value.machines as Array<any>).map(SubCategoryModelToJSON)),
        'companyOwner': LessorUserViewModelToJSON(value.companyOwner),
        'companyUsers': value.companyUsers === undefined ? undefined : (value.companyUsers === null ? null : (value.companyUsers as Array<any>).map(LessorUserViewModelToJSON)),
        'serviceRegions': value.serviceRegions === undefined ? undefined : (value.serviceRegions === null ? null : (value.serviceRegions as Array<any>).map(BasicModelToJSON)),
        'categories': value.categories === undefined ? undefined : (value.categories === null ? null : (value.categories as Array<any>).map(BasicModelToJSON)),
    };
}

