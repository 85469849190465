import { Col, Row } from "antd";
import ModalLayout from "../components/layouts/modal-layout";
import { t } from "../translate";
import styles from "./styles/delete-account-modal.module.scss"
import PrimaryButton from "../components/atomics/primary-button";

interface DeleteAccountModalProps {
    type: 'customer' | 'lessor' | 'manager';
    show: boolean;
    hasAnyOffer?: boolean;
    hasAnyRequest?: boolean;
    onDeleteClick: () => void;
    onCloseClick: () => void;
}


const DeleteAccountModal = (props: DeleteAccountModalProps) => {

    return(
        <ModalLayout onCloseClick={props.onCloseClick} show={props.show}>
            <Row className={styles['container']}> 
                <Col className={styles['question-container']} xs={22}>
                    <span className={styles.text}>{t('delete-account-modal.question')}</span>
                </Col>
                {
                    props.type === 'customer' ? 
                    (
                        <Col className={styles['item-container']} xs={21}>
                            <ul>
                                <li>
                                    <span>{t('delete-account-modal.customer.item-1')}</span>
                                </li>
                                <li hidden={!props.hasAnyRequest}>
                                    <span>{t('delete-account-modal.customer.item-2')}</span>
                                </li>
                            </ul>               
                        </Col>
                    )
                    :
                    props.type === 'lessor' ?
                    (
                        <Col className={styles['item-container']} xs={21}>
                            <ul>
                                <li>
                                    <span>{t('delete-account-modal.lessor.item-1')}</span>
                                </li>
                                <li>
                                    <span>{t('delete-account-modal.lessor.item-2')}</span>
                                </li>
                                <li>
                                    <span>{t('delete-account-modal.lessor.item-3')}</span>
                                </li>
                            </ul>               
                        </Col>
                    )
                    :
                    (<>
                        <Col className={styles['item-container']} xs={21}>
                            <ul>
                                <li>
                                    <span>{t('delete-account-modal.manager.item-1')}</span>
                                </li>
                                <li>
                                    <span>{t('delete-account-modal.manager.item-2')}</span>
                                </li>
                                <li>
                                    <span>{t('delete-account-modal.manager.item-3')}</span>
                                </li>
                                <li hidden={!props.hasAnyOffer}>
                                    <span>{t('delete-account-modal.manager.item-4')}</span>
                                </li>
                            </ul>               
                        </Col>
                    </>)
                }
                <Row className={styles['button-container']}>
                    <Col xs={24} sm={11}>
                        <PrimaryButton onClick={props.onCloseClick}>
                            <span>{t('common.cancel')}</span>
                        </PrimaryButton>
                    </Col>
                    <Col xs={24} sm={11}>
                        <PrimaryButton onClick={props.onDeleteClick} style={{backgroundColor: 'red', borderColor: 'transparent'}}>
                            <span>{t('common.delete')}</span>
                        </PrimaryButton>
                    </Col>
                </Row>
            </Row>
        </ModalLayout>
    );
}


export default DeleteAccountModal;