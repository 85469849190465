import { Col, Collapse, Form, FormInstance, InputNumber, Row } from "antd";
import styles from "./min-max-collapse-input.module.scss"
import CollapsePanel from "antd/lib/collapse/CollapsePanel";
import { useState } from "react";

interface MinMaxInputProps {
    form: FormInstance<any>;
    minFieldName: string;
    maxFieldName: string;
    minPlaceholder?: string;
    maxPlaceholder?: string;
    defaultMinValue?: number; 
    defaultMaxValue?: number; 
    min?: number;
    max?: number;
    label: string;
}

const MinMaxCollapseInput = (props: MinMaxInputProps) => {
    const [minVal, setMinVal] = useState(props.defaultMinValue);
    const [maxVal, setMaxVal] = useState(props.defaultMaxValue);

    return(
        <Collapse expandIconPosition="right" className={styles.container}>
            <CollapsePanel className={styles.panel} key={1} header={props.label}>
                <Row justify="space-between" gutter={[4,4]}>
                    <Col xs={12} className={styles.inputContainer} style={{paddingLeft: '0'}}>
                        <Form.Item
                            name={props.minFieldName}
                        >
                            <InputNumber 
                            min={props.min}
                            max={maxVal ?? props.max} 
                            value={minVal} 
                            onChange={(e) => setMinVal(e)} className={styles.input} placeholder={props.minPlaceholder}></InputNumber>
                        </Form.Item>
                    </Col> 
                    <Col xs={12} className={styles.inputContainer} style={{paddingRight: '0'}}>
                        <Form.Item
                            name={props.maxFieldName}
                        >
                            <InputNumber
                            min={minVal ?? props.min}
                            max={props.max}
                            value={maxVal} 
                            onChange={(e) => setMaxVal(e)} 
                            className={styles.input} 
                            placeholder={props.maxPlaceholder}></InputNumber>
                        </Form.Item>
                    </Col>                   
                </Row>
            </CollapsePanel>
        </Collapse>
    );
}

export default MinMaxCollapseInput;